import Footer from 'components/Footer';
import { Container } from './styled';
import GlobalInsightsLast30Days from './GlobalInsightsLast30Days';
import MonthlyBookingsInsights from './MonthlyBookingsInsights';
import AffiliateBookingsInsights from './AffiliateBookingsInsights';

const AffiliateInsights = () => (
    <Container>
        <GlobalInsightsLast30Days />
        <MonthlyBookingsInsights />
        <AffiliateBookingsInsights />
        <Footer />
    </Container>
);

export default AffiliateInsights;
