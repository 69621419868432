import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import SpamBadge from 'components/SpamBadge';
import Badge from 'components/Badge';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import { UserCellWrapper } from '../styled';
import { StyledTitleContainer, PhoneNumber } from './styled';

interface Props {
    row?: EntityGet<Session>;
}

const TitleWithBadge = ({ value = '', tags = [] }: { value?: string; tags?: string[] }) => (
    <StyledTitleContainer>
        <PhoneNumber>
            {formatIntlPhoneNumber(value)}
        </PhoneNumber>
        {tags.includes('spam') && <SpamBadge />}
        {tags.includes('transferred') && <Badge variant="default" borderRadius="semi-square" size="small">Transferred</Badge>}
    </StyledTitleContainer>
);

const UserColumn = ({ row }: Props) => {
    const { from = '', _tags: tags = [] } = row || {};
    return (
        <UserCellWrapper>
            <ListTile
                icon={<UserAvatar value={from} />}
                title={<TitleWithBadge value={from} tags={tags} />}
                subtitle="Mobile"
            />
        </UserCellWrapper>
    );
};

export default UserColumn;
