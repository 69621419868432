import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { ButtonOption, RenderIf } from 'react-rainbow-components';
import { orderBy, query, where } from 'firebase/firestore';
import { DateTime } from 'luxon';
import Calendar from 'components/Calendar';
import CalendarEventDetails from 'components/CalendarEventDetails';
import EmptyMessage from 'components/EmptyMessage';
import ChevronLeft from 'components/icons/chevronLeft';
import ChevronRight from 'components/icons/chevronRight';
import Plus from 'components/icons/plus';
import SettingIcon from 'components/icons/setting';
import LoadingShape from 'components/LoadingShape';
import { Customer } from 'data/firestore/agent/customer/types';
import useCalendars from 'data/firestore/agent/calendar/useCollection';
import useEventStatuses from 'data/firestore/agent/calendareventstatus/useCollection';
import useEvents from 'data/firestore/agent/calendarevent/useCollection';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { getFormatter } from 'data/services/date/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import useAgentTimeZone from 'hooks/useAgentTimeZone';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import { ADD_RESCHEDULE_CALENDAR_EVENT, CALENDAR_EVENT_DETAILS } from '../../../constants';
import AddRescheduleCalendarEventModal from './AddRescheduleCalendarEventModal';
import getTitle from './helpers/getTitle';
import {
    Container,
    LeftColumn,
    RightColumn,
    Header,
    HeaderItems,
    Divider,
    StyledAccordion,
    StyledAccordionSection,
    StyledButtonIcon,
    Title,
    StyledButtonGroupPicker,
    StyledCheckBoxGroup,
    LeftColumnContent,
    StyledButton,
} from './styled';
import { ViewContextType } from '../types';

const CalendarViewPage = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();
    const match = useMatch(':agentId/calendar/view/:viewType');
    const timeZone = useAgentTimeZone();
    const [
        selectedDate,
        setSelectedDate,
    ] = useState(DateTime.now().setZone(timeZone).toJSDate());

    const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
    const connectedAddRescheduleModalProps = useConnectModal(ADD_RESCHEDULE_CALENDAR_EVENT);
    const [
        openAddRescheduleNewEventForm, closeAddRescheduleEventForm,
    ] = useOpenModal(ADD_RESCHEDULE_CALENDAR_EVENT);

    const connectedDetailsModalProps = useConnectModal(CALENDAR_EVENT_DETAILS);
    const [, closeEventDetailsModal] = useOpenModal(CALENDAR_EVENT_DETAILS);

    const queryClient = useQueryClient();

    const formattedDate = useMemo(() => getTitle({
        date: selectedDate,
        viewType: match?.params.viewType as any,
        timezone: timeZone,
    }), [match?.params.viewType, selectedDate, timeZone]);

    const { data: calendars = [], isLoading: isLoadingCalendars } = useCalendars(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
        },
    );

    const { data: eventStatuses = [], isLoading: isLoadingEventStatuses } = useEventStatuses(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
        },
    );

    const { data: events = [], isLoading: isLoadingEvents } = useEvents(
        agentId,
        {
            disabled: !agentId,
            track: [agentId, selectedDate.toISOString()],
            listQueryFn: (ref) => {
                const from = new Date(selectedDate);
                from.setHours(0, 0, 0, 1);
                const to = new Date(from.getTime() + (24 * 60 * 60 * 1000) - 2);
                return query(
                    ref,
                    where('removed', '==', false),
                    where('startAt', '>=', from),
                    where('startAt', '<=', to),
                    orderBy('startAt', 'asc'),
                );
            },
        },
    );

    const [highlightedDays, setHighlightedDays] = useState<Date[]>([]);
    const { data: pendingEvents = [] } = useEvents(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
            listQueryFn: (ref) => query(
                ref,
                where('removed', '==', false),
                where('status.name', '==', 'pending-approval'),
                where('status.removable', '==', false),
            ),
        },
    );

    useEffect(() => {
        const newHighlightedDays = new Set<Date>();
        const calendarsIds = calendars.map((calendar) => calendar.id);
        pendingEvents.filter(
            (event) => calendarsIds.includes(event.calendarId),
        ).forEach((event) => {
            const date = new Date(event.startAt);
            newHighlightedDays.add(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setHighlightedDays([...newHighlightedDays]);
    }, [pendingEvents, calendars]);

    useEffect(
        () => {
            const { eventId = '', isOpen: isOpenDetails } = connectedDetailsModalProps;
            const eventExists = events.some((event) => event.id === eventId);
            if (!eventExists && isOpenDetails) {
                closeEventDetailsModal();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [events],
    );

    const { mutateAsync: addNewEvent } = useHttpMutation({
        method: 'POST',
        onSuccess: closeAddRescheduleEventForm,
    });

    const { mutateAsync: rescheduleEvent } = useHttpMutation({
        method: 'POST',
        onSuccess: () => {
            closeEventDetailsModal();
            closeAddRescheduleEventForm();
        },
    });

    const showPrevDay = useCallback(() => {
        const date = DateTime.fromJSDate(selectedDate);
        switch (match?.params.viewType) {
            case 'week':
                setSelectedDate(date.minus({ week: 1 }).toJSDate());
                break;
            case 'month':
                setSelectedDate(date.minus({ month: 1 }).toJSDate());
                break;
            default:
                setSelectedDate(date.minus({ day: 1 }).toJSDate());
                break;
        }
    }, [match?.params.viewType, selectedDate]);

    const showNextDay = useCallback(() => {
        const date = DateTime.fromJSDate(selectedDate);
        switch (match?.params.viewType) {
            case 'week':
                setSelectedDate(date.plus({ week: 1 }).toJSDate());
                break;
            case 'month':
                setSelectedDate(date.plus({ month: 1 }).toJSDate());
                break;
            default:
                setSelectedDate(date.plus({ day: 1 }).toJSDate());
                break;
        }
    }, [match?.params.viewType, selectedDate]);

    const calendarsList = useMemo(() => calendars.map((calendar) => ({
        value: calendar.id,
        label: calendar.name,
        disabled: false,
    })), [calendars]);

    const eventStatusesList = useMemo(() => eventStatuses.map((eventStatus) => ({
        value: eventStatus.id,
        label: eventStatus.name,
        disabled: false,
    })), [eventStatuses]);

    const contextValue = useMemo(
        () => ({
            selectedDate,
            selectedEvent,
            isLoading: isLoadingCalendars || isLoadingEventStatuses || isLoadingEvents,
            calendars: calendars.filter((calendar) => selectedCalendars.includes(calendar.id)),
            events: events.filter(
                (event) => (
                    selectedCalendars.includes(event.calendarId)
                        && (
                            !event.status?.id
                                || selectedStatuses.includes(event.status?.id as string)
                        )
                ),
            ),
            timeZone,
            onEventSelect: setSelectedEvent,
        }) satisfies ViewContextType,
        [
            selectedDate,
            selectedEvent,
            events,
            calendars,
            isLoadingCalendars,
            isLoadingEventStatuses,
            isLoadingEvents,
            selectedCalendars,
            selectedStatuses,
            timeZone,
        ],
    );

    const handleAddEvent = useCallback(
        async (values: Record<string, unknown>) => {
            const {
                calendarId, eventTypeId, customer, ...params
            } = values;
            const { startAt } = params;
            const { id: peopleId } = customer as Customer;
            const customerName = getDisplayName(customer);

            showAppSpinner();
            try {
                await addNewEvent({
                    pathname: `/agents/${agentId}/calendars/${calendarId}/events`,
                    body: {
                        ...params,
                        peopleId,
                        typeId: eventTypeId,
                    },
                });

                const date = getFormatter('en-US', { month: 'long', day: 'numeric' }).format(new Date(startAt as string));
                const time = getFormatter(
                    'en-US',
                    {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                    },
                ).format(new Date(startAt as string));
                showAppNotification({
                    title: 'Event Confirmed',
                    description: `A event with ${customerName} has been successfully scheduled for ${date} at ${time}.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });

                queryClient.invalidateQueries(
                    [
                        'calendar-available-event-slots',
                        agentId,
                        calendarId,
                        eventTypeId,
                        (startAt as string).slice(0, 10),
                    ],
                    {
                        refetchInactive: false,
                    },
                );
            }
            hideAppSpinner();
        },
        [addNewEvent, agentId, queryClient],
    );

    const handleRescheduleEvent = useCallback(
        async (values: Record<string, unknown>) => {
            const {
                eventId, calendarId, eventTypeId, customer, startAt,
            } = values;
            const customerName = getDisplayName(customer);

            showAppSpinner();
            try {
                await rescheduleEvent({
                    pathname: `/agents/${agentId}/calendars/${calendarId}/events/${eventId}/reschedule`,
                    body: {
                        startAt,
                    },
                });

                const date = getFormatter('en-US', { month: 'long', day: 'numeric' }).format(new Date(startAt as string));
                const time = getFormatter(
                    'en-US',
                    {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                    },
                ).format(new Date(startAt as string));
                showAppNotification({
                    title: 'Rescheduling Successful',
                    description: `The event for ${customerName} is now set for ${date} at ${time}.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });

                queryClient.invalidateQueries(
                    [
                        'calendar-available-event-slots',
                        agentId,
                        calendarId,
                        eventTypeId,
                        (startAt as string).slice(0, 10),
                    ],
                    {
                        refetchInactive: false,
                    },
                );
            }
            hideAppSpinner();
        },
        [rescheduleEvent, agentId, queryClient],
    );

    const handleaddNewEvent = useCallback(
        async (values: Record<string, unknown>) => {
            const { mode = 'create' } = connectedAddRescheduleModalProps;
            return (
                mode === 'create'
                    ? handleAddEvent(values)
                    : handleRescheduleEvent(values)
            );
        },
        [connectedAddRescheduleModalProps, handleAddEvent, handleRescheduleEvent],
    );

    const handleCloseEventDetails = useCallback(
        () => {
            setSelectedEvent(null);
            closeEventDetailsModal();
        },
        [closeEventDetailsModal],
    );

    useEffect(
        () => {
            setSelectedCalendars(calendars.map((calendar) => calendar.id));
        },
        [calendars],
    );

    useEffect(
        () => {
            setSelectedStatuses(eventStatuses.map((eventStatus) => eventStatus.id));
        },
        [eventStatuses],
    );

    return (
        <>
            <Container>
                <LeftColumn>
                    <Header>
                        <StyledButton
                            variant="brand"
                            borderRadius="semi-rounded"
                            disabled={calendars.length === 0}
                            onClick={() => openAddRescheduleNewEventForm({})}
                        >
                            <Plus className="rainbow-m-right_small" />
                            Add Event
                        </StyledButton>
                    </Header>
                    <LeftColumnContent>
                        <Calendar
                            value={selectedDate}
                            onChange={setSelectedDate}
                            highlightedDays={highlightedDays}
                        />
                        <Divider />
                        <StyledAccordion
                            multiple
                            activeSectionNames={['calendars', 'statuses']}
                        >
                            <StyledAccordionSection name="calendars" label="Calendars">
                                <RenderIf isTrue={isLoadingCalendars}>
                                    <LoadingShape width="30%" />
                                    <LoadingShape width="40%" />
                                    <LoadingShape width="10%" />
                                </RenderIf>
                                <RenderIf isTrue={!isLoadingCalendars && calendars.length > 0}>
                                    <StyledCheckBoxGroup
                                        hideLabel
                                        options={calendarsList}
                                        value={selectedCalendars}
                                        onChange={setSelectedCalendars}
                                    />
                                </RenderIf>
                                <RenderIf isTrue={!isLoadingCalendars && calendars.length === 0}>
                                    <EmptyMessage message="There is no calendar" />
                                </RenderIf>
                            </StyledAccordionSection>
                            <StyledAccordionSection name="statuses" label="Status">
                                <RenderIf isTrue={isLoadingEventStatuses}>
                                    <LoadingShape width="30%" />
                                    <LoadingShape width="40%" />
                                    <LoadingShape width="10%" />
                                </RenderIf>
                                <RenderIf
                                    isTrue={!isLoadingEventStatuses && eventStatuses.length > 0}
                                >
                                    <StyledCheckBoxGroup
                                        hideLabel
                                        options={eventStatusesList}
                                        value={selectedStatuses}
                                        onChange={setSelectedStatuses}
                                    />
                                </RenderIf>
                                <RenderIf
                                    isTrue={!isLoadingEventStatuses && eventStatuses.length === 0}
                                >
                                    <EmptyMessage message="There is no calendar status" />
                                </RenderIf>
                            </StyledAccordionSection>
                        </StyledAccordion>
                    </LeftColumnContent>
                </LeftColumn>
                <RightColumn>
                    <Header className="rainbow-m-bottom_medium">
                        <HeaderItems>
                            <StyledButtonIcon
                                icon={<ChevronLeft />}
                                onClick={showPrevDay}
                            />
                            <StyledButtonIcon
                                icon={<ChevronRight />}
                                onClick={showNextDay}
                            />
                            <Title>
                                {formattedDate}
                            </Title>
                            <StyledButtonIcon
                                icon={<SettingIcon />}
                                tooltip="Calendar Settings"
                                onClick={() => navigate('../settings')}
                            />
                            <StyledButtonGroupPicker
                                borderRadius="semi-rounded"
                                value={match?.params?.viewType}
                                onChange={(viewType) => {
                                    navigate(viewType as string);
                                }}
                            >
                                <ButtonOption label="Day" name="day" />
                                {/* <ButtonOption label="Week" name="week" />
                                <ButtonOption label="Month" name="month" /> */}
                                <ButtonOption label="List" name="list" />
                            </StyledButtonGroupPicker>
                        </HeaderItems>
                    </Header>
                    <Divider />
                    <Outlet context={contextValue} />
                </RightColumn>
            </Container>
            <AddRescheduleCalendarEventModal
                {...connectedAddRescheduleModalProps}
                agentId={agentId}
                onRequestClose={closeAddRescheduleEventForm}
                onComplete={handleaddNewEvent}
            />
            <CalendarEventDetails
                {...connectedDetailsModalProps}
                agentId={agentId}
                calendars={contextValue.calendars}
                onRequestClose={handleCloseEventDetails}
            />
        </>
    );
};

export default CalendarViewPage;
