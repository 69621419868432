import { IconProps } from 'types';

const Divider = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        width={24}
        height={24}
    >
        <title>{title}</title>
        <path d="M3 12V12.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 12H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21 12V12.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Divider;
