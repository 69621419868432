import React from 'react';
import { useMatch } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { StyledLink, Container, StyledBadge } from './styled';

interface ItemProps {
    to: string;
    icon: React.ReactNode;
    label: string;
    isShrinked?: boolean;
    notifications?: string | number;
    customBadge?: React.ReactNode;
}

const Item = (props: ItemProps) => {
    const {
        to, icon, label, isShrinked, notifications, customBadge,
    } = props;
    const match = useMatch(`${to}/*`);
    return (
        <Container className="rainbow-p-left_x-small rainbow-p-right_x-small">
            <StyledLink
                to={to}
                isActive={match !== null}
                notifications={notifications}
                isShrinked={isShrinked}
            >
                <div>
                    {icon}
                    <RenderIf isTrue={!isShrinked}>
                        <span className="rainbow-m-left_medium">{label}</span>
                    </RenderIf>
                    <RenderIf isTrue={customBadge && !isShrinked}>
                        {customBadge}
                    </RenderIf>
                </div>
                <RenderIf isTrue={customBadge && !isShrinked}>
                    <StyledBadge variant="brand">{notifications}</StyledBadge>
                </RenderIf>
            </StyledLink>
        </Container>
    );
};

export default Item;
