import { ReactNode } from 'react';
import { EmptyMessageContainer, EmptyMessage as EmptyMessageLabel } from './styled';

interface Params {
    className?: string;
    message: string | ReactNode;
}

const EmptyMessage = ({
    className,
    message,
}: Params) => {
    if (typeof message === 'string') {
        return (
            <EmptyMessageContainer className={className}>
                <EmptyMessageLabel>{message}</EmptyMessageLabel>
            </EmptyMessageContainer>
        );
    }

    return (
        <div className={className}>
            {message}
        </div>
    );
};

export default EmptyMessage;
