import { RenderIf } from 'react-rainbow-components';
import TimeIcon from 'components/icons/time';
import HelpIcon from 'components/icons/help';
import FloppyIcon from 'components/icons/floppy';
import FlaskIcon from 'components/icons/flask';
import ValidationStatus, { ValidationStatusProps } from 'components/ValidationStatus';
import {
    StyledFooter,
    FooterButton,
    LeftButtonsContainer,
} from './styled';

interface FormFooterProps {
    formId?: string;
    submitLoading?: boolean;
    onOpenHistory?: (value: boolean) => void;
    historyOpen?: boolean;
    onOpenHelp?: (value: boolean) => void;
    helpOpen?: boolean;
    onsubmit?: () => void;
    validationStatus?: 'valid' | 'invalid' | 'loading';
    validationErrors?: number;
    disableTesting?: boolean;
    testOpen?: boolean;
    onOpenTest?: () => void;
}

const FormFooter = ({
    formId,
    submitLoading = false,
    onOpenHistory = () => {},
    historyOpen = false,
    onOpenHelp = () => {},
    helpOpen = false,
    onsubmit = () => {},
    validationStatus,
    validationErrors,
    disableTesting = false,
    testOpen = false,
    onOpenTest = () => {},
}: FormFooterProps) => (
    <StyledFooter>
        <LeftButtonsContainer>
            <RenderIf isTrue={validationStatus}>
                <ValidationStatus status={validationStatus as ValidationStatusProps['status']} errors={validationErrors} />
            </RenderIf>
        </LeftButtonsContainer>
        <div className="rainbow-m-left_medium">
            <FooterButton
                borderRadius="semi-square"
                onClick={() => onOpenHelp(!historyOpen)}
                disabled={helpOpen}
                icon={<HelpIcon />}
                title="Help"
            />
            <FooterButton
                borderRadius="semi-square"
                onClick={() => onOpenHistory(!historyOpen)}
                disabled={historyOpen}
                icon={<TimeIcon />}
                title="History"
            />
            <FooterButton
                borderRadius="semi-square"
                disabled={disableTesting || testOpen}
                icon={<FlaskIcon />}
                onClick={() => onOpenTest()}
                title="Testing"
            />
            <FooterButton
                type="submit"
                variant="base"
                form={formId}
                borderRadius="semi-square"
                onClick={() => onsubmit()}
                disabled={validationStatus !== 'valid' || submitLoading}
                icon={<FloppyIcon />}
                title="Save Changes"
            />
        </div>
    </StyledFooter>
);

export default FormFooter;
