import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { limit, query, where } from 'firebase/firestore';
import TasksInCategory from 'components/TasksInCategory';
import LoadingShape from 'components/LoadingShape';
import TasksCategoriesFilter from 'components/TasksCategoriesFilter';
import useTasks from 'data/firestore/inbox/useCollection';
import useStoredValue from 'data/hooks/useStoredValue';
import useTaskTopics from './hooks/useTaskTopics';
import { SELECTED_TOPICS_STORAGE_KEY } from '../../constants';
import EmptyTasks from './empty';
import { Container } from './styled';

interface TasksOpenedProps {
    searchQuery?: string;
}

const TasksOpened = ({ searchQuery }: TasksOpenedProps) => {
    const { agentId = '' } = useParams();
    const { topicsWithTasks, mappedTasks, isLoading } = useTaskTopics({ searchQuery });

    const { data: existingTasks = [], isLoading: isLoadingExistingTasks } = useTasks({
        listQueryFn: (ref) => query(ref, where('agentId', '==', agentId), limit(1)),
        disabled: !agentId,
    });

    // If no topic is selected, show all topics with tasks
    const { value: selectedTopicIds = [], save: setSelectedTopicIds } = useStoredValue<string[]>(
        SELECTED_TOPICS_STORAGE_KEY,
    );
    const visibleTopics = useMemo(() => (selectedTopicIds.length > 0
        ? topicsWithTasks.filter((t) => selectedTopicIds.includes(t.id))
        : topicsWithTasks), [selectedTopicIds, topicsWithTasks]);

    const topicsSections = useMemo(
        () => visibleTopics.map(
            (topic) => (
                <TasksInCategory
                    key={topic.id}
                    topicId={topic.id}
                    title={topic.displayName || topic.name}
                    tasks={mappedTasks[topic.id] || []}
                />
            ),
        ),
        [mappedTasks, visibleTopics],
    );

    const isEmpty = !topicsWithTasks.length;
    if (isLoading || isLoadingExistingTasks) {
        // TODO: loading screen
        return (
            <Container>
                <div className="rainbow-flex-column">
                    <LoadingShape width="50%" height="1rem" />
                    <LoadingShape width="30%" height="1rem" />
                    <LoadingShape width="60%" height="1rem" />
                </div>
            </Container>
        );
    }
    if (isEmpty) {
        return <EmptyTasks searchQuery={searchQuery} hadTasks={existingTasks.length > 0} />;
    }

    return (
        <Container>
            <TasksCategoriesFilter
                categories={topicsWithTasks}
                value={selectedTopicIds}
                onChange={setSelectedTopicIds}
            />
            {topicsSections}
        </Container>
    );
};

export default TasksOpened;
