import { forwardRef, useMemo } from 'react';
import { getFormatter } from 'data/services/date/formatter';
import addDays from '../helpers/addDays';
import {
    Container,
    Hours,
    Days,
    Day,
} from './styled';

interface HeaderProps {
    week: Date;
    locale?: string;
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(
    (props, ref) => {
        const { week, locale = 'en-US' } = props;

        const formatter = getFormatter(
            locale,
            {
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
            },
        );

        const days = useMemo(
            () => Array.from(Array(7), (_value, index) => {
                const day = addDays(week, index);
                return (
                    <Day key={index}>{formatter.format(day)}</Day>
                );
            }),
            [formatter, week],
        );

        return (
            <Container role="presentation">
                <Hours />
                <Days ref={ref}>
                    <div />
                    {days}
                </Days>
                <div />
            </Container>
        );
    },
);

export default Header;
