import { useState, useEffect } from "react";
import { FirestoreError } from "firebase/firestore";
import get from "./get";
import { Llm } from "./types";
import { EntityGet, HookOpts, HookReturnDoc } from "../../types";

export type LlmUseDocHook<T> = (
  agentId: string,
  id: string,
  opts?: HookOpts
) => HookReturnDoc<T>;

const useDocOnce: LlmUseDocHook<EntityGet<Llm>> = (agentId, id, opts = {}) => {
  const [data, setData] = useState<EntityGet<Llm> | null>(null);
  const [error, setError] = useState<FirestoreError | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { disabled = false } = opts;
  useEffect(() => {
    if (!disabled) {
      setLoading(true);
      (async () => {
        try {
          const doc = await get(agentId, id);
          setData(doc);
        } catch (err) {
          if (err instanceof FirestoreError) {
            setError(err);
          } else {
            setError({
              code: "unknown",
              message: "Unknown error",
              name: "Unknown error",
            });
          }
        }
        setLoading(false);
      })();
    }
  }, [id, disabled]);
  return { data, error, isLoading };
};

export default useDocOnce;
