import { useParams, useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import CustomerProfile from 'components/CustomerProfile';
import Close from 'components/icons/close';
import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import { CloseButton, Container, Footer } from './styles';

interface CustomerPanelProps {
    customer: EntityGet<Customer>;
    onRequestClose?: () => void;
}

const CustomerPanel = ({ customer, onRequestClose }: CustomerPanelProps) => {
    const { agentId = '' } = useParams();
    const navigate = useNavigate();

    return (
        <Container>
            <CloseButton icon={<Close />} onClick={onRequestClose} />
            <CustomerProfile agentId={agentId} customerId={customer.id} />
            <Footer>
                <Button
                    label="Open Customer"
                    variant="brand"
                    borderRadius="semi-rounded"
                    onClick={() => navigate(`/${agentId}/customers/${customer.id}`)}
                />
            </Footer>
        </Container>
    );
};

export default CustomerPanel;
