const pronounLabelMap: Record<string, string> = {
    he: 'He/Him',
    she: 'She/Her',
    they: 'They/Them',
    ze: 'Ze/Zir',
    unknown: 'Prefer Not to Say',
};

export default function formatPronoun(pronoun: string = ''): string {
    return pronounLabelMap[pronoun.toLowerCase()] || pronoun;
}
