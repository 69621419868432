import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getFromLocalStorage, saveToLocalStorage } from './localStorage';

const useStoredValue = <T = string>(key: string) => {
    const { agentId = '' } = useParams();
    const agentKey = `${agentId}-${key}`;
    const [value, updateValue] = useState<T | undefined>(getFromLocalStorage<T>(agentKey));

    useEffect(() => {
        updateValue(getFromLocalStorage<T>(agentKey));

        function storageEventHandler(event: StorageEvent) {
            if (event.key === agentKey) {
                updateValue(getFromLocalStorage<T>(agentKey));
            }
        }

        window.addEventListener('storage', storageEventHandler, false);

        return () => {
            window.removeEventListener('storage', storageEventHandler);
        };
    }, [agentKey]);

    const get = useCallback(() => getFromLocalStorage<T>(agentKey), [agentKey]);

    const save = useCallback(
        (newValue: T) => saveToLocalStorage<T>(agentKey, newValue),
        [agentKey],
    );

    return useMemo(
        () => ({
            value,
            get,
            save,
        }),
        [value, get, save],
    );
};

export default useStoredValue;
