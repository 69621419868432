import {
    useCallback, useContext, useEffect, useMemo,
} from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Form from 'components/InstructionTestingFormStep';
import context from 'components/InstructionTestingLayout/context';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import LoadingShape from 'components/LoadingShape';
import PhoneNumberPicker from 'components/PhoneNumberPicker';
import { Agent } from 'data/firestore/agent/types';
import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import useAgentData from 'hooks/useAgentData';
import getDate from '../helpers/getDate';
import getLanguage from '../helpers/getLanguage';
import {
    FieldsContainer, FieldLabel, Row, StyledCustomerPicker, StyledDatePicker,
} from './styled';
import { VoipInstructionTestingContext } from '../types';

const mapFormValuesToContext = (values: Record<string, unknown>) => {
    const {
        agent, phoneNumber, customer = {}, date,
    } = values;

    const {
        companyName, companyAddress, aiOutputLanguage,
    } = agent as Agent;

    const {
        firstName, lastName, alias, phoneNumber: customerPhoneNumber, bio, email,
    } = (customer || {}) as Customer;

    const customerData: Record<string, unknown> = {
        alias,
        firstName,
        lastName,
        email,
        phoneNumber: customerPhoneNumber,
        bio,
    };

    return {
        company: {
            name: companyName,
            address: companyAddress.formattedAddress,
            timezone: companyAddress.timezone,
        },
        customer: Object.keys(customerData).reduce(
            (data, key) => ({
                ...data,
                [key]: customerData[key] || '',
            }),
            {},
        ),
        language: getLanguage((phoneNumber as Record<string, string>)?.language),
        outputLanguage: getLanguage(aiOutputLanguage),
        currentDate: getDate(date as Date, companyAddress.timezone),
    };
};

const Fields = ({ agent }: { agent?: EntityGet<Agent> | null; }) => {
    const { dirtyFieldsSinceLastSubmit, submitting } = useFormState();
    const { submit } = useForm();

    const shouldSubmit = useMemo(
        () => Object.keys(dirtyFieldsSinceLastSubmit).length > 0 && !submitting,
        [dirtyFieldsSinceLastSubmit, submitting],
    );

    useEffect(
        () => {
            if (shouldSubmit) submit();
        },
        [shouldSubmit, submit],
    );

    return (
        <FieldsContainer>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={PhoneNumberPicker}
                    name="phoneNumber"
                    label={<FieldLabel>Select Phone Number</FieldLabel>}
                    labelAlignment="left"
                    phoneNumbers={agent?.phoneNumbers}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledCustomerPicker}
                    name="customer"
                    label={<FieldLabel>Customer</FieldLabel>}
                    labelAlignment="left"
                    agentId={agent?.id}
                    disableCreate
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledDatePicker}
                    borderRadius="semi-square"
                    name="date"
                    label={<FieldLabel>Date</FieldLabel>}
                    labelAlignment="left"
                />
            </Row>
        </FieldsContainer>
    );
};

const now = new Date();

const UseCaseView = ({
    onChange = () => {},
}: InstructionTestStepComponentProps) => {
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const { useCaseValues = {} } = useContext<VoipInstructionTestingContext>(context);

    const initialValues = useMemo(
        () => ({ date: now, ...useCaseValues }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleSubmit = useCallback(
        async (values: Record<string, unknown>) => {
            const newContext = mapFormValuesToContext({
                ...values,
                agent,
            });
            onChange({ context: newContext, formValues: values });
        },
        [agent, onChange],
    );

    if (isLoadingAgent) {
        return (
            <FieldsContainer>
                <LoadingShape width="80%" height="10px" />
                <LoadingShape width="100%" height="10px" />
                <LoadingShape width="70%" height="10px" />
            </FieldsContainer>
        );
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
        >
            <Fields agent={agent} />
        </Form>
    );
};

export default UseCaseView;
