import {
    ComponentType, useCallback, useMemo, useState,
} from 'react';
import ListIcon from 'components/icons/list';
import PaperIcon from 'components/icons/paper';
import ChatIcon from 'components/icons/chat2';
import BulletListIcon from 'components/icons/bulletList';
import SettingIcon from 'components/icons/setting';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import { InstructionTestComponentProps } from 'components/InstructionTestingModal/types';
import InstructionTestingLayout from 'components/InstructionTestingLayout';
import { ChatMessage } from '../../../../types';
import StepCompileSystemMessage from '../views/systemMessage';
import StepContext from '../views/context';
import StepConversation from '../views/conversation';
import StepUseCase from '../views/useCase';
import StepGenerateSummary from './summary';

const stepComponentMap: Record<string, ComponentType<InstructionTestStepComponentProps>> = {
    'use-case': StepUseCase,
    conversation: StepConversation,
    context: StepContext,
    'system-message': StepCompileSystemMessage,
    summary: StepGenerateSummary,
};

interface Params extends InstructionTestComponentProps {}

const SummaryInstructionTesting = ({ template, agentId }: Params) => {
    const [currentStep, setCurrentStep] = useState('use-case');
    const [contextValue, setContextValue] = useState<Record<string, unknown>>({});
    const [isLoading, setLoading] = useState(false);

    const {
        mutateAsync: compileTemplate,
    } = useHttpMutation<Record<string, unknown>, { result: string }>({
        pathname: `/agents/${agentId}/system-message/validate`,
        method: 'POST',
    });

    const handleStepUpdate = useCallback(
        (stepName: string) => async (values: Record<string, unknown>) => {
            const newContext = contextValue;

            if (stepName === 'use-case') {
                const { context, formValues } = values;
                newContext.useCaseValues = formValues;
                newContext.templateContext = context as Record<string, unknown>;
            }

            if (stepName === 'conversation') {
                const { messages } = values;

                newContext.chatMessages = messages as ChatMessage[];
                const { templateContext } = newContext;
                const {
                    numAgentTurns,
                    numUserTurns,
                } = (messages as ChatMessage[]).reduce(
                    (result, { role }) => ({
                        numAgentTurns: (role === 'assistant' ? result.numAgentTurns + 1 : result.numAgentTurns),
                        numUserTurns: (role === 'assistant' ? result.numUserTurns : result.numUserTurns + 1),
                    }),
                    {
                        numAgentTurns: 0,
                        numUserTurns: 0,
                    },
                );
                newContext.templateContext = {
                    ...templateContext as Record<string, unknown>,
                    conversation: (messages as ChatMessage[]).map(
                        ({ role, content }) => ({ role, content }),
                    ),
                    numAgentTurns,
                    numUserTurns,
                };
                newContext.generatedSummary = undefined;

                try {
                    setLoading(true);
                    const { result } = await compileTemplate({
                        body: {
                            agentId,
                            template,
                            context: newContext.templateContext,
                        },
                    });
                    newContext.compiledTemplate = result;
                } catch (error) {
                    // no catch
                }
                setLoading(false);
            }

            if (stepName === 'summary') {
                newContext.generatedSummary = values.generatedSummary as Record<string, string>;
            }

            setContextValue({ ...newContext });
        },
        [agentId, compileTemplate, contextValue, template],
    );

    const StepComponent = useMemo(() => stepComponentMap[currentStep], [currentStep]);

    return (
        <InstructionTestingLayout
            activeStep={currentStep}
            steps={[
                {
                    name: 'use-case',
                    label: 'Use Case',
                    icon: <SettingIcon />,
                },
                {
                    name: 'conversation',
                    label: 'Conversation',
                    icon: <ChatIcon />,
                },
                {
                    name: 'context',
                    label: 'Context',
                    icon: <ListIcon />,
                    disabled: !contextValue.compiledTemplate,
                },
                {
                    name: 'system-message',
                    label: 'System Message',
                    icon: <PaperIcon />,
                    disabled: !contextValue.compiledTemplate,
                },
                {
                    name: 'summary',
                    label: 'Summary',
                    icon: <BulletListIcon />,
                    disabled: !contextValue.compiledTemplate,
                },
            ]}
            onStepChange={setCurrentStep}
            contextValue={contextValue}
        >
            <StepComponent
                agentId={agentId}
                systemMessageTemplate={template}
                onChange={handleStepUpdate(currentStep)}
                isLoading={isLoading}
            />
        </InstructionTestingLayout>
    );
};

export default SummaryInstructionTesting;
