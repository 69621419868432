import { IconProps } from 'types';

const Meta = ({ className, title }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M2.59889 14.5127C2.59889 15.4291 2.80003 16.1327 3.06293 16.5584C3.40762 17.1159 3.92174 17.3521 4.44587 17.3521C5.12191 17.3521 5.74035 17.1843 6.93217 15.536C7.88696 14.2148 9.01202 12.3603 9.76901 11.1977L11.051 9.22799C11.9415 7.86006 12.9722 6.33941 14.154 5.30867C15.1188 4.46738 16.1596 4 17.207 4C18.9656 4 20.6406 5.01906 21.9226 6.93031C23.3256 9.02351 24.0066 11.66 24.0066 14.3809C24.0066 15.9983 23.6878 17.1868 23.1453 18.1257C22.6211 19.0338 21.5996 19.941 19.8811 19.941V17.3521C21.3525 17.3521 21.7198 16 21.7198 14.4526C21.7198 12.2476 21.2056 9.80053 20.0731 8.05202C19.2694 6.8118 18.2278 6.05397 17.0818 6.05397C15.8425 6.05397 14.8451 6.98873 13.7242 8.65545C13.1283 9.54097 12.5165 10.6201 11.8297 11.8378L11.0735 13.1774C9.55451 15.8706 9.16976 16.4841 8.41026 17.4965C7.07906 19.2692 5.94233 19.941 4.44587 19.941C2.67066 19.941 1.54811 19.1723 0.852885 18.0139C0.285351 17.07 0.0065918 15.8314 0.0065918 14.4201L2.59889 14.5127Z" fill="#0081FB" />
        <path d="M2.05054 7.11309C3.23902 5.28112 4.95414 4 6.92131 4C8.06055 4 9.19312 4.33718 10.3758 5.30282C11.6694 6.3586 13.0482 8.09709 14.7683 10.9623L15.3851 11.9905C16.874 14.471 17.7211 15.7471 18.2169 16.3489C18.8545 17.1217 19.3011 17.3521 19.8811 17.3521C21.3525 17.3521 21.7198 16 21.7198 14.4526L24.0066 14.3809C24.0066 15.9983 23.6878 17.1868 23.1453 18.1257C22.6211 19.0338 21.5996 19.941 19.8811 19.941C18.8128 19.941 17.8664 19.709 16.8198 18.7217C16.0152 17.9638 15.0746 16.6176 14.351 15.4074L12.1985 11.8119C11.1186 10.0075 10.1279 8.66212 9.5545 8.05286C8.93773 7.39769 8.14485 6.60648 6.87958 6.60648C5.85552 6.60648 4.98585 7.32508 4.25808 8.42426L2.05054 7.11309Z" fill="url(#paint0_linear_4636_15590)" />
        <path d="M6.87959 6.60648C5.85553 6.60648 4.98587 7.32508 4.25809 8.42426C3.22901 9.97747 2.59889 12.291 2.59889 14.5127C2.59889 15.4291 2.80003 16.1327 3.06293 16.5584L0.852885 18.0139C0.285351 17.07 0.0065918 15.8314 0.0065918 14.4201C0.0065918 11.8537 0.711001 9.17875 2.05055 7.11309C3.23903 5.28112 4.95415 4 6.92132 4L6.87959 6.60648Z" fill="url(#paint1_linear_4636_15590)" />
        <defs>
            <linearGradient id="paint0_linear_4636_15590" x1="5.09769" y1="13.7649" x2="21.6229" y2="14.5995" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0064E1" />
                <stop offset="0.4" stopColor="#0064E1" />
                <stop offset="0.83" stopColor="#0073EE" />
                <stop offset="1" stopColor="#0082FB" />
            </linearGradient>
            <linearGradient id="paint1_linear_4636_15590" x1="3.76233" y1="15.6011" x2="3.76233" y2="9.50842" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0082FB" />
                <stop offset="1" stopColor="#0064E0" />
            </linearGradient>
        </defs>
    </svg>
);
export default Meta;
