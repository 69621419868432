import { IconProps } from 'types';

const ExportIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        viewBox="0 0 18 18"
        width={18}
        height={18}
        fill="none"
    >
        <title>{title}</title>
        <path d="M15.75 9V11.25C15.75 11.6642 15.4142 12 15 12H3C2.58579 12 2.25 11.6642 2.25 11.25V3.75C2.25 3.33579 2.58579 3 3 3H9.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.25 15H12.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.75 12V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 12V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.75 3H15.75V6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 6.75L15.75 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

    </svg>
);

export default ExportIcon;
