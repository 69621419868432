import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    height: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 1.5rem;
    align-self: stretch;
    flex: 1;
`;

export const TopBar = styled.div`
    display: flex;
    position: relative;
    align-self: stretch;
    padding: 0.25rem 2rem 1rem;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const Footer = styled.div<{ align?: string; }>`
    display: flex;
    padding: 0.5rem 1.25rem 0.5rem 2.25rem;
    justify-content: ${(props) => props.align || 'flex-start'};
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
`;

export const CenteredButton = styled(Button)`
    flex: 0;
    align-self: center;
    margin: auto;
`;

export const Title = styled.h2`
    padding: 0.5rem 0 0;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; 
`;

export const Text = styled.p`
    padding: 0.25rem 0 1.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
`;
