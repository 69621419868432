import styled from 'styled-components';
import { Tab, Tabset } from 'react-rainbow-components';
import UserAvatar from 'components/UserAvatar';
import SaraAvatar from 'components/icons/saraAvatar';
import {
    GREY_500,
    CARD_SHADOW,
    WHITE,
    GREY_300,
    GREY_400,
    PURPLE_100,
    PURPLE_800,
    BACKGROUND,
} from '../../../constants';

export const PageTitle = styled.h1`
    margin: 0;
    color: ${GREY_500};
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 2px;
`;

export const StyledAvatar = styled(UserAvatar)`
    width: 2rem;
    height: 2rem;
    font-size: 0.75rem;

`;

export const ItemContent = styled.div`
    background-color: ${BACKGROUND};
    height: 100%;
    border-radius: 0 0 0.375rem 0;
    padding: 1.25rem;
    overflow: scroll;
`;

export const SendNoteContainer = styled.div`
    border-radius: 0 0 0.375rem 0;
    padding: 0 1.25rem 1.25rem;
`;

export const Card = styled.div`
    box-shadow: ${CARD_SHADOW};
    border-radius: 1rem;
    background: ${WHITE};
    overflow: hidden;
`;

export const CustomerName = styled.h3`
    font-size: 0.9375rem;
    color: ${GREY_500};
    line-height: 1.3125rem;
`;

export const SecondaryText = styled.h4`
    font-size: 0.8125rem;
    line-height: 1.25rem;
    color: ${GREY_300};
    font-weight: 300;
`;

export const Date = styled.p`
    font-size: 0.8125rem;
    line-height: 1.25rem;
    text-align: right;
    color: ${GREY_300};
    flex-shrink: 0;
    font-weight: 300;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
`;

export const SummaryContainer = styled.div`
    display: flex;
    gap: 1rem;
    padding: 0.88rem 0 2rem 0;
`;

export const PrimaryText = styled.h3`
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: ${GREY_500};
`;

export const Description = styled.p`
    font-size: 0.9375rem;
    line-height: 1.5;
    color: ${GREY_400};
    font-weight: 300;
`;

export const AccordionHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CardHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.95rem 1.25rem;
`;

export const ItemPrimaryBar = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 1.125rem 0.75rem 0.95rem 0.5rem;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const StyledSecondaryBar = styled.header`
    display: flex;
    align-items: flex-end;
    padding: 0.25rem 1.25rem 0;
    margin: 0 0 -1px;
    width: 100%;
`;

export const StyledTabset = styled(Tabset)`
    position: unset;
`;

export const StyledTab = styled(Tab)`
    button {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        padding: 0.5rem 1.25rem;
    }

    button::before {
        border-radius: 0px;
        height: 0.13rem;
    }
`;

export const MessageContainer = styled.div`
    background-color: ${PURPLE_100};
    border-radius: 0rem 0.75rem 0.75rem 0.75rem;
    padding: 1rem;
    color: ${PURPLE_800};
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${PURPLE_100} transparent transparent transparent;
            left: 0;
            top: 0;
            transform: translateX(-50%);
        }
`;

export const MessageTitle = styled.h3`
    color: ${PURPLE_800};
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 600;
`;

export const MessageDescription = styled.p`
    color: ${PURPLE_800};
    font-size: 0.9375rem;
    line-height: 1.5;
    font-weight: 400;
    font-family: 'Public Sans', sans-serif;
    margin: 0;
    width: fit-content;
    white-space: break-spaces;
`;

export const StyledSaraAvatar = styled(SaraAvatar)`
    flex-shrink: 0;
    width: 44px;
    height: fit-content;
`;

export const SummaryHeader = styled.div`
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
`;

export const MessageTime = styled.span`
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1.5rem;
    display: flex;
    gap: 3px;
    align-items: center;
    color: ${GREY_400};
`;

export const MessageName = styled.h3`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    white-space: nowrap;
    color: ${GREY_400};
`;

export const BubbleContainer = styled.div`
    width: 100%;
`;
