import { useContext } from 'react';
import ReactJson from 'react-json-view';
import context, { Context } from 'components/InstructionTestingLayout/context';
import { Container, JsonView } from './styled';

const ContextView = () => {
    const { templateContext = {} } = useContext<Context>(context);

    return (
        <Container>
            <JsonView>
                <ReactJson
                    src={templateContext}
                    displayObjectSize={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                />
            </JsonView>
        </Container>
    );
};

export default ContextView;
