import { getAnalytics } from 'firebase/analytics';
import { app, auth, db } from 'data/firestore/firebase';
import { getStorage } from 'firebase/storage';

const analytics = getAnalytics(app);
const storage = getStorage(app);

export {
    app, auth, db, analytics, storage,
};
