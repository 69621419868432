import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { CheckboxGroupProps } from 'react-rainbow-components/components/CheckboxGroup';
import LoadingShape from 'components/LoadingShape';
import useEventStatus from 'data/firestore/agent/calendareventstatus/useCollection';
import { StyledCheckBoxGroup } from './styled';

interface Params extends Omit<CheckboxGroupProps, 'value' | 'onChange'> {
    agentId?: string;
    value?: Record<string, unknown>[];
    onChange?: (values: Record<string, unknown>[]) => void;
}

const CalendarEventStatusSelect = ({
    agentId = '',
    ...props
}: Params) => {
    const {
        value: valueInProps,
        onChange = () => {},
        ...rest
    } = useReduxForm(props);

    const { data = [], isLoading } = useEventStatus(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    const options = useMemo(
        () => data.map(
            (status) => ({
                value: status.id,
                label: status.name,
                disabled: !status.removable,
            }),
        ),
        [data],
    );

    const value = useMemo(
        () => Array.from(
            new Set([
                ...(valueInProps || []).map((status) => status.id),
                ...data.filter((status) => !status.removable).map((status) => status.id),
            ]),
        ),
        [data, valueInProps],
    );

    const handleOnChange = useCallback(
        (values: string[]) => onChange(
            (data.filter(
                (status) => values.includes(status.id),
            )) as any[],
        ),
        [data, onChange],
    );

    if (isLoading) {
        return (
            <>
                <LoadingShape width="30%" />
                <LoadingShape width="40%" />
                <LoadingShape width="10%" />
            </>
        );
    }

    return (
        <StyledCheckBoxGroup
            {...rest}
            hideLabel
            options={options}
            value={value as string[]}
            onChange={handleOnChange}
        />
    );
};

export default CalendarEventStatusSelect;
