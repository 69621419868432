import { rgbToRgba } from 'react-rainbow-components/styles/helpers/color';

const colorValues = (color: string) => {
    const div = document.createElement('div');
    div.style.backgroundColor = color;
    document.body.appendChild(div);
    let rgba = getComputedStyle(div).getPropertyValue('background-color');
    div.remove();

    if (rgba.indexOf('rgba') === -1) {
        rgba = rgbToRgba(rgba);
    }

    return (rgba.match(/[.\d]+/g) || []).map((a) => +a);
};

const getColorSteps = (colorStart: string, colorEnd: string, steps: number) => {
    const start = colorValues(colorStart);
    const end = colorValues(colorEnd);
    const opacityStep = (end[3] * 100 - start[3] * 100) / steps;
    let alpha = 0; let
        opacity = start[3] * 100;

    const colors = Array.from(new Array(steps)).map(() => {
        alpha += 1.0 / steps;
        opacity += opacityStep;

        const c = [
            Math.round(end[0] * alpha + (1 - alpha) * start[0]),
            Math.round(end[1] * alpha + (1 - alpha) * start[1]),
            Math.round(end[2] * alpha + (1 - alpha) * start[2]),
        ];

        return (
            opacity === 100
                ? `rgb(${c[0]},${c[1]},${c[2]})`
                : `rgba(${c[0]},${c[1]},${c[2]},${opacity / 100})`
        );
    });

    return colors;
};

export default getColorSteps;
