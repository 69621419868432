import SettingIcon from 'components/icons/setting';
import {
    ItemContainer,
    ItemTitleContainer,
    ItemTitle,
    StyledButtonIcon,
} from './styled';
import { ColorIcon } from './icon';

interface Params {
    name: string;
    color: string;
    onEdit?: () => void;
}

const CalendarItem = ({
    name,
    color,
    onEdit = () => {},
}: Params) => (
    <ItemContainer>
        <ItemTitleContainer>
            <ItemTitle>
                <ColorIcon color={color} />
                {name}
            </ItemTitle>
            <StyledButtonIcon
                borderRadius="semi-square"
                size="small"
                icon={<SettingIcon />}
                onClick={onEdit}
            />
        </ItemTitleContainer>
    </ItemContainer>
);

export default CalendarItem;
