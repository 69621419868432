import { getFormatter } from 'data/services/date/formatter';
import {
    TimelineContainer,
    Timeline,
    TimelineTime,
    Divider,
    TimelineCircle,
    Version,
    ModifyBy,
    TimelineContent,
    TopDivider,
} from './styled';
import { HistoryItem } from './types';

interface Props {
    version: HistoryItem;
    current?: boolean;
    onClick?: (version: HistoryItem) => void;
}
const timeFormatter = getFormatter('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
});

const versionNumberFormatter = (versionNumber: number) => {
    const paddedVersionNumber = versionNumber.toString().padStart(5, '0');
    return `Version #${paddedVersionNumber}`;
};

const TimelineItem = ({
    version,
    onClick = () => {},
    current = false,
}: Props) => (
    <TimelineContainer onClick={() => onClick(version)} isActive={current}>
        <TimelineTime isActive={current}>
            {timeFormatter.format(version.createdAt)}
        </TimelineTime>
        <Timeline>
            <TopDivider />
            <TimelineCircle isActive={current} />
            <Divider />
        </Timeline>
        <TimelineContent>
            <Version isActive={current}>
                {versionNumberFormatter(version.number)}
            </Version>
            <ModifyBy isActive={current}>
                {version.createdBy ? version.createdBy.email : 'Unknown'}
            </ModifyBy>
        </TimelineContent>
    </TimelineContainer>
);

export default TimelineItem;
