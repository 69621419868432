const languageMap: Record<string, { [key: string]: string }> = {
    en: {
        tag: 'en',
        inSpanish: 'Inglés',
        inEnglish: 'English',
    },
    es: {
        tag: 'es',
        inSpanish: 'Español',
        inEnglish: 'Spanish',
    },
};

const getLanguage = (value: string = 'en') => languageMap[value] || languageMap.en;

export default getLanguage;
