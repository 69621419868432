import styled from 'styled-components';
import { Chip } from 'react-rainbow-components';
import { color } from 'theme';

export const ValueContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
    }
`;

export const InnerContainer = styled.div`
    position: relative;
    display: flex;
`;

export const StyledChip = styled(Chip)`
    background-color: ${color('background.main')};
    padding: 0.4375rem 0.625rem 0.4375rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    flex: 1 1 auto;
    height: 3.375rem;

    & > button[data-id="button-icon-element"] {
        margin-right: -0.3rem;
        border-radius: 0.375rem;

        &:hover {
            margin-right: -0.3rem;
            border-radius: 0.375rem;
        }
    }
`;
