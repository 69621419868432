import styled, { css } from 'styled-components';
import { color } from 'theme';

export const StyledImage = styled.img<{ isOpen: boolean }>`
    cursor: zoom-in;
    visibility: ${(props) => (props.isOpen ? 'hidden' : 'visible')};
`;

export const StyledImageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5002;
`;

export const StyledBackdrop = styled.div<{ isCentered?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${color('background.main')};
    opacity: 0;
    transition: opacity 300ms ease 0s;

    ${(props) => props.isCentered && css`
        opacity: 1;
    `}
`;

export const StyledCenteredImage = styled.img<{
    top: number;
    left: number;
    isCentered?: boolean;
    transform: {
        translateX: number;
        translateY: number;
        scale: number;
    };
}>`
    position: absolute;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
    transition: transform 300ms ease 0s;
    transform: translate3d(0, 0, 0) scale(1);
    will-change: transform;
    transform-origin: center center;
    cursor: zoom-out;
    ${(props) => props.isCentered && props.transform && css`
        transform: translate3d(${props.transform.translateX}px, ${props.transform.translateY}px, 0px) scale(${props.transform.scale});
    `}
`;
