/* eslint-disable react/no-unused-prop-types */
import {
    ChangeEvent, ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import Label from 'react-rainbow-components/components/Input/label';
import useErrorMessageId from 'react-rainbow-components/libs/hooks/useErrorMessageId';
import Input from 'components/Input';
import tryParseNumber from 'data/services/string/parseNumber';
import { Container, InputsContainer, ErrorText } from './styled';

interface PriceFilterProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    error?: ReactNode;
    required?: boolean;
    value?: Array<number | null>;
    onChange?: (value: Array<number | null>) => void;
    hideLabel?: boolean;
}

const PriceFilter = (props: PriceFilterProps) => {
    const {
        value: valueInProps = [],
        onChange = () => {},
        id,
        className,
        label,
        error,
        required,
        hideLabel = false,
    } = useReduxForm(props);

    const errorMessageId = useErrorMessageId(error);

    const minValue = useMemo(
        () => valueInProps.at(0) || '',
        [valueInProps],
    );

    const maxValue = useMemo(
        () => valueInProps.at(1) || '',
        [valueInProps],
    );

    const handleMinChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => onChange([
            tryParseNumber(event.target.value),
            maxValue || null,
        ]),
        [onChange, maxValue],
    );

    const handleMaxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => onChange([
            minValue || null,
            tryParseNumber(event.target.value),
        ]),
        [onChange, minValue],
    );

    return (
        <Container id={id} className={className}>
            <RenderIf isTrue={!hideLabel}>
                <Label
                    label={label}
                    labelAlignment="left"
                    required={required}
                />
            </RenderIf>
            <InputsContainer>
                <Input
                    borderRadius="semi-square"
                    placeholder="Min"
                    value={minValue}
                    onChange={handleMinChange}
                />
                {' - '}
                <Input
                    borderRadius="semi-square"
                    placeholder="Max"
                    value={maxValue}
                    onChange={handleMaxChange}
                />
            </InputsContainer>
            <RenderIf isTrue={error}>
                <ErrorText id={errorMessageId}>{error}</ErrorText>
            </RenderIf>
        </Container>
    );
};

export default PriceFilter;
