/* eslint-disable react/jsx-no-undef */
import { Field } from '@rainbow-modules/forms';
import Input from 'components/Input';
import SecretInput from './secretInput';

interface Props {
    mode?: 'edit' | 'create';
}

const Fields = ({ mode }: Props) => (
    <>
        <Field
            component={Input}
            name="name"
            label="Name"
            labelAlignment="left"
            placeholder="Responder Name"
            borderRadius="semi-square"
            required
        />
        {mode === 'edit' && (
            <Field
                component={SecretInput}
                name="secret"
                label="Secret"
                labelAlignment="left"
                placeholder="••••••••"
                borderRadius="semi-square"
                required
            />
        )}
    </>
);

export default Fields;
