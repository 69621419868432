import {
    ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { DateTime } from 'luxon';
import { isEmpty } from '@rainbow-modules/validation';
import { DatePickerModal, Option } from 'react-rainbow-components';
import { getFormatter } from 'data/services/date/formatter';
import { PickListGlobalStyle, StyledPickList } from './styled';

interface PicklistValue {
    label?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

interface DatePickerFilterProps {
    value?: Date[];
    onChange?: (value: Date[]) => void;
    [key: string]: unknown;
}

const dateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

const options = [
    {
        name: 'all',
        label: 'All Time',
    },
    {
        name: 'today',
        label: 'Today',
    },
    {
        name: 'yesterday',
        label: 'Yesterday',
    },
    {
        name: 'custom',
        label: 'Custom Date',
    },
];

const getOptionFromValue = (value: Date[]) => {
    if (isEmpty(value)) {
        return options[0];
    }
    const [from, to] = value.map((date) => DateTime.fromJSDate(date));
    const now = DateTime.now();

    const diffNow = from.diff(now.startOf('day'), 'days').days;
    if (diffNow === 0) {
        return options[1];
    }

    if (diffNow === -1) {
        return options[2];
    }

    const customOption = options[3];

    if (to) {
        return {
            ...customOption,
            label: `${dateFormatter.format(from.toJSDate())} - ${dateFormatter.format(to.toJSDate())}`,
        };
    }

    return customOption;
};

const DatePickerFilter = ({
    value: valueInProps,
    onChange = () => {},
    ...rest
}:DatePickerFilterProps) => {
    const [activeOption, setActiveOption] = useState<PicklistValue | undefined>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const handlePicklistChange = useCallback(
        (newValue: PicklistValue) => {
            const { name } = newValue;
            setActiveOption((
                activeOption?.name === newValue.name
                    ? { ...newValue, label: activeOption?.label }
                    : newValue
            ));

            if (name === 'all') {
                return onChange([]);
            }

            if (name === 'today') {
                const startOf = new Date();
                const endOf = new Date();
                startOf.setHours(0, 0, 0, 0);
                endOf.setHours(23, 59, 59, 0);
                return onChange([startOf, endOf]);
            }

            if (name === 'yesterday') {
                const yesterday = Date.now() - (1000 * 60 * 60 * 24);
                const startOf = new Date(yesterday);
                const endOf = new Date(yesterday);
                startOf.setHours(0, 0, 0, 0);
                endOf.setHours(23, 59, 59, 0);
                return onChange([startOf, endOf]);
            }
            return openModal();
        },
        [activeOption, onChange, openModal],
    );

    const handleDatePickerChange = useCallback(
        (newValue: Date | Date[]) => {
            const newVal = Array.isArray(newValue) ? newValue : [newValue];
            if (newVal.length > 1) {
                const [from, to] = newVal;
                setActiveOption({
                    ...activeOption,
                    label: `${dateFormatter.format(from)} - ${dateFormatter.format(to)}`,
                });
                closeModal();
            }
            onChange(newVal);
        },
        [closeModal, onChange, activeOption],
    );

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`date-picker-filter-option__${option.name}`} {...option} />),
        [],
    );

    useEffect(
        () => {
            if (!valueInProps || (isEmpty(valueInProps) && !activeOption)) {
                setActiveOption({ name: 'all', label: 'All Time' });
            } else {
                setActiveOption(getOptionFromValue(valueInProps));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [valueInProps],
    );

    return (
        <>
            <PickListGlobalStyle />
            <StyledPickList
                onChange={handlePicklistChange}
                value={activeOption}
                {...rest}
            >
                {pickListOptions}
            </StyledPickList>
            <DatePickerModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                onChange={handleDatePickerChange}
                value={valueInProps}
                maxDate={new Date()}
                selectionType="range"
                title="Select Date(s)"
                borderRadius="semi-square"
            />
        </>
    );
};

export default DatePickerFilter;
