import styled from 'styled-components';
import { Link } from 'react-router-dom';
import OpenAI from 'components/icons/openai';
import ButtonIcon from 'components/ButtonIcon';
import CopyToClipboardButton from 'components/CopyToClipboardButton';

export const StyledOpenAiIcon = styled(OpenAI)`
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
`;

export const StyledLink = styled(Link)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
    margin-right: 1.5rem;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
`;
