import { GoogleAuthProvider, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { auth } from 'data/firestore/firebase';
import Logo from 'components/Logo';
import ArrowNarrowRight from 'components/icons/arrowNarrowRight';
import LoginLayout from 'layouts/Login';
import {
    StyledGoogleIcon,
    StyledMicrosoftIcon,
    StyledButton,
    LoginHeader,
    LoginDescription,
    TermsOfServiceContainer,
    RightButtonContent,
} from './styled';

const microsoftProvider = new OAuthProvider('microsoft.com');

const Login = () => {
    const signInWithGoogle = () => {
        signInWithPopup(auth, new GoogleAuthProvider());
    };

    const signInWithMicrosoft = () => {
        signInWithPopup(auth, microsoftProvider);
    };

    return (
        <LoginLayout>
            <Logo />
            <LoginHeader>
                Welcome to Voxity!
                {' '}
                {'\u{1F44B}'}
            </LoginHeader>
            <LoginDescription>
                Please sign in to your account and start the adventure
            </LoginDescription>
            <br />
            <StyledButton borderRadius="semi-rounded" variant="outline-brand" onClick={signInWithGoogle}>
                <RightButtonContent>
                    <StyledGoogleIcon />
                    <span>
                        Continue with Google
                    </span>
                </RightButtonContent>
                <ArrowNarrowRight />
            </StyledButton>
            <StyledButton className="rainbow-m-top_small" borderRadius="semi-rounded" variant="outline-brand" onClick={signInWithMicrosoft}>
                <RightButtonContent>
                    <StyledMicrosoftIcon />
                    <span>
                        Continue with Microsoft
                    </span>
                </RightButtonContent>
                <ArrowNarrowRight />
            </StyledButton>
            <TermsOfServiceContainer>
                <div>
                    By signing up, you have read and agree to our
                    {' '}
                    <a href="https://www.voxity.ai/terms-of-service">terms of service</a>
                    {' '}
                    and
                    {' '}
                    <a href="https://www.voxity.ai/privacy-policy">privacy policy</a>
                </div>
            </TermsOfServiceContainer>
        </LoginLayout>
    );
};

export default Login;
