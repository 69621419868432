/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useMemo, useRef, useState,
} from 'react';
import {
    useOutsideClick, useReduxForm, useUniqueIdentifier, useWindowResize,
} from '@rainbow-modules/hooks';
import { InternalOverlay } from 'react-rainbow-components';
import useErrorMessageId from 'react-rainbow-components/libs/hooks/useErrorMessageId';
import {
    ColorBox, Dropdown, StyledContainer, StyledIndicator, StyledInput,
    StyledCard, StyledColorPicker, InnerContainer, ErrorText,
} from './styled';

interface ColorPicklistProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string;
    onChange?: (value?: string) => void;
}

const ColorPicklist = (props: ColorPicklistProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex = -1,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const triggerRef = useRef<any>(null);
    const dropdownRef = useRef<any>();
    const [isOpen, setIsOpen] = useState(false);

    const inputId = useUniqueIdentifier('phone-input');
    const errorMessageId = useErrorMessageId(error);

    useOutsideClick(
        dropdownRef,
        (event) => {
            // eslint-disable-next-line max-len
            if (event.target !== triggerRef.current.fieldRef.current.inputRef.current.inputRef.current) {
                setIsOpen(false);
            }
        },
        isOpen,
    );
    useWindowResize(() => setIsOpen(false), isOpen);

    const icon = useMemo(() => valueInProps && <ColorBox color={valueInProps} />, [valueInProps]);

    return (
        <>
            <StyledContainer id={id} className={className}>
                <InnerContainer
                    aria-expanded={isOpen}
                    aria-haspopup="listbox"
                    role="combobox"
                >
                    <StyledInput
                        aria-describedby={errorMessageId}
                        id={inputId}
                        iconPosition="left"
                        autoComplete="off"
                        type="text"
                        name={name}
                        ref={triggerRef}
                        required={required}
                        label={label}
                        readOnly
                        tabIndex={tabIndex}
                        placeholder={placeholder}
                        icon={icon}
                        onClick={() => setIsOpen(true)}
                        value={valueInProps}
                    />
                    <StyledIndicator error={error} />
                </InnerContainer>
                <ErrorText id={errorMessageId}>{error}</ErrorText>
            </StyledContainer>
            <InternalOverlay
                isVisible={isOpen}
                triggerElementRef={
                    () => triggerRef.current.fieldRef.current.inputRef.current.inputRef
                }
            >
                <Dropdown ref={dropdownRef}>
                    <StyledCard>
                        <StyledColorPicker
                            variant="default"
                            tabIndex={-1}
                            value={{ hex: valueInProps }}
                            onChange={(color) => onChange(color.hex)}
                            defaultColors={[
                                '#7367F0', '#FF9F43',
                                '#75DBA3', '#00CFE8',
                                '#E54335', '#F6B704',
                                '#e9e7fd', '#fff0e1',
                                '#e9f9f0', '#d6f7fb',
                                '#fbe1df', '#fef3d7',
                            ]}
                        />
                    </StyledCard>
                </Dropdown>
            </InternalOverlay>
        </>
    );
};

export default ColorPicklist;
