import styled from 'styled-components';
import { AvatarMenu } from 'react-rainbow-components';
import { GREY_500, GREY_800 } from '../../constants';

export const Container = styled.div`
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 5rem;
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);
    height: 4.5rem;
`;

export const RightSection = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const UserInformation = styled.li`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0.6rem 3rem 0.6rem 1.5rem;
    gap: 0.625rem;
`;

export const UserName = styled.p`
    color: ${GREY_800};
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.375rem;
`;

export const UserEmail = styled.p`
    color: ${GREY_500};
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
`;

export const StyledAvatarMenu = styled(AvatarMenu)`
    display: inline-flex;
    justify-content: center;
    margin-left: 1rem;
`;
