import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import EmptyMessage from 'components/EmptyMessage';
import CalendarIcon from 'components/icons/calendarThin';
import { color } from 'theme';
import {
    PURPLE_500,
    PURPLE_100,
    GREY_500,
    GREY_400,
    DIVIDER,
} from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    align-self: stretch;
`;

export const Header = styled.div`
    display: flex;
    height: 5.375rem;
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
`;

export const HeaderItems = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;

export const Title = styled.div`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_400};
    flex: 1;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;

export const Divider = styled.div`
    display: flex;
    align-self: stretch;
    height: 0.0625rem;
    background: ${DIVIDER};
`;

export const Content = styled.div`
    display: flex;
    padding: 1.5rem 1.25rem;
    align-items: stretch;
    gap: 1.25rem;
    flex: 1 0 0;
    align-self: stretch;
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 15.625rem;
    gap: 0;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
`;

export const Options = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    align-self: stretch;
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
    width: 1.125rem !important;
    height: 1.125rem !important;
    font-size: 1.125rem !important;
`;

export const MenuItemContainer = styled.li`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const StyledLink = styled(Link).withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{ isActive: boolean; }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    width: 100%;
    color: ${GREY_500};
    padding: 0.6rem 1rem;
    margin: 2.5px 0;
    border-radius: 5px;
    font-size: 15px;
    transition: background-color 0.2s ease-in-out;
    font-weight: 300;

    &:hover, &:focus, &:active {
        color: ${GREY_500};
        text-decoration: none;
        background-color: ${color('background.highlight')};
    }

    svg {
        width: 1.125rem !important;
        height: 1.125rem !important;
        font-size: 1.125rem !important;
    }

    ${(props) => props.isActive && css`
        color: ${PURPLE_500};
        background: ${PURPLE_100};
        box-shadow: none;

        &:hover, &:focus, &:active {
            color: ${PURPLE_500};
            background: ${PURPLE_100};
        }
    `}
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledEmptyMessage = styled(EmptyMessage)`
    padding: 2rem 0;
`;

export const Footer = styled.div`
    display: flex;
    padding: 1.5rem 1.5rem 1.5rem 0rem;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    align-self: stretch;
`;
