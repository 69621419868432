import styled from 'styled-components';
import { GREY_500, DIVIDER } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    border: 1px solid ${DIVIDER};

    > div:last-child {
        overflow: scroll;
        visibility: hidden;
        flex: none;
    }   
`;

export const Hours = styled.h4`
    flex: none;
    text-align: center;
    color: ${GREY_500};
    min-width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
`;

export const Days = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;

    > div:first-child {
        height: 100%;
        border-right: 1px solid ${DIVIDER};
    }
    > div:last-child {
        border-right: none;
    }
`;

export const Day = styled.div`
    flex: 1 0 auto;
    border-right: 1px solid ${DIVIDER};
    position: relative;
    width: 110px;
    min-width: 110px;
    height: 100%;
    color: ${GREY_500};
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;    
    padding: 0.1875rem 0.625rem;
    gap: 0.625rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
`;
