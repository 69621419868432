import { forwardRef, useCallback } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import useCustomer from 'data/firestore/agent/customer/use';
import TaskStatusActions from 'components/TaskStatusActions';
import { EntityGet } from 'data/firestore/types';
import { Inbox } from 'data/firestore/inbox/types';
import CustomerInfo from './CustomerInfo';
import TaskTitle from './Title';
import TaskSummary from './Summary';
import { TASK_DETAILS_DRAWER } from '../../../constants';
import {
    TaskActions, TaskHeader,
    Line,
    TaskContent,
    Container,
    Content,
    BottomShadow,
} from './styled';

interface TaskProps {
    task: EntityGet<Inbox>;
    scrollDirection?: 'up' | 'down';
    onResolved?: () => void,
    onArchived?: () => void,
}

const animations = {
    up: {
        initial: { opacity: 1, translateY: -20, scale: 0.95 },
        animate: {
            opacity: [0, 0.5, 1.01],
            translateY: [-20, -10, 0],
            scale: [0.95, 0.975, 1],
            zIndex: [1, 2, 3],
        },
        exit: {
            opacity: 1, translateY: 20, scale: 0.95, zIndex: 1,
        },
    },
    down: {
        initial: { opacity: 1, translateY: 20, scale: 0.95 },
        animate: {
            opacity: [0, 0.5, 1.01],
            translateY: [20, 10, 0],
            scale: [0.95, 0.975, 1],
            zIndex: [1, 2, 3],
        },
        exit: {
            opacity: 1, translateY: -20, scale: 0.95, zIndex: 1,
        },
    },
};

const Task = forwardRef<HTMLDivElement, TaskProps>(({
    task,
    scrollDirection = 'down',
    onResolved = () => {},
    onArchived = () => {},
}, ref) => {
    const [conversationSummary, isLoadingSummary] = useDocOnce({
        path: `/session/${task.sessionId}/metadata/summary`,
        flat: true,
    });
    const {
        data: customer,
        isLoading: isLoadingCustomer,
    } = useCustomer(task.agentId || '', task.customer?.uid || '', {
        disabled: !task || !task.customer?.uid,
    });
    const [openModal] = useOpenModal(TASK_DETAILS_DRAWER);
    const handleOpenDetails = useCallback(
        () => openModal({
            data: {
                id: task.id,
            },
        }),
        [openModal, task.id],
    );

    if (!task) {
        // TODO: what to show when something fails
        return null;
    }

    const { title, priority, createdAt } = task;
    const { summary = '' } = conversationSummary || {};
    const { initial, animate, exit } = animations[scrollDirection];

    return (
        <Container
            key={task.id}
            initial={initial}
            animate={animate}
            exit={exit}
            transition={{ duration: 0.3, ease: 'linear' }}
            ref={ref}
        >
            <BottomShadow />
            <Content>
                <TaskHeader>
                    <TaskTitle
                        label={title}
                        priority={priority}
                        date={createdAt}
                    />
                    <TaskActions>
                        <TaskStatusActions
                            taskId={task.id}
                            customer={customer as unknown as Record<string, unknown>}
                            isArchived={false}
                            isSolved={false}
                            onArchived={onArchived}
                            onResolved={onResolved}
                        />
                    </TaskActions>
                </TaskHeader>
                <TaskContent>
                    <CustomerInfo
                        customer={customer}
                        isLoading={isLoadingCustomer}
                    />
                    <Line />
                    <TaskSummary
                        header="Sara's got the highlights for you"
                        summary={summary}
                        isLoading={isLoadingSummary}
                        onDetailsClick={handleOpenDetails}
                    />
                </TaskContent>
            </Content>
        </Container>
    );
});

export default Task;
