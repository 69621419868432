import { IconProps } from 'types';

const Pencil = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
    >
        <title>{title}</title>
        <path d="M3.66666 18.3323H7.33332L16.9583 8.70731C17.9708 7.69479 17.9708 6.05316 16.9583 5.04064C15.9458 4.02812 14.3042 4.02812 13.2917 5.04064L3.66666 14.6656V18.3323" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.375 5.95703L16.0417 9.6237" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Pencil;
