/* eslint-disable jsx-a11y/aria-role */
import { useContext, useEffect } from 'react';
import ChatView from 'components/ChatView';
import context from 'components/InstructionTestingLayout/context';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import useOpenAiChat from 'hooks/useOpenAiChat';
import { ResponderInstructionTestingContext } from '../types';

interface Params extends InstructionTestStepComponentProps {}

const OpenAIChatView = ({
    onChange = () => {},
}: Params) => {
    const {
        responderConfig,
        compiledTemplate,
        chatMessages,
    } = useContext<ResponderInstructionTestingContext>(context);

    const {
        messages,
        isLoading,
        isInitialized,
        initialize: initializeChat,
        reset: resetChat,
        addMessage,
        updateMessage,
        deleteMessage,
        send: submitConversation,
    } = useOpenAiChat({
        model: responderConfig?.model.name as string,
        temperature: responderConfig?.temperature as number,
        systemMessage: compiledTemplate,
        messages: chatMessages,
    });

    useEffect(
        () => {
            onChange({ messages });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [messages],
    );

    return (
        <ChatView
            isLoading={isLoading}
            messages={messages}
            initialized={isInitialized}
            onChatInit={initializeChat}
            onAddMessage={addMessage}
            onUpdateMessage={updateMessage}
            onDeleteMessage={deleteMessage}
            onSubmit={submitConversation}
            onReset={resetChat}
        />
    );
};

export default OpenAIChatView;
