import { useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import Eye from 'components/icons/eye';
import { Responder } from 'data/firestore/agent/responder/types';
import { EntityGet } from 'data/firestore/types';
import { CellWrapper } from '../styled';
import {
    ButtonsContainer, StyledButtonIcon, StyledCopyToClipboardButton, StyledSpan,
} from './styled';

interface Props {
    value?: EntityGet<Responder>['secret'];
}

const SecretColumn = ({ value }: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible((prev) => !prev);
    };

    return (
        <CellWrapper>
            <StyledSpan>
                <RenderIf isTrue={isVisible}>
                    {value}
                </RenderIf>
                <RenderIf isTrue={!isVisible}>
                    {'•'.repeat(16)}
                </RenderIf>
            </StyledSpan>
            <ButtonsContainer>
                <StyledButtonIcon icon={<Eye />} borderRadius="semi-square" onClick={toggleVisibility} />
                <StyledCopyToClipboardButton mode="icon" value={value || ''} borderRadius="semi-square" />
            </ButtonsContainer>
        </CellWrapper>
    );
};

export default SecretColumn;
