import { PositionResolverOpts } from 'react-rainbow-components/components/InternalOverlay';

const positionResolver = (opts: PositionResolverOpts) => {
    const { trigger, content } = opts;
    return {
        top: Math.max(0, trigger.rightBottomAnchor.y + 16),
        left: Math.max(0, trigger.rightBottomAnchor.x - content.width),
    };
};

export default positionResolver;
