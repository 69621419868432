/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const equal: Snippet = {
    label: 'equal',
    body: '{{#equal ${1:value1} ${2:value2}}}\n\t${0}\n{{/equal}}',
    documentation: 'equal statement',
};

export default equal;
