import { useEffect, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import SortByPicker from 'components/SortByPIcker';
import { Inbox } from 'data/firestore/inbox/types';
import { EntityGet } from 'data/firestore/types';
import ArrowUp from 'components/icons/arrowUp';
import ArrowDown from 'components/icons/arrowDown';
import { AnimatePresence } from 'framer-motion';
import {
    Container,
    Header,
    CategoryTitle,
    Content,
    ButtonGroupDivider,
    CategoryActions,
} from './styled';
import Task from './Task';
import useStoredSort from '../../hooks/useStoredSort';
import sortTasks from './helpers/sortTasks';

const sortFields = [
    { name: 'priority', label: 'Priority' },
    { name: 'createdAt', label: 'Create Date' },
    { name: 'name', label: 'Name' },
];

interface TasksInCategoryProps {
    topicId: string;
    title?: string;
    tasks: EntityGet<Inbox>[];
}

const TasksInCategory = ({
    topicId,
    title,
    tasks,
}: TasksInCategoryProps) => {
    const [sort, setSort] = useStoredSort(topicId);
    const [sortedTasks, setSortedTasks] = useState<EntityGet<Inbox>[]>([]);
    const [currentTask, setCurrentTask] = useState<EntityGet<Inbox>>();
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');

    useEffect(() => {
        if (!sort) {
            return;
        }

        const sorted = sortTasks({ sort, tasks });
        setSortedTasks(sorted);
    }, [sort, tasks]);

    useEffect(() => {
        setCurrentTask(sortedTasks[0]);
    }, [sortedTasks]);

    const prevPage = () => {
        const currentIndex = sortedTasks.findIndex((task) => task.id === currentTask?.id);
        const prevTask = currentIndex > 0
            ? sortedTasks[currentIndex - 1]
            : sortedTasks[tasks.length - 1];
        setCurrentTask(prevTask);
        setScrollDirection('up');
    };

    const nextPage = () => {
        const currentIndex = sortedTasks.findIndex((task) => task.id === currentTask?.id);
        const nextTask = currentIndex < sortedTasks.length - 1
            ? sortedTasks[currentIndex + 1]
            : sortedTasks[0];
        setCurrentTask(nextTask);
        setScrollDirection('down');
    };
    const shouldDisablePagination = sortedTasks.length === 0 || sortedTasks.length === 1;

    if (!currentTask) {
        return null;
    }

    return (
        <Container>
            <Header>
                <CategoryTitle>{title}</CategoryTitle>
                <CategoryActions>
                    <SortByPicker sortFields={sortFields} value={sort} onChange={setSort} />
                    <ButtonGroupDivider />
                    <ButtonIcon
                        icon={<ArrowUp />}
                        onClick={() => prevPage()}
                        disabled={shouldDisablePagination}
                    />
                    <ButtonIcon
                        icon={<ArrowDown />}
                        onClick={() => nextPage()}
                        disabled={shouldDisablePagination}
                    />
                </CategoryActions>
            </Header>
            <Content>
                <AnimatePresence mode="popLayout">
                    {currentTask && (
                        <Task
                            key={currentTask!.id}
                            task={currentTask!}
                            onArchived={() => {}}
                            onResolved={() => {}}
                            scrollDirection={scrollDirection}
                        />
                    )}
                </AnimatePresence>
            </Content>
        </Container>
    );
};

export default TasksInCategory;
