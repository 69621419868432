import { IconProps } from 'types';

const AlertCircle = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <circle cx="12" cy="7" r="0.5" stroke="currentColor" />
        <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
        <path d="M12 17L12 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);
export default AlertCircle;
