import { useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { PercentLabel } from '../../styled';
import { BookingsColumnValue, BookingsCellWrapper } from './styled';

interface Props {
    row?: Record<string, number>;
    value?: any;
    hidePercent?: boolean;
}

const BookingsAmountColumn = ({
    row,
    hidePercent = false,
    value: valueInProps = 0,
}: Props) => {
    const value = useMemo(
        () => {
            const numbericValue = (!valueInProps ? 0 : valueInProps as number);
            const suffix = (numbericValue === 0 || numbericValue > 1 ? 's' : '');

            return `${numbericValue} Event${suffix}`;
        },
        [valueInProps],
    );

    const percent = useMemo(
        () => {
            const totalBookins = (row || {})['total-bookings'] || 0;
            if (totalBookins === 0) return 0;
            return 100 * (valueInProps / totalBookins);
        },
        [row, valueInProps],
    );

    return (
        <BookingsCellWrapper>
            <BookingsColumnValue>{value}</BookingsColumnValue>
            <RenderIf isTrue={!hidePercent}>
                <PercentLabel>{`${percent.toFixed(1)}%`}</PercentLabel>
            </RenderIf>
        </BookingsCellWrapper>
    );
};

export default BookingsAmountColumn;
