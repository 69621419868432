import styled from 'styled-components';
import { PhoneInput } from 'react-rainbow-components';
import { DIVIDER } from '../../constants';

export default styled(PhoneInput)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    label + div {
        border: 1px solid ${DIVIDER};
        border-radius: 0.375rem;
        box-shadow: none;
    }

    input {
        font-weight: 300 !important;

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }

`;
