import { useState, useEffect, useCallback } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { JSONObject, Message as TwilioMessage } from '@twilio/conversations';
import { UrlPreview } from 'components/SMSChat/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import {
    Container, Description, StyledImage, Title, TitleDescriptionContainer,
} from './styled';
import backupImage from './assets/backupImage.png';

interface Props {
    url: string;
    twilioMessage?: TwilioMessage;
    urlPreviewMetadata?: UrlPreview;
}

const LinkPreview = (props: Props) => {
    const { url, twilioMessage, urlPreviewMetadata } = props;
    const [preview, setPreview] = useState<UrlPreview | undefined>(urlPreviewMetadata);
    const {
        title, description, image, error,
    } = preview || {};
    const { mutateAsync: getUrlPreview } = useHttpMutation({
        method: 'GET',
    });

    const updateMessageAttributes = useCallback(
        async (newAttributes: JSONObject) => {
            try {
                await twilioMessage?.updateAttributes(newAttributes);
            } catch (e) {
                // no catch
            }
        },
        [twilioMessage],
    );

    const getUrlPreviewMetadata = useCallback(
        async (link: string) => {
            if (preview) {
                return;
            }
            const encodedParamValue = encodeURIComponent(link);
            try {
                const data = await getUrlPreview({
                    pathname: `/url-image-preview?url=${encodedParamValue}`,
                });
                const newAttributes = {
                    ...(twilioMessage?.attributes as JSONObject),
                    urlPreviewMetadata: data as UrlPreview & JSONObject,
                };
                updateMessageAttributes(newAttributes);
                setPreview(data as UrlPreview);
            } catch (e) {
                const urlPreviewMetadataError = {
                    error: true,
                    title: '',
                    description: '',
                    image: '',
                };
                const newAttributes = {
                    ...(twilioMessage?.attributes as JSONObject),
                    urlPreviewMetadata: urlPreviewMetadataError,
                };
                updateMessageAttributes(newAttributes);
                setPreview(urlPreviewMetadataError);
            }
        },
        [getUrlPreview, preview, twilioMessage, updateMessageAttributes],
    );

    useEffect(() => {
        const { attributes } = twilioMessage as TwilioMessage;
        if (attributes) {
            const { urlPreviewMetadata: previewMetadata } = attributes as JSONObject;
            if (previewMetadata) {
                const {
                    title: titleAttribute,
                    description: descriptionAttribute,
                    image: imageAttribute,
                } = previewMetadata as UrlPreview & JSONObject;
                setPreview({
                    title: titleAttribute,
                    description: descriptionAttribute,
                    image: imageAttribute,
                });
            } else {
                getUrlPreviewMetadata(url);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, twilioMessage]);
    return (
        <RenderIf isTrue={!error}>
            <Container as="a" href={url} target="_blank" rel="noopener">
                <RenderIf isTrue={title || description}>
                    <TitleDescriptionContainer>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </TitleDescriptionContainer>
                </RenderIf>
                <StyledImage
                    src={image ?? backupImage}
                    alt="preview"
                    onError={(e) => {
                        (e.target as HTMLImageElement).src = backupImage;
                    }}
                />
            </Container>
        </RenderIf>
    );
};

export default LinkPreview;
