import AdjustmentsHorizontalIcon from './adjustmentsHorizontal';
import AlertCircleIcon from './alertCircle';
import AlertOctagonIcon from './alertOctagon';
import AlertTriangleIcon from './alertTriangle';
import ArchiveIcon from './archive';
import ArrowDownIcon from './arrowDown';
import ArrowLeftIcon from './arrowLeft';
import ArrowNarrowRightIcon from './arrowNarrowRight';
import ArrowRightIcon from './arrowRight';
import ArrowsDiagonalIcon from './arrowsDiagonal';
import ArrowUpIcon from './arrowUp';
import AvatarIcon from './avatar';
import BarChartIcon from './barChart';
import BellIcon from './bell';
import BellRingingIcon from './bellRinging';
import BirthdayCakeIcon from './birthdayCake';
import BoardIconIcon from './board';
import BuildingIcon from './building';
import BulletListIcon from './bulletList';
import CalendarIcon from './calendar';
import Calendar2Icon from './calendar2';
import CalendarStatusIcon from './calendarStatus';
import CalendarThinIcon from './calendarThin';
import CardIcon from './card';
import ChatIcon from './chat';
import Chat2Icon from './chat2';
import CheckIcon from './check';
import ChecksIcon from './checks';
import ChevronDownIcon from './chevronDown';
import ChevronLeftIcon from './chevronLeft';
import ChevronRightIcon from './chevronRight';
import ChevronsRightIcon from './chevronsRight';
import CircleIcon from './circle';
import CircleDotIcon from './circleDot';
import CircleLargeIcon from './circleLarge';
import CircleWithDotIcon from './circleWithDot';
import ClockIcon from './clock';
import Clock2Icon from './clock2';
import Clock3Icon from './clock3';
import CloseIcon from './close';
import ComputerIcon from './computer';
import CopyIcon from './copy';
import DividerIcon from './divider';
import DocumentIcon from './document';
import DollarIcon from './dollar';
import DotsVerticalIcon from './dotsVertical';
import DoubleCheckIcon from './doubleCheck';
import DownloadIcon from './download';
import EmailIcon from './email';
import ExportIcon from './export';
import Export2Icon from './export2';
import ExternalLinkIcon from './externalLink';
import EyeIcon from './eye';
import FacebookIcon from './facebook';
import FacebookSolidIcon from './facebookSolid';
import FilesIcon from './files';
import FilterIcon from './filter';
import FlaskIcon from './flask';
import FloppyIcon from './floppy';
import FocusIcon from './focus';
import GaugeIcon from './gauge';
import HandlebarIcon from './handlebar';
import HappyFaceIcon from './happyFace';
import HeadphonesIcon from './headphones';
import HelpIcon from './help';
import HistoryIcon from './history';
import HomeIcon from './home';
import IdCardIcon from './idcard';
import ImageIcon from './image';
import InboxIcon from './inbox';
import InboxThinIcon from './inboxThin';
import IncommingCallIcon from './incommingCall';
import InfoIcon from './info';
import InsightsIcon from './insight';
import InstagramIcon from './instagram';
import InstagramSolidIcon from './instagramSolid';
import LanguagesIcon from './languages';
import LayoutListIcon from './layoutList';
import ListIcon from './list';
import ListDetailsIcon from './listDetails';
import LocationIcon from './location';
import LockClosedIcon from './lockClosed';
import LockOpenIcon from './lockOpen';
import LogoutIcon from './logout';
import MailClosedIcon from './mailClosed';
import MailOpenIcon from './mailOpened';
import MessageIcon from './message';
import MessageCircleIcon from './messageCircle';
import MicrophoneIcon from './microphone';
import MicrosoftIcon from './microsoft';
import MinusCircleIcon from './minusCircle';
import NoteIcon from './note';
import OpenAIIcon from './openai';
import OpportunityIcon from './opportunity';
import PaperIcon from './paper';
import PauseIcon from './pause';
import PencilIcon from './pencil';
import Pencil2Icon from './pencil2';
import Pencil3Icon from './pencil3';
import PhoneIcon from './phone';
import Phone2Icon from './phone2';
import PhoneAddIcon from './phoneAdd';
import PhoneThinIcon from './phoneThin';
import PlayIcon from './play';
import PlusIcon from './plus';
import PlusCircleIcon from './plusCircle';
import RefreshIcon from './refresh';
import RestoreIcon from './restore';
import RotateClockwiseIcon from './rotateClockwise';
import RotateCounterClockwiseIcon from './rotateCounterClockwise';
import SaraAvatarIcon from './saraAvatar';
import SearchIcon from './search';
import SendIcon from './send';
import SettingIcon from './setting';
import SidebarExpandIcon from './sidebarExpand';
import SidebarShrinkIcon from './sidebarShrink';
import SmsIcon from './sms';
import SocialIcon from './social';
import SortAscIcon from './sortAsc';
import SortDescIcon from './sortDesc';
import SplitArrowIcon from './split';
import StarIcon from './star';
import StarThinIcon from './starThin';
import TableViewIcon from './tableView';
import TasksIcon from './tasks';
import TimeIcon from './time';
import TransferredIcon from './transferred';
import TrashIcon from './trash';
import UserIcon from './user';
import UserThinIcon from './userThin';
import UsersIcon from './users';
import VideoIcon from './video';
import VolumeIcon from './volume';
import ZoomInIcon from './zoomIn';

const iconsMap: Record<string, React.FunctionComponent<{}>> = {
    'adjustments-horizontal': AdjustmentsHorizontalIcon,
    'alert-circle': AlertCircleIcon,
    'alert-octagon': AlertOctagonIcon,
    'alert-triangle': AlertTriangleIcon,
    archive: ArchiveIcon,
    'arrow-down': ArrowDownIcon,
    'arrow-left': ArrowLeftIcon,
    'arrow-narrow-right': ArrowNarrowRightIcon,
    'arrow-right': ArrowRightIcon,
    'arrows-diagonal': ArrowsDiagonalIcon,
    'arrow-up': ArrowUpIcon,
    avatar: AvatarIcon,
    'bar-chart': BarChartIcon,
    bell: BellIcon,
    'bell-ringing': BellRingingIcon,
    'birthday-cake': BirthdayCakeIcon,
    'board-icon': BoardIconIcon,
    building: BuildingIcon,
    'bullet-list': BulletListIcon,
    calendar: CalendarIcon,
    'calendar-2': Calendar2Icon,
    'calendar-status': CalendarStatusIcon,
    'calendar-thin': CalendarThinIcon,
    card: CardIcon,
    chat: ChatIcon,
    'chat-2': Chat2Icon,
    check: CheckIcon,
    checks: ChecksIcon,
    'chevron-down': ChevronDownIcon,
    'chevron-left': ChevronLeftIcon,
    'chevron-right': ChevronRightIcon,
    'chevrons-right': ChevronsRightIcon,
    circle: CircleIcon,
    'circle-dot': CircleDotIcon,
    'circle-large': CircleLargeIcon,
    'circle-with-dot': CircleWithDotIcon,
    clock: ClockIcon,
    'clock-2': Clock2Icon,
    'clock-3': Clock3Icon,
    close: CloseIcon,
    computer: ComputerIcon,
    copy: CopyIcon,
    divider: DividerIcon,
    document: DocumentIcon,
    dollar: DollarIcon,
    'dots-vertical': DotsVerticalIcon,
    'double-check': DoubleCheckIcon,
    download: DownloadIcon,
    email: EmailIcon,
    export: ExportIcon,
    'export-2': Export2Icon,
    'external-link': ExternalLinkIcon,
    eye: EyeIcon,
    facebook: FacebookIcon,
    'facebook-solid': FacebookSolidIcon,
    files: FilesIcon,
    filter: FilterIcon,
    flask: FlaskIcon,
    floppy: FloppyIcon,
    focus: FocusIcon,
    gauge: GaugeIcon,
    handlebar: HandlebarIcon,
    'happy-face': HappyFaceIcon,
    headphones: HeadphonesIcon,
    help: HelpIcon,
    history: HistoryIcon,
    home: HomeIcon,
    'id-card': IdCardIcon,
    image: ImageIcon,
    inbox: InboxIcon,
    'inbox-thin': InboxThinIcon,
    'incoming-call': IncommingCallIcon,
    info: InfoIcon,
    insights: InsightsIcon,
    instagram: InstagramIcon,
    'instagram-solid': InstagramSolidIcon,
    languages: LanguagesIcon,
    'layout-list': LayoutListIcon,
    list: ListIcon,
    'list-details': ListDetailsIcon,
    location: LocationIcon,
    'lock-closed': LockClosedIcon,
    'lock-open': LockOpenIcon,
    logout: LogoutIcon,
    'mail-closed': MailClosedIcon,
    'mail-open': MailOpenIcon,
    message: MessageIcon,
    'message-circle': MessageCircleIcon,
    microphone: MicrophoneIcon,
    microsoft: MicrosoftIcon,
    'minus-circle': MinusCircleIcon,
    note: NoteIcon,
    openai: OpenAIIcon,
    opportunity: OpportunityIcon,
    paper: PaperIcon,
    pause: PauseIcon,
    pencil: PencilIcon,
    'pencil-2': Pencil2Icon,
    'pencil-3': Pencil3Icon,
    phone: PhoneIcon,
    'phone-2': Phone2Icon,
    'phone-add': PhoneAddIcon,
    'phone-thin': PhoneThinIcon,
    play: PlayIcon,
    plus: PlusIcon,
    'plus-circle': PlusCircleIcon,
    refresh: RefreshIcon,
    restore: RestoreIcon,
    'rotate-clockwise': RotateClockwiseIcon,
    'rotate-counter-clockwise': RotateCounterClockwiseIcon,
    'sara-avatar': SaraAvatarIcon,
    search: SearchIcon,
    send: SendIcon,
    setting: SettingIcon,
    'sidebar-expand': SidebarExpandIcon,
    'sidebar-shrink': SidebarShrinkIcon,
    sms: SmsIcon,
    social: SocialIcon,
    'sort-asc': SortAscIcon,
    'sort-desc': SortDescIcon,
    'split-arrow': SplitArrowIcon,
    star: StarIcon,
    'star-thin': StarThinIcon,
    'table-view': TableViewIcon,
    tasks: TasksIcon,
    time: TimeIcon,
    transferred: TransferredIcon,
    trash: TrashIcon,
    user: UserIcon,
    'user-thin': UserThinIcon,
    users: UsersIcon,
    video: VideoIcon,
    volume: VolumeIcon,
    'zoom-in': ZoomInIcon,
};

export default iconsMap;
