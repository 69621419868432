import { forwardRef } from 'react';
import {
    StyledHours, StyledHour, StyledTitleHour,
} from './styled';

interface HoursProps {
    locale?: string,
}

const Hours = forwardRef<HTMLDivElement, HoursProps>((props, ref) => {
    const { locale } = props;

    function ListHours() {
        const hour = new Date();
        hour.setMinutes(0);
        hour.setSeconds(0);
        return Array.from(Array(24), (_value, index) => {
            hour.setHours(index);
            const formattedHours = new Intl.DateTimeFormat(locale, {
                hour: 'numeric',
                hour12: true,
            }).format(hour);
            return (
                <StyledHour key={index}>
                    <StyledTitleHour>
                        {formattedHours}
                    </StyledTitleHour>
                </StyledHour>
            );
        });
    }

    return (
        <StyledHours ref={ref}>
            <ListHours />
        </StyledHours>
    );
});

export default Hours;
