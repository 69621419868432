import styled, { css } from 'styled-components';
import { AvatarGroup } from 'react-rainbow-components';

export default styled(AvatarGroup)`
    ${(props) => props.size === 'small' && css`
        span {
            width: 1.75rem;
            height: 1.75rem;
        }
    `}
`;
