import styled, { css } from 'styled-components';
import { Input } from 'react-rainbow-components';

export default styled(Input)`
    input {
        font-weight: 300 !important;

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    ${(props) => props.type === 'checkbox' && css`
        div {
            margin-bottom: 0;
        }

        label .rainbow-input_faux {
            margin-right: 0;
        }
    `}

    ${(props) => props.variant === 'bare' && css`
        svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
            margin-right: 0.75rem;
            flex-shrink: 0;
        }
    `}
`;
