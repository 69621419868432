import styled from 'styled-components';
import Card from 'components/Card';
import Plot from 'react-plotly.js';
import {
    GREY_800,
    GREY_500,
    DIVIDER,
    GREEN_100,
    GREEN_500,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
`;

export const SumaryContainer = styled(Card)`
    display: flex;
    padding: 1.5rem 0;
    align-items: center;
    margin-bottom: 1rem;
    
    & > *:not(:first-child) {
        border-left: 1px solid ${DIVIDER};
    }
`;

export const IconContainer = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    background-color: ${GREEN_100};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${GREEN_500};
`;

export const MinutesUsageCard = styled(Card)`
    padding: 1.5rem;
    display: flex;
    height: 17.75rem;
    margin-bottom: 1rem;
`;

export const UsageLeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
`;

export const UsageRightColumn = styled.div`
    flex-grow: 1;
`;

export const UsageCardHeader = styled.header`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 100%;
`;

export const UsageCardFooter = styled.footer`
    display: flex;
    flex-direction: column;
`;

export const CardTitle = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;

export const CardDescription = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${GREY_500};
`;

export const CardValue = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    line-height: 3.25rem;
    color: ${GREY_800};
`;

export const HistoryCard = styled(Card)`
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const HistoryHeader = styled.header`
    display: flex;
    align-items: center;
    padding: 1.5rem;
`;

export const StyledPlot = styled(Plot)`
    width: 100%;
    height: 220px;
`;
