import { IconProps } from 'types';

const Pause = ({ title = 'Pause', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M8 6L8 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M15 6L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default Pause;
