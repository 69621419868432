import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import ModalBody from 'components/ModalBody';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import useHttpMutation from 'data/firestore/useHttpMutation';
import ModalTitle from 'components/ModalTitle';
import { ADD_EDIT_CALENDAR_EVENT_STATUS } from '../../../../constants';
import {
    ItemContainer,
    ItemTitle,
    StyledButtonIcon,
    ColorBox,
} from './styled';

interface Params {
    id: string;
    name: string;
    color: string;
    removable: boolean;
}

const EventStatus = ({
    id,
    name,
    color,
    removable,
}: Params) => {
    const { agentId = '' } = useParams();
    const [
        openEventStatusForm,
        closeEventStatusForm,
    ] = useOpenModal(ADD_EDIT_CALENDAR_EVENT_STATUS);

    const { mutateAsync: updateEventStatus } = useHttpMutation({
        method: 'PATCH',
        pathname: `/agents/${agentId}/event-statuses/${id}`,
        onSuccess: closeEventStatusForm,
    });

    const { mutateAsync: deleteEventStatus } = useHttpMutation({
        method: 'DELETE',
        pathname: `/agents/${agentId}/event-statuses/${id}`,
    });

    const handleEditEventType = useCallback(
        () => openEventStatusForm({
            id,
            title: <ModalTitle>Edit Event Status</ModalTitle>,
            message: 'Update the name and color of this event status for better event tracking.',
            submitButtonLabel: 'Update',
            initialValues: {
                name,
                color,
            },
            onSubmit: async ({
                name: newName,
                color: newColor,
            }: {
                name: string;
                color: string;
            }) => {
                showAppSpinner();
                try {
                    await updateEventStatus({
                        body: {
                            name: newName,
                            color: newColor,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [color, id, name, openEventStatusForm, updateEventStatus],
    );

    const handleDeleteEventType = useCallback(
        async () => {
            const result = await confirmModal({
                header: '',
                question: (
                    <ModalBody
                        icon={<Trash />}
                        title="Remove Event Status?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteEventStatus({});
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteEventStatus],
    );

    return (
        <ItemContainer>
            <ColorBox color={color} />
            <ItemTitle>{name}</ItemTitle>
            <RenderIf isTrue={removable}>
                <StyledButtonIcon
                    borderRadius="semi-square"
                    size="small"
                    icon={<Pencil />}
                    onClick={handleEditEventType}
                />
                <StyledButtonIcon
                    borderRadius="semi-square"
                    size="small"
                    icon={<Trash />}
                    onClick={handleDeleteEventType}
                />
            </RenderIf>
        </ItemContainer>
    );
};

export default EventStatus;
