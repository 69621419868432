/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'partial',
    body: '{{> ${0}}}',
    documentation: 'partial statement',
};

export default snippet;
