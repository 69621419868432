import { Outlet } from 'react-router-dom';
import Footer from 'components/Footer';
import TaskDetailsDrawer from 'components/TaskDetailsDrawer';
import {
    Container, PageContainer, Gradient, BottomGradient,
} from './styled';

const TasksPage = () => (
    <PageContainer>
        <Container>
            <Outlet />
        </Container>
        <Footer />
        <TaskDetailsDrawer />
        <Gradient />
        <BottomGradient />
    </PageContainer>
);

export default TasksPage;
