import styled, { css } from 'styled-components';
import Accordion from 'components/Accordion';
import { GREY_500, DIVIDER } from '../../constants';

export const StyledAccordion = styled(Accordion)`
    display: flex;
    flex: 1;
    align-items: center;
    align-self: stretch;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid ${DIVIDER};
    padding: 0;
    
    li {
        flex: 1;

        > button {
            padding: 0.8125rem 0.75rem;
            border-radius: 0.5rem;
        }
    }
`;

export const PrimaryText = styled.h3`
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: ${GREY_500};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 1rem;
    gap: 1.5rem;
`;

export const BadgeContainer = styled.div`
    display: flex;
    align-items: start;
`;

export const StyledBadge = styled.div<{ color?: string }>`
    padding: 0.125rem 0.375rem 0.125rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
    whites-pace: nowrap;
    ${(props) => props.color && css`
        color: ${props.color};
        background: ${props.color}29;
    `};
`;
