import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { hideAppSpinner, showAppMessage, showAppSpinner } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { BaseFormProps } from './types';
import FormFooter from './footer';
import Form from './form';
import { Container, Content } from './styled';

interface CreateFormProps extends BaseFormProps {}

const CreateForm = ({
    agentId = '',
    onRequestClose = () => {},
}: CreateFormProps) => {
    const navigate = useNavigate();
    const {
        mutateAsync: createResponder,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/agents/${agentId}/responders`,
        method: 'POST',
        onSuccess: (response) => {
            onRequestClose();
            navigate(`/${agentId}/responders/${response.id}`);
        },
    });

    const handleSubmit = useCallback(
        async (values: Record<string, unknown>) => {
            try {
                showAppSpinner();
                await createResponder({
                    body: {
                        ...values,
                    },
                });
            } catch (error) {
                showAppMessage({
                    variant: 'error',
                    message: (error as Error).message,
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [createResponder],
    );

    return (
        <Container>
            <Content>
                <Form
                    id="responder-form"
                    onSubmit={handleSubmit}
                />
            </Content>
            <FormFooter
                formId="responder-form"
                onCancelClick={onRequestClose}
            />
        </Container>
    );
};

export default CreateForm;
