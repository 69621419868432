import { IconProps } from 'types';

const Tasks = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
    >
        <title>{title}</title>
        <path d="M5 9V8.9C5 6.74609 6.74609 5 8.9 5H14M14 19H15C17.2091 19 19 17.2091 19 15V14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M3 12L9 18L21 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Tasks;
