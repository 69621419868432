import styled, { css } from 'styled-components';
import { Virtuoso } from 'react-virtuoso';
import { BadgeOverlay, Spinner } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import {
    DIVIDER, GREY_800, HIGHLIGHT, WHITE,
} from '../../../constants';

export const NotificationMenuContainer = styled.div`
    position: relative;
    display: block;
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    z-index: 1;
    top: unset;
    left: unset;
    transform: unset;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    flex-shrink: 0;
`;

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    & > span {
        font-size: 10px;
        font-weight: 500;
        line-height: 11px;
        text-align: center;
        min-width: 18px;
        height: 18px;
    }
`;

export const DropdownContainer = styled.div`
    background-color: ${WHITE};
    color: ${GREY_800};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06), 0px 8px 24px 0px rgba(75, 70, 92, 0.16);
    width: 380px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 2rem);
    overflow: hidden;
`;

export const Header = styled.header`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-bottom: solid 1px ${DIVIDER};
    font-size: 18px;
    font-weight: 500;
    height: 3.6875rem;
    box-sizing: border-box;
`;

export const NotificationContainer = styled.li`
    display: flex;
    padding: 1rem 1.5rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    background: ${WHITE};
    cursor: pointer;

    &:hover {
        background: ${HIGHLIGHT};
    }
`;

export const NotificationContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
`;

export const NotidicationTitle = styled.h4`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
`;

export const NotidicationText = styled.p<{ hasIcon?: boolean }>`
    display: flex;
    flex: 1;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    max-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin-right: 3rem;

    ${(props) => props.hasIcon && css`
        margin-left: 0.25rem;
    `};
`;

export const DateLabel = styled.div`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-transform: capitalize;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;

    > div {
        padding: 0;
        padding-bottom: 0.5rem;
    }
`;

export const EmptyMessageTitle = styled.h3`
    font-size: 1rem;
    color: ${GREY_800};
    font-weight: 500;
`;

export const EmptyMessageEmoji = styled.span`
    font-size: 2.5rem;
`;

export const NotificationsContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const StyledVirtuoso = styled(Virtuoso)`
    width: 100%;
    height: 100%;
`;
