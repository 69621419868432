import { ReactNode } from 'react';
import { StyledSpinner, StyledStatusBadge } from './styled';

interface StatusBadgeProps {
    value?: string;
}

const colorsMap: Record<string, 'default' | 'warning' | 'brand' | 'success' | 'error'> = {
    unknown: 'default',
    ringing: 'warning',
    'in-progress': 'brand',
    completed: 'success',
    cancelled: 'error',
    blocked: 'default',
};

const labelMap: Record<string, string> = {
    unknown: 'Unknown',
    ringing: 'Ringing',
    'in-progress': 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled',
    blocked: 'Blocked',
};

const iconMap: Record<string, ReactNode> = {
    ringing: <StyledSpinner type="arc" variant="brand" />,
    'in-progress': <StyledSpinner type="arc" variant="brand" />,
};

const StatusBadge = ({ value }: StatusBadgeProps) => (
    <StyledStatusBadge
        variant={colorsMap[value || 'unknown']}
        borderRadius="semi-square"
    >
        {iconMap[value || 'unknown']}
        {labelMap[value || 'unknown']}
    </StyledStatusBadge>
);

export default StatusBadge;
