/* eslint-disable react/forbid-dom-props */
import {
    CSSProperties, MutableRefObject, forwardRef, useImperativeHandle, useRef,
} from 'react';
import { UniversalFormProps } from '@rainbow-modules/forms/lib/components/UniversalForm';
import { Form } from 'react-final-form';

interface FormProps extends UniversalFormProps {
    className?: string;
    style?: CSSProperties;
}

const UniversalForm = forwardRef((props: FormProps, ref) => {
    const {
        onSubmit = () => {},
        id,
        children,
        initialValues,
        validate,
        style,
        className,
        keepDirtyOnReinitialize = false,
    } = props;
    const formRef: MutableRefObject<any> = useRef(null);

    useImperativeHandle(ref, () => ({
        reset: () => {
            formRef.current.reset();
        },
    }));

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            validate={validate}
            render={(formProps) => {
                const { handleSubmit, form } = formProps;
                formRef.current = form;
                return (
                    <form
                        className={className}
                        style={style}
                        onSubmit={handleSubmit}
                        noValidate
                        id={id}
                    >
                        {children}
                    </form>
                );
            }}
        />
    );
});

export default UniversalForm;
