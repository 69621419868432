import { collection, query, getCountFromServer } from "firebase/firestore";
import { ListServiceOpts } from "../types";
import { db } from "../firebase";

export type InboxCountService = (opts?: ListServiceOpts) => Promise<number>;

const count: InboxCountService = async (opts = {}) => {
  const collectionRef = collection(db, `/inbox`);
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};

export default count;
