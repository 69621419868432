import RecordedAudioPlayer from './RecordedAudio';
import WebAudioPlayer from './WebAudio';

interface AudioPlayerProps {
    recordingId?: string;
    url?: string | null;
}

const AudioPlayer = ({
    recordingId = '',
    url = '',
}:AudioPlayerProps) => {
    if (recordingId) {
        return <RecordedAudioPlayer recordingId={recordingId} />;
    }

    return <WebAudioPlayer url={url} />;
};

export default AudioPlayer;
