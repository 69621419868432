import { CellWrapper } from './styled';

interface Props {
    value?: string;
}

const Name = ({ value }: Props) => (
    <CellWrapper>
        {value}
    </CellWrapper>
);
export default Name;
