import { Conversation } from '@twilio/conversations';
import { useQuery } from 'react-query';

const useMessagesCount = (
    customerId: string,
    conversation?: Conversation,
) => useQuery<unknown, unknown, number>(
    ['messages-count', customerId],
    () => conversation?.getMessagesCount(),
    {
        enabled: Boolean(conversation),
        cacheTime: 0,
    },
);

export default useMessagesCount;
