import { CellWrapper } from './styled';

interface Props {
    value?: string;
}

const LabelColumn = ({ value }: Props) => (
    <CellWrapper>
        {value}
    </CellWrapper>
);

export default LabelColumn;
