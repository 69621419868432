import { SortOrder } from 'types';

const sortByNumericField = (
    fieldName: string,
    direction: SortOrder,
) => (objA: Record<string, unknown>, objB: Record<string, unknown>) => {
    const valueA = (objA[fieldName] || 0) as number;
    const valueB = (objB[fieldName] || 0) as number;
    const multiplier = direction === 'asc' ? 1 : -1;
    return (valueA - valueB) * multiplier;
};

const sortByDateFieldFn = (
    fieldName: string,
    direction: SortOrder,
) => (objA: Record<string, unknown>, objB: Record<string, unknown>) => {
    const valueA = objA[fieldName] as Date;
    const valueB = objB[fieldName] as Date;
    const multiplier = direction === 'asc' ? 1 : -1;
    return (valueA.getTime() - valueB.getTime()) * multiplier;
};

const opportunitiesSortMap: Record<string, Function> = {
    score: sortByNumericField,
    createdAt: sortByDateFieldFn,
    updatedAt: sortByDateFieldFn,
};

const sortOpportunities = (fieldName: string, direction: SortOrder) => {
    const sortFn = opportunitiesSortMap[fieldName];
    if (sortFn) return sortFn(fieldName, direction);
    return () => 0;
};

export default sortOpportunities;
