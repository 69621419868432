import {
    useCallback, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import DotsVertical from 'components/icons/dotsVertical';
import FloatingFilterButton from 'components/FloatingFilterButton';
import TaskStatusPicker from 'components/TaskStatusPicker';
import TaskWithStatus from 'components/TaskWithStatus';
import TasksOpened from 'components/TasksOpened';
import { TaskStatus } from 'types';
import {
    Actions, Container, Header, TasksListContainer, Title,
} from './styled';

const tasksStatusSwitchOptions = [
    { name: 'open', label: 'Open' },
    { name: 'solved', label: 'Solved' },
    { name: 'archived', label: 'Archived' },
];

const TasksList = () => {
    const { agentId = '', taskStatus = 'open' } = useParams<{ agentId: string; taskStatus: TaskStatus }>();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const showOpenTasks = useMemo(() => taskStatus.trim().toLowerCase() === 'open', [taskStatus]);

    const switchStatusFilter = useCallback(
        (status: string) => navigate(`/${agentId}/tasks/${status}`),
        [agentId, navigate],
    );

    return (
        <Container>
            <Header>
                <Title>Tasks</Title>
                <Actions>
                    <FloatingFilterButton
                        value={searchQuery}
                        onChange={setSearchQuery}
                    />
                    <TaskStatusPicker
                        buttonIcon={<DotsVertical />}
                        taskStatuses={tasksStatusSwitchOptions}
                        value={taskStatus}
                        onChange={switchStatusFilter}
                    />
                </Actions>
            </Header>
            <TasksListContainer>
                <RenderIf isTrue={showOpenTasks}>
                    <TasksOpened
                        searchQuery={searchQuery}
                    />
                </RenderIf>
                <RenderIf isTrue={!showOpenTasks}>
                    <TaskWithStatus
                        status={taskStatus as Exclude<TaskStatus, 'open'>}
                        searchQuery={searchQuery}
                    />
                </RenderIf>
            </TasksListContainer>
        </Container>
    );
};

export default TasksList;
