import { ReactNode, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import User from 'components/icons/user';
import LoadingShape from 'components/LoadingShape';
import { Customer } from 'data/firestore/agent/customer/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import {
    LoadingBarsContainer,
    MessageContainer,
    MessageContent,
    StyledAvatar,
    MessageRole,
    MessageContentContainer,
    StyledSaraAvatar,
} from './styled';

interface ConversationMessageProps {
    role?: string;
    from?: Customer | null;
    content?: ReactNode;
    isLoading?: boolean;
}

const getRoleText = (role:string, displayName: string) => (role === 'assistant' ? 'Sara (Assistant)' : displayName);

const getValue = (role:string, from: string) => (role === 'assistant' ? role : from || role);

const ConversationMessage = ({
    role = 'assistant',
    from,
    content = '',
    isLoading = false,
}:ConversationMessageProps) => {
    const displayName = useMemo(
        () => {
            const { firstName, lastName, alias } = from || {};
            return getDisplayName({ firstName, lastName, alias });
        },
        [from],
    );

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    return (
        <MessageContainer>
            <RenderIf isTrue={isLoading}>
                <LoadingShape shape="circle" width="38px" height="38px" />
                <LoadingBarsContainer>
                    <LoadingShape width="90%" height="5px" shape="rect" />
                    <LoadingShape width="70%" height="5px" shape="rect" />
                </LoadingBarsContainer>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <RenderIf isTrue={role === 'assistant'}>
                    <StyledSaraAvatar />
                </RenderIf>
                <RenderIf isTrue={role === 'user'}>
                    <StyledAvatar
                        value={getValue(role, from?.id || from?.phoneNumber || '')}
                        icon={<User />}
                        initials={initials}
                        src={from?.photoURL}
                    />
                </RenderIf>
                <MessageContentContainer>
                    <MessageRole>{getRoleText(role, displayName)}</MessageRole>
                    <MessageContent>{content}</MessageContent>
                </MessageContentContainer>
            </RenderIf>
        </MessageContainer>
    );
};

export default ConversationMessage;
