import { useMemo, useContext } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import context from 'context';
import { SMS_CHAT_DRAWER } from '../constants';

const useSMSChat = () => {
    const { smsChatProps } = useContext(context);
    const { isOpen, ...data } = smsChatProps;
    const [openModal, closeModal] = useOpenModal(SMS_CHAT_DRAWER);

    return useMemo(
        () => ({
            isOpen: isOpen as boolean,
            data,
            openChat: openModal,
            closeChat: closeModal,
        }),
        [closeModal, isOpen, data, openModal],
    );
};

export default useSMSChat;
