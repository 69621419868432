import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChatIcon from 'components/icons/chat';
import { Accordion } from 'react-rainbow-components';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import { StyledButtonIcon } from '../../styled';
import getNotificationRuleDescription from './helpers/getNotificationRuleDescription';
import {
    ItemContent,
    IconContainer,
    ItemDescription,
    ItemHeader,
    ItemLabel,
    ItemTitleContainer,
    StyledAccordionSection,
    StyledTimelineMarker,
    ItemActions,
} from './styled';
import MessageExample from './messageExample';
import useManageNotificationRules from '../hooks/useManageNotificationRules';

interface ReminderProps {
    id: string;
    eventTypeId: string;
    when: number;
    instructions: string;
    relativeTo: 'event-time' | 'creation-time';
    messageExample?: string;
    label: string;
}

const NotificationRule = ({
    id,
    eventTypeId,
    when,
    instructions,
    relativeTo,
    messageExample,
    label,
}: ReminderProps) => {
    const { agentId = '' } = useParams();
    const [activeSection, setActiveSection] = useState<string>();

    const {
        edit: editNotificationRule,
        remove: removeNotificationRule,
    } = useManageNotificationRules({ agentId, eventTypeId });

    const handleEditNotificationRule = useCallback(
        () => {
            editNotificationRule({
                id,
                when,
                relativeTo,
                instructions,
                messageExample,
            });
        },
        [editNotificationRule, id, instructions, messageExample, relativeTo, when],
    );

    const handleDeleteNotificationRule = useCallback(
        () => removeNotificationRule(id),
        [id, removeNotificationRule],
    );

    const title = useMemo(
        () => (activeSection === 'details' ? 'Hide Details' : 'Show Details'),
        [activeSection],
    );

    return (
        <StyledTimelineMarker
            icon={(
                <IconContainer>
                    <ChatIcon />
                </IconContainer>
            )}
            label={(
                <ItemHeader>
                    <ItemTitleContainer>
                        <ItemLabel>{label}</ItemLabel>
                        <ItemDescription>
                            {getNotificationRuleDescription(when, relativeTo)}
                        </ItemDescription>
                    </ItemTitleContainer>
                    <ItemActions>
                        <StyledButtonIcon
                            variant="base"
                            borderRadius="semi-square"
                            icon={<Pencil />}
                            onClick={handleEditNotificationRule}
                        />
                        <StyledButtonIcon
                            variant="base"
                            borderRadius="semi-square"
                            icon={<Trash />}
                            onClick={handleDeleteNotificationRule}
                        />
                    </ItemActions>
                </ItemHeader>
            )}
        >
            <Accordion
                activeSectionNames={activeSection}
                onToggleSection={(_, name) => setActiveSection(name as string)}
            >
                <StyledAccordionSection name="details" label={title}>
                    <ItemContent>
                        <div>
                            <ItemLabel>Instructions:</ItemLabel>
                            <ItemDescription>{instructions}</ItemDescription>
                        </div>
                        <div>
                            <ItemLabel>Message Example:</ItemLabel>
                            <MessageExample message={messageExample} />
                        </div>
                    </ItemContent>
                </StyledAccordionSection>
            </Accordion>
        </StyledTimelineMarker>
    );
};

export default NotificationRule;
