import { StyledLink } from './styled';
import { DisplayContext } from './types';

const actionMessageMap: Record<string, Record<string, string>> = {
    'inbox-read': {
        inbox: 'Saw this Inbox',
        customer: 'Saw this Inbox',
    },
    'inbox-solved': {
        inbox: 'Solved this Inbox',
        customer: 'Solved this Inbox',
    },
    'inbox-removed': {
        inbox: 'Deleted this Inbox',
        customer: 'Deleted this Inbox',
    },
    'inbox-reopen': {
        inbox: 'Reopened this Inbox',
        customer: 'Reopened this Inbox',
    },
    'inbox-spam': {
        inbox: 'Marked this number as “Spam”',
        customer: 'Marked this number as “Spam”',
    },
    'inbox-not-spam': {
        inbox: 'Marked this number as "Not spam”',
        customer: 'Marked this number as “Not spam”',
    },
};

const getActionLabel = ({
    action,
    agentId,
    inboxId,
    context,
}: {
    action: string;
    agentId: string;
    inboxId: string | null;
    context: DisplayContext;
}) => {
    const actionLabel = (actionMessageMap[action] || {})[context];

    const linkToInbox = (
        context === 'customer' && [
            'inbox-read',
            'inbox-solved',
            'inbox-removed',
            'inbox-reopen',
        ].includes(action)
            ? <StyledLink to={`/${agentId}/inbox/${inboxId}/overview`}>{`#${inboxId?.slice(-5)}`}</StyledLink>
            : null
    );
    if (actionLabel) {
        return (
            <>
                {actionLabel}
                {' '}
                {linkToInbox}
            </>
        );
    }
    return null;
};

const getMessageLabel = ({
    type,
    content,
    agentId = '',
    inboxId = '',
    context = 'customer',
}: {
    type: string;
    content: Record<string, unknown>;
    agentId: string;
    inboxId: string | null;
    opportunityId: string | null;
    context: DisplayContext;
}) => {
    if (type === 'call') return 'Made a Phone Call';
    if (type === 'SMS') return 'Sent a SMS';
    if (type === 'message-sent') return 'Sent a Message';
    if (type === 'file') return 'Uploaded a File';
    if (type === 'action') {
        const action = content.action as string;
        return getActionLabel({
            action,
            agentId,
            inboxId,
            context,
        });
    }
    if (type === 'customer-create') return 'Created this Customer';
    if (type === 'customer-update') return 'Updated this Customer Information';

    if (type === 'calendar-event-created') return `Book an Event ${(content.eventId as string).slice(-5)}`;
    if (type === 'calendar-event-accepted') return `Accepted the Event ${(content.eventId as string).slice(-5)}`;
    if (type === 'calendar-event-rejected') return `Rejected the Event ${(content.eventId as string).slice(-5)}`;
    if (type === 'calendar-event-removed') return `Deleted the Event ${(content.eventId as string).slice(-5)}`;
    if (type === 'calendar-event-rescheduled') return `Reschedule the Event ${(content.eventId as string).slice(-5)}`;
    if (type === 'calendar-event-sms-sent') {
        return `Sent a SMS reminder for event ${(content.eventId as string).slice(-5)}`;
    }

    if (type === 'opportunity-created') {
        return (
            <>
                Created a new Opportunity
            </>
        );
    }

    if (type === 'opportunity-stage-changed') {
        return (
            <>
                Changed this Opportunity&apos;s stage
            </>
        );
    }

    if (type === 'opportunity-assigned') {
        return (
            <>
                Changed this Opportunity&apos;s owner
            </>
        );
    }

    if (type === 'opportunity-unassigned') {
        return (
            <>
                Removed this Opportunity&apos;s owner
            </>
        );
    }

    if (type === 'opportunity-removed') {
        return (
            <>
                Removed this Opportunity
            </>
        );
    }

    if (type === 'opportunity-qualified') {
        if (content.prevScore === null) {
            return (
                <>
                    Assigned a score to this Opportunity
                </>
            );
        }
        return (
            <>
                Changed this Opportunity&apos;s score
            </>
        );
    }

    if (type === 'opportunity-unqualified') {
        return (
            <>
                Removed this Opportunity&apos;s score
            </>
        );
    }

    if (type === 'opportunity-amount-updated') {
        return (
            <>
                Changed this Opportunity&apos;s amount
            </>
        );
    }

    if (type === 'note') {
        return (
            <>
                Left a Note
            </>
        );
    }

    return 'Unknown';
};

export default getMessageLabel;
