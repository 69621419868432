import {
    MouseEvent, useCallback, useMemo, useState,
} from 'react';
import { Column } from 'react-rainbow-components';
import { useOutletContext, useParams } from 'react-router-dom';
import { ViewContextType } from 'pages/Opportunities/types';
import DateTimeColumn from 'components/DateTimeColumn';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import getSign from 'helpers/getSign';
import useQueryPaging from 'hooks/useQueryPaging';
import { PAGE_SIZE } from '../../../../constants';
import UserColumn from './columns/user';
import OpportunityColumn from './columns/opportunity';
import StageColumn from './columns/stage';
import ActionsColumn from './columns/actions';
import {
    TableContainer,
    PaginationContainer,
    EntriesText,
} from '../styled';

const getFieldValue = (opportunity: EntityGet<Opportunity>, fieldName: string): number => {
    if (fieldName === 'name') {
        return (
            opportunity.score === null
                ? -1
                : opportunity.score as number
        );
    }

    if (fieldName === 'createdAt') {
        return opportunity.createdAt.getTime();
    }

    return 0;
};

const OpportunitiesViewList = () => {
    const { agentId = '' } = useParams();
    const {
        opportunities,
        isLoading,
        totalHits,
    } = useOutletContext<ViewContextType>();

    const { activePage, gotoPage } = useQueryPaging({
        maxPages: Math.ceil(totalHits / PAGE_SIZE),
    });

    const {
        data,
        pages,
        firstItemOfActivePage,
        lastItemOfActivePage,
        setPage,
    } = useMemo(
        () => {
            const start = Math.min(PAGE_SIZE * (activePage - 1), totalHits);
            const end = Math.min(PAGE_SIZE * activePage, totalHits);
            const dataToShow = opportunities.slice(start, end);

            return ({
                data: dataToShow,
                pages: Math.ceil(totalHits / PAGE_SIZE),
                firstItemOfActivePage: start + 1,
                lastItemOfActivePage: end,
                setPage: (page: number) => {
                    if (isLoading) return;
                    if (page === activePage) return;
                    gotoPage(page);
                },
            });
        },
        [activePage, totalHits, opportunities, isLoading, gotoPage],
    );

    const [sortedBy, setSortedBy] = useState<string>();
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>();

    const handleChangePage = useCallback(
        (event: React.ChangeEvent<unknown>, page: number) => setPage(page),
        [setPage],
    );

    const handleOnSort = useCallback(
        (
            event: MouseEvent<HTMLElement>,
            field: string,
            nextSortDirection: 'asc' | 'desc',
        ) => {
            setSortedBy(field);
            setSortDirection(nextSortDirection);
        },
        [],
    );

    const sortedData = useMemo(
        () => {
            if (!sortDirection || !sortedBy) return data;

            const newData = [...data];
            const reverse = (sortDirection === 'asc' ? 1 : -1);

            return newData.sort((opportunityA, opportunityB) => {
                const scoreA = getFieldValue(opportunityA, sortedBy);
                const scoreB = getFieldValue(opportunityB, sortedBy);
                return reverse * getSign(scoreA - scoreB);
            });
        },
        [data, sortDirection, sortedBy],
    );

    return (
        <TableContainer>
            <Table
                data={sortedData}
                isLoading={isLoading}
                keyField="id"
                onSort={handleOnSort}
                sortDirection={sortDirection}
                sortedBy={sortedBy}
            >
                <Column
                    header="Opportunity"
                    field="name"
                    component={OpportunityColumn}
                    sortable
                />
                <Column
                    header="Customer"
                    component={UserColumn}
                />
                <Column
                    header="Stage"
                    field="stageId"
                    component={StageColumn}
                    defaultWidth={200}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    component={DateTimeColumn}
                    defaultWidth={210}
                    showRelativeDate
                    sortable
                />
                <Column
                    header="Actions"
                    agentId={agentId}
                    component={ActionsColumn}
                    defaultWidth={120}
                />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handleChangePage}
                />
            </PaginationContainer>
        </TableContainer>
    );
};

export default OpportunitiesViewList;
