import React from 'react';
import EmptyMessage from 'components/EmptyMessage';
import LoadingShape from 'components/LoadingShape';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import CustomerMessage from './message';
import {
    EmptyMessageContainer, EmptyMessageTitle, EmptyMessageEmoji, StyledVirtuoso,
    NotificationsContainer,
} from './styled';

const virtuosoComponents = {
    EmptyPlaceholder: () => (
        <EmptyMessageContainer>
            <EmptyMessageEmoji>
                📭
            </EmptyMessageEmoji>
            <EmptyMessageTitle>
                You&apos;re all caught up!
            </EmptyMessageTitle>
            <EmptyMessage message="Stay tuned for upcoming notifications." />
        </EmptyMessageContainer>
    ),
};

const getvVirtuosoItemContent = (
    onClickFn: ((customer: EntityGet<Customer>) => void) = () => {},
// eslint-disable-next-line react/function-component-definition
) => (_: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    const { id } = props;
    return (
        <CustomerMessage
            onClick={onClickFn}
            key={id}
            customerData={props}
        />
    );
};

interface CustomerMessagesProps {
    data?: Array<EntityGet<Customer>>;
    onClick?: ((customer: EntityGet<Customer>) => void);
    onLoadMore?: (atBottom:boolean) => void;
    isLoading?: boolean | null;
}

const CustomerMessages: React.FC<CustomerMessagesProps> = ({
    data,
    onClick,
    onLoadMore,
    isLoading,
}: CustomerMessagesProps) => {
    if (isLoading) {
        return (
            <div className="rainbow-flex rainbow-flex-grow rainbow-flex_column rainbow-p-around_small">
                <LoadingShape height="0.75rem" width="150px" />
                <LoadingShape height="0.75rem" width="300px" />
                <LoadingShape height="0.75rem" width="200px" />
            </div>
        );
    }

    return (
        <NotificationsContainer>
            <StyledVirtuoso
                data={data}
                initialTopMostItemIndex={0}
                components={virtuosoComponents}
                itemContent={getvVirtuosoItemContent(onClick)}
                atBottomStateChange={onLoadMore}

            />
        </NotificationsContainer>
    );
};

export default CustomerMessages;
