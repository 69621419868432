import { IconProps } from 'types';

const AlertTriangle = ({ className, title }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={51}
        height={51}
        viewBox="0 0 51 51"
        fill="none"
    >
        <title>{title}</title>
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M25.417 16.963v7.878M25.417 32.67v.099M10.834 39.88H40a4.167 4.167 0 0 0 3.834-5.73L29.042 8.63a4.167 4.167 0 0 0-7.292 0L6.96 34.15a4.167 4.167 0 0 0 3.646 5.73"
        />
    </svg>
);
export default AlertTriangle;
