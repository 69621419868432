import { useCallback, useMemo, useState } from 'react';
import { Option, RenderIf } from 'react-rainbow-components';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Calendareventstatus } from 'data/firestore/agent/calendareventstatus/types';
import {
    StatusBadgeContainer,
    StyledPicklistButton,
    StyledSpinner,
} from './styled';

interface EventStatusBadgeProps {
    value?: string;
    eventStatuses?: Calendareventstatus[];
    onUpdate?: (value: string) => boolean;
}

const EventStatusBadge = ({
    value: valueInProps,
    eventStatuses = [],
    onUpdate = () => true,
}: EventStatusBadgeProps) => {
    const [isLoading, setLoading] = useState(false);
    const { picklistValue, picklistColor } = useMemo(() => {
        if (valueInProps) {
            const found = eventStatuses.find(
                (eventStatus) => eventStatus.id === valueInProps,
            );
            if (found) {
                const {
                    id: eventStatusId,
                    name: eventStatusName,
                    color: eventStatusColor,
                } = found;
                return {
                    picklistValue: {
                        label: eventStatusName,
                        name: eventStatusId,
                        value: eventStatusId,
                        searchableText: eventStatusName,
                    },
                    picklistColor: eventStatusColor,
                };
            }
        }
        return {};
    }, [eventStatuses, valueInProps]);

    const handleOnChange = useCallback(
        async (value: PicklistValue) => {
            setLoading(true);
            await onUpdate(value.value);
            setLoading(false);
        },
        [onUpdate],
    );

    const pickListOptions = useMemo(
        () => eventStatuses.map(
            (eventStatus) => (
                <Option
                    key={`event-status__${eventStatus.id}`}
                    label={eventStatus.name}
                    name={eventStatus.id}
                    value={eventStatus.id}
                    searchableText={eventStatus.name}
                />
            ),
        ),
        [eventStatuses],
    );

    return (
        <StatusBadgeContainer>
            <StyledPicklistButton
                value={picklistValue}
                variant="bare"
                borderRadius="semi-rounded"
                size="small"
                hideLabel
                enableSearch
                onChange={handleOnChange}
                color={picklistColor}
                disabled={isLoading}
            >
                <Option name="header" label="Select status" variant="header" />
                {pickListOptions}
            </StyledPicklistButton>
            <RenderIf isTrue={isLoading}>
                <StyledSpinner variant="base" type="arc" />
            </RenderIf>
        </StatusBadgeContainer>
    );
};

export default EventStatusBadge;
