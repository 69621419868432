import { RenderIf } from 'react-rainbow-components';
import AlertOctagon from 'components/icons/alertOctagon';
import { AlertContainer, AlertIcon } from './styled';
import { AlertVariant } from './types';

interface AlertProps {
    variant?: AlertVariant;
}

const iconMap: Record<string, React.ReactNode> = {
    error: <AlertOctagon />,
};

const Alert = ({ children, variant }: React.PropsWithChildren<AlertProps>) => {
    const icon = iconMap[variant || 'default'];
    return (
        <AlertContainer variant={variant}>
            <RenderIf isTrue={!!icon}>
                <AlertIcon>{icon}</AlertIcon>
            </RenderIf>
            {children}
        </AlertContainer>
    );
};

export default Alert;
