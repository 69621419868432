import ListTile from 'components/ListTile';
import { InnerContainer, StyledChip } from './styled';

interface Params {
    label: string;
    onClearValue?: () => void;
}

const SelectedValue = ({
    label,
    onClearValue = () => {},
}: Params) => (
    <InnerContainer>
        <StyledChip
            label={(
                <ListTile
                    title={label}
                />
            )}
            borderRadius="semi-square"
            onDelete={onClearValue}
        />
    </InnerContainer>
);

export default SelectedValue;
