import {
    ReactNode, useCallback, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Column, RenderIf, Spinner } from 'react-rainbow-components';
import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';
import { Wordcloud } from '@visx/wordcloud';
import { DateTime } from 'luxon';
import ButtonIcon from 'components/ButtonIcon';
import SortAscIcon from 'components/icons/sortAsc';
import SortDescIcon from 'components/icons/sortDesc';
import Table from 'components/Table';
import useHttpQuery from 'data/firestore/useHttpQuery';
import useAgentData from 'hooks/useAgentData';
import DateFilter from './dateFilter';
import { SortOrder } from '../../../types';
import {
    Content, StyledInsightSectionContainer,
} from '../styled';
import LabelColumn from './columns/label';
import ValueColumn from './columns/value';
import {
    TableContainer, TableHeading, TableTitle, WordCloudContainer,
} from './styled';

const sortDirectionIconMap: Record<SortOrder, ReactNode> = {
    asc: <SortAscIcon className="rainbow-m-right_small" />,
    desc: <SortDescIcon className="rainbow-m-right_small" />,
};

const fixedValueGenerator = () => 0.5;

interface TopicInfo {
    name: string;
    displayName: number;
    count: number;
    percent: number;
}

interface WordData {
    text: string;
    value: number;
}

const colors = ['#ABA4F6', '#8F85F3', '#7367F0', '#5C52C0', '#453E90'];

const TopicsAnalysis = () => {
    const { agentId = '' } = useParams();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const [dateFilter, setDateFilter] = useState<string | Date[]>('current-month');
    const [sortDirection, setSortDirection] = useState<SortOrder>('desc');

    const { from, to } = useMemo(
        () => {
            const timeZone = agent?.companyAddress.timezone
                || Intl.DateTimeFormat().resolvedOptions().timeZone;

            const now = DateTime.now().setZone(timeZone);

            if (Array.isArray(dateFilter)) {
                const [fromDate, toDate] = dateFilter;
                return {
                    from: DateTime.fromJSDate(fromDate).setZone(timeZone, { keepLocalTime: true }).startOf('day'),
                    to: toDate && DateTime.fromJSDate(toDate).setZone(timeZone, { keepLocalTime: true }).endOf('day'),
                };
            }

            if (dateFilter === 'last-month') {
                return {
                    from: now.minus({ month: 1 }).startOf('month'),
                    to: now.minus({ month: 1 }).endOf('month'),
                };
            }

            if (dateFilter === 'current-year') {
                return {
                    from: DateTime.now().setZone(timeZone).startOf('year'),
                    to: DateTime.now().setZone(timeZone).endOf('year'),
                };
            }

            if (dateFilter === 'last-year') {
                return {
                    from: DateTime.now().setZone(timeZone).minus({ months: 12 }).startOf('year'),
                    to: DateTime.now().setZone(timeZone).minus({ months: 12 }).endOf('year'),
                };
            }

            return {
                from: now.startOf('month'),
                to: now.endOf('month'),
            };
        },
        [agent, dateFilter],
    );

    const { data: reportData, isLoading: isLoadingData } = useHttpQuery<TopicInfo[]>({
        key: [
            'topics-snalysis',
            agentId,
            from.toJSDate().toISOString().slice(0, 10),
            to?.toJSDate().toISOString().slice(0, 10),
        ],
        pathname: `/agents/${agentId}/insights/topics/analysis?&start=${encodeURIComponent(from.toISO() || '')}&end=${encodeURIComponent(to?.toISO() || '')}`,
        queryOptions: {
            enabled: Boolean(agent?.id && from.toISO() && to?.toISO()),
            retryOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            gcTime: 24 * 60 * 60 * 1000,
            staleTime: 24 * 60 * 60 * 1000,
        },
    });

    const fontScaleFn = useMemo(
        () => scaleLinear({
            domain: [
                Math.min(...(reportData || []).map((topic) => topic.count)),
                Math.max(...(reportData || []).map((topic) => topic.count)),
            ],
            range: [12, 96],
        }),
        [reportData],
    );

    const fontSizeSetter = useCallback(
        (data: WordData) => fontScaleFn(data.value),
        [fontScaleFn],
    );

    const plotData = useMemo(
        () => (reportData || []).map(
            (topic) => ({ text: topic.displayName, value: topic.count }) as unknown as WordData,
        ),
        [reportData],
    );

    const sortedData = useMemo(
        () => {
            const multiplier = sortDirection === 'asc' ? -1 : 1;
            return (reportData || []).sort(
                (valueA, valueB) => multiplier * (valueB.count - valueA.count),
            );
        },
        [reportData, sortDirection],
    );

    const handleChangeSortDirection = useCallback(
        () => {
            if (sortDirection === 'asc') return setSortDirection('desc');
            return setSortDirection('asc');
        },
        [sortDirection],
    );

    const isLoading = isLoadingAgent || isLoadingData;

    return (
        <StyledInsightSectionContainer
            label="Topics Analysis"
            // TODO: add description
            description=""
            filter={(
                <DateFilter
                    value={dateFilter}
                    onChange={setDateFilter}
                    disabled={isLoading}
                />
            )}
        >
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner size="large" variant="brand" type="arc" />
                </RenderIf>
                <RenderIf isTrue={!isLoading && plotData.length > 0}>
                    <WordCloudContainer>
                        <ParentSize>
                            {(parent) => (
                                <Wordcloud
                                    width={parent.width}
                                    height={Math.max(parent.height, 350)}
                                    words={plotData}
                                    font="Public Sans"
                                    fontSize={fontSizeSetter}
                                    padding={2}
                                    spiral="archimedean"
                                    rotate={0}
                                    random={fixedValueGenerator}
                                >
                                    {(cloudWords) => cloudWords.map((w, i) => {
                                        const key = `word-${i}-${w.text}`;
                                        return (
                                            <Text
                                                key={key}
                                                fill={colors[i % colors.length]}
                                                textAnchor="middle"
                                                transform={`translate(${w.x}, ${w.y})`}
                                                fontSize={w.size}
                                                fontFamily={w.font}
                                            >
                                                {w.text}
                                            </Text>
                                        );
                                    })}
                                </Wordcloud>
                            )}
                        </ParentSize>
                    </WordCloudContainer>
                    <TableContainer>
                        <TableHeading>
                            <TableTitle>Number of calls per topic </TableTitle>
                            <ButtonIcon
                                icon={sortDirectionIconMap[sortDirection]}
                                onClick={handleChangeSortDirection}
                            />
                        </TableHeading>
                        <Table data={[...sortedData]} isLoading={isLoading} keyField="displayName" hideTableHeader>
                            <Column field="displayName" component={LabelColumn} />
                            <Column field="count" component={ValueColumn} />
                        </Table>
                    </TableContainer>
                </RenderIf>
            </Content>
        </StyledInsightSectionContainer>
    );
};

export default TopicsAnalysis;
