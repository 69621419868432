import { StyledRadio, StyledRadioItem } from './styled';

interface RadioItemProps {
    children: React.ReactNode;
    isHover?: boolean;
    isFocused?: boolean;
    isSelected?: boolean;
    disabled?: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

export default RadioItem;
