/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const greaterThan: Snippet = {
    label: 'greaterThan',
    body: '{{#greaterThan ${1:value1} ${2:value2}}}\n\t${0}\n{{/greaterThan}}',
    documentation: 'greaterThan statement',
};

export default greaterThan;
