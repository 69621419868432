/* eslint-disable react/no-unused-prop-types */
import useCalendars from 'data/firestore/agent/calendar/useCollection';
import { CalendarPickerProps } from './types';
import CalendarPickerVariantSelect from './select';
import CalendarPickerVariantPicker from './picker';

const CalendarPicker = (props: CalendarPickerProps) => {
    const {
        agentId = '',
        variant = 'select',
        ...rest
    } = props;

    const { data: calendars = [], isLoading } = useCalendars(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    if (variant === 'picker') {
        return (
            <CalendarPickerVariantPicker
                {...rest}
                calendars={calendars}
                isLoading={isLoading}
            />
        );
    }

    return (
        <CalendarPickerVariantSelect
            {...rest}
            calendars={calendars}
            isLoading={isLoading}
        />
    );
};

export default CalendarPicker;
