/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const lessThan: Snippet = {
    label: 'lessThan',
    body: '{{#lessThan ${1:value1} ${2:value2}}}\n\t${0}\n{{/lessThan}}',
    documentation: 'lessThan statement',
};

export default lessThan;
