import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import { Timestamp } from 'firebase/firestore';
import Button from 'components/Button';
import Pencil from 'components/icons/pencil3';
import Plus from 'components/icons/plus';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import { getFormatter } from 'data/services/date/formatter';
import FileList from './filesList';
import {
    Actions, Container, StyledButton, StyledTextarea,
} from './styled';

interface NotesEditorProps {
    value?: string;
    files?: Calendarevent['files'];
    customerInfo?: Calendarevent['people'];
    onUpdate?: (value?: string) => boolean;
}

const dobFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'utc',
});

const normalizeDateValue = (value: Timestamp | string): Date => {
    if (typeof value === 'string') return new Date(value);
    return (value as Timestamp).toDate();
};

const NotesEditor = ({
    value: valueInProps,
    files,
    customerInfo,
    onUpdate = () => true,
}: NotesEditorProps) => {
    const [value, setValue] = useState(valueInProps);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const toggleEditButtonProps = useMemo(
        () => {
            if (value) {
                return {
                    icon: <Pencil />,
                    label: 'Edit Note',
                };
            }
            return {
                icon: <Plus />,
                label: 'Add Note',
            };
        },
        [value],
    );

    const updateButtonLabel = useMemo(
        () => {
            if (valueInProps) return 'Update';
            return 'Add';
        },
        [valueInProps],
    );

    const customerDOB = useMemo(
        () => {
            if (customerInfo?.dob) {
                // TODO: bug in data layer, dob should come as Date, not Timestamp
                const dob = normalizeDateValue(customerInfo.dob as unknown as Timestamp);
                return `Date of birth: ${dobFormatter.format(dob)}`;
            }
            return undefined;
        },
        [customerInfo?.dob],
    );

    const handleAddUpdateClick = useCallback(
        async () => {
            setLoading(true);
            const success = await onUpdate(value);
            setLoading(false);
            if (success) {
                setEditMode(false);
            }
        },
        [onUpdate, value],
    );

    const handleCancelClick = useCallback(
        () => {
            setValue(valueInProps);
            setEditMode(false);
        },
        [valueInProps],
    );

    useEffect(() => setValue(valueInProps), [valueInProps]);

    return (
        <Container>
            <RenderIf isTrue={editMode}>
                <StyledTextarea
                    rows={2}
                    placeholder="Write a note here"
                    value={value}
                    hideLabel
                    required
                    onChange={(e) => setValue(e.target.value)}
                    disabled={isLoading}
                />
                <Actions>
                    <Button
                        label="Cancel"
                        borderRadius="semi-rounded"
                        variant="outline-brand"
                        size="small"
                        onClick={handleCancelClick}
                        disabled={isLoading}
                    />
                    <Button
                        label={updateButtonLabel}
                        borderRadius="semi-rounded"
                        variant="brand"
                        size="small"
                        onClick={handleAddUpdateClick}
                        disabled={isLoading}
                    />
                </Actions>
            </RenderIf>
            <RenderIf isTrue={!editMode}>
                <RenderIf isTrue={customerDOB}>
                    <div>{customerDOB}</div>
                </RenderIf>
                <RenderIf isTrue={valueInProps}>
                    <div>{valueInProps}</div>
                </RenderIf>
                <RenderIf isTrue={files?.length}>
                    <FileList data={files} />
                </RenderIf>
                <StyledButton
                    size="small"
                    borderRadius="semi-rounded"
                    variant="base"
                    onClick={() => setEditMode(true)}
                >
                    {toggleEditButtonProps.icon}
                    {toggleEditButtonProps.label}
                </StyledButton>
            </RenderIf>
        </Container>
    );
};

export default NotesEditor;
