import { ItemUser } from 'data/firestore/globals';
import getInitials from 'data/services/string/getInitials';
import AvatarGroup from 'components/AvatarGroup';

interface Props {
    users: ItemUser[];
}

const ReadBy = ({ users }: Props) => {
    const avatars = users.map(
        ({ displayName, photoURL }) => ({
            title: displayName || 'Unknown',
            initials: getInitials(displayName || 'Unknown'),
            src: photoURL,
            tooltip: displayName,
        }),
    );
    return (
        <AvatarGroup maxAvatars={3} avatars={avatars} size="small" />
    );
};

export default ReadBy;
