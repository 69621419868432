import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import ButtonIcon from 'components/ButtonIcon';
import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil2';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { ActionsCellWrapper } from './styled';

interface Props {
    row?: Record<string, string>;
    agentId: string;
    onEdit?: (row: Record<string, string>) => void;
    onDelete?: () => void;
}

const ActionsColumn = ({
    row = {},
    agentId = '',
    onEdit = () => {},
    onDelete = () => {},
}: Props) => {
    const {
        mutateAsync: deleteLocation,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/agents/${agentId}/locations/${row.id}`,
        method: 'DELETE',
        onSuccess: onDelete,
    });

    const requestDeleteLocation = useCallback(
        async ({ name }: Record<string, string>) => {
            const result = await confirmModal({
                header: '',
                question: (
                    <ModalBody
                        icon={<Trash />}
                        title="Delete Location?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteLocation({});
                showAppNotification({
                    title: 'Location Removed',
                    description: `Location ${name} has been removed.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteLocation],
    );

    return (
        <ActionsCellWrapper>
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Pencil />}
                tooltip="Edit Location"
                size="small"
                onClick={() => onEdit(row)}
            />
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Trash />}
                tooltip="Remove Location"
                size="small"
                onClick={() => requestDeleteLocation(row)}
            />
        </ActionsCellWrapper>
    );
};

export default ActionsColumn;
