import { IconProps } from 'types';

const CircleWithDotIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        viewBox="0 0 22 23"
        width={22}
        height={23}
        fill="none"
    >
        <title>{title}</title>
        <circle cx="11" cy="11.3134" r="0.916667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="11" cy="11.3135" r="8.25" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default CircleWithDotIcon;
