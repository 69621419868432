import { Monaco } from '@monaco-editor/react';
import { languages } from 'monaco-editor';
import { Variable } from './types';

let registered = false;

const registerVariables = (monaco: Monaco, variables: Variable[]) => {
    if (registered || !variables.length) return;
    monaco.languages.registerCompletionItemProvider(
        'handlebars',
        {
            triggerCharacters: ['{'],
            provideCompletionItems: (model, position) => {
                const word = model.getWordUntilPosition(position);
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn,
                };
                const currentPrefixBraces = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: Math.max(word.startColumn - 2, 1),
                    endColumn: word.startColumn,
                });
                const hasPrefixBraces = currentPrefixBraces === '{{';
                if (hasPrefixBraces) {
                    range.startColumn = word.startColumn - 2;
                }
                const hasSinglePrefixBrace = currentPrefixBraces === '{';
                if (hasSinglePrefixBrace) {
                    range.startColumn = word.startColumn - 1;
                }
                const currentSuffixBraces = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.endColumn,
                    endColumn: word.endColumn + 2,
                });

                const hasSuffixBraces = currentSuffixBraces === '}}';
                if (hasSuffixBraces) {
                    range.endColumn = word.endColumn + 2;
                }
                const hasSingleSuffixBrace = currentSuffixBraces === '}';
                if (hasSingleSuffixBrace) {
                    range.endColumn = word.endColumn + 1;
                }
                return {
                    suggestions: variables.map((variable) => ({
                        label: variable.value,
                        filterText: `{{${variable.value}}}`,
                        kind: monaco.languages.CompletionItemKind.Variable,
                        insertText: `{{${variable.value}}}`,
                        detail: variable.label,
                        documentation: variable.documentation,
                        range,
                    })),
                } as languages.ProviderResult<languages.CompletionList>;
            },
        },
    );
    registered = true;
};

export default registerVariables;
