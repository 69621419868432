import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';

interface DeleteModalProps {
    responderName: string;
}

const DeleteModal = ({ responderName }: DeleteModalProps) => (
    <ModalBody
        title={`Are you sure you want to delete the "${responderName}" responder?`}
        description="Please confirm that you want to delete the selected responder. If you delete this responder, it will be permanently removed from your agent."
        icon={<Trash />}
        variant="destructive"
    />
);

export default DeleteModal;
