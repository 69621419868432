import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 0.75rem 1.5rem 2rem 1.5rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
`;

export const BarsContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
`;
