import styled from 'styled-components';

export const StyledSpinnerContiner = styled.div`
    position: relative;
    height: 212px;
    width: 172px;
`;

export const StyledImage = styled.img`
    border-radius: 6px;
    margin: 5px 0;
`;
