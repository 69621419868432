import { IconProps } from 'types';

const HistoryIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
    >
        <title>{title}</title>
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M14.667 5.5h2.75c.506 0 .916.41.916.917V16.5a1.833 1.833 0 0 1-3.666 0V4.583a.917.917 0 0 0-.917-.916H4.583a.917.917 0 0 0-.916.916v11a2.75 2.75 0 0 0 2.75 2.75H16.5"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M14.667 5.5h2.75c.506 0 .916.41.916.917V16.5a1.833 1.833 0 0 1-3.666 0V4.583a.917.917 0 0 0-.917-.916H4.583a.917.917 0 0 0-.916.916v11a2.75 2.75 0 0 0 2.75 2.75H16.5"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M7.333 7.333H11"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M7.333 7.333H11"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M7.333 11H11"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M7.333 11H11"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M7.333 14.667H11"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M7.333 14.667H11"
        />
    </svg>
);
export default HistoryIcon;
