import styled, { css } from 'styled-components';
import { Modal, Tabset } from 'react-rainbow-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import Picklist from 'components/Picklist';
import { lighten } from 'react-rainbow-components/styles/helpers/color';
import UniversalForm from 'components/UniversalForm';
import Input from 'components/Input';
import { color } from 'theme';
import { GREY_800 } from '../../../constants';

export const StyledModal = styled(Modal)`
    padding: 1rem 0 !important;
    min-height: calc(100vh - 6rem);
    min-width: calc(100vw - 6rem);
`;

export const StyledUniversalForm = styled(UniversalForm)`
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    overflow: hidden;
    gap: 1rem;
`;

export const ModalTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    height: 100%;
`;

export const TabContentContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    margin-top: 1rem;
    padding: .5rem 1.5rem;
`;

export const FormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 1.5rem;
    gap: 1rem;
    flex: 0;
`;

export const StyledTabset = styled(Tabset)`
    padding: 0 1.5rem;
`;

export const FieldTitle = styled.h3`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${color('text.label')};
    margin-bottom: 0.25rem;
`;

export const FieldDescription = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${color('text.header')};
    margin-bottom: 1rem;
`;

export const CreativityHelpContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${color('text.header')};
    padding: 0.5rem 0;
`;

interface StyledRadioItemProps {
    isHover?: boolean;
    isFocused?: boolean;
    isSelected?: boolean;
    disabled?: boolean;
}

export const StyledUniversalPicker = styled(UniversalPicker)`
    width: 100%;

    > div {
        gap: 0.5rem;

        > div {
            margin: 0;
        }
    }
`;

export const StyledRadioItem = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 100%;
    padding: 1rem;
    background-color: white;
    border: solid 1px #dbdade;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;

    ${(props) => props.isHover
        && `
            background-color: ${lighten(color('brand.main')(props), 0.9)};    
            border: solid 1px ${color('brand.main')(props)};
            cursor: pointer;
    `};

    ${(props) => props.isFocused
        && `
            border: solid 1px ${color('brand.main')(props)};
            box-shadow: ${color('brand.light')(props)} 0px 0px 2px;
    `};

    ${(props) => props.isSelected
        && `
            border: solid 1px ${color('brand.main')(props)};
            box-shadow: ${color('brand.light')(props)} 0px 0px 2px;
    `};

    ${(props) => props.disabled
        && `
            border: solid 1px #dbdade;
            background-color: #dbdade;
            box-shadow: 0 0 0 0 transparent;
            cursor: not-allowed;
    `};
`;

export const StyledRadio = styled.span<StyledRadioItemProps>`
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    background: #fff;
    border: solid 2px #dbdade;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    flex-shrink: 0;

    &::after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        top: 4px;
        left: 4px;
        background: #fff;
        border-radius: 20px;
        box-sizing: border-box;
    }

    ${(props) => props.isSelected && css`
            background: ${color('brand.main')(props)};
            border: 2px solid;
            border-color: ${color('brand.main')(props)};
            box-shadow: 0px 2px 4px 0px ${color('brand.light')(props)};

            &::after {
                background: #fff;
                box-sizing: border-box;
            }
    `};

    ${(props) => props.isFocused && css`
            background: ${color('brand.main')(props)};
            border: 2px solid;
            border-color: ${color('brand.main')(props)};
            box-shadow: 0px 2px 4px 0px ${color('brand.light')(props)};
    `};

    ${(props) => props.disabled && css`
            background-color: #fff;
            border-color: #dbdade;

            &::after {
                background: #fff;
                box-sizing: border-box;
            }
    `};
`;

export const StyledPickerOptionContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-left: 0.85rem;
`;

export const StyledOptionLabel = styled.span`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3125rem;
    color: ${GREY_800};
`;

export const StyledInput = styled(Input)`
    margin-top: 0.5rem;

    label {
        margin-left: 0rem;
    }
`;

export const StyledPicklist = styled(Picklist)`
    margin-top: 1rem;

    label {
        margin-left: 0rem;
    }
`;
