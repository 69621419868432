import { BadgeContainer, StyledBadge } from './styled';

interface CalendarEventStatusBadgeProps {
    value?: string;
    color?: string;
}

const CalendarEventStatusBadge = ({
    value = '',
    color = '',
}: CalendarEventStatusBadgeProps) => (
    <BadgeContainer>
        <StyledBadge color={color}>{value}</StyledBadge>
    </BadgeContainer>
);

export default CalendarEventStatusBadge;
