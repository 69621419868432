import styled from 'styled-components';
import { Table, TableWithBrowserPagination } from 'react-rainbow-components';
import { WHITE } from '../../constants';

export default styled(Table)`
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    table {
        padding-bottom: 1.5rem;

        thead {

            th {
                font-weight: 500;
                font-size: 0.8125rem;
                letter-spacing: 0.0625rem;
            
                div {
                    background: ${WHITE};
                }
            }
        }
    }
`;

export const TableWithPagination = styled(TableWithBrowserPagination)`
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: auto;
    box-sizing: border-box;

    table {
        padding-bottom: 1.5rem;

        thead {

            th {
                font-weight: 500;
                font-size: 0.8125rem;
                letter-spacing: 0.0625rem;
            
                div {
                    background: ${WHITE};
                }
            }
        }
    }

    & > div:last-child {
        background: ${WHITE};
    }
`;
