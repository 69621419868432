import { Availability } from 'components/CalendarAvailabilityInput/types';

const getDayHighlighedHours = (availability: Availability[]) => availability
    .sort((a, b) => a.startAt - b.startAt)
    .reduce(
        (acc: any, item, index) => {
            if (index === 0) return [{ ...item }];

            const latestItem = acc[acc.length - 1] as Availability;
            const latestAvailableTime = latestItem.startAt + latestItem.duration;

            if (item.startAt === latestAvailableTime) {
                latestItem.duration += item.duration;
                acc[acc.length - 1] = latestItem;
                return acc;
            }

            return [...acc, { ...item }];
        },
        [],
    );

export default getDayHighlighedHours;
