import { useEffect, useState } from 'react';
import { useParams, Outlet, useMatch } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { confirmModal, showAppNotification } from '@rainbow-modules/app';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import Badge from 'components/Badge';
import Checks from 'components/icons/checks';
import RotateClockwise from 'components/icons/rotateClockwise';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import ChevronLeft from 'components/icons/chevronLeft';
import Button from 'components/Button';
import SpamBadge from 'components/SpamBadge';
import { ItemUser } from 'data/firestore/globals';
import useInboxItem from 'data/firestore/inbox/use';
import getDisplayName from 'data/services/profile/getDisplayName';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import {
    PageTitle,
    ItemPrimaryBar,
    StyledSecondaryBar,
    StyledTabset,
    StyledTab,
} from './styled';
import {
    Content, Header, Divider, Actions,
} from '../styled';
import { Provider } from './context';
import ResolveModal from './resolveModal';
import DeleteModal from './deleteModal';
import useUpdateItem from '../hooks/useUpdateItem';
import ReadBy from './readBy';
import NotSpamModal from '../Items/notSpamModal';
import getPrimaryLabel from './helpers/getPrimaryLabel';

const badgeMap: Record<string, React.ReactNode> = {
    urgent: <Badge
        className="rainbow-m-left_small"
        label="Urgent"
        variant="error"
        borderRadius="semi-square"
    />,
    high: <Badge
        className="rainbow-m-left_small"
        label="High"
        variant="inverse"
        borderRadius="semi-square"
    />,
    medium: <Badge
        className="rainbow-m-left_small"
        label="Medium"
        variant="warning"
        borderRadius="semi-square"
    />,
    low: <Badge
        className="rainbow-m-left_small"
        label="Low"
        variant="success"
        borderRadius="semi-square"
    />,
    spam: <SpamBadge className="rainbow-m-left_small" size="medium" />,
    blocked: <SpamBadge className="rainbow-m-left_small" size="medium" />,
};

const Item = () => {
    const navigate = useNavigateWithQuery();
    const match = useMatch(':agentId/inbox/:inboxId/:tabName');
    const [isLoadingResolve, setIsLoadingResolve] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingSpam, setIsLoadingSpam] = useState(false);

    const { itemId } = useParams<{ itemId: string }>();
    const { data: inboxItem, isLoading } = useInboxItem(itemId as string);
    const user = useCurrentUser();
    const { mutateAsync: updateItem } = useUpdateItem();

    const removeItem = async () => {
        if (!itemId || inboxItem?.removedBy) return;

        const result = await confirmModal({
            header: '',
            question: <DeleteModal />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
        });
        if (!result) return;

        setIsLoadingDelete(true);
        try {
            await updateItem(itemId, 'remove');
            navigate('..');
        } catch (error) {
            // TODO: handle error
            console.error(error);
        } finally {
            setIsLoadingDelete(false);
        }
    };

    const handleMarkAsNotSpam = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!itemId || !inboxItem) return;
        event.preventDefault();
        event.stopPropagation();

        const name = getDisplayName(inboxItem?.customer);

        const result = await confirmModal({
            header: '',
            question: <NotSpamModal phoneNumber={inboxItem?.customer?.phoneNumber as string} />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
        });
        if (!result) return;

        setIsLoadingSpam(true);
        try {
            await updateItem(itemId, 'not-spam');
            showAppNotification({
                title: `${name} removed from spam`,
                description: 'The phone number has been removed from the Blocked List and will now be received as regular calls.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            // TODO: handle error
            console.error(error);
            showAppNotification({
                title: 'Error',
                description: 'Something went wrong. Please try again.',
                icon: 'error',
                timeout: 5000,
            });
        }
        setIsLoadingSpam(false);
    };

    const toggleSolved = async () => {
        if (!itemId || !inboxItem) return;
        const name = getDisplayName(inboxItem.customer);

        if (inboxItem?.solvedBy) {
            setIsLoadingResolve(true);
            try {
                await updateItem(itemId, 'reopen');
                showAppNotification({
                    icon: 'success',
                    title: 'Issue Reopened!',
                    description: `${name}'s issue has been successfully reopened.`,
                    timeout: 5000,
                });
            } catch (error) {
                // TODO: handle error
                console.error(error);
                showAppNotification({
                    icon: 'error',
                    title: 'Error',
                    description: 'Something went wrong. Please try again.',
                    timeout: 5000,
                });
            }
        } else {
            let note = '';
            const result = await confirmModal({
                header: '',
                question: <ResolveModal onNoteChanged={(value) => { note = value; }} />,
                borderRadius: 'semi-square',
                okButtonLabel: 'Confirm',
                cancelButtonLabel: 'Cancel',
            });
            if (!result) return;

            setIsLoadingResolve(true);
            try {
                await updateItem(itemId, 'solve', note);
                showAppNotification({
                    icon: 'success',
                    title: 'Issue Solved!',
                    description: `${name}'s issue has been successfully marked as Solved.`,
                    timeout: 5000,
                });
            } catch (error) {
                // TODO: handle error
                console.error(error);
                showAppNotification({
                    icon: 'error',
                    title: 'Error',
                    description: 'Something went wrong. Please try again.',
                    timeout: 5000,
                });
            }
        }
        setIsLoadingResolve(false);
    };

    useEffect(
        () => {
            const markAsRead = async (id: string) => {
                await updateItem(id, 'read');
            };

            if (
                itemId
                && user
                && inboxItem
                && !inboxItem.readBy?.some((u) => u?.uid === user?.uid)
            ) {
                // eslint-disable-next-line promise/prefer-await-to-then
                markAsRead(itemId).catch(console.error);
            }
        },
        [itemId, user, inboxItem, updateItem],
    );

    if (!inboxItem) {
        // TODO: handle error and loading
        return null;
    }

    const {
        title, priority, readBy, solvedBy, removedBy, _tags: tags,
    } = inboxItem;
    const isOverallLoading = isLoading
        || isLoadingResolve
        || isLoadingDelete;
    const isSolved = !!solvedBy;
    const isRemoved = !!removedBy;
    const isSpam = tags?.includes('spam');
    const isBlocked = tags?.includes('blocked');
    const badgeKey = isSpam || isBlocked ? getPrimaryLabel(tags || []) : priority;
    return (
        <Content>
            <Header>
                <ItemPrimaryBar>
                    <ButtonIcon variant="base" icon={<ChevronLeft />} size="small" onClick={() => navigate('..')} />
                    <PageTitle title={title}>
                        {title}
                        {badgeMap[badgeKey ?? 'low']}
                    </PageTitle>
                    <Actions>
                        <RenderIf isTrue={readBy?.length}>
                            <ReadBy users={(readBy || []) as Array<ItemUser>} />
                        </RenderIf>
                        <RenderIf isTrue={!isRemoved}>
                            <RenderIf isTrue={!isSpam}>
                                <Button variant="outline-brand" size="small" isLoading={isLoadingResolve} disabled={isOverallLoading} onClick={toggleSolved}>
                                    {isSolved ? <RotateClockwise className="rainbow-m-right_xx-small" /> : <Checks className="rainbow-m-right_xx-small" />}
                                    {isSolved ? 'Reopen' : 'Solve'}
                                </Button>
                            </RenderIf>
                            <RenderIf isTrue={isSpam}>
                                <Button variant="outline-brand" size="small" isLoading={isLoadingSpam} disabled={isLoadingDelete} onClick={handleMarkAsNotSpam}>
                                    Not spam
                                </Button>
                            </RenderIf>
                            <ButtonIcon variant="base" tooltip="Delete" icon={<Trash />} size="small" disabled={isLoadingDelete} onClick={removeItem} />
                        </RenderIf>
                    </Actions>
                </ItemPrimaryBar>
                <Divider />
                <StyledSecondaryBar>
                    <StyledTabset
                        variant="line"
                        activeTabName={match?.params?.tabName}
                        onSelect={(event, eventName) => {
                            navigate(eventName);
                            event.preventDefault();
                        }}
                    >
                        <StyledTab name="overview" label="Overview" />
                        <StyledTab name="activity" label="Activity" />
                    </StyledTabset>
                </StyledSecondaryBar>
                <Divider />
            </Header>
            <Provider value={{ isLoading, inboxItem }}>
                <Outlet />
            </Provider>
        </Content>
    );
};

export default Item;
