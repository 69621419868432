import { DeliveryStatus } from '@twilio/conversations';
import Check from 'components/icons/check';
import Clock from 'components/icons/clock';
import Checks from 'components/icons/checks';
import {
    StatusContainer, StatusLabel, StyledReadCheck, StyledAlertCircle,
} from './styled';

interface Props {
    status: DeliveryStatus;
}

const statusIconMap = {
    read: {
        icon: <StyledReadCheck />,
        tooltip: 'Seen',
    },
    delivered: {
        icon: <Checks />,
        tooltip: 'Delivered',
    },
    sent: {
        icon: <Check />,
        tooltip: 'Sent',
    },
    failed: {
        icon: <StyledAlertCircle />,
        tooltip: 'Error sending message',
    },
    undelivered: {
        icon: <StyledAlertCircle />,
        tooltip: 'Error sending message',
    },
    queued: {
        icon: <Clock />,
        tooltip: 'Sending',
    },
};

const getIconAndTooltip = (status: DeliveryStatus) => {
    const iconAndTooltip = statusIconMap[status as DeliveryStatus];
    return (
        iconAndTooltip ?? {
            icon: <Clock />,
            tooltip: 'Sending',
        }
    );
};

const StatusIcon = (props: Props) => {
    const { status } = props;
    const { icon, tooltip } = getIconAndTooltip(status);

    return (
        <StatusContainer>
            <StatusLabel>{tooltip}</StatusLabel>
            {icon}
        </StatusContainer>
    );
};

export default StatusIcon;
