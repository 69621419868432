import { DateTime } from 'luxon';
import getHeightOfMinutes from './getHeightOfMinutes';

export default function getHeightOfDate(
    date: Date | string,
    timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
) {
    const dateInTz = DateTime.fromJSDate(new Date(date)).setZone(timeZone);
    return getHeightOfMinutes(dateInTz.hour * 60 + dateInTz.minute);
}
