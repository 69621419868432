import { useCallback, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import Plus from 'components/icons/plus';
import TimeRangePicker from './timeRangePicker';
import { Availability, WeekDay } from '../types';
import {
    Container, DayLabel, InputsContainer, Label, StyledButtonIcon,
} from './styled';

interface AvailabilityInputItemProps {
    dayOfWeek: WeekDay;
    availabilityHours?: Availability[];
    onChange?: (value: Availability[]) => void;
}

const AvailabilityInputItem = ({
    dayOfWeek,
    availabilityHours = [],
    onChange = () => {},
}: AvailabilityInputItemProps) => {
    const availability = useMemo(
        () => availabilityHours.sort((a, b) => a.startAt - b.startAt),
        [availabilityHours],
    );

    const disableAdd = useMemo(
        () => {
            if (availability.length === 0) return false;
            const latestAvailableTime = availability[availability.length - 1];
            return latestAvailableTime.startAt + latestAvailableTime.duration >= 24 * 3600;
        },
        [availability],
    );

    const handleAddNew = useCallback(
        () => {
            if (availability.length === 0) {
                return onChange([
                    {
                        dayOfWeek,
                        startAt: 28800,
                        duration: 3600,
                    },
                ]);
            }
            const latestAvailableTime = availability[availability.length - 1];
            return onChange([
                ...availability,
                {
                    dayOfWeek,
                    startAt: latestAvailableTime.startAt + latestAvailableTime.duration,
                    duration: 3600,
                },
            ]);
        },
        [availability, dayOfWeek, onChange],
    );

    const handleChange = useCallback(
        (atIndex: number, from: number, to: number) => {
            availability[atIndex] = {
                dayOfWeek,
                startAt: from,
                duration: to - from,
            };

            onChange(availability);
        },
        [availability, dayOfWeek, onChange],
    );

    const handleRemove = useCallback(
        (atIndex: number) => onChange(availability.filter((value, index) => atIndex !== index)),
        [availability, onChange],
    );

    const availabilityInputs = useMemo(
        () => availability
            .map((item, index) => {
                const min = (
                    index === 0
                        ? 0
                        : availability[index - 1].startAt + availability[index - 1].duration
                );

                const max = (
                    index === availability.length - 1
                        ? 24 * 3600
                        : availability[index + 1].startAt
                );

                return (
                    <TimeRangePicker
                        key={`${dayOfWeek}_${item.startAt}`}
                        from={item.startAt}
                        min={min}
                        max={max}
                        to={item.startAt + item.duration}
                        onDelete={() => handleRemove(index)}
                        onChange={({ from, to }) => handleChange(index, from, to)}
                    />
                );
            }),
        [availability, dayOfWeek, handleChange, handleRemove],
    );

    return (
        <Container>
            <DayLabel>{dayOfWeek.slice(0, 3)}</DayLabel>
            <RenderIf isTrue={availabilityHours.length === 0}>
                <Label>Unavailable</Label>
            </RenderIf>
            <RenderIf isTrue={availabilityHours.length > 0}>
                <InputsContainer>
                    {availabilityInputs}
                </InputsContainer>
            </RenderIf>
            <StyledButtonIcon
                size="small"
                borderRadius="semi-rounded"
                icon={<Plus />}
                onClick={handleAddNew}
                disabled={disableAdd}
            />
        </Container>
    );
};

export default AvailabilityInputItem;
