import { useState, useEffect } from "react";
import { FirestoreError } from "firebase/firestore";
import onSnapshotDoc from "./onSnapshotDoc";
import { Llm } from "./types";
import { EntityGet, HookOpts, HookReturnDoc } from "../../types";

export type LlmUseDocHook<T> = (
  agentId: string,
  id: string,
  opts?: HookOpts
) => HookReturnDoc<T>;

const useDoc: LlmUseDocHook<EntityGet<Llm>> = (agentId, id, opts = {}) => {
  const [data, setData] = useState<EntityGet<Llm> | null>(null);
  const [error, setError] = useState<FirestoreError | null>(null);
  const [isLoading, setLoading] = useState(false);
  const { disabled = false } = opts;
  useEffect(() => {
    if (!disabled) {
      setLoading(true);
      const unsub = onSnapshotDoc(
        agentId,
        id,
        (doc) => {
          setData(doc);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
      return () => unsub();
    }
  }, [id, disabled]);
  return { data, error, isLoading };
};

export default useDoc;
