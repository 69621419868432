import { DateTime } from 'luxon';

function formatDate(dateStr?: string, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    if (!dateStr) return '';
    const today = DateTime.now().setZone(timezone, { keepLocalTime: true });
    const yesterday = today.minus({ day: 1 });
    const date = DateTime.fromISO(dateStr as string).setZone(timezone);

    if (date.hasSame(today, 'day')) return 'Today';

    if (date.hasSame(yesterday, 'day')) return 'Yesterday';

    if (date.hasSame(today, 'year')) return date.toFormat('cccc, MMMM d');

    return date.toFormat('cccc, MMMM d, kkkk');
}

export default formatDate;
