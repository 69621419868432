import { CheckboxToggle } from 'react-rainbow-components';
import Trash from 'components/icons/trash';
import ButtonIcon from 'components/ButtonIcon';
import Pencil from 'components/icons/pencil2';
import { Apikey } from 'data/firestore/agent/apikey/types';
import { EntityGet } from 'data/firestore/types';
import { ActionsColumnContainer } from './styled';

interface ActionsColumnProps {
    row?: EntityGet<Apikey>;
    onToggle?: (apiKey: EntityGet<Apikey>) => void;
    onEdit?: (apiKey: EntityGet<Apikey>) => void;
    onDelete?: (apiKey: EntityGet<Apikey>) => void;
}

const ActionsColumn = ({
    row, onEdit, onDelete, onToggle,
}: ActionsColumnProps) => row && (
    <ActionsColumnContainer>
        <CheckboxToggle
            value={row.enabled}
            labelAlignment="left"
            onClick={() => onToggle?.(row)}
        />
        <ButtonIcon
            borderRadius="semi-rounded"
            icon={<Pencil />}
            tooltip="Edit API Key Name"
            onClick={() => onEdit?.(row)}
        />
        <ButtonIcon
            borderRadius="semi-rounded"
            icon={<Trash />}
            tooltip="Remove API Key"
            onClick={() => onDelete?.(row)}
        />
    </ActionsColumnContainer>
);

export default ActionsColumn;
