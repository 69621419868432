import {
    ComponentType, useCallback, useMemo, useState,
} from 'react';
import ListIcon from 'components/icons/list';
import PaperIcon from 'components/icons/paper';
import ChatIcon from 'components/icons/chat2';
import SettingIcon from 'components/icons/setting';
import useResponderInstructionsValidation from 'data/hooks/useResponderInstructionsValidation';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import InstructionTestingLayout from 'components/InstructionTestingLayout';
import StepCompileSystemMessage from '../views/systemMessage';
import StepContext from '../views/context';
import StepUseCase from '../views/useCase';
import StepOpenAIChat from './openAIChat';
import { ResponderInstructionTestComponentProps, ResponderInstructionTestingContext } from '../types';

const stepComponentMap: Record<string, ComponentType<InstructionTestStepComponentProps>> = {
    'use-case': StepUseCase,
    context: StepContext,
    'system-message': StepCompileSystemMessage,
    chat: StepOpenAIChat,
};

const steps = [
    {
        name: 'use-case',
        label: 'Use Case',
        icon: <SettingIcon />,
    },
    {
        name: 'context',
        label: 'Context',
        icon: <ListIcon />,
    },
    {
        name: 'system-message',
        label: 'System Message',
        icon: <PaperIcon />,
    },
    {
        name: 'chat',
        label: 'Chat',
        icon: <ChatIcon />,
    },
];

interface Params extends ResponderInstructionTestComponentProps {}

const MainInstructionTesting = ({
    template,
    agentId,
    responderId,
    responderConfig,
}: Params) => {
    const [currentStep, setCurrentStep] = useState('use-case');
    // eslint-disable-next-line max-len
    const [contextValue, setContextValue] = useState<ResponderInstructionTestingContext>({ responderConfig });

    const { mutateAsync: compileTemplate } = useResponderInstructionsValidation(
        agentId as string,
        responderId,
    );

    const handleStepUpdate = useCallback(
        (stepName: string) => async (values: Record<string, unknown>) => {
            const newContext: Record<string, unknown> = contextValue;

            if (stepName === 'use-case') {
                const { context, formValues } = values;
                newContext.useCaseValues = formValues;
                newContext.templateContext = context;
                newContext.compiledTemplate = '';
                try {
                    const { result } = await compileTemplate({
                        body: {
                            template,
                            context: context as any,
                        },
                    });
                    newContext.compiledTemplate = result;
                } catch (error) {
                    // No catch
                }
            }

            if (stepName === 'chat') {
                const { messages } = values;
                newContext.chatMessages = messages;
            }
            setContextValue(newContext);
        },
        [compileTemplate, contextValue, template],
    );

    const StepComponent = useMemo(() => stepComponentMap[currentStep], [currentStep]);

    return (
        <InstructionTestingLayout
            activeStep={currentStep}
            steps={steps}
            onStepChange={setCurrentStep}
            contextValue={contextValue}
        >
            <StepComponent
                agentId={agentId}
                systemMessageTemplate={template}
                onChange={handleStepUpdate(currentStep)}
            />
        </InstructionTestingLayout>
    );
};

export default MainInstructionTesting;
