import { useState, useEffect, useContext } from 'react';
import { SearchResponse } from '@algolia/client-search';
import useGenerateSearchKey from 'data/hooks/useGenerateSearchKey';
import useAlgoliaIndex from 'data/algolia/useIndex';
import context, { Context } from 'context';

interface AlgoliaError {
    status: number;
    message: string;
}

interface Params {
    search?: string;
    activePage: number;
    pageSize: number;
    indexName: keyof Context['algoliaSecureKeys'];
    filters: string;
    facets?: string[];
    enabled?: boolean;
    track?: unknown[];
}

const useAlgoliaData = <T = {}>({
    search = '', enabled = false, activePage, pageSize, indexName, filters, facets, track = [],
}: Params) => {
    const [algoliaData, setAlgoliaData] = useState<SearchResponse<T> | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setAlgoliaSecureKeys } = useContext(context);
    const { mutateAsync } = useGenerateSearchKey();

    const algoliaIndex = useAlgoliaIndex(indexName);

    useEffect(
        () => {
            (async () => {
                if (enabled) {
                    if (algoliaIndex) {
                        setIsLoading(true);
                        try {
                            const {
                                hits, ...rest
                            } = await algoliaIndex.search<T>(search, {
                                page: activePage - 1, // algolia pages are base 0
                                hitsPerPage: pageSize,
                                filters,
                                facets,
                                cacheable: false,
                            });
                            setAlgoliaData({
                                hits: hits.map((hit) => ({
                                    ...hit,
                                    id: hit.objectID,
                                })),
                                ...rest,
                            });
                        } catch (err) {
                            if ((err as AlgoliaError)?.status === 400 && (err as AlgoliaError)?.message.includes('validUntil')) {
                                const { algoliaSecureKey } = await mutateAsync({
                                    body: {
                                        indexName: String(indexName),
                                    },
                                });
                                setAlgoliaSecureKeys((prevKeys) => ({
                                    ...prevKeys,
                                    [indexName]: algoliaSecureKey,
                                }));
                            }
                        }
                        setIsLoading(false);
                    }
                } else {
                    setAlgoliaData(null);
                }
            })();
        },
        [
            search,
            activePage,
            filters,
            algoliaIndex,
            mutateAsync,
            setAlgoliaSecureKeys,
            pageSize,
            indexName,
            enabled,
            facets,
            ...track,
        ],
    );

    return {
        ...algoliaData,
        isLoading,
    };
};

export default useAlgoliaData;
