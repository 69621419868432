import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    confirmModal,
    hideAppSpinner,
    showAppNotification,
    showAppSpinner,
} from '@rainbow-modules/app';
import { Column } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import { orderBy, query } from 'firebase/firestore';
import useResponders from 'data/firestore/agent/llm/useCollection';
import { EntityGet } from 'data/firestore/types';
import { Llm as Responder } from 'data/firestore/agent/llm/types';
import useFeaturesSchemas from 'data/firestore/responderfeatureschema/useCollection';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import useClientPagination from 'hooks/useClientPagination';
import {
    Container,
    EntriesText,
    PaginationContainer,
    FormHeaderTitle,
    FormHeader,
    FormHeaderDescription,
} from './styled';
import NameColumn from './columns/name';
import ModelColumn from './columns/model';
import ActionsColumn from './columns/actions';
import DescriptionColumn from './columns/description';
import useDeleteResponder from './hooks/useDeleteResponder';
import DeleteModal from './deleteModal';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../../constants';
import TypeColumn from './columns/type';

interface RespondersListProps {
    search?: string;
}

const NewResponderFormHeader = () => (
    <FormHeader>
        <FormHeaderTitle>Edit AI Persona</FormHeaderTitle>
        <FormHeaderDescription>
            Update the name and description of your AI persona.
            Note that the skill type cannot be modified.
        </FormHeaderDescription>
    </FormHeader>
);

const LLMRespondersList = ({ search }: RespondersListProps) => {
    const [openModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);
    const { agentId = '' } = useParams<{ agentId: string }>();
    const {
        data: responders,
        isLoading: isLoadingResponders,
        error,
    } = useResponders(
        agentId,
        {
            disabled: !agentId,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
            track: [agentId],
        },
    );

    const { data: features = [], isLoading: isLoadingFeatures } = useFeaturesSchemas();
    const { mutateAsync: deleteResponder } = useDeleteResponder();

    const [filteredData, setFilteredData] = useState<EntityGet<Responder>[]>();
    const {
        activePage,
        dataToShow,
        pages,
        firstItemOfActivePage,
        lastItemOfActivePage,
        totalHits,
        gotoPage,
    } = useClientPagination(filteredData || []);

    useEffect(() => {
        setFilteredData(responders.filter((responder) => {
            if (!search) return true;
            return responder.name.toLowerCase().includes(search.toLowerCase());
        }).map((responder) => ({
            ...responder,
            type: features.find((feature) => feature.name === responder.featureSchema)?.displayName,
        })));
    }, [features, responders, search]);

    useEffect(() => {
        gotoPage(1);
    }, [search, gotoPage]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingResponders) return;
        if (page === activePage) return;
        gotoPage(page);
    };

    const handleEdit = (responder: EntityGet<Responder>) => {
        openModal({
            title: <NewResponderFormHeader />,
            mode: 'edit',
            responderData: responder,
        });
    };

    const handleDelete = async (responder: EntityGet<Responder>) => {
        const { id, name } = responder;
        const result = await confirmModal({
            header: '',
            question: <DeleteModal responderName={name} />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            variant: 'destructive',
        });
        if (!result) return;

        showAppSpinner();
        try {
            await deleteResponder({
                pathname: `/agents/${agentId}/llms/${id}`,
            });
            showAppNotification({
                title: 'Success',
                description: 'Responder was successfully removed',
                icon: 'success',
                timeout: 5000,
            });
        } catch (e) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    if (error || (!responders && !isLoadingResponders)) {
        return (
            <Container>
                <h1>Something went wrong</h1>
                {error?.message && (
                    <p>
                        {error.name}
                        :
                        {' '}
                        {error.code}
                    </p>
                )}
            </Container>
        );
    }

    return (
        <Container>
            <Table
                data={dataToShow}
                keyField="id"
                isLoading={isLoadingResponders || isLoadingFeatures}
            >
                <Column
                    header="Name"
                    component={NameColumn}
                />
                <Column
                    header="Type"
                    field="type"
                    component={TypeColumn}
                />
                <Column
                    header="Descrtiption"
                    field="description"
                    component={DescriptionColumn}
                />
                <Column
                    header="Model"
                    field="model"
                    component={ModelColumn}
                />
                <Column
                    width={120}
                    headerAlignment="right"
                    cellAlignment="right"
                    component={ActionsColumn}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </Container>
    );
};

export default LLMRespondersList;
