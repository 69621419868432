import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

const getE164Number = (phone: string, defaultCountryCode: CountryCode = 'US'): string => {
    const phoneNumber = parsePhoneNumber(phone, defaultCountryCode);
    if (phoneNumber?.isValid()) {
        return phoneNumber.number;
    }
    return phone;
};

export default getE164Number;
