import { IconProps } from 'types';

const Inbox = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={22}
        height={22}
        fill="none"
        viewBox="0 0 22 22"
    >
        <title>{title}</title>
        <rect
            x="3.66666"
            y="3.66675"
            width="14.6667"
            height="14.6667"
            rx="2"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.66666 11.9167H6.41666L9.16666 14.6667H12.8333L15.5833 11.9167H18.3333"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default Inbox;
