import { IconProps } from 'types';

const MicrosoftIcon = ({ title = 'Layout List', className }: IconProps) => (
    <svg className={className} width={25} height={25} viewBox="0 0 25 25" fill="none">
        <title>{title}</title>
        <g clipPath="url(#clip0_176_67223)">
            <path d="M0.5 0H24.4933V24.4805H0.5V0Z" fill="#F3F3F3" />
            <path d="M1.54321 1.06445H11.9751V11.7081H1.54321V1.06445Z" fill="#F35325" />
            <path d="M13.0183 1.06445H23.4502V11.7081H13.0183V1.06445Z" fill="#81BC06" />
            <path d="M1.54321 12.7725H11.9751V23.4161H1.54321V12.7725Z" fill="#05A6F0" />
            <path d="M13.0183 12.7725H23.4502V23.4161H13.0183V12.7725Z" fill="#FFBA08" />
        </g>
        <defs>
            <clipPath id="clip0_176_67223">
                <rect width="23.9933" height="24.4805" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>

    </svg>
);

export default MicrosoftIcon;
