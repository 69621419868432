import { forwardRef, useMemo } from 'react';
import { Calendar } from 'data/firestore/agent/calendar/types';
import {
    Container,
    Hours,
    HeaderItems,
    HeaderItem,
    Label,
} from './styled';

interface HeaderProps {
    calendars: Calendar[];
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(
    (props, ref) => {
        const { calendars } = props;

        const calendarsList = useMemo(
            () => calendars.map((calendar) => (
                <HeaderItem key={calendar.id}>
                    <Label>{calendar.name}</Label>
                </HeaderItem>
            )),
            [calendars],
        );

        return (
            <Container role="presentation">
                <Hours />
                <HeaderItems ref={ref}>
                    <div />
                    {calendarsList}
                </HeaderItems>
                <div />
            </Container>
        );
    },
);

export default Header;
