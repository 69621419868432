import styled, { css } from 'styled-components';
import { Badge, ProgressCircular } from 'react-rainbow-components';
import Card from 'components/Card';
import UserAvatar from 'components/UserAvatar';
import ListTile from 'components/ListTile';
import {
    WHITE, GREY_400, GREY_300, GREY_800,
} from '../../../../constants';

export const BoardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 1rem 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
`;

export const SummaryContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const SummaryLabel = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
`;

export const PriceLabel = styled.span`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem;
`;

export const CardContainer = styled(Card)`
    display: flex;
    padding: 0.875rem 0.75rem;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 1rem;
    background: #FFF;
    flex-shrink: 0;
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
`;

export const CardTitle = styled.button`
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
    padding: 0;
    margin: 0;
    color: ${GREY_800};
    background: none;
    border: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const CardHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 0.5rem;

`;

export const CardFooter = styled.div`
    display: flex;
    box-sizing: border-box;
    padding-top: 0.75rem;
    height: 2.5rem;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
`;

export const DateLabel = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    padding-right: 0.5rem;
    color: ${GREY_300};
`;

export const Score = styled(ProgressCircular)`
    min-width: 2.24675rem;
    width: 2.24675rem;
    height: 2.24994rem;
    flex-shrink: 0;

    svg {
        width: 2.05744rem;
        height: 2.24994rem;
        flex-shrink: 0;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    & > h1 {
        font-size: 1px;
        color: ${WHITE};
        &::after {
            color: ${GREY_400};
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';
            `}
        }
    }
    
    ${(props) => !props.variant && css`
        color: ${GREY_400};
        > h1 {
            color: ${WHITE};
            ::after {
                color: ${GREY_400};
                content: '-';
                position: absolute;
                left: 0;
                font-size: 0.75rem;
                width: 100%;
                text-align: center;
            }
        }
    `};
`;

export const StageInfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const StageColor = styled.div<{ color: string; }>`
    display: flex;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0rem 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 2px solid ${(props) => props.color};
    background: ${(props) => props.color}26;
`;

export const StageTitle = styled.h3<{ isBuiltin?: boolean; }>`
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem; 

    ${(props) => props.isBuiltin && css`
        text-transform: capitalize;
    `};
`;

export const StageCardsCount = styled(Badge)`
    background: #E7E7E7;
    padding: 0.25rem;
    height: 1.125rem;
    box-sizing: border-box;

    & > span {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
    }
`;

export const StyledUserAvatar = styled(UserAvatar)`
    width: 1.75rem;
    height: 1.75rem;

    & abbr {
        font-size: 0.75rem;
    }
`;

export const StyledListTile = styled(ListTile)`
    & > div ~ div > span {
        font-size: 13px;
    }
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 3rem 4rem;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
`;

export const EmptyMessageEmoji = styled.h2`
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 150%;
`;

export const EmptyMessageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};
`;

export const EmptyMessageDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    width: 100%;
    max-width: 500px;
    line-height: 1.5rem;
    color: ${GREY_800};
`;
