import styled from 'styled-components';
import { motion } from 'framer-motion';
import { CARD_SHADOW, PURPLE_100, WHITE } from '../../../constants';

export const Container = styled(motion.div)`
    z-index: 1;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: ${WHITE};
    box-shadow: ${CARD_SHADOW};
    border-radius: 1.5rem;
    padding: 1.5rem;
`;

export const TaskHeader = styled.div`
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
`;

export const TaskActions = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
`;

export const TaskContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    width: 100%;
`;

export const Line = styled.div`
    border-left: 1px dashed ${PURPLE_100};
    width: 1px;
    height: 1.5rem;
    position: absolute;
    top: 3rem;
    left: 1.5rem;
`;

export const BottomShadow = styled.div`
    position: absolute;
    bottom: -0.75rem;
    width: calc(100% - 3rem);
    height: 3rem;
    margin: 0 1.5rem;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 17.71%, rgba(0, 0, 0, 0.20) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), ${WHITE};
    box-shadow: ${CARD_SHADOW};

    :after {
        position: absolute;
        content: "";
        width: calc(100% - 3rem);
        height: 3rem;
        bottom: -0.75rem;
        margin: 0 1.5rem;
        border-radius: 0rem 0rem 1.5rem 1.5rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 16.67%, rgba(0, 0, 0, 0.2) 59.38%), linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.03) 100%), rgb(255, 255, 255);
        z-index: -1;
    }
`;
