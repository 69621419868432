import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf, Column, Modal } from 'react-rainbow-components';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { Apikey } from 'data/firestore/agent/apikey/types';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import FormModal from 'components/FormModal';
import DateTimeColumn from 'components/DateTimeColumn';
import Footer from 'components/Footer';
import Button from 'components/Button';
import Table from 'components/Table';
import useHttpQuery from 'data/firestore/useHttpQuery';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { EntityGet } from 'data/firestore/types';
import Empty from './empty';
import CreatedBy from './columns/createdBy';
import ActionsColumn from './columns/actions';
import Form from './form';
import {
    Container,
    PageContainer,
    HeaderContainer,
    HeaderTitle,
    HeaderSubtitle,
    HeaderContent,
    StyledCard,
} from './styled';
import SaveKeyModal from './saveKeyModal';
import SecretKey from './columns/secret';
import Name from './columns/name';
import DeleteModal from './deleteModal';

const ApiKeyPage = () => {
    const { agentId = '' } = useParams();

    const { data: apiKeys = [], isLoading, refetch: updateList } = useHttpQuery<Apikey[]>({
        pathname: `/agents/${agentId}/apikeys`,
    });

    const { mutateAsync: createKey } = useHttpMutation({
        pathname: `/agents/${agentId}/apikeys`,
        method: 'POST',
    });

    const { mutateAsync: updateKey } = useHttpMutation({
        method: 'PATCH',
    });

    const { mutateAsync: deleteKey } = useHttpMutation({
        method: 'DELETE',
    });

    const connectedFormModalProps = useConnectModal('api-key-form');
    const [openFormModal, closeFormModal] = useOpenModal('api-key-form');

    const connectedModalProps = useConnectModal('api-key');
    const [openSecretKeyModal, closeSecretKeyModal] = useOpenModal('api-key');

    const newAPIKey = useCallback(
        () => {
            openFormModal({
                title: 'Create New Secret Key',
                onSubmit: async (values: Record<string, string>) => {
                    showAppSpinner();
                    try {
                        const response = await createKey({
                            body: values,
                        });
                        await updateList();
                        closeFormModal();
                        const { details } = response as Record<string, unknown>;
                        openSecretKeyModal({
                            title: '',
                            value: (details as Record<string, unknown>).key as string,
                        });
                    } catch (error) {
                        const message = (error as any)?.message || 'Something went wrong. Please try again.';
                        showAppNotification({
                            title: 'Error',
                            description: message,
                            icon: 'error',
                            timeout: 5000,
                        });
                    }
                    hideAppSpinner();
                },
            });
        },
        [openFormModal, createKey, closeFormModal, openSecretKeyModal, updateList],
    );

    const handleEdit = useCallback(
        (apiKey: EntityGet<Apikey>) => {
            openFormModal({
                title: 'Edit Secret Key',
                mode: 'edit',
                initialValues: {
                    name: apiKey.name,
                },
                onSubmit: async (values: Record<string, string>) => {
                    showAppSpinner();
                    try {
                        await updateKey({
                            pathname: `/agents/${agentId}/apikeys/${apiKey.id}`,
                            body: values,
                        });
                        await updateList();
                        closeFormModal();
                        showAppNotification({
                            title: 'Success',
                            description: 'Secret key was successfully updated',
                            icon: 'success',
                            timeout: 5000,
                        });
                    } catch (error) {
                        const message = (error as any)?.message || 'Something went wrong. Please try again.';
                        showAppNotification({
                            title: 'Error',
                            description: message,
                            icon: 'error',
                            timeout: 5000,
                        });
                    }
                    hideAppSpinner();
                },
            });
        },
        [agentId, closeFormModal, openFormModal, updateKey, updateList],
    );

    const handleToggleEnabled = useCallback(
        async (apiKey: EntityGet<Apikey>) => {
            showAppSpinner();
            try {
                await updateKey({
                    pathname: `/agents/${agentId}/apikeys/${apiKey.id}`,
                    body: {
                        enabled: !apiKey.enabled,
                    },
                });
                await updateList();
                showAppNotification({
                    title: 'Success',
                    description: 'Secret key was successfully enabled',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, updateKey, updateList],
    );

    const handleDelete = useCallback(
        async (apiKey: EntityGet<Apikey>) => {
            const { id, name } = apiKey;
            const result = await confirmModal({
                header: '',
                question: <DeleteModal name={name} />,
                borderRadius: 'semi-square',
                okButtonLabel: 'Delete',
                cancelButtonLabel: 'Cancel',
                variant: 'destructive',
            });
            if (!result) return;

            showAppSpinner();
            try {
                await deleteKey({
                    pathname: `/agents/${agentId}/apikeys/${id}`,
                });
                await updateList();
                showAppNotification({
                    title: 'Success',
                    description: 'Secret key was successfully removed',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deleteKey],
    );

    const isEmpty = useMemo(() => {
        if (isLoading || apiKeys.length > 0) return false;
        return true;
    }, [apiKeys, isLoading]);

    const modalFormButtonLabel = useMemo(
        () => {
            const { mode } = connectedFormModalProps;
            if (mode === 'edit') return 'Update Secret Key';

            return 'Create Secret Key';
        },
        [connectedFormModalProps],
    );

    return (
        <PageContainer>
            <Container>
                <RenderIf isTrue={isEmpty}>
                    <Empty onClick={newAPIKey} />
                </RenderIf>
                <RenderIf isTrue={!isEmpty}>
                    <StyledCard>
                        <HeaderContainer>
                            <HeaderContent>
                                <HeaderTitle>
                                    API Keys
                                </HeaderTitle>
                                <HeaderSubtitle>
                                    Do not share your API key with others,
                                    or expose it in the browser or other client-side code.
                                </HeaderSubtitle>
                            </HeaderContent>
                            <Button
                                label="Create New API Key"
                                variant="brand"
                                size="medium"
                                borderRadius="semi-rounded"
                                onClick={newAPIKey}
                            />
                        </HeaderContainer>
                        <Table
                            data={isLoading ? [] : apiKeys}
                            isLoading={isLoading}
                            keyField="id"
                        >
                            <Column
                                header="Name"
                                field="name"
                                component={Name}
                            />
                            <Column
                                header="Secret Key"
                                field="key"
                                component={SecretKey}
                            />
                            <Column
                                header="Created By"
                                component={CreatedBy}
                            />
                            <Column
                                header="Created At"
                                field="createdAt"
                                component={DateTimeColumn}
                            />
                            <Column
                                field="id"
                                component={ActionsColumn}
                                width={290}
                                onToggle={handleToggleEnabled}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        </Table>
                    </StyledCard>
                </RenderIf>
            </Container>
            <Footer />
            <FormModal
                fields={Form}
                borderRadius="semi-rounded"
                submitButtonLabel={modalFormButtonLabel}
                {...connectedFormModalProps}
            />
            <Modal size="small" {...connectedModalProps}>
                <SaveKeyModal
                    value={connectedModalProps.value as string}
                    onClick={closeSecretKeyModal}
                />
            </Modal>
        </PageContainer>
    );
};

export default ApiKeyPage;
