import styled, { css } from 'styled-components';
import { ColorPicker } from 'react-rainbow-components';
import Card from 'components/Card';
import Input from 'components/Input';
import { PURPLE_500, DIVIDER, RED_500 } from '../../constants';

export const StyledContainer = styled.div`
    position: relative;
`;

export const StyledInput = styled(Input)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    label + div > span:first-child {
        margin-left: 0.5rem;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};
        padding: 0.375rem 0.75rem 0.375rem 1.385rem;

        ::placeholder {
            font-weight: 300 !important;
        }

        &[readonly] {
            padding: 0 1rem;
            border: 1px solid ${DIVIDER};
            
            &:focus {
                border: 2px solid ${PURPLE_500};
            }
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }

    ${(props) => props.icon && css`
        input {
            &[readonly] {
                padding-left: 2.375rem;
            }
        }
    `};
`;

export const InnerContainer = styled.div`
    position: relative;
`;

export const StyledIndicator = styled.span<{ error?: string; }>`
    position: absolute;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 38%;
    right: 1.2rem;

    &::after {
        content: '';
        position: absolute;
        display: block;
        pointer-events: none;
        width: 0.45rem;
        height: 0.45rem;
        border-style: solid;
        border-width: 0.15em 0.15em 0 0;
        transform: rotate(135deg);
        color: ${PURPLE_500};
        box-sizing: border-box;
    }

    ${(props) => props.error && css`
        ::after {
            color: ${RED_500};
        }
    `};
`;

export const ErrorText = styled.div`
    font-size: 0.875rem;
    margin-top: 0.5rem;
    align-self: left;
    color: ${RED_500};
`;

export const Dropdown = styled.div`
    width: 275px;
`;

export const StyledCard = styled(Card)`
    padding: 0.5rem;
`;

export const StyledColorPicker = styled(ColorPicker)`
    & > div:first-child {
        border-radius: 0.375rem;
    }

    & > div:nth-child(2) > div:first-child {
        align-self: center;

        & > div:last-child {
            display: none;
        }
    }

    & > div:nth-child(2) > div:last-child {
        border-radius: 0.375rem;
        border-color: ${DIVIDER};
    }

    & > div:nth-child(3) {
        display: none;
    }

    label {
        border-radius: 0.375rem;
        border: none;
    }
`;

export const ColorBox = styled.div<{ color?: string; }>`
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    align-items: flex-start;
    border-radius: 0.375rem;
    background: ${(props) => props.color || '#000000'};
`;
