import { ReactNode, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import PicklistWithCustomOption from 'components/PicklistWithCustomOption';
import { Option } from 'react-rainbow-components';

interface GenderPickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: PicklistValue) => void;
}

const options = [
    { name: 'male', label: 'Male', value: 'male' },
    { name: 'female', label: 'Female', value: 'female' },
    { name: 'non-binary', label: 'Non-Binary', value: 'non-binary' },
    { name: 'queer', label: 'Genderqueer', value: 'queer' },
    { name: 'fluid', label: 'Genderfluid', value: 'fluid' },
    { name: 'trans', label: 'Transgender', value: 'trans' },
    { name: 'unknown', label: 'Prefer Not to Say', value: 'unknown' },
];

const GenderPicker = (props: GenderPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        if (typeof valueInProps === 'string') {
            const found = options.find((option) => option.value === valueInProps);
            return found || { name: 'custom', label: 'Prefer to Self-Describe', value: valueInProps };
        }
        return valueInProps;
    }, [valueInProps]);

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`gender-picker-option__${option.name}`} {...option} />),
        [],
    );

    return (
        <PicklistWithCustomOption
            id={id}
            name={name}
            label={label}
            className={className}
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={onChange}
            customOptionLabel="Prefer to Self-Describe"
            error={error}
        >
            <Option label="Gender" variant="header" />
            {pickListOptions}
        </PicklistWithCustomOption>
    );
};

export default GenderPicker;
