import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import getTransform from './helpers/getTransform';
import { StyledBackdrop, StyledCenteredImage, StyledImageContainer } from './styled';

interface ZoomedImageProps {
    src?: string;
    alt?: string;
    close?: () => void;
    originalRect?: DOMRect;
}

const ZoomedImage = ({
    src = '',
    alt = '',
    close = () => {},
    originalRect = {} as DOMRect,
}: ZoomedImageProps) => {
    const [isCentered, setIsCentered] = useState(false);

    const handleKeyDown = useCallback(
        (event: { key: string; }) => {
            if (event.key === 'Escape') {
                setIsCentered(false);
                setTimeout(close, 300);
            }
        },
        [close],
    );

    useEffect(() => {
        setIsCentered(true);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    const handleClick = useCallback(
        () => {
            setIsCentered(false);
            setTimeout(close, 300);
        },
        [close],
    );

    return ReactDOM.createPortal(
        <StyledImageContainer onClick={handleClick}>
            <StyledBackdrop isCentered={isCentered} />
            <StyledCenteredImage
                src={src}
                alt={alt}
                top={originalRect.top}
                left={originalRect.left}
                width={originalRect.width}
                height={originalRect.height}
                isCentered={isCentered}
                transform={getTransform(originalRect)}
            />
        </StyledImageContainer>,
        document.body,
    );
};

export default ZoomedImage;
