import styled from 'styled-components';
import { GREY_800 } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    align-self: stretch;
    gap: 1.5rem;
    flex: 1;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3rem auto 1rem auto;
    margin-top: 3rem;
    padding: 0 1rem;
    gap: 0.5rem;
    width: 100%;
    max-width: 35rem;
`;

export const EmptyMessageEmoji = styled.h2`
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 150%;
`;

export const EmptyMessageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};
`;

export const EmptyMessageDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
`;
