import { getFormatter } from 'data/services/date/formatter';
import { Fragment } from 'react';
import TimelineItem from './timelineItem';
import {
    HistoryContainer,
    HistoryHeader,
    Date,
} from './styled';
import { HistoryItem } from './types';

interface Props {
    items: Array<HistoryItem>;
    selectedItem?: HistoryItem | null;
    onSelectedVersion?: (version: HistoryItem) => void;
}

const versionDateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    year: 'numeric',
});

const groupByDate = (versions: HistoryItem[]) => {
    const groups: {
        key: string;
        value: HistoryItem[];
    }[] = [];

    versions.forEach((version) => {
        const date = versionDateFormatter.format(version.createdAt);
        const group = groups.find((g) => g.key === date);
        if (group) {
            group.value.push(version);
        } else {
            groups.push({
                key: date,
                value: [version],
            });
        }
    });
    return groups;
};

const History = ({
    items,
    selectedItem,
    onSelectedVersion = () => {},
}: Props) => {
    const groupedByDate = groupByDate(items);

    return (
        <HistoryContainer>
            {
                groupedByDate.map((group) => {
                    const { key, value } = group;
                    return (
                        <Fragment key={key}>
                            <HistoryHeader>
                                <Date>{key}</Date>
                            </HistoryHeader>
                            <div className="rainbow-m-bottom_large">
                                {
                                    value.map((version) => (
                                        <TimelineItem
                                            key={version.id}
                                            current={version.id === selectedItem?.id}
                                            version={version}
                                            onClick={
                                                (selectedVersion) => onSelectedVersion(
                                                    selectedVersion,
                                                )
                                            }
                                        />
                                    ))
                                }
                            </div>
                        </Fragment>
                    );
                })
            }
        </HistoryContainer>
    );
};

export default History;
