import { Field, isRequired } from '@rainbow-modules/forms';
import { ModalContent, StyledInput, ModalTitle } from './styled';

interface Props {
    title?: string;
}

const Form = ({ title }: Props) => (
    <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <Field
            name="name"
            component={StyledInput}
            label="Friendly Name"
            placeholder="Enter a friendly name for this key"
            validate={isRequired()}
            labelAlignment="left"
            borderRadius="semi-rounded"
            required
        />
    </ModalContent>
);

export default Form;
