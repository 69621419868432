import { Conversation, Message, Paginator } from '@twilio/conversations';
import { useQuery } from 'react-query';
import { CHAT_PAGE_SIZE } from '../../../constants';

const useMessages = (
    customerId: string,
    conversation?: Conversation,
) => useQuery<unknown, unknown, Paginator<Message>>(
    ['messages', customerId],
    () => conversation?.getMessages(CHAT_PAGE_SIZE),
    {
        enabled: Boolean(conversation),
        cacheTime: 0,
    },
);

export default useMessages;
