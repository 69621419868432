import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BadgeOverlay } from 'react-rainbow-components';
import UserAvatar from 'components/UserAvatar';
import {
    GREY_800,
    GREY_400,
    GREY_100,
    GREY_500,
    PURPLE_500,
} from '../../../constants';

export const BasicInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const LeftContent = styled.div`
    display: flex;
    align-items: center;
`;

export const CustomerName = styled.span`
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: ${GREY_800};
`;

export const CustomerAvatar = styled(UserAvatar)`
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
`;

export const RightContent = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;
    color: ${GREY_500};
`;

export const CustomerDetails = styled.p`
    display: flex;
    gap: 0.25rem;
    align-items: center;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.25rem;
    text-decoration: underline;
    color: ${GREY_400};

    svg {
        width: 1rem;
        height: 1rem;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${GREY_500};
    padding: 12px;
    border-radius: 100%;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
        color: ${PURPLE_500};
        background: rgb(227, 224, 252);
    }
`;

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    & > span {
        width: 0.625rem;
        height: 0.625rem;
        box-sixing: border-box;
        border: 2px solid ${GREY_100};
        right: 0.325rem;
        border-radius: 100%;
    }
`;

export const CustomerButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    border-radius: 100rem;
    background: transparent;
    cursor: pointer;
    padding: 0 1rem 0 0;
    margin: 0;

    &:hover {
        background: rgba(0, 0, 0, 0.02);

        > span:last-child {
            color: ${GREY_800};
            text-decoration: underline;
        }
    }
`;
