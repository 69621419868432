/* eslint-disable react/forbid-component-props */
import ModalTitle from 'components/ModalTitle';
import { CalendarEventModalProps } from './types';
import CalendarEventModalRescheduleMode from './rescheduleMode';
import CalendarEventModalCreateMode from './createMode';

interface Params extends CalendarEventModalProps {
    mode?: 'create' | 'reschedule';
}

const CalendarEventModal = ({
    mode = 'create',
    ...rest
}: Params) => {
    if (mode === 'reschedule') {
        return (
            <CalendarEventModalRescheduleMode
                {...rest}
                title={<ModalTitle>Reschedule Event</ModalTitle>}
            />
        );
    }

    return (
        <CalendarEventModalCreateMode
            {...rest}
            title={<ModalTitle>Add New Event</ModalTitle>}
        />
    );
};

export default CalendarEventModal;
