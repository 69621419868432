import { Calendareventtype } from 'data/firestore/agent/calendareventtype/types';
import { formatDurationFromSeconds } from 'data/services/duration/formatter';

const getTitleFromEventType = (
    eventType?: Calendareventtype,
) => {
    if (!eventType) return null;
    return `${eventType?.name} ${formatDurationFromSeconds(eventType.duration)}`;
};

export default getTitleFromEventType;
