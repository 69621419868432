import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from 'components/Table';
import {
    GREY_300,
    GREY_400,
    PURPLE_500,
} from '../../../constants';

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    flex-grow: 1;
    max-height: 800px;
`;

export const StyledTable = styled(Table)`
    flex-grow: 1;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-weight: 300;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
    padding: 0 2rem 0 0;
`;

export const PlanCellWrapper = styled(CellWrapper)`
    padding: 0.69rem 0.625rem;
`;

export const StyledLink = styled(Link)`
    color: ${PURPLE_500};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 0.7rem;

    &:hover {
        color: ${PURPLE_500};
    }
`;
