import priceFormatter from 'data/services/price/formatter';
import { Plan } from 'types';

const formatPrice = (
    price: string | number,
    currency: string,
): string => priceFormatter(currency).format(Number(price) / 100);

const getTierDescription = (tier: Plan['tiers'][0], currency: string) => {
    if (tier.flat_amount_decimal || tier.flat_amount) {
        const fixedPrice: string | number = (
            tier.flat_amount_decimal || tier.flat_amount
        ) as string | number;
        const formattedPrice = `${formatPrice(fixedPrice, currency)} fixed`;
        if (tier.up_to) {
            return `${formattedPrice} up to ${tier.up_to / 60} min`;
        }
        return formattedPrice;
    }
    if (tier.unit_amount_decimal || tier.unit_amount) {
        const pricePerSecond = (
            tier.unit_amount_decimal || tier.unit_amount
        ) as string | number;
        const pricePerMin = Number(pricePerSecond) * 60;
        const formattedPrice = `${formatPrice(pricePerMin, currency)} per min`;
        if (tier.up_to) {
            return `${formattedPrice} up to ${tier.up_to / 60} min`;
        }
        return formattedPrice;
    }
    return '';
};

export default getTierDescription;
