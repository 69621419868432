import getSign from 'helpers/getSign';
import { PercentLabel } from './styled';
import { GREEN_500, RED_500 } from '../../constants';

const getSignString = (value: number) => {
    if (getSign(value) < 0) return '-';
    return '+';
};

const ValuePercentLabel = ({ value = 0 }: { value?: number }) => (
    <PercentLabel color={(value <= 0 ? RED_500 : GREEN_500)}>
        {`${getSignString(value)}${Math.abs(value).toFixed(1)}%`}
    </PercentLabel>
);

export default ValuePercentLabel;
