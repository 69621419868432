/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useEffect, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import { Calendareventstatus } from 'data/firestore/agent/calendareventstatus/types';

interface CalendarEventStatusPickerProps {
    id?: string;
    className?: string;
    eventStatuses?: Calendareventstatus[],
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value?: Calendareventstatus) => void;
}

const CalendarEventStatusPicker = (props: CalendarEventStatusPickerProps) => {
    const {
        value: valueInProps,
        eventStatuses = [],
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (valueInProps) {
            const valueId = (
                typeof valueInProps === 'string'
                    ? valueInProps
                    : (valueInProps as Calendareventstatus).id
            );
            const found = eventStatuses.find(
                (eventStatus) => eventStatus.id === valueId && eventStatus.removable,
            );
            if (found) {
                if (typeof valueInProps === 'string') {
                    onChange(found);
                }
                const {
                    id: eventStatusId,
                    name: eventStatusName,
                } = found;
                return {
                    label: eventStatusName,
                    name: eventStatusId,
                    value: found,
                    searchableText: eventStatusName,
                };
            }
        }
        return undefined;
    }, [eventStatuses, onChange, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => eventStatuses.map(
            (eventStatus) => {
                if (!eventStatus.removable) return null;
                return (
                    <Option
                        key={`event-status__${eventStatus.id}`}
                        label={eventStatus.name}
                        name={eventStatus.id}
                        value={eventStatus}
                        searchableText={eventStatus.name}
                    />
                );
            },
        ),
        [eventStatuses],
    );

    useEffect(() => {
        if (!pickListvalue) {
            onChange();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventStatuses]);

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
            enableSearch
        >
            <Option label="Select event status" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default CalendarEventStatusPicker;
