import styled from 'styled-components';
import { GREY_400 } from '../../constants';

export const EmptyMessageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    gap: 0.5rem;
`;

export const EmptyMessage = styled.p`
    font-size: 0.9375rem;
    color: ${GREY_400};
    font-weight: 300;
`;
