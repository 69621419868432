/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import getDisplayName from 'data/services/profile/getDisplayName';
import { ItemUser } from 'data/firestore/globals';
import useHttpQuery from 'data/firestore/useHttpQuery';
import getInitials from 'data/services/string/getInitials';
import SelectedValue from './value';

interface TeamMember extends ItemUser {
    status: string;
}

interface TeamMemberPickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value?: ItemUser | null) => void;
    hideLabel?: boolean;
}

const TeamMemberPicker = (props: TeamMemberPickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        hideLabel = false,
    } = useReduxForm(props);

    const { data = [], isFetched } = useHttpQuery<TeamMember[]>({
        pathname: `/team/${agentId}/members`,
        queryOptions: {
            enabled: Boolean(agentId),
        },
    });

    const members = useMemo(
        () => data.filter((member) => member.status === 'active'),
        [data],
    );

    const pickListvalue = useMemo(() => {
        if (valueInProps) {
            let inputValue: TeamMember | null | undefined = valueInProps as TeamMember;
            if (typeof valueInProps === 'string') {
                inputValue = members.find((member) => member.uid === valueInProps);
                onChange(inputValue);
            }
            if (inputValue) {
                const { uid: memberId, phoneNumber, ...rest } = inputValue;
                const displayName = getDisplayName(rest);
                return {
                    label: displayName,
                    name: memberId,
                    value: inputValue,
                    searchableText: `${displayName} ${inputValue.email}`,
                };
            }
        }
        return undefined;
    }, [members, onChange, valueInProps]);

    const handleOnChange = useCallback(
        (newValue: PicklistValue) => onChange(newValue.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => members.map(
            (member) => {
                const displayName = getDisplayName(member);
                const initials = (
                    displayName === 'Unknown'
                        ? null
                        : getInitials(displayName)
                );
                return (
                    <Option
                        key={`member__${member.uid}`}
                        label={(
                            <ListTile
                                icon={(
                                    <UserAvatar
                                        initials={initials}
                                        value={member.uid}
                                        src={member.photoURL}
                                    />
                                )}
                                title={displayName}
                                subtitle={member.email}
                            />
                        )}
                        name={member.uid}
                        value={member}
                        searchableText={`${displayName} ${member.email}`}
                    />
                );
            },
        ),
        [members],
    );

    if (pickListvalue) {
        return (
            <SelectedValue
                id={id}
                className={className}
                label={label}
                required={required}
                value={pickListvalue.value}
                onClearValue={() => onChange(null)}
                hideLabel={hideLabel}
            />
        );
    }

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={!isFetched}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
            enableSearch
            hideLabel={hideLabel}
        >
            <Option label="Select Team Member" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default TeamMemberPicker;
