/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo, useState,
} from 'react';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { useReduxForm } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import EmptyMessage from 'components/EmptyMessage';
import Gradient from 'components/Gradient';
import LoadingShape from 'components/LoadingShape';
import useHttpQuery from 'data/firestore/useHttpQuery';
import { getFormatter } from 'data/services/date/formatter';
import {
    Container,
    LeftColumn,
    RightColumn,
    StyledCalendar,
    DateLabel,
    ButtonsContainer,
    ItemLabel,
    StyledUniversalPicker,
} from './styled';
import AvailableTime from './item';
import extractDate from './helpers/extractDate';

interface Params {
    id?: string;
    className?: string;
    locale?: string;
    required?: boolean;
    value?: Date | string | null;
    onChange?: (value: any) => void;
    error?: ReactNode;
    agentId:string;
    calendarId:string;
    eventTypeId:string;
    timezone?: string;
    alwaysVisibleSlots?: string[];
}

const CalendarEventTimePicker = (props: Params) => {
    const {
        value: valueInProps,
        onChange = () => {},
        alwaysVisibleSlots = [],
        required = false,
        locale = 'en-US',
        id,
        className,
        error,
        agentId,
        calendarId,
        eventTypeId,
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...rest
    } = useReduxForm(props);
    const [date, setDate] = useState(extractDate({
        value: valueInProps,
        timezone,
    }));

    const normalizedValue = useMemo(() => {
        if (typeof valueInProps === 'object') return valueInProps?.toISOString();
        return valueInProps;
    }, [valueInProps]);

    const from = useMemo(() => {
        let newDate = new Date(date);
        newDate.setHours(0, 0, 0, 1);

        if (newDate.getTime() < Date.now()) {
            newDate = new Date(Date.now());
        }

        return newDate.toISOString();
    }, [date]);

    const to = useMemo(() => {
        const newDate = new Date(from);
        newDate.setHours(23, 59, 59, 999);
        return newDate.toISOString();
    }, [from]);

    const {
        data: availableTimeSlots = [],
        isLoading: isLoadingSlots,
        isRefetching: isRefetchingSlots,
    } = useHttpQuery<{ startAt: string; duration: number; }[]>({
        pathname: `/agents/${agentId}/calendars/${calendarId}/event-slots?eventTypeId=${eventTypeId}&start=${encodeURIComponent(from)}&end=${encodeURIComponent(to)}`,
        key: ['calendar-available-event-slots', agentId, calendarId, eventTypeId, date.toISOString().slice(0, 10)],
        queryOptions: {
            enabled: Boolean(agentId) && Boolean(calendarId) && Boolean(eventTypeId),
            retry: 3,
        },
    });

    const formattedDate = useMemo(
        () => getFormatter(locale, {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone: timezone,
        }).format(date),
        [date, locale, timezone],
    );

    const handleDateChange = useCallback(
        (newValue: Date) => {
            setDate(newValue);
            onChange(null);
        },
        [onChange],
    );

    const handleTimeChange = useCallback(
        (newValue: string | string[]) => {
            const value = typeof newValue === 'string' ? newValue : newValue[0];
            return onChange(new Date(value));
        },
        [onChange],
    );

    const timeSlots = useMemo(
        () => {
            let slots = availableTimeSlots;
            if (alwaysVisibleSlots.length > 0) {
                slots = [
                    ...availableTimeSlots,
                    ...alwaysVisibleSlots.reduce(
                        (acc: { startAt: string; duration: number; }[], startTime) => {
                            // eslint-disable-next-line max-len
                            if (!availableTimeSlots.find((availableSlot) => availableSlot.startAt === startTime)) {
                                return [...acc, { startAt: startTime, duration: 0 }];
                            }
                            return acc;
                        },
                        [],
                    ),
                ].sort(
                    (slotA, slotB) => (
                        new Date(slotA.startAt).getTime() - new Date(slotB.startAt).getTime()
                    ),
                );
            }

            return slots.map(
                (slot) => {
                    const startAt = new Date(slot.startAt);
                    // eslint-disable-next-line max-len
                    return (
                        <UniversalPickerOption
                            key={`time-slot__${startAt.getTime()}`}
                            id={`time-slot__${startAt.getTime()}`}
                            name={slot.startAt}
                            component={AvailableTime}
                        >
                            <ItemLabel>
                                {getFormatter(
                                    locale,
                                    {
                                        hour: 'numeric',
                                        minute: '2-digit',
                                        hour12: true,
                                        timeZone: timezone,
                                    },
                                ).format(startAt)}
                            </ItemLabel>
                        </UniversalPickerOption>
                    );
                },
            );
        },
        [alwaysVisibleSlots, availableTimeSlots, locale, timezone],
    );

    const isLoading = isLoadingSlots || isRefetchingSlots;

    return (
        <Container id={id} className={className}>
            <LeftColumn>
                <StyledCalendar
                    value={date}
                    onChange={handleDateChange}
                    {...rest}
                />
            </LeftColumn>
            <RightColumn>
                <DateLabel>{formattedDate}</DateLabel>
                <ButtonsContainer>
                    <StyledUniversalPicker
                        direction="vertical"
                        required={required}
                        value={normalizedValue}
                        onChange={handleTimeChange}
                        error={error}
                    >
                        <RenderIf isTrue={isLoading}>
                            <UniversalPickerOption
                                component={LoadingShape}
                                disabled
                            />
                            <UniversalPickerOption
                                component={LoadingShape}
                                disabled
                            />
                            <UniversalPickerOption
                                component={LoadingShape}
                                disabled
                            />
                        </RenderIf>
                        <RenderIf isTrue={!isLoading && timeSlots.length > 0}>
                            {timeSlots}
                        </RenderIf>
                        <RenderIf isTrue={!isLoading && timeSlots.length === 0}>
                            <EmptyMessage message="Not available" />
                        </RenderIf>
                    </StyledUniversalPicker>
                </ButtonsContainer>
                <Gradient />
            </RightColumn>
        </Container>
    );
};

export default CalendarEventTimePicker;
