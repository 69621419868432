import styled, { css } from 'styled-components';
import { MenuItem } from 'react-rainbow-components';
import Button from 'components/Button';
import {
    DIVIDER, GREY_800, PURPLE_500, WHITE,
} from '../../constants';

export const StyledButton = styled(Button)`
    color: ${GREY_800};
    height: 48px;
`;

export const DropDown = styled.div`
    display: flex;
    width: 18.75rem;
    padding: 0.75rem 0 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1rem;
    background: ${WHITE};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06), 0px 8px 24px 0px rgba(75, 70, 92, 0.16);
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledMenuItem = styled(MenuItem)`
    align-items: center;
    padding: 0.5rem 1.5rem;
    color: ${GREY_800};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;

    ${(props) => props.icon && css`
        & > span:last-child {
            width: unset;
            height: unset;
            color: ${PURPLE_500};
        }
    `};
`;

export const Divider = styled.li`
    display: flex;
    flex-shrink: 0;
    height: 1px;
    margin: 0.5rem 0;
    background: ${DIVIDER};
    width: 100%;
`;

export const Header = styled.li`
    width: 100%;
    padding: 0 1rem 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; 
`;
