import UserAvatar from 'components/UserAvatar';
import LoadingShape from 'components/LoadingShape';
import { ExampleContainer, ExampleLoadingContainer, ExampleMessage } from './styled';

const MessageExample = ({
    message = '',
    isLoading = false,
}: {
    message?: string;
    isLoading?: boolean;
}) => {
    if (isLoading) {
        return (
            <ExampleLoadingContainer>
                <LoadingShape shape="circle" />
                <LoadingShape shape="rect" width="100%" height="80px" />
            </ExampleLoadingContainer>
        );
    }

    return (
        <ExampleContainer>
            <UserAvatar />
            <ExampleMessage>{message}</ExampleMessage>
        </ExampleContainer>
    );
};

export default MessageExample;
