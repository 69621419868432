import { IconProps } from 'types';

const Check = ({ title = 'Checkmark', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M3 12L9 18L21 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Check;
