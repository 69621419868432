import styled, { css } from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import {
    PURPLE_500, PURPLE_100, GREY_400, DIVIDER, GREY_100,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    flex: 1;
    height: 100%;
    align-self: stretch;
    border-top: 1px solid ${DIVIDER};

    form {
        height: 100%;
    }
`;

export const LeftColumn = styled.div`
    display: flex;
    width: 16.25rem;
    padding: 1.25rem 1rem 0rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    border-right: 1px solid ${DIVIDER};
`;

export const MainContent = styled.div`
    isplay: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
    align-self: stretch;
    padding: 0.75rem 0 0.5rem;
`;

export const StyledUniversalPicker = styled(UniversalPicker)`
    flex: 1;
    align-self: stretch;
    justify-content: flex-start;

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StepContainer = styled.div<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean;
}>`
    display: flex;
    padding: 0.75rem;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    border: 1px solid ${DIVIDER};
    border-radius: 0.375rem;

    ${(props) => props.isHover && !props.disabled && css`
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
        cursor: pointer;
    `};

    ${(props) => props.isSelected && css`
        background: ${PURPLE_100};
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
    `};

    ${(props) => props.disabled && css`
        border-color: ${DIVIDER};
        color: ${GREY_100};
    `};

    & > svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;

export const StepLabel = styled.h3`
    height: 1.25rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
`;

export const StepDescription = styled.p`
    overflow: hidden;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_400};
`;
