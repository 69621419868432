import { FieldTitle } from 'components/Instructions/aiModelConfigForm/styled';
import {
    StyledFieldDescription, TabHeaderActionsContainer, TabHeaderContainer, TabHeaderInfoContainer,
} from './styled';

interface Props {
    title?: string;
    description?: string;
    actions?: React.ReactNode;
}

const TabHeader = ({ title, description, actions }: Props) => (
    <TabHeaderContainer>
        <TabHeaderInfoContainer>
            <FieldTitle>{title}</FieldTitle>
            <StyledFieldDescription>{description}</StyledFieldDescription>
        </TabHeaderInfoContainer>
        <TabHeaderActionsContainer>{actions}</TabHeaderActionsContainer>

    </TabHeaderContainer>
);

export default TabHeader;
