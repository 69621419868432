/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import usePipeline from 'data/firestore/agent/pipeline/use';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { EntityGet } from 'data/firestore/types';
import { StyledOption, StyledPicklist } from './styled';

interface PipelineStagePickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    pipelineId?: string;
    orderInPipeline?: string[];
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: string) => void;
}

const PipelineStagePicker = (props: PipelineStagePickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        pipelineId = '',
        orderInPipeline = [],
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const { data: pipeline, isLoading: isLoadingPipeline } = usePipeline(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId || orderInPipeline.length > 0,
            track: [agentId, pipelineId],
        },
    );

    const { data: stages = [], isLoading: isLoadingStages } = usePipelineStages(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId,
            track: [agentId, pipelineId],
        },
    );

    const isLoading = useMemo(
        () => isLoadingPipeline || isLoadingStages,
        [isLoadingPipeline, isLoadingStages],
    );

    const orderedStages = useMemo(
        () => {
            const order = pipeline?.stages || orderInPipeline || [];
            if (order.length > 0) {
                return order.reduce(
                    (activeStages: EntityGet<Stage>[], stageId: string) => {
                        const found = stages.find((stage) => stage.id === stageId);
                        if (found) {
                            return [...activeStages, found];
                        }

                        return activeStages;
                    },
                    [],
                );
            }

            return stages;
        },
        [orderInPipeline, pipeline?.stages, stages],
    );

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Stage).id
                );
                const found = orderedStages.find((stage) => stage.id === valueId);
                if (found) {
                    return {
                        label: found.name,
                        name: found.id,
                        value: found.id,
                        searchableText: found.name,
                    };
                }
            }
            if (orderedStages.length === 1) {
                const { id: stageId, name: stageName } = orderedStages[0];
                onChange(stageId);
                return {
                    label: stageName,
                    name: stageId,
                    value: stageId,
                    searchableText: stageName,
                };
            }
        }
        return undefined;
    }, [isLoading, onChange, orderedStages, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => orderedStages.map(
            (stage) => (
                <StyledOption
                    key={`stage__${stage.id}`}
                    label={stage.name}
                    name={stage.id}
                    value={stage.id}
                    isBuiltIn={!stage.removable}
                    searchableText={stage.name}
                />
            ),
        ),
        [orderedStages],
    );

    return (
        <StyledPicklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            <Option label="Select Pipeline Stage" variant="header" />
            {pickListOptions}
        </StyledPicklist>
    );
};

export default PipelineStagePicker;
