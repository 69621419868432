/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { UniversalPickerOptionProps } from '@rainbow-modules/forms/lib/components/UniversalPickerOption';
import { useReduxForm } from '@rainbow-modules/hooks';
import PhoneNumberOption from './option';
import { StyledUniversalPicker } from './styled';

interface PhoneNumberPickerProps extends Omit<UniversalPickerOptionProps, 'value' | 'onChange'> {
    phoneNumbers?: Record<string, string>[];
    value?: Record<string, unknown> | string;
    isLoading?: boolean;
    onChange?: (value?: Record<string, string>) => void;
}

const PhoneNumberPicker = (props: PhoneNumberPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        phoneNumbers = [],
        isLoading = false,
        ...rest
    } = useReduxForm(props);

    const pickerValue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : valueInProps.id
                );
                const found = phoneNumbers.find((phoneNumber) => phoneNumber.id === valueId);
                if (found) {
                    if (typeof valueInProps === 'string') {
                        onChange(found);
                    }
                    return found.id;
                }
            }
            if (phoneNumbers.length > 0) {
                const firstInList = phoneNumbers[0];
                onChange(firstInList);
                return firstInList.id;
            }
        }
        return undefined;
    }, [phoneNumbers, isLoading, onChange, valueInProps]);

    const pickerOptions = useMemo(
        () => phoneNumbers.map(
            (phoneNumber) => (
                <UniversalPickerOption
                    key={phoneNumber.id}
                    name={phoneNumber.id}
                    component={PhoneNumberOption}
                    {...phoneNumber}
                />
            ),
        ),
        [phoneNumbers],
    );

    const handleOnChange = useCallback(
        (newValue: string | string[]) => {
            const phoneNumberId = typeof newValue === 'string' ? newValue : newValue[0];
            const found = phoneNumbers.find((phoneNumber) => phoneNumber.id === phoneNumberId);
            if (found) {
                onChange(found);
            }
        },
        [phoneNumbers, onChange],
    );

    return (
        <StyledUniversalPicker
            labelAlignment="left"
            value={pickerValue}
            onChange={handleOnChange}
            {...rest}
        >
            {pickerOptions}
        </StyledUniversalPicker>
    );
};

export default PhoneNumberPicker;
