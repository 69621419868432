import { IconProps } from 'types';

const Languages = ({ title = 'Language', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M3.59985 5.40002H10.7999" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.39985 3.60007V6.68578C8.39985 10.946 6.25041 14.4001 3.59985 14.4001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.59986 9.60007C3.59663 11.5297 6.77215 13.1173 10.7999 13.2001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.2 20.4001L16.9333 12.0001L20.6666 20.4001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.2001 18.5333H14.4001" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Languages;
