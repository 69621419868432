import { useMemo } from 'react';
import Message from './message';
import { ChatMessage } from './types';

interface Params {
    messages?: ChatMessage[];
}

const MessagesList = ({
    messages = [],
}: Params) => useMemo(
    () => messages
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .map(
            (message: ChatMessage) => (
                <Message
                    key={`message-${message.id}`}
                    type={message.type}
                    agentId={message.agentId}
                    inboxId={message.inboxId}
                    opportunityId={message.opportunityId}
                    content={message.content || {}}
                    createdAt={message.createdAt}
                    createdBy={message.createdBy}
                    context={message.context}
                />
            ),
        ),
    [messages],
);

export default MessagesList;
