import { CopyToClipboardButton } from '@rainbow-modules/record';
import {
    ModalContent, ModalDescription, ModalTitle, KeyField, StyledButton,
} from './styled';

interface Props {
    value?: string;
    onClick?: () => void;
}

const SaveKeyModal = ({ value, onClick }: Props) => (
    <ModalContent>
        <ModalTitle>Save your key</ModalTitle>
        <ModalDescription>
            Please save this secret key somewhere safe and accessible. For security reasons,
            you won’t be able to view it again trough your Voxity account.
            If you lose this secret key, you’ll need to generate a new one.
        </ModalDescription>
        <KeyField>
            <p>{value}</p>
            <CopyToClipboardButton value={value} />
        </KeyField>
        <StyledButton label="Got it!" variant="brand" borderRadius="semi-rounded" onClick={onClick} />
    </ModalContent>
);

export default SaveKeyModal;
