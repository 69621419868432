import { useEffect, useState } from 'react';
import { auth } from 'data/firestore/firebase';
import { Spinner } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import useRedirectWhenNotAdmin from 'hooks/useRedirectWhenNotAdmin';
import { StyledIframe } from './styled';

const UNIVERSAL_WEBHOOK_FUNCTION_TRIGGER = process.env.REACT_APP_UNIVERSAL_WEBHOOK_FUNCTION_TRIGGER;

const getUserIdToken = () => auth.currentUser?.getIdToken(true);

const WebhooksPage = () => {
    const { agentId } = useParams<{ agentId: string }>();
    const [idToken, setIdToken] = useState<string | undefined>();
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);

    useRedirectWhenNotAdmin({
        redirectTo: `/${agentId}`,
    });

    useEffect(() => {
        (async () => {
            const token = await getUserIdToken();
            setIdToken(token);
        })();
    }, []);

    if (idToken && agentId) {
        const widgetEndpoint = `${UNIVERSAL_WEBHOOK_FUNCTION_TRIGGER}/widget?idToken=${idToken}&group=${agentId}`;
        return (
            <div>
                <StyledIframe
                    hidden={isLoadingIframe}
                    title="iframe-webhooks"
                    src={widgetEndpoint}
                    height="750px"
                    width="100%"
                    onLoad={() => { setIsLoadingIframe(false); }}
                />
                <Spinner type="arc" size="large" variant="brand" isVisible={isLoadingIframe} />
            </div>
        );
    }
    return <div />;
};

export default WebhooksPage;
