import {
    MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { ButtonIcon } from 'react-rainbow-components';
import Play from 'components/icons/play';
import Pause from 'components/icons/pause';
import formatTime from 'components/Player/helpers/formatTime';
import VolumeInput from 'components/Player/volumeInput';
import RateInput from 'components/Player/rateInput';
import { StyledControls, StyledInputRange } from './styled';

interface Props {
    url?: string | null;
}

const WebAudioPlayer = ({ url }: Props) => {
    const playerRef = useRef<HTMLAudioElement>();
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [playing, setPlaying] = useState<boolean>(true);
    const [volume, setVolume] = useState<number>(1);
    const [rate, setRate] = useState<number>(1);

    const duration = playerRef.current?.duration || 0;
    const PlayPauseIcon = playing ? Play : Pause;

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        if (playing) {
            playerRef.current?.pause();
        } else {
            playerRef.current?.play();
        }
    }, [playing]);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.currentTime = currentTime;
        }
    }, [currentTime]);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.playbackRate = rate;
        }
    }, [rate]);

    const onEnded = () => {
        setPlaying(true);
        setCurrentTime(0);
    };

    const onSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const seconds = event.target.value as unknown as number;
        setCurrentTime(seconds);
    };

    const onTimeUpdate = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
        const target = event.target as HTMLAudioElement;
        setCurrentTime(Math.floor(target.currentTime));
    };

    return (
        <>
            <audio
                ref={playerRef as MutableRefObject<HTMLAudioElement>}
                src={url as string}
                onTimeUpdate={onTimeUpdate}
                onEnded={onEnded}
            >
                <track kind="captions" />
            </audio>
            <StyledControls>
                <ButtonIcon
                    size="small"
                    icon={<PlayPauseIcon />}
                    variant="brand"
                    borderRadius="semi-rounded"
                    onClick={() => setPlaying(!playing)}
                />
                <span>{formatTime(currentTime)}</span>
                <span>{`-${formatTime(duration)}`}</span>
                <StyledInputRange
                    type="range"
                    value={currentTime}
                    onChange={onSliderChange}
                    min={0}
                    max={duration}
                />
                <VolumeInput value={volume} onChange={setVolume} />
                <RateInput value={rate} onChange={setRate} />
            </StyledControls>
        </>
    );
};

export default WebAudioPlayer;
