/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { CheckboxGroupProps } from 'react-rainbow-components/components/CheckboxGroup';
import LoadingShape from 'components/LoadingShape';
import UserAvatar from 'components/UserAvatar';
import getDisplayName from 'data/services/profile/getDisplayName';
import { ItemUser } from 'data/firestore/globals';
import useHttpQuery from 'data/firestore/useHttpQuery';
import getInitials from 'data/services/string/getInitials';
import { MemberInfo, StyledCheckBoxGroup } from './styled';

interface TeamMember extends ItemUser {
    status: string;
}

interface TeamMemberSelectProps extends Omit<CheckboxGroupProps, 'value' | 'onChange'> {
    agentId?: string;
    value?: Array<ItemUser | string> | null;
    onChange?: (value: Array<ItemUser> | null) => void;
}

const TeamMemberSelect = ({
    agentId = '',
    ...props
}: TeamMemberSelectProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        ...rest
    } = useReduxForm(props);

    const { data = [], isFetched } = useHttpQuery<TeamMember[]>({
        pathname: `/team/${agentId}/members`,
        queryOptions: {
            enabled: Boolean(agentId),
        },
    });

    const members = useMemo(
        () => data.filter((member) => member.status === 'active'),
        [data],
    );

    const options = useMemo(
        () => members.map(
            (member) => {
                const displayName = getDisplayName(member);
                const initials = (
                    displayName !== 'Unknown'
                        ? getInitials(displayName)
                        : undefined
                );
                return {
                    value: member.uid,
                    label: (
                        <MemberInfo
                            icon={(
                                <UserAvatar
                                    size="x-small"
                                    initials={initials}
                                    value={member.uid}
                                    src={member.photoURL}
                                />
                            )}
                            title={displayName}
                        />
                    ),
                };
            },
        ),
        [members],
    );

    const value = useMemo(
        () => {
            if (valueInProps) {
                if (valueInProps.length === 0) return [];
                const firstValue = valueInProps.at(0);
                return (
                    typeof firstValue === 'string'
                        ? valueInProps
                        : (valueInProps as ItemUser[]).map((member) => member.uid)
                ) as string[];
            }
            return members.map((member) => member.uid) as string[];
        },
        [valueInProps, members],
    );

    const handleOnChange = useCallback(
        (values: string[]) => {
            if (values.length === members.length) return onChange(null);
            const selectedMembers = members.filter(
                (member) => values.includes(member.uid as string),
            );
            return onChange(selectedMembers);
        },
        [members, onChange],
    );

    if (!isFetched) {
        return (
            <>
                <LoadingShape width="30%" />
                <LoadingShape width="40%" />
                <LoadingShape width="10%" />
            </>
        );
    }

    return (
        <StyledCheckBoxGroup
            {...rest}
            hideLabel
            options={options}
            value={value}
            onChange={handleOnChange}
        />
    );
};

export default TeamMemberSelect;
