import { Llm as Responder } from 'data/firestore/agent/llm/types';
import { EntityGet } from 'data/firestore/types';
import { NameCellWrapper } from '../styled';
import { StyledLink } from './styled';

interface Props {
    row?: EntityGet<Responder>;
}

const NameColumn = ({ row }: Props) => (
    <NameCellWrapper>
        <StyledLink to={row?.id || ''}>
            {row?.name}
        </StyledLink>
    </NameCellWrapper>
);

export default NameColumn;
