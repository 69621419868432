import { IconProps } from 'types';

const Phone = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>

        <path d="M3.18936 8.61619L3.1921 8.62451L3.19498 8.63277C3.82525 10.4407 5.16806 13.1602 8.00497 15.9984L8.00507 15.9985C10.8422 18.8362 13.5614 20.1798 15.3722 20.8059L15.3792 20.8083L15.3861 20.8106C16.7306 21.2536 18.2089 20.9058 19.215 19.912C19.2235 19.9039 19.233 19.8946 19.2433 19.884C19.7147 19.4194 20.2196 18.9144 20.5904 18.5434L20.591 18.5428C20.8522 18.2815 21 17.9262 21 17.5553C21 17.1845 20.8522 16.8292 20.591 16.5679L17.747 13.7229L17.0398 14.4299L17.747 13.7229C17.4858 13.4616 17.1305 13.3137 16.7594 13.3137C16.3883 13.3137 16.033 13.4616 15.7718 13.7229L15.7718 13.7229L14.3899 15.1053C14.3291 15.1662 14.2361 15.1782 14.1628 15.1368C13.5384 14.7342 12.4157 13.9433 11.238 12.7653C10.0593 11.5862 9.26869 10.4627 8.87029 9.83945C8.8692 9.83767 8.86809 9.83587 8.86697 9.83406C8.82821 9.76053 8.84261 9.67125 8.89883 9.61508L8.89884 9.61509L8.90243 9.61146C9.28789 9.22232 9.85732 8.6523 10.2804 8.22944L10.2808 8.22911C10.5419 7.96784 10.6897 7.61257 10.6897 7.24167C10.6897 6.87076 10.5419 6.51549 10.2808 6.25423L9.57355 6.96121L10.2808 6.25422L7.43673 3.40924L6.7295 4.11623L7.43673 3.40924C7.17554 3.14796 6.82022 3 6.44914 3C6.07806 3 5.72275 3.14796 5.46156 3.40924L4.10634 4.7649C4.10632 4.76492 4.1063 4.76494 4.10628 4.76496C4.10624 4.76501 4.1062 4.76505 4.10616 4.76509C3.09813 5.77306 2.7439 7.26263 3.18936 8.61619Z" stroke="currentColor" strokeWidth="2" />
    </svg>
);

export default Phone;
