import { useCallback, useMemo } from 'react';
import { Message } from 'data/firestore/message/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useChat from './useChat';
import { ChatMessage } from '../types';

const useOpenAiChat = ({
    model,
    temperature,
    systemMessage = '',
    messages: initialMessages = [],
} : {
    model: string;
    temperature: number;
    systemMessage?: string;
    messages?: ChatMessage[];
}) => {
    const {
        mutateAsync: sendConversation,
        isLoading: isAwaitingResponse,
    } = useHttpMutation<Record<string, unknown>, { content: string }>({
        pathname: '/testing/chat/respond',
        method: 'POST',
    });

    const handleSend = useCallback(
        async (messages: Pick<Message, 'content' | 'role'>[]) => {
            const { content: aiResponse } = await sendConversation({
                body: {
                    model,
                    temperature,
                    systemMessage,
                    conversation: messages,
                },
            });
            return aiResponse;
        },
        [model, sendConversation, systemMessage, temperature],
    );

    const handleInitChat = useCallback(
        async () => handleSend([{ role: 'user', content: '' }]),
        [handleSend],
    );

    const {
        isInitialized,
        messages,
        initialize,
        reset,
        addMessage,
        updateMessage,
        deleteMessage,
        send,
    } = useChat({
        initialized: initialMessages.length > 0,
        messages: initialMessages,
        onInitChat: handleInitChat,
        onSend: handleSend,
    });

    return useMemo(
        () => ({
            isLoading: isAwaitingResponse,
            isInitialized,
            messages,
            initialize,
            reset,
            addMessage,
            updateMessage,
            deleteMessage,
            send,
        }),
        [
            isAwaitingResponse,
            isInitialized,
            messages,
            initialize,
            reset,
            addMessage,
            updateMessage,
            deleteMessage,
            send,
        ],
    );
};

export default useOpenAiChat;
