import styled from 'styled-components';
import {
    CARD_SHADOW, DIVIDER, GREY_300, GREY_800, WHITE,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-self: stretch;
    flex: 1;
`;

export const Header = styled.div`
    display: flex;
    padding: 0rem 0.25rem;
    justify-content: stretch;
    align-items: center;
    align-self: stretch;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

export const Title = styled.h3`
    display: flex;
    flex: 1;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
`;

export const Actions = styled.div`
    display: flex;
    padding: 0.25rem;
    align-items: center;
    border-radius: 3.875rem;
    background: ${WHITE};
`;

export const ButtonGroupDivider = styled.div`
    display: inline-flex;
    width: 1px;
    align-self: stretch;
    background: ${DIVIDER};
    margin: 0.5rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    align-self: stretch;
`;

export const TaskContainer = styled.div`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    border-radius: 1.5rem;
    background: ${WHITE};
    box-shadow: ${CARD_SHADOW};
`;

export const PaginationContainer = styled.div`
    display: flex;
    flex-grow: 0;
    align-items: center;
`;

export const PaginationInformation = styled.p`
    margin: 0;
    color: ${GREY_300};
    font-size: 0.9375rem;
    font-weight: 400;
    margin-right: 0.75rem;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    padding: 0 3rem;
    gap: 0.5rem;
    width: 100%;
`;

export const EmptyMessageEmoji = styled.h2`
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 150%;
`;

export const EmptyMessageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};
`;

export const EmptyMessageDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
`;
