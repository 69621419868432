import getFirstDayOfWeek from './getFirstDayOfWeek';
import normalizeDate from './normalizeDate';

export default function getCurrentWeek(
    currentWeek?: Date | string,
    minDate?: Date | string,
    maxDate?: Date | string,
) {
    const week = getFirstDayOfWeek(normalizeDate(currentWeek));
    week.setHours(0, 0, 0, 0);

    if (minDate) {
        const minWeek = getFirstDayOfWeek(minDate);
        minWeek.setHours(0, 0, 0, 0);
        return new Date(Math.max(week.getTime(), minWeek.getTime()));
    }

    if (maxDate) {
        const maxWeek = getFirstDayOfWeek(maxDate);
        maxWeek.setHours(0, 0, 0, 0);
        return new Date(Math.min(week.getTime(), maxWeek.getTime()));
    }

    return week;
}
