import { IconProps } from 'types';

const IdCard = ({ title = 'ID Card', className }: IconProps) => (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none">
        <title>{title}</title>
        <path
            d="M7.08329 7.5V12.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <rect
            x="2.5"
            y="4.16675"
            width="15"
            height="11.6667"
            rx="2"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 7.5H11.875C12.7379 7.5 13.4375 8.19956 13.4375 9.0625V10.9375C13.4375 11.8004 12.7379 12.5 11.875 12.5H10V7.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IdCard;
