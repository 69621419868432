const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const formatBytes = (bytes: number) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    if (bytes === 0) return '0 Bytes';

    if (!bytes) return 'Unknown';

    return `${(bytes / 1024 ** i).toFixed(2)} ${sufixes[i]}`;
};
