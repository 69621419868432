import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import { DIVIDER, WHITE, GREY_200 } from '../../constants';

export const Container = styled.div`
    display: flex;
    padding: 1rem 0.5rem 0.5rem 1rem;
    justify-content: stretch;
    align-items: flex-end;
    gap: 0.5rem;
    border-radius: 0.75rem 0.75rem 2rem 0.75rem;
    border: 1px solid ${DIVIDER};
    margin: 0 1rem 1rem;
`;

export const SendButton = styled(ButtonIcon)`
    width: 3.5rem;
    height: 3.5rem;

    & svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
`;

export const MessageInput = styled(Textarea)`
    width: 100%;
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    background-clip: unset;
    
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    & > div {
        border none;
        border-radius: none;
        box-shadow: none;
        background: ${WHITE};
    }
    textarea {
        max-height: 420px;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 0;
        border-radius: 0;

        &:focus,
        &:active {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            border-radius: 0;
            padding: 0;
        }

        &[disabled] {
            background: ${WHITE};
            color: ${GREY_200};
            padding-left: 0;
            padding-right: 0;

            &:focus,
            &:active {
                padding: 0.625rem 0;
            }
        }
    }
`;

export const ActionButton = styled(ButtonIcon)`
    width: 3rem;
    height: 3rem;
    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    padding: 0.25rem 0rem;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid ${DIVIDER};
`;
