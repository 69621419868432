import { useMemo } from 'react';
import { Availability } from 'components/CalendarAvailabilityInput/types';
import { Day } from './styled';
import getDayHighlighedHours from './helpers/getDayHighlighedHours';
import HighligthedHours from './highlightedHours';

interface DayProps {
    availability?: Availability[];
    highlightColor: string;
}

const WeekDay = ({
    highlightColor,
    availability = [],
}: DayProps) => {
    const highlightedHours = useMemo(
        () => getDayHighlighedHours(availability)
            .map(
                (highlightedHour: Availability, index: number) => {
                    const key = `${highlightedHour.dayOfWeek}_${index}`;
                    return (
                        <HighligthedHours
                            key={key}
                            color={highlightColor}
                            {...highlightedHour}
                        />
                    );
                },
            ),
        [availability, highlightColor],
    );

    return (
        <Day>
            {highlightedHours}
        </Day>
    );
};

export default WeekDay;
