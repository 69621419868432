import styled from 'styled-components';

export const ModalDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin-top: 1rem;
`;

export const HiddenA = styled.a`
    display: none;
`;
