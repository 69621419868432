import { IconProps } from 'types';

const SplitArrow = ({ title = 'Checkmark', className }: IconProps) => (
    <svg className={className} width="20px" height="20px" viewBox="0 0 20 20" fill="none">
        <title>{title}</title>
        <path
            d="M17.5 14.1667H13.0025C11.6429 14.1666 10.3688 13.5031 9.58917 12.3892L9.16083 11.7775C8.38121 10.6636 7.10714 10.0001 5.7475 10H2.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 5.83325H13.0042C11.6436 5.83319 10.3688 6.49738 9.58917 7.61242L9.16417 8.22075C8.38475 9.33555 7.11024 9.99971 5.75 9.99992H2.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 8.33325L17.5 5.83325L15 3.33325"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15 16.6667L17.5 14.1667L15 11.6667"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SplitArrow;
