import { IconProps } from 'types';

const Sms = ({ title = 'Message', className }: IconProps) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M23 22H20V19L23 22Z" fill="currentColor" />
        <path d="M3 6C3 4.34315 4.34315 3 6 3H16C17.6569 3 19 4.34315 19 6V21H6C4.34315 21 3 19.6569 3 18V6Z" stroke="currentColor" strokeWidth="2" />
        <line x1="7" y1="8" x2="15" y2="8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <line x1="7" y1="12" x2="11" y2="12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <line x1="7" y1="16" x2="13" y2="16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default Sms;
