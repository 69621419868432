import { CellWrapper, StyledDescription } from './styled';

interface Props {
    value?: string;
}

const DescriptionColumn = ({ value }: Props) => (
    <CellWrapper>
        <StyledDescription>
            {value}
        </StyledDescription>
    </CellWrapper>
);

export default DescriptionColumn;
