import { useMemo } from 'react';
import { orderBy, query, where } from 'firebase/firestore';
import useCalendarEvents from 'data/firestore/agent/calendarevent/useCollectionOnce';
import { Calendareventstatus } from 'data/firestore/agent/calendareventstatus/types';
import getStatusFieldName from '../helpers/getStatusFieldName';

interface Params {
    agentId?: string;
    from?: Date;
    to?: Date;
}

interface Results {
    globalStats: Record<string, number>;
    byAffiliate: Record<string, { [key: string]: number | string; }>;
}

const useAffiliateBookingsInsights = ({
    agentId = '',
    from,
    to,
}: Params) => {
    const { data: events = [], isLoading: isLoadingEvents } = useCalendarEvents(
        agentId,
        {
            disabled: !agentId || !from || !to,
            track: [agentId, from?.toISOString(), to?.toISOString()],
            listQueryFn: (ref) => query(
                ref,
                where('createdAt', '>', from),
                where('createdAt', '<=', to),
                where('createdBy.role', '==', 'affiliate'),
                orderBy('createdAt'),
            ),
        },
    );

    const mappedData = useMemo(
        () => {
            const data = events.reduce(
                (result, event) => {
                    const { createdBy } = event;

                    const fieldName = getStatusFieldName(event.status as Calendareventstatus);

                    const { globalStats, byAffiliate } = result;

                    globalStats[fieldName] = (globalStats[fieldName] || 0) + 1;

                    const affiliateId = createdBy?.uid as string;
                    const record = byAffiliate[affiliateId] || { ...createdBy };
                    record[fieldName] = (record[fieldName] as number || 0) + 1;
                    record['total-bookings'] = (record['total-bookings'] as number || 0) + 1;
                    byAffiliate[affiliateId] = record;

                    return {
                        globalStats,
                        byAffiliate,
                    };
                },
                {
                    globalStats: {
                        totalBookings: events.length,
                    },
                    byAffiliate: {},
                } as Results,
            );

            const { globalStats, byAffiliate } = data;
            return {
                globalStats,
                byAffiliate: Object.values(byAffiliate),
            };
        },
        [events],
    );

    return useMemo(
        () => ({
            isLoading: isLoadingEvents,
            ...mappedData,
        }),
        [isLoadingEvents, mappedData],
    );
};

export default useAffiliateBookingsInsights;
