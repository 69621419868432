import { RenderIf } from 'react-rainbow-components';
import ModalTitle from '../ModalTitle';
import {
    ModalContainer, ModalDescription, ModalIconContainer,
} from './styled';
import { Variant } from './types';

interface ModalBodyProps {
    icon?: React.ReactNode;
    title: React.ReactNode;
    description?: React.ReactNode;
    variant?: Variant;
}

const ModalBody = ({
    icon, title, description, variant,
}: ModalBodyProps) => (
    <ModalContainer>
        <RenderIf isTrue={icon}>
            <ModalIconContainer variant={variant}>
                {icon}
            </ModalIconContainer>
        </RenderIf>
        <ModalTitle>{title}</ModalTitle>
        <ModalDescription>
            {description}
        </ModalDescription>
    </ModalContainer>
);

export default ModalBody;
