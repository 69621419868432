import styled from 'styled-components';
import { Modal } from 'react-rainbow-components';
import { PURPLE_500 } from '../../constants';

export const FullSizeModal = styled(Modal)`
    width: calc(100vw - 4rem);
    height: 100%;
    max-height: calc(100vh - 4rem);

    &[role="dialog"][aria-describedby^="modal-content"] {
        padding: 0;

        & > div[id^="modal-content"] {
            flex: 1;
        }
    }
`;

export const Title = styled.h1`
    display: flex;
    padding: 1.25rem 1rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.25rem;
    margin: 0;

    & > svg {
        color: ${PURPLE_500};
        width: 1.5rem;
        height: 1.5rem;
    }
`;
