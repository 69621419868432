import { useEffect, useState } from 'react';
import { app } from 'data/firestore/firebase';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const storage = getStorage(app);

const useConversationAudio = (recordingId?: string) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recordingUrl, setRecordingUrl] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (recordingId) {
            (async () => {
                setIsLoading(true);
                try {
                    const recording = ref(storage, `/recordings/${recordingId}.wav`);
                    const url = await getDownloadURL(recording);
                    setRecordingUrl(url);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error('Recording not found: ', { recordingId });
                }
                setIsLoading(false);
            })();
        }
    }, [recordingId]);

    return {
        isLoading,
        recordingUrl,
    };
};

export default useConversationAudio;
