import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { query, where } from 'firebase/firestore';
import countAffiliates from 'data/firestore/agent/affiliate/count';
import countBookings from 'data/firestore/agent/calendarevent/count';
import useAgentData from 'hooks/useAgentData';
import computeGrowthRate from '../helpers/computeGrouthRate';

const useGlobalAffiliateInsightsLast30days = () => {
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [data, setData] = useState({
        totalAffiliates: 0,
        newAffiliates: 0,
        affiliateGrowthRate: 0,
        newBookings: 0,
        bookingsGrowthRate: 0,
    });

    useEffect(() => {
        (async () => {
            if (!isLoadingAgent && agent) {
                const timeZone = agent?.companyAddress.timezone
                    || Intl.DateTimeFormat().resolvedOptions().timeZone;

                const today = DateTime.now().setZone(timeZone, { keepLocalTime: true }).endOf('day');
                const thirtyDaysAgo = today.minus({ days: 30 }).startOf('day');

                setIsLoadingReport(true);

                try {
                    const totalAffiliates = await countAffiliates(agent.id, {});
                    const totalAffiliatesThirtyDaysAgo = await countAffiliates(agent.id, {
                        listQueryFn: (ref) => query(
                            ref,
                            where('createdAt', '<=', thirtyDaysAgo.toJSDate()),
                        ),
                    });
                    const newAffiliates = await countAffiliates(agent.id, {
                        listQueryFn: (ref) => query(
                            ref,
                            where('createdAt', '>', thirtyDaysAgo.toJSDate()),
                            where('createdAt', '<=', today.toJSDate()),
                        ),
                    });
                    const affiliateGrowthRate = computeGrowthRate(
                        totalAffiliates,
                        totalAffiliatesThirtyDaysAgo,
                    );
                    const totalBookings = await countBookings(agent.id, {
                        listQueryFn: (ref) => query(
                            ref,
                            where('createdBy.role', '==', 'affiliate'),
                        ),
                    });
                    const totalBookingsThirtyDaysAgo = await countBookings(agent.id, {
                        listQueryFn: (ref) => query(
                            ref,
                            where('createdAt', '<=', thirtyDaysAgo.toJSDate()),
                            where('createdBy.role', '==', 'affiliate'),
                        ),
                    });
                    const newBookings = await countBookings(agent.id, {
                        listQueryFn: (ref) => query(
                            ref,
                            where('createdAt', '>', thirtyDaysAgo.toJSDate()),
                            where('createdAt', '<=', today.toJSDate()),
                            where('createdBy.role', '==', 'affiliate'),
                        ),
                    });

                    const bookingsGrowthRate = computeGrowthRate(
                        totalBookings,
                        totalBookingsThirtyDaysAgo,
                    );
                    setData({
                        totalAffiliates,
                        newAffiliates,
                        affiliateGrowthRate,
                        newBookings,
                        bookingsGrowthRate,
                    });
                } catch (error) {
                    // no catch
                }
                setIsLoadingReport(false);
            }
        })();
    }, [agent, isLoadingAgent]);

    return useMemo(
        () => ({
            isLoading: isLoadingAgent || isLoadingReport,
            ...data,
        }),
        [data, isLoadingAgent, isLoadingReport],
    );
};

export default useGlobalAffiliateInsightsLast30days;
