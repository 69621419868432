import useCustomerPanel from 'hooks/useCustomerPanel';
import CustomerPanel from './panel';

const CustomerPanelModal = () => {
    const { isOpen, data: customerData, closeCustomerPanel } = useCustomerPanel();

    if (!isOpen || !customerData || !customerData.id) return null;

    return (
        <CustomerPanel
            customer={customerData}
            onRequestClose={closeCustomerPanel}
        />
    );
};

export default CustomerPanelModal;
