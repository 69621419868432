import styled, { css } from 'styled-components';
import {
    ProgressCircular, Picklist, Option, Spinner,
} from 'react-rainbow-components';
import LinkWithQuery from 'components/LinkWithQuery';
import {
    PURPLE_500, GREY_400, GREY_300, WHITE, PURPLE_600,
} from '../../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    text-transform: capitalize;
    position: relative;
`;

export const StyledTitleContainer = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.35rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`;

export const PhoneNumber = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledLink = styled(LinkWithQuery)`
    color: ${PURPLE_500};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: ${PURPLE_600};
    }
`;
export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    font-weight: 300;
    font-size: 0.9375rem;
`;

export const OpportunityCellWrapper = styled(CellWrapper)`
    font-size: 1rem;
    font-weight: 400;
    padding-left: 1rem;
    gap: 0.5rem;
`;

export const StyledProgressCircular = styled(ProgressCircular)`
    min-width: 44px;
    width: 44px;
    height: 44px;

    & > h1 {
        font-size: 1px;
        color: ${WHITE};
        &::after {
            color: ${GREY_400};
            position: absolute;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.375rem;

            ${(props) => props.variant && css`
                content: '${props.value}';
            `}
        }
    }

    svg {
        width: 40px;
        height: 40px;
        overflow: visible !important;
        
        circle {
            stroke-width: 4px;
        }
    }

    ${(props) => !props.variant && css`
        color: ${GREY_400};
        > h1 {
            color: ${WHITE};
            ::after {
                color: ${GREY_400};
                content: '-';
                position: absolute;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    `};
`;

export const CellLabel = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledPicklist = styled(Picklist)<{ color?: string }>`
    width: calc(100% - 1.25rem);

    input,
    input:focus,
    input:active {
        border: 1px solid transparent;
        color: ${(props) => props.color};
        background: ${(props) => `${props.color}29`};
        transition: all 200ms ease-in;
    }

    input[disabled] {
        padding-left: 0.8rem;
        border: 1px solid transparent;
        color: ${(props) => `${props.color}60`};
        background: ${(props) => `${props.color}19`};
    }
    
    input:focus,
    input:active {
        box-shadow: ${(props) => `${props.color}29`} 0px 0px 2px;
    }

    span::after {
        color: ${(props) => props.color};
    }

    ${(props) => !props.value?.value.removable && css`
        input {
            text-transform: capitalize;
        }
    `};
`;

export const StyledOption = styled(Option)<{ isBuiltIn: boolean }>`
    ${(props) => props.isBuiltIn && css`
        span {
            text-transform: capitalize;
        }
    `};
`;

export const CellActions = styled(CellWrapper)`
    gap: 1rem;
`;

export const StyledSpinner = styled(Spinner)`
    z-index: unset;
    position: absolute;
    // transform: unset;
    left: calc(100% - 1rem);
    top: 50%;
`;

export const LinkButton = styled.button`
    color: ${PURPLE_500};
    background: none;
    border: none;
    &:hover {
        text-decoration: underline;
    }
`;
