import {
    ComponentType, useCallback, useMemo, useState,
} from 'react';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { ChatMessage } from 'types';
import PaperIcon from 'components/icons/paper';
import ChatIcon from 'components/icons/chat2';
import SettingIcon from 'components/icons/setting';
import useResponderInstructionsValidation from 'data/hooks/useResponderInstructionsValidation';
import InstructionTestingLayout from 'components/InstructionTestingLayout';
import StepCompileSystemMessage from '../views/systemMessage';
import StepOutputView from '../views/output';
import StepInputView from '../views/input';
import StepConversation from '../views/conversation';
import {
    ResponderInstructionTestComponentProps,
    ResponderInstructionTestingContext,
    ResponderInstructionTestStepComponentProps,
} from '../types';

// eslint-disable-next-line max-len
const stepComponentMap: Record<string, ComponentType<ResponderInstructionTestStepComponentProps>> = {
    input: StepInputView,
    'system-message': StepCompileSystemMessage,
    conversation: StepConversation,
    output: StepOutputView,
};

interface Params extends ResponderInstructionTestComponentProps {}

const MainInstructionTesting = ({
    template,
    agentId,
    responderId,
    responderConfig,
}: Params) => {
    const [currentStep, setCurrentStep] = useState('input');
    // eslint-disable-next-line max-len
    const [contextValue, setContextValue] = useState<ResponderInstructionTestingContext>({ responderConfig });

    const { mutateAsync: compileTemplate } = useResponderInstructionsValidation(
        agentId as string,
        responderId,
    );

    const {
        mutateAsync: invokeResponder,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/agents/${agentId}/llms/${responderId}/test`,
        method: 'POST',
    });

    const handleStepUpdate = useCallback(
        (stepName: string) => async (values: Record<string, unknown>) => {
            const newContext: Record<string, unknown> = { ...contextValue };

            if (stepName === 'input') {
                const { context, formValues } = values;
                newContext.inputValues = formValues;
                newContext.templateContext = context;
                newContext.compiledTemplate = '';
                showAppSpinner();
                try {
                    const { result } = await compileTemplate({
                        pathname: `/agents/${agentId}/llms/${responderId}/system-message/validate`,
                        body: {
                            template,
                            context: context as any,
                        },
                    });
                    newContext.compiledTemplate = result;
                    setCurrentStep('system-message');
                } catch (error) {
                    // No catch
                }
                hideAppSpinner();
            }

            if (stepName === 'conversation') {
                newContext.chatMessages = values.messages as ChatMessage[];
                showAppSpinner();
                try {
                    const response = await invokeResponder({
                        body: {
                            input: newContext.templateContext,
                            messages: newContext.chatMessages,
                            template,
                        },
                    });
                    newContext.output = response;
                    setCurrentStep('output');
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            }

            if (stepName === 'output') {
                newContext.output = values.output as Record<string, unknown>;
            }

            setContextValue(newContext);
        },
        [compileTemplate, contextValue, invokeResponder, template],
    );

    const StepComponent = useMemo(() => stepComponentMap[currentStep], [currentStep]);

    return (
        <InstructionTestingLayout
            activeStep={currentStep}
            steps={[
                {
                    name: 'input',
                    label: 'Input',
                    icon: <SettingIcon />,
                },
                {
                    name: 'system-message',
                    label: 'System Message',
                    icon: <PaperIcon />,
                    disabled: !contextValue.compiledTemplate,
                },
                {
                    name: 'conversation',
                    label: 'Conversation',
                    icon: <ChatIcon />,
                    disabled: !contextValue.compiledTemplate,
                },
                {
                    name: 'output',
                    label: 'Output',
                    icon: <ChatIcon />,
                    disabled: !contextValue.chatMessages,
                },
            ]}
            onStepChange={setCurrentStep}
            contextValue={contextValue}
        >
            <StepComponent
                agentId={agentId}
                responderId={responderId}
                systemMessageTemplate={template}
                onChange={handleStepUpdate(currentStep)}
            />
        </InstructionTestingLayout>
    );
};

export default MainInstructionTesting;
