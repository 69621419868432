import styled from 'styled-components';
import { color } from 'theme';
import { GREY_600 } from '../../constants';

export const Container = styled.aside`
    display: flex;
    flex-direction: column;
    background-color: ${color('background.main')};
    width: 250px;
    min-width: 250px;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 1.5rem;
    padding-top: 24px;
    padding: 1rem 1rem 0 1rem;
`;

export const OptionsSection = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 10px 0;
`;

export const Header = styled.header`
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.375rem;
    margin: 0 0.5rem 0 0.5rem;
    margin-top: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    padding-top: 0.75rem;
    color: ${GREY_600};
`;
