import { Avatar } from 'react-rainbow-components';
import styled, { css } from 'styled-components';
import User from 'components/icons/user';
import {
    PURPLE_500,
    PURPLE_100,
    GREEN_600,
    GREEN_100,
    RED_500,
    RED_100,
    ORANGE_600,
    ORANGE_200,
    TEAL_600,
    TEAL_100,
    GREY_700,
    GREY_100,
} from '../../constants';

const backgroundColorMap = [
    PURPLE_100,
    GREEN_100,
    RED_100,
    ORANGE_200,
    TEAL_100,
];

const iconColorMap = [
    PURPLE_500,
    GREEN_600,
    RED_500,
    ORANGE_600,
    TEAL_600,
];

const getColorIndexForValue = (value:string) => value.toLowerCase().split('').reduce(
    (acc, letter) => {
        const charNumber = Number(letter) || String(letter).charCodeAt(0);
        return acc + charNumber;
    },
    0,
) % iconColorMap.length;

const getBackgroundColorForValue = (value:string, role?: string): string => {
    if (value === 'assistant' || role === 'agent') return GREY_100;

    if (role === 'viewer') return ORANGE_200;

    if (role === 'editor') return TEAL_100;

    if (role === 'owner') return GREY_100;

    return backgroundColorMap[getColorIndexForValue(value)];
};

const getIconColorForValue = (value:string, role?: string): string => {
    if (value === 'assistant' || role === 'agent') return GREY_700;

    if (role === 'viewer') return ORANGE_600;

    if (role === 'editor') return TEAL_600;

    if (role === 'owner') return GREY_700;

    return iconColorMap[getColorIndexForValue(value)];
};

const StyledAvatar = styled(Avatar).withConfig({
    shouldForwardProp: (prop) => !['value', 'role'].includes(prop),
})<{ value: string; role?:string }>`
    flex-shrink: 0;
    color: ${(props) => getIconColorForValue(props.value, props.role)};
    background: ${(props) => getBackgroundColorForValue(props.value, props.role)};

    :hover {
        color: ${(props) => getIconColorForValue(props.value, props.role)};
    }

    ${(props) => props.size === 'x-small' && css`
        svg {
            width: 0.875rem;
            height: 0.875rem;
        }
    `}
`;

const UserAvatar = ({
    icon = <User />,
    value = '',
    ...rest
}: any) => <StyledAvatar {...rest} value={value} icon={icon} />;

export default UserAvatar;
