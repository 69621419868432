import { Field } from 'react-final-form';
import { isEmpty } from '@rainbow-modules/validation';
import DurationPicker from 'components/DurationPicker';
import TimeIncrementPicker from 'components/TimeIncrementPicker';
import identity from 'helpers/identity';
import {
    FieldsContainer,
    StyledInput,
    Message,
    StyledTextarea,
    Row,
} from './styled';

interface Params {
    message?: string;
}

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const Fields = ({
    message = '',
}: Params) => (
    <>
        <Message>{message}</Message>
        <FieldsContainer>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledInput}
                    name="name"
                    label="Name"
                    labelAlignment="left"
                    placeholder="Enter a name for the event type"
                    borderRadius="semi-square"
                    autoComplete="off"
                    required
                    validate={validateNotEmpty}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={DurationPicker}
                    name="duration"
                    label="Duration"
                    labelAlignment="left"
                    placeholder="Select event duration"
                    borderRadius="semi-square"
                    required
                    validate={validateNotEmpty}
                />
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={TimeIncrementPicker}
                    name="eventTypeIncrement"
                    label="Start Time Increments"
                    placeholder="Select start time increments"
                    labelAlignment="left"
                    required
                    borderRadius="semi-square"
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledTextarea}
                    name="description"
                    label="Description"
                    labelAlignment="left"
                    placeholder="Enter a description for the event type"
                    borderRadius="semi-square"
                    rows={4}
                    parse={identity}
                />
            </Row>
        </FieldsContainer>
    </>
);

export default Fields;
