import styled from 'styled-components';

export const FieldsContainer = styled.fieldset`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const FieldSection = styled.div`
    margin: 0 0 1rem 1.75rem;
`;
