import { useMemo } from 'react';
import { query, where } from 'firebase/firestore';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import useCustomerChatMessages from 'data/firestore/agent/customer/chat/useCollection';
import { Sms } from 'data/firestore/sms/types';
import useSms from 'data/firestore/sms/useCollection';
import { EntityGet, ListQueryFn } from 'data/firestore/types';

type SmsActivityContent = {
    smsId: string;
    eventId: string;
};
const useCustomerActivity = (
    agentId: string,
    customerId: string,
    options: {
        disabled: boolean;
        track?: Array<string | number | boolean | undefined>;
        listQueryFn?: ListQueryFn;
    },
): [EntityGet<Chat>[], boolean] => {
    const { data: messages = [], isLoading: isLoadingMessages } = useCustomerChatMessages(
        agentId,
        customerId,
        { ...options },
    );

    const { data: sms = [], isLoading: isLoadingSms } = useSms({
        listQueryFn: (ref) => query(ref, where('metadata.customerId', '==', customerId)),
    });

    const smsMap = sms.reduce((acc, obj) => {
        acc.set(obj.id, obj);
        return acc;
    }, new Map<string, Sms>());

    const activityEntries = messages.map((message) => {
        if (message.type === 'calendar-event-sms-sent') {
            const smsActivityContent = message?.content as SmsActivityContent;
            const smsData = smsMap.get(smsActivityContent.smsId);
            if (smsData) {
                return {
                    ...message,
                    content: {
                        ...message.content,
                        smsBody: smsData.body,
                        smsStatus: smsData.delivery?.info?.status,
                    },
                };
            }
        }
        return message;
    });

    const isLoading = isLoadingMessages || isLoadingSms;
    return useMemo(
        () => [activityEntries, isLoading],
        [activityEntries, isLoading],
    );
};

export default useCustomerActivity;
