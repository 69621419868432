import { useState } from 'react';
import SearchIcon from 'components/icons/search';
import Button from 'components/Button';
import { useOpenModal } from '@rainbow-modules/hooks';
import { StyledCard } from '../styled';
import { SearchContainer, SearchInput } from './styled';
import { ADD_EDIT_RESPONDER_MODAL } from '../../../constants';
import RespondersList from './respondersList';

const RespondersListPage = () => {
    const [search, setSearch] = useState('');
    const [openModal] = useOpenModal(ADD_EDIT_RESPONDER_MODAL);

    return (
        <StyledCard>
            <SearchContainer>
                <SearchInput
                    type="search"
                    placeholder="Search responder..."
                    variant="bare"
                    icon={<SearchIcon />}
                    borderRadius="semi-square"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <Button
                    label="Add Responder"
                    variant="brand"
                    size="medium"
                    borderRadius="semi-square"
                    onClick={() => openModal({
                        title: 'Add a Responder',
                    })}
                />
            </SearchContainer>
            <RespondersList search={search} />
        </StyledCard>
    );
};

export default RespondersListPage;
