import { isDate } from '@rainbow-modules/validation';
import { ReactNode, useMemo } from 'react';
import { Timestamp } from 'firebase/firestore';
import defaultFormatter, { formatRelativeDate, getFormatter } from 'data/services/date/formatter';
import {
    StyledColumnWrapper,
    StyledDate,
    StyledColumnWrapperRelative,
    RelativeDateLabel,
    RelativeDateDescription,
} from './styled';

interface DateTimeColumnProps {
    value?: Timestamp | Date | string;
    options?: Intl.DateTimeFormatOptions;
    showRelativeDate?: boolean;
    emptyLabel?: ReactNode;
}

const normalizeDate = (value: Timestamp | Date | string) => (
    value instanceof Timestamp
        ? new Date(value.toMillis())
        : new Date(value || '')
);

const isValidDate = (
    value: Date,
) => isDate(value) && !Number.isNaN(value.getTime());

const DateTimeColumn = (props: DateTimeColumnProps) => {
    const {
        value, options, emptyLabel = null, showRelativeDate = false,
    } = props;
    const formatter = useMemo(
        () => (
            options
                ? getFormatter('en-US', options)
                : defaultFormatter
        ),
        [options],
    );

    const dateValue = useMemo(() => {
        const date = normalizeDate(value || '');
        if (value && isValidDate(date)) {
            return formatter.format(normalizeDate(value));
        }
        return null;
    }, [formatter, value]);

    const relativeDateValue = useMemo(() => {
        const date = normalizeDate(value || '');
        if (value && isValidDate(date)) {
            return formatRelativeDate(date);
        }
        return null;
    }, [value]);

    if (!dateValue) return emptyLabel;

    if (showRelativeDate) {
        return (
            <StyledColumnWrapperRelative>
                <RelativeDateLabel>{relativeDateValue}</RelativeDateLabel>
                <RelativeDateDescription>{dateValue}</RelativeDateDescription>
            </StyledColumnWrapperRelative>
        );
    }

    return (
        <StyledColumnWrapper>
            <StyledDate>{dateValue}</StyledDate>
        </StyledColumnWrapper>
    );
};

export default DateTimeColumn;
