import { CellWrapper } from '../styled';

interface Props {
    value?: Record<string, any>;
}

const Carrier = ({ value }: Props) => {
    const { carrier } = value || {};
    const { name = 'Unknown' } = carrier || {};
    return (
        <CellWrapper>
            {name}
        </CellWrapper>
    );
};

export default Carrier;
