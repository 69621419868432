import styled from 'styled-components';
import { UpDirection } from '@rainbow-modules/icons';
import {
    DIVIDER,
    WHITE,
    GREY_800,
} from '../../constants';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`;

export const Header = styled.div`
    display: flex;
    padding: 0rem 0.25rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 1rem;
`;

export const CategoryTitle = styled.h3`
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.625rem;
    color: ${GREY_800};
`;

export const CategoryActions = styled.div`
    display: flex;
    padding: 0.25rem;
    align-items: center;
    border-radius: 3.875rem;
    background: ${WHITE};
`;

export const ButtonGroupDivider = styled.div`
    display: inline-flex;
    width: 1px;
    align-self: stretch;
    background: ${DIVIDER};
    margin: 0.5rem;
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    z-index: 1;
`;

export const DownDirectionIcon = styled(UpDirection)`
    transform: rotate(180deg);
`;
