import {
    forwardRef, useCallback, useMemo, useState,
} from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { InternalOverlay, RenderIf } from 'react-rainbow-components';
import { InternalOverlayProps } from 'react-rainbow-components/components/InternalOverlay';
import { useOpenModal } from '@rainbow-modules/hooks';
import ButtonIcon from 'components/ButtonIcon';
import CalendarIcon from 'components/icons/calendarThin';
import Check from 'components/icons/check';
import Clock from 'components/icons/clock';
import Close from 'components/icons/close';
import Note from 'components/icons/note';
import Trash from 'components/icons/trash';
import User from 'components/icons/user';
import ModalBody from 'components/ModalBody';
import Button from 'components/Button';
import CalendarBuiltInStatusBadge from 'components/CalendarBuiltInStatusBadge';
import { Calendar } from 'data/firestore/agent/calendar/types';
import useEvent from 'data/firestore/agent/calendarevent/use';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { getFormatter } from 'data/services/date/formatter';
import { formatDurationFromSeconds } from 'data/services/duration/formatter';
import useAgentTimeZone from 'hooks/useAgentTimeZone';
import Bell from 'components/icons/bellRinging';
import useEventReminders from 'data/hooks/useEventReminders';
import { ADD_RESCHEDULE_CALENDAR_EVENT } from '../../constants';
import positionResolver from './helpers/positionResolver';
import {
    Actions,
    FooterButtonsContainer,
    Backdrop,
    ColorBox,
    Container,
    Content,
    Description,
    EventDetails,
    Footer,
    Header,
    LabelContainer,
    Title,
} from './styled';
import CustomerInfo from './user';
import DetailElement from './detail';
import EventStatusBadge from './statusBadge';
import EventCreatedBy from './createdBy';
import NotesEditor from './NotesEditor';
import ReminderList from './ReminderList';

interface CalendarEventDetailsProps extends Omit<InternalOverlayProps, 'isVisible' | 'triggerElementRef'> {
    isOpen?: boolean;
    triggerRef?: InternalOverlayProps['triggerElementRef'];
    locale?: string;
    agentId: string;
    calendarId?: string;
    eventId?: string;
    calendars?: Calendar[];
    onRequestClose?: () => void;
}

const builtInEventNames = ['pending-approval', 'rejected'];

const CalendarEventDetails = forwardRef<HTMLDivElement, CalendarEventDetailsProps>(
    (props, ref) => {
        const {
            isOpen = false,
            triggerRef,
            agentId,
            calendarId = '',
            eventId = '',
            calendars = [],
            onRequestClose = () => {},
            locale = 'en-US',
            ...internalOverlayProps
        } = props;

        const timeZone = useAgentTimeZone();
        const [openRescheduleEventForm] = useOpenModal(ADD_RESCHEDULE_CALENDAR_EVENT);

        const { data: event, isLoading: isLoadingEvent } = useEvent(
            agentId,
            eventId,
            {
                disabled: !agentId || !eventId,
                track: [eventId],
            },
        );

        const calendarInfo = useMemo(() => {
            if (!calendarId) return null;
            const eventCalendar = calendars.find((calendar) => calendar.id === calendarId);
            return {
                name: eventCalendar?.name,
                color: eventCalendar?.color,
                statuses: eventCalendar?.eventStatuses,
                defaultStatus: eventCalendar?.eventStatuses?.find(
                    (status) => status.id === eventCalendar.defaultEventStatusId,
                ),
                rejectStatus: eventCalendar?.eventStatuses?.find(
                    (status) => status.name === 'rejected',
                ),
            };
        }, [calendars, calendarId]);

        const [reminders, isLoadingReminders] = useEventReminders(agentId, eventId);

        const formattedDate = useMemo(
            () => getFormatter(
                locale,
                {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    timeZone,
                },
            ).format(event?.startAt),
            [locale, event?.startAt, timeZone],
        );

        const formattedTimeRange = useMemo(
            () => {
                if (!event?.startAt) return '';
                const from = new Date(event.startAt);
                const to = new Date(event.startAt.getTime() + event.duration * 1000);
                const timeFormatter = getFormatter(
                    locale,
                    {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                        timeZone,
                    },
                );

                return [
                    timeFormatter.format(from),
                    timeFormatter.format(to),
                ].join(' - ');
            },
            [locale, event, timeZone],
        );

        const formattedDuration = useMemo(
            () => formatDurationFromSeconds(event?.duration || 0),
            [event?.duration],
        );

        const { mutateAsync: updateEvent } = useHttpMutation({
            method: 'PATCH',
        });

        const { mutateAsync: deleteEvent } = useHttpMutation({
            method: 'DELETE',
            onSuccess: onRequestClose,
        });

        const [isAccepting, setIsAccepting] = useState(false);
        const [isRejecting, setIsRejecting] = useState(false);

        const handleUpdateEventField = useCallback(
            (fieldName: string) => async (value: string) => {
                try {
                    await updateEvent({
                        pathname: `/agents/${agentId}/calendars/${calendarId}/events/${eventId}`,
                        body: {
                            [fieldName]: value,
                        },
                    });
                    return true;
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                return false;
            },
            [agentId, calendarId, eventId, updateEvent],
        );

        const handleDeleteEvent = useCallback(
            async () => {
                const result = await confirmModal({
                    header: '',
                    question: (
                        <ModalBody
                            icon={<Trash />}
                            title="Remove Event?"
                            description="Once this action is confirmed, it cannot be undone."
                        />
                    ),
                    borderRadius: 'semi-rounded',
                    okButtonLabel: 'Remove',
                    cancelButtonLabel: 'Cancel',
                });

                if (!result) {
                    return;
                }

                showAppSpinner();
                try {
                    await deleteEvent({
                        pathname: `/agents/${agentId}/calendars/${calendarId}/events/${eventId}`,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
            [agentId, deleteEvent, calendarId, eventId],
        );

        const handleRejectEvent = useCallback(
            async () => {
                const result = await confirmModal({
                    header: '',
                    question: (
                        <ModalBody
                            icon={<Trash />}
                            title="Reject Event?"
                            description="Once this action is confirmed, it cannot be undone."
                        />
                    ),
                    borderRadius: 'semi-rounded',
                    okButtonLabel: 'Reject',
                    cancelButtonLabel: 'Cancel',
                });

                if (!result) {
                    return;
                }

                const { rejectStatus } = calendarInfo || {};
                if (!rejectStatus) return;
                setIsRejecting(true);
                await handleUpdateEventField('statusId')(rejectStatus.id);
                setIsRejecting(false);
            },
            [calendarInfo, handleUpdateEventField],
        );

        const handleAcceptEvent = useCallback(
            async () => {
                const { defaultStatus } = calendarInfo || {};
                if (!defaultStatus) return;
                setIsAccepting(true);
                await handleUpdateEventField('statusId')(defaultStatus.id);
                setIsAccepting(false);
            },
            [calendarInfo, handleUpdateEventField],
        );

        const calendarCustomStatuses = useMemo(
            () => (calendarInfo?.statuses || []).filter(
                (status) => Boolean(status.removable),
            ),
            [calendarInfo?.statuses],
        );

        const isBuiltInStatus = useMemo(
            () => {
                const calendarEventStatus = (calendarInfo?.statuses || []).find(
                    (status) => event?.status?.id === status.id,
                );
                if (calendarEventStatus) {
                    return Boolean(
                        builtInEventNames.includes(event?.status?.name as string)
                        && !calendarEventStatus.removable,
                    );
                }

                return false;
            },
            [calendarInfo?.statuses, event?.status?.id, event?.status?.name],
        );

        const handleRescheduleEvent = useCallback(
            () => openRescheduleEventForm({
                mode: 'reschedule',
                initialValues: {
                    eventId,
                    customer: event?.people,
                    calendar: {
                        id: event?.calendarId,
                    },
                    eventType: {
                        id: event?.typeId,
                    },
                    startAt: event?.startAt.toISOString(),
                },
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [
                eventId,
                event?.calendarId,
                event?.startAt,
                event?.typeId,
                openRescheduleEventForm,
            ],
        );

        if (!isOpen) return null;

        const isPending = event?.status?.name === 'pending-approval' && isBuiltInStatus;
        const isRejected = event?.status?.name === 'rejected' && isBuiltInStatus;

        return (
            <InternalOverlay
                {...internalOverlayProps}
                isVisible={isOpen}
                triggerElementRef={triggerRef}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                positionResolver={positionResolver}
            >
                <Backdrop onClick={onRequestClose} />
                <Container ref={ref}>
                    <Header>
                        <ColorBox color={calendarInfo?.color} />
                        <LabelContainer>
                            <Title>{event?.title}</Title>
                            <Description>
                                {formattedDate}
                                <span>&#8226;</span>
                                {formattedTimeRange}
                            </Description>
                        </LabelContainer>
                        <Actions>
                            <ButtonIcon
                                title="delete"
                                tooltip="Delete event"
                                icon={<Trash />}
                                borderRadius="semi-rounded"
                                size="small"
                                onClick={handleDeleteEvent}
                            />
                            <ButtonIcon
                                title="close"
                                tooltip="Close event details"
                                icon={<Close />}
                                borderRadius="semi-rounded"
                                size="small"
                                onClick={onRequestClose}
                            />
                        </Actions>
                    </Header>
                    <Content>
                        <CustomerInfo
                            info={event?.people}
                            isLoading={isLoadingEvent}
                        />
                        <EventDetails>
                            <DetailElement
                                icon={<CalendarIcon />}
                                isLoading={isLoadingEvent}
                                label="Calendar:"
                                value={calendarInfo?.name}
                            />
                            <DetailElement
                                icon={<Clock />}
                                isLoading={isLoadingEvent}
                                label="Duration:"
                                value={formattedDuration}
                            />
                            <RenderIf isTrue={!isBuiltInStatus}>
                                <DetailElement
                                    component={EventStatusBadge}
                                    isLoading={isLoadingEvent}
                                    icon={<Check />}
                                    label="Status:"
                                    value={event?.status?.id}
                                    eventStatuses={calendarCustomStatuses}
                                    onUpdate={handleUpdateEventField('statusId')}
                                />
                            </RenderIf>
                            <RenderIf isTrue={isBuiltInStatus}>
                                <DetailElement
                                    component={CalendarBuiltInStatusBadge}
                                    isLoading={isLoadingEvent}
                                    icon={<Check />}
                                    label="Status:"
                                    value={event?.status as unknown as Record<string, unknown>}
                                />
                            </RenderIf>
                            <DetailElement
                                icon={<User />}
                                isLoading={isLoadingEvent}
                                label="Created By:"
                                value={event?.createdBy as unknown as Record<string, unknown>}
                                component={EventCreatedBy}
                            />
                            <RenderIf isTrue={!isPending && !isRejected}>
                                <DetailElement
                                    component={ReminderList}
                                    icon={<Bell />}
                                    isLoading={isLoadingEvent || isLoadingReminders}
                                    label="Reminders:"
                                    value={reminders as unknown as Record<string, unknown>}
                                    wrap
                                />
                            </RenderIf>
                            <DetailElement
                                component={NotesEditor}
                                icon={<Note />}
                                isLoading={isLoadingEvent}
                                label="Notes & Attachments:"
                                value={event?.notes}
                                files={event?.files}
                                customerInfo={event?.people}
                                wrap
                                onUpdate={handleUpdateEventField('notes')}
                            />
                        </EventDetails>
                    </Content>
                    <RenderIf isTrue={isPending || !isRejected}>
                        <Footer>
                            <RenderIf isTrue={isPending}>
                                <FooterButtonsContainer>
                                    Accept Event?
                                    <Button
                                        variant="outline-brand"
                                        borderRadius="semi-rounded"
                                        size="small"
                                        onClick={handleAcceptEvent}
                                        isLoading={isAccepting}
                                        disabled={isAccepting || isRejecting}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        variant="outline-brand"
                                        borderRadius="semi-rounded"
                                        size="small"
                                        disabled={isAccepting || isRejecting}
                                        isLoading={isRejecting}
                                        onClick={handleRejectEvent}
                                    >
                                        No
                                    </Button>
                                </FooterButtonsContainer>
                            </RenderIf>
                            <RenderIf isTrue={!isPending && !isRejected}>
                                <FooterButtonsContainer justify="flex-end">
                                    <Button
                                        variant="outline-brand"
                                        borderRadius="semi-rounded"
                                        size="small"
                                        onClick={handleRescheduleEvent}
                                    >
                                        Reschedule
                                    </Button>
                                </FooterButtonsContainer>
                            </RenderIf>
                        </Footer>
                    </RenderIf>
                </Container>
            </InternalOverlay>
        );
    },
);

export default CalendarEventDetails;
