import styled from 'styled-components';
import { DIVIDER } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;

    & > div:last-child {
        overflow: scroll;
        flex: none;
    }   
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    height: 960px;
    min-width: 642px;  
`;

export const Days = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-right: 1px;

    & > div:first-child {
        height: 100%;
        border-right: 1px solid ${DIVIDER};
    }       
`;

export const Grid = styled.div`
    border-top: 1px solid ${DIVIDER};
`;

export const GridLine = styled.div`
    height: 40px;

    ::after {
        content: '';
        border-bottom: 1px solid ${DIVIDER};
        position: absolute;
        width: 100%;
        margin-top: -1px;
        z-index: 0;
        pointer-events: none;
    }         
`;

export const Scroll = styled.div`
    display: flex;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: auto;
    width: 100%;
    height: 100%;
`;

export const Day = styled.div`
    flex: 1 0 auto;
    border-right: 1px solid ${DIVIDER};
    position: relative;
    width: 110px;
    min-width: 110px;
    height: 100%;
    display: flex;

    :last-child {
        border-right: none;
    }
`;

export const Highlight = styled.div<{ color: string }>`
    text-align: left;
    font-size: 12px;
    width: 100%;
    min-height: 20px;
    padding: 0 5px;
    overflow: hidden;
    position: absolute;
    background: ${(props) => `${props.color}29`};
`;
