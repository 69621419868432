const getPrimaryLabel = (labels: string[]) => {
    if (labels.length === 0) {
        return null;
    }
    if (labels.includes('spam')) {
        return 'spam';
    }
    if (labels.includes('blocked')) {
        return 'blocked';
    }
    return labels[0];
};

export default getPrimaryLabel;
