import styled from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import LoadingShape from 'components/LoadingShape';
import { RecordField } from '@rainbow-modules/record';
import { GREY_300, GREY_500, GREY_800 } from '../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const AvatarContainer = styled.button`
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0;
    border: none;
    background: none;
    outline: none;
    justify-content: flex-start;
    text-align: left;
`;

export const StyledAvatar = styled(UserAvatar)`
    width: 4.5rem;
    height: 4.5rem;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    pointer-events: none;

    svg {
        width: 3rem;
        height: 3rem;
    }
`;

export const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 1.25rem;
`;

export const NameLabel = styled.label`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
    text-transform: uppercase;
`;

export const PhoneLabel = styled.span`
    font-size: 1.125rem;
    font-weight: 400;
    color: ${GREY_800};
    display: block;
`;

export const SectionTitle = styled.div`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
    text-transform: uppercase;
    margin-bottom: 0.8125rem;
`;

export const ConversationSummaryContainer = styled.div`
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.4rem;
    letter-spacing: 0.4px;
    text-align: left;
    color: ${GREY_500};
`;

export const DetailsElements = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ElementContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${GREY_500};
    margin: 0;

    svg {
        width: 20px;
        height: auto;
    }
`;

export const IconContainer = styled.div`
    display: inline-flex;
    width: 20px;
    height: 20px;
`;

export const StyledRecordField = styled(RecordField)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    > span {
        font-size: 0.9375rem;;
        font-weight: 300;
        line-height: 1.375rem;
        color: ${GREY_500};
    }
    
    > span:first-child {
        font-weight: 500;
        white-space: nowrap;
        color: ${GREY_500};
        line-height: 1.375rem;
    }

    > span:last-child {
        overflow: hidden;
        color: ${GREY_500};
            
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${GREY_500};
        }
    }
`;

export const StyledLoadingShape = styled(LoadingShape)`
    margin-bottom: 5px;
`;

export const Row = styled.div`
    display: inline-flex;
    flex-direction: row;
`;
