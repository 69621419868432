import { useMemo, useState } from 'react';
import { Column } from 'react-rainbow-components';
import { useConnectModal } from '@rainbow-modules/hooks';
import DateTimeColumn from 'components/DateTimeColumn';
import SearchIcon from 'components/icons/search';
import Footer from 'components/Footer';
import FormModal from 'components/FormModal';
import Table from 'components/Table';
import useAgentData from 'hooks/useAgentData';
import useUserRoles from 'hooks/useUserRoles';
import LanguageColumn from 'pages/Customers/CustomersList/columns/preferredLanguage';
import ActionsColumn from './columns/actions';
import { CellWrapper } from './columns/styled';
import { EDIT_PHONE_LANGUAGE } from '../../constants';
import Fields from './fields';
import {
    EmptyLabel,
    SearchContainer, SearchInput, StyledCard, TableContainer,
} from './styled';
import PhoneColumn from './columns/phoneNumber';

const PhoneNumbersPage = () => {
    const [search, setSearch] = useState('');
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const { role, isAdmin } = useUserRoles();
    const userCanWrite = useMemo(() => isAdmin || ['owner', 'editor'].includes(role as string), [role, isAdmin]);
    const connectedModalProps = useConnectModal(EDIT_PHONE_LANGUAGE);

    const filteredNumbers = useMemo(() => {
        if (!search) return agent?.phoneNumbers;

        return agent?.phoneNumbers.filter((phoneNumber) => {
            const { number = '' } = phoneNumber;
            return number.includes(search.replace(/[^0-9.]/g, ''));
        });
    }, [agent?.phoneNumbers, search]);

    return (
        <>
            <StyledCard>
                <SearchContainer>
                    <SearchInput
                        type="search"
                        placeholder="Find phone number..."
                        variant="bare"
                        icon={<SearchIcon />}
                        borderRadius="semi-square"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </SearchContainer>
                <TableContainer>
                    <Table
                        data={filteredNumbers}
                        keyField="id"
                        isLoading={isLoadingAgent}
                    >
                        <Column
                            header="Phone number"
                            field="number"
                            component={PhoneColumn}
                        />
                        <Column
                            header="Preferred Language"
                            field="language"
                            component={LanguageColumn}
                        />
                        <Column
                            header="Created At"
                            field="createdAt"
                            component={DateTimeColumn}
                            emptyLabel={(
                                <CellWrapper>
                                    <EmptyLabel>-</EmptyLabel>
                                </CellWrapper>
                            )}
                        />
                        {userCanWrite && (
                            <Column
                                header="Actions"
                                component={ActionsColumn}
                                disabled={!userCanWrite}
                                defaultWidth={100}
                            />
                        )}
                    </Table>
                </TableContainer>
            </StyledCard>
            <FormModal
                borderRadius="semi-square"
                size="small"
                fields={Fields}
                {...connectedModalProps}
            />
            <Footer />
        </>
    );
};

export default PhoneNumbersPage;
