import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import SaraAvatar from 'components/icons/saraAvatar';
import { DeliveryStatus } from '@twilio/conversations';
import { FormattedDate } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import {
    Item as ChatBubble,
    AuthorContainer,
    AuthorName,
    Time,
    MessageContainer,
    MessageContent,
    BubbleBottomContainer,
    BubbleTopContainer,
    WarningIcon,
} from './styled';
import { Message } from '../../types';
import StatusIcon from './StatusIcon';
import Body from './Body';
import MediaContent from './MediaContent';

interface Props {
    message: Message;
}

const MessageBubble = ({ message }: Props) => {
    const {
        body,
        dateCreated,
        direction: messageDirection,
        user,
        type,
        attachedMedia,
        twilioMessage,
        urlPreviewMetadata,
        status,
    } = message;
    const { agentId } = useParams();
    const { uid } = useCurrentUser() || {};

    const authorDisplayName = useMemo(
        () => getDisplayName(user),
        [user],
    );

    const direction = useMemo(
        () => {
            if (!uid) return messageDirection;
            if (user.uid === uid) return 'outgoing' as Message['direction'];
            return 'incoming' as Message['direction'];
        },
        [uid, user, messageDirection],
    );

    const avatar = useMemo(
        () => {
            if (user.uid === agentId) {
                return <SaraAvatar />;
            }
            return (
                <UserAvatar
                    value={user.uid}
                    initials={getInitials(authorDisplayName)}
                    src={user.photoURL}
                />
            );
        },
        [agentId, authorDisplayName, user],
    );

    return (
        <MessageContainer direction={direction}>
            <AuthorContainer>
                {avatar}
            </AuthorContainer>
            {type === 'text' && (
                <MessageContent direction={direction}>
                    <BubbleTopContainer>
                        <AuthorName>{authorDisplayName}</AuthorName>
                        {dateCreated && (
                            <Time>
                                <FormattedDate
                                    value={dateCreated}
                                    hour="numeric"
                                    minute="numeric"
                                />
                            </Time>
                        )}
                        <RenderIf isTrue={user.uid === 'unknown'}>
                            <WarningIcon title="This member has been removed from the chat but their messages remain visible for reference." />
                        </RenderIf>
                    </BubbleTopContainer>
                    <ChatBubble direction={direction} role={user.role}>
                        {body && (
                            <Body
                                body={body}
                                twilioMessage={twilioMessage}
                                urlPreviewMetadata={urlPreviewMetadata}
                            />
                        )}
                    </ChatBubble>
                    <BubbleBottomContainer>
                        <RenderIf isTrue={direction === 'outgoing' && status}>
                            <StatusIcon status={status as DeliveryStatus} />
                        </RenderIf>
                    </BubbleBottomContainer>
                </MessageContent>
            )}
            {type === 'media' && (
                <MediaContent
                    attachedMedia={attachedMedia}
                    direction={direction}
                    dateCreated={dateCreated}
                    status={status}
                    authorDisplayName={authorDisplayName}
                />
            )}
        </MessageContainer>
    );
};

export default MessageBubble;
