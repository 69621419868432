import { Spinner } from 'react-rainbow-components';
import AudioPlayer from 'components/AudioPlayer';
import Microphone from 'components/icons/microphone';
import {
    StyledContent,
    StyledContiner,
    StyledFileName,
    StyledIcon,
    StyledIconContainer,
    StyledInfo,
} from '../Generic/styled';
import { StyledSpinnerContiner } from './styled';

const AUDIO_EXTENSIONS = /\.(m4a|m4b|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i;

interface Props {
    url?: string | null;
    filename?: string | null;
    isLoading: boolean;
}

const Audio = ({ filename, isLoading, url }: Props) => {
    const canPlay = AUDIO_EXTENSIONS.test(filename || '');

    if (isLoading) {
        return (
            <StyledSpinnerContiner>
                <Spinner type="arc" variant="brand" />
            </StyledSpinnerContiner>
        );
    }

    if (canPlay) {
        return <AudioPlayer url={url} />;
    }

    return (
        <StyledContiner>
            <StyledIconContainer>
                <StyledIcon as={Microphone} />
            </StyledIconContainer>
            <StyledContent>
                <StyledFileName>{filename}</StyledFileName>
                <StyledInfo>Download file.</StyledInfo>
            </StyledContent>
        </StyledContiner>
    );
};

export default Audio;
