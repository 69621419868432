/* eslint-disable react/no-unused-prop-types */
import Editor from '@monaco-editor/react';
import { useReduxForm } from '@rainbow-modules/hooks';
import React, { useRef } from 'react';
import * as monacoLib from 'monaco-editor/esm/vs/editor/editor.api';
import { Container } from './styled';
import registerIntelliSense from './intellisense';
import { Variable, Snippet } from './intellisense/types';

type Monaco = typeof monacoLib;

export interface Markers {
    severity?: monacoLib.MarkerSeverity;
    message: string;
    startLineNumber: number;
    startColumn: number;
    endLineNumber: number;
    endColumn: number;
}

interface Props {
    style?: React.CSSProperties;
    className?: string;
    value: string;
    onChange: (value?: string) => void;
    onSave?: () => void;
    variables?: Variable[];
    snippets?: Snippet[];
    placeholder?: string;
    onValidate?: (value: string) => Markers[] | Promise<Markers[] | undefined> | undefined ;
    validationDebounceTime?: number;
}

const InstructionsEditor = (props: Props) => {
    const {
        value,
        onChange = () => {},
        className,
        style,
        variables = [],
        snippets = [],
        placeholder,
        onSave,
        onValidate,
        validationDebounceTime = 500,
    } = useReduxForm(props);
    const editorRef = useRef<any>(null);
    const monacoRef = useRef<any>(null);
    const validateTimeout = useRef<NodeJS.Timeout>();

    const resetTimeout = () => {
        if (validateTimeout.current) {
            clearTimeout(validateTimeout.current);
            validateTimeout.current = undefined;
        }
    };
    const handleModelValidation = async () => {
        const monaco: Monaco = monacoRef.current;
        const editor = editorRef.current;
        if (onValidate) {
            const markers = await onValidate(editor.getValue());
            const parsedMarkers = markers?.map((marker) => ({
                ...marker,
                severity: marker.severity || monaco.MarkerSeverity.Error,
            })) || [];
            monaco.editor.setModelMarkers(editor.getModel(), 'owner', parsedMarkers);
        }
    };

    const handleDebouncedModelValidation = () => {
        resetTimeout();
        if (validationDebounceTime) {
            validateTimeout.current = setTimeout(() => {
                resetTimeout();
                handleModelValidation();
            }, validationDebounceTime);
        } else {
            handleModelValidation();
        }
    };

    return (
        // eslint-disable-next-line react/forbid-component-props
        <Container className={className} style={style}>
            <Editor
                value={value}
                onChange={(newValue?: string) => {
                    onChange(newValue);
                    if (onValidate) {
                        handleDebouncedModelValidation();
                    }
                }}
                defaultLanguage="handlebars"
                language="handlebars"
                defaultValue={placeholder || ''}
                onMount={(editor, monaco) => {
                    monacoRef.current = monaco;
                    editorRef.current = editor;

                    // eslint-disable-next-line no-bitwise
                    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
                        if (onSave) {
                            onSave();
                        }
                    });
                    registerIntelliSense({
                        monaco,
                        variables,
                        snippets,
                    });
                }}
                keepCurrentModel={false}
                options={{
                    wordWrap: 'on',
                    minimap: {
                        enabled: false,
                    },
                }}
            />
        </Container>
    );
};

export default InstructionsEditor;
