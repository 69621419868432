import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Footer from 'components/Footer';
import { Outlet, useParams } from 'react-router-dom';
import useRedirectWhenNotAdmin from 'hooks/useRedirectWhenNotAdmin';
import { Modal } from 'react-rainbow-components';
import { ADD_EDIT_RESPONDER_MODAL } from '../../constants';
import CreateEditResponderForm from './CreateResponderForm';

type FormOpenMode = 'edit' | 'create';

const Responders = () => {
    const { agentId = '' } = useParams();

    useRedirectWhenNotAdmin({});

    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_RESPONDER_MODAL,
        { size: 'small' },
    );

    const [, closeModal] = useOpenModal(ADD_EDIT_RESPONDER_MODAL);
    const { mode, responderData = {}, ...modalProps } = connectedAddModifyProps;

    return (
        <>
            <Outlet />
            <Footer />
            <Modal {...modalProps}>
                <CreateEditResponderForm
                    mode={mode as FormOpenMode}
                    agentId={agentId}
                    onRequestClose={closeModal}
                    {...(responderData as object)}
                />
            </Modal>
        </>
    );
};

export default Responders;
