import { Spinner } from 'react-rainbow-components';
import VideoIcon from 'components/icons/video';
import VideoPlayer from 'components/VideoPlayer';
import {
    StyledContent,
    StyledContiner,
    StyledFileName,
    StyledIcon,
    StyledIconContainer,
    StyledInfo,
} from '../Generic/styled';
import { StyledSpinnerContiner } from './styled';

const VIDEO_EXTENSIONS = /\.(mp4|og[gv]|webm|mov|m4v)(#t=[,\d+]+)?($|\?)/i;

interface Props {
    url?: string | null;
    filename?: string | null;
    isLoading: boolean;
}

const Video = ({ filename, isLoading, url }: Props) => {
    const canPlay = VIDEO_EXTENSIONS.test(filename || '');
    if (isLoading) {
        return (
            <StyledSpinnerContiner>
                <Spinner type="arc" variant="brand" />
            </StyledSpinnerContiner>
        );
    }

    if (canPlay) {
        return <VideoPlayer url={url as string} />;
    }

    return (
        <StyledContiner>
            <StyledIconContainer>
                <StyledIcon as={VideoIcon} />
            </StyledIconContainer>
            <StyledContent>
                <StyledFileName>{filename}</StyledFileName>
                <StyledInfo>Download file.</StyledInfo>
            </StyledContent>
        </StyledContiner>
    );
};

export default Video;
