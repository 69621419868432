import styled from 'styled-components';
import { Divider } from 'pages/Inbox/styled';
import {
    GREY_800,
    GREY_200,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.45rem;
    gap: 0.5rem;
    overflow: scroll;
    height: 100%;
    padding-bottom: 2rem;
`;

export const Label = styled.span`
    color: ${GREY_800}
    flex-grow: 1;
    line-height: 1.375rem;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
`;

export const Description = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_200};
    margin-bottom: 0.5rem;
`;

export const GeneralVariable = styled.span`
    width: min-content;
    padding: 0rem 0.375rem;
    border-radius: 4px;
    background: #F8F7FA;
    font-family: Menlo, Monaco, "Courier New", monospace;
    color: ${GREY_800};
    line-height: 1.55rem;
`;

export const StyledDivider = styled(Divider)`
    margin: 1rem 0 1rem 0;
`;
