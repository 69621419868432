import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import Button from 'components/Button';
import ChevronLeft from 'components/icons/chevronLeft';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import { Calendar } from 'data/firestore/agent/calendar/types';
import useCalendar from 'data/firestore/agent/calendar/useOnce';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { EntityGet } from 'data/firestore/types';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import {
    Container,
    Divider,
    Footer,
    Header,
    HeaderItems,
    StyledButtonIcon,
    Title,
} from '../../styled';
import CalendarForm from '../CalendarForm';

const mapValues = (data: EntityGet<Calendar> | null) => {
    const {
        name,
        color,
        defaultEventStatusId,
        eventTypes = [],
        eventStatuses = [],
        availability = [],
    } = (data || {}) as EntityGet<Calendar>;
    return {
        name,
        color,
        eventTypes,
        eventStatuses,
        defaultEventStatusId,
        availability,
    };
};

const EditCalendarPage = () => {
    const { agentId = '', calendarId = '' } = useParams();
    const navigate = useNavigateWithQuery();

    const { data, isLoading } = useCalendar(
        agentId,
        calendarId,
        {
            disabled: !(agentId || calendarId),
        },
    );

    const goBack = useCallback(
        () => navigate(`/${agentId}/calendar/settings/calendars`),
        [agentId, navigate],
    );

    const { mutateAsync: updateCalendar } = useHttpMutation({
        method: 'PATCH',
        pathname: `/agents/${agentId}/calendars/${calendarId}`,
        onSuccess: goBack,
    });

    const { mutateAsync: deleteCalendar } = useHttpMutation({
        method: 'DELETE',
        pathname: `/agents/${agentId}/calendars/${calendarId}`,
    });

    const handleUpdateCalendar = useCallback(
        async (values: Record<string, unknown>) => {
            showAppSpinner();
            try {
                await updateCalendar({
                    body: values,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [updateCalendar],
    );

    const handleDeleteCalendar = useCallback(
        async () => {
            const result = await confirmModal({
                header: '',
                question: (
                    <ModalBody
                        icon={<Trash />}
                        title="Remove Calendar?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteCalendar({});
                goBack();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteCalendar, goBack],
    );

    return (
        <Container>
            <Header>
                <HeaderItems>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<ChevronLeft />}
                        onClick={goBack}
                    />
                    <Title title="Edit Calendar">Edit Calendar</Title>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<Trash />}
                        onClick={handleDeleteCalendar}
                    />
                </HeaderItems>
            </Header>
            <Divider />
            <CalendarForm
                id="edit-calendar-form"
                agentId={agentId}
                initialValues={mapValues(data)}
                onSubmit={handleUpdateCalendar}
            />
            <Divider />
            <Footer>
                <Button
                    variant="outline-brand"
                    borderRadius="semi-square"
                    label="Cancel"
                    onClick={goBack}
                />
                <Button
                    variant="brand"
                    borderRadius="semi-square"
                    label="Update"
                    type="submit"
                    form="edit-calendar-form"
                    disabled={isLoading}
                />
            </Footer>
        </Container>
    );
};

export default EditCalendarPage;
