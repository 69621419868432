import ListTile from 'components/ListTile';
import Dollar from 'components/icons/dollar';
import { Plan } from 'types';
import getShortPlanDescription from 'data/services/price/getShortPlanDescription';
import { PlanCellWrapper } from '../styled';

interface Props {
    value?: Plan;
}

const PlanColumn = ({ value }: Props) => (
    <PlanCellWrapper>
        <ListTile
            icon={<Dollar />}
            title={value?.name}
            subtitle={value && getShortPlanDescription(value)}
        />
    </PlanCellWrapper>
);

export default PlanColumn;
