import styled from 'styled-components';
import CopyToClipboardButton from 'components/CopyToClipboardButton';

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
    margin-right: 1.5rem;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
`;

export const StyledSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
