import useNotifications from 'data/firestore/agent/calendarevent/notification/useCollection';
import { Sms } from 'data/firestore/sms/types';
import useSms from 'data/firestore/sms/useCollection';
import { orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';

export interface Reminder {
    notificationId: string;
    smsId?: string;
    body: string;
    when: Date;
    status: 'scheduled' | 'sent' | 'failed';
}

type SmsStatus = 'accepted'
| 'scheduled'
| 'queued'
| 'sending'
| 'sent'
| 'delivery_unknown'
| 'delivered'
| 'undelivered'
| 'failed';

const getReminderStatus = (smsStatus?: SmsStatus): Reminder['status'] => {
    if (smsStatus === 'sent' || smsStatus === 'delivered' || smsStatus === 'delivery_unknown') return 'sent';
    if (smsStatus === 'failed' || smsStatus === 'undelivered') return 'failed';
    return 'scheduled';
};

export default (
    agentId: string,
    eventId: string,
): [Reminder[], boolean] => {
    const {
        data: eventNotifications,
        isLoading: isLoadingNorifications,
    } = useNotifications(agentId, eventId, {
        listQueryFn: (ref) => query(ref, orderBy('when', 'asc')),
        disabled: !agentId || !eventId,
    });
    const {
        data: eventSms,
        isLoading: isLoadingSms,
    } = useSms({
        listQueryFn: (ref) => query(ref, where('metadata.eventId', '==', eventId)),
        disabled: !agentId || !eventId,
    });

    const smsMap = eventSms.reduce((acc, obj) => {
        acc.set(obj.metadata?.notificationId as string, obj);
        return acc;
    }, new Map<string, Sms>());

    const isLoading = isLoadingNorifications || isLoadingSms;

    return useMemo(
        () => [
            isLoading
                ? []
                : eventNotifications
                    .filter((notification) => notification.status !== 'canceled')
                    .map((notification) => {
                        const smsData = smsMap.get(notification.id);

                        return {
                            notificationId: notification.id,
                            smsId: smsData?.id,
                            body: notification.message,
                            when: notification.when,
                            status: getReminderStatus(smsData?.delivery?.info?.status),
                        };
                    }),
            isLoading,
        ],
        [eventNotifications, isLoading, smsMap],
    );
};
