import styled from 'styled-components';
import SaraAvatar from 'components/icons/saraAvatar';
import {
    GREY_400, PURPLE_100, PURPLE_500, PURPLE_700,
} from '../../../../constants';

export const SummaryContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.7rem;
    align-self: stretch;
    width: 100%;
`;

export const StyledSaraAvatar = styled(SaraAvatar)`
    flex-shrink: 0;
    width: 53px;
    height: fit-content;
`;

export const BubbleContainer = styled.div`
    width: 100%;
`;

export const SummaryHeader = styled.div`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; 
    display: flex;
    padding-bottom: 0.25rem;
    align-items: center;
    align-items: flex-end;
    gap: 0.5rem;
    color: ${GREY_400};
`;

export const MessageContainer = styled.div`
    background-color: ${PURPLE_100};
    border-radius: 0rem 0.75rem 0.75rem 0.75rem;
    padding: 1rem;
    color: ${PURPLE_700};
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    white-space: break-spaces;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${PURPLE_100} transparent transparent transparent;
            left: 0;
            top: 0;
            transform: translateX(-50%);
        }
`;

export const DetailsButton = styled.button`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    display: flex;
    padding: 0;
    color: ${PURPLE_500};
    white-space: nowrap;
    background: none;
    border: none;

    &:hover {
        color: ${PURPLE_700};
        text-decoration: underline;
    }
`;

export const DateLabel = styled.small`
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${GREY_400};
`;
