import Footer from 'components/Footer';
import { Container } from './styled';
import ConversionJourneyInsights from './ConversionJourneyInsights';

const OpportunityInsights = () => (
    <Container>
        <ConversionJourneyInsights />
        <Footer />
    </Container>
);

export default OpportunityInsights;
