import { IconProps } from 'types';

const Refresh = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.9214 12.1644C17.9214 8.75992 15.1615 6 11.7569 6C9.9683 6 8.35661 6.76164 7.22978 7.98025H9.3688C9.92109 7.98025 10.3688 8.42796 10.3688 8.98025C10.3688 9.53253 9.92109 9.98025 9.3688 9.98025H5.38855C4.83627 9.98025 4.38855 9.53253 4.38855 8.98025V5C4.38855 4.44772 4.83627 4 5.38855 4C5.94084 4 6.38855 4.44772 6.38855 5V6.01319C7.82326 4.76004 9.70099 4 11.7569 4C16.266 4 19.9214 7.65535 19.9214 12.1644C19.9214 16.6735 16.266 20.3289 11.7569 20.3289C8.20053 20.3289 5.17765 18.0555 4.05733 14.8858C3.87328 14.3651 4.1462 13.7938 4.66692 13.6098C5.18763 13.4257 5.75896 13.6986 5.943 14.2193C6.78973 16.615 9.07446 18.3289 11.7569 18.3289C15.1615 18.3289 17.9214 15.569 17.9214 12.1644Z" fill="currentColor" />
        <path d="M12.8564 9L10.9998 12.543L13.657 13.9355" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Refresh;
