import { IconProps } from 'types';

const TimeIcon = ({ className, title = 'Time' }: IconProps) => (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <path d="M9 6V9L10.5 10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.54146 8.17357C1.49948 8.58565 1.79951 8.95373 2.21159 8.99571C2.62368 9.03768 2.99176 8.73765 3.03374 8.32557L1.54146 8.17357ZM3.36707 10.9922C3.22495 10.6032 2.79434 10.403 2.40527 10.5451C2.0162 10.6872 1.81601 11.1178 1.95813 11.5069L3.36707 10.9922ZM1.5376 14.9996C1.5376 15.4138 1.87338 15.7496 2.2876 15.7496C2.70181 15.7496 3.0376 15.4138 3.0376 14.9996H1.5376ZM2.2876 11.2496V10.4996C1.87338 10.4996 1.5376 10.8354 1.5376 11.2496H2.2876ZM6.0376 11.9996C6.45181 11.9996 6.7876 11.6638 6.7876 11.2496C6.7876 10.8354 6.45181 10.4996 6.0376 10.4996V11.9996ZM3.03374 8.32557C3.34303 5.28921 5.88244 2.96885 8.93432 2.93399L8.91719 1.43409C5.10234 1.47766 1.92807 4.37811 1.54146 8.17357L3.03374 8.32557ZM8.93432 2.93399C11.9862 2.89913 14.578 5.16088 14.9565 8.18939L16.4449 8.00334C15.9717 4.2177 12.732 1.39052 8.91719 1.43409L8.93432 2.93399ZM14.9565 8.18939C15.3351 11.2179 13.3798 14.048 10.4133 14.7655L10.7659 16.2234C14.4741 15.3266 16.9181 11.789 16.4449 8.00334L14.9565 8.18939ZM10.4133 14.7655C7.4467 15.4829 4.41426 13.859 3.36707 10.9922L1.95813 11.5069C3.26711 15.0904 7.05766 17.1203 10.7659 16.2234L10.4133 14.7655ZM3.0376 14.9996V11.2496H1.5376V14.9996H3.0376ZM2.2876 11.9996H6.0376V10.4996H2.2876V11.9996Z" fill="currentColor" />
    </svg>

);
export default TimeIcon;
