import { useCallback, useRef } from 'react';
import { useLocale } from 'react-rainbow-components';
import { Availability } from 'components/CalendarAvailabilityInput/types';
import Header from './header';
import {
    Container,
    Content,
} from './styled';
import useCurrentWeek from './hooks/useCurrentWeek';
import Hours from './hours';
import Week from './week';

interface CalendarWeekPreviewProps {
    highlightColor: string;
    className?: string;
    currentWeek?: Date | string;
    locale?: string;
    availabileHours?: Availability[];
}

const CalendarWeekPreview = ({
    className,
    currentWeek,
    locale: localLocale,
    highlightColor,
    availabileHours = [],
}: CalendarWeekPreviewProps) => {
    const locale = useLocale(localLocale);
    const hoursRef = useRef<HTMLDivElement>(null);
    const daysRef = useRef<HTMLDivElement>(null);
    const week = useCurrentWeek(currentWeek);

    const handleScroll = useCallback(
        (event: any) => {
            if (hoursRef.current) {
                hoursRef.current.scrollTop = event.target.scrollTop;
            }
            if (daysRef.current) {
                daysRef.current.scrollLeft = event.target.scrollLeft;
            }
        },
        [],
    );

    return (
        <Container className={className}>
            <Header
                week={week}
                locale={locale}
                ref={daysRef}
            />
            <Content>
                <Hours
                    ref={hoursRef}
                    locale={locale}
                />
                <Week
                    highlightColor={highlightColor}
                    availabileHours={availabileHours}
                    onScroll={handleScroll}
                />
            </Content>
        </Container>
    );
};

export default CalendarWeekPreview;
