import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import {
    GREY_300, GREY_500, DIVIDER, GREY_200, GREY_400,
} from '../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
`;

export const Header = styled.div`
    display: flex;
    padding: 0rem 0rem 1.5rem 1.5rem;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_500};
`;

export const Description = styled.span`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.375rem;
    color: ${GREY_400};
    margin-bottom: 0.5rem;
`;

export const Content = styled.div`
    display: flex;
    padding: 0rem 1.5rem;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    flex-wrap: wrap;
    overflow: auto;
`;

export const ItemContainer = styled.div`
    display: flex;
    width: 21.25rem;
    padding: 1.25rem;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
    gap: 1rem;
    border-radius: 0.375rem;
    border: 1px solid ${DIVIDER};
`;

export const IconContainer = styled.div`
    display: inline-flex;
    flex: 0;
    padding: 0.375rem;
    border-radius: 0.375rem;
    width: 2rem;
    height: 2rem;
    background: ${(props) => `${props.color}29`};
    color: ${(props) => `${props.color}`};

    svg {
        width: 1.25rem !important;
        height: 1.25rem !important;
        font-size: 1.25rem !important;
    }
`;

export const ItemTitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
`;

export const ItemTitle = styled.div`
    display: flex;
    gap: 0.625rem;
    flex-grow: 1;
    align-items: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    color: ${GREY_300};
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;
