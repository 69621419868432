import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import KanbanBoardColumns from './columns';
import { Container, StyledLoadingShape } from './styled';
import {
    BoardColumn, BaseProps, BoardCard, CardMoveEvent,
} from './types';

interface KanbanBoardProps extends BaseProps {
    className?: string;
    isLoading?: boolean;
    columns?: BoardColumn[];
    cards?: BoardCard[];
    onCardMoved?: (event: CardMoveEvent) => Promise<boolean>;
    sortCardsFn?: (cardA: BoardCard, cardB: BoardCard) => number;
}

const KanbanBoard = ({
    className,
    isLoading = false,
    columns = [],
    cards = [],
    sortCardsFn,
    cardComponent,
    columnHeaderComponent,
    columnFooterComponent,
    onColumnAddClick,
    onCardMoved = async () => true,
}: KanbanBoardProps) => {
    const [cardsPerColumn, setCardsPerColumn] = useState<Record<string, BoardCard[]>>({});

    useEffect(
        () => {
            setCardsPerColumn(columns.reduce(
                (result, column) => {
                    const columnCards = cards.filter((card) => card.column === column.name);
                    return {
                        ...result,
                        [column.name]: (
                            sortCardsFn
                                ? columnCards.sort(sortCardsFn)
                                : columnCards
                        ),
                    };
                },
                {} as Record<string, BoardCard[]>,
            ));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cards, columns],
    );

    const handleDragEnd = useCallback(
        async (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const sourceColumnId = result.source.droppableId;
            const destinationColumnId = result.destination.droppableId;

            if (destinationColumnId === sourceColumnId) {
                return;
            }

            // // moving between lists
            const sourceColumn = cardsPerColumn[sourceColumnId];
            const destinationColumn = cardsPerColumn[destinationColumnId];
            const card = sourceColumn[result.source.index];
            card.column = destinationColumnId;
            sourceColumn.splice(result.source.index, 1);
            destinationColumn.splice(result.destination.index, 0, card);

            setCardsPerColumn({
                ...cardsPerColumn,
                [sourceColumnId]: [...sourceColumn],
                [destinationColumnId]: [...destinationColumn],
            });

            await onCardMoved({
                cardId: card.id,
                sourceColumn: sourceColumnId,
                destinationColumn: destinationColumnId,
            });
        },
        [cardsPerColumn, onCardMoved],
    );

    if (isLoading) {
        return (
            <Container className={className}>
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
                <StyledLoadingShape width="18.75rem" height="100%" />
            </Container>
        );
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Container className={className}>
                <KanbanBoardColumns
                    columns={columns}
                    cards={cardsPerColumn}
                    cardComponent={cardComponent}
                    columnHeaderComponent={columnHeaderComponent}
                    columnFooterComponent={columnFooterComponent}
                    onColumnAddClick={onColumnAddClick}
                />
            </Container>
        </DragDropContext>
    );
};

export default KanbanBoard;
