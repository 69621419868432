import styled from 'styled-components';
import { LoadingShape } from 'react-rainbow-components';

export const Container = styled.div`
    display: flex;
    height: 100%;
    align-items: end;
`;

interface StyledLoadingProps {
    randomWidth?: boolean;
}
export const StyledLoading = styled(LoadingShape).withConfig({
    shouldForwardProp: (prop) => !['randomWidth'].includes(prop),
})<StyledLoadingProps>`
    margin-bottom: 1rem;
    ${(props) => props.randomWidth && `width: ${Math.floor(Math.random() * 100) + 1}%`}
`;

export const LoadingContainer = styled.div`
    width: 100%;
    padding: 1rem;
`;
