import {
    forwardRef, KeyboardEvent, useCallback, useImperativeHandle, useMemo, useRef, useState,
} from 'react';
import ImageIcon from 'components/icons/image';
import MicrophoneIcon from 'components/icons/microphone';
import SendIcon from 'components/icons/send';
import {
    Container, SendButton, ActionsContainer,
    MessageInput, InputContainer, ActionButton,
} from './styled';

interface ChatInputProps {
    className?: string;
    onSend?: (note:string) => Promise<void>;
    disableSend?: boolean;
}

const ChatInput = forwardRef(
    ({
        className,
        onSend = async () => {},
        disableSend = false,
    }: ChatInputProps, ref) => {
        const [value, setValue] = useState('');
        const inputRef = useRef<HTMLTextAreaElement>();
        const normCleanValue = useMemo(() => (value || '').trim(), [value]);

        const sendText = useCallback(
            async () => {
                try {
                    await onSend(normCleanValue);
                    setValue('');
                } catch (error) {
                    // no catch
                }
            },
            [onSend, normCleanValue],
        );

        const handleKeyDown = useCallback(
            (event: KeyboardEvent<HTMLTextAreaElement>) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    if (/\S/.test(normCleanValue)) {
                        sendText();
                    }
                }
            },
            [normCleanValue, sendText],
        );

        useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current?.focus();
            },
        }));

        return (
            <Container className={className}>
                <InputContainer>
                    <MessageInput
                        variant="shaded"
                        placeholder="Write a note here..."
                        value={value}
                        grow
                        rows={1}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        disabled={disableSend}
                    />
                    <ActionsContainer>
                        <ActionButton
                            borderRadius="semi-square"
                            title="Attach image"
                            icon={<ImageIcon />}
                            disabled
                        />
                        <ActionButton
                            borderRadius="semi-square"
                            title="Record Audio"
                            icon={<MicrophoneIcon />}
                            disabled
                        />
                    </ActionsContainer>
                </InputContainer>
                <SendButton
                    variant="brand"
                    icon={<SendIcon />}
                    onClick={sendText}
                    disabled={disableSend || !normCleanValue}
                />
            </Container>
        );
    },
);

export default ChatInput;
