import { Field } from 'react-final-form';
import ColorPicklist from 'components/ColorPicklist';
import { isEmpty } from '@rainbow-modules/validation';
import {
    FieldsContainer,
    StyledInput,
    Message,
} from './styled';

interface Params {
    message?: string;
}

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const Fields = ({
    message = '',
}: Params) => (
    <>
        <Message>{message}</Message>
        <FieldsContainer>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="name"
                label="Name"
                labelAlignment="left"
                placeholder="Enter a name for the event status"
                borderRadius="semi-square"
                autoComplete="off"
                required
                validate={validateNotEmpty}
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={ColorPicklist}
                name="color"
                label="Color"
                labelAlignment="left"
                placeholder="Select Color"
                borderRadius="semi-square"
                required
                validate={validateNotEmpty}
            />
        </FieldsContainer>
    </>
);

export default Fields;
