import { ItemUser } from 'data/firestore/globals';

const resolveUserFromAuthor = ({
    participants = {},
    author = '',
}: {
    participants?: Record<string, ItemUser>;
    author?: string | null;
}) => participants[author as string] || {
    uid: 'unknown',
    displayName: 'Former Member',
};

export default resolveUserFromAuthor;
