import {
    ChangeEvent, MutableRefObject, useRef, useState,
} from 'react';
import { InternalOverlay } from 'react-rainbow-components';
import { useOutsideClick } from '@rainbow-modules/hooks';
import VolumeIcon from 'components/icons/volume';
import { StyledButtonIcon, StyledInputRange, StyledVolumeContainer } from './styled';
import positionResolver from './helpers/positionResolver';

interface Props {
    value?: number;
    onChange: (newValue: number) => void;
    dark?: boolean;
}

interface ButtonIconRef {
    htmlElementRef: MutableRefObject<HTMLElement>;
}

const VolumeInput = ({ value, onChange, dark }: Props) => {
    const triggerRef = useRef<ButtonIconRef>();
    const dropdownRef = useRef<HTMLDivElement>();
    const [isOpen, setIsOpen] = useState(false);

    useOutsideClick(
        dropdownRef as MutableRefObject<HTMLElement>,
        (event) => {
            if (event.target !== triggerRef?.current?.htmlElementRef?.current) {
                setIsOpen(false);
            }
        },
        isOpen,
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const volume = event.target.value as unknown as number;
        onChange(volume);
    };

    return (
        <>
            <StyledButtonIcon
                size="x-small"
                icon={<VolumeIcon />}
                variant="base"
                ref={triggerRef}
                onClick={() => setIsOpen(!isOpen)}
                dark={dark}
            />
            <InternalOverlay
                isVisible={isOpen}
                positionResolver={positionResolver}
                render={() => (
                    <StyledVolumeContainer ref={dropdownRef as MutableRefObject<HTMLDivElement>}>
                        <StyledInputRange
                            type="range"
                            value={value}
                            onChange={handleChange}
                            min={0}
                            max={1}
                            step={0.01}
                        />
                    </StyledVolumeContainer>
                )}
                // eslint-disable-next-line max-len
                triggerElementRef={() => triggerRef?.current?.htmlElementRef as MutableRefObject<HTMLElement>}
            />
        </>
    );
};

export default VolumeInput;
