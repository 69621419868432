import { isDefined } from '@rainbow-modules/validation';
import { useMemo } from 'react';
import { ParamKeyValuePair, URLSearchParamsInit, useSearchParams } from 'react-router-dom';

interface Params {
    filterNames: string[];
    defaultValues?: URLSearchParamsInit;
}

const useSearchFilters = ({
    filterNames = [],
    defaultValues = {},
}: Params) => {
    const [params, setParams] = useSearchParams(defaultValues);

    const values = filterNames.reduce(
        (result, filterName) => ({
            ...result,
            [filterName]: params.get(filterName),
        }),
        {},
    );

    return useMemo(
        () => ({
            values: values as Record<string, string | string[] | null>,
            getValue: (key: string) => {
                if (filterNames.includes(key)) {
                    return params.get(key);
                }
                return null;
            },
            setValue: (key: string, value?: string | null) => setParams(
                (prevParams) => {
                    if (filterNames.includes(key)) {
                        if (!isDefined(value)) {
                            prevParams.delete(key);
                        } else {
                            prevParams.set(key, value as string);
                        }
                    }
                    return prevParams;
                },
            ),
            remove: (key: string) => setParams(
                (prevParams) => {
                    if (filterNames.includes(key)) {
                        prevParams.delete(key);
                    }

                    return prevParams;
                },
            ),
            clear: () => {
                const newParams: ParamKeyValuePair[] = [];
                params.forEach((value, key) => {
                    if (filterNames.includes(key)) return;
                    newParams.push([key, value]);
                });
                setParams(newParams);
            },
        }),
        [values, filterNames, params, setParams],
    );
};

export default useSearchFilters;
