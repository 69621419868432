import styled from 'styled-components';
import Badge from 'components/Badge';
import {
    GREY_600, GREY_800,
} from '../../../../constants';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
`;

export const TaskTitle = styled.h3`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.75rem; 
    color: ${GREY_600};
`;

export const PriorityBadge = styled(Badge)`
    text-transform: capitalize;
`;

export const DateLabel = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 0.625rem;
    color: ${GREY_800}
`;
