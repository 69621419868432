import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import useTask from 'data/firestore/inbox/use';
import useSession from 'data/firestore/session/use';
import useCustomer from 'data/firestore/agent/customer/use';
// import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import { Inbox as Task } from 'data/firestore/inbox/types';
import useSearchFilters from 'hooks/useSearchFilters';
import { TASK_DETAILS_DRAWER } from '../../constants';
// import TaskDetails from './taskDetails';
import { DrawerGradient, StyledDrawer } from './styled';
import TaskDetails from './Details';

const TaskDetailsDrawer = () => {
    const { agentId = '' } = useParams();
    const {
        getValue,
        setValue,
        clear,
    } = useSearchFilters({
        filterNames: ['showTask'],
    });

    const {
        data: taskData = {}, isOpen, ...connectedDrawerProps
    } = useConnectModal(TASK_DETAILS_DRAWER);

    const [openDrawer, closeDrawer] = useOpenModal(TASK_DETAILS_DRAWER);

    const taskId = getValue('showTask') as string;

    const { data: taskInfo, isLoading: isLoadingTask } = useTask(
        taskId,
        {
            disabled: !taskId,
            track: [taskId],
        },
    );

    const sessionId = taskInfo?.sessionId || '';
    const customerId = taskInfo?.customer?.uid || '';

    const { data: sessionInfo = {}, isLoading: isLoadingSession } = useSession(
        sessionId,
        {
            disabled: !sessionId,
            track: [sessionId],
        },
    );

    const [conversationSummary, isLoadingConversationSummary] = useDocOnce({
        path: `/session/${sessionId}/metadata/summary`,
        flat: true,
        disabled: !sessionId,
    });

    const { data: customerInfo = {}, isLoading: isLoadingCustomer } = useCustomer(
        agentId,
        customerId,
        {
            disabled: !agentId || !customerId,
            track: [agentId, customerId],
        },
    );

    const handleCloseDrawer = useCallback(
        () => {
            if (isOpen) {
                clear();
                closeDrawer();
            }
        },
        [closeDrawer, isOpen, clear],
    );

    const isLoading = useMemo(
        () => isLoadingTask
            || isLoadingSession
            || isLoadingCustomer
            || isLoadingConversationSummary,
        [isLoadingConversationSummary, isLoadingCustomer, isLoadingSession, isLoadingTask],
    );

    useEffect(() => {
        if (isOpen) {
            const id = (
                typeof taskData === 'string'
                    ? taskData
                    : (taskData as Task)?.id
            );
            if (id) {
                setValue('showTask', id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (taskId && !isOpen) {
            openDrawer({ data: taskId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId]);

    return (
        <StyledDrawer
            {...connectedDrawerProps}
            isOpen={isOpen}
            onRequestClose={handleCloseDrawer}
            hideCloseButton
            slideFrom="right"
            size="medium"
        >
            <DrawerGradient />
            <RenderIf isTrue={isOpen}>
                <TaskDetails
                    onRequestClose={handleCloseDrawer}
                    isLoading={isLoading}
                    data={{
                        task: taskInfo,
                        session: sessionInfo as EntityGet<Session>,
                        summary: conversationSummary?.summary,
                        customer: customerInfo as EntityGet<Customer>,
                    }}
                />
            </RenderIf>
        </StyledDrawer>
    );
};

export default TaskDetailsDrawer;
