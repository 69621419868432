import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { analytics } from '../firebase';

const useIdentifyUserInAnalytics = () => {
    const user = useCurrentUser();
    const posthog = usePostHog();

    useEffect(() => {
        if (user) {
            const { uid, email, displayName } = user;
            posthog?.identify(uid, {
                name: displayName,
                email,
            });
            setUserId(analytics, uid);
            setUserProperties(analytics, {
                name: displayName,
                email,
            });
        }
    }, [user, posthog]);
};

export default useIdentifyUserInAnalytics;
