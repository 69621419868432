import styled from 'styled-components';
import Card from 'components/Card';
import Input from 'components/Input';
import ButtonIcon from 'components/ButtonIcon';
import { GREY_400, GREY_300, DIVIDER } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledCard = styled(Card)`
    height: calc(100vh - 150px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
`;

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    margin-top: 1rem;
    width: 100%;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-weight: 300;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
    padding: 0 2rem 0 0;
`;

export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    font-weight: 300;
    font-size: 0.9375rem;
`;

export const StyledInput = styled(Input)`
    margin-bottom: 12px;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }

    svg {
        width: 20px !important;
        height: 20px !important;
        margin-left: -6px;
    }
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;
