import { ComponentType, useMemo } from 'react';
import FlaskIcon from 'components/icons/flask';
import { InstructionTestComponentProps } from './types';
import { FullSizeModal, Title } from './styled';

const EmptyComponent = () => null;

interface Params {
    isOpen?: boolean;
    agentId: string;
    template?: string;
    title?: string;
    component: ComponentType<InstructionTestComponentProps & { [key:string]: unknown }>;
    onRequestClose?: () => void;
    [key: string]: unknown;
}

// eslint-disable-next-line max-len
const InstructionTestingModal = ({
    isOpen = false,
    agentId = '',
    template = '',
    title = '',
    component: Component = EmptyComponent,
    onRequestClose = () => {},
    ...rest
}: Params) => {
    const titleLabel = useMemo(
        () => {
            const result = ['Test'];
            if (title) result.push(title);
            return result.join(' - ');
        },
        [title],
    );

    return (
        <FullSizeModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            borderRadius="semi-square"
            size="large"
            title={(
                <Title>
                    <FlaskIcon />
                    {titleLabel}
                </Title>
            )}
        >
            <Component agentId={agentId} template={template} {...rest} />
        </FullSizeModal>
    );
};

export default InstructionTestingModal;
