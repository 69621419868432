import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column, RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Footer from 'components/Footer';
import Input from 'components/Input';
import LoadingShape from 'components/LoadingShape';
import SearchIcon from 'components/icons/search';
import Table from 'components/Table';
import useResponders from 'data/firestore/agent/llm/useCollection';
import useResponderMapping from 'data/firestore/agent/respondermapping/useCollection';
import useRedirectWhenNotAdmin from 'hooks/useRedirectWhenNotAdmin';
import CreateEditResponderForm from 'pages/LLMResponders/CreateResponderForm';
import { NewResponderFormHeader } from 'pages/LLMResponders/RespondersList';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../constants';
import {
    featureIconMap, featuresList, groupDescription, groupIconMap,
} from './constants';
import {
    Container,
    LeftColumn,
    Main,
    SideBar,
    Option,
    OptionIconContainer,
    Header,
    Title,
    SubTitle,
    Modal,
} from './styled';
import DescriptionColumn from './columns/description';
import FeatureColumn from './columns/feature';
import UrlColumn from './columns/url';
import AIPersonaColumn from './columns/aiPersona';
import { Provider } from './context';
import buildUrlForFeature from './helpers/buildUrlForFeature';

const Skills = () => {
    const { agentId = '' } = useParams();
    const [selectedGroup, selectGroup] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');

    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_LLM_RESPONDER_MODAL,
        { size: 'small' },
    );
    const [, closeModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);
    const {
        initialValues,
        onSuccess,
        responderData = {},
        ...modalProps
    } = connectedAddModifyProps;

    useRedirectWhenNotAdmin({});

    const { data: responders, isLoading: isLoadingResponders } = useResponders(agentId);

    const {
        data: responderMapping,
        isLoading: isLoadingResponderMapping,
    } = useResponderMapping(agentId);

    const isLoading = useMemo(
        () => isLoadingResponders || isLoadingResponderMapping,
        [isLoadingResponders, isLoadingResponderMapping],
    );

    const features = useMemo(
        () => {
            if (isLoading) return [];
            if (isEmpty(searchQuery)) {
                return featuresList;
            }
            return featuresList.filter(
                (feature) => (
                    feature.name.includes(searchQuery) || feature.group.includes(searchQuery)
                ),
            );
        },
        [searchQuery, isLoading],
    );

    const groups = useMemo(
        () => features.reduce(
            (result: string[], feature) => {
                if (result.includes(feature.group)) return result;
                return [...result, feature.group];
            },
            [],
        ),
        [features],
    );

    const featuresInGroup = useMemo(
        () => features
            .filter((feature) => feature.group === selectedGroup)
            .map((feature) => ({
                ...feature,
                icon: featureIconMap[feature.name],
                url: buildUrlForFeature({ agentId, feature: feature.name }),
                mapping: responderMapping?.find(
                    (responderMap) => responderMap.feature === feature.name,
                ),
            })),
        [features, selectedGroup, agentId, responderMapping],
    );

    const groupsList = useMemo(
        () => groups.map((group) => (
            <Option
                selected={group === selectedGroup}
                onClick={() => selectGroup(group)}
            >
                <OptionIconContainer>
                    {groupIconMap[group]}
                </OptionIconContainer>
                {group}
            </Option>
        )),
        [groups, selectedGroup],
    );

    useEffect(
        () => {
            if (!groups.includes(selectedGroup)) {
                selectGroup(groups.at(0) || '');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [groups],
    );

    return (
        <Provider value={{ responders, responderMapping }}>
            <Container>
                <LeftColumn>
                    <Input
                        icon={<SearchIcon />}
                        placeholder="Search in AI Skills"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <SideBar>
                        <RenderIf isTrue={isLoading}>
                            <LoadingShape />
                            <LoadingShape />
                            <LoadingShape />
                        </RenderIf>
                        {groupsList}
                    </SideBar>
                </LeftColumn>
                <Main>
                    <Header>
                        <Title>{selectedGroup}</Title>
                        <SubTitle>{groupDescription[selectedGroup]}</SubTitle>
                    </Header>
                    <Table isLoading={isLoading} keyField="name" data={featuresInGroup}>
                        <Column
                            header="Name"
                            field="label"
                            component={FeatureColumn}
                        />
                        {selectedGroup === 'Meta DMs' && (
                            <Column
                                header="Endpoint URL"
                                field="url"
                                component={UrlColumn}
                            />
                        )}
                        {selectedGroup !== 'Meta DMs' && (
                            <Column
                                header="Description"
                                field="description"
                                component={DescriptionColumn}
                            />
                        )}
                        <Column
                            header="AI Persona"
                            agentId={agentId}
                            component={AIPersonaColumn}
                            width={280}
                        />
                    </Table>
                </Main>
            </Container>
            <Footer />
            <Modal
                {...modalProps}
                title={<NewResponderFormHeader />}
            >
                <CreateEditResponderForm
                    agentId={agentId}
                    initialValues={initialValues}
                    disabledFields={['skill', 'feature']}
                    hiddenFields={['skill']}
                    onRequestClose={closeModal}
                    onSuccess={onSuccess}
                    {...(responderData as object)}
                />
            </Modal>
        </Provider>
    );
};

export default Skills;
