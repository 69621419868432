import { SyntheticEvent, useState } from 'react';
import { Spinner } from 'react-rainbow-components';
import ZoomableImage from 'components/ZoomableImage';
import { StyledSpinnerContiner } from './styled';

interface Props {
    url?: string | null;
    isLoading: boolean;
}

const Image = ({ url, isLoading }: Props) => {
    const [dimensions, setDimensions] = useState({
        height: 172,
        width: 212,
    });
    const { height, width } = dimensions;

    if (isLoading) {
        return (
            <StyledSpinnerContiner>
                <Spinner type="arc" variant="brand" />
            </StyledSpinnerContiner>
        );
    }

    return (
        <ZoomableImage
            src={url || ''}
            alt=""
            width={width}
            height={height}
            onLoad={(event: SyntheticEvent<HTMLImageElement>) => {
                const target = event.target as HTMLImageElement;
                setDimensions({
                    width: target.offsetWidth,
                    height: target.offsetHeight,
                });
            }}
        />
    );
};

export default Image;
