import { IconProps } from 'types';

const BarChartIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M4 11L9 11V20H4L4 11Z" stroke="currentColor" strokeWidth="2" />
        <path d="M9 7L14 7V20H9V7Z" stroke="currentColor" strokeWidth="2" />
        <path d="M14 4L19 4V20H14V4Z" stroke="currentColor" strokeWidth="2" />
    </svg>
);
export default BarChartIcon;
