import styled, { css } from 'styled-components';
import { darken, getContrastText } from 'react-rainbow-components/styles/helpers/color';
import { color } from 'theme';
import {
    DIVIDER, GREY_300, RED_500, WHITE,
} from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;

    & > div:last-child {
        overflow: scroll;
        flex: none;
    }   
`;

export const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    height: 2112px;
    min-width: 642px;  
`;

export const StyledCalendars = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-right: 1px;

    & > div:first-child {
        width: 5px;
        min-width: 5px; 
        height: 100%;
        border-right: 1px solid ${DIVIDER};
    }       
`;

export const Grid = styled.div`
    border-top: 1px solid ${DIVIDER};
`;

export const GridLine = styled.div<{ color?: string }>`
    height: 44px;

    ::after {
        content: '';
        border-bottom: 1px solid ${DIVIDER};
        position: absolute;
        width: 100%;
        margin-top: -1px;
        z-index: 0;
        pointer-events: none;

        ${(props) => props.color && css`
            border-bottom: 1px solid ${props.color};
        `};
    }         
`;

export const Scroll = styled.div`
    display: flex;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: auto;
    width: 100%;
    height: 100%;
`;

export const CalendarItemContainer = styled.div`
    flex: 1 0 14.5rem;
    border-right: 1px solid ${DIVIDER};
    position: relative;
    min-width: 14.5rem;
    height: 100%;
    display: flex;

    :last-child {
        border-right: none;
    }
`;

export const StyledEvent = styled.div<{ left: number; width?: number; }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex: 1 0 0;
    align-self: stretch;
    position: absolute;
    cursor: pointer;
    box-sizing: border-box;

    ${(props) => props.width && css`
        max-width: ${props.width}px;
    `};

    ${(props) => props.left > 0 && css`
        margin-left: ${props.left}px;
    `};
`;

export const StyledEventBackground = styled.div<{
    color: string;
    isSelected: boolean;
    isPending?: boolean;
    isRejected?: boolean;
}>`
    position: relative;
    border-radius: 0.25rem;
    padding: 0.25rem 0.625rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.25rem;
    flex: 1 0 0;
    align-self: stretch;
    background: ${(props) => props.color};
    color: ${(props) => getContrastText(props.color)};
    border: 1px solid rgba(255, 255, 255, 0.5);
    white-space: nowrap;

    &:hover {
        background: ${(props) => darken(props.color)};
        color: ${(props) => getContrastText(darken(props.color))};
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        z-index: 1;
    }

    ${(props) => props.isSelected && css`
        background: ${darken(props.color)};
        color: ${getContrastText(darken(props.color))};
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        z-index: 1;
    `};

    ${(props) => (props.isPending || props.isRejected) && css`
        background: ${DIVIDER};
        color: ${GREY_300};

        &:hover {
            background: ${darken(DIVIDER)};
            color: ${darken(GREY_300)};
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
            z-index: 1;
        }

        ${props.isPending && css`
            ::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                height: 8px;
                transform: translate(-40%, -40%);
                background: ${RED_500};
                border: 2px solid ${WHITE};
                border-radius: 50%;
                box-sizing: content-box;
                z-index: 1;
            }
        `};

        ${props.isRejected && css`
            & > span {
                text-decoration: line-through;
            }
        `};
    `};
`;

export const StyledEventTitle = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.875rem;
`;

export const StyledEventDescription = styled.span`
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.875rem;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledClockLine = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1;
`;

export const StyledCircle = styled.div`
    display: inline-block;
    position: absolute;
    top: -4px;
    background-color: ${color('error.main')};
    border-radius: 50%;
    content: "";
    height: 10px;
    width: 10px;
`;

export const StyledLine = styled.div`
    position: relative;
    border-top: 2px solid ${color('error.main')};
    pointer-events: none;
`;

export const UnavailableTime = styled.div<{ height: number; top: number; }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex: 1 0 0;
    align-self: stretch;
    position: absolute;
    box-sizing: border-box;
    top: ${(props) => props.top}px;
    height: ${(props) => props.height}px;
    background: ${GREY_300}10;
`;
