import styled from 'styled-components';
import { color } from 'theme';
import { AlertVariant } from './types';
import { RED_100 } from '../../constants';

const variantColorMap: Record<AlertVariant, any> = {
    brand: color('brand.dark'),
    warning: color('warning.dark'),
    success: color('success.dark'),
    error: color('error.dark'),
    default: color('text.main'),
};

const variantBackgroundColorMap: Record<AlertVariant, any> = {
    brand: color('brand.light'),
    warning: color('warning.light'),
    success: color('success.light'),
    error: RED_100,
    default: color('background.secondary'),
};

export const AlertContainer = styled.div.withConfig({
    shouldForwardProp(prop, defaultValidatorFn) {
        return !['variant'].includes(prop) && defaultValidatorFn(prop);
    },
})<{ variant?: AlertVariant }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1rem;
    color: ${(props) => variantColorMap[props.variant || 'default']};
    background-color: ${(props) => variantBackgroundColorMap[props.variant || 'default']};
`;

export const AlertIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background-color: ${color('background.main')};
    margin-right: .5rem;
    border-radius: 4px;

    > svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;
