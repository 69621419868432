import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Field, useForm, useFormState } from 'react-final-form';
import { isDefined, isEmpty } from '@rainbow-modules/validation';
import ReminderTimeSelect, { ReminderValue } from 'components/ReminderTimeSelect';
import identity from 'helpers/identity';
import {
    FieldsContainer,
    Message,
    StyledTextarea,
    Row,
} from './styled';
import SMSPreview from './smsPreview';

interface Params {
    message?: string;
}

const validateNotEmptyWhen = (value: { value: ReminderValue; }) => {
    const { when, relativeTo } = value.value;
    if (!isDefined(when) || !relativeTo) return 'This field is required';
    return undefined;
};

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const Fields = ({
    message = '',
}: Params) => {
    const { agentId = '' } = useParams();
    const { change } = useForm();
    const { values, initialValues } = useFormState();

    useEffect(
        () => {
            if (values.instructions === initialValues.instructions) {
                change('messageExample', initialValues.messageExample);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.instructions],
    );

    return (
        <>
            <Message>{message}</Message>
            <FieldsContainer>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={ReminderTimeSelect}
                        name="when"
                        label="When to send the reminder"
                        labelAlignment="left"
                        borderRadius="semi-square"
                        required
                        validate={validateNotEmptyWhen}
                    />
                </Row>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledTextarea}
                        name="instructions"
                        label="What do you want to say on this reminder?"
                        labelAlignment="left"
                        placeholder="Ex: Remember the company address....."
                        borderRadius="semi-square"
                        rows={3}
                        required
                        validate={validateNotEmpty}
                    />
                </Row>
                <Row>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={SMSPreview}
                        agentId={agentId}
                        instructions={values.instructions}
                        name="messageExample"
                        borderRadius="semi-square"
                        parse={identity}
                    />
                </Row>
            </FieldsContainer>
        </>
    );
};

export default Fields;
