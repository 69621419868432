import { PositionResolverOpts } from 'react-rainbow-components/components/InternalOverlay';

const positionResolver = (opts: PositionResolverOpts) => {
    const { trigger, content, viewport } = opts;

    const desiredPos = {
        top: Math.max(0, trigger.leftUpAnchor.y - content.height / 2 - trigger.height / 2),
        left: Math.max(0, trigger.leftUpAnchor.x - content.width + 8),
    };

    if (viewport.height < desiredPos.top + content.height) {
        return {
            bottom: 16,
            left: desiredPos.left,
        };
    }

    if (desiredPos.top < 0) {
        return {
            top: 0,
            left: desiredPos.left,
        };
    }

    return desiredPos;
};

export default positionResolver;
