import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Check from 'components/icons/check';
import Transferred from '../icons/transferred';
import Option from './Option';
import InboxIcon from '../icons/inbox';
import Trash from '../icons/trash';
import AlertOctagon from '../icons/alertOctagon';
import Dot from '../Dot';
import {
    Container, OptionsSection, Header,
} from './styled';
import useUnsolvedCount from './hooks/useUnsolvedCount';

const InboxBar = () => {
    const { agentId } = useParams<{ agentId: string; itemId: string; }>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { count: inboxNotifications } = useUnsolvedCount({
        agentId: String(agentId),
    });
    const { count: urgentNotifications } = useUnsolvedCount({
        agentId: String(agentId),
        priority: 'urgent',
    });
    const { count: highNotifications } = useUnsolvedCount({
        agentId: String(agentId),
        priority: 'high',
    });
    const { count: mediumNotifications } = useUnsolvedCount({
        agentId: String(agentId),
        priority: 'medium',
    });
    const { count: lowNotifications } = useUnsolvedCount({
        agentId: String(agentId),
        priority: 'low',
    });

    const toStatus = (event: React.MouseEvent<HTMLAnchorElement>, status = '') => {
        event.preventDefault();
        searchParams.set('status', status);
        navigate(`/${agentId}/inbox?${searchParams.toString()}`);
    };

    const toPriority = (event: React.MouseEvent<HTMLAnchorElement>, priority = '') => {
        event.preventDefault();
        const currentPriority = searchParams.get('priority');
        if (currentPriority !== priority) {
            searchParams.set('priority', priority);
        } else {
            searchParams.delete('priority');
        }
        navigate(`/${agentId}/inbox?${searchParams.toString()}`);
    };

    return (
        <Container>
            <OptionsSection>
                <Option variant="brand" to={`/${agentId}/inbox`} label="Inbox" icon={<InboxIcon />} notifications={inboxNotifications} isActive={!searchParams.get('status')} />
                <Option to="" label="Solved" icon={<Check />} onClick={(event) => toStatus(event, 'solved')} isActive={searchParams.get('status') === 'solved'} />
                <Option to="" label="Transferred" icon={<Transferred />} onClick={(event) => toStatus(event, 'transferred')} isActive={searchParams.get('status') === 'transferred'} />
                <Option to="" label="Spam" icon={<AlertOctagon />} onClick={(event) => toStatus(event, 'spam')} isActive={searchParams.get('status') === 'spam'} />
                <Option to="" label="Trash" icon={<Trash />} onClick={(event) => toStatus(event, 'removed')} isActive={searchParams.get('status') === 'removed'} />
            </OptionsSection>
            <OptionsSection>
                <Header>Priority</Header>
                <Option to="" label="Urgent" icon={<Dot variant="error" />} variant="error" notifications={urgentNotifications} onClick={(event) => toPriority(event, 'urgent')} isActive={searchParams.get('priority') === 'urgent'} />
                <Option to="" label="High" icon={<Dot variant="orange" />} variant="inverse" notifications={highNotifications} onClick={(event) => toPriority(event, 'high')} isActive={searchParams.get('priority') === 'high'} />
                <Option to="" label="Medium" icon={<Dot variant="warning" />} variant="warning" notifications={mediumNotifications} onClick={(event) => toPriority(event, 'medium')} isActive={searchParams.get('priority') === 'medium'} />
                <Option to="" label="Low" icon={<Dot variant="success" />} variant="success" notifications={lowNotifications} onClick={(event) => toPriority(event, 'low')} isActive={searchParams.get('priority') === 'low'} />
            </OptionsSection>
        </Container>
    );
};

export default InboxBar;
