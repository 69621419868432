/* eslint-disable react/forbid-component-props */
import useTimer from '../hooks/useTimer';
import useClockLineStyle from './hooks/useClockLineStyle';
import { StyledClockLine, StyledCircle, StyledLine } from './styled';

const ClockLine = ({
    visible = true,
}: {
    visible?: boolean,
}) => {
    const clock = useTimer();
    const style = useClockLineStyle(clock);
    if (!visible) return null;
    return (
        <StyledClockLine style={style}>
            <StyledCircle />
            <StyledLine />
        </StyledClockLine>
    );
};

export default ClockLine;
