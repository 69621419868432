import formatDuration from 'data/services/duration/formatter';
import { StyledColumnWrapper } from './styled';

interface DateTimeColumnProps {
    value?: number;
}

const DurationColumn = (props: DateTimeColumnProps) => {
    const { value } = props;
    if (!value) return null;
    return <StyledColumnWrapper>{formatDuration(value * 1000)}</StyledColumnWrapper>;
};

export default DurationColumn;
