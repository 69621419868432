import { DateTime } from 'luxon';
import { getFormatter } from 'data/services/date/formatter';

export default ({
    date,
    viewType = 'day',
    locale = 'en-US',
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: {
    date: Date;
    viewType?: 'day' | 'week' | 'month' | 'list';
    locale?: string;
    timezone?: string;
}) => {
    const dateStr = date.toISOString();
    const dateInTz = DateTime
        .fromISO(dateStr)
        .setZone(timezone)
        .setLocale(locale);

    if (['day', 'list'].includes(viewType)) {
        return getFormatter(locale, {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(dateInTz.toJSDate());
    }

    if (viewType === 'week') {
        const from = dateInTz.startOf('week', { useLocaleWeeks: true });
        const to = dateInTz.endOf('week', { useLocaleWeeks: true });
        const dateFrom = from.toJSDate();
        const dateTo = to.toJSDate();

        if (from.hasSame(to, 'month')) {
            return [
                getFormatter(locale, { month: 'short' }).format(dateFrom),
                getFormatter(locale, { day: 'numeric' }).format(dateFrom),
                '-',
                `${getFormatter(locale, { day: 'numeric' }).format(dateTo)},`,
                getFormatter(locale, { year: 'numeric' }).format(dateTo),
            ].join(' ');
        }

        if (from.hasSame(to, 'year')) {
            return [
                getFormatter(locale, { month: 'short' }).format(dateFrom),
                getFormatter(locale, { day: 'numeric' }).format(dateFrom),
                '-',
                getFormatter(locale, { month: 'short' }).format(dateTo),
                `${getFormatter(locale, { day: 'numeric' }).format(dateTo)},`,
                getFormatter(locale, { year: 'numeric' }).format(dateTo),
            ].join(' ');
        }

        return [
            getFormatter(locale, { month: 'short' }).format(dateFrom),
            `${getFormatter(locale, { day: 'numeric' }).format(dateFrom)},`,
            getFormatter(locale, { year: 'numeric' }).format(dateFrom),
            '-',
            getFormatter(locale, { month: 'short' }).format(dateTo),
            `${getFormatter(locale, { day: 'numeric' }).format(dateTo)},`,
            getFormatter(locale, { year: 'numeric' }).format(dateTo),
        ].join(' ');
    }

    if (viewType === 'month') {
        return getFormatter(locale, {
            month: 'long',
            year: 'numeric',
        }).format(dateInTz.toJSDate());
    }

    return '';
};
