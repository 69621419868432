import { useCallback, useMemo } from 'react';
import { Message } from 'data/firestore/message/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useChat from '../../../../hooks/useChat';
import { ChatMessage } from '../../../../types';

const useAgentChat = ({
    agentId = '',
    systemMessage = '',
    context = {},
    messages: initialMessages = [],
} : {
    agentId: string;
    systemMessage?: string;
    context?: Record<string, unknown>;
    messages?: ChatMessage[];
}) => {
    const {
        mutateAsync: startChat,
        isLoading: isStartingConversation,
    } = useHttpMutation<Record<string, unknown>, { greetings: string }>({
        pathname: '/testing/chat/init',
        method: 'POST',
    });

    const {
        mutateAsync: sendConversation,
        isLoading: isAwaitingResponse,
    } = useHttpMutation<Record<string, unknown>, { content: string }>({
        pathname: '/testing/chat/respond',
        method: 'POST',
    });

    const handleInitChat = useCallback(
        async () => {
            const { greetings } = await startChat({
                body: {
                    agentId,
                    context,
                },
            });
            return greetings;
        },
        [agentId, context, startChat],
    );

    const handleSend = useCallback(
        async (messages: Pick<Message, 'content' | 'role'>[]) => {
            const { content: aiResponse } = await sendConversation({
                body: {
                    agentId,
                    systemMessage,
                    conversation: messages,
                },
            });
            return aiResponse;
        },
        [agentId, sendConversation, systemMessage],
    );

    const {
        isInitialized,
        messages,
        initialize,
        reset,
        addMessage,
        updateMessage,
        deleteMessage,
        send,
    } = useChat({
        initialized: initialMessages.length > 0,
        messages: initialMessages,
        onInitChat: handleInitChat,
        onSend: handleSend,
    });

    return useMemo(
        () => ({
            isLoading: isStartingConversation || isAwaitingResponse,
            isInitialized,
            messages,
            initialize,
            reset,
            addMessage,
            updateMessage,
            deleteMessage,
            send,
        }),
        [
            isStartingConversation,
            isAwaitingResponse,
            isInitialized,
            messages,
            initialize,
            reset,
            addMessage,
            updateMessage,
            deleteMessage,
            send,
        ],
    );
};

export default useAgentChat;
