import {
    SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from '@rainbow-modules/validation';
import { RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import ExternalLink from 'components/icons/externalLink';
import AccordionSection from 'components/AccordionSection';
import ConversationSummary from 'components/ConversationSummary';
import CallTranscription from 'components/CallTranscriptionHighlights';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import { Customer } from 'data/firestore/agent/customer/types';
import useSession from 'data/firestore/session/use';
import useResolveCustomerByPhoneNumber from 'data/hooks/useResolveCustomerByPhoneNumber';
import {
    StyledAccordion,
    AccordionHeader,
    PrimaryText,
    SecondaryText,
} from './styled';

interface CallInformationProps { sessionId: string; }

const CallInformation = ({ sessionId }: CallInformationProps) => {
    const { agentId = '' } = useParams();
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState<string | string[]>();
    const [fetchData, setFetchData] = useState(false);
    const [session, setSession] = useState<EntityGet<Session> | null>();
    const [customer, setCustomer] = useState<EntityGet<Customer> | null>();

    const { data: sessionData } = useSession(sessionId || '', {
        disabled: !sessionId || !fetchData || Boolean(session),
    });

    const { data: customerData } = useResolveCustomerByPhoneNumber(
        session?.agentId || '',
        session?.from || '',
        {
            disabled: Boolean(!session?.agentId || !session?.from || !fetchData || customer),
        },
    );

    const handleToggleAccordion = useCallback(
        (event: any, activeNames: SetStateAction<string | string[] | undefined>) => {
            setFetchData(!isEmpty(activeNames));
            setActiveSection(activeNames);
        },
        [],
    );

    useEffect(() => {
        setSession(sessionData);
    }, [sessionData]);

    useEffect(() => {
        setCustomer(customerData as EntityGet<Customer>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData]);

    return (
        <StyledAccordion
            activeSectionNames={activeSection}
            onToggleSection={handleToggleAccordion}
        >
            <AccordionSection
                label={(
                    <AccordionHeader>
                        <div>
                            <PrimaryText>
                                Call Information
                            </PrimaryText>
                            <SecondaryText>
                                ID:
                                {' '}
                                {sessionId}
                            </SecondaryText>
                        </div>
                        <ButtonIcon
                            variant="base"
                            icon={<ExternalLink />}
                            size="small"
                            onClick={() => navigate(`/${agentId}/voip/history/${sessionId}`)}
                        />
                    </AccordionHeader>
                )}
            >
                <RenderIf isTrue={Boolean(session)}>
                    <ConversationSummary sessionId={sessionId} />
                    <CallTranscription
                        sessionId={sessionId}
                        recordingId={session?.recordingId}
                        from={customer}
                        status={session?.status}
                        markHighlightedText
                    />
                </RenderIf>
            </AccordionSection>
        </StyledAccordion>
    );
};

export default CallInformation;
