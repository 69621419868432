import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import useAgentData from 'hooks/useAgentData';
import { StyledDot } from 'components/CalendarListView/styled';
import { statusLabelMap } from '../helpers/mappers';
import {
    ButtonBar, Content, SecondaryInsightSectionContainer, StyledPlot, StyledButton,
} from '../styled';
import useMonthlyBookingsInsights from '../hooks/useMonthlyBookingsInsights';
import MonthlyBookingsInsightsFilters from './filters';

const MonthlyBookingsInsights = () => {
    const { agentId = '' } = useParams();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const [statusFilter, setStatusFilter] = useState(['total-bookings', 'accepted']);
    const [filter, setFilter] = useState('last-12-months');

    const { from, to } = useMemo(
        () => {
            if (!agent) return {};
            const timeZone = agent?.companyAddress.timezone
                || Intl.DateTimeFormat().resolvedOptions().timeZone;

            if (filter === 'current-year') {
                return {
                    from: DateTime.now().setZone(timeZone).startOf('year'),
                    to: DateTime.now().setZone(timeZone).endOf('year'),
                };
            }

            if (filter === 'last-year') {
                return {
                    from: DateTime.now().setZone(timeZone).minus({ months: 12 }).startOf('year'),
                    to: DateTime.now().setZone(timeZone).minus({ months: 12 }).endOf('year'),
                };
            }

            return {
                from: DateTime.now().setZone(timeZone).minus({ months: 12 }).startOf('month'),
                to: DateTime.now().setZone(timeZone).endOf('month'),
            };
        },
        [agent, filter],
    );

    const {
        isLoading: isLoadingEvents,
        data: mappedData = {},
        totalBookings,
    } = useMonthlyBookingsInsights({
        agentId,
        from: from?.toJSDate(),
        to: to?.toJSDate(),
    });

    const toggleStatusFilter = useCallback(
        (name: string) => {
            if (statusFilter.includes(name)) {
                setStatusFilter(statusFilter.filter((status) => status !== name));
            } else if (statusFilter.length < 2) {
                setStatusFilter([...statusFilter, name]);
            }
        },
        [statusFilter],
    );

    const plotData = useMemo(
        () => {
            const {
                data = {}, months = [],
            } = (mappedData || {}) as {
                months?: string[];
                data?: Record<string, { color: string; values: number[]; }>;
            };

            return {
                tiles: Object.keys(data)
                    .sort(
                        (a, b) => {
                            if (/^pending/gi.test(a)) return 1;
                            if (/^total/gi.test(a)) return -1;
                            return a.charCodeAt(0) - b.charCodeAt(0);
                        },
                    )
                    .map(
                        (key) => ({
                            name: key,
                            color: data[key].color,
                            label: statusLabelMap[key],
                        }),
                    ),
                data: (
                    statusFilter.length === 0
                        ? [{
                            x: months,
                            y: Array.from(new Array(months.length)).fill(0),
                            type: 'bar',
                            xaxis: 'x',
                            yaxis: 'y',
                        }]
                        : Object.keys(data).map(
                            (key) => ({
                                visible: statusFilter.includes(key),
                                automargin: true,
                                x: months,
                                y: data[key].values,
                                type: 'bar',
                                xaxis: 'x',
                                yaxis: 'y',
                                text: data[key].values.map(String),
                                textfont: {
                                    color: '#fff',
                                },
                                name: key,
                                width: 0.35,
                                marker: {
                                    color: data[key].color,
                                    cornerradius: 4,
                                },
                                hoverinfo: 'text',
                                hovertext: data[key].values.map((item: number) => `${item} ${statusLabelMap[key].toLowerCase()}`) || [],
                            }),
                        )
                ),
            };
        },
        [mappedData, statusFilter],
    );

    const buttons = useMemo(
        () => plotData.tiles?.map(
            (tile) => (
                <StyledButton
                    key={`legend__${tile.name}`}
                    variant={statusFilter.includes(tile.name) ? 'outline-brand' : 'neutral'}
                    borderRadius="semi-square"
                    size="small"
                    onClick={() => toggleStatusFilter(tile.name as string)}
                    disabled={!statusFilter.includes(tile.name) && statusFilter.length === 2}
                >
                    <StyledDot color={tile.color} />
                    {tile.label}
                </StyledButton>
            ),
        ),
        [plotData.tiles, statusFilter, toggleStatusFilter],
    );

    const isLoading = isLoadingAgent || isLoadingEvents;

    return (
        <SecondaryInsightSectionContainer
            label="Monthly Booking Trends"
            description={`Total Bookings: ${totalBookings}`}
            isLoading={isLoadingAgent}
            filter={(
                <MonthlyBookingsInsightsFilters
                    value={filter}
                    onChange={setFilter}
                    disabled={isLoading}
                />
            )}
        >
            <Content>
                <StyledPlot
                    config={{
                        displayModeBar: false,
                        responsive: true,
                        autosizable: true,
                        doubleClick: false,
                        editable: false,
                        fillFrame: false,
                        scrollZoom: false,
                        showAxisDragHandles: false,
                        showAxisRangeEntryBoxes: false,
                        showTips: false,
                        showLink: false,
                        showEditInChartStudio: false,
                    }}
                    data={plotData.data as Plotly.Data[]}
                    layout={{
                        autosize: true,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        showlegend: false,
                        bargroupgap: 0.1,
                        margin: {
                            l: 30,
                            r: 20,
                            b: 30,
                            t: 0,
                        },
                        xaxis: {
                            showgrid: false,
                            fixedrange: true,
                            zeroline: false,
                            ticks: 'outside',
                            tickcolor: 'transparent',
                        },
                        yaxis: {
                            fixedrange: true,
                            zeroline: false,
                            rangemode: 'tozero',
                        },
                        legend: {
                            orientation: 'h',
                            xanchor: 'center',
                            x: 0.5,
                            y: -0.2,
                            valign: 'bottom',
                        },
                    }}
                />
                <ButtonBar>
                    {buttons}
                </ButtonBar>
            </Content>
        </SecondaryInsightSectionContainer>
    );
};

export default MonthlyBookingsInsights;
