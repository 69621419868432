import { Inbox } from 'data/firestore/inbox/types';
import { EntityGet } from 'data/firestore/types';

interface Args {
    tasks: EntityGet<Inbox>[];
    searchQuery?: string;
}

const filterTasks = ({
    searchQuery,
    tasks,
}: Args) => {
    if (!searchQuery) return tasks;

    const query = searchQuery.toLowerCase();
    return tasks.filter((task) => task.title?.toLowerCase().includes(query)
        || task.customer?.displayName?.toLowerCase().includes(query)
        || task.customer?.firstName?.toLowerCase().includes(query)
        || task.customer?.lastName?.toLowerCase().includes(query)
        || task.customer?.phoneNumber?.toLowerCase().includes(query));
};

export default filterTasks;
