import { RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import TimeIcon from 'components/icons/time';
import HelpIcon from 'components/icons/help';
import Trash from 'components/icons/trash';
import FloppyIcon from 'components/icons/floppy';
import FlaskIcon from 'components/icons/flask';
import AdjustmentsHorizontal from 'components/icons/adjustmentsHorizontal';
import ValidationStatus, { ValidationStatusProps } from 'components/ValidationStatus';
import {
    StyledFooter,
    FooterButton,
    LeftButtonsContainer,
} from './styled';

interface FormFooterProps {
    formId?: string;
    submitLoading?: boolean;
    onOpenHistory?: (value: boolean) => void;
    historyOpen?: boolean;
    onOpenHelp?: (value: boolean) => void;
    helpOpen?: boolean;
    onOpenModelConfig?: (value: boolean) => void;
    modelConfigOpen?: boolean;
    onsubmit?: () => void;
    validationStatus?: 'valid' | 'invalid' | 'loading';
    validationErrors?: number;
    disableModelConfig?: boolean;
    deleteEnabled?: boolean;
    onDelete?: () => void;
    disableTesting?: boolean;
    onOpenTest?: () => void;
}

const FormFooter = ({
    formId,
    submitLoading = false,
    onOpenHistory,
    historyOpen = false,
    onOpenHelp,
    helpOpen = false,
    onOpenModelConfig,
    modelConfigOpen = false,
    onsubmit = () => {},
    validationStatus,
    validationErrors,
    disableModelConfig = false,
    deleteEnabled = true,
    onDelete = () => {},
    disableTesting = true,
    onOpenTest = () => {},
}: FormFooterProps) => (
    <StyledFooter>
        <LeftButtonsContainer>
            <RenderIf isTrue={validationStatus}>
                <ValidationStatus status={validationStatus as ValidationStatusProps['status']} errors={validationErrors} />
            </RenderIf>
            <RenderIf isTrue={deleteEnabled}>
                <ButtonIcon
                    icon={<Trash />}
                    onClick={() => onDelete()}
                    size="small"
                    className="rainbow-m-left_small"
                    borderRadius="semi-square"
                    variant="base"
                />
            </RenderIf>
        </LeftButtonsContainer>
        <div className="rainbow-m-left_medium">
            <RenderIf isTrue={onOpenModelConfig}>
                <FooterButton
                    borderRadius="semi-square"
                    onClick={() => onOpenModelConfig?.(!modelConfigOpen)}
                    disabled={disableModelConfig || modelConfigOpen}
                    title="AI Model"
                    icon={<AdjustmentsHorizontal />}
                />
            </RenderIf>
            <RenderIf isTrue={onOpenHelp}>
                <FooterButton
                    borderRadius="semi-square"
                    onClick={() => onOpenHelp?.(!historyOpen)}
                    disabled={helpOpen}
                    icon={<HelpIcon />}
                    title="Help"
                />
            </RenderIf>
            <RenderIf isTrue={onOpenHistory}>
                <FooterButton
                    borderRadius="semi-square"
                    onClick={() => onOpenHistory?.(!historyOpen)}
                    disabled={historyOpen}
                    icon={<TimeIcon />}
                    title="History"
                />
            </RenderIf>
            <FooterButton
                borderRadius="semi-square"
                icon={<FlaskIcon />}
                onClick={() => onOpenTest()}
                disabled={disableTesting}
                title="Testing"
            />
            <FooterButton
                type="submit"
                form={formId}
                borderRadius="semi-square"
                onClick={() => onsubmit()}
                disabled={validationStatus !== 'valid' || submitLoading}
                icon={<FloppyIcon />}
                title="Save Changes"
            />
        </div>
    </StyledFooter>
);

export default FormFooter;
