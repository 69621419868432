import { IconProps } from 'types';

const Headphones = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
    >
        <title>{title}</title>
        <path d="M21 18.4286C21 19.8487 19.8487 21 18.4286 21V21C17.6396 21 17 20.3604 17 19.5714L17 13.4286C17 12.6396 17.6396 12 18.4286 12V12C19.8487 12 21 13.1513 21 14.5714L21 18.4286Z" stroke="currentColor" strokeWidth="2" />
        <path d="M3 14.5714C3 13.1513 4.15127 12 5.57143 12V12C6.36041 12 7 12.6396 7 13.4286L7 19.5714C7 20.3604 6.36041 21 5.57143 21V21C4.15127 21 3 19.8487 3 18.4286L3 14.5714Z" stroke="currentColor" strokeWidth="2" />
        <path d="M19 12V10C19 6.13401 15.866 3 12 3V3C8.13401 3 5 6.13401 5 10V12" stroke="currentColor" strokeWidth="2" />

    </svg>
);
export default Headphones;
