import { IconProps } from 'types';

const Clock = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none">
        <title>{title}</title>
        <circle
            cx="10"
            cy="10"
            r="7.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 5.83325V9.99992L12.5 12.4999"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Clock;
