import { isEmpty } from '@rainbow-modules/validation';

const validateNotEmpty = (
    message: string,
) => (value: unknown) => {
    if (isEmpty(value)) return message;
    return undefined;
};

export default validateNotEmpty;
