import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import Input from 'components/Input';
import { DIVIDER, GREY_200 } from '../../../../../constants';

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    padding: 1rem 0 1rem;

    & > div {
        flex: 1;
    }
`;

export const StyledInput = styled(Input)`
    flex: 1;
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledTextarea = styled(Textarea)`
    display: flex;
    box-sizing: border-box;
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > div {
        border: none;
        box-shadow: none;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};
        padding: 0.5rem 0.875rem;

        :focus, :active {
            width: 100%;
            padding: 0.5rem 0.875rem;
            border: 1px solid ${DIVIDER};
        }

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;

export const Row = styled.div`
    display: flex;
    flex: 0;
    align-items: stretch;
    gap: 1rem;

    & > div {
        flex: 1;
    }
`;
