import { Column } from 'react-rainbow-components';
import Pagination from 'components/Pagination';
import useHttpQuery from 'data/firestore/useHttpQuery';
import { useState } from 'react';
import { Invoice, Page } from 'types';
import IdColumn from './columns/id';
import PlanColumn from './columns/plan';
import UsageColumn from './columns/usage';
import TotalColumn from './columns/total';
import StatusColumn from './columns/status';
import ActionsColumn from './columns/actions';
import {
    TableContainer,
    PaginationContainer,
    EntriesText,
    StyledTable,
} from './styled';
import DateColumn from './columns/date';

interface Props {
    subscriptionId?: string | null;
}
const pageSize = 5;

const InvoiceList = ({ subscriptionId }:Props) => {
    const [localPages, setLocalPages] = useState<(string | undefined)[]>([undefined]);
    const [activePage, setActivePage] = useState(0);
    const currentPageId = localPages[activePage];
    const {
        data: pageResult,
        isFetched,
    } = useHttpQuery<Page<Invoice>>({
        pathname: `/subscriptions/${subscriptionId}/invoices?limit=${pageSize}${currentPageId ? `&page=${currentPageId}` : ''}`,
        queryOptions: {
            enabled: Boolean(subscriptionId),
            onSuccess: (data: Page<Invoice>) => {
                const existsNextPage = Boolean(data.next_page);
                const isNextPageLocal = localPages[activePage + 1] === data.next_page;
                if (existsNextPage && !isNextPageLocal) {
                    setLocalPages((prev) => [...prev, data.next_page]);
                }
            },
        },
    });
    const isLoading = !isFetched;

    const invoices = pageResult?.data || [];
    const total = pageResult?.total || 0;
    return (
        <TableContainer>
            <StyledTable
                data={invoices}
                isLoading={isLoading}
                keyField="id"
            >
                <Column header="ID" field="number" component={IdColumn} defaultWidth={200} />
                <Column header="Date" field="created" component={DateColumn} />
                <Column header="Plan" field="plan" component={PlanColumn} />
                <Column header="Usage" field="usage" component={UsageColumn} />
                <Column header="Total" field="amount_due" component={TotalColumn} />
                <Column header="Status" field="status" component={StatusColumn} />
                <Column header="Actions" component={ActionsColumn} defaultWidth={110} />
            </StyledTable>

            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {activePage * pageSize + 1}
                    {' '}
                    to
                    {' '}
                    {Math.min((activePage + 1) * pageSize, total)}
                    {' '}
                    of
                    {' '}
                    {total}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    pages={localPages.length}
                    activePage={activePage + 1}
                    onChange={(_, newPage) => {
                        setActivePage(newPage - 1);
                    }}
                />
            </PaginationContainer>
        </TableContainer>
    );
};

export default InvoiceList;
