import {
    query, where, orderBy,
} from 'firebase/firestore';
import useDayAggregateCollection from 'data/firestore/agent/dateinsight/useCollection';
import { Dateinsight } from 'data/firestore/agent/dateinsight/types';
import useAgentData from 'hooks/useAgentData';
import { DateTime } from 'luxon';
import breakdownDate from './helpers/breakdownDate';

function getLastNDays(n: number, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    const dates = Array.from({ length: n }).map((_, i) => DateTime.now().setZone(timezone).minus({ days: n - i - 1 }).toFormat('kkkkMMdd'));
    return dates;
}

function fillMissingDates(
    dates: string[],
    data: Dateinsight[],
    timezone: string,
    agentId: string,
) : Dateinsight[] {
    return dates.map((date) => {
        const entry = data.find((d) => d.id === date);
        if (!entry) {
            const { year, month, day } = breakdownDate(date);
            return {
                date,
                id: date,
                total: 0,
                duration: 0,
                year: parseInt(year, 10),
                month: parseInt(month, 10),
                day: parseInt(day, 10),
                timezone,
                agentId,
            };
        }
        return entry;
    });
}

function formatDate(date: Date, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).toFormat('kkkkMMdd');
}
const useLast30DaysCallData = (agentId: string) => {
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));

    const todayStr = formatDate(today, agent?.companyAddress?.timezone);
    const thirtyDaysAgoStr = formatDate(thirtyDaysAgo, agent?.companyAddress?.timezone);
    const { data, isLoading: isLoadingInsights } = useDayAggregateCollection(agentId, {
        disabled: isLoadingAgent,
        listQueryFn: (ref) => query(
            ref,
            where('date', '>=', thirtyDaysAgoStr),
            where('date', '<=', todayStr),
            orderBy('date'),
        ),
    });
    const isLoading = isLoadingAgent || isLoadingInsights;
    return {
        data: isLoading ? {
            insights: [],
            timezone: agent?.companyAddress.timezone as string,
        } : {
            insights: fillMissingDates(
                getLastNDays(30, agent?.companyAddress.timezone),
                data,
                agent?.companyAddress.timezone as string,
                agentId,
            ),
            timezone: agent?.companyAddress.timezone as string,
        },
        isLoading,
    };
};

export default useLast30DaysCallData;
