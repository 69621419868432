import { CellWrapper, StyledStatusChip } from '../styled';

interface Props {
    value?: string;
}

const colorsMap: Record<string, 'base' | 'warning' | 'brand' | 'success' | 'error'> = {
    unknown: 'base',
    ringing: 'warning',
    'in-progress': 'brand',
    completed: 'success',
    cancelled: 'error',
    'no-answer': 'base',
    blocked: 'base',
};

const labelMap: Record<string, string> = {
    unknown: 'Unknown',
    ringing: 'Ringing',
    'in-progress': 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled',
    'no-answer': 'No Answer',
    blocked: 'Blocked',
};

const StatusColumn = ({ value }: Props) => (
    <CellWrapper>
        <StyledStatusChip label={labelMap[value || 'unknown'] || 'Unknown'} size="small" variant={colorsMap[value || 'unknown'] || 'base'} borderRadius="semi-square" />
    </CellWrapper>
);

export default StatusColumn;
