import { IconProps } from 'types';

const ArrowDown = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M4 13L12 21M12 21L20 13M12 21L12 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default ArrowDown;
