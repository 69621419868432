import ModalBody from 'components/ModalBody';
import AlertTriangle from 'components/icons/alertTriangle';

const ImportModal = () => (
    <ModalBody
        title={'Are you sure you want to import these "instructions" into your assistant?'}
        description="Please confirm that you want to import these instructions into you assistant. You assistant already have instructions created, the existing instructions and partials will be permanently removed from your assistant."
        icon={<AlertTriangle />}
        variant="warning"
    />
);

export default ImportModal;
