import { Field, composeValidators, isRequired } from '@rainbow-modules/forms';
import Input from 'components/Input';
import ModalBody from 'components/ModalBody';
import isCamelCase from 'helpers/validators/isCamelCase';
import { ModalDescriptionContainer } from './styled';

const isNotMain = () => (value: string) => {
    if (value === 'main') {
        return 'The name cannot be "main".';
    }
    return undefined;
};

const Fields = () => (
    <ModalBody
        title="Create New Partial"
        description={(
            <ModalDescriptionContainer>
                Create reusable content blocks here to streamline your instruction writing process
                in the main workspace.
                <Field
                    name="name"
                    component={Input}
                    label="Name"
                    placeholder="Sample: samplePartialName"
                    validate={composeValidators(isRequired(), isCamelCase('The name should be in camelCase format.'), isNotMain())}
                    labelAlignment="left"
                    borderRadius="semi-square"
                    required
                />
                <Field
                    name="description"
                    component={Input}
                    label="Description"
                    placeholder="Enter a description for your partial"
                    labelAlignment="left"
                    borderRadius="semi-square"
                />
            </ModalDescriptionContainer>
        )}
    />
);

export default Fields;
