import Ajv from 'ajv';

const validateJsonSchema = (value?: string | { [key: string]: unknown }) => {
    if (!value) return undefined;
    try {
        const json = (
            typeof value === 'string'
                ? JSON.parse(value)
                : value
        );
        if (
            Object.keys(json).length === 0
            || json.type !== 'object'
            || !json.properties
        ) {
            return 'Invalid JSON Schema';
        }

        const ajv = new Ajv({ allErrors: true });
        const isValid = ajv.validateSchema(json);
        if (isValid) return undefined;
    } catch (error) {
        // no catch
    }
    return 'Invalid JSON Schema';
};

export default validateJsonSchema;
