import { Outlet, useParams } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import CreateEditCustomerForm from 'components/CreateEditCustomerForm';
import Footer from 'components/Footer';
import { ADD_EDIT_CUSTOMER_MODAL } from '../../constants';
import { StyledDrawer } from './styled';

type FormOpenMode = 'edit' | 'create';

const CustomersPage = () => {
    const { agentId = '' } = useParams();
    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_CUSTOMER_MODAL,
        { size: 'small', slideFrom: 'right' },
    );

    const [, closeDrawer] = useOpenModal(ADD_EDIT_CUSTOMER_MODAL);
    const {
        mode,
        onCompleted,
        customerData = {},
        ...drawerProps
    } = connectedAddModifyProps;

    return (
        <>
            <Outlet />
            <Footer />
            <StyledDrawer
                {...drawerProps}
                onRequestClose={closeDrawer}
            >
                <CreateEditCustomerForm
                    mode={mode as FormOpenMode}
                    agentId={agentId}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onCompleted={onCompleted}
                    onRequestClose={closeDrawer}
                    {...(customerData as object)}
                />
            </StyledDrawer>
        </>
    );
};

export default CustomersPage;
