/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
    useState,
} from 'react';
import { useConnectModal, useOpenModal, useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option, RenderIf } from 'react-rainbow-components';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { Customer } from 'data/firestore/agent/customer/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import CreateEditCustomerForm from 'components/CreateEditCustomerForm';
import CirclePlusIcon from 'components/icons/plusCircle';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import { EntityGet } from 'data/firestore/types';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getInitials from 'data/services/string/getInitials';
import { PAGE_SIZE, ALGOLIA_INDEX_CUSTOMERS } from '../../constants';
import SelectedValue from './value';
import {
    Container, StyledPicklist, StyledButton, StyledDrawer,
} from './styled';

interface CustomerPickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    value?: string | PicklistValue;
    onChange?: (value?: Customer | null) => void;
    disableCreate?: boolean;
}

const ADD_CUSTOMER_FORM_PREFIX = 'customer-picker-add-form';

const CustomerPicker = (props: CustomerPickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        disabled,
        disableCreate = false,
    } = useReduxForm(props);

    const connectedAddModifyProps = useConnectModal(
        `${ADD_CUSTOMER_FORM_PREFIX}__${name}`,
        { size: 'small', slideFrom: 'right' },
    );

    const [openDrawer, closeDrawer] = useOpenModal(`${ADD_CUSTOMER_FORM_PREFIX}__${name}`);
    const {
        mode, customerData = {}, onCompleted, ...drawerProps
    } = connectedAddModifyProps;

    const [search, setSearch] = useState<string>('');

    const algoliaData = useAlgoliaSearchData<EntityGet<Customer>>({
        search,
        activePage: 1,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_CUSTOMERS,
        filters: `agentId:${agentId}`,
        enabled: true,
    });

    const customers = useMemo(
        () => algoliaData?.hits || [],
        [algoliaData?.hits],
    );

    const pickListvalue = useMemo(() => {
        if (valueInProps) {
            let inputValue: Customer | null | undefined = valueInProps as Customer;
            if (typeof valueInProps === 'string') {
                inputValue = customers.find((customer) => customer.id === valueInProps);
            }
            if (inputValue) {
                const { id: customerId, phoneNumber, ...rest } = inputValue;
                const displayName = getDisplayName(rest);
                return {
                    label: displayName,
                    name: customerId,
                    value: inputValue,
                    searchableText: displayName,
                };
            }
        }
        return undefined;
    }, [customers, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const handleOnSearch = useCallback(
        (query: string) => setSearch(query),
        [],
    );

    const handleClearValue = useCallback(
        () => {
            onChange(null);
            setSearch('');
        },
        [onChange],
    );

    const createCustomer = useCallback(
        () => new Promise(
            (resolve) => {
                openDrawer({
                    header: 'Add a Customer',
                    onRequestClose: () => {
                        closeDrawer();
                        resolve(false);
                    },
                    onCompleted: (data: Record<string, unknown>) => {
                        closeDrawer();
                        resolve(data);
                    },
                });
            },
        ),
        [closeDrawer, openDrawer],
    );

    const handleCreateCustomerClick = useCallback(
        async () => {
            if (disableCreate) return;
            const result = await createCustomer() as Customer;
            if (result) {
                onChange(result);
            }
        },
        [createCustomer, disableCreate, onChange],
    );

    const pickListOptions = useMemo(
        () => customers.map(
            (customer) => {
                const displayName = getDisplayName(customer);
                const initials = (
                    displayName === 'Unknown'
                        ? null
                        : getInitials(displayName)
                );
                const formattedPhoneNumber = formatIntlPhoneNumber(customer.phoneNumber);
                return (
                    <Option
                        key={`customer__${customer.id}`}
                        label={(
                            <ListTile
                                icon={(
                                    <UserAvatar
                                        initials={initials}
                                        value={customer.id}
                                        src={customer.photoURL}
                                    />
                                )}
                                title={displayName}
                                subtitle={formattedPhoneNumber}
                            />
                        )}
                        name={customer.id}
                        value={customer}
                        searchableText={`${displayName} ${formattedPhoneNumber} ${customer.phoneNumber}`}
                    />
                );
            },
        ),
        [customers],
    );

    if (pickListvalue) {
        return (
            <SelectedValue
                id={id}
                className={className}
                label={label}
                required={required}
                disabled={disabled}
                value={pickListvalue.value}
                onClearValue={handleClearValue}
            />
        );
    }

    return (
        <>
            <Container id={id} className={className}>
                <StyledPicklist
                    name={name}
                    label={label}
                    labelAlignment="left"
                    tabIndex={tabIndex}
                    required={required}
                    placeholder={placeholder}
                    value={pickListvalue}
                    onChange={handleOnChange}
                    error={error}
                    disabled={disabled}
                    enableSearch
                    onSearch={handleOnSearch}
                    debounce
                >
                    <Option label="Select Customer" variant="header" />
                    {pickListOptions}
                </StyledPicklist>
                <RenderIf isTrue={!disableCreate}>
                    <StyledButton
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        onClick={handleCreateCustomerClick}
                        hasError={Boolean(error)}
                    >
                        <CirclePlusIcon className="rainbow-m-right_x-small" />
                        New Customer
                    </StyledButton>
                </RenderIf>
            </Container>
            <StyledDrawer
                {...drawerProps}
                onRequestClose={closeDrawer}
            >
                <CreateEditCustomerForm
                    mode="create"
                    agentId={agentId}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onCompleted={onCompleted}
                    {...(customerData as object)}
                />
            </StyledDrawer>
        </>
    );
};

export default CustomerPicker;
