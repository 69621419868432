import { RenderIf } from 'react-rainbow-components';
import CheckIcon from 'components/icons/check';
import {

    StyledSpinner,
    ValidationStatusSpan,
    BadgeErrorCount,
} from './styled';

export interface ValidationStatusProps {
    status: 'valid' | 'invalid' | 'loading';
    errors?: number;
}

const ValidationStatus = ({
    status,
    errors = 1,
}: ValidationStatusProps) => (
    <>
        <RenderIf isTrue={status === 'loading'}>
            <ValidationStatusSpan variant="loading">
                <StyledSpinner type="arc" variant="brand" />
                Validating
            </ValidationStatusSpan>
        </RenderIf>
        <RenderIf isTrue={status === 'valid'}>
            <ValidationStatusSpan variant="success">
                <CheckIcon className="rainbow-m-right_xx-small" />
                Verified
            </ValidationStatusSpan>
        </RenderIf>
        <RenderIf isTrue={status === 'invalid'}>
            <ValidationStatusSpan variant="error">
                <BadgeErrorCount variant="error" className="rainbow-m-right_xx-small">{errors}</BadgeErrorCount>
                Errors
            </ValidationStatusSpan>
        </RenderIf>
    </>
);

export default ValidationStatus;
