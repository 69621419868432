import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/Button';
import ChevronLeft from 'components/icons/chevronLeft';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import {
    Container,
    Divider,
    Footer,
    Header,
    HeaderItems,
    StyledButtonIcon,
    Title,
} from '../../styled';
import CalendarForm from '../CalendarForm';

const initialValues = {
    color: '#7367F0',
    availability: [
        { dayOfWeek: 'monday', startAt: 25200, duration: 36000 },
        { dayOfWeek: 'tuesday', startAt: 25200, duration: 36000 },
        { dayOfWeek: 'wednesday', startAt: 25200, duration: 36000 },
        { dayOfWeek: 'thursday', startAt: 25200, duration: 36000 },
        { dayOfWeek: 'friday', startAt: 25200, duration: 36000 },
        { dayOfWeek: 'saturday', startAt: 25200, duration: 36000 },
    ],
};

const NewCalendarPage = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();

    const goBack = useCallback(
        () => navigate(`/${agentId}/calendar/settings/calendars`),
        [agentId, navigate],
    );

    const { mutateAsync: createCalendar } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/calendars`,
        onSuccess: goBack,
    });

    const handleAddNewCalendar = useCallback(
        async (values: Record<string, unknown>) => {
            showAppSpinner();
            try {
                await createCalendar({
                    body: values,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [createCalendar],
    );

    return (
        <Container>
            <Header>
                <HeaderItems>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<ChevronLeft />}
                        onClick={goBack}
                    />
                    <Title title="New Calendar">New Calendar</Title>
                </HeaderItems>
            </Header>
            <Divider />
            <CalendarForm
                id="create-calendar-form"
                agentId={agentId}
                initialValues={initialValues}
                onSubmit={handleAddNewCalendar}
            />
            <Divider />
            <Footer>
                <Button
                    variant="outline-brand"
                    borderRadius="semi-square"
                    label="Cancel"
                    onClick={goBack}
                />
                <Button
                    variant="brand"
                    borderRadius="semi-square"
                    label="Create"
                    type="submit"
                    form="create-calendar-form"
                />
            </Footer>
        </Container>
    );
};

export default NewCalendarPage;
