import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { CheckboxGroupProps } from 'react-rainbow-components/components/CheckboxGroup';
import LoadingShape from 'components/LoadingShape';
import useEventTypes from 'data/firestore/agent/calendareventtype/useCollection';
import { StyledCheckBoxGroup } from './styled';

interface Params extends Omit<CheckboxGroupProps, 'value' | 'onChange'> {
    agentId?: string;
    value?: Record<string, unknown>[];
    onChange?: (values: Record<string, unknown>[]) => void;
}

const CalendarEventTypeSelect = ({
    agentId = '',
    ...props
}: Params) => {
    const {
        value: valueInProps,
        onChange = () => {},
        ...rest
    } = useReduxForm(props);

    const { data = [], isLoading } = useEventTypes(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    const options = useMemo(
        () => data.map(
            (type) => ({
                value: type.id,
                label: type.name,
            }),
        ),
        [data],
    );

    const value = useMemo(() => (valueInProps || []).map((type) => type.id), [valueInProps]);

    const handleOnChange = useCallback(
        (values: string[]) => onChange(values.map((val) => ({ id: val }))),
        [onChange],
    );

    if (isLoading) {
        return (
            <>
                <LoadingShape width="30%" />
                <LoadingShape width="40%" />
                <LoadingShape width="10%" />
            </>
        );
    }
    return (
        <StyledCheckBoxGroup
            {...rest}
            hideLabel
            options={options}
            value={value as string[]}
            onChange={handleOnChange}
        />
    );
};

export default CalendarEventTypeSelect;
