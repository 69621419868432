import { IconProps } from 'types';

const OpenAI = ({ className, title }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={30}
        height={31}
        viewBox="0 0 30 31"
        fill="none"
    >
        <title>{title}</title>
        <path
            fill="#000"
            d="M28.022 13.154a7.386 7.386 0 0 0-.651-6.139c-1.659-2.85-4.994-4.316-8.25-3.626A7.622 7.622 0 0 0 13.406.875C10.077.868 7.124 2.983 6.1 6.108a7.572 7.572 0 0 0-5.065 3.626 7.483 7.483 0 0 0 .942 8.863 7.386 7.386 0 0 0 .651 6.139c1.659 2.85 4.994 4.316 8.25 3.626a7.617 7.617 0 0 0 5.714 2.513c3.331.008 6.286-2.109 7.31-5.237a7.572 7.572 0 0 0 5.065-3.626 7.483 7.483 0 0 0-.944-8.86l-.001.002Zm-11.428 15.76a5.726 5.726 0 0 1-3.648-1.3c.047-.025.128-.07.18-.101l6.054-3.45c.31-.174.5-.499.498-.85V14.79l2.558 1.458c.028.013.046.039.05.069v6.974c-.004 3.101-2.55 5.616-5.692 5.622ZM4.352 23.756a5.535 5.535 0 0 1-.679-3.767l.18.106 6.054 3.45a.997.997 0 0 0 .995 0l7.39-4.211v2.916a.093.093 0 0 1-.036.078l-6.12 3.486c-2.725 1.549-6.206.628-7.783-2.058ZM2.76 10.715a5.66 5.66 0 0 1 2.965-2.464l-.002.206v6.9a.97.97 0 0 0 .497.85l7.39 4.21-2.558 1.459a.091.091 0 0 1-.087.007l-6.12-3.49c-2.72-1.554-3.653-4.988-2.086-7.677h.001Zm21.022 4.828-7.39-4.212 2.558-1.457a.092.092 0 0 1 .087-.007l6.12 3.486c2.725 1.554 3.66 4.994 2.085 7.683a5.677 5.677 0 0 1-2.965 2.463v-7.107a.968.968 0 0 0-.494-.85Zm2.547-3.782a8.284 8.284 0 0 0-.18-.106l-6.054-3.45a.997.997 0 0 0-.995 0l-7.39 4.211V9.5a.093.093 0 0 1 .035-.077l6.12-3.484C20.59 4.387 24.075 5.31 25.646 8a5.557 5.557 0 0 1 .68 3.76h.002Zm-16.01 5.196L7.757 15.5a.088.088 0 0 1-.05-.069V8.456c.002-3.105 2.555-5.621 5.702-5.62 1.331 0 2.62.462 3.643 1.302a4.232 4.232 0 0 0-.18.1l-6.054 3.45a.967.967 0 0 0-.498.85l-.004 8.418v.001ZM11.707 14l3.291-1.876L18.293 14v3.751l-3.293 1.875-3.292-1.875V14Z"
        />
    </svg>
);

export default OpenAI;
