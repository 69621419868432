const validateConversation = (
    message: string,
) => (value: unknown) => {
    if ((value as string).split('\n').some(
        (line) => !line.startsWith('assistant: ') && !line.startsWith('user: '),
    )) return message;
    return undefined;
};

export default validateConversation;
