/* eslint-disable jsx-a11y/aria-role */
import { useCallback, useContext, useEffect } from 'react';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Button from 'components/Button';
import ChatView from 'components/ChatView';
import ImportIcon from 'components/icons/import';
import ImportConversationModal from 'components/ImportConversationModal';
import context, { Context } from 'components/InstructionTestingLayout/context';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import { IMPORT_VOIP_CONVERSATION_MODAL } from '../../../../constants';
import { ChatMessage } from '../../../../types';
import useChat from '../../../../hooks/useChat';
import { Container } from './styled';
import { TopBar } from '../SummaryInstructionTesting/styled';

interface Params extends InstructionTestStepComponentProps {}

const ConversationView = ({
    isLoading = false,
    onChange = () => {},
}: Params) => {
    const { chatMessages = [] } = useContext<Context>(context);
    const connectedProps = useConnectModal(IMPORT_VOIP_CONVERSATION_MODAL);
    const [openModal] = useOpenModal(IMPORT_VOIP_CONVERSATION_MODAL);

    const {
        messages,
        isInitialized,
        isLoading: isLoadingMessages,
        initialize: initializeChat,
        reset: resetChat,
        addMessage,
        updateMessage,
        deleteMessage,
        importMessages,
    } = useChat({
        messages: chatMessages,
        initialized: chatMessages?.length > 0,
    });

    const handleSubmit = useCallback(
        async () => onChange({ messages }),
        [messages, onChange],
    );

    const importConversation = useCallback(
        () => new Promise(
            (resolve) => {
                openModal({
                    onCancel: () => resolve(false),
                    onImport: resolve,
                });
            },
        ),
        [openModal],
    );

    const handleImportClick = useCallback(
        async () => {
            const result = await importConversation() as ChatMessage[];
            if (!result) return;
            importMessages(result);
        },
        [importMessages, importConversation],
    );

    useEffect(
        () => {
            if (!isInitialized) initializeChat();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (!isInitialized) return null;

    return (
        <Container>
            <TopBar>
                <Button
                    variant="outline-brand"
                    borderRadius="semi-square"
                    onClick={handleImportClick}
                >
                    <ImportIcon className="rainbow-m-right_x-small" />
                    Import conversation
                </Button>
            </TopBar>
            <ChatView
                isLoading={isLoadingMessages}
                disabled={isLoading}
                messages={messages}
                initialized={isInitialized}
                onChatInit={initializeChat}
                onAddMessage={addMessage}
                onUpdateMessage={updateMessage}
                onDeleteMessage={deleteMessage}
                onSubmit={handleSubmit}
                onReset={resetChat}
            />
            <ImportConversationModal {...connectedProps} />
        </Container>
    );
};

export default ConversationView;
