import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    OptionContainer,
    OptionLabelContainer,
    OptionLabel,
    OptionDescription,
    IconContainer,
} from './styled';

interface RolePickerOptionProps {
    roleName?: string;
    label?: string;
    description?: string;
    roleIcon?: ReactNode;
}

const RolePickerOption = ({
    label,
    description,
    roleIcon,
    roleName,
}: RolePickerOptionProps) => (
    <OptionContainer>
        <RenderIf isTrue={roleIcon}>
            <IconContainer roleName={roleName}>{roleIcon}</IconContainer>
        </RenderIf>
        <OptionLabelContainer>
            <OptionLabel>{label}</OptionLabel>
            <RenderIf isTrue={description}>
                <OptionDescription>{description}</OptionDescription>
            </RenderIf>
        </OptionLabelContainer>
    </OptionContainer>
);

export default RolePickerOption;
