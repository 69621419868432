/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'with',
    body: '{{#with ${1}}}\n\t${0}\n{{/with}}',
    documentation: 'with statement',
};

export default snippet;
