import { ReactNode, useState } from 'react';
import { ShowIf } from 'react-rainbow-components';
import useSearchFilters from 'hooks/useSearchFilters';
import {
    StyledContent, AccordionContainer, AccordionLabel, StyledChevron, AccordionLabelContainer,
} from './styled';

interface AccordionProps {
    children: ReactNode;
    name: string;
    label: string;
    icon?: React.ReactNode;
}

const Accordion = ({
    children, label, icon, name,
}: AccordionProps) => {
    const { getValue } = useSearchFilters({ filterNames: [name] });

    const isFilterSet = getValue(name) !== null;

    const [expanded, setExpanded] = useState<boolean>(true);

    return (
        <AccordionContainer>
            <StyledContent
                isActive={isFilterSet}
                onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
            >
                <AccordionLabelContainer>
                    {icon}
                    <AccordionLabel>{label}</AccordionLabel>
                </AccordionLabelContainer>
                <StyledChevron expanded={expanded} />
            </StyledContent>
            <ShowIf isTrue={expanded} inAnimation="fade" outAnimation="slideVertical">
                {children}
            </ShowIf>
        </AccordionContainer>
    );
};

export default Accordion;
