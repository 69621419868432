import { IconProps } from 'types';

const Note = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M17 3L5 3C3.89543 3 3 3.89543 3 5L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 7M17 3L21 7M17 3L17 5C17 6.10457 17.8954 7 19 7L21 7" stroke="currentColor" strokeWidth="2" />
        <path d="M7 8H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 12H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 16H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

    </svg>
);
export default Note;
