import { useCallback } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import CustomerInfo from 'components/TasksInCategory/Task/CustomerInfo';
import { DetailsButton } from 'components/TasksInCategory/Task/Summary/styled';
import { TaskActions, TaskHeader } from 'components/TasksInCategory/Task/styled';
import TaskTitle from 'components/TasksInCategory/Task/Title';
import TaskStatusActions from 'components/TaskStatusActions';
import { EntityGet } from 'data/firestore/types';
import { Inbox } from 'data/firestore/inbox/types';
import { TASK_DETAILS_DRAWER } from '../../constants';
import { TaskContainer } from './styled';

interface TaskProps {
    task: EntityGet<Inbox>;
}

const Task = ({ task }: TaskProps) => {
    const [openModal] = useOpenModal(TASK_DETAILS_DRAWER);

    const isSolved = !!task?.solvedBy;
    const isArchived = !!task?.removedBy;

    const handleOpenDetails = useCallback(
        () => openModal({
            data: {
                id: task?.id,
            },
        }),
        [openModal, task],
    );

    return (
        <TaskContainer>
            <TaskHeader>
                <TaskTitle
                    label={task.title}
                    priority={task.priority}
                    date={task.createdAt}
                />
                <TaskActions>
                    <TaskStatusActions
                        taskId={task.id}
                        customer={task.customer as unknown as Record<string, unknown>}
                        isArchived={isArchived}
                        isSolved={isSolved}
                    />
                </TaskActions>
            </TaskHeader>
            <CustomerInfo
                hideActions
                customer={{
                    ...task.customer,
                    id: task.customer?.uid || '',
                }}
            />
            <DetailsButton onClick={handleOpenDetails}>See all details</DetailsButton>
        </TaskContainer>
    );
};

export default Task;
