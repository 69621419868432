import styled from 'styled-components';
import { GREY_800, GREY_400 } from '../../constants';

export const SummaryItemContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1.5rem;
    text-align: center;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 1rem;
`;

export const Value = styled.h1`
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.875rem;
    text-align: left;
    white-space: nowrap;
    color: ${GREY_800};
`;

export const Label = styled.p`
    margin-top: 0.3rem;
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.375rem;
    text-align: left;
    white-space: nowrap;
    color: ${GREY_400};
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 0.4rem;
`;
