import { UniversalPicker } from '@rainbow-modules/forms';
import Calendar from 'components/Calendar';
import styled, { css } from 'styled-components';
import {
    PURPLE_500, GREY_700, DIVIDER, GREY_100, WHITE,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    gap: 2.5rem;
    flex: 1;
    align-self: stretch;
    height: 100%;
    flex-wrap: wrap;
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const RightColumn = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    align-items: stretch;
    gap: 1rem;
    height: 100%;
`;

export const StyledCalendar = styled(Calendar)`
    margin: 0;
`;

export const DateLabel = styled.h3`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    text-align: center;
    color: ${GREY_700};
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-self: stretch;
    align-items: flex-start;
    padding-bottom: 2rem;
`;

export const StyledUniversalPicker = styled(UniversalPicker)`
    flex: 1;

    & > div {
        gap: 1rem;
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const ItemContainer = styled.div<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.375rem;
    padding: 0.625rem 1.25rem;
    gap: 0.75rem;
    border: 1px solid ${DIVIDER};

    ${(props) => props.isHover && !props.disabled && css`
        border-color: ${PURPLE_500};
        cursor: pointer;
    `};

    ${(props) => props.isSelected && css`
        background: ${PURPLE_500};
        color: ${WHITE};
        box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
    `};

    ${(props) => props.disabled && css`
        color: ${GREY_100};
        border-color: ${GREY_100};
        box-shadow: 0 0 0 0 transparent;
        cursor: not-allowed;
    `};  
`;

export const ItemLabel = styled.span`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.02688rem;
`;
