import { PURPLE_500 } from '../../constants';

interface IconProps {
    title?: string;
    className?: string;
}

const Logo = ({ title = 'Voxity.ai', className }: IconProps) => (
    <svg className={className} width={68} height={68} viewBox="0 0 30 30" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.00464 7.6117C6.00464 5.49613 7.71964 3.78113 9.83521 3.78113C11.9508 3.78113 13.6658 5.49613 13.6658 7.6117V22.0634C13.6658 22.8327 14.2894 23.4563 15.0587 23.4563C15.828 23.4563 16.4517 22.8327 16.4517 22.0634V14.4023C16.4517 12.2867 18.1667 10.5717 20.2822 10.5717C22.3978 10.5717 24.1128 12.2867 24.1128 14.4023V15.9693C24.1128 16.6425 23.5671 17.1881 22.894 17.1881C22.2209 17.1881 21.6752 16.6425 21.6752 15.9693V14.4023C21.6752 13.633 21.0515 13.0093 20.2822 13.0093C19.5129 13.0093 18.8893 13.633 18.8893 14.4023V22.0634C18.8893 24.179 17.1743 25.894 15.0587 25.894C12.9432 25.894 11.2281 24.179 11.2281 22.0634V7.6117C11.2281 6.8424 10.6045 6.21877 9.83521 6.21877C9.06592 6.21877 8.44228 6.8424 8.44228 7.6117V10.7458C8.44228 11.4189 7.89659 11.9646 7.22346 11.9646C6.55032 11.9646 6.00464 11.4189 6.00464 10.7458V7.6117Z" fill={PURPLE_500} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.781234 15.9693L0.781233 14.054C0.781233 13.3809 1.32692 12.8352 2.00005 12.8352C2.67319 12.8352 3.21887 13.3809 3.21887 14.054L3.21887 15.9693C3.21887 16.6424 2.67319 17.1881 2.00005 17.1881C1.32692 17.1881 0.781234 16.6424 0.781234 15.9693Z" fill={PURPLE_500} />
        <path fillRule="evenodd" clipRule="evenodd" d="M26.8989 15.9693L26.8989 14.054C26.8989 13.3809 27.4446 12.8352 28.1177 12.8352C28.7909 12.8352 29.3365 13.3809 29.3365 14.054L29.3365 15.9693C29.3365 16.6424 28.7909 17.1881 28.1177 17.1881C27.4446 17.1881 26.8989 16.6424 26.8989 15.9693Z" fill={PURPLE_500} />
    </svg>
);

export default Logo;
