import {
    EmptyMessageContainer, EmptyMessageDescription, EmptyMessageEmoji, EmptyMessageTitle,
} from './styled';

const EmptySearchMessage = ({
    query,
}: {
    query?: string
}) => (
    <EmptyMessageContainer>
        <EmptyMessageEmoji>🔍</EmptyMessageEmoji>
        <EmptyMessageTitle>No Matching Results</EmptyMessageTitle>
        <EmptyMessageDescription>
            Hmm, we couldn&apos;t find anything matching your search for
            {' '}
            &quot;
            <b>{query}</b>
            &quot;. Try using different keywords or check your spelling.
        </EmptyMessageDescription>
    </EmptyMessageContainer>
);

export default EmptySearchMessage;
