import { AggregatedDeliveryReceipt, DeliveryStatus } from '@twilio/conversations';

const getMessageStatus = (aggregatedDeliveryReceipt: AggregatedDeliveryReceipt) => {
    const {
        delivered, sent, read, undelivered, failed,
    } = aggregatedDeliveryReceipt;
    let status: DeliveryStatus = 'queued';
    if (delivered === 'all' && sent === 'all' && read === 'all') {
        status = 'read';
    } else if (delivered === 'all' && sent === 'all') {
        status = 'delivered';
    } else if (sent === 'all') {
        status = 'sent';
    } else if (undelivered === 'all') {
        status = 'undelivered';
    } else if (failed === 'all') {
        status = 'failed';
    }
    return status as DeliveryStatus;
};

export default getMessageStatus;
