import styled from 'styled-components';
import Card from 'components/Card';

export const StyledCard = styled(Card)`
    min-height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    display: flex;
    min-height: 5.375rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
`;
