import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import useUserRoles from 'hooks/useUserRoles';
import SmsNotifications from 'components/SmsNotifications';
import { User } from 'data/firestore/user/types';
import useTwilioConversation from 'hooks/useTwilioConversation';
import Agents from './agents';
import Avatar from './avatar';
import { Container, RightSection } from './styled';

const TopBar = () => {
    const { agentId = '' } = useParams();
    const { user, isAdmin } = useUserRoles();
    const { isSMSEnabled } = useTwilioConversation();
    return (
        <Container>
            <Agents user={user as User} isAdmin={isAdmin} />
            <RightSection>
                <RenderIf isTrue={isSMSEnabled}>
                    <SmsNotifications agentId={agentId} />
                </RenderIf>
                <Avatar user={user as User} />
            </RightSection>
        </Container>
    );
};

export default TopBar;
