import styled from 'styled-components';
import { color } from 'theme';
import Illustration from './illustration';

export const Container = styled.div`
    display: flex;
    min-height: 100vh;
    background-color: ${color('background.main')};
    position: relative;
    overflow-x: hidden;
`;

export const IlustrationContainer = styled.div`
    flex: 1 1 0%;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    margin: 2rem 0rem 2rem 2rem;
    background: #F8F7FA;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledIllustration = styled(Illustration)`
    max-width: 75%;
    height: auto;

        @media (max-width: 1539px) {
            max-width: 85%;
        }

        @media (max-width: 1199px) {
            max-width: 90%;
        }
`;

export const RightContent = styled.div`
    width: 100%;

    @media (min-width: 900px) {
        max-width: 450px;
    }

    @media (min-width: 1200px) {
        max-width: 600px;
    }

    @media (min-width: 1536px) {
        max-width: 750px;
    }
`;

export const FormContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 600px) {
        padding: 3rem;
    }

    @media (min-width: 0) {
        padding: 1.5rem;
    }
`;

export const LoginContent = styled.div`
    width: 100%;
    max-width: 400px;
`;
