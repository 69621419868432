export const opportunityScoreLabels: Record<string, { title: string; description: string }> = {
    high: {
        title: 'High Chance - Hot Lead',
        description: 'Act Now. The prospect has shown high interest or engagement.',
    },
    medium: {
        title: 'Moderate Chance - Warm Lead',
        description: 'The prospect is interested but may need further nurturing.',
    },
    low: {
        title: 'Low Chance - Cold Lead',
        description: 'The prospect has engaged minimally and will likely require significant follow-up.',
    },
};
