import { IconProps } from 'types';

const Clock = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>
        <circle cx="7.5" cy="7.5" r="5.625" stroke="#CDD0D4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 4.375V7.5L9.375 9.375" stroke="#CDD0D4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Clock;
