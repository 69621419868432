import styled from 'styled-components';
import { GoogleAddressLookup } from 'react-rainbow-components';
import { DIVIDER } from '../../constants';

export const StyledGoogleAddressLookup = styled(GoogleAddressLookup)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    label + div {
        border-radius: 0.375rem;
    }

    label + div[role=combobox] input,
    label + div > div {
        border: 1px solid ${DIVIDER};
        border-radius: 0.375rem;
    }

    label + div[role=combobox] input,
    input {
        font-weight: 300 !important;

        ::placeholder {
            font-weight: 300 !important;
        }
    }
    
    input + span {
        width: 0;
        border-radius: 0.375rem;
        border: none;
    }

    input + span > span {
        min-width: 0;
        flex-basis: 99%;
    }
    
    input + span > span > span{
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        white-space: nowrap;
    }
`;
