import { useState } from 'react';
import { orderBy, query, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Footer from 'components/Footer';
import SearchIcon from 'components/icons/search';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import { Column } from 'react-rainbow-components';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import DateTimeColumn from 'components/DateTimeColumn';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useCollectionWithPagination from 'data/firestore/agent/blockednumber/useCollectionWithPagination';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { EntityGet } from 'data/firestore/types';
import { Blockednumber } from 'data/firestore/agent/blockednumber/types';
import validatePhoneNumber from 'data/services/phone/isValid';
import UserColumn from './columns/user';
import CarrierColumn from './columns/carrier';
import LineTypeColumn from './columns/lineType';
import AddedByColumn from './columns/addedBy';
import ActionsColumn from './columns/actions';
import {
    Container,
    StyledCard,
    SearchContainer,
    SearchInput,
    PaginationContainer,
    EntriesText,
    TableContainer,
} from './styled';
import Fields from './blockNumberForm';
import { ALGOLIA_INDEX_BLOCKED_NUMBER, PAGE_SIZE } from '../../constants';
import getAlgoliaFilters from './helpers/getAlgoliaFilters';

const BlockedNumbers = () => {
    const { agentId = '' } = useParams<{ agentId: string }>();
    const connectedModalProps = useConnectModal('add-edit-book');
    const [openModal, closeModal] = useOpenModal('add-edit-book');
    const { mutateAsync: block } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/blocked-numbers`,
        onSuccess: closeModal,
    });

    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);
    const {
        data: blockedNumbers, isLoading, nextPage, prevPage, totalRecords,
    } = useCollectionWithPagination(agentId as string, {
        listQueryFn: (ref) => query(ref, where('status', '==', 'active'), orderBy('createdAt', 'desc')),
    });
    const algoliaData = useAlgoliaSearchData<EntityGet<Blockednumber>>({
        search,
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_BLOCKED_NUMBER,
        filters: getAlgoliaFilters({ agentId }),
        enabled: Boolean(search),
    });

    const blockNumber = () => {
        openModal({
            title: '',
            onSubmit: async ({ phoneNumber }: { phoneNumber: string }) => {
                if (!validatePhoneNumber(phoneNumber)) {
                    // TODO: what to do?
                    showAppNotification({
                        title: 'Error',
                        description: 'Phone number is not valid.',
                        icon: 'error',
                        timeout: 5000,
                    });
                    return;
                }
                showAppSpinner();
                try {
                    await block({
                        body: {
                            phoneNumber,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        });
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoading) return;
        if (page === activePage) return;
        if (!algoliaData?.hits) {
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
        }
        setActivePage(page);
    };

    const dataToShow = algoliaData?.hits || blockedNumbers;
    const totalHits = totalRecords;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    return (
        <>
            <Container>
                <StyledCard>
                    <SearchContainer>
                        <SearchInput
                            type="search"
                            placeholder="Search phone number..."
                            variant="bare"
                            icon={<SearchIcon />}
                            borderRadius="semi-square"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                        <Button variant="brand" size="medium" borderRadius="semi-square" onClick={blockNumber}>
                            <Plus className="rainbow-m-right_x-small" />
                            Add a Number
                        </Button>
                    </SearchContainer>
                    <TableContainer>
                        <Table
                            keyField="id"
                            data={dataToShow}
                            isLoading={isLoading}
                        >
                            <Column header="User" field="customer" component={UserColumn} />
                            <Column header="Carrier" field="twilioLookup" component={CarrierColumn} />
                            <Column header="Line Type" field="twilioLookup" component={LineTypeColumn} />
                            <Column header="Added By" field="addedBy" component={AddedByColumn} />
                            <Column header="Date" field="updatedAt" component={DateTimeColumn} />
                            <Column header="Actions" component={ActionsColumn} defaultWidth={110} />
                        </Table>

                        <PaginationContainer>
                            <EntriesText>
                                Showing
                                {' '}
                                {firstItemOfActivePage}
                                {' '}
                                to
                                {' '}
                                {lastItemOfActivePage}
                                {' '}
                                of
                                {' '}
                                {totalHits}
                                {' '}
                                entries.
                            </EntriesText>
                            <Pagination
                                activePage={activePage}
                                pages={pages}
                                onChange={handlePageChange}
                            />
                        </PaginationContainer>
                    </TableContainer>
                </StyledCard>
                <Footer />
            </Container>
            <UniversalFormModal fields={Fields} borderRadius="semi-square" submitButtonLabel="Add to Block List" {...connectedModalProps} />
        </>
    );
};

export default BlockedNumbers;
