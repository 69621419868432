import styled, { css } from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import SaraAvatar from 'components/icons/saraAvatar';
import { Spinner } from 'react-rainbow-components';
import { GREY_800, GREY_300 } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const MessagesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const StyledAvatar = styled(UserAvatar)`
    width: 38px;
    height: 38px;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
`;

export const MessageContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
`;

export const MessageRole = styled.p`
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${GREY_800};
`;

export const MessageContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
`;

export const MessageContent = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
`;

export const LoadingBarsContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    gap: 15px;
    margin: 3px 0px 0px 12px;
`;

export const SpinnerContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    align-content: center;
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    transform: unset;
    flex-shrink: 0;
    flex-grow: 0;
    width: 1.875rem;
    height: 1.875rem;
`;

export const SpinnerMessage = styled.div`
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    flex: 1;
    color: ${GREY_300};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.9375rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.375rem; /* 146.667% */
`;

export const StyledSaraAvatar = styled(SaraAvatar)`
    width: 42px;
    height: fit-content;
    margin-right: 12px;
    flex-shrink: 0;
`;

export const SectionHeader = styled.h2`
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${GREY_800};
    padding-top: 0.5rem;
`;

export const HighlightedText = styled.span<{ type?: string; disableHighlight: boolean }>`
    padding: 0rem 0.25rem;
    border-radius: 0.25rem;
    
    ${(props) => !props.disableHighlight && css`
        background: #FCEDDD;
    `}
`;
