import { useMemo } from 'react';
import { Paginator, Message, JSONObject } from '@twilio/conversations';
import * as linkify from 'linkifyjs';
import { ItemUser } from 'data/firestore/globals';
import isSameDay from '../helpers/isSameDay';
import getMessageStatus from '../helpers/getMessageStatus';
import { Message as ItemMessage, UrlPreview } from '../../types';
import resolveUserFromAuthor from '../helpers/resolveUserFromAuthor';

const useGroupMessages = ({
    messages,
    participants = {},
}: {
    messages?: Paginator<Message>;
    participants?: Record<string, ItemUser>;
}) => useMemo(
    () => messages?.items.map((item, index) => {
        const {
            author,
            sid,
            body,
            dateCreated,
            type,
            attachedMedia,
            aggregatedDeliveryReceipt,
        } = item;

        let containsUrl = false;
        if (body) {
            containsUrl = linkify.find(body as string).length > 0;
        }

        const user = resolveUserFromAuthor({ participants, author });

        const newItem: ItemMessage = {
            sid,
            body,
            dateCreated,
            type,
            attachedMedia,
            direction: /^\+\d+$/.test(author as string) ? 'incoming' : 'outgoing',
            showGroupDate: false,
            twilioMessage: item,
            user,
        };
        if (!isSameDay(item.dateCreated, messages?.items?.[index - 1]?.dateCreated)) {
            newItem.showGroupDate = true;
        }
        if (containsUrl) {
            const { attributes } = item;
            if (attributes) {
                const { urlPreviewMetadata } = attributes as JSONObject;
                if (urlPreviewMetadata) {
                    const {
                        title, description, image, error,
                    } = urlPreviewMetadata as UrlPreview & JSONObject;
                    if (!error) {
                        newItem.urlPreviewMetadata = {
                            title,
                            description,
                            image,
                        };
                    } else {
                        newItem.urlPreviewMetadata = {
                            error: true,
                            title: '',
                            description: '',
                            image: '',
                        };
                    }
                }
            }
        }
        if (aggregatedDeliveryReceipt) {
            newItem.status = getMessageStatus(aggregatedDeliveryReceipt);
        }
        return newItem;
    }),
    [messages, participants],
);

export default useGroupMessages;
