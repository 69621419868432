import Trash from 'components/icons/trash';
import {
    ModalContainer, ModalDescription, ModalIconContainer, ModalTitle,
} from './styled';

const DeleteModal = ({ type, value }: {
    type: string;
    value: string;
}) => (
    <ModalContainer>
        <ModalIconContainer>
            <Trash />
        </ModalIconContainer>
        <ModalTitle>Are you sure you want to delete this?</ModalTitle>
        <ModalDescription>
            You have requested to remove the
            {' '}
            {type}
            {' '}
            {`"${value}".`}
            {' '}
            Once this action is confirmed, it cannot be undone.
            {' '}
            Ensure that all issues have been addressed before proceeding.
        </ModalDescription>
    </ModalContainer>
);

export default DeleteModal;
