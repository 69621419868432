import Checks from 'components/icons/checks';
import {
    ModalContainer,
    ModalDescription,
    ModalIconContainer,
    ModalTitle,
    ModalBody,
    NoteInput,
} from '../styled';

interface Params {
    onNoteChanged?: (value: string) => void;
}

const ResolveModal = ({
    onNoteChanged = () => {},
}:Params) => (
    <ModalContainer>
        <ModalIconContainer>
            <Checks />
        </ModalIconContainer>
        <ModalTitle>Ready to Mark as Solved?</ModalTitle>
        <ModalBody>
            <ModalDescription>
                Once you confirm, this task will be marked as complete.
                Any last thoughts? Jot them down in the note area.
            </ModalDescription>
            <NoteInput
                label="Leave a Note (Optional)"
                labelAlignment="left"
                placeholder="Add your notes here..."
                rows={1}
                grow
                onChange={(event) => onNoteChanged(event.target.value)}
            />
        </ModalBody>
    </ModalContainer>
);

export default ResolveModal;
