import { IconProps } from 'types';

const RotateCounterClockwise = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
        width={12}
        height={12}
        fill="none"
    >
        <title>{title}</title>
        <path
            d="M2.50437 6.47539C2.46848 6.20159 2.21743 6.00872 1.94363 6.04461C1.66983 6.0805 1.47696 6.33156 1.51285 6.60536L2.50437 6.47539ZM1.7941 4.35536C1.69192 4.6119 1.81706 4.9027 2.0736 5.00488C2.33014 5.10706 2.62094 4.98193 2.72312 4.72539L1.7941 4.35536ZM2.50867 2.04034C2.50867 1.7642 2.28481 1.54034 2.00867 1.54034C1.73253 1.54034 1.50867 1.7642 1.50867 2.04034L2.50867 2.04034ZM2.00867 4.54034L1.50867 4.54034C1.50867 4.67295 1.56135 4.80013 1.65511 4.8939C1.74888 4.98766 1.87606 5.04034 2.00867 5.04034L2.00867 4.54034ZM4.50867 5.04034C4.78481 5.04034 5.00867 4.81649 5.00867 4.54034C5.00867 4.2642 4.78481 4.04034 4.50867 4.04034L4.50867 5.04034ZM1.51285 6.60536C1.80714 8.85048 3.72382 10.5273 5.98814 10.5205L5.98515 9.52049C4.22401 9.52576 2.73326 8.2216 2.50437 6.47539L1.51285 6.60536ZM5.98814 10.5205C8.25246 10.5137 10.1591 8.82551 10.4399 6.57866L9.44766 6.45463C9.22922 8.20217 7.74629 9.51523 5.98515 9.52049L5.98814 10.5205ZM10.4399 6.57866C10.7208 4.33182 9.2884 2.22623 7.09542 1.6623L6.84637 2.63079C8.55202 3.06941 9.6661 4.70708 9.44766 6.45463L10.4399 6.57866ZM7.09542 1.6623C4.90243 1.09837 2.63198 2.25175 1.7941 4.35536L2.72312 4.72539C3.37481 3.08925 5.14071 2.19218 6.84637 2.63079L7.09542 1.6623ZM1.50867 2.04034L1.50867 4.54034L2.50867 4.54034L2.50867 2.04034L1.50867 2.04034ZM2.00867 5.04034L4.50867 5.04034L4.50867 4.04034L2.00867 4.04034L2.00867 5.04034Z"
            fill="currentColor"
        />
    </svg>
);
export default RotateCounterClockwise;
