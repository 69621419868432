import { IconProps } from 'types';

const Book = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        width={24}
        height={24}
    >
        <title>{title}</title>
        <path d="M3 19C5.78461 17.3923 9.21539 17.3923 12 19C14.7846 17.3923 18.2154 17.3923 21 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 5.99996C5.78461 4.39227 9.21539 4.39227 12 5.99996C14.7846 4.39227 18.2154 4.39227 21 5.99996" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 6V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 6V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21 6V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Book;
