import { useState } from 'react';
import SearchIcon from 'components/icons/search';
import Button from 'components/Button';
import { useOpenModal } from '@rainbow-modules/hooks';
import Plus from 'components/icons/plus';
import { StyledCard } from '../styled';
import {
    FormHeaderTitle, SearchContainer, SearchInput, FormHeader, FormHeaderDescription,
} from './styled';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../../constants';
import RespondersList from './respondersList';

export const NewResponderFormHeader = () => (
    <FormHeader>
        <FormHeaderTitle>Create New AI Persona</FormHeaderTitle>
        <FormHeaderDescription>
            Set up a unique AI persona by naming it,
            providing a brief description, and defining its skill type.
        </FormHeaderDescription>
    </FormHeader>
);

const LLMRespondersListPage = () => {
    const [search, setSearch] = useState('');
    const [openModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);

    return (
        <StyledCard>
            <SearchContainer>
                <SearchInput
                    type="search"
                    placeholder="Search responder..."
                    variant="bare"
                    icon={<SearchIcon />}
                    borderRadius="semi-rounded"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <Button
                    variant="brand"
                    size="medium"
                    borderRadius="semi-rounded"
                    onClick={() => openModal({ title: <NewResponderFormHeader /> })}
                >
                    <Plus className="rainbow-m-right_small" />
                    New AI Persona
                </Button>
            </SearchContainer>
            <RespondersList search={search} />
        </StyledCard>
    );
};

export default LLMRespondersListPage;
