import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import {
    Field, composeValidators, isEmail, isRequired,
} from '@rainbow-modules/forms';
import { useConnectModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import FormModal from 'components/FormModal';
import Input from 'components/Input';
import MailClosed from 'components/icons/mailClosed';
import PhoneAddIcon from 'components/icons/phoneAdd';
import PhoneInput from 'components/PhoneInput';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useSubscriptions from 'data/firestore/agent/subscriptions/useCollection';
import { Subscriptions } from 'data/firestore/agent/subscriptions/types';
import remove from 'data/firestore/agent/subscriptions/remove';
import validatePhoneNumber from 'data/services/phone/isValid';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import useUserRoles from 'hooks/useUserRoles';
import Footer from 'components/Footer';
import useAddNotificationOption, { ADD_NOTIFICATION_OPTION_FORM_MODAL } from './hooks/useAddNotification';
import {
    Container, Column, FieldContainer, PageContainer,
} from './styled';
import NotificationSection from './Section';
import DeleteModal from './deleteModal';

const validatePhone = (value: Record<string, string> = {}) => {
    if (!validatePhoneNumber(`${value?.countryCode}${value?.phone}`)) return 'Please enter a valid phone number.';
    return undefined;
};

const PhoneField = () => (
    <FieldContainer>
        <Field
            name="phoneNumber"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={PhoneInput}
            label="Phone Number"
            required
            placeholder="(415) 111-1111"
            borderRadius="semi-square"
            labelAlignment="left"
            validate={validatePhone}
        />
    </FieldContainer>
);

const EmailField = () => (
    <FieldContainer>
        <Field
            name="emailAddress"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Input}
            label="Email Address"
            icon={<MailClosed />}
            required
            placeholder="john@gmail.com"
            borderRadius="semi-square"
            labelAlignment="left"
            validate={composeValidators(
                isEmail('Please enter a valid email address.'),
                isRequired('Please enter a valid email address.'),
            )}
        />
    </FieldContainer>
);

const NotificationSettings = () => {
    const { agentId = '' } = useParams();
    const connectedModalProps = useConnectModal(ADD_NOTIFICATION_OPTION_FORM_MODAL);
    const [openModal, closeModal] = useAddNotificationOption();
    const { role, isAdmin } = useUserRoles();

    const userCanWrite = useMemo(() => isAdmin || ['owner', 'editor'].includes(role as string), [role, isAdmin]);

    const { data: subscriptions, isLoading } = useSubscriptions(
        agentId,
        {
            disabled: !agentId,
            track: [agentId],
        },
    );

    const [
        smsSubscriptions,
        emailSubscriptions,
    ] = useMemo(
        () => subscriptions.reduce(
            (result: Subscriptions[][], subscription) => {
                const index = (subscription.type === 'sms' ? 0 : 1);
                result[index].push({ ...subscription });
                return result;
            },
            [[], []],
        ),
        [subscriptions],
    );

    const { mutateAsync: addNotification } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/notifications`,
        onSuccess: closeModal,
    });

    const addNewEmail = useCallback(
        () => {
            if (userCanWrite) {
                openModal({
                    title: 'Add Email Address',
                    subtitle: 'Enter an email address to receive call notifications.',
                    submitButtonLabel: 'Add Email',
                    fields: EmailField,
                    onSubmit: async (values: any) => {
                        const { emailAddress: to } = values;
                        let notification = {
                            title: 'Email Added Successfully',
                            description: `${to} has been added for receive Email Notifications.`,
                            icon: 'success',
                            timeout: 5000,
                        };
                        showAppSpinner();
                        try {
                            await addNotification({
                                body: {
                                    type: 'email',
                                    to,
                                },
                            });
                        } catch (error) {
                            notification = {
                                ...notification,
                                title: 'Error',
                                description: `Unable to add ${to}.`,
                                icon: 'error',
                            };
                        }
                        hideAppSpinner();
                        showAppNotification(notification);
                    },
                });
            }
        },
        [addNotification, openModal, userCanWrite],
    );

    const addNewPhone = useCallback(
        () => {
            if (userCanWrite) {
                openModal({
                    title: 'Add SMS Notification Number',
                    subtitle: 'Enter a phone number to receive SMS alerts after calls.',
                    submitButtonLabel: 'Add Number',
                    fields: PhoneField,
                    onSubmit: async (values: any) => {
                        const to = `${values.phoneNumber?.countryCode as string}${values.phoneNumber?.phone}`;
                        let notification = {
                            title: 'Phone Added Successfully',
                            description: `${formatIntlPhoneNumber(to)} has been added for receive SMS Notifications.`,
                            icon: 'success',
                            timeout: 5000,
                        };
                        showAppSpinner();
                        try {
                            await addNotification({
                                body: {
                                    type: 'sms',
                                    to,
                                },
                            });
                        } catch (error) {
                            notification = {
                                ...notification,
                                title: 'Error',
                                description: `Unable to add ${formatIntlPhoneNumber(to)}.`,
                                icon: 'error',
                            };
                        }
                        hideAppSpinner();
                        showAppNotification(notification);
                    },
                });
            }
        },
        [addNotification, openModal, userCanWrite],
    );

    const deleteNotification = useCallback(
        async (option: Subscriptions) => {
            if (!userCanWrite) return;
            const valueType = (option.type === 'sms' ? 'phone number' : 'email address');
            const optionValue = (option.type === 'sms' ? formatIntlPhoneNumber(option.to) : option.to);
            if (await confirmModal({
                header: '',
                question: <DeleteModal type={valueType} value={optionValue} />,
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            })) {
                let notification = {
                    title: (
                        option.type === 'sms'
                            ? 'Phone Removed Successfully'
                            : 'Email Removed Successfully'
                    ),
                    description: (
                        option.type === 'sms'
                            ? `${formatIntlPhoneNumber(option.to)} has been removed and will no longer receive SMS Notifications.`
                            : `${option.to} has been removed and will longer receive Email Notifications.`
                    ),
                    icon: 'success',
                    timeout: 5000,
                };
                showAppSpinner();
                try {
                    await remove(agentId, option.id);
                } catch (error) {
                    notification = {
                        ...notification,
                        title: 'Error',
                        description: (
                            option.type === 'sms'
                                ? `Unable to remove ${formatIntlPhoneNumber(option.to)}.`
                                : `Unable to remove ${option.to}.`
                        ),
                        icon: 'error',
                    };
                }
                hideAppSpinner();
                showAppNotification(notification);
            }
        },
        [agentId, userCanWrite],
    );

    return (
        <PageContainer>
            <Container>
                <Column>
                    <NotificationSection
                        readOnly={!userCanWrite}
                        isLoading={isLoading}
                        title="Email Notifications"
                        subtitle="Configure email alerts to get notified on chosen addresses immediately when calls end."
                        emptyMessage="There is no email address to receive notification yet."
                        notifications={emailSubscriptions}
                        onAddClick={addNewEmail}
                        onRemoveItem={deleteNotification}
                    />
                </Column>
                <Column>
                    <NotificationSection
                        readOnly={!userCanWrite}
                        isLoading={isLoading}
                        title="SMS Notifications"
                        subtitle="Configure SMS alerts to get notified on selected phone numbers immediately when calls end."
                        actionIcon={<PhoneAddIcon />}
                        emptyMessage="There is no phone number to receive notification yet."
                        notifications={smsSubscriptions}
                        onAddClick={addNewPhone}
                        onRemoveItem={deleteNotification}
                    />
                </Column>
            </Container>
            <Footer />
            <RenderIf isTrue={userCanWrite}>
                <FormModal
                    {...connectedModalProps}
                    onRequestClose={closeModal}
                />
            </RenderIf>
        </PageContainer>
    );
};

export default NotificationSettings;
