import getHeightOfDate from 'components/CalendarDayView/helpers/getHeightOfDate';
import getHeightOfMinutes from 'components/CalendarDayView/helpers/getHeightOfMinutes';
import { useMemo } from 'react';

export default function useEventStyle({
    startDate,
    duration,
    timeZone,
}: {
    startDate: Date;
    duration: number;
    timeZone: string;
}) {
    return useMemo(
        () => ({
            height: `${getHeightOfMinutes(duration) - 1}px`,
            top: `${getHeightOfDate(startDate, timeZone) + 1}px`,
        }),
        [startDate, duration, timeZone],
    );
}
