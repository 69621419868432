import Picklist from 'components/Picklist';
import styled from 'styled-components';
import { GREY_500, PURPLE_500 } from '../../../constants';

export const StyledPicklist = styled(Picklist)`
    width: 250px;

    input {
        border-radius: 6.25rem;
    }
`;

export const LabelsContainer = styled.div`
    position: absolute;
    top: 0;
    left:0;
    display: flex;
    width: 100%;
    height: 100px;
    padding: 0 20px;
`;

export const Label = styled.div`
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    padding: 0.5rem 0 0 1rem;
    pointer-events: none;
    user-select: none;
`;

export const Total = styled.div`
    font-size: 1.375rem;
    color: ${GREY_500};
`;

export const StageName = styled.div`
    font-size: 0.875rem;
    color: ${PURPLE_500};
    text-transform: capitalize;
`;

export const Percent = styled.div`
    font-size: 1.125rem;
    color: ${GREY_500};
`;
