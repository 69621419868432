import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import { BarsContainer, Container } from './styled';

interface Params {
    style?: object;
    className?: string;
    hideAvatar?: boolean;
}

const MessageLoadingShape = ({ hideAvatar = false, ...rest }: Params) => (
    <Container {...rest}>
        <RenderIf isTrue={!hideAvatar}>
            <div>
                <LoadingShape shape="circle" />
            </div>
        </RenderIf>
        <BarsContainer>
            <LoadingShape shape="rounded-rect" width="6.25rem" height="0.5625rem" />
            <LoadingShape shape="rounded-rect" width="13.25rem" height="0.5625rem" />
        </BarsContainer>
    </Container>
);

export default MessageLoadingShape;
