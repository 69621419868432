import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import useCustomerPanel from 'hooks/useCustomerPanel';
import { useCallback, useMemo } from 'react';

interface Props {
    isLoading?: boolean;
    info?: Record<string, unknown>;
}

const CustomerInfo = ({
    info,
    isLoading = false,
}: Props) => {
    const {
        id = '', firstName = '', lastName = '', alias = '', phoneNumber, photoURL,
    } = info || {};
    const { openCustomerPanel } = useCustomerPanel();

    const displayName = useMemo(
        () => getDisplayName({ firstName, lastName, alias }),
        [firstName, lastName, alias],
    );

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const handleCustomerPanelClick = useCallback(
        () => openCustomerPanel({ data: info }),
        [info, openCustomerPanel],
    );

    return (
        <ListTile
            isLoading={isLoading}
            icon={(
                <UserAvatar
                    initials={initials}
                    value={id}
                    src={photoURL}
                />
            )}
            title={displayName}
            subtitle={formatIntlPhoneNumber(phoneNumber as string)}
            onClick={handleCustomerPanelClick}
        />
    );
};

export default CustomerInfo;
