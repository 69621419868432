import { DateTime } from 'luxon';

const isSameDay = (date1?: Date | null, date2?: Date | null) => {
    try {
        if (date1 && date2) {
            const dateA = DateTime.fromJSDate(date1).startOf('day');
            const dateB = DateTime.fromJSDate(date2).startOf('day');
            return dateA.toMillis() === dateB.toMillis();
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return false;
};

export default isSameDay;
