import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Option, RenderIf } from 'react-rainbow-components';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import Picklist from 'components/Picklist';
import useResponders from 'data/firestore/agent/llm/useCollection';
import useUpdateAgentMutation from 'data/hooks/useUpdateAgentMutation';
import { Agent } from 'data/firestore/agent/types';
import useAgentData from 'hooks/useAgentData';
import CreateEditResponderForm from 'pages/LLMResponders/CreateResponderForm';
import {
    Column, Row,
    Container, Header, ListHeader, MetaAccountsContainer, Title,
    AccountNameContainer,
    FacebookIcon,
    InstagramIcon,
} from './styled';
import { ADD_EDIT_LLM_RESPONDER_MODAL } from '../../../constants';
import UrlColumn from './columns/url';

type FormOpenMode = 'create';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Meta = () => {
    const { agentId = '' } = useParams<{ agentId?: string }>();
    const navigate = useNavigate();
    const { agent, isLoading: isLodingAgent } = useAgentData();
    const { data: responders, isLoading: isLoadingResponders } = useResponders(agentId);
    const {
        mutateAsync: updateAgent, isLoading: isUpdatingAgent,
    } = useUpdateAgentMutation(agentId);

    const [selectedFacebookResponder, setSelectedFacebookResponder] = useState<any>(null);
    const [selectedInstagramResponder, setSelectedInstagramResponder] = useState<any>(null);

    const connectedAddModifyProps = useConnectModal(
        ADD_EDIT_LLM_RESPONDER_MODAL,
        { size: 'small' },
    );
    const [openModal, closeModal] = useOpenModal(ADD_EDIT_LLM_RESPONDER_MODAL);
    const { mode, responderData = {}, ...modalProps } = connectedAddModifyProps;
    const createResponderForRef = useRef<'facebook' | 'instagram' | null>(null);

    useEffect(() => {
        if (!agent || !responders || responders.length === 0) return;

        const facebookResponder = responders.find(
            (responder) => responder.id === agent.socialMedia?.facebookResponderId,
        );
        const instagramResponder = responders.find(
            (responder) => responder.id === agent.socialMedia?.instagramResponderId,
        );
        setSelectedFacebookResponder({
            name: facebookResponder?.name,
            label: facebookResponder?.name,
            value: facebookResponder?.id,
        });
        setSelectedInstagramResponder({
            name: instagramResponder?.name,
            label: instagramResponder?.name,
            value: instagramResponder?.id,
        });
    }, [agent, responders]);

    const updateSocialMedia = async (value: Agent['socialMedia']) => {
        showAppSpinner();
        try {
            await updateAgent({
                body: {
                    socialMedia: value,
                },
            });
            showAppNotification({
                icon: 'success',
                title: 'Agent updated',
                description: 'Social media responders were updated.',
                timeout: 5000,
            });
        } catch (error) {
            showAppNotification({
                icon: 'error',
                title: 'Error',
                description: 'Something went wrong. Please try again.',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    const handleFacebookResponderChange = async (value: PicklistValue) => {
        if (value.name === 'new') {
            createResponderForRef.current = 'facebook';
            openModal({ mode: 'create' });
            return;
        }
        setSelectedFacebookResponder(value);
        await updateSocialMedia({
            facebookResponderId: value.value,
        });
    };

    const handleInstagramResponderChange = async (value: PicklistValue) => {
        if (value.name === 'new') {
            createResponderForRef.current = 'instagram';
            openModal({ mode: 'create' });
            return;
        }
        setSelectedInstagramResponder(value);
        await updateSocialMedia({
            instagramResponderId: value.value,
        });
    };

    const handleResponderCreated = async (responder: any) => {
        switch (createResponderForRef.current) {
            case 'facebook':
                await updateSocialMedia({
                    facebookResponderId: responder.id,
                });
                break;
            case 'instagram':
                await updateSocialMedia({
                    instagramResponderId: responder.id,
                });
                break;
            default:
                break;
        }
        createResponderForRef.current = null;
        navigate(`/${agentId}/llms/${responder.id}`);
    };

    const isLoading = isLodingAgent || isLoadingResponders;
    return (
        <>
            <Container>
                <Header>
                    <Title>Connect Meta Accounts</Title>
                </Header>
                <MetaAccountsContainer>
                    <ListHeader>
                        <Column>Account</Column>
                        <Column>Endpoint URL</Column>
                        <Column>Responder</Column>
                    </ListHeader>
                    <Row>
                        <Column>
                            <AccountNameContainer>
                                <FacebookIcon />
                                Facebook
                            </AccountNameContainer>
                        </Column>
                        <Column>
                            <RenderIf isTrue={selectedFacebookResponder}>
                                <UrlColumn value={`${BASE_URL}/agents/${agentId}/social/facebook`} />
                            </RenderIf>
                        </Column>
                        <Column>
                            <Picklist
                                placeholder="Click to select Responder"
                                size="small"
                                isLoading={isLoading}
                                disabled={isUpdatingAgent}
                                value={selectedFacebookResponder}
                                onChange={handleFacebookResponderChange}
                            >
                                <Option name="new" label="Create a Responder" />
                                <Option variant="header" label="Your responders" />
                                {responders?.map((responder) => (
                                    <Option
                                        key={responder.id}
                                        name={responder.id}
                                        label={responder.name}
                                        value={responder.id}
                                    />
                                ))}
                            </Picklist>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <AccountNameContainer>
                                <InstagramIcon />
                                Instagram Account
                            </AccountNameContainer>
                        </Column>
                        <Column>
                            <RenderIf isTrue={selectedInstagramResponder}>
                                <UrlColumn value={`${BASE_URL}/agents/${agentId}/social/instagram`} />
                            </RenderIf>
                        </Column>
                        <Column>
                            <Picklist
                                placeholder="Click to select Responder"
                                size="small"
                                isLoading={isLoading}
                                disabled={isUpdatingAgent}
                                value={selectedInstagramResponder}
                                onChange={handleInstagramResponderChange}
                            >
                                <Option name="new" label="Create a Responder" />
                                <Option variant="header" label="Your responders" />
                                {responders?.map((responder) => (
                                    <Option
                                        key={responder.id}
                                        name={responder.id}
                                        label={responder.name}
                                        value={responder.id}
                                    />
                                ))}
                            </Picklist>
                        </Column>
                    </Row>
                </MetaAccountsContainer>
            </Container>
            <Modal {...modalProps}>
                <CreateEditResponderForm
                    mode={mode as FormOpenMode}
                    agentId={agentId}
                    onRequestClose={closeModal}
                    onSucess={handleResponderCreated}
                    {...(responderData as object)}
                />
            </Modal>
        </>
    );
};

export default Meta;
