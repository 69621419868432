import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { EntityGet } from 'data/firestore/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { RED_500, AMERICAN_YELLOW, GREEN_500 } from '../../constants';
import {
    StagePickerContainer, StyledPicklist, StyledOption, StyledSpinner,
    OpportunityStageLabel,
} from './styled';

interface Props {
    pipelineStages: EntityGet<Stage>[];
    value?: string;
    onUpdate?: (value: string) => Promise<boolean> | boolean;
    readOnly?: boolean;
}

const StagePicker = ({
    value: valueInProps,
    readOnly = false,
    pipelineStages = [],
    onUpdate = () => true,
}: Props) => {
    const [value, setValue] = useState<PicklistValue>();
    const [isLoading, setLoading] = useState(false);

    const pickListvalue = useMemo(
        () => {
            if (value) return value;
            if (!isLoading) {
                if (valueInProps) {
                    const found = pipelineStages.find((stage) => stage.id === valueInProps);
                    if (found) {
                        const {
                            id: stageId,
                            name: stageName,
                            description: stageDescription,
                        } = found;
                        return {
                            label: stageName,
                            name: stageId,
                            value: found,
                            searchableText: `${stageName} - ${stageDescription}`,
                        };
                    }
                }
            }
            return undefined;
        },
        [isLoading, pipelineStages, valueInProps, value],
    );

    const pickListOptions = useMemo(
        () => pipelineStages.map(
            (stage) => (
                <StyledOption
                    key={`pipeline-stage__${stage.id}`}
                    label={stage.name}
                    name={stage.id}
                    value={stage}
                    searchableText={`${stage.name} - ${stage.description}`}
                    isBuiltIn={!stage.removable}
                />
            ),
        ),
        [pipelineStages],
    );

    const color = useMemo(
        () => {
            const { type = 'open' } = pickListvalue?.value || {};
            if (type === 'won') return GREEN_500;
            if (type === 'lost') return RED_500;
            return AMERICAN_YELLOW;
        },
        [pickListvalue],
    );

    const handleOnChange = useCallback(
        async (newValue: PicklistValue) => {
            setValue(newValue);
            setLoading(true);
            const updated = await onUpdate(newValue.value.id);
            if (!updated) {
                setValue(undefined);
            }
            setLoading(false);
        },
        [onUpdate],
    );

    useEffect(
        () => setValue(undefined),
        [valueInProps],
    );

    if (readOnly) {
        return (
            <OpportunityStageLabel isBuiltIn={!pickListvalue?.value?.removable}>
                {pickListvalue?.label}
            </OpportunityStageLabel>
        );
    }

    return (
        <StagePickerContainer>
            <StyledPicklist
                id="select-stage"
                label="Select Stage"
                size="small"
                onChange={handleOnChange}
                hideLabel
                value={pickListvalue}
                isLoading={isLoading}
                disabled={isLoading}
                color={color}
            >
                {pickListOptions}
            </StyledPicklist>
            <RenderIf isTrue={isLoading}>
                <StyledSpinner variant="base" type="arc" size="xx-small" />
            </RenderIf>
        </StagePickerContainer>
    );
};

export default StagePicker;
