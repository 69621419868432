import { IconProps } from 'types';

const BellRingingIcon = ({ className, title }: IconProps) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title}</title>
        <path d="M6.66699 3.33333C6.66699 2.59695 7.26395 2 8.00033 2C8.7367 2 9.33366 2.59695 9.33366 3.33333C10.8938 4.07104 11.9194 5.6095 12.0003 7.33333V9.33333C12.1021 10.1739 12.5969 10.9161 13.3337 11.3333H2.66699C3.40373 10.9161 3.89858 10.1739 4.00033 9.33333V7.33333C4.08124 5.6095 5.10688 4.07104 6.66699 3.33333" stroke="#4B465C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.66699 3.33333C6.66699 2.59695 7.26395 2 8.00033 2C8.7367 2 9.33366 2.59695 9.33366 3.33333C10.8938 4.07104 11.9194 5.6095 12.0003 7.33333V9.33333C12.1021 10.1739 12.5969 10.9161 13.3337 11.3333H2.66699C3.40373 10.9161 3.89858 10.1739 4.00033 9.33333V7.33333C4.08124 5.6095 5.10688 4.07104 6.66699 3.33333" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 11.332V11.9987C6 13.1033 6.89543 13.9987 8 13.9987C9.10457 13.9987 10 13.1033 10 11.9987V11.332" stroke="#4B465C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 11.332V11.9987C6 13.1033 6.89543 13.9987 8 13.9987C9.10457 13.9987 10 13.1033 10 11.9987V11.332" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.0004 4.48467C13.5631 3.5335 12.9281 2.68646 12.1377 2" stroke="#4B465C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.0004 4.48467C13.5631 3.5335 12.9281 2.68646 12.1377 2" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 4.48467C2.4369 3.53363 3.07143 2.6866 3.86133 2" stroke="#4B465C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 4.48467C2.4369 3.53363 3.07143 2.6866 3.86133 2" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />

    </svg>
);
export default BellRingingIcon;
