import { ChangeEvent, useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import RadioGroup, { RadioGroupProps } from 'react-rainbow-components/components/RadioGroup';
import { RadioOption } from 'react-rainbow-components/components/types';

interface RadioGroupValue<T> extends Omit<RadioOption, 'value'> {
    name: string;
    value?: T;
}

export interface RedioGroupWithCustomValueProps<T = any> extends Omit<RadioGroupProps, 'value' | 'onChange' | 'options'> {
    value?: RadioGroupValue<T>;
    onChange?: (value: RadioGroupValue<T>) => void;
    options: RadioGroupValue<T>[];
}

const RedioGroupWithCustomValue = (props: RedioGroupWithCustomValueProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        options,
        ...rest
    } = useReduxForm(props);

    const radioGroupValue = useMemo(() => {
        if (valueInProps) {
            const found = options.find((option) => option.name === valueInProps.name);
            if (found) {
                return found.name;
            }
        }
        return undefined;
    }, [options, valueInProps]);

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = options.find((option) => option.name === event.target.value);
            if (newValue) onChange(newValue);
        },
        [onChange, options],
    );

    const radioGroupOptions = useMemo(
        () => options.map(({ label, name }) => ({
            label,
            value: name,
        })),
        [options],
    );

    return (
        <RadioGroup
            {...rest}
            options={radioGroupOptions}
            value={radioGroupValue}
            onChange={handleOnChange}
        />
    );
};

export default RedioGroupWithCustomValue;
