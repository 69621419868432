import { useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import ZoomInIcon from 'components/icons/zoomIn';
import ListTile from 'components/ListTile';
import { formatBytes } from 'data/services/files/formatter';
import {
    CellWrapper, FilePreviewContainer, IconContainer,
    StyledZoomableImage,
} from './styled';
import checkIsImage from '../../helpers/checkIsImage';

interface FilePreviewColumnProps {
    value?: string;
    row?: Record<string, unknown>;
}

interface FileIconProps {
    fileName?: string;
    contentType?: string;
    url?: string;
}

const FilePreview = ({
    fileName = '',
    contentType,
    url,
}: FileIconProps) => {
    const isImage = useMemo(
        () => (contentType && checkIsImage(contentType)),
        [contentType],
    );

    if (!url) return null;

    return (
        <FilePreviewContainer>
            <RenderIf isTrue={isImage}>
                <StyledZoomableImage
                    alt={fileName}
                    src={url}
                    width={38}
                    height={38}
                />
            </RenderIf>
            <IconContainer>
                <ZoomInIcon />
            </IconContainer>
        </FilePreviewContainer>
    );
};

const FilePreviewColumn = ({ value = '', row }: FilePreviewColumnProps) => (
    <CellWrapper>
        <ListTile
            icon={(
                <FilePreview
                    fileName={row?.name as string}
                    url={value}
                    contentType={row?.contentType as string}
                />
            )}
            title={row?.name as string}
            subtitle={formatBytes(row?.size as number)}
        />
    </CellWrapper>
);

export default FilePreviewColumn;
