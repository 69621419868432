import { IconProps } from 'types';

const Users = ({ title = 'Users', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <circle cx="9" cy="7" r="4" stroke="currentColor" strokeWidth="2" />
        <path d="M16 11C17.6569 11 19 9.433 19 7.5C19 5.567 17.6569 4 16 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M15 21C15 17.134 12.3137 14 9 14C5.68629 14 3 17.134 3 21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M21 21C21 17.6863 18.7614 15 16 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default Users;
