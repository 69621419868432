import { DrawerProps } from 'react-rainbow-components/components/Drawer';
import Close from 'components/icons/close';
import ButtonIcon from 'components/ButtonIcon';
import {
    HeaderContainer, HeaderLabel, StyledDrawer,
} from './styled';

const Drawer = ({
    onRequestClose,
    header,
    ...props
}:DrawerProps) => (
    <StyledDrawer
        {...props}
        onRequestClose={onRequestClose}
        hideCloseButton
        header={(
            <HeaderContainer>
                <HeaderLabel>{header}</HeaderLabel>
                <ButtonIcon
                    icon={<Close />}
                    onClick={onRequestClose}
                />
            </HeaderContainer>
        )}
    />
);

export default Drawer;
