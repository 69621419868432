import {
    ComponentType, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { orderBy, query } from 'firebase/firestore';
import Handlebar from 'components/icons/handlebar';
import GeneralVariables from 'components/GeneralVariables';
import useTemplatePartials from 'data/firestore/agent/responder/templatepartial/useCollection';
import useResponder from 'data/firestore/agent/responder/use';
import { HistoryItem, InstructionsValidationError, ModelConfig } from 'components/Instructions/types';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { Modal } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Instructions from 'components/Instructions';
import useResponderInstructionsValidation from 'data/hooks/useResponderInstructionsValidation';
import useSystemMessageVersions from 'data/firestore/agent/responder/systemmessageversion/useCollection';
import useTemplatePartialVersions from 'data/firestore/agent/responder/templatepartial/version/useCollection';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useUpdateTemplatePartialMutation from 'data/hooks/useUpdateTemplatePartialMutation';
import useDeleteTemplatePartialMutation from 'data/hooks/useDeleteTemplatePartialMutation';
import { InstructionTestComponentProps } from 'components/InstructionTestingModal/types';
import ModalBody from 'components/ModalBody';
import AlertTriangle from 'components/icons/alertTriangle';
import InstructionTestingModal from 'components/InstructionTestingModal';
import Button from 'components/Button';
import ImportIcon from 'components/icons/import';
import ExportIcon from 'components/icons/export2';
import ImportModal from 'components/ImportInstructionsModal';
import useExportTemplatePartialsMutation from 'data/hooks/useExportTemplatePartialsMutation';
import useImportTemplatePartialsMutation from 'data/hooks/useImportTemplatePartialsMutation';
import Fields from './createPartialForm';
import DeleteModal from './deleteModal';
import checkTemplateInUse from '../RespondersList/helpers/checkTemplateInUse';
import { RESPONDER_INSTRUCTIONS_TESTING_MODAL } from '../../../constants';
import MainInstructionTesting from './InstructionsTesting/MainInstructionTesting';
import { ResponderInstructionTestComponentProps } from './InstructionsTesting/types';
import { HiddenA } from './styled';

const mainTemplateName = 'main';

const templates = [
    {
        name: 'main',
        label: 'Main Instructions',
    },
];

const contentMapper: Record<string, ComponentType<ResponderInstructionTestComponentProps>> = {
    [mainTemplateName]: MainInstructionTesting,
};

const titleMapper: Record<string, string> = {
    [mainTemplateName]: 'Main Instructions',
};

const builtinTemplatesNames = templates.map((template) => template.name);

const ResponderInstructions = () => {
    const { agentId = '', responderId = '' } = useParams<{ agentId?: string; responderId?: string }>();

    const [selectedTemplate, setTemplate] = useState<string>(mainTemplateName);
    const [editorContent, setEditorContent] = useState<string>('');
    const [currentModelConfig, setCurrentModelConfig] = useState({});
    const [validationErrors, setValidationErrors] = useState<InstructionsValidationError[]>([]);
    const [isTemplateInUse, setIsTemplateInUse] = useState(false);

    const connectedModalProps = useConnectModal('add-template-partial');
    const [openCreateForm, closeCreateForm] = useOpenModal('add-template-partial');
    const inUseConnectedModalProps = useConnectModal('template-partial-in-use');
    const [openInUseModal, closeInUseModal] = useOpenModal('template-partial-in-use');

    // instructions testing
    const connectedTestingModalProps = useConnectModal(RESPONDER_INSTRUCTIONS_TESTING_MODAL);
    const [openInstructionTesting] = useOpenModal(RESPONDER_INSTRUCTIONS_TESTING_MODAL);

    const isBuiltinTemplate = builtinTemplatesNames.includes(selectedTemplate);

    const { data: responder } = useResponder(agentId, responderId);

    const { data: templatePartials } = useTemplatePartials(
        agentId as string,
        responderId,
        {
            disabled: !agentId || !responderId,
            track: [agentId, responderId],
        },
    );
    const [selectedVersion, setSelectedVersion] = useState<HistoryItem | undefined>(undefined);

    const { mutateAsync: validateInstructions } = useResponderInstructionsValidation(
        agentId as string,
        responderId,
    );

    const {
        mutateAsync: updateResponder,
        isLoading: isUpdatingResponder,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        method: 'PATCH',
    });

    const {
        mutateAsync: createTemplatePartial,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        method: 'POST',
        onSuccess: closeCreateForm,
    });

    const {
        mutateAsync: updateTemplatePartial, isLoading: isUpdatingPartial,
    } = useUpdateTemplatePartialMutation();

    const {
        mutateAsync: deleteTemplatePartial,
        isLoading: isDeletingPartial,
    } = useDeleteTemplatePartialMutation();

    // Import/export
    const hiddenLinkRef = useRef<HTMLAnchorElement>(null);
    const {
        mutateAsync: exportInstructions,
        isLoading: isExporting,
    } = useExportTemplatePartialsMutation(
        agentId || '',
        `/agents/${agentId}/responders/${responderId}/export`,
    );
    const {
        mutateAsync: importInstructions,
        isLoading: isImporting,
    } = useImportTemplatePartialsMutation(
        agentId || '',
        `/agents/${agentId}/responders/${responderId}/import`,
    );

    const isUpdating = isUpdatingResponder || isUpdatingPartial || isDeletingPartial;

    useEffect(() => {
        if (selectedVersion) {
            setEditorContent(selectedVersion.value || '');
        }
    }, [selectedVersion]);

    useEffect(() => {
        setSelectedVersion(undefined);
    }, [selectedTemplate]);

    useEffect(
        () => {
            if (isBuiltinTemplate) {
                setIsTemplateInUse(false);
                return;
            }
            const isUsed = checkTemplateInUse(
                selectedTemplate,
                responder?.systemMessageTemplate,
                templatePartials,
            );
            setIsTemplateInUse(isUsed);
        },
        [selectedTemplate, templatePartials, isBuiltinTemplate, responder?.systemMessageTemplate],
    );

    useEffect(
        () => {
            if (isBuiltinTemplate) {
                // eslint-disable-next-line max-len
                setEditorContent(responder?.systemMessageTemplate || '');
            } else {
                setEditorContent(templatePartials?.find(({ name }) => name === selectedTemplate)?.content || '');
            }
        },
        [isBuiltinTemplate, responder?.systemMessageTemplate, selectedTemplate, templatePartials],
    );

    useEffect(
        () => {
            if (responder?.id) {
                setTemplate(mainTemplateName);
                setCurrentModelConfig(responder);
            }
        },
        [responder],
    );

    const handleTemplateChange = (name: string) => {
        setTemplate(name);
        if (isBuiltinTemplate) {
            const config = {
                model: responder?.model,
                temperature: responder?.temperature,
            };
            setCurrentModelConfig(config);
        } else {
            setCurrentModelConfig({});
        }
    };

    const handleValidate = useCallback(
        async (currentValue: string) => {
            try {
                await validateInstructions({
                    body: {
                        template: currentValue,
                        partialName: selectedTemplate !== mainTemplateName ? `${selectedTemplate}` : undefined,
                    },
                });
                setValidationErrors([]);
            } catch (errorPayload: any) {
                if (errorPayload?.errors) {
                    setValidationErrors(errorPayload.errors);
                    return errorPayload.errors;
                }
            }
            return undefined;
        },
        [selectedTemplate, validateInstructions],
    );

    const handleCreatePartial = useCallback(
        () => openCreateForm({
            title: '',
            onSubmit: async ({
                name,
                description:
                partialDescription,
            }: {
                name: string;
                description?: string;
            }) => {
                showAppSpinner();
                try {
                    await createTemplatePartial({
                        pathname: `agents/${agentId}/responders/${responderId}/template-partials`,
                        body: {
                            name,
                            description: partialDescription,
                            content: '{{! This is a comment. Remove it and add your instructions here}}',
                        },
                    });
                    setTemplate(name);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [openCreateForm, createTemplatePartial, agentId, responderId],
    );

    const handleDeletePartial = async () => {
        const partialName = selectedTemplate;
        if (isTemplateInUse) {
            openInUseModal({
                title: '',
            });
            return;
        }
        const result = await confirmModal({
            header: '',
            question: <DeleteModal partialName={partialName} />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            variant: 'destructive',
        });
        if (!result) return;

        try {
            if (isBuiltinTemplate) {
                throw new Error('Cannot delete a built-in template');
            }
            const partial = templatePartials?.find(({ name }) => name === selectedTemplate);
            if (partial) {
                await deleteTemplatePartial({
                    pathname: `agents/${agentId}/responders/${responderId}/template-partials/${partial.id}`,
                });
                setTemplate(mainTemplateName);
            }
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            if (message === 'Template partial is in use') {
                openInUseModal({
                    title: '',
                });
            } else {
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        }
    };

    const handleUpdateModelConfig = useCallback(
        async (values: ModelConfig) => {
            let success = false;
            showAppSpinner();
            try {
                await updateResponder({
                    pathname: `/agents/${agentId}/responders/${responderId}`,
                    body: {
                        ...(responder || {}),
                        ...currentModelConfig,
                        ...values,
                    },
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Account model was successfully updated',
                    icon: 'success',
                    timeout: 5000,
                });
                success = true;
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
            return success;
        },
        [updateResponder, agentId, responderId, responder, currentModelConfig],
    );

    const handleChange = useCallback(
        (newValue?: string) => setEditorContent(newValue || ''),
        [],
    );

    const handleSubmit = useCallback(
        async () => {
            try {
                if (selectedTemplate === mainTemplateName) {
                    await updateResponder({
                        pathname: `/agents/${agentId}/responders/${responderId}`,
                        body: {
                            ...responder || {},
                            systemMessageTemplate: editorContent,
                        },
                    });
                } else {
                    const partial = templatePartials?.find(({ name }) => name === selectedTemplate);
                    if (partial) {
                        await updateTemplatePartial({
                            pathname: `agents/${agentId}/responders/${responderId}/template-partials/${partial.id}`,
                            body: {
                                name: partial.name as string,
                                description: partial.description as string,
                                content: editorContent,
                            },
                        });
                    }
                }
                showAppNotification({
                    title: 'Success',
                    description: 'Your changes have been saved.',
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error: any) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [
            selectedTemplate,
            updateResponder,
            agentId,
            responderId,
            responder,
            editorContent,
            templatePartials,
            updateTemplatePartial,
        ],
    );

    const { data: systemMessageVersions } = useSystemMessageVersions(
        agentId as string,
        responderId,
        {
            disabled: !agentId,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
        },
    );
    const selectedPartialId = templatePartials?.find(({ name }) => name === selectedTemplate)?.id;
    const { data: templatePartialVersions } = useTemplatePartialVersions(
        agentId as string,
        responderId,
        selectedPartialId || '',
        {
            disabled: !agentId || !selectedPartialId,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
            track: [selectedPartialId],
        },
    );
    const historyItems = useMemo(
        () => {
            if (selectedTemplate === 'main') {
                return systemMessageVersions.map<HistoryItem>((v) => ({
                    ...v,
                    value: v.systemMessageTemplate,
                }));
            }

            return templatePartialVersions.map<HistoryItem>((v) => ({
                ...v,
                value: v.content,
            }));
        },
        [selectedTemplate, systemMessageVersions, templatePartialVersions],
    );

    const handleImport = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        input.onchange = async (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (!file) return;
            const reader = new FileReader();
            reader.onload = async (event) => {
                const data = event.target?.result;
                if (!data) return;

                const result = await confirmModal({
                    header: '',
                    question: <ImportModal />,
                    borderRadius: 'semi-square',
                    okButtonLabel: 'Import',
                    cancelButtonLabel: 'Cancel',
                    variant: 'neutral',
                });
                if (!result) return;

                try {
                    showAppSpinner();
                    await importInstructions({
                        body: JSON.parse(data as string),
                    });
                    showAppNotification({
                        title: 'Success',
                        description: 'Your changes have been saved.',
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            };
            reader.readAsText(file);
        };
        input.click();
    };

    const handleExport = async () => {
        if (!hiddenLinkRef.current) {
            return;
        }
        try {
            const res = await exportInstructions({});
            const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(res, null, 2))}`;
            hiddenLinkRef.current.href = dataStr;
            hiddenLinkRef.current.setAttribute('download', 'export.json');
            hiddenLinkRef.current.click();
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
    };

    const testingDisabled = useMemo(
        () => ![mainTemplateName].includes(selectedTemplate),
        [selectedTemplate],
    );

    return (
        <>
            <Instructions
                description="Define how your AI assistant responds to interactions, using customizable instructions and variables tailored to your business needs."
                templates={templates.map((t) => ({
                    ...t,
                    isActive: selectedTemplate === t.name,
                }))}
                partials={templatePartials.map((partial) => ({
                    name: partial.name,
                    label: partial.name,
                    icon: <Handlebar />,
                    isActive: selectedTemplate === partial.name,
                }))}
                actions={(
                    <>
                        <Button
                            borderRadius="semi-square"
                            variant="outline-brand"
                            onClick={handleImport}
                            isLoading={isImporting}
                        >
                            <ImportIcon className="rainbow-m-right_x-small" />
                            Import
                        </Button>
                        <Button
                            borderRadius="semi-square"
                            variant="outline-brand"
                            onClick={handleExport}
                            isLoading={isExporting}
                        >
                            <ExportIcon className="rainbow-m-right_x-small" />
                            Export
                        </Button>
                        <HiddenA ref={hiddenLinkRef} />
                    </>
                )}
                help={<GeneralVariables />}
                value={editorContent}
                validationErrors={validationErrors}
                modelConfig={currentModelConfig}
                historyItems={historyItems}
                selectedVersion={selectedVersion}
                isLoading={isUpdating}
                disableModelConfig={!isBuiltinTemplate}
                onChange={handleChange}
                onValidate={handleValidate}
                onSubmit={handleSubmit}
                onMenuItemClick={(item) => handleTemplateChange(item.name)}
                onAddPartialClick={handleCreatePartial}
                deleteEnabled={!isBuiltinTemplate}
                onDeleteClick={handleDeletePartial}
                onSelectedVersion={setSelectedVersion}
                onSubmitModelConfig={handleUpdateModelConfig}
                disableTesting={testingDisabled}
                onTestClick={openInstructionTesting}
            />
            <UniversalFormModal fields={Fields} borderRadius="semi-square" submitButtonLabel="Create Partial" {...connectedModalProps} />
            <Modal {...inUseConnectedModalProps} onRequestClose={closeInUseModal} borderRadius="semi-square">
                <ModalBody
                    title={`The partial "${selectedTemplate}" is in use.`}
                    description={`The partial "${selectedTemplate}" is currently active in the main instructions. Please remove it from there before attempting to delete.`}
                    icon={<AlertTriangle />}
                    variant="warning"
                />
            </Modal>
            <InstructionTestingModal
                {...connectedTestingModalProps}
                // eslint-disable-next-line max-len
                component={contentMapper[selectedTemplate] as ComponentType<InstructionTestComponentProps>}
                title={titleMapper[selectedTemplate]}
                agentId={agentId}
                responderId={responderId}
                responderConfig={responder}
                template={editorContent}
            />
        </>
    );
};

export default ResponderInstructions;
