/* eslint-disable react/no-unused-prop-types */
import { useCallback, useEffect, useState } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import { InputProps } from 'react-rainbow-components/components/Input';
import Label from 'react-rainbow-components/components/Input/label';
import useHttpMutation from 'data/firestore/useHttpMutation';
import MessageExample from '../DetailsPage/messageExample';
import { GeneratePreviewButton, PreviewContainer } from './styled';

interface SMSPreviewProps extends Omit<InputProps, 'onChange'> {
    label?: string;
    value?: string;
    instructions?: string;
    agentId: string;
    onChange?: (value: string | null) => void;
}

const SMSPreview = (props: SMSPreviewProps) => {
    const {
        value,
        agentId,
        instructions: instructionsProp,
        onChange = () => {},
    } = useReduxForm(props);

    const [instructions, setInstructions] = useState(instructionsProp);

    const {
        mutateAsync: generateExample,
        isLoading: isGeneratingExample,
    } = useHttpMutation({
        pathname: `/agents/${agentId}/reminders/generate-sms`,
        method: 'POST',
    });

    const handleGeneratePreviewClick = useCallback(
        async () => {
            try {
                const { content } = await generateExample({
                    body: {
                        context: { instructions },
                    },
                }) as { content: string };
                onChange(content);
            } catch (error) {
                // no catch
            }
        },
        [generateExample, instructions, onChange],
    );

    useEffect(
        () => {
            if (instructions !== instructionsProp) {
                onChange(null);
                setInstructions(instructionsProp);
            }
        },
        [instructions, instructionsProp, onChange],
    );

    return (
        <PreviewContainer>
            <RenderIf isTrue={!isGeneratingExample && !value}>
                <GeneratePreviewButton
                    size="small"
                    borderRadius="semi-square"
                    disabled={!instructions}
                    onClick={handleGeneratePreviewClick}
                >
                    View Example Message
                </GeneratePreviewButton>
            </RenderIf>
            <RenderIf isTrue={isGeneratingExample || value}>
                <Label
                    label="Reminder Message Example:"
                    labelAlignment="left"
                />
                <MessageExample message={value} isLoading={isGeneratingExample} />
            </RenderIf>
        </PreviewContainer>
    );
};

export default SMSPreview;
