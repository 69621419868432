import { IconProps } from 'types';

const BirthdayCake = ({ className, title }: IconProps) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        className={className}
    >
        <title>{title}</title>
        <path d="M3 13C3 11.8954 3.89543 11 5 11H19C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13Z" stroke="currentColor" strokeWidth="2" />
        <path d="M4 16L6.92336 15.2692C7.61812 15.0955 8.35208 15.176 8.99261 15.4963L10.6584 16.3292C11.5029 16.7515 12.4971 16.7515 13.3416 16.3292L15.0074 15.4963C15.6479 15.176 16.3819 15.0955 17.0766 15.2692L20 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M8 8C8 7.44772 7.55228 7 7 7C6.44772 7 6 7.44772 6 8L8 8ZM8 11L8 8L6 8L6 11L8 11Z" fill="currentColor" />
        <path d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8L13 8ZM13 11L13 8L11 8L11 11L13 11Z" fill="currentColor" />
        <path d="M18 8C18 7.44772 17.5523 7 17 7C16.4477 7 16 7.44772 16 8L18 8ZM18 11L18 8L16 8L16 11L18 11Z" fill="currentColor" />
        <path d="M6 4.8C6 5.13456 6.15671 5.37438 6.34724 5.56519C6.63467 5.85303 7.11892 5.90628 7.43217 5.64676C8.69113 4.60372 7.75016 2.88998 6.86018 2C6.86018 4 6 3.5 6 4.8Z" fill="currentColor" />
        <path d="M11 4.8C11 5.13456 11.1567 5.37438 11.3472 5.56519C11.6347 5.85303 12.1189 5.90628 12.4322 5.64676C13.6911 4.60372 12.7502 2.88998 11.8602 2C11.8602 4 11 3.5 11 4.8Z" fill="currentColor" />
        <path d="M16 4.8C16 5.13456 16.1567 5.37438 16.3472 5.56519C16.6347 5.85303 17.1189 5.90628 17.4322 5.64676C18.6911 4.60372 17.7502 2.88998 16.8602 2C16.8602 4 16 3.5 16 4.8Z" fill="currentColor" />
    </svg>
);
export default BirthdayCake;
