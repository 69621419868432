import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import useEventTypes from 'data/firestore/agent/calendareventtype/useCollection';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useManageEventType from '../hooks/useManageEventType';
import LoadingShape from '../../loading';
import { StyledEmptyMessage } from '../../styled';
import EventType from './item';
import {
    Container, Header, Description, Title, TitleContainer, Content,
} from './styled';
import AddEditEventTypeForm from '../AddEditForm';

const CalendarEventTypesList = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();
    const { create } = useManageEventType({
        agentId,
        onEventTypeCreated: (id: string) => navigate(`/${agentId}/calendar/settings/event-types/${id}/details`),
    });

    const { data = [], isLoading } = useEventTypes(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    const handleAddNewEventType = useCallback(
        () => create({
            eventTypeIncrement: 900,
        }),
        [create],
    );

    const eventyTypes = useMemo(
        () => {
            if (data.length === 0) {
                return (
                    <StyledEmptyMessage message="No event types have been defined yet." />
                );
            }

            return data.map(
                (event) => (
                    <EventType
                        key={event.id}
                        id={event.id}
                        name={event.name}
                        description={event.description}
                        duration={event.duration}
                    />
                ),
            );
        },
        [data],
    );

    return (
        <>
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>Event Types</Title>
                        <Description>
                            Tailor your calendars by setting up distinct event types.
                            Each type can have a preset duration and a description
                            to help organize your schedule.
                        </Description>
                    </TitleContainer>
                    <Button
                        variant="outline-brand"
                        borderRadius="semi-square"
                        label="New Event Type"
                        onClick={handleAddNewEventType}
                    />
                </Header>
                <Content>
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {eventyTypes}
                    </RenderIf>
                </Content>
            </Container>
            <AddEditEventTypeForm />
        </>
    );
};

export default CalendarEventTypesList;
