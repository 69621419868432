import { useCallback, useMemo, useState } from 'react';
import { confirmModal, showAppNotification } from '@rainbow-modules/app';
import ModalBody from 'components/ModalBody';
import Refresh from 'components/icons/refresh';
import switchRole from '../components/ChatView/helpers/switchRole';
import { ChatMessage } from '../types';

const generateId = () => Date.now().toString();

const useChat = ({
    initialized = false,
    messages: initialMessages = [],
    onInitChat = () => 'Hello',
    onSend,
} : {
    initialized?: boolean;
    messages?: ChatMessage[];
    onInitChat?: () => Promise<string | undefined> | string | undefined;
    onSend?: (messages: Pick<ChatMessage, 'content' | 'role'>[]) => Promise<string> | string;
}) => {
    const [isInitialized, setInitialized] = useState(initialized);
    const [isLoading, setLoading] = useState(false);
    const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);

    const handleAddNewMessage = useCallback(
        (value?: Pick<ChatMessage, 'role' | 'content'>) => setMessages([
            ...messages,
            {
                id: generateId(),
                role: value?.role || switchRole(messages[messages.length - 1]?.role),
                content: value?.content || '',
            },
        ]),
        [messages],
    );

    const handleRemoveMessage = useCallback(
        (messageId: string) => setMessages(
            messages.filter((message) => message.id !== messageId),
        ),
        [messages],
    );

    const handleImportMessages = useCallback(
        (values: Pick<ChatMessage, 'role' | 'content'>[]) => setMessages(
            values.map(
                ({ role, content }, index) => ({
                    id: generateId() + index,
                    role,
                    content,
                }),
            ),
        ),
        [],
    );

    const handleUpdateMessage = useCallback(
        (messageId: string, data: Record<string, unknown>) => {
            setMessages(
                messages.map((message) => {
                    if (message.id !== messageId) return message;
                    return {
                        ...message,
                        ...data,
                    };
                }),
            );
        },
        [messages],
    );

    const handleInitChat = useCallback(
        async () => {
            try {
                setLoading(true);
                const greetings = await onInitChat();
                if (greetings) {
                    setMessages([
                        {
                            id: generateId(),
                            role: 'assistant',
                            content: greetings.trim(),
                        },
                    ]);
                }
                setInitialized(true);
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
                setInitialized(initialized);
            }
            setLoading(false);
        },
        [initialized, onInitChat],
    );

    const handleResetChat = useCallback(
        async () => {
            if (!isInitialized) return;
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Refresh />}
                        title="Reset Chat?"
                        description="This will remove all messages. Are you sure you want to continue?"
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Reset',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }
            setMessages([]);
            await handleInitChat();
        },
        [handleInitChat, isInitialized],
    );

    const handleSend = useCallback(
        async () => {
            if (!onSend) return;
            try {
                setLoading(true);
                const response = await onSend(
                    messages.map(({ role, content }) => ({ role, content })),
                );
                setMessages([
                    ...messages,
                    {
                        id: generateId(),
                        role: 'assistant',
                        content: response.trim(),
                    },
                ]);
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            setLoading(false);
        },
        [messages, onSend],
    );

    return useMemo(
        () => ({
            isInitialized,
            isLoading,
            messages,
            initialize: handleInitChat,
            reset: handleResetChat,
            addMessage: handleAddNewMessage,
            updateMessage: handleUpdateMessage,
            deleteMessage: handleRemoveMessage,
            importMessages: handleImportMessages,
            send: handleSend,
        }),
        [
            handleInitChat,
            handleResetChat,
            handleAddNewMessage,
            handleUpdateMessage,
            handleRemoveMessage,
            handleImportMessages,
            handleSend,
            isInitialized,
            isLoading,
            messages,
        ],
    );
};

export default useChat;
