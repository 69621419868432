import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Clock from 'components/icons/clock';
import SettingIcon from 'components/icons/setting';
import { formatDurationFromSeconds } from 'data/services/duration/formatter';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import {
    ItemContainer,
    ItemTitleContainer,
    ItemTitle,
    ItemDescription,
    StyledButtonIcon,
    ItemDurationContainer,
    StyledRecordField,
} from './styled';

interface Params {
    id: string;
    name: string;
    description: string;
    duration: number;
}

const EventType = ({
    id,
    name,
    description,
    duration,
}: Params) => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();

    const handleEditEventType = useCallback(
        () => navigate(`/${agentId}/calendar/settings/event-types/${id}/details`),
        [agentId, id, navigate],
    );

    const formattedDuration = useMemo(() => formatDurationFromSeconds(duration), [duration]);

    return (
        <ItemContainer>
            <ItemTitleContainer>
                <ItemTitle>{name}</ItemTitle>
                <StyledButtonIcon
                    borderRadius="semi-square"
                    size="small"
                    icon={<SettingIcon />}
                    onClick={handleEditEventType}
                />
            </ItemTitleContainer>
            <ItemDescription>{description}</ItemDescription>
            <ItemDurationContainer>
                <Clock />
                <StyledRecordField
                    label="Duration"
                    value={formattedDuration}
                />
            </ItemDurationContainer>
        </ItemContainer>
    );
};

export default EventType;
