import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useOpenModal } from '@rainbow-modules/hooks';
import CalendarDayView from 'components/CalendarDayView';
import { ViewContextType } from 'pages/Calendar/types';
import { ADD_RESCHEDULE_CALENDAR_EVENT, CALENDAR_EVENT_DETAILS } from '../../../../constants';
import ModalTitle from '../../../../components/ModalTitle';
import { Container } from './styled';
import getEventStartAtFromMinutes from './helpers/getEventStartAtFromMinutes';

const CalendarViewDay = () => {
    const {
        calendars,
        events,
        selectedDate,
        timeZone,
        selectedEvent,
        onEventSelect = () => {},
    } = useOutletContext<ViewContextType>();
    const [openEventDetails] = useOpenModal(CALENDAR_EVENT_DETAILS);
    const [openAddEventForm] = useOpenModal(ADD_RESCHEDULE_CALENDAR_EVENT);

    const hideClockLine = useMemo(() => {
        const selected = new Date(selectedDate || Date.now());
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const diff = Math.trunc(Math.abs(selected.getTime() - today.getTime()) / 1000);
        return diff >= 24 * 3600;
    }, [selectedDate]);

    return (
        <Container>
            <CalendarDayView
                date={selectedDate}
                calendars={calendars}
                events={events}
                timeZone={timeZone}
                selectedEvent={selectedEvent}
                onEventClick={({ triggerRef, event }) => {
                    onEventSelect(event.id as string);
                    openEventDetails({
                        triggerRef,
                        eventId: event.id,
                        calendarId: event.calendarId,
                    });
                }}
                onAvailableSpaceClick={(calendar, minute) => {
                    const date = getEventStartAtFromMinutes({
                        selectedDate,
                        minute,
                        timeZone,
                    });
                    openAddEventForm({
                        title: <ModalTitle>Add New Event</ModalTitle>,
                        initialValues: {
                            calendar,
                            startAt: date.toISOString(),
                        },
                    });
                }}
                hideClockLine={hideClockLine}
            />
        </Container>
    );
};

export default CalendarViewDay;
