import { useState } from 'react';
import { useForm } from 'react-final-form';
import Input from 'components/Input';
import ButtonIcon from 'components/ButtonIcon';
import Eye from 'components/icons/eye';
import Refresh from 'components/icons/refresh';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { SecretInputActionsContainer, SecretInputContainer } from './styled';

const SecretInput = ({ input, ...props }: any) => {
    const [showSecret, setShowSecret] = useState(false);
    const { change } = useForm();

    const {
        mutateAsync: generateSecret,
        isLoading: isGeneratingSecret,
    } = useHttpMutation<{}, { secret: string }>({
        method: 'GET',
        pathname: '/secrets/generate',
    });

    const toggleShowSecret = () => {
        setShowSecret((prev) => !prev);
    };

    const handleGenerateSecret = async () => {
        if (input.name) {
            const { secret } = await generateSecret({});
            change(input.name, secret);
        }
    };

    const inputType = showSecret ? 'text' : 'password';
    return (
        <SecretInputContainer>
            <Input disabled={isGeneratingSecret} {...input} {...props} type={inputType} />
            <SecretInputActionsContainer>
                <ButtonIcon icon={<Eye />} size="small" borderRadius="semi-square" onClick={toggleShowSecret} />
                <ButtonIcon icon={<Refresh />} size="small" borderRadius="semi-square" onClick={handleGenerateSecret} />
                <CopyToClipboardButton mode="icon" value={String(input.value)} size="small" borderRadius="semi-square" />
            </SecretInputActionsContainer>
        </SecretInputContainer>
    );
};

export default SecretInput;
