import { Field, isRequired } from '@rainbow-modules/forms';
import { ModalContent } from './styled';
import { StyledInput, StyledGoogleAddressLookup } from '../styled';

const Fields = () => (
    <ModalContent>
        <Field
            name="name"
            component={StyledInput}
            label="Location Name"
            placeholder="Enter the location name"
            validate={isRequired()}
            labelAlignment="left"
            borderRadius="semi-square"
            required
        />
        <Field
            name="address"
            component={StyledGoogleAddressLookup}
            label="Location Address"
            placeholder="Enter the location address"
            validate={isRequired()}
            labelAlignment="left"
            borderRadius="semi-square"
            countries={['us']}
            required
        />
    </ModalContent>
);

export default Fields;
