import { RenderIf } from 'react-rainbow-components';
import {
    Container, StyledLoading, LoadingContainer,
} from './styled';

interface Props {
    children: React.ReactNode;
    isLoading?: boolean;
}

const ChartContainer = ({
    children, isLoading,
}: Props) => (
    <Container>
        <RenderIf isTrue={isLoading}>
            <LoadingContainer>
                {
                    Array.from(Array(7).keys()).map((_, index) => (
                        <StyledLoading key={`ls_${index + 1}`} randomWidth />
                    ))
                }
            </LoadingContainer>
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
            {children}
        </RenderIf>
    </Container>
);

export default ChartContainer;
