import ListTile from 'components/ListTile';
import Headphones from 'components/icons/headphones';
import getInitials from 'data/services/string/getInitials';
import { ItemUser } from 'data/firestore/globals';
import { CellWrapper, StyledAvatar } from '../styled';

interface Props {
    value?: ItemUser;
}

const AddedBy = ({ value }: Props) => {
    const { displayName, photoURL, role } = value || {};
    const title = displayName || 'Unknown';

    return (
        <CellWrapper>
            <ListTile
                icon={(
                    role === 'agent'
                        ? <StyledAvatar icon={<Headphones />} />
                        : <StyledAvatar initials={getInitials(title)} src={photoURL} />
                )}
                title={title}
            />
        </CellWrapper>
    );
};

export default AddedBy;
