import { EntityGet } from 'data/firestore/types';
import { Topic } from 'data/firestore/agent/topic/types';
import iconsMap from 'components/icons';
import {
    ItemContainer, ItemLabel, StyledIcon, StyledBadge,
} from './styled';

interface ItemProps extends EntityGet<Topic> {
    isSelected?: boolean;
    isHover?: boolean;
    count: number;
}

const DefaultIcon = () => <span />;

const Item = ({
    icon, displayName, name, count, ...rest
}: ItemProps) => {
    const { isSelected, isHover } = rest;
    const IconComponent = icon ? iconsMap[icon] : DefaultIcon;

    return (
        <ItemContainer {...rest}>
            <StyledIcon isSelected={isSelected} isHover={isHover}>
                <IconComponent />
            </StyledIcon>
            <ItemLabel isSelected={isSelected} isHover={isHover}>{displayName || name}</ItemLabel>
            <StyledBadge label={count} isSelected={isSelected} isHover={isHover} />
        </ItemContainer>
    );
};

export default Item;
