import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useAgent from 'data/firestore/agent/use';
import useUserRoles from 'hooks/useUserRoles';
import { Provider } from './context';

interface Params {
    children?: ReactNode | ReactNode[];
}

const AgentProvider = ({ children }: Params) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();
    const { role, isAdmin, isLoading: isLoadingUser } = useUserRoles();

    const { data: agentData, isLoading: isLoadingAgent } = useAgent(
        agentId,
        {
            disabled: !agentId || isLoadingUser || !(isAdmin || ['viewer', 'editor', 'owner'].includes(role || '')),
            track: [agentId],
        },
    );

    const value = useMemo(() => ({
        agent: agentData,
        isLoading: isLoadingUser || isLoadingAgent,
    }), [agentData, isLoadingUser, isLoadingAgent]);

    return (
        <Provider value={value}>
            {children}
        </Provider>
    );
};

export default AgentProvider;
