import { IconProps } from 'types';

const HappyFace = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 9H9.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 9H15.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.75 13C8.75 12.5858 8.41421 12.25 8 12.25C7.58579 12.25 7.25 12.5858 7.25 13H8.75ZM16.75 13C16.75 12.5858 16.4142 12.25 16 12.25C15.5858 12.25 15.25 12.5858 15.25 13H16.75ZM16 13.75C16.4142 13.75 16.75 13.4142 16.75 13C16.75 12.5858 16.4142 12.25 16 12.25V13.75ZM8 12.25C7.58579 12.25 7.25 12.5858 7.25 13C7.25 13.4142 7.58579 13.75 8 13.75V12.25ZM7.25 13C7.25 15.6234 9.37665 17.75 12 17.75V16.25C10.2051 16.25 8.75 14.7949 8.75 13H7.25ZM12 17.75C14.6234 17.75 16.75 15.6234 16.75 13H15.25C15.25 14.7949 13.7949 16.25 12 16.25V17.75ZM16 12.25H8V13.75H16V12.25Z" fill="currentColor" />
    </svg>
);

export default HappyFace;
