import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';

interface TimeIncrementPickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: number;
    onChange?: (value: string) => void;
}

const options = [
    { name: '15m', label: '15 minutes', value: 900 },
    { name: '30m', label: '30 minutes', value: 1800 },
    { name: '45m', label: '45 Minutes', value: 2700 },
    { name: '1h', label: '1 hour', value: 3600 },
];

const TimeIncrementPicker = (props: TimeIncrementPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps);
    }, [valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`time-increment-picker-option__${option.name}`} {...option} />),
        [],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            <Option label="Select Increment" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default TimeIncrementPicker;
