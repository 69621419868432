import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import Input from 'components/Input';
import UniversalForm from 'components/UniversalForm';
import { GREY_400, DIVIDER } from '../../../../../constants';

export const StyledUniversalForm = styled(UniversalForm)`
    display: flex;
    align-items: stretch;
    align-self: stretch;
    flex: 1 0 0;
    overflow: hidden;
`;

export const LeftColumn = styled.div`
    display: flex;
    width: 31.25rem;
    padding: 1rem 1.25rem 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    align-self: stretch;
    border-right: 1px solid ${DIVIDER};
    overflow: auto;
    flex-shrink: 0;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
    overflow: hidden;
`;

export const FormSection = styled.div`
    display: flex;
    padding-top: 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;

    &:first-child {
        padding-top: unset;
    }

    &:last-child {
        padding-bottom: 1.25rem;
    }
`;

export const FormSectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
`;

export const SectionTitle = styled.div`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.25rem;
`;

export const SectionDescription = styled.div`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
    align-self: stretch;
    gap: 1rem;

    & > div {
        flex: 1;
    }
`;

export const StyledInput = styled(Input)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const PreviewTitle = styled.div`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.25rem;
    padding: 0.75rem 1.5rem 0.625rem 1.5rem;
`;

export const PreviewContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
`;

export const HeaderActions = styled.div`
    display: flex;
    padding: 0.75rem 1.5rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;

export const Title = styled.div`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_400};
    flex: 1;
`;
