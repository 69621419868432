import styled from 'styled-components';
import Plot from 'react-plotly.js';
import { color } from 'theme';
import Card from 'components/Card';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: auto;
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1em;
    padding: 1em;
`;

export const SumaryContainer = styled(Card)`
    display: flex;
    padding: 1.5rem 0;
    align-items: center;
    
    & > *:not(:first-child) {
        border-left: 1px solid ${color('border.divider')};
    }
`;

export const StyledPlot = styled(Plot)`
    width: 100%;
    height: 300px;
`;

export const HeatmapContainer = styled(Plot)`
    width: 100%;
    height: 500px;
`;
