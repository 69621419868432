import {
    ReactNode, useCallback, useMemo, useState,
} from 'react';
import { DatePickerModal, Option } from 'react-rainbow-components';
import { getFormatter } from 'data/services/date/formatter';
import CalendarIcon from 'components/icons/calendarThin';
import { StyledPicklist } from './styled';

interface PicklistValue {
    label?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

interface DateFilterProps {
    value?: string | Date[];
    onChange?: (value: string | Date[]) => void;
    [key: string]: unknown;
}

const dateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

const options = [
    {
        name: 'current-month',
        label: 'Current Month',
    },
    {
        name: 'last-month',
        label: 'Last Month',
    },
];

const DateFilter = ({
    value: valueInProps,
    onChange = () => {},
    ...rest
}:DateFilterProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) {
            return {
                name: 'last-month',
                label: 'Last Month',
                icon: <CalendarIcon />,
            };
        }

        if (Array.isArray(valueInProps)) {
            const [from, to] = valueInProps;

            const label = (
                to
                    ? `${dateFormatter.format(from)} - ${dateFormatter.format(to)}`
                    : 'Custom date'
            );
            return {
                name: 'custom',
                label,
                icon: <CalendarIcon />,
            };
        }
        const found = (
            valueInProps
                ? options.find((option) => option.name === valueInProps)
                : null
        ) || options[0];
        return { ...found, icon: <CalendarIcon /> };
    }, [valueInProps]);

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`affiliate-bookings-insights-filter-option__${option.name}`} {...option} />),
        [],
    );

    const handlePicklistChange = useCallback(
        (newValue: PicklistValue) => {
            const { name } = newValue;
            if (name === 'custom') {
                return openModal();
            }
            return onChange(name as string);
        },
        [onChange, openModal],
    );

    const handleDatePickerChange = useCallback(
        (newValue: Date | Date[]) => {
            const newVal = Array.isArray(newValue) ? newValue : [newValue];
            if (newVal.length > 1) {
                closeModal();
            }
            onChange(newVal);
        },
        [closeModal, onChange],
    );

    return (
        <>
            <StyledPicklist
                onChange={handlePicklistChange}
                value={pickListvalue}
                {...rest}
            >
                {pickListOptions}
                <Option name="custom" label="Custom Date" />
            </StyledPicklist>
            <DatePickerModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                onChange={handleDatePickerChange}
                value={valueInProps}
                maxDate={new Date()}
                selectionType="range"
                title="Select Date(s)"
                borderRadius="semi-square"
            />
        </>
    );
};

export default DateFilter;
