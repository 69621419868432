import { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { validateNotEmpty } from '../validators';
import {
    FieldsContainer,
    Row,
    StyledCalendarEventTypePicker,
    StyledCalendarPicker,
    StyledCustomerPicker,
    StyledInput,
    StyledTextarea,
} from '../styled';
import getTitleFromEventType from '../helpers/getTitleFromEventType';

interface Params {
    agentId?: string;
}

const StepOne = ({ agentId = '' }: Params) => {
    const { change } = useForm();
    const state = useFormState();
    const { values, dirtyFieldsSinceLastSubmit } = state;

    useEffect(
        () => {
            if (dirtyFieldsSinceLastSubmit.eventType) {
                change('title', getTitleFromEventType(values.eventType));
            }
        },
        [change, dirtyFieldsSinceLastSubmit.eventType, values.eventType],
    );

    return (
        <FieldsContainer>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledCalendarPicker}
                    agentId={agentId}
                    name="calendar"
                    label="Select Calendar"
                    variant="picker"
                    labelAlignment="left"
                    borderRadius="semi-square"
                    required
                    validate={validateNotEmpty('This field is required')}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledCalendarEventTypePicker}
                    eventTypes={values?.calendar?.eventTypes}
                    name="eventType"
                    label="Select Event Type"
                    labelAlignment="left"
                    borderRadius="semi-square"
                    variant="picker"
                    required
                    validate={validateNotEmpty('This field is required')}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledCustomerPicker}
                    agentId={agentId}
                    name="customer"
                    label="Select Customer"
                    labelAlignment="left"
                    placeholder="Find a Customer"
                    borderRadius="semi-square"
                    required
                    validate={validateNotEmpty('This field is required')}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledInput}
                    name="title"
                    label="Title"
                    labelAlignment="left"
                    placeholder="Add title"
                    borderRadius="semi-square"
                    required
                    validate={validateNotEmpty('This field is required')}
                />
            </Row>
            <Row>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={StyledTextarea}
                    name="notes"
                    label="Notes"
                    labelAlignment="left"
                    placeholder="Enter a description for the event"
                    borderRadius="semi-square"
                    rows={2}
                />
            </Row>
        </FieldsContainer>
    );
};

export default StepOne;
