import Button from 'components/Button';
import { FieldDescription } from 'components/Instructions/aiModelConfigForm/styled';
import { Tabset } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';

export const ModalDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin-top: 1rem;
`;

export const HiddenA = styled.a`
    display: none;
`;

export const StyledTabset = styled(Tabset)`
    padding: 0 1.5rem;
`;

export const TabContentContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    margin-top: 1rem;
    padding: .5rem 3rem;
`;

export const SchemaInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const TabHeaderContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
`;

export const TabHeaderInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const TabHeaderActionsContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-shrink: 0;
`;

export const StyledTabHeaderButton = styled(Button)`
    height: 2rem;
    line-height: 2rem;
`;

export const StyledFieldDescription = styled(FieldDescription)`
    margin-bottom: 0;
`;

export const JSONValueContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    box-sizing: border-box; 
    padding: 1rem 1.5rem;
    border: 1px solid ${color('border.divider')};
    border-radius: 4px;

    & > .react-json-view > div {
        padding-bottom: 1rem;
    }
`;
