import styled, { css } from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import Picklist from 'components/Picklist';
import { Chip } from 'react-rainbow-components';
import { color } from 'theme';
import {
    GREY_400, GREY_800, GREY_100, DIVIDER,
} from '../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    align-self: stretch;
`;

export const Header = styled.div`
    display: flex;
    height: 5.375rem;
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
`;

export const HeaderItems = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;

export const Title = styled.div`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_400};
    flex: 1;
`;

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    color: ${GREY_800};
    margin-bottom: 1.5rem;
`;

export const SectionTitle = styled.div`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.25rem;
    color: ${GREY_800};
`;

export const SectionDescription = styled.div`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;

export const Divider = styled.div`
    display: flex;
    align-self: stretch;
    height: 0.0625rem;
    background: ${DIVIDER};
`;

export const Row = styled.div`
    display: flex;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    align-items: stretch;
    gap: 1.25rem;
    flex: 1 0 0;
    align-self: stretch;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 450px;
    margin-bottom: 0.5rem;
`;

export const ColumnLabel = styled.h3`
    font-size: 0.875rem;
    color: ${GREY_400};
    text-transform: uppercase;
`;

export const MappingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
`;

export const Location = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${DIVIDER};
    border-radius: 0.375rem;
    padding: 12px 16px;
    gap: 4px;
    width: 100%;
    color: ${GREY_400};
`;

export const LocationName = styled.h1`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3125rem;
`;

export const LocationAddress = styled.p`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.3125rem;
`;

export const InnerContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 72px;
`;

export const StyledPicklist = styled(Picklist)`
    width: 100%;

    input {
        height: 72px;
    }
`;

export const LocationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledChip = styled(Chip)<{ disabled?: boolean }>`
    background-color: ${color('background.main')};
    align-items: center;
    gap: 0.5rem;
    flex: 1 1 auto;
    height: 72px;
    line-height: 72px;
    padding-left: 1.25rem;
    font-size: 1rem;
    border-radius: 0.375rem;

    span {
        font-size: 1rem;
    }

    & > button[data-id="button-icon-element"] {
        margin-right: -0.3rem;
        border-radius: 0.375rem;

        &:hover {
            margin-right: -0.3rem;
            border-radius: 0.375rem;
        }
    }

    ${(props) => props.disabled && css`
        background-color: ${GREY_100};
        cursor: default;
    `};
`;
