import { UniversalForm } from '@rainbow-modules/forms';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Content = styled.div`
    padding: 0 1.5rem 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    padding: 2rem 1.5rem 1.5rem;
`;

export const StyledUniversalForm = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;    
`;

export const SecretInputContainer = styled.div`
    position: relative;
    input {
        padding-right: 7rem !important;
    }
`;

export const SecretInputActionsContainer = styled.div`
    position: absolute;
    bottom: 2px;
    right: 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    background-color: white;
    height: 2.2rem;
`;
