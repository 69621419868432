import styled from 'styled-components';
import { Google } from '@rainbow-modules/icons';
import MicrosoftIcon from 'components/icons/microsoft';
import { Button } from 'react-rainbow-components';
import { GREY_800, PURPLE_500, GREY_400 } from '../../constants';

export const StyledGoogleIcon = styled(Google)`
    width: 24px;
    height: 24px;
`;

export const StyledMicrosoftIcon = styled(MicrosoftIcon)`
    width: 24px;
    height: 24px;
`;

export const StyledButton = styled(Button)`
    min-width: 310px;
    padding: 0 1.5rem;
    letter-spacing: 0.025rem;
    border-radius: 6px;
    padding: 0.4rem 1.1875rem;
    width: 100%;

    @media (max-width: 768px) {
        min-width: 220px;
    }
`;

export const RightButtonContent = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
`;

export const LoginHeader = styled.h1`
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 2.25rem;
    letter-spacing: 0px;
    text-align: left;
    white-space: nowrap;
    color: ${GREY_800};
    margin-top: 1rem;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const LoginDescription = styled.h2`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.375rem;
    letter-spacing: 0px;
    text-align: left;
    color: ${GREY_400};
    margin: 0.5rem 0 0.75rem 0;

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

export const TermsOfServiceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.5rem;
    text-align: center;
    color: ${GREY_400};
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.375rem;
    padding: 0 2.5rem;

    a {
        color: ${PURPLE_500};

        &:hover {
            color: ${PURPLE_500};
        }
    }

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;
