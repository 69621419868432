/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback } from 'react';
import { DeliveryStatus, Media } from '@twilio/conversations';
import { useQuery } from 'react-query';
import { FormattedDate } from 'react-intl';
import { RenderIf } from 'react-rainbow-components';
import { ItemUser } from 'data/firestore/globals';
import Download from 'components/icons/download';
import StatusIcon from '../StatusIcon';
import {
    AuthorName,
    BubbleBottomContainer, BubbleTopContainer, Item as ChatBubble, MessageContent, Time,
} from '../styled';
import { MediaContainer, StyledButtonIcon } from './styled';
import getMediaComponent from './helpers/getMediaComponent';
import { createDownloadLink } from './helpers/createDownloadLink';

const useMedia = (media?: Media) => useQuery(media?.sid || '', () => media?.getContentTemporaryUrl(), {
    enabled: Boolean(media),
});

interface Props {
    attachedMedia: Media[] | null;
    direction: 'outgoing' | 'incoming';
    dateCreated: Date | null;
    status?: DeliveryStatus;
    authorDisplayName?: string;
    role?: ItemUser['role'];
}

const MediaContent = (props: Props) => {
    const {
        attachedMedia, direction, dateCreated, status, authorDisplayName, role,
    } = props;
    const media = attachedMedia?.[0];
    const { data, isLoading } = useMedia(media);
    const Component = getMediaComponent(media?.contentType);

    const handleDownload = useCallback(
        async () => {
            if (!media) return;
            try {
                const mediaUrl = await media.getContentTemporaryUrl();
                await createDownloadLink(mediaUrl || '', media.filename);
            } catch (error) {
                // no chach
            }
        },
        [media],
    );

    return (
        <MessageContent direction={direction}>
            <BubbleTopContainer>
                <AuthorName>{authorDisplayName}</AuthorName>
                {dateCreated && (
                    <Time>
                        <FormattedDate
                            value={dateCreated}
                            hour="numeric"
                            minute="numeric"
                        />
                    </Time>
                )}
            </BubbleTopContainer>
            <MediaContainer direction={direction}>
                <ChatBubble direction={direction} role={role}>
                    <Component url={data} filename={media?.filename} isLoading={isLoading} />
                </ChatBubble>
                {data && !isLoading && media?.filename && (
                    <StyledButtonIcon
                        direction={direction}
                        size="small"
                        shaded
                        icon={<Download />}
                        onClick={handleDownload}
                    />
                )}
            </MediaContainer>
            <BubbleBottomContainer>
                <RenderIf isTrue={direction === 'outgoing' && status}>
                    <StatusIcon status={status as DeliveryStatus} />
                </RenderIf>
            </BubbleBottomContainer>
        </MessageContent>
    );
};

export default MediaContent;
