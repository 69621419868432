import { IconProps } from 'types';

const ImageIcon = ({ className, title }: IconProps) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title}</title>
        <rect x="3" y="3" width="12" height="12" rx="2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 9.74841L5.25 7.49841C5.946 6.82866 6.804 6.82866 7.5 7.49841L14.25 14.2484" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.75 9.74841L11.25 8.24841C11.946 7.57866 12.804 7.57866 13.5 8.24841L15 9.74841" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5002 6H10.5077" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);
export default ImageIcon;
