import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 0rem 0.125rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
`;
