import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import Trash from 'components/icons/trash';
import ModalBody from 'components/ModalBody';
import ModalTitle from 'components/ModalTitle';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { ADD_EDIT_OPPORTUNITY_MODAL } from '../../constants';

const mapDataToFormValues = (data: Opportunity) => ({
    name: data.name,
    customer: data.customer,
    owner: data.owner?.uid,
    pipeline: data.pipelineId,
    stageId: data.stageId,
    rate: typeof data.score === 'number' ? 'yes' : 'no',
    score: data.score || 0,
    amount: data.amount && (data.amount / 100),
});

const useManageOpportunities = ({
    agentId,
    onOpportunityCreated = () => {},
    onOpportunityDeleted = () => {},
} : {
    agentId: string;
    onOpportunityCreated?: (id: string) => void
    onOpportunityDeleted?: () => void;
}) => {
    const [openOpportunityForm, closeOpportunityForm] = useOpenModal(ADD_EDIT_OPPORTUNITY_MODAL);

    const { mutateAsync: createOpportuniity } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/opportunities`,
        onSuccess: closeOpportunityForm,
    });

    const { mutateAsync: updateOpportunity } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeOpportunityForm,
    });

    const { mutateAsync: deleteOpportunity } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateOpportunity = useCallback(
        (initialValues: Record<string, unknown> = {}) => openOpportunityForm({
            title: <ModalTitle>Create Opportunity</ModalTitle>,
            submitButtonLabel: 'Create',
            initialValues: {
                rate: 'no',
                score: 0,
                ...initialValues,
            },
            agentId,
            onSubmit: async (values: Record<string, unknown>) => {
                showAppSpinner();
                try {
                    const { id } = await createOpportuniity({
                        body: values,
                    }) as Opportunity;
                    onOpportunityCreated(id);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, createOpportuniity, onOpportunityCreated, openOpportunityForm],
    );

    const handleEditOpportunity = useCallback(
        (data: Opportunity) => openOpportunityForm({
            id: data?.id,
            agentId,
            disabledFields: ['customer'],
            title: <ModalTitle>Edit Opportunity</ModalTitle>,
            submitButtonLabel: 'Update',
            initialValues: mapDataToFormValues(data),
            onSubmit: async (values: Record<string, unknown>) => {
                const { customerId, ...rest } = values;
                showAppSpinner();
                try {
                    await updateOpportunity({
                        pathname: `/agents/${agentId}/opportunities/${data.id}`,
                        body: rest,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, openOpportunityForm, updateOpportunity],
    );

    const handleDeleteOpportunity = useCallback(
        async (opportunityId: string) => {
            const result = await confirmModal({
                header: '',
                variant: 'destructive',
                question: (
                    <ModalBody
                        variant="destructive"
                        icon={<Trash />}
                        title="Remove Opportunity?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteOpportunity({
                    pathname: `/agents/${agentId}/opportunities/${opportunityId}`,
                });
                onOpportunityDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deleteOpportunity, onOpportunityDeleted],
    );

    const handleUpdateOpportunityField = useCallback(
        (opportunityId: string, fieldName: string) => async (value: string) => {
            try {
                await updateOpportunity({
                    pathname: `/agents/${agentId}/opportunities/${opportunityId}`,
                    body: {
                        [fieldName]: value,
                    },
                });
                return true;
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            return false;
        },
        [agentId, updateOpportunity],
    );

    return {
        create: handleCreateOpportunity,
        edit: handleEditOpportunity,
        remove: handleDeleteOpportunity,
        updateField: handleUpdateOpportunityField,
    };
};

export default useManageOpportunities;
