import { useParams } from 'react-router-dom';
import ChevronLeft from 'components/icons/chevronLeft';
import ArrowNarrowRight from 'components/icons/arrowNarrowRight';
// import useAgentData from 'hooks/useAgentData';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useLocations from 'data/firestore/agent/location/useCollection';
import useCalendars from 'data/firestore/agent/calendar/useCollection';
import CalendarPicklist from './calendarPicklist';
import {
    Container,
    Content,
    Divider,
    Header,
    HeaderItems,
    StyledButtonIcon,
    Title,
    SectionHeader,
    SectionTitle,
    SectionDescription,
    Column,
    ColumnLabel,
    MappingContainer,
    Location,
    LocationName,
    LocationAddress,
    Row,
    LocationContainer,
} from './styled';

const AffiliatesSettings = () => {
    const { agentId = '' } = useParams();
    // const { agent } = useAgentData();
    const { data: locations } = useLocations(agentId);
    const { data: calendars } = useCalendars(agentId);
    const navigate = useNavigateWithQuery();

    return (
        <Container>
            <Header>
                <HeaderItems>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<ChevronLeft />}
                        size="small"
                        onClick={() => navigate('../affiliates')}
                    />
                    <Title title="Settings">Affiliate Settings</Title>
                </HeaderItems>
            </Header>
            <Divider />
            <Content>
                <SectionHeader>
                    <SectionTitle>Calendars and Locations Management</SectionTitle>
                    <SectionDescription>
                        Link each location with a calendar to streamline event bookings.
                    </SectionDescription>
                </SectionHeader>
                <LocationContainer>
                    <Row>
                        <Column>
                            <ColumnLabel>Location</ColumnLabel>
                        </Column>
                        <Column className="rainbow-m-left_x-large rainbow-p-left_x-large">
                            <ColumnLabel>Address</ColumnLabel>
                        </Column>
                    </Row>

                    {/* TODO: Mapping for company main address */}
                    {/* <MappingContainer>
                            <div>
                                <div>Company Address</div>
                                <div>{agent?.companyAddress.formattedAddress}</div>
                            </div>
                            <ArrowNarrowRight />
                            <CalendarPicklist calendars={calendars} />
                        </MappingContainer> */}
                    {locations?.map((location) => (
                        <MappingContainer key={location.id}>
                            <Column>
                                <Location>
                                    <LocationName>{location.name}</LocationName>
                                    <LocationAddress>
                                        {location.address.formattedAddress}
                                    </LocationAddress>
                                </Location>
                            </Column>
                            <ArrowNarrowRight />
                            <Column>
                                <CalendarPicklist calendars={calendars} location={location} />
                            </Column>
                        </MappingContainer>
                    ))}
                </LocationContainer>
            </Content>
        </Container>
    );
};

export default AffiliatesSettings;
