import { LeftCellWrapper, StyledLink } from '../styled';

interface Props {
    value?: string;
}

const ConversationIdColumn = ({ value }: Props) => {
    if (!value) return null;

    return <LeftCellWrapper><StyledLink to={value}>{`#${value.slice(-5)}`}</StyledLink></LeftCellWrapper>;
};

export default ConversationIdColumn;
