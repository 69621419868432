import styled from 'styled-components';
import { GREY_400 } from '../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.75rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    gap: 1rem;
`;

export const ActionsColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 1rem;
    padding-right: 1rem;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 20px;
    text-overflow: ellipsis;
    overflow: hidden;
`;
