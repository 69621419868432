import { useCallback, useState } from 'react';
import { orderBy, query, where } from 'firebase/firestore';
import { Modal, RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import getSession from 'data/firestore/session/get';
import listMessages from 'data/firestore/session/conversation/list';
import ModalTitle from 'components/ModalTitle';
import UniversalForm from 'components/UniversalForm';
import Fields from './fields';
import parseMessages from './helpers/parseMessages';

const mapValues = async (values: Record<string, unknown>) => {
    const { importFrom, conversation, sessionId } = values;
    if (importFrom === 'text') {
        return parseMessages(conversation as string);
    }

    try {
        const session = await getSession(sessionId as string);
        if (!session) throw Error('Invalid conversation Id');
        const sessionMessages = await listMessages(sessionId as string, {
            listQueryFn: (ref) => query(
                ref,
                where('role', 'in', ['assistant', 'user']),
                orderBy('createdAt', 'asc'),
            ),
        });

        return sessionMessages.map(({ role, content }) => ({ role, content: content.trim() }));
    } catch (error) {
        return {
            error: (error as Error).message,
        };
    }
};

interface ImportConversationModalProps {
    isOpen?: boolean;
    onRequestClose?: () => void;
    onCancel?: () => void;
    onImport?: (value: { from: string; value: string; }) => void;
}

const ImportConversationModal = ({
    isOpen = false,
    onRequestClose = () => {},
    onCancel = () => {},
    onImport = () => {},
}: ImportConversationModalProps) => {
    const [isLoading, setLoading] = useState(false);
    const [err, setError] = useState('');

    const handleClose = useCallback(
        () => {
            if (isLoading) return;
            onRequestClose();
            onCancel();
        },
        [onCancel, onRequestClose, isLoading],
    );

    const handleImport = useCallback(
        async (values: Record<string, unknown>) => {
            setLoading(true);
            const result = await mapValues(values);
            setLoading(false);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (result.error) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return setError(result.error as string);
            }
            onRequestClose();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return onImport(result);
        },
        [onImport, onRequestClose],
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            title={<ModalTitle>Import Conversation</ModalTitle>}
            size="medium"
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        className="rainbow-m-right_medium"
                        label="Close"
                        variant="neutral"
                        borderRadius="semi-square"
                        onClick={handleClose}
                        disabled={isLoading}
                    />
                    <Button
                        label="Import"
                        borderRadius="semi-square"
                        variant="brand"
                        type="submit"
                        form="import-form"
                        isLoading={isLoading}
                    />
                </div>
            )}
        >
            <UniversalForm
                id="import-form"
                initialValues={{
                    importFrom: 'text',
                }}
                onSubmit={handleImport}
            >
                <Fields />
                <RenderIf isTrue={err}>
                    <span>{err}</span>
                </RenderIf>
            </UniversalForm>
        </Modal>
    );
};

export default ImportConversationModal;
