import { PURPLE_500 } from '../../constants';

interface IconProps {
    title?: string;
    className?: string;
}

const LogoLarge = ({ title = 'Voxity.ai', className }: IconProps) => (
    <svg className={className} width={791} height={208} viewBox="0 0 791 208" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.5303 49.7678C60.5303 33.4247 73.7789 20.1761 90.122 20.1761C106.465 20.1761 119.714 33.4247 119.714 49.7678V161.409C119.714 167.352 124.531 172.17 130.474 172.17C136.417 172.17 141.235 167.352 141.235 161.409V102.226C141.235 85.8828 154.484 72.6341 170.827 72.6341C187.17 72.6341 200.418 85.8827 200.418 102.226V114.331C200.418 119.532 196.203 123.747 191.003 123.747C185.803 123.747 181.587 119.532 181.587 114.331V102.226C181.587 96.2829 176.77 91.4652 170.827 91.4652C164.884 91.4652 160.066 96.2829 160.066 102.226V161.409C160.066 177.752 146.817 191.001 130.474 191.001C114.131 191.001 100.883 177.752 100.883 161.409V49.7678C100.883 43.8249 96.0649 39.0072 90.122 39.0072C84.1791 39.0072 79.3614 43.8249 79.3614 49.7678V73.9792C79.3614 79.1792 75.1459 83.3947 69.9458 83.3947C64.7458 83.3947 60.5303 79.1792 60.5303 73.9792V49.7678Z" fill={PURPLE_500} />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.1779 114.331L20.1779 99.5356C20.1779 94.3356 24.3934 90.1201 29.5935 90.1201C34.7935 90.1201 39.009 94.3356 39.009 99.5356L39.009 114.331C39.009 119.532 34.7936 123.747 29.5935 123.747C24.3934 123.747 20.178 119.532 20.1779 114.331Z" fill={PURPLE_500} />
        <path fillRule="evenodd" clipRule="evenodd" d="M221.941 114.331L221.941 99.5356C221.941 94.3356 226.156 90.1201 231.356 90.1201C236.556 90.1201 240.772 94.3356 240.772 99.5356L240.772 114.331C240.772 119.532 236.556 123.747 231.356 123.747C226.156 123.747 221.941 119.532 221.941 114.331Z" fill={PURPLE_500} />
        <path d="M597.911 177.352V159.914L607.549 160.04C609.24 160.04 610.868 159.766 612.432 159.216C613.996 158.666 615.434 157.906 616.744 156.933C618.097 155.961 619.281 154.798 620.295 153.446C621.31 152.135 622.134 150.698 622.768 149.133C620.866 149.937 618.921 150.74 616.934 151.543C614.99 152.346 613.024 152.748 611.037 152.748C606.852 152.748 602.92 152.008 599.242 150.529C595.565 149.049 592.33 146.956 589.54 144.251C586.792 141.503 584.615 138.184 583.009 134.295C581.445 130.363 580.663 125.967 580.663 121.105V83.5651H597.911V121.105C597.911 123.515 598.249 125.629 598.925 127.446C599.644 129.222 600.595 130.723 601.779 131.949C602.963 133.132 604.337 134.02 605.901 134.612C607.507 135.204 609.219 135.5 611.037 135.5C612.813 135.5 614.483 135.098 616.047 134.295C617.653 133.449 619.048 132.35 620.232 130.998C621.416 129.645 622.346 128.123 623.022 126.432C623.698 124.699 624.037 122.923 624.037 121.105V83.5651H641.475V142.919C641.433 147.696 640.503 152.177 638.685 156.362C636.867 160.548 634.373 164.204 631.202 167.333C628.074 170.461 624.417 172.934 620.232 174.752C616.047 176.57 611.566 177.479 606.789 177.479L597.911 177.352Z" fill="black" />
        <path d="M559.957 151.48C555.772 151.48 551.84 150.698 548.162 149.133C544.485 147.527 541.251 145.35 538.46 142.602C535.713 139.812 533.535 136.578 531.929 132.9C530.365 129.222 529.583 125.29 529.583 121.105V100.94H521.149V83.6919H529.583V56.5514H546.831V83.6919H573.084V100.94H546.831V121.105C546.831 122.923 547.169 124.635 547.845 126.242C548.522 127.806 549.452 129.18 550.636 130.363C551.819 131.547 553.214 132.498 554.821 133.217C556.427 133.893 558.139 134.232 559.957 134.232H573.084V151.48H559.957Z" fill="black" />
        <path d="M518.229 65.112C518.229 66.7185 517.912 68.2192 517.278 69.6143C516.686 71.0094 515.862 72.2354 514.805 73.2922C513.748 74.3068 512.501 75.1312 511.064 75.7653C509.669 76.3572 508.168 76.6531 506.561 76.6531C504.955 76.6531 503.433 76.3572 501.996 75.7653C500.601 75.1312 499.375 74.3068 498.318 73.2922C497.303 72.2354 496.479 71.0094 495.845 69.6143C495.253 68.2192 494.957 66.7185 494.957 65.112C494.957 63.5479 495.253 62.0682 495.845 60.6732C496.479 59.2358 497.303 58.0099 498.318 56.9953C499.375 55.9384 500.601 55.114 501.996 54.5222C503.433 53.888 504.955 53.571 506.561 53.571C508.168 53.571 509.669 53.888 511.064 54.5222C512.501 55.114 513.748 55.9384 514.805 56.9953C515.862 58.0099 516.686 59.2358 517.278 60.6732C517.912 62.0682 518.229 63.5479 518.229 65.112ZM515.249 151.48H497.811V83.5651H515.249V151.48Z" fill="black" />
        <path d="M496.791 151.48H475.611L462.104 130.68L448.471 151.48H427.291L452.085 116.666L427.291 83.1846H448.471L462.104 102.652L475.611 83.1846H496.791L471.933 116.666L496.791 151.48Z" fill="black" />
        <path d="M432.676 116.92C432.676 121.993 431.767 126.728 429.949 131.124C428.131 135.479 425.658 139.262 422.53 142.475C419.402 145.646 415.724 148.161 411.496 150.021C407.311 151.839 402.809 152.748 397.989 152.748C393.212 152.748 388.71 151.839 384.482 150.021C380.297 148.161 376.619 145.646 373.449 142.475C370.32 139.262 367.847 135.479 366.029 131.124C364.212 126.728 363.303 121.993 363.303 116.92C363.303 111.762 364.212 106.985 366.029 102.589C367.847 98.1922 370.32 94.4086 373.449 91.238C376.619 88.0251 380.297 85.5097 384.482 83.6919C388.71 81.8741 393.212 80.9651 397.989 80.9651C402.809 80.9651 407.311 81.8318 411.496 83.5651C415.724 85.2561 419.402 87.6869 422.53 90.8575C425.658 93.9858 428.131 97.7694 429.949 102.208C431.767 106.605 432.676 111.509 432.676 116.92ZM415.237 116.92C415.237 114.13 414.772 111.614 413.842 109.374C412.955 107.091 411.729 105.146 410.164 103.54C408.6 101.891 406.761 100.644 404.648 99.7986C402.576 98.9109 400.357 98.467 397.989 98.467C395.622 98.467 393.381 98.9109 391.268 99.7986C389.196 100.644 387.378 101.891 385.814 103.54C384.292 105.146 383.087 107.091 382.2 109.374C381.312 111.614 380.868 114.13 380.868 116.92C380.868 119.541 381.312 121.972 382.2 124.212C383.087 126.453 384.292 128.398 385.814 130.046C387.378 131.695 389.196 133.006 391.268 133.978C393.381 134.908 395.622 135.373 397.989 135.373C400.357 135.373 402.576 134.929 404.648 134.041C406.761 133.154 408.6 131.906 410.164 130.3C411.729 128.694 412.955 126.749 413.842 124.466C414.772 122.183 415.237 119.668 415.237 116.92Z" fill="black" />
        <path d="M323.127 151.48L297.255 83.5651H316.976L331.815 125.925L346.59 83.5651H366.374L340.502 151.48H323.127Z" fill="black" />
        <path d="M677.942 145.351C677.942 146.873 677.667 148.31 677.118 149.663C676.568 150.974 675.807 152.115 674.835 153.087C673.862 154.06 672.721 154.821 671.41 155.37C670.1 155.962 668.684 156.258 667.162 156.258C665.682 156.258 664.266 155.962 662.913 155.37C661.603 154.821 660.44 154.06 659.426 153.087C658.453 152.115 657.671 150.974 657.079 149.663C656.53 148.31 656.255 146.873 656.255 145.351C656.255 143.871 656.53 142.476 657.079 141.166C657.671 139.855 658.453 138.714 659.426 137.742C660.44 136.769 661.603 136.008 662.913 135.459C664.266 134.867 665.682 134.571 667.162 134.571C668.684 134.571 670.1 134.867 671.41 135.459C672.721 136.008 673.862 136.769 674.835 137.742C675.807 138.714 676.568 139.855 677.118 141.166C677.667 142.476 677.942 143.871 677.942 145.351Z" fill={PURPLE_500} />
        <path d="M784.807 65.112C784.807 66.7185 784.489 68.2192 783.855 69.6143C783.263 71.0094 782.439 72.2354 781.382 73.2922C780.325 74.3068 779.078 75.1312 777.641 75.7653C776.246 76.3572 774.745 76.6531 773.139 76.6531C771.532 76.6531 770.01 76.3572 768.573 75.7653C767.178 75.1312 765.952 74.3068 764.895 73.2922C763.88 72.2354 763.056 71.0094 762.422 69.6143C761.83 68.2192 761.534 66.7185 761.534 65.112C761.534 63.5479 761.83 62.0682 762.422 60.6732C763.056 59.2358 763.88 58.0099 764.895 56.9953C765.952 55.9384 767.178 55.114 768.573 54.5222C770.01 53.888 771.532 53.571 773.139 53.571C774.745 53.571 776.246 53.888 777.641 54.5222C779.078 55.114 780.325 55.9384 781.382 56.9953C782.439 58.0099 783.263 59.2358 783.855 60.6732C784.489 62.0682 784.807 63.5479 784.807 65.112ZM781.826 151.48H764.388V83.5651H781.826V151.48Z" fill={PURPLE_500} />
        <path d="M757.628 151.48H753.443L746.721 142.158C745.072 143.638 743.318 145.033 741.458 146.343C739.64 147.612 737.717 148.732 735.687 149.704C733.658 150.634 731.566 151.374 729.41 151.924C727.296 152.473 725.14 152.748 722.941 152.748C718.164 152.748 713.662 151.945 709.435 150.338C705.249 148.732 701.571 146.407 698.401 143.363C695.273 140.277 692.799 136.514 690.982 132.076C689.164 127.637 688.255 122.585 688.255 116.92C688.255 111.636 689.164 106.795 690.982 102.399C692.799 97.9597 695.273 94.1549 698.401 90.9843C701.571 87.8137 705.249 85.3617 709.435 83.6285C713.662 81.8529 718.164 80.9651 722.941 80.9651C725.14 80.9651 727.317 81.2399 729.473 81.7895C731.629 82.3391 733.722 83.1 735.751 84.0724C737.78 85.0447 739.703 86.1861 741.521 87.4966C743.381 88.8072 745.115 90.2234 746.721 91.7453L753.443 83.6919H757.628V151.48ZM740.19 116.92C740.19 114.553 739.725 112.27 738.795 110.071C737.907 107.831 736.681 105.865 735.117 104.174C733.552 102.441 731.714 101.067 729.6 100.052C727.528 98.9954 725.309 98.467 722.941 98.467C720.574 98.467 718.333 98.8686 716.22 99.6718C714.148 100.475 712.33 101.659 710.766 103.223C709.244 104.787 708.04 106.732 707.152 109.057C706.264 111.34 705.82 113.961 705.82 116.92C705.82 119.879 706.264 122.521 707.152 124.847C708.04 127.129 709.244 129.053 710.766 130.617C712.33 132.181 714.148 133.365 716.22 134.168C718.333 134.971 720.574 135.373 722.941 135.373C725.309 135.373 727.528 134.866 729.6 133.851C731.714 132.794 733.552 131.42 735.117 129.729C736.681 127.996 737.907 126.03 738.795 123.832C739.725 121.591 740.19 119.287 740.19 116.92Z" fill={PURPLE_500} />
    </svg>
);

export default LogoLarge;
