import SummaryAffiliatesItem from 'components/SummaryAffiliatesItem';
import UsersIcon from 'components/icons/users';
import CalendarIcon from 'components/icons/calendarThin';
import {
    PURPLE_100, PURPLE_500, GREEN_500, GREEN_100, ORANGE_500, ORANGE_100,
} from '../../../constants';
import {
    IconContainer, PrimaryInsightSectionContainer, SumaryContainer,
} from '../styled';
import useGlobalAffiliateInsightsLast30days from '../hooks/useGlobalAffiliateInsightsLast30days';
import ValuePercentLabel from '../percentValueLabel';

const GlobalInsightsLast30Days = () => {
    const {
        isLoading,
        totalAffiliates,
        newAffiliates,
        affiliateGrowthRate,
        newBookings,
        bookingsGrowthRate,
    } = useGlobalAffiliateInsightsLast30days();
    return (
        <PrimaryInsightSectionContainer
            label="Affiliate Performance"
            description="Quick stats on your affiliate network's activity over the last 30 days."
        >
            <SumaryContainer>
                <SummaryAffiliatesItem
                    isLoading={isLoading}
                    label="Total Affiliates"
                    value={totalAffiliates}
                    icon={(
                        <IconContainer color={GREEN_500} background={GREEN_100}>
                            <UsersIcon />
                        </IconContainer>
                    )}
                />
                <SummaryAffiliatesItem
                    isLoading={isLoading}
                    label="New Affiliates"
                    value={newAffiliates}
                    percentLabel={<ValuePercentLabel value={affiliateGrowthRate} />}
                    icon={(
                        <IconContainer color={ORANGE_500} background={ORANGE_100}>
                            <UsersIcon />
                        </IconContainer>
                    )}
                />
                <SummaryAffiliatesItem
                    isLoading={isLoading}
                    label="Bookings"
                    value={newBookings}
                    percentLabel={<ValuePercentLabel value={bookingsGrowthRate} />}
                    icon={(
                        <IconContainer color={PURPLE_500} background={PURPLE_100}>
                            <CalendarIcon />
                        </IconContainer>
                    )}
                />
            </SumaryContainer>
        </PrimaryInsightSectionContainer>
    );
};

export default GlobalInsightsLast30Days;
