import { Geometry } from 'types';

const getLocation = (geometry?: Geometry) => {
    const lat = (
        typeof geometry?.location?.lat === 'function'
            ? geometry?.location?.lat()
            : geometry?.location?.lat
    );
    const lng = (
        typeof geometry?.location?.lng === 'function'
            ? geometry?.location?.lng()
            : geometry?.location?.lng
    );
    return { lat, lng };
};

export default getLocation;
