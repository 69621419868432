import { ButtonMenu, MenuItem } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import ButtonIcon from 'components/ButtonIcon';
import DotsVertical from 'components/icons/dotsVertical';
import ArrowsDiagonal from 'components/icons/arrowsDiagonal';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet } from 'data/firestore/types';
import { OPPORTUNITY_DETAILS_DRAWER } from '../../../../../constants';
import useManageOpportunities from '../../../../../data/hooks/useManageOpportunities';
import { CellActions } from './styled';

interface Props {
    row?: EntityGet<Opportunity>;
    agentId: string;
}

const ActionsColumn = ({ row, agentId }: Props) => {
    const [openModal] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);
    const {
        edit: editOpportinity,
        remove: removeOpportunity,
    } = useManageOpportunities({ agentId });
    return (
        <CellActions>
            <ButtonIcon
                icon={<ArrowsDiagonal />}
                borderRadius="semi-square"
                onClick={() => openModal({
                    data: row,
                })}
            />
            <ButtonMenu
                buttonVariant="base"
                icon={<DotsVertical />}
                borderRadius="semi-square"
            >
                <MenuItem
                    label="Edit Opportunity"
                    icon={<Pencil />}
                    onClick={() => editOpportinity(row as Opportunity)}
                />
                <MenuItem
                    label="Remove Opportunity"
                    icon={<Trash />}
                    onClick={() => removeOpportunity(row?.id as string)}
                />
            </ButtonMenu>
        </CellActions>
    );
};

export default ActionsColumn;
