import { useConnectModal } from '@rainbow-modules/hooks';
import { ADD_EDIT_CALENDAR_EVENT_TYPE_NOTIFICATION_RULE } from '../../../../../constants';
import Fields from './fields';
import { StyledFormModal } from './styled';

const AddEditReminerForm = () => {
    const {
        ...connectedModalProps
    } = useConnectModal(ADD_EDIT_CALENDAR_EVENT_TYPE_NOTIFICATION_RULE);

    return (
        <StyledFormModal
            {...connectedModalProps}
            borderRadius="semi-square"
            size="medium"
            fields={Fields}
        />
    );
};

export default AddEditReminerForm;
