import { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { ButtonProps } from 'react-rainbow-components/components/Button/index';
import Button from 'components/Button';
import Copy from 'components/icons/copy';
import Check from 'components/icons/check';
import ButtonIcon from 'components/ButtonIcon';

interface CopyToClipboardButtonProps extends Omit<ButtonProps, 'onClick'> {
    value: string;
    label?: React.ReactNode;
    copiedLabel?: React.ReactNode;
    mode?: 'default' | 'icon';
}

const CopyToClipboardButton = ({
    value,
    label = 'Copy',
    copiedLabel = 'Copied',
    mode = 'default',
    ...rest
}: CopyToClipboardButtonProps) => {
    const [isCopied, setCopied] = useState(false);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
    }, [isCopied]);

    const handleClick = () => {
        const success = copy(value);
        if (success) {
            setCopied(true);
        }
    };

    const iconClassName = mode === 'default' ? 'rainbow-m-right_x-small' : '';
    const icon = isCopied
        ? <Check className={iconClassName} />
        : <Copy className={iconClassName} />;
    const buttonLabel = isCopied ? copiedLabel : label;
    if (mode === 'icon') {
        return (
            <ButtonIcon {...rest} icon={icon} onClick={handleClick} />
        );
    }

    return (
        <Button {...rest} borderRadius="semi-rounded" onClick={handleClick}>
            {icon}
            {' '}
            {buttonLabel}
        </Button>
    );
};

export default CopyToClipboardButton;
