import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Option } from 'react-rainbow-components';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { Location } from 'data/firestore/agent/location/types';
import { EntityGet } from 'data/firestore/types';
import SelectedValue from './selectedValue';
import { StyledPicklist } from './styled';

const DEFALT_VALUE_CALENDAR_PICKLIST = {
    name: 'select-calendar',
    label: 'Select a calendar',
};

interface Params {
    calendars: EntityGet<Calendar>[];
    location: EntityGet<Location>;
}

const CalendarPicklist = ({ calendars, location }: Params) => {
    const { agentId = '' } = useParams();
    const {
        mutateAsync: updateLocation,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        method: 'PATCH',
    });

    const update = async (val: string | null = null) => {
        showAppSpinner();
        try {
            await updateLocation({
                pathname: `/agents/${agentId}/locations/${location.id}`,
                body: {
                    affiliatesCalendar: val,
                },
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    const value = useMemo(() => {
        const calendar = calendars.find((cal) => cal.id === location.affiliatesCalendar);
        if (calendar) {
            return {
                name: calendar.id,
                label: calendar.name,
            };
        }
        return null;
    }, [calendars, location]);

    if (value) {
        return (
            <SelectedValue
                label={value.label}
                onClearValue={() => update(null)}
            />
        );
    }

    return (
        <StyledPicklist
            value={DEFALT_VALUE_CALENDAR_PICKLIST}
            onChange={async (val) => update(val.name as string)}
        >
            <Option label="Select Calendar" variant="header" />
            {calendars.map((calendar) => (
                <Option
                    key={calendar.id}
                    name={calendar.id}
                    label={calendar.name}
                />
            ))}
        </StyledPicklist>
    );
};

export default CalendarPicklist;
