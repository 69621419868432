import {
    useState, ReactNode, useEffect, useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Picklist, Option } from 'react-rainbow-components';
import {
    query, where, documentId, collection, getDocs,
} from 'firebase/firestore';
import useSMSChat from 'hooks/useSMSChat';
import { Agent } from 'data/firestore/agent/types';
import { User } from 'data/firestore/user/types';
import HeadphonesIcon from 'components/icons/headphones';
import listAgents from 'data/firestore/agent/list';
import { db } from '../../firebase';

interface PicklistValue {
    label?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

const agentsCollectionRef = collection(db, '/agent');

// Function to split array into chunks
const chunkArray = (arr: string[], chunkSize: number) => Array(Math.ceil(arr.length / chunkSize))
    .fill(null)
    .map((_, index) => index * chunkSize)
    .map((begin) => arr.slice(begin, begin + chunkSize));

const useAgents = ({
    agentIds,
    showAll = false,
}: {
    agentIds: string[];
    showAll?: Boolean;
}) => {
    const [allAgents, setAllAgents] = useState<Agent[]>([]);

    // TODO: this does not scale
    // we are fetching all agents in batches of 30 because of firestore 'in' operator limits
    useEffect(() => {
        const fetchAgentsInBatches = async () => {
            // Array to hold all fetched agents
            let fetchedAgents: any[] = [];

            if (showAll) {
                fetchedAgents = await listAgents();
            } else {
                // Split agentIds into chunks of 30
                const chunks = chunkArray(agentIds, 30);

                // eslint-disable-next-line no-restricted-syntax
                for (const chunk of chunks) {
                    const chunkQuery = query(agentsCollectionRef, where(documentId(), 'in', chunk));
                    // eslint-disable-next-line no-await-in-loop
                    const querySnapshot = await getDocs(chunkQuery);
                    querySnapshot.forEach((doc) => {
                        fetchedAgents.push({ id: doc.id, ...doc.data() });
                    });
                }
            }

            setAllAgents(fetchedAgents);
        };

        if (agentIds.length > 0 || showAll) {
            fetchAgentsInBatches();
        }
    }, [agentIds, showAll]);

    return useMemo(() => ({ data: allAgents }), [allAgents]);
};

const Agents = ({ user: userData, isAdmin = false }: { user: User; isAdmin?: boolean; }) => {
    const navigate = useNavigate();
    const { closeChat } = useSMSChat();
    const [selectedAgent, setAgent] = useState<PicklistValue>();
    const { agentId } = useParams<{ agentId?: string }>();
    const agentIds = useMemo(() => Object.keys(userData?.agents || {}), [userData]);
    const { data: agents } = useAgents({ agentIds, showAll: isAdmin });

    useEffect(() => {
        // TODO: what to do when the user does not have any agent
        if (agents.length > 0) {
            const pathAgent = agents.find(({ id }) => id === agentId);
            const agent = pathAgent || agents[0];
            const { companyName, id } = agent;
            if (!pathAgent) navigate(`/${id}/tasks`);
            setAgent({
                name: id,
                label: companyName,
                value: agent,
                icon: <HeadphonesIcon />,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agents]);

    return (
        <Picklist
            onChange={(value) => {
                setAgent(value);
                closeChat();
                navigate(`/${value.name}/tasks`);
            }}
            value={selectedAgent}
            label="Select Assistant"
            variant="bare"
            hideLabel
            enableSearch
        >
            {agents.map((agent) => {
                const { id, companyName } = agent;
                // TODO: search is not working
                return (
                    <Option
                        key={id}
                        name={id}
                        label={companyName}
                        value={agent}
                        icon={<HeadphonesIcon />}
                    />
                );
            })}
        </Picklist>
    );
};

export default Agents;
