import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import ArrowRight from 'components/icons/arrowRight';
import {
    EmptyMessageDescription, EmptyMessageTitle, EmptyMessageEmoji, EmptyMessageContainer,
} from './styled';

interface Props {
    searchQuery?: string;
    status?: string;
}

const EmptyTasks = ({ searchQuery, status }: Props) => {
    const { displayName } = useCurrentUser() || {};
    const { agentId } = useParams<{ agentId: string }>();
    const navigate = useNavigate();

    if (searchQuery) {
        return (
            <EmptyMessageContainer>
                <EmptyMessageEmoji>
                    🔍
                </EmptyMessageEmoji>
                <EmptyMessageTitle>
                    No Matching Results
                </EmptyMessageTitle>
                <EmptyMessageDescription>
                    We couldn&apos;t find anything matching your search for &quot;
                    <strong>{searchQuery}</strong>
                    &quot;
                    <br />
                    Try using different keywords or check your spelling.
                </EmptyMessageDescription>
            </EmptyMessageContainer>
        );
    }

    return (
        <EmptyMessageContainer>
            <EmptyMessageEmoji>
                📭
            </EmptyMessageEmoji>
            <RenderIf isTrue={status === 'archived'}>
                <EmptyMessageTitle>
                    Your Archive is Empty,
                    {' '}
                    {displayName}
                    !
                </EmptyMessageTitle>
                <EmptyMessageDescription>
                    There are no archived tasks yet.
                    You can archive resolved or open tasks as needed.
                </EmptyMessageDescription>
            </RenderIf>
            <RenderIf isTrue={status === 'solved'}>
                <EmptyMessageTitle>
                    Let&apos;s Start Resolving,
                    {' '}
                    {displayName}
                    !
                </EmptyMessageTitle>
                <EmptyMessageDescription>
                    No tasks resolved yet. Explore your open tasks to see what needs your attention.
                </EmptyMessageDescription>
                <Button
                    variant="base"
                    borderRadius="semi-rounded"
                    onClick={() => navigate(`/${agentId}/tasks/open`)}
                >
                    Go to Open Tasks
                    <ArrowRight className="rainbow-m-left_small" />
                </Button>
            </RenderIf>
        </EmptyMessageContainer>
    );
};

export default EmptyTasks;
