import styled from 'styled-components';
import ZoomableImage from 'components/ZoomableImage';
import { GREY_400, DIVIDER } from '../../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    text-transform: capitalize;
`;

export const StyledZoomableImage = styled(ZoomableImage)``;

export const FilePreviewContainer = styled.div`
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    align-content: center;
    flex-shrink: 0;
    border-radius: 0.375rem;
    border: 1px solid ${DIVIDER};
    color: white;
    overflow: hidden;
    background-color: ${DIVIDER}16;
    outline: 0;
    justify-content: center;
    align-items: center;
    display: flex;

    &:hover {
        div {
            background-color: #00000033;
            svg {
                opacity: 1;
            }
        }
    }
`;

export const IconContainer = styled.div`
    top: 0;
    left: 0;
    width: 3.5rem;
    height: 3.5rem;
    outline: 0;
    pointer-events: none;
    user-select: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 500ms ease;
    
    * {
        outline: 0;
        pointer-events: none;
        user-select: none;
    }
    
    svg {
        opacity: 0;
    }
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
`;
