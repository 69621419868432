import { StyledFooter, Links, StyledLink } from './styled';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooter>
            <div>
                ©
                {' '}
                {currentYear}
                , Made with ❤️ by Voxity
            </div>
            <Links>
                <StyledLink href="https://www.voxity.ai/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy Policy
                </StyledLink>
                <StyledLink href="https://www.voxity.ai/terms-of-service" target="_blank" rel="noreferrer">
                    Terms of Service
                </StyledLink>
            </Links>
        </StyledFooter>
    );
};

export default Footer;
