import {
    useCallback, useRef, useState,
} from 'react';
import { InternalOverlay } from 'react-rainbow-components';
import { useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import Filter from 'components/icons/filter';
import ButtonIcon from 'components/ButtonIcon';
import Close from 'components/icons/close';
import positionResolver from './resolver';
import {
    DropDown, Header,
    StyledCheckboxGroup,
} from './styled';

const options = [
    { value: 'urgent', label: 'Urgent' },
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
    { value: 'low', label: 'Low' },
];

interface TaskPriorityFilterProps {
    value?: string[];
    onChange?: (newValue: string[]) => void;
}

const TaskPriorityFilter = ({
    value: valueInProps = [],
    onChange = () => {},
}: TaskPriorityFilterProps) => {
    const buttonRef = useRef();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    useWindowResize(() => setOpen(false), isOpen);
    useOutsideClick(dropdownRef, () => setOpen(false), isOpen);

    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

    const close = useCallback(() => setOpen(false), []);

    return (
        <>
            <ButtonIcon
                ref={buttonRef}
                onClick={toggle}
                icon={<Filter />}
            />
            <InternalOverlay
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => buttonRef.current.htmlElementRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <Header>
                        Filter by Priority
                        <ButtonIcon
                            icon={<Close />}
                            onClick={close}
                        />
                    </Header>
                    <StyledCheckboxGroup
                        value={valueInProps}
                        onChange={onChange}
                        orientation="vertical"
                        options={options}
                    />
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default TaskPriorityFilter;
