/* eslint-disable react/no-unused-prop-types */
import { ReactNode } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Option, RenderIf } from 'react-rainbow-components';
import { PicklistProps } from 'react-rainbow-components/components/Picklist';
import Picklist from 'components/Picklist';
import {
    Container, PickListGlobalStyle, StyledInput,
} from './styled';

interface PicklistValue {
    label?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

interface PicklistWithCustomOptionProps extends PicklistProps {
    id?: string;
    className?: string;
    name?: string;
    customOptionLabel?: string;
    customValuePlaceHolder?: string;
    value?: PicklistValue;
    onChange?: (value: PicklistValue) => void;
    children?: ReactNode;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    customInputComponent?: any;
}

const DefaultInput = ({
    value,
    placeholder,
    required = false,
    error,
    onChange = () => {},
}: {
    value?: any;
    placeholder?: string;
    required?: boolean;
    error?: string;
    onChange?: (value: any) => void;
}) => (
    <StyledInput
        borderRadius="semi-square"
        value={value}
        placeholder={placeholder}
        required={required}
        error={error}
        autoComplete="off"
        onChange={(event) => onChange(event.target.value)}
    />
);

const PicklistWithCustomOption = (props: PicklistWithCustomOptionProps) => {
    const {
        id,
        className,
        name,
        label,
        customOptionLabel = 'Custom',
        customValuePlaceHolder = 'Enter custom value',
        value: valueInProps,
        onChange = () => {},
        error,
        children = [],
        placeholder,
        tabIndex,
        required,
        customInputComponent: CustomInput = DefaultInput,
    } = useReduxForm(props);

    return (
        <Container
            id={id}
            className={className}
        >
            <PickListGlobalStyle />
            <Picklist
                name={name}
                label={label}
                labelAlignment="left"
                value={valueInProps}
                placeholder={placeholder}
                onChange={onChange}
                tabIndex={tabIndex}
                required={required}
                error={valueInProps?.name !== 'custom' && error}
            >
                {children}
                <Option name="custom" label={customOptionLabel} />
            </Picklist>
            <RenderIf isTrue={valueInProps?.name === 'custom'}>
                <CustomInput
                    value={valueInProps?.value}
                    placeholder={customValuePlaceHolder}
                    required={required}
                    error={error}
                    onChange={(inputValue: any) => onChange({ name: 'custom', label: customOptionLabel, value: inputValue })}
                />
            </RenderIf>
        </Container>
    );
};

export default PicklistWithCustomOption;
