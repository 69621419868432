import styled, { css } from 'styled-components';
import { ButtonMenu } from 'react-rainbow-components';
import { GREY_400 } from '../../constants';

export default styled(ButtonMenu)`
    flex-shrink: 0;

    button {
        width: 3rem;
        height: 3rem;
    }

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }

    ${(props) => props.buttonSize === 'small' && css`
        width: 2rem;
        height: 2rem;

        svg {
            width: 1.35rem !important;
            height: 1.35rem !important;
            font-size: 1.35rem !important;
        }
    `}


    ${(props) => props.buttonVariant === 'base' && css`
        color: ${GREY_400};
    `}


    ${(props) => props.borderRadius === 'semi-square' && css`
        button {
            border-radius: 0.375rem;
        }
    `}

    ${(props) => props.borderRadius === 'semi-rounded' && css`
        button {
            border-radius: 0.5rem;
        }
    `}
`;
