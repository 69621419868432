import styled from 'styled-components';
import {
    RED_500, PURPLE_500, GREEN_500, ORANGE_500,
} from '../../constants';

export const StyledDot = styled.div<{ variant: 'brand' | 'success' | 'error' | 'warning' | 'orange' }>`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin: 0 0.25rem 0;
    flex-shrink: 0;

    ${(props) => props.variant === 'brand'
        && `
        background-color: ${PURPLE_500};
    `}

    ${(props) => props.variant === 'success'
        && `
        background-color: ${GREEN_500};
    `}

    ${(props) => props.variant === 'warning'
        && `
        background-color: rgba(248, 197, 54, 0.65);
    `}

    ${(props) => props.variant === 'error'
        && `
        background-color: ${RED_500};
    `}

    ${(props) => props.variant === 'orange'
        && `
        background-color: ${ORANGE_500};
    `}
`;
