import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil2';
import useHttpMutation from 'data/firestore/useHttpMutation';
import DeleteModal from '../deleteModal';
import { ActionsCellWrapper } from './styled';

interface Props {
    row?: Record<string, string>;
    agentId: string;
    onEdit?: (row: Record<string, string>) => void;
    onDelete?: () => void;
}

const ActionsColumn = ({
    row = {},
    agentId = '',
    onEdit = () => {},
    onDelete = () => {},
}: Props) => {
    const {
        mutateAsync: deleteMember,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/team/${agentId}/member/${row.uid}`,
        method: 'DELETE',
        onSuccess: onDelete,
    });

    const requestDeleteMember = useCallback(
        async ({ uid, email }: Record<string, string>) => {
            if (!uid) {
                return;
            }

            const result = await confirmModal({
                header: '',
                question: <DeleteModal />,
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteMember({});
                showAppNotification({
                    title: 'Member Removed',
                    description: `${email} has been removed.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [deleteMember],
    );

    return (
        <ActionsCellWrapper>
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Pencil />}
                tooltip="Edit member role"
                disabled={row?.role === 'owner'}
                size="small"
                onClick={() => onEdit(row)}
            />
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Trash />}
                tooltip="Remove member"
                disabled={row?.role === 'owner'}
                size="small"
                onClick={() => requestDeleteMember(row)}
            />
        </ActionsCellWrapper>
    );
};

export default ActionsColumn;
