import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { limit as limitFn, query, where } from 'firebase/firestore';
import useCalendars from 'data/firestore/agent/calendar/useCollection';
import useCalendarEvents from 'data/firestore/agent/calendarevent/useCollection';

interface UsePendingApprovalEventsCount {
    limit?: number;
}

const usePendingApprovalEventsCount = ({ limit = 100 }: UsePendingApprovalEventsCount = {}) => {
    const { agentId } = useParams<{ agentId: string; }>();
    const [count, setCount] = useState<number | null>(null);
    const { data: calendars = [] } = useCalendars(String(agentId), { track: [agentId] });
    const { data: calendarEvents = [] } = useCalendarEvents(String(agentId), {
        track: [agentId],
        listQueryFn: (ref) => query(
            ref,
            where('status.name', '==', 'pending-approval'),
            where('status.removable', '==', false),
            where('removed', '==', false),
            limitFn(limit),
        ),
    });

    useEffect(() => {
        // eslint-disable-next-line max-len
        if (calendars && calendarEvents) {
            const calendarsIds = calendars.map((calendar) => calendar.id);
            setCount(calendarEvents.filter(
                (event) => calendarsIds.includes(event.calendarId),
            ).length);
        }
    }, [calendarEvents, calendars]);

    return { count };
};

export default usePendingApprovalEventsCount;
