import { useMemo } from 'react';
import useAgentData from './useAgentData';

const useAgentTimeZone = () => {
    const { agent } = useAgentData();

    return useMemo(
        () => agent?.companyAddress.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        [agent?.companyAddress.timezone],
    );
};

export default useAgentTimeZone;
