import { ReactNode, RefObject, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Close from 'components/icons/close';
import ChevronLeft from 'components/icons/chevronLeft';
import UserAvatar from 'components/UserAvatar';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/globals';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import ListTile from './ListTile';
import {
    CloseButton, Container, Content, Header, Footer,
} from './styled';
import ChatInput from './chatIinput';

interface SMSChatLayoutProps {
    children: ReactNode;
    hideInput?: boolean;
    customerData: EntityGet<Customer>;
    onRequestClose?: () => void;
    onBackClick?: () => void;
    inputRef?: RefObject<any>,
    onTextSend?: (value: string) => void;
}

const SMSChatLayout = ({
    customerData,
    onRequestClose = () => {},
    hideInput = false,
    children,
    inputRef,
    onTextSend = () => {},
    onBackClick = () => {},
}: SMSChatLayoutProps) => {
    const customerDisplayName = useMemo(
        () => getDisplayName(customerData),
        [customerData],
    );
    return (
        <Container>
            <Header>
                <ButtonIcon
                    icon={<ChevronLeft />}
                    onClick={onBackClick}
                />
                <ListTile
                    icon={(
                        <UserAvatar
                            initials={getInitials(customerDisplayName)}
                            src={customerData?.photoURL}
                            value={customerData?.id}
                        />
                    )}
                    title={customerDisplayName}
                    subtitle={formatIntlPhoneNumber((customerData?.phoneNumber as string) || '')}
                />
                <CloseButton icon={<Close />} onClick={onRequestClose} />
            </Header>
            <Content>
                {children}
            </Content>
            <RenderIf isTrue={!hideInput}>
                <Footer>
                    <ChatInput
                        ref={inputRef}
                        onTextSend={onTextSend}
                    />
                </Footer>
            </RenderIf>
        </Container>
    );
};

export default SMSChatLayout;
