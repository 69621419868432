import { useCallback, useMemo } from 'react';
import useSearchFilters from './useSearchFilters';

interface Params {
    defaultPage?: number;
    maxPages?: number;
}

const useQueryPaging = ({
    defaultPage = 1,
    maxPages,
}: Params) => {
    const {
        getValue,
        setValue,
    } = useSearchFilters({
        filterNames: ['page'],
        defaultValues: { page: defaultPage.toString() },
    });

    const gotoPage = useCallback(
        (page: number) => setValue(
            'page',
            (
                page === 1
                    ? null
                    : page.toString()
            ),
        ),
        [setValue],
    );

    const activePage = useMemo(
        () => {
            try {
                const page = parseInt(getValue('page') || defaultPage.toString(), 10);
                return page;
            } catch (error) {
                // no catch
            }

            return defaultPage;
        },
        [defaultPage, getValue],
    );

    return useMemo(
        () => ({
            activePage,
            nextPage: () => gotoPage(Math.min(maxPages || Infinity, activePage + 1)),
            prevPage: () => gotoPage(Math.max(activePage, activePage - 1)),
            gotoPage,
        }),
        [activePage, gotoPage, maxPages],
    );
};

export default useQueryPaging;
