import styled from 'styled-components';
import Badge from 'components/Badge';
import Card from 'components/Card';
import Facebook from 'components/icons/facebook';
import Instagram from 'components/icons/instagram';
import {
    GREY_800, PURPLE_100, GREY_400, GREY_300,
} from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    box-sizing: border-box;
`;

export const Header = styled.div`
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    z-index: 8;
`;

export const MetaAccountsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
`;

export const ListHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-weight: 700;
`;

export const Column = styled.div`
    flex: 1;
    max-width: 33.33%;
`;

export const Row = styled(Card)`
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0.25rem 0;
`;

export const AccountNameContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h1`
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.75rem;
`;

export const StyledCard = styled(Card)`
    flex: 1;
    padding: 1.5rem;
    width: 100%;
`;

export const CardTitle = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;
export const CardSubtitle = styled.h3`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
    margin-top: 0.375rem;
`;

export const FacebookIcon = styled(Facebook)`
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
`;

export const InstagramIcon = styled(Instagram)`
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
`;

export const AccountLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 8rem;
`;

export const ActionsContainer = styled.div`
    display: flex;
    align-content: center;
    gap: 1rem;
`;

export const ModalDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin-top: 1rem;
`;

export const SocialInfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0.6rem 3rem 1rem 1.5rem;
    gap: 0.625rem;
`;

export const UserName = styled.p`
    color: ${GREY_800};
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.375rem;
`;

export const Platform = styled.p`
    color: ${GREY_300};
    border-radius: 4px;
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
`;

export const AccountPlatformInfo = styled(Badge)`
    display: flex;
    font-size: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    padding: 0 0.5rem 0 0;
    color: ${GREY_400};
    background: ${PURPLE_100};
    
    & > span {
        display: flex;
        align-items: center;    
        gap: 0.5rem;
    }
`;

export const IconContainer = styled.div`
    display: inline-flex;
    box-sizing: border-box;

    svg {
        display: flex;
        width: 1rem !important;
        height: 1rem !important;
    }
`;

export const AccountLabel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const CheckboxToggleLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;
