import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';
import Badge from 'components/Badge';
import { GREY_800 } from '../../constants';

export const StyledSpinner = styled(Spinner)`
    position: unset;
    z-index: unset;
    transform: unset;
    left: unset;
    top: unset;
    width: 1rem;
    height: 1rem;
    padding: 1px;
    margin-right: 0.25rem;
`;

interface ValidationStatusProps {
    variant?: 'success' | 'error' | 'loading';
}

export const ValidationStatusSpan = styled.span.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<ValidationStatusProps>`
    color: ${(props) => {
        if (props.variant === 'success') {
            return '#34A353';
        } if (props.variant === 'error') {
            return '#E54335';
        }
        return GREY_800;
    }};
    display: flex;
    align-items: center;
`;

export const BadgeErrorCount = styled(Badge)`
    border-radius: 4px;
    padding: 5px 8px 5px 8px;
`;
