import { useMemo } from 'react';
import getStageTypeColor from '../../helpers/getStageColor';
import { Column, OpportunityCard } from './types';
import {
    StageCardsCount, StageColor, StageInfoContainer, StageTitle,
} from './styled';

const StageInfo = ({
    title,
    column,
    cards,
}: {
    title?: string;
    column: Column;
    cards: OpportunityCard[],
}) => {
    const { type } = column.data || {};

    const color = useMemo(
        () => getStageTypeColor(type),
        [type],
    );

    return (
        <StageInfoContainer>
            <StageColor color={color} />
            <StageTitle isBuiltin={!column.data?.removable}>{title}</StageTitle>
            <StageCardsCount label={cards.length} />
        </StageInfoContainer>
    );
};

export default StageInfo;
