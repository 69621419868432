import { IconProps } from 'types';

const Refresh = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M6 12.1644C6 8.75992 8.75992 6 12.1644 6C13.9531 6 15.5648 6.76164 16.6916 7.98025H14.5526C14.0003 7.98025 13.5526 8.42796 13.5526 8.98025C13.5526 9.53253 14.0003 9.98025 14.5526 9.98025H18.5328C19.0851 9.98025 19.5328 9.53253 19.5328 8.98025V5C19.5328 4.44772 19.0851 4 18.5328 4C17.9805 4 17.5328 4.44772 17.5328 5V6.01319C16.0981 4.76004 14.2204 4 12.1644 4C7.65535 4 4 7.65535 4 12.1644C4 16.6735 7.65535 20.3289 12.1644 20.3289C15.7209 20.3289 18.7437 18.0555 19.8641 14.8858C20.0481 14.3651 19.7752 13.7938 19.2545 13.6098C18.7338 13.4257 18.1624 13.6986 17.9784 14.2193C17.1317 16.615 14.8469 18.3289 12.1644 18.3289C8.75992 18.3289 6 15.569 6 12.1644Z" fill="currentColor" />
    </svg>
);

export default Refresh;
