import {
    ImgHTMLAttributes, useCallback, useRef, useState,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import ZoomedImage from './zoomedImage';
import { StyledImage } from './styled';

interface ZoomableImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    className?: string;
    src: string;
    alt?: string;
    width: number;
    height: number;
}

const ZoomableImage = ({
    className,
    src,
    alt = '',
    width,
    height,
}: ZoomableImageProps) => {
    const ref = useRef<HTMLImageElement>(null);
    const imageRect = useRef<DOMRect>();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = useCallback(
        () => {
            imageRect.current = ref.current?.getBoundingClientRect();
            setIsOpen(true);
        },
        [],
    );

    const close = useCallback(
        () => {
            setIsOpen(false);
        },
        [],
    );

    return (
        <>
            <StyledImage
                className={className}
                src={src}
                alt={alt}
                width={width}
                height={height}
                ref={ref}
                isOpen={isOpen}
                onClick={open}
            />
            <RenderIf isTrue={isOpen}>
                <ZoomedImage src={src} alt={alt} close={close} originalRect={imageRect.current} />
            </RenderIf>
        </>
    );
};

export default ZoomableImage;
