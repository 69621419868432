import { DateTime } from 'luxon';

const getEventStartAtFromMinutes = ({
    minute,
    selectedDate = Date.now(),
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: {
    minute: number,
    selectedDate?: Date | number,
    timeZone?: string;
}) => {
    const quarterMin = Math.floor(minute / 15) * 15;
    const hours = Math.floor(quarterMin / 60);
    const minutes = quarterMin - hours * 60;
    const date = new Date(selectedDate);
    date.setHours(hours, minutes, 0, 0);
    return DateTime.fromJSDate(date).setZone(timeZone, { keepLocalTime: true }).toJSDate();
};

export default getEventStartAtFromMinutes;
