import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';

interface DeleteModalProps {
    name: string;
}

const DeleteModal = ({ name }: DeleteModalProps) => (
    <ModalBody
        title={`Are you sure you want to delete the api key "${name}"?`}
        description="Please confirm that you want to delete the selected api key. If you delete this api key, it will be permanently removed from your agent."
        icon={<Trash />}
        variant="destructive"
    />
);

export default DeleteModal;
