import { IconProps } from 'types';

const CalendarIcon = ({ className }: IconProps) => (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="6" fill="#FFF7F0" />
        <rect x="10.6667" y="11.8335" width="18.6667" height="18.6667" rx="2" stroke="#FFA266" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.6667 9.5V14.1667" stroke="#FFA266" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.3333 9.5V14.1667" stroke="#FFA266" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.6667 18.8333H29.3333" stroke="#FFA266" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.8333 23.4998H20" stroke="#FFA266" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20 23.5V27" stroke="#FFA266" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default CalendarIcon;
