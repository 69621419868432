/* eslint-disable jsx-a11y/aria-role */
import {
    useCallback, useMemo, useState,
    ChangeEvent, forwardRef,
    useEffect,
    useRef,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import ButtonIcon from 'components/ButtonIcon';
import MinusCircle from 'components/icons/minusCircle';
import CirclePlusIcon from 'components/icons/plusCircle';
import RotateClockwise from 'components/icons/rotateClockwise';
import LoadingShape from 'components/LoadingShape';
import switchRole from './helpers/switchRole';
import {
    Container,
    InitChatButton,
    ChatMessageContainer,
    ChatMessageRole,
    ChatMessageContent,
    ChatMessagesList,
    Footer,
    StyledTextarea,
} from './styled';
import { ChatMessage as Message } from '../../types';

type ChatMessageType = Pick<Message, 'content' | 'role'> & {
    id?: string;
    isLoading?: boolean;
    disabled?: boolean;
    onRemove?: (id: string) => void;
    onRoleChange?: (role: Message['role']) => void;
    onContentChange?: (value: string) => void;
};

const ChatMessage = forwardRef<HTMLTextAreaElement, ChatMessageType>(
    (props, ref) => {
        const {
            id = '',
            role,
            content = '',
            isLoading = false,
            disabled = false,
            onRemove = () => {},
            onRoleChange = () => {},
            onContentChange = () => {},
        } = props;
        const [isFocused, setFocused] = useState(false);

        const handleBlur = useCallback(
            () => setFocused(false),
            [],
        );

        const handleFocus = useCallback(
            () => setFocused(!disabled),
            [disabled],
        );

        const handleContentChange = useCallback(
            (event: ChangeEvent<HTMLTextAreaElement>) => onContentChange(event.target.value),
            [onContentChange],
        );

        const handleChangeRole = useCallback(
            () => onRoleChange(switchRole(role)),
            [onRoleChange, role],
        );

        return (
            <ChatMessageContainer>
                <ChatMessageRole onClick={handleChangeRole}>
                    {role}
                </ChatMessageRole>
                <RenderIf isTrue={isLoading}>
                    <ChatMessageContent>
                        <LoadingShape width="100%" height="15px" />
                    </ChatMessageContent>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <StyledTextarea
                        ref={ref}
                        value={content}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleContentChange}
                        hideLabel
                        rows={1}
                        borderRadius="semi-rounded"
                        grow
                        readOnly={!isFocused || disabled}
                    />
                    <ButtonIcon
                        icon={<MinusCircle />}
                        borderRadius="semi-rounded"
                        size="small"
                        disabled={disabled}
                        onClick={() => onRemove(id)}
                    />
                </RenderIf>
            </ChatMessageContainer>
        );
    },
);

const ChatView = ({
    isLoading = false,
    messages = [],
    initialized = false,
    disabled = false,
    onChatInit = () => {},
    onAddMessage = () => {},
    onUpdateMessage = () => {},
    onDeleteMessage = () => {},
    onSubmit = () => {},
    onReset = () => {},
}: {
    isLoading?: boolean;
    disabled?: boolean;
    messages?: Message[];
    initialized?: boolean;
    onChatInit?: () => void;
    onAddMessage?: (value?: Message) => void;
    onUpdateMessage?: (messageId: string, data: Record<string, string>) => void;
    onDeleteMessage?: (id: string) => void;
    onSubmit?: () => void;
    onReset?: () => void;
}) => {
    const lastMessageRef = useRef<HTMLTextAreaElement>(null);

    const messagesList = useMemo(
        () => messages.map((message) => (
            <ChatMessage
                ref={lastMessageRef}
                key={`chat-message__${message.id}`}
                {...message}
                onRemove={onDeleteMessage}
                onContentChange={
                    (value: string) => onUpdateMessage(message.id, { content: value })
                }
                onRoleChange={
                    (value: string) => onUpdateMessage(message.id, { role: value })
                }
                disabled={disabled}
            />
        )),
        [messages, disabled, onDeleteMessage, onUpdateMessage],
    );

    useEffect(
        () => {
            if (isLoading && lastMessageRef.current) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lastMessageRef.current?.fieldRef.current.blur();
            }
            return () => {
                if (!isLoading && lastMessageRef.current) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    lastMessageRef.current?.fieldRef.current.focus();
                }
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, messages.length],
    );

    useEffect(
        () => {
            if (lastMessageRef.current) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lastMessageRef.current?.fieldRef.current.blur();
            }
        },
        [],
    );

    if (!initialized) {
        return (
            <Container className="rainbow-p-vertical_large rainbow-p-horizontal_small">
                <InitChatButton
                    variant="brand"
                    borderRadius="semi-rounded"
                    label="Start Conversation"
                    onClick={onChatInit}
                />
            </Container>
        );
    }

    return (
        <Container>
            <ChatMessagesList>
                {messagesList}
                <RenderIf isTrue={isLoading}>
                    <ChatMessage
                        role="assistant"
                        content=""
                        isLoading
                    />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <div className="rainbow-m-top_xx-small">
                        <Button
                            variant="base"
                            borderRadius="semi-rounded"
                            size="small"
                            disabled={disabled}
                            onClick={() => onAddMessage()}
                        >
                            <CirclePlusIcon className="rainbow-m-right_xx-small" />
                            Add Message
                        </Button>
                    </div>
                </RenderIf>
            </ChatMessagesList>
            <Footer>
                <Button
                    variant="brand"
                    borderRadius="semi-rounded"
                    label="Submit"
                    disabled={isLoading || disabled}
                    onClick={onSubmit}
                />
                <Button
                    variant="base"
                    borderRadius="semi-rounded"
                    disabled={isLoading || disabled}
                    onClick={onReset}
                >
                    <RotateClockwise className="rainbow-m-right_xx-small" />
                    Reset Chat
                </Button>
            </Footer>
        </Container>
    );
};

export default ChatView;
