import styled from 'styled-components';
import Input from 'components/Input';
import Card from 'components/Card';

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
`;

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;
