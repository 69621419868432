import { ReactNode } from 'react';
import { To } from 'react-router-dom';
import Footer from 'components/Footer';
import useRedirectOnMissingRoles from 'hooks/useRedirectWhenMissingRoles';
import { Role } from 'types';

interface BasicPageLayoutProps {
    children: ReactNode;
    requiredRoles?: Role[];
    redirectTo?: To;
}

const BasicPageLayout = ({
    children,
    requiredRoles = [],
    redirectTo,
}: BasicPageLayoutProps) => {
    useRedirectOnMissingRoles({ requiredRoles, redirectTo });

    return (
        <>
            {children}
            <Footer />
        </>
    );
};

export default BasicPageLayout;
