import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { PAGE_SIZE } from '../constants';

/**
 * A hook to handle client-side pagination.
 * @param data The data to paginate. Must come from a state or be memoized
 * to avoid infinite renders.
 */
const useClientPagination = (data: any[]) => {
    const [activePage, setActivePage] = useState(1);

    const gotoPage = useCallback(
        (page: number) => {
            setActivePage(page);
        },
        [setActivePage],
    );

    useEffect(() => {
        gotoPage(1);
    }, [data, gotoPage]);

    const pageData = useMemo(
        () => {
            const start = Math.min(PAGE_SIZE * (activePage - 1), data.length);
            const end = Math.min(PAGE_SIZE * activePage, data.length);
            const dataToShow = data.slice(start, end);

            return ({
                dataToShow,
                totalHits: data.length,
                pages: Math.ceil(data.length / PAGE_SIZE),
                activePage,
                firstItemOfActivePage: start + 1,
                lastItemOfActivePage: end,
            });
        },
        [activePage, data],
    );

    return {
        ...pageData,
        gotoPage,
    };
};

export default useClientPagination;
