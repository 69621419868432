import { IconProps } from 'types';

const Transferred = ({ className, title = 'Transferred' }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M18 6H3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 16L19 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 9L19 6L16 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 13L3 16L6 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

    </svg>

);
export default Transferred;
