import { useEffect, useState } from 'react';
import {
    ref, getMetadata, getDownloadURL,
} from 'firebase/storage';
import { storage } from '../../../firebase';

interface Props {
    agentId: string;
    customerId: string;
    media: {
        url: any;
        contentType: any;
        size: number;
    }[];
}

const useMediaFiles = ({ agentId, customerId, media }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<Record<string, unknown>[]>([]);

    useEffect(() => {
        (async () => {
            if (!Array.isArray(media)) return;
            setIsLoading(true);
            const promises = media.map(async (m) => {
                try {
                    const fileRef = ref(storage, m.url);
                    const metadata = await getMetadata(fileRef);
                    const url = await getDownloadURL(fileRef);
                    return {
                        ...metadata,
                        url,
                    };
                } catch {
                    return null;
                }
            });
            const filesData = (
                await Promise.all(promises)
            ).filter((f): f is NonNullable<typeof f> => f !== null);
            setIsLoading(false);
            setFiles(filesData);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentId, customerId]);

    return { isLoading, files };
};

export default useMediaFiles;
