export const createDownloadLink = async (url: string, fileName: string | null) => {
    const response = await fetch(url);
    const href = URL.createObjectURL(await response.blob());
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName || 'file');
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.parentNode?.removeChild(link);
};
