import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAA2tswRNt1fCjz4jiYl6SlnKyb9zP5NPw",
  authDomain: "voxity-ai.firebaseapp.com",
  projectId: "voxity-ai",
  storageBucket: "voxity-ai.appspot.com",
  messagingSenderId: "115595701574",
  appId: "1:115595701574:web:9be9b8e33c0af8092d8947",
  measurementId: "G-F6W1HN9RDW",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
