import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';
import useHttpMutation from 'data/firestore/useHttpMutation';
import ModalTitle from 'components/ModalTitle';
import { ADD_EDIT_CALENDAR_EVENT_TYPE_NOTIFICATION_RULE } from '../../../../../constants';

const mapInitialValues = (values?: Record<string, unknown>) => {
    if (values) {
        const { when, relativeTo, ...rest } = values;
        return {
            when: {
                value: {
                    when,
                    relativeTo,
                },
            },
            ...rest,
        };
    }

    return values;
};

const mapValues = (values: Record<string, unknown>) => {
    const { when: whenValue, instructions, messageExample } = values;
    const {
        when,
        relativeTo,
    } = (whenValue as Record<string, unknown>).value as Record<string, unknown>;
    return {
        when,
        relativeTo,
        instructions,
        messageExample,
    };
};

const useManageNotificationRules = ({
    agentId,
    eventTypeId,
} : {
    agentId: string;
    eventTypeId: string;
}) => {
    const [
        openNotificationRuleForm,
        closeNotificationRuleForm,
    ] = useOpenModal(ADD_EDIT_CALENDAR_EVENT_TYPE_NOTIFICATION_RULE);

    const { mutateAsync: createNotificationRules } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/event-types/${eventTypeId}/notification-rules`,
        onSuccess: closeNotificationRuleForm,
    });

    const { mutateAsync: updateNotificationRules } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeNotificationRuleForm,
    });

    const { mutateAsync: deleteNotificationRules } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateNotificationRule = useCallback(
        (initialValues: Record<string, unknown>) => openNotificationRuleForm({
            title: <ModalTitle>Add New Reminder</ModalTitle>,
            message: 'Customize reminder timings and messages to effectively notify attendees.',
            submitButtonLabel: 'Add',
            initialValues: mapInitialValues(initialValues),
            onSubmit: async (values: Record<string, unknown>) => {
                const {
                    when, relativeTo, instructions, messageExample,
                } = mapValues(values);
                showAppSpinner();
                try {
                    await createNotificationRules({
                        body: {
                            when,
                            relativeTo,
                            instructions,
                            messageExample,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [createNotificationRules, openNotificationRuleForm],
    );

    const handleEditNotificationRule = useCallback(
        (eventData: {
            id: string;
            when: number;
            instructions: string;
            relativeTo: 'event-time' | 'creation-time';
            messageExample?: string;
        }) => openNotificationRuleForm({
            id: eventData.id,
            title: <ModalTitle>Edit Reminder</ModalTitle>,
            message: 'Adjust the timing and message content for the selected reminder.',
            submitButtonLabel: 'Update',
            initialValues: mapInitialValues(eventData),
            onSubmit: async (values: Record<string, unknown>) => {
                const {
                    when: newWhen,
                    relativeTo: newRelativeTo,
                    instructions: newInstructions,
                } = mapValues(values);
                showAppSpinner();
                try {
                    await updateNotificationRules({
                        pathname: `/agents/${agentId}/event-types/${eventTypeId}/notification-rules/${eventData.id}`,
                        body: {
                            when: newWhen,
                            relativeTo: newRelativeTo,
                            instructions: newInstructions,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, eventTypeId, openNotificationRuleForm, updateNotificationRules],
    );

    const handleDeleteNotificationRule = useCallback(
        async (eventId: string) => {
            const result = await confirmModal({
                header: '',
                question: (
                    <ModalBody
                        icon={<Trash />}
                        title="Remove Reminder?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteNotificationRules({
                    pathname: `/agents/${agentId}/event-types/${eventTypeId}/notification-rules/${eventId}`,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deleteNotificationRules, eventTypeId],
    );

    return {
        create: handleCreateNotificationRule,
        edit: handleEditNotificationRule,
        remove: handleDeleteNotificationRule,
    };
};

export default useManageNotificationRules;
