import { Responder } from 'data/firestore/agent/responder/types';
import { EntityGet } from 'data/firestore/types';
import { CellWrapper } from '../styled';
import { ButtonsContainer, StyledCopyToClipboardButton, StyledSpan } from './styled';

interface Props {
    value?: EntityGet<Responder>['url'];
}

const UrlColumn = ({ value }: Props) => (
    <CellWrapper>
        <StyledSpan>
            {value}
        </StyledSpan>
        <ButtonsContainer>
            <StyledCopyToClipboardButton mode="icon" value={value || ''} borderRadius="semi-square" />
        </ButtonsContainer>
    </CellWrapper>
);

export default UrlColumn;
