import { MouseEvent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { BoardCard, KanbanBoardCardComponent } from '../types';
import { StyledCard, CardContainer } from './styled';

interface KanbandBoardCardProps extends BoardCard {
    component?: KanbanBoardCardComponent;
    index: number;
}

const DefaultCard = ({
    title,
}: BoardCard) => (
    <StyledCard>
        {title}
    </StyledCard>
);

const KanbandBoardCard = ({
    component: Component = DefaultCard,
    index,
    ...rest
}: KanbandBoardCardProps) => {
    const { id } = rest;
    return (
        <Draggable key={id} draggableId={id} index={index}>
            {(provided, snapshot) => (
                <CardContainer
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    onClick={(e: MouseEvent<HTMLDivElement>) => {
                        if (!e.currentTarget.contains(document.activeElement)) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            document.activeElement?.blur();
                        }
                    }}
                >
                    <Component {...rest} />
                </CardContainer>
            )}
        </Draggable>
    );
};

export default KanbandBoardCard;
