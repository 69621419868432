import { Snippet } from '../types';
import ifSnippet from './if';
import ifelSnippet from './ifel';
import eachSnippet from './each';
import elseSnippet from './else';
import partialSnippet from './partial';
import unlessSnippet from './unless';
import withSnippet from './with';
import equalSnippet from './equal';
import greaterThanSnippet from './greaterThan';
import greaterThanEqualSnippet from './greaterThanEqual';
import lessThanSnippet from './lessThan';
import lessThanEqualSnippet from './lessThanEqual';

const snippets: Snippet[] = [
    ifSnippet,
    ifelSnippet,
    eachSnippet,
    elseSnippet,
    partialSnippet,
    unlessSnippet,
    withSnippet,
    equalSnippet,
    greaterThanSnippet,
    greaterThanEqualSnippet,
    lessThanSnippet,
    lessThanEqualSnippet,
];

export default snippets;
