import { IconProps } from 'types';

const FilterIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        width={24}
        height={24}
    >
        <title>{title}</title>
        <path d="M4 6H20" stroke="currentColor" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 12H16" stroke="currentColor" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 18H14" stroke="currentColor" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default FilterIcon;
