import Audio from '../Audio';
import Generic from '../Generic';
import Image from '../Image';
import Video from '../Video';

type ComponentKey = 'audio' | 'video' | 'image';

const componentsMap = {
    audio: Audio,
    image: Image,
    video: Video,
};

const getMediaComponent = (contentType?: string | null) => {
    const componentKey = Object.keys(componentsMap).find((key) => contentType?.includes(key));
    if (componentKey) {
        return componentsMap[componentKey as ComponentKey];
    }
    return Generic;
};

export default getMediaComponent;
