import { ReactNode, useEffect, useRef } from 'react';
import isInViewport from './helpers/isInViewport';
import { ItemContainer } from './styled';

interface Params extends Record<string, unknown> {
    children?: ReactNode;
}

const AvailableTime = ({
    children,
    ...rest
}: Params) => {
    const elRef = useRef<HTMLDivElement>(null);
    const { isSelected } = rest;

    useEffect(
        () => {
            if (isSelected && !isInViewport(elRef)) {
                elRef.current?.scrollIntoView();
            }
        },
        [isSelected],
    );

    return (
        <ItemContainer {...rest} ref={elRef}>
            {children}
        </ItemContainer>
    );
};

export default AvailableTime;
