import { useCallback, useMemo } from 'react';
import { Option } from 'react-rainbow-components';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { PicklistProps } from 'react-rainbow-components/components/Picklist';
import CalendarIcon from 'components/icons/calendarThin';
import { StyledPicklist } from './styled';

interface Props extends Omit<PicklistProps, 'value' | 'onChange'> {
    value?: string;
    onChange?: (value: string) => void;
}

const options = [
    {
        name: 'last-12-months',
        label: 'Last 12 Months',
        value: 'last-12-months',
    },
    {
        name: 'current-year',
        label: 'Current Year',
        value: 'current-year',
    },
    {
        name: 'last-year',
        label: 'Last Year',
        value: 'last-year',
    },
];

const MonthlyBookingsInsightsFilters = ({
    value: valueInProps,
    onChange = () => {},
    ...rest
}: Props) => {
    const pickListvalue = useMemo(() => {
        const found = (
            valueInProps
                ? options.find((option) => option.value === valueInProps)
                : null
        ) || options[0];
        return { ...found, icon: <CalendarIcon /> };
    }, [valueInProps]);

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`monthly-bookings-insights-filter-option__${option.name}`} {...option} />),
        [],
    );

    const handleOnChange = useCallback(
        (newValue: PicklistValue) => onChange(newValue.value),
        [onChange],
    );

    return (
        <StyledPicklist
            hideLabel
            borderRadius="semi-square"
            value={pickListvalue}
            onChange={handleOnChange}
            {...rest}
        >
            {pickListOptions}
        </StyledPicklist>
    );
};

export default MonthlyBookingsInsightsFilters;
