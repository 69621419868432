import { ReactNode, useCallback, useState } from 'react';
import { showAppNotification } from '@rainbow-modules/app';
import {
    Container, Form,
} from './styled';

interface Params {
    keepDirtyOnReinitialize?: boolean;
    initialValues?: Record<string, unknown>;
    onSubmit?: (values: Record<string, unknown>) => void;
    children?: ReactNode;
}

const InstructionTestingFormStep = ({
    children,
    initialValues = {},
    onSubmit = () => {},
    keepDirtyOnReinitialize,
}: Params) => {
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = useCallback(
        async (values: Record<string, unknown>) => {
            if (isLoading) return;
            try {
                setLoading(true);
                await onSubmit(values);
            } catch (error: any) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            setLoading(false);
        },
        [isLoading, onSubmit],
    );

    return (
        <Container>
            <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            >
                {children}
            </Form>
        </Container>
    );
};

export default InstructionTestingFormStep;
