import { useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { Subscriptions } from 'data/firestore/agent/subscriptions/types';
import MailClosed from 'components/icons/mailClosed';
import Phone from 'components/icons/phone';
import Trash from 'components/icons/trash';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import {
    ItemLabelContaier,
    Label,
    OptionContainer,
    NotificationIcon,
    TrashButton,
} from './styled';

interface NotificationOptionProps {
    readOnly?: boolean;
    type: 'sms' | 'email';
    value: Subscriptions;
    onDelete?: (value: Subscriptions) => void;
}

const NotificationOption = ({
    value,
    type,
    readOnly = true,
    onDelete = () => {},
}: NotificationOptionProps) => {
    const fallBackIcon = useMemo(() => {
        if (type === 'email') return <MailClosed />;
        return <Phone />;
    }, [type]);

    const formattedValue = useMemo(() => {
        if (type === 'sms') return formatIntlPhoneNumber(value.to);
        return value.to;
    }, [type, value.to]);

    return (
        <OptionContainer>
            <ItemLabelContaier>
                <NotificationIcon
                    value={value.to}
                    icon={fallBackIcon}
                    type={type as string}
                />
                <Label>{formattedValue}</Label>
            </ItemLabelContaier>
            <RenderIf isTrue={!readOnly}>
                <TrashButton
                    variant="destructive"
                    borderRadius="semi-rounded"
                    icon={<Trash />}
                    onClick={() => onDelete(value)}
                />
            </RenderIf>
        </OptionContainer>
    );
};

export default NotificationOption;
