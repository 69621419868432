import styled, { css } from 'styled-components';
import ChevronRight from 'components/icons/chevronRight';

export const Container = styled.li`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #FFF;
    border-radius: 4px;
    padding: 2px;
    margin-bottom: 2px;

    :hover {
        background-color: #F5F5F5;
        border-radius: 4px;
        cursor: pointer;
    }
`;

interface StyledExpandedProps {
    expanded: boolean;
    disabled: boolean;
}

export const StyledChevron = styled(ChevronRight).withConfig({
    shouldForwardProp: (prop) => !['expanded', 'disabled'].includes(prop),
})<StyledExpandedProps>`
    width: 1.15rem;
    height: 1.15rem;
    transition: transform 0.25s ease-in-out 0s;
    margin-right: 0.3rem;

    ${(props) => props.expanded && css`
        transition: transform 0.25s ease-in-out 0s;
        transform: rotate(90deg);
    `}

    ${(props) => props.disabled && css`
        visibility: hidden;
    `};
`;
