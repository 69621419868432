import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { orderBy, query } from 'firebase/firestore';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { Affiliate } from 'data/firestore/agent/affiliate/types';
import useAffiliates from 'data/firestore/agent/affiliate/useCollectionWithPagination';
import { EntityGet } from 'data/firestore/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import validatePhoneNumber from 'data/services/phone/isValid';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import Table from 'components/Table';
import SearchIcon from 'components/icons/search';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import SettingIcon from 'components/icons/setting';
import Pagination from 'components/Pagination';
import DateTimeColumn from 'components/DateTimeColumn';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import { ALGOLIA_INDEX_AFFILIATES, PAGE_SIZE } from '../../../constants';
import {
    CellWrapper,
    Container, EmptyLabel, EntriesText, PaginationContainer,
    SearchContainer, SearchInput, StyledCard, TableContainer,
    StyledButtonIcon,
} from './styled';
import Fields from './form';
import ActionsColumn from './columns/actions';
import UserColumn from './columns/user';

const affiliatesUrl = process.env.REACT_APP_AFFILIATES_PAGE_URL || '';

const getLinkUrl = (agentId: string) => {
    if (agentId === '6bOSgGkSkRrQPEJAjMr6') {
        return 'https://affiliates.estrellamedicalcenters.com'; // custom URl for Estrella Medical Centers
    }
    return `${affiliatesUrl}/?agentId=${agentId}`;
};

const TeamAffiliates = () => {
    const { agentId = '' } = useParams<{ agentId: string }>();
    const [search, setSearch] = useState('');
    const [activePage, setActivePage] = useState(1);
    const connectedModalProps = useConnectModal('add-affiliate');
    const [openModal, closeModal] = useOpenModal('add-affiliate');
    const navigate = useNavigateWithQuery();

    const { mutateAsync: createAffiliate } = useHttpMutation<unknown, { message: string }>({
        method: 'POST',
        pathname: `/agents/${agentId}/affiliates`,
        onSuccess: closeModal,
    });

    const {
        data: affiliates,
        isLoading: isLoadingAffiliates,
        totalRecords: totalAffiliates,
        nextPage,
        prevPage,
    } = useAffiliates(
        agentId as string,
        {
            disabled: !agentId,
            limit: PAGE_SIZE,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', 'asc')),
            track: [agentId],
        },
    );

    const algoliaData = useAlgoliaSearchData<EntityGet<Affiliate>>({
        search,
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_AFFILIATES,
        filters: `agentId:${agentId}`,
        enabled: Boolean(search),
    });

    const addAffiliate = () => {
        openModal({
            title: '',
            onSubmit: async (
                {
                    displayName, phoneNumber: phoneNumberObj,
                }: {
                    displayName: string;
                    phoneNumber: {
                        countryCode: string;
                        phone: string;
                    };
                },
            ) => {
                const { countryCode, phone } = phoneNumberObj;
                const phoneNumber = `${countryCode}${phone}`;
                if (!validatePhoneNumber(phoneNumber)) {
                    // TODO: what to do?
                    showAppNotification({
                        title: 'Error',
                        description: 'Phone number is not valid.',
                        icon: 'error',
                        timeout: 5000,
                    });
                    return;
                }
                showAppSpinner();
                try {
                    const response = await createAffiliate({
                        body: {
                            displayName,
                            phoneNumber,
                        },
                    });
                    showAppNotification({
                        title: 'Success',
                        description: response.message,
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        });
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingAffiliates) return;
        if (page === activePage) return;
        if (!algoliaData?.hits) {
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
        }
        setActivePage(page);
    };

    useEffect(() => setActivePage(1), [search]);

    const dataToShow = algoliaData?.hits || affiliates;
    const totalHits = typeof algoliaData?.nbHits === 'number' ? algoliaData.nbHits : totalAffiliates;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    return (
        <>
            <Container>
                <StyledCard>
                    <SearchContainer>
                        <SearchInput
                            type="search"
                            placeholder="Search phone number..."
                            variant="bare"
                            icon={<SearchIcon />}
                            borderRadius="semi-square"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                        <StyledButtonIcon
                            borderRadius="semi-square"
                            icon={<SettingIcon />}
                            tooltip="Affiliate Settings"
                            onClick={() => navigate('settings')}
                        />
                        <CopyToClipboardButton
                            variant="outline-brand"
                            size="medium"
                            borderRadius="semi-square"
                            label="Copy Link"
                            value={getLinkUrl(agentId)}
                        />
                        <Button variant="brand" size="medium" borderRadius="semi-square" onClick={addAffiliate}>
                            <Plus className="rainbow-m-right_x-small" />
                            Add Affiliate
                        </Button>
                    </SearchContainer>
                    <TableContainer>
                        <Table keyField="id" data={dataToShow} isLoading={isLoadingAffiliates}>
                            <Column header="Affiliate" component={UserColumn} />
                            <Column
                                header="Created"
                                field="createdAt"
                                component={DateTimeColumn}
                                emptyLabel={(
                                    <CellWrapper>
                                        <EmptyLabel>-</EmptyLabel>
                                    </CellWrapper>
                                )}
                            />
                            <Column
                                header="Signed In"
                                field="signedInAt"
                                component={DateTimeColumn}
                                emptyLabel={(
                                    <CellWrapper>
                                        <EmptyLabel>-</EmptyLabel>
                                    </CellWrapper>
                                )}
                            />
                            <Column header="Actions" component={ActionsColumn} defaultWidth={110} />
                        </Table>
                    </TableContainer>
                    <PaginationContainer>
                        <EntriesText>
                            Showing
                            {' '}
                            {firstItemOfActivePage}
                            {' '}
                            to
                            {' '}
                            {lastItemOfActivePage}
                            {' '}
                            of
                            {' '}
                            {totalHits}
                            {' '}
                            entries.
                        </EntriesText>
                        <Pagination
                            activePage={activePage}
                            pages={pages}
                            onChange={handlePageChange}
                        />
                    </PaginationContainer>
                </StyledCard>
            </Container>
            <UniversalFormModal fields={Fields} borderRadius="semi-square" submitButtonLabel="Add Affiliate" {...connectedModalProps} />
        </>
    );
};

export default TeamAffiliates;
