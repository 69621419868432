import { useCallback } from 'react';
import { ButtonMenu, MenuItem } from 'react-rainbow-components';
import ExportIcon from 'components/icons/export';
import exportCSV from './helpers/exportCSV';

interface DataExportPickerProps {
    columns?: string[];
    data?: Record<string, unknown>[];
}

const exportMappers: Record<string, Function> = {
    csv: exportCSV,
};

const DataExportPicker = ({
    columns = [],
    data = [],
}: DataExportPickerProps) => {
    const handleExport = useCallback(
        (format: string) => {
            const exportFn = exportMappers[format];
            if (exportFn) exportFn({ columns, data });
        },
        [columns, data],
    );
    return (
        <ButtonMenu
            menuSize="x-small"
            borderRadius="semi-square"
            label="Export"
            icon={<ExportIcon />}
        >
            <MenuItem label="CSV Format" onClick={() => handleExport('csv')} />
        </ButtonMenu>
    );
};

export default DataExportPicker;
