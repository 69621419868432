import { useMemo } from 'react';
import DasboardLayout from 'layouts/Dashboard';
import LoginLayout from 'layouts/Login';
import Logo from 'components/Logo';
import TwilioConversationProvider from 'components/TwilioConversationProvider';
import { isEmpty } from '@rainbow-modules/validation';
import useUserRoles from 'hooks/useUserRoles';
import { LoginDescription, LoginHeader } from 'pages/Login/styled';
import { Role } from 'types';
import { SpinnerContainer, StyledSpinner, TextContainer } from './styled';

const useCanAccessDashboard = (): {
    isLoading: boolean;
    canAccessDashboard: boolean;
} => {
    const { isLoading = false, user, isAdmin } = useUserRoles();

    const canAccessDashboard = useMemo(() => {
        if (isAdmin) return true;
        const userAgents = user?.agents || {};
        if (isLoading || isEmpty(userAgents)) return false;
        return Object.keys(userAgents).some(
            (agentId) => ['owner', 'editor', 'viewer'].includes((userAgents as Record<string, string>)[agentId] as Role),
        );
    }, [isLoading, user, isAdmin]);

    return {
        isLoading,
        canAccessDashboard,
    };
};

const AppLayout = () => {
    const { isLoading, canAccessDashboard } = useCanAccessDashboard();

    if (isLoading) {
        return (
            <SpinnerContainer>
                <StyledSpinner variant="brand" type="arc" />
            </SpinnerContainer>
        );
    }

    if (!canAccessDashboard) {
        return (
            <LoginLayout>
                <Logo />
                <LoginHeader>
                    Thank you for signing up!
                    {' '}
                    {'\u{1F389}'}
                </LoginHeader>
                <LoginDescription>
                    We&apos;re currently setting up your account.
                    This process might take some time.
                </LoginDescription>
                <TextContainer>
                    <div>
                        Got questions? Email us at
                        {' '}
                        <a href="mailto:support@voxity.ai">support@voxity.ai</a>
                    </div>
                </TextContainer>
            </LoginLayout>
        );
    }

    return (
        <TwilioConversationProvider>
            <DasboardLayout />
        </TwilioConversationProvider>
    );
};

export default AppLayout;
