import { IconProps } from 'types';

const Insights = ({ className, title }: IconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
    >
        <title>{title}</title>
        <path d="M3 3V18C3 19.6569 4.34315 21 6 21H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M19.0015 6.9982L14.5011 11.4987L10.0006 9.49872L4.5001 19.4986" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.6296 10.9416L19.6283 6.31322L15 6.31445" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Insights;
