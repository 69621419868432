import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const Content = styled.div`
    background-color: ${color('background.main')};
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 2112px;
`;
