import { IconProps } from 'types';

const BellIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
        width={18}
        height={18}
    >
        <title>{title}</title>
        <path d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C12.2551 4.57992 13.409 6.31068 13.5 8.25V10.5C13.6145 11.4456 14.1712 12.2807 15 12.75H3C3.82884 12.2807 4.38554 11.4456 4.5 10.5V8.25C4.59103 6.31068 5.74487 4.57992 7.5 3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.75 12.75V13.5C6.75 14.7426 7.75736 15.75 9 15.75C10.2426 15.75 11.25 14.7426 11.25 13.5V12.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 8.25H10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 6.75V9.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default BellIcon;
