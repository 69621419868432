import { IconProps } from 'types';

const Star = ({ title = 'Checkmark', className }: IconProps) => (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 17.75L5.82808 20.995L7.00708 14.122L2.00708 9.25495L8.90708 8.25495L11.9931 2.00195L15.0791 8.25495L21.9791 9.25495L16.9791 14.122L18.1581 20.995L12.0001 17.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Star;
