import { IconProps } from 'types';

const DoubleCheckIcon = ({ className }: IconProps) => (
    <svg className={className} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="6" fill="none" fillOpacity="0.62" />
        <path d="M13.1667 18.9998L19 24.8332L30.6667 13.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.86364 18.4695C7.57075 18.1766 7.09588 18.1766 6.80298 18.4695C6.51009 18.7624 6.51009 19.2373 6.80298 19.5302L7.86364 18.4695ZM12.6363 25.3635C12.9292 25.6564 13.4041 25.6564 13.697 25.3635C13.9899 25.0706 13.9899 24.5957 13.697 24.3028L12.6363 25.3635ZM18.4697 18.4695C18.1768 18.7624 18.1768 19.2373 18.4697 19.5302C18.7625 19.8231 19.2374 19.8231 19.5303 19.5302L18.4697 18.4695ZM25.3636 13.6968C25.6565 13.4039 25.6565 12.9291 25.3636 12.6362C25.0708 12.3433 24.5959 12.3433 24.303 12.6362L25.3636 13.6968ZM6.80298 19.5302L12.6363 25.3635L13.697 24.3028L7.86364 18.4695L6.80298 19.5302ZM19.5303 19.5302L25.3636 13.6968L24.303 12.6362L18.4697 18.4695L19.5303 19.5302Z" fill="currentColor" />
        <path d="M7.86364 18.4695C7.57075 18.1766 7.09588 18.1766 6.80298 18.4695C6.51009 18.7624 6.51009 19.2373 6.80298 19.5302L7.86364 18.4695ZM12.6363 25.3635C12.9292 25.6564 13.4041 25.6564 13.697 25.3635C13.9899 25.0706 13.9899 24.5957 13.697 24.3028L12.6363 25.3635ZM18.4697 18.4695C18.1768 18.7624 18.1768 19.2373 18.4697 19.5302C18.7625 19.8231 19.2374 19.8231 19.5303 19.5302L18.4697 18.4695ZM25.3636 13.6968C25.6565 13.4039 25.6565 12.9291 25.3636 12.6362C25.0708 12.3433 24.5959 12.3433 24.303 12.6362L25.3636 13.6968ZM6.80298 19.5302L12.6363 25.3635L13.697 24.3028L7.86364 18.4695L6.80298 19.5302ZM19.5303 19.5302L25.3636 13.6968L24.303 12.6362L18.4697 18.4695L19.5303 19.5302Z" fill="white" fillOpacity="0.2" />
    </svg>
);
export default DoubleCheckIcon;
