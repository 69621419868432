import { Pagination } from 'react-rainbow-components';
import styled from 'styled-components';
import { color } from 'theme';
import { WHITE } from '../../constants';

export default styled(Pagination)`
    button {
        border-radius: 4px !important;
        margin: 0 1px !important;
        font-weight: 400 !important;
    }

    button[aria-current='page'] {
        color: ${WHITE};
        background-color: ${color('brand.main')};
    }
`;
