/* eslint-disable react/forbid-component-props */
import Button from 'components/Button';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { Calendareventtype } from 'data/firestore/agent/calendareventtype/types';
import StepTwo from './step2';
import {
    Message,
    ModalFooter,
    StyledModal,
    StyledUniversalForm,
} from './styled';
import { CalendarEventModalProps } from './types';

const mapValues = (values: Record<string, unknown>) => {
    const {
        eventId, startAt: startAtValue, calendar, eventType, customer,
    } = values;

    const { id: calendarId } = calendar as Calendar;
    const { id: eventTypeId } = eventType as Calendareventtype;

    const startAt = (
        typeof startAtValue === 'string'
            ? startAtValue
            : (startAtValue as Date).toISOString()
    );

    return {
        eventId,
        startAt,
        calendarId,
        eventTypeId,
        customer,
    };
};

const CalendarEventModalRescheduleMode = ({
    agentId,
    isOpen = false,
    title,
    initialValues = {},
    onRequestClose = () => {},
    onComplete = () => {},
    className,
    style,
}: CalendarEventModalProps) => (
    <StyledModal
        className={className}
        style={style}
        title={title}
        size="small"
        borderRadius="semi-square"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        footer={(
            <ModalFooter>
                <Button
                    label="Close"
                    onClick={onRequestClose}
                    borderRadius="semi-square"
                />
                <Button
                    label="Save New Time"
                    variant="brand"
                    type="submit"
                    form="calendar-event-form"
                    borderRadius="semi-square"
                />
            </ModalFooter>
        )}
    >
        <StyledUniversalForm
            id="calendar-event-form"
            initialValues={initialValues}
            onSubmit={(values) => onComplete(mapValues(values))}
        >
            <Message>Select a new date and time for your event.</Message>
            <StepTwo agentId={agentId} />
        </StyledUniversalForm>
    </StyledModal>
);

export default CalendarEventModalRescheduleMode;
