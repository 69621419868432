import { To } from 'react-router-dom';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import {
    Container, IconContainer, StyledAvatarLoadingShape, StyledLink, Subtitle, Title, TitleContainer,
} from './styled';

interface Props {
    icon?: React.ReactNode;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    className?: string;
    to?: To;
    isLoading?: boolean;
}

const ListTile = ({
    icon, title, subtitle, isLoading, to, className,
}: Props) => (
    <Container className={className}>
        {icon && (
            <IconContainer>
                <RenderIf isTrue={!isLoading}>{icon}</RenderIf>
                <RenderIf isTrue={isLoading}>
                    <StyledAvatarLoadingShape variant="avatar" />
                </RenderIf>
            </IconContainer>
        )}
        <TitleContainer>
            <RenderIf isTrue={isLoading}>
                <LoadingShape />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <Title as={(to && StyledLink)} to={to}>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </RenderIf>
        </TitleContainer>
    </Container>
);

export default ListTile;
