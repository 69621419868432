import styled from 'styled-components';
import CopyToClipboardButton from 'components/CopyToClipboardButton';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    display: flex;
    padding: 1rem 1rem 1rem 0.5rem;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const FeatureWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
    margin-right: 1.5rem;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
`;

export const StyledSpan = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledDescription = styled.p`
    overflow-wrap: break-word;
    white-space: normal;
    line-height: 20px;
`;

export const IconContainer = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
        width: 1.875rem;
        height: 1.875rem;
    }
`;
