import { IconProps } from 'types';

const Opportunity = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M12 3C12 2.44772 12.4489 1.99482 12.9984 2.04995C14.93 2.24374 16.7715 2.9968 18.2916 4.22728C20.0719 5.66832 21.3035 7.67681 21.7806 9.91696C22.2577 12.1571 21.9514 14.4932 20.9128 16.5345C19.8742 18.5759 18.1663 20.1989 16.0747 21.1322C13.9831 22.0655 11.6344 22.2524 9.42149 21.6618C7.20854 21.0713 5.26539 19.7389 3.9169 17.8876C2.56842 16.0362 1.89632 13.7781 2.01298 11.4907C2.1126 9.53746 2.78215 7.664 3.92815 6.097C4.25416 5.6512 4.89103 5.61967 5.30205 5.98856C5.71307 6.35745 5.74126 6.98668 5.42649 7.44048C4.58055 8.66008 4.08665 10.0971 4.01038 11.5925C3.91706 13.4225 4.45473 15.229 5.53352 16.7101C6.61232 18.1911 8.16683 19.257 9.93719 19.7295C11.7076 20.2019 13.5864 20.0524 15.2598 19.3058C16.9331 18.5591 18.2994 17.2607 19.1302 15.6276C19.9611 13.9945 20.2062 12.1257 19.8245 10.3336C19.4428 8.54145 18.4575 6.93465 17.0333 5.78182C15.8694 4.83972 14.4701 4.24746 12.9974 4.06241C12.4495 3.99355 12 3.55228 12 3Z" fill="currentColor" />
        <path d="M9 15H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <circle cx="12" cy="10" r="2" stroke="currentColor" strokeWidth="2" />
    </svg>
);
export default Opportunity;
