import styled from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import {
    GREY_500,
    GREEN_500,
    GREEN_100,
} from '../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PhoneCellWrapper = styled(CellWrapper)`
    display: flex;
    padding: 0.6875rem 1.25rem;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-start;
    padding: 0 2rem 0 0;
    gap: 1.75rem;
`;

export const Icon = styled(UserAvatar)`
    display: flex;
    width: 2.375rem;
    height: 2.375rem;
    justify-content: center;
    align-items: center;

    & > svg {
        width: 1.2rem !important;
        height: auto !important;
        flex-shrink: 0;
    }
    background: ${GREEN_100};
    color: ${GREEN_500};

    &:hover {
        color: ${GREEN_500};
    }
`;

export const Label = styled.h2`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${GREY_500};
    font-weight: 300;
    font-size: 0.9375rem;
`;
