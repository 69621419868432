import styled from 'styled-components';
import Button from 'components/Button';
import { GREY_800 } from '../../constants';

export const StyledButton = styled(Button)`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    gap: 0.5rem;
    color: ${GREY_800};
`;

export const DotContainer = styled.div`
    position: absolute;
    left: 26px;
    top: 10px;
`;
