/* eslint-disable jsx-a11y/aria-role */
import { useCallback, useContext } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
import Button from 'components/Button';
import LoadingShape from 'components/LoadingShape';
import useHttpMutation from 'data/firestore/useHttpMutation';
import context from 'components/InstructionTestingLayout/context';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import {
    Container, Content, Footer, CenteredButton, Text,
} from './styled';
import { VoipInstructionTestingContext } from '../types';

interface Params extends InstructionTestStepComponentProps {}

const InboxTitleView = ({
    agentId = '',
    onChange = () => {},
}: Params) => {
    const {
        compiledTemplate, generatedSummary, generatedSubject, chatMessages,
    } = useContext<VoipInstructionTestingContext>(context);

    const {
        mutateAsync: generate,
        isLoading,
    } = useHttpMutation<Record<string, unknown>, { subject: string }>({
        pathname: '/testing/inbox-subject',
        method: 'POST',
    });

    const handleGenerateSubject = useCallback(
        async () => {
            try {
                const response = await generate({
                    body: {
                        agentId,
                        systemMessage: compiledTemplate,
                        conversation: chatMessages,
                        summary: generatedSummary?.summary,
                    },
                });
                onChange({ generatedSubject: response.subject });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [agentId, chatMessages, compiledTemplate, generate, generatedSummary, onChange],
    );

    if (!generatedSubject && !isLoading) {
        return (
            <Container className="rainbow-p-vertical_large rainbow-p-horizontal_small">
                <CenteredButton
                    variant="brand"
                    borderRadius="semi-square"
                    label="Generate Subject"
                    onClick={handleGenerateSubject}
                />
            </Container>
        );
    }

    return (
        <Container>
            <Content>
                <Text>
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape width="80%" height="10px" />
                        <LoadingShape width="100%" height="10px" />
                        <LoadingShape width="70%" height="10px" />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>{generatedSubject}</RenderIf>
                </Text>
            </Content>
            <Footer align="flex-end">
                <Button
                    variant="brand"
                    borderRadius="semi-square"
                    label="Generate"
                    disabled={isLoading}
                    onClick={handleGenerateSubject}
                />
            </Footer>
        </Container>
    );
};

export default InboxTitleView;
