import { useCallback } from 'react';
import { UniversalFormProps } from '@rainbow-modules/forms/lib/components/UniversalForm';
import CalendarPreview from './preview';
import {
    StyledUniversalForm,
    LeftColumn,
    RightColumn,
    PreviewTitle,
} from './styled';
import FormFields from './fields';

interface Params extends UniversalFormProps {
    agentId?: string;
    onSubmit?: (values: Record<string, unknown>) => void;
}

const CalendarForm = ({
    agentId,
    initialValues = {
        color: '#7367F0',
    },
    onSubmit = () => {},
    ...props
}: Params) => {
    const handleOnSubmit = useCallback(
        (values: Record<string, unknown>) => {
            const {
                defaultEventStatusId,
                availability,
                ...rest
            } = values;

            onSubmit({
                ...rest,
                defaultEventStatusId: (defaultEventStatusId as Record<string, unknown>).id,
                availability,
            });
        },
        [onSubmit],
    );

    return (
        <StyledUniversalForm {...props} initialValues={initialValues} onSubmit={handleOnSubmit}>
            <LeftColumn>
                <FormFields agentId={agentId} />
            </LeftColumn>
            <RightColumn>
                <PreviewTitle>Calendar Preview</PreviewTitle>
                <CalendarPreview />
            </RightColumn>
        </StyledUniversalForm>
    );
};

export default CalendarForm;
