import { Responder } from 'data/firestore/agent/responder/types';
import { EntityGet } from 'data/firestore/types';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import Pencil from 'components/icons/pencil3';
import { CellWrapper } from '../styled';

interface Props {
    row?: EntityGet<Responder>;
    onEdit?: (responder: EntityGet<Responder>) => void;
    onDelete?: (responder: EntityGet<Responder>) => void;
}

const ActionsColumn = ({ row, onEdit, onDelete }: Props) => row && (
    <CellWrapper>
        <ButtonIcon icon={<Pencil />} borderRadius="semi-square" onClick={() => onEdit?.(row)} />
        <ButtonIcon icon={<Trash />} borderRadius="semi-square" onClick={() => onDelete?.(row)} />
    </CellWrapper>
);

export default ActionsColumn;
