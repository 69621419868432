import { useState, useEffect } from 'react';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';

const { REACT_APP_ALGOLIA_APPLICATION_ID = '' } = process.env;

const useAlgoliaClient = (key?: string) => {
    const [client, setClient] = useState<SearchClient>();

    useEffect(() => {
        if (key) {
            setClient(algoliasearch(REACT_APP_ALGOLIA_APPLICATION_ID, key));
        }
    }, [key]);

    return client;
};

export default useAlgoliaClient;
