import styled from 'styled-components';
import { GREY_400, GREY_300 } from '../../constants';

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
    color: ${GREY_400};
    font-weight: 300;
    box-sizing: border-box;
`;

export const StyledDate = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const StyledColumnWrapperRelative = styled(StyledColumnWrapper)`
    font-size: 0.9375rem;
    font-style: normal;
    line-height: 1.375rem;
    text-transform: capitalize;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.75rem 0.5rem;
`;

export const RelativeDateLabel = styled.span`
    font-weight: 400;
    font-size: 0.9375rem;
    color: ${GREY_400};
`;

export const RelativeDateDescription = styled.span`
    font-weight: 400;
    font-size: 0.8125rem;
    color: ${GREY_300};
`;
