import { IconProps } from 'types';

const Dollar = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={24}
        height={24}
    >
        <title>{title}</title>
        <path d="M17 8.4V8.4C17 6.52223 15.4778 5 13.6 5H12.5M7 16V16.0326C7 17.6714 8.32857 19 9.96744 19H14.5C16.433 19 18 17.433 18 15.5V15.5C18 13.567 16.433 12 14.5 12H10.5C8.567 12 7 10.433 7 8.5V8.5C7 6.567 8.567 5 10.5 5H12.5M12.5 5V3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Dollar;
