import { IconProps } from 'types';

const CirclePlusIcon = ({ title = 'Plus', className }: IconProps) => (
    <svg className={className} width={16} height={16} viewBox="0 0 16 16" fill="none">
        <title>{title}</title>
        <circle cx="8" cy="8" r="6" transform="rotate(-180 8 8)" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 7.99992L6 7.99992" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.99992 10L7.99992 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default CirclePlusIcon;
