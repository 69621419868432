import { IconProps } from 'types';

const SidebarExpandIcon = ({ title = 'Shrink Sidebar', className }: IconProps) => (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M20.6464 12.3536L18.8536 14.1464C18.5386 14.4614 18 14.2383 18 13.7929L18 10.2071C18 9.76165 18.5386 9.53857 18.8536 9.85355L20.6464 11.6464C20.8417 11.8417 20.8417 12.1583 20.6464 12.3536Z" fill="currentColor" />
        <path d="M4 6H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M4 18H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M4 12H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default SidebarExpandIcon;
