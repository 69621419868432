import styled, { css } from 'styled-components';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';

export const StyledPicklist = styled(Picklist)``;

export const StyledOption = styled(Option)<{ isBuiltIn: boolean }>`
    ${(props) => props.isBuiltIn && css`
        span {
            text-transform: capitalize;
        }
    `};
`;
