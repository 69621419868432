import ModalBody from 'components/ModalBody';
import LockOpen from 'components/icons/lockOpen';

const UnblockModal = () => (
    <ModalBody
        icon={<LockOpen />}
        title="Remove Number from Blocked List?"
        description="Please confirm if you want to unblock this phone number. Once unblocked, this number will be able to contact you again through calls."
    />
);

export default UnblockModal;
