import { useLayoutEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import Footer from 'components/Footer';
import { StyledCard } from './styled';

const Teamlayout = () => {
    const navigate = useNavigate();
    const membersMatch = useMatch('/:agentid/team/members');
    const affiliatesMatch = useMatch('/:agentid/team/affiliates');
    const affiliatesSettingsMatch = useMatch('/:agentid/team/affiliates/settings');

    useLayoutEffect(() => {
        if (!membersMatch && !affiliatesMatch && !affiliatesSettingsMatch) {
            navigate('members', { replace: true });
        }
    }, [membersMatch, affiliatesMatch, affiliatesSettingsMatch, navigate]);

    return (
        <>
            <StyledCard>
                <Outlet />
            </StyledCard>
            <Footer />
        </>
    );
};

export default Teamlayout;
