import { ReactNode } from 'react';
import {
    Container,
    IlustrationContainer,
    FormContainer,
    LoginContent,
    RightContent,
    StyledIllustration,
} from './styled';

const LoginLayout = ({ children } :{ children?: ReactNode; }) => (
    <Container>
        <IlustrationContainer>
            <StyledIllustration />
        </IlustrationContainer>
        <RightContent>
            <FormContainer>
                <LoginContent>
                    {children}
                </LoginContent>
            </FormContainer>
        </RightContent>
    </Container>
);

export default LoginLayout;
