import {
    useContext, useEffect, useRef, useState,
} from 'react';
import { query, where } from 'firebase/firestore';
import CustomerActivity from 'components/CustomerActivity';
import SendNoteForm from 'components/SendNoteForm';
import useCustomers from 'data/firestore/agent/customer/useCollectionOnce';
import useInboxChatMessages from 'data/firestore/agent/customer/chat/useCollection';
import useSendNote from 'data/hooks/useSendNote';
import { Card, ItemContent, SendNoteContainer } from '../styled';
import context, { Context } from '../context';

const InboxItemActivity = () => {
    const { inboxItem, isLoading: isLoadingInboxItem } = useContext<Context>(context);
    const messagesListRef = useRef<HTMLDivElement>(null);
    const [isSendingNote, setSendingNote] = useState(false);

    const { mutateAsync: sendNote } = useSendNote();

    const { data: customers = [], isLoading: isLoadingCustomer } = useCustomers(
        inboxItem?.agentId || '',
        {
            listQueryFn: (ref) => query(ref, where('phoneNumber', '==', inboxItem?.customer?.phoneNumber)),
            disabled: (
                isLoadingInboxItem || !inboxItem?.agentId || !inboxItem?.customer?.phoneNumber
            ),
        },
    );

    const { data: messages = [], isLoading: isLoadingMessages } = useInboxChatMessages(
        inboxItem?.agentId || '',
        customers[0]?.id || '',
        {
            listQueryFn: (ref) => query(ref, where('inboxId', '==', inboxItem?.id)),
            disabled: (
                isLoadingInboxItem
                    || !inboxItem?.agentId
                    || !inboxItem?.customer?.phoneNumber
                    || isLoadingCustomer
                    || customers.length === 0
            ),
        },
    );

    useEffect(
        () => messagesListRef.current?.scrollBy(0, messagesListRef.current?.scrollHeight),
        [messages.length],
    );

    const handleSendNote = async (note: string) => {
        setSendingNote(true);
        try {
            await sendNote({
                agentId: inboxItem?.agentId,
                customerId: customers[0]?.id,
                inboxId: inboxItem?.id,
                note,
            });
        } catch (error) {
            // TODO: handle error
            console.error(error);
        }
        setSendingNote(false);
    };

    const isLoading = isLoadingInboxItem || isLoadingCustomer || isLoadingMessages;

    return (
        <ItemContent ref={messagesListRef}>
            <Card>
                <CustomerActivity
                    isLoading={isLoading}
                    messages={messages}
                    emptyMessage="There is no activity for this inbox"
                    agentId={inboxItem?.agentId}
                    context="inbox"
                />
                <SendNoteContainer>
                    <SendNoteForm onSend={handleSendNote} disableSend={isSendingNote} />
                </SendNoteContainer>
            </Card>
        </ItemContent>
    );
};

export default InboxItemActivity;
