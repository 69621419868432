import { RoleCellWrapper, RoleLabel } from './styled';

interface Props {
    value?: string;
}

const StatusColumn = ({ value }: Props) => (
    <RoleCellWrapper>
        <RoleLabel>{value}</RoleLabel>
    </RoleCellWrapper>
);

export default StatusColumn;
