import { IconProps } from 'types';

const Trash = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M6 7V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V7M6 7H3M6 7H9M18 7H21M18 7H15M9 7V5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7M9 7H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M10 11L10 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M14 11L14 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);
export default Trash;
