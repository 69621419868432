import { useCallback } from 'react';
import useHttpMutation from 'data/firestore/useHttpMutation';

type Action = 'read' | 'solve' | 'reopen' | 'remove' | 'restore' | 'spam' | 'not-spam';

const useUpdateItem = () => {
    const { mutate: hookMutate, mutateAsync: hookMutateAsync, ...rest } = useHttpMutation({
        method: 'PATCH',
    });
    const mutate = useCallback((itemId: string, action: Action, note: string = '') => hookMutate({
        pathname: `/inbox/${itemId}/status`,
        body: { action, note },
    }), [hookMutate]);
    const mutateAsync = useCallback((itemId: string, action: Action, note: string = '') => hookMutateAsync({
        pathname: `/inbox/${itemId}/status`,
        body: { action, note },
    }), [hookMutateAsync]);

    return { mutate, mutateAsync, ...rest };
};

export default useUpdateItem;
