import { useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
import Button from 'components/Button';
import Close from 'components/icons/close';
import MenuItem from './menuItem';
import {
    Content,
    Description, Header, HeaderActions, HeaderContent,
    HeaderWithActions,
    HelperContainer,
    InstructionsContainer, InstructionsInput, InstructionsInputContainer,
    Row, SidebarLabel, StyledButtonIcon, StyledSidebar, Title,
    InstructionsInputContent,
} from './styled';
import FormFooter from './formFooter';
import { InstructionsProps, ModelConfig } from './types';
import getValidationStatus from './helpers/getValidationStatus';
import History from './history';
import getRightSidebarTitle from './helpers/getRightSidebarTitle';
import AiModelConfigForm from './aiModelConfigForm';

const Instructions = ({
    title = 'Instructions',
    description,
    help,
    actions,
    value = '',
    templates = [],
    partials,
    historyItems = [],
    selectedVersion,
    validationErrors = [],
    modelConfig,
    isLoading,
    disableModelConfig = false,
    deleteEnabled = true,
    onChange,
    onValidate,
    onSubmit,
    onSubmitModelConfig,
    onMenuItemClick,
    onAddPartialClick = () => {},
    onDeleteClick = () => {},
    disableTesting = true,
    onTestClick = () => {},
    onSelectedVersion,
    modelConfigFieldsComponent,
}: InstructionsProps) => {
    const [historyOpen, setHistoryOpen] = useState(false);
    const [helpOpen, setHelpOpen] = useState(false);
    const [modelConfigOpen, setModelConfigOpen] = useState(false);
    const [isValidating, setIsValidating] = useState(false);

    const handleChange = (newValue?: string) => {
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleValidate = async (currentValue: string) => {
        if (onValidate) {
            setIsValidating(true);
            try {
                const result = await onValidate(currentValue);
                return result;
            } finally {
                setIsValidating(false);
            }
        }
        return [];
    };

    const handleSaveChanges = () => {
        if (onSubmit) {
            onSubmit(value);
        }
    };

    const handleSubmitModelConfig = async (values: ModelConfig) => {
        if (onSubmitModelConfig) {
            try {
                const success = await onSubmitModelConfig(values);
                if (success) {
                    setModelConfigOpen(false);
                }
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        }
    };

    const validationStatus = getValidationStatus(validationErrors, isValidating);

    return (
        <Content>
            <InstructionsContainer>
                <Header>
                    <HeaderContent>
                        <Row>
                            <Title>{title}</Title>
                            <Description>{description}</Description>
                        </Row>
                        <HeaderActions>
                            {actions}
                        </HeaderActions>
                    </HeaderContent>
                </Header>
                <InstructionsInputContainer>
                    <StyledSidebar>
                        {templates.map((item) => (
                            <MenuItem
                                key={item.name}
                                label={item.label}
                                isActive={item.isActive}
                                icon={item.icon}
                                onClick={() => onMenuItemClick?.(item)}
                            />
                        ))}
                        <SidebarLabel>Partials</SidebarLabel>
                        {partials?.map((item) => (
                            <MenuItem
                                key={item.name}
                                label={item.label}
                                isActive={item.isActive}
                                icon={item.icon}
                                onClick={() => onMenuItemClick?.(item)}
                            />
                        ))}
                        <Button
                            variant="neutral"
                            label="Add Partial"
                            borderRadius="semi-square"
                            onClick={onAddPartialClick}
                        />
                    </StyledSidebar>
                    <InstructionsInputContent>
                        <InstructionsInput
                            value={value}
                            onChange={handleChange}
                            onValidate={handleValidate}
                        />
                        <FormFooter
                            formId="instructions-form"
                            submitLoading={isLoading}
                            historyOpen={historyOpen}
                            helpOpen={helpOpen}
                            modelConfigOpen={modelConfigOpen}
                            onOpenHelp={help ? (() => {
                                setHistoryOpen(false);
                                setHelpOpen(true);
                                setModelConfigOpen(false);
                            }) : undefined}
                            onOpenHistory={historyItems.length > 0 ? () => {
                                setHelpOpen(false);
                                setHistoryOpen(true);
                                setModelConfigOpen(false);
                            } : undefined}
                            onOpenModelConfig={() => {
                                setModelConfigOpen(true);
                                setHelpOpen(false);
                                setHistoryOpen(false);
                            }}
                            onsubmit={() => handleSaveChanges()}
                            validationStatus={validationStatus}
                            validationErrors={validationErrors.length}
                            disableModelConfig={disableModelConfig}
                            deleteEnabled={isLoading || deleteEnabled}
                            onDelete={onDeleteClick}
                            disableTesting={disableTesting}
                            onOpenTest={onTestClick}
                        />
                    </InstructionsInputContent>
                </InstructionsInputContainer>
            </InstructionsContainer>
            <RenderIf isTrue={historyOpen || helpOpen}>
                <HelperContainer>
                    <HeaderWithActions>
                        <Title>
                            {getRightSidebarTitle({
                                isHelpOpen: helpOpen,
                                isHistoryOpen: historyOpen,
                                isModelConfigOpen: modelConfigOpen,
                            })}
                        </Title>
                        <StyledButtonIcon
                            icon={<Close />}
                            onClick={() => {
                                setHistoryOpen(false);
                                setHelpOpen(false);
                                setModelConfigOpen(false);
                            }}
                            size="small"
                            className="rainbow-m-left_small"
                            borderRadius="semi-square"
                            variant="base"
                        />
                    </HeaderWithActions>
                    <RenderIf isTrue={historyOpen}>
                        <History
                            items={historyItems}
                            selectedItem={selectedVersion || historyItems[0]}
                            onSelectedVersion={onSelectedVersion}
                        />
                    </RenderIf>
                    <RenderIf isTrue={help && helpOpen}>
                        {help}
                    </RenderIf>
                </HelperContainer>
            </RenderIf>
            <RenderIf isTrue={modelConfigOpen}>
                <AiModelConfigForm
                    initialValues={modelConfig}
                    onSubmit={handleSubmitModelConfig}
                    fieldsComponent={modelConfigFieldsComponent}
                    onRequestClose={() => {
                        setHistoryOpen(false);
                        setHelpOpen(false);
                        setModelConfigOpen(false);
                    }}
                />
            </RenderIf>
        </Content>
    );
};

export default Instructions;
