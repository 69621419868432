import { useContext } from 'react';
import { RenderIf } from 'react-rainbow-components';
import EmptyMessage from 'components/EmptyMessage';
import context, { Context } from 'components/InstructionTestingLayout/context';
import { Container, Instructions } from './styled';

const CompileSystemMessage = () => {
    const { compiledTemplate } = useContext<Context>(context);

    return (
        <Container>
            <RenderIf isTrue={!compiledTemplate}>
                <EmptyMessage message="System message have not been compiled yet." />
            </RenderIf>
            <RenderIf isTrue={compiledTemplate}>
                <Instructions>{compiledTemplate}</Instructions>
            </RenderIf>
        </Container>
    );
};

export default CompileSystemMessage;
