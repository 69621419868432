import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import Button from 'components/Button';
import { color } from 'theme';
import { DIVIDER } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    gap: 0.75rem;
`;

export const StyledButton = styled(Button)`
    align-self: flex-start;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.02688rem;
    padding: 1px 2px 1px 2px;

    &:focus {
        border: none;
        box-shadow: none;
    }

    svg {
        width: 1rem !important;
        height: 1rem !important;
        font-size: 1rem !important;
    }
`;

export const StyledTextarea = styled(Textarea)`
    display: flex;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;

    label {
        margin-left: 0;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
    }

    > div {
        border: none;
        box-shadow: none;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        box-sizing: border-box;
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};
        padding: 0.5rem 0.875rem;

        :focus, :active {
            width: 100%;
            padding: 0.5rem 0.875rem;
            border: 1px solid ${DIVIDER};
        }

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const Actions = styled.div`
    display: flex;
    padding: 0.5rem 0rem 0rem 1.5rem;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    align-self: stretch;
`;

export const FilesContainer = styled.div`
    display: flex;
    align-self: stretch;
    flex-wrap: wrap;
`;

export const FileItem = styled.button`
    display: inline-flex;
    padding: 0.125rem 0.375rem;
    align-items: center;
    gap: 0.375rem;
    border-radius: 0.375rem;
    color: ${color('brand.main')};
    background: ${color('brand.light')};
    border: none;
`;
