import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';

interface LanguagePickerProps {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    value?: string;
    onChange?: (value: string) => void;
}

const options = [
    { name: 'en', label: 'English', value: 'en' },
    { name: 'es', label: 'Spanish', value: 'es' },
];

const LanguagePicker = (props: LanguagePickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        disabled,
        readOnly,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps);
    }, [valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => options.map((option) => <Option key={`language-picker-option__${option.name}`} {...option} />),
        [],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            value={pickListvalue}
            disabled={disabled}
            readOnly={readOnly}
            onChange={handleOnChange}
            error={error}
        >
            <Option label="Preferred Language" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default LanguagePicker;
