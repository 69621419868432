import { IconProps } from 'types';

const PhoneIcon = ({ className }: IconProps) => (
    <svg className={className} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="6" fill="#EEFBF3" />
        <path d="M10.8333 9.6665H15.5L17.8333 15.4998L14.9167 17.2498C16.1661 19.7833 18.2165 21.8337 20.75 23.0832L22.5 20.1665L28.3333 22.4998V27.1665C28.3333 28.4552 27.2887 29.4998 26 29.4998C16.5823 28.9275 9.07232 21.4176 8.5 11.9998C8.5 10.7112 9.54467 9.6665 10.8333 9.6665" stroke="#28C76F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.8333 9.6665H15.5L17.8333 15.4998L14.9167 17.2498C16.1661 19.7833 18.2165 21.8337 20.75 23.0832L22.5 20.1665L28.3333 22.4998V27.1665C28.3333 28.4552 27.2887 29.4998 26 29.4998C16.5823 28.9275 9.07232 21.4176 8.5 11.9998C8.5 10.7112 9.54467 9.6665 10.8333 9.6665" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default PhoneIcon;
