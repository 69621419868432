import { Link, useLocation, LinkProps } from 'react-router-dom';

interface LinkWithQueryProps extends LinkProps {
    to: string;
}

const LinkWithQuery = ({ to, ...props }: LinkWithQueryProps) => {
    const location = useLocation();

    const toWithQuery = {
        pathname: to,
        search: location.search,
    };

    return <Link to={toWithQuery} {...props} />;
};

export default LinkWithQuery;
