import { IconProps } from 'types';

const FlaskIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
        width={18}
        height={18}
    >
        <title>{title}</title>
        <path d="M6.75 2.25H11.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 6.75H10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.50019 2.25V6.75L4.50019 15C4.42683 15.1476 4.42703 15.3211 4.50074 15.4685C4.57445 15.6159 4.7131 15.7201 4.87519 15.75H13.1252C13.2873 15.7201 13.4259 15.6159 13.4996 15.4685C13.5734 15.3211 13.5736 15.1476 13.5002 15L10.5002 6.75V2.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default FlaskIcon;
