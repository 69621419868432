const formatDuration = (
    ms: number,
    { showMs, padStart }: { showMs?: boolean; padStart?: boolean } = {},
) => {
    // Convert milliseconds to total seconds
    const totalSeconds = Math.floor(ms / 1000);

    // Compute minutes and remaining seconds
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const milliseconds = ms % 1000;
    const millisecondsToShow = milliseconds < 1 ? milliseconds.toFixed(2) : milliseconds.toFixed(0);

    // Format to ensure proper display
    const formattedMinutes = padStart ? String(minutes).padStart(2, '0') : minutes;
    const formattedSeconds = padStart ? String(seconds).padStart(2, '0') : seconds;
    const formattedMilliseconds = padStart ? String(millisecondsToShow).padStart(3, '0') : millisecondsToShow;

    const millisecondsStr = showMs ? `.${formattedMilliseconds}` : '';
    return `${formattedMinutes}m ${formattedSeconds}${millisecondsStr}s`;
};

const getSuffixForValue = (value: number) => (value === 1 ? '' : 's');

export const formatDurationFromSeconds = (
    s: number,
) => {
    // Compute minutes and remaining seconds
    const hours = Math.floor(s / 3600);
    const rest = s % 3600;
    const minutes = Math.floor(rest / 60);
    const seconds = rest % 60;

    const parts = [];
    if (hours > 0) {
        parts.push(`${hours} hour${getSuffixForValue(hours)}`);
    }

    if (minutes > 0) {
        parts.push(`${minutes} minute${getSuffixForValue(minutes)}`);
    }

    if (seconds > 0) {
        parts.push(`${seconds} second${getSuffixForValue(seconds)}`);
    }

    return parts.join(' ');
};

export default formatDuration;
