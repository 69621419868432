const getCharsFromName = (name: string) => {
    const nameArray = name.split(' ').slice(0, 2);
    const initials = nameArray.map((word) => word.charAt(0).toUpperCase()).join('');
    return initials;
};

export default function getInitials(
    profile?: string | Record<string, unknown>,
): string | undefined {
    if (profile) {
        if (typeof profile === 'string') return getCharsFromName(profile);

        const {
            firstName = '',
            lastName = '',
            alias = '',
            displayName = '',
        } = (profile as Record<string, unknown>) || {};
        const fullName = [firstName, lastName].join(' ').trim();

        if (fullName) return getCharsFromName(fullName);

        if (alias) return getCharsFromName(alias as string);

        if (displayName) return getCharsFromName(displayName as string);
    }

    return undefined;
}
