/* eslint-disable jsx-a11y/aria-role */
import { useContext, useEffect } from 'react';
import ChatView from 'components/ChatView';
import context, { Context } from 'components/InstructionTestingLayout/context';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import useAgentChat from '../hooks/useAgentChat';

interface Params extends InstructionTestStepComponentProps {}

const AgentChatView = ({
    agentId,
    onChange = () => {},
}: Params) => {
    const {
        compiledTemplate,
        templateContext,
        chatMessages,
    } = useContext<Context>(context);

    const {
        messages,
        isLoading,
        isInitialized,
        initialize: initializeChat,
        reset: resetChat,
        addMessage,
        updateMessage,
        deleteMessage,
        send: submitConversation,
    } = useAgentChat({
        agentId,
        context: templateContext,
        systemMessage: compiledTemplate,
        messages: chatMessages,
    });

    useEffect(
        () => {
            onChange({ messages });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [messages],
    );

    return (
        <ChatView
            isLoading={isLoading}
            messages={messages}
            initialized={isInitialized}
            onChatInit={initializeChat}
            onAddMessage={addMessage}
            onUpdateMessage={updateMessage}
            onDeleteMessage={deleteMessage}
            onSubmit={submitConversation}
            onReset={resetChat}
        />
    );
};

export default AgentChatView;
