import { useEffect } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Role } from 'types';
import useUserRoles from './useUserRoles';

interface Params {
    requiredRoles: Role[],
    redirectTo?: To,
}

const useRedirectOnMissingRoles = ({
    requiredRoles = [],
    redirectTo = '/',
}: Params) => {
    const { isLoading = false, role, isAdmin } = useUserRoles();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            !isLoading
            && requiredRoles.length > 0
            && !requiredRoles.includes(role as Role)
            && !isAdmin
        ) {
            navigate(redirectTo, { replace: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAdmin, redirectTo, requiredRoles, role]);
};

export default useRedirectOnMissingRoles;
