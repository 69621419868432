const getScoreRange = (score?: number | null) => {
    if (typeof score === 'number') {
        if (score < 40) return 'low';
        if (score < 70) return 'medium';
        return 'high';
    }
    return undefined;
};

export default getScoreRange;
