import styled from 'styled-components';
import { CheckboxGroup } from 'react-rainbow-components';
import { GREY_400 } from '../../constants';

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    & > div {
        gap: 0.75rem;
    }

    label > span:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400}
    }
`;
