import Shape from 'components/LoadingShape';
import { LoadingContainer } from './styled';

const LoadingShape = () => (
    <LoadingContainer>
        <Shape width="90%" />
        <Shape width="100%" />
        <Shape width="60%" />
    </LoadingContainer>
);

export default LoadingShape;
