import { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import CalendarEventTimePicker from 'components/CalendarEventTimePicker';
import useAgentTimeZone from 'hooks/useAgentTimeZone';
import { validateNotEmpty } from '../validators';
import {
    FieldsContainer,
} from '../styled';

interface Params {
    agentId?: string;
}

const StepTwo = ({ agentId = '' }: Params) => {
    const { values, initialValues } = useFormState();
    const timezone = useAgentTimeZone();

    const alwaysVisibleSlots = useMemo(
        () => (initialValues.startAt ? [initialValues.startAt] : undefined),
        [initialValues.startAt],
    );
    return (
        <FieldsContainer>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={CalendarEventTimePicker}
                agentId={agentId}
                alwaysVisibleSlots={alwaysVisibleSlots}
                calendarId={values?.calendar?.id}
                eventTypeId={values?.eventType?.id}
                name="startAt"
                hideLabel
                validate={validateNotEmpty('Please select a start time.')}
                minDate={new Date()}
                timezone={timezone}
            />
        </FieldsContainer>
    );
};

export default StepTwo;
