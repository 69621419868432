import { IconProps } from 'types';

const Card = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <rect
            width={18}
            height={14}
            x={3}
            y={5}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            rx={2}
        />
    </svg>
);
export default Card;
