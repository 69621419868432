import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ActivityTimeline, RenderIf } from 'react-rainbow-components';
import EmptyMessage from 'components/EmptyMessage';
import BellIcon from 'components/icons/bell';
import ChevronLeft from 'components/icons/chevronLeft';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import LoadingShape from 'components/LoadingShape';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useEventType from 'data/firestore/agent/calendareventtype/use';
import { formatDurationFromSeconds } from 'data/services/duration/formatter';
import {
    Container,
    Divider,
    Header,
    HeaderItems,
    StyledButtonIcon,
    Title,
} from '../../styled';
import AddEditEventTypeForm from '../AddEditForm';
import useManageEventType from '../hooks/useManageEventType';
import {
    Content,
    Description,
    LeftColumn,
    RightColumn,
    TitleContainer,
    DetailsContainer,
    StyledInput,
    AddReminderButton,
} from './styled';
import AddEditReminerForm from '../AddEditReminerForm';
import useManageNotificationRules from '../hooks/useManageNotificationRules';
import NotificationRule from './notificationRule';

const CalendarEventTypeDetails = () => {
    const { agentId = '', eventTypeId = '' } = useParams();
    const navigate = useNavigateWithQuery();

    const goBack = useCallback(
        () => navigate(`/${agentId}/calendar/settings/event-types`),
        [agentId, navigate],
    );

    const {
        edit: editEventType,
        remove: removeEventType,
    } = useManageEventType({
        agentId,
        onEventTypeDeleted: goBack,
    });

    const { create: addReminder } = useManageNotificationRules({
        agentId,
        eventTypeId,
    });

    const { data, isLoading } = useEventType(
        agentId,
        eventTypeId,
        {
            disabled: !(agentId || eventTypeId),
        },
    );

    const reminders = useMemo(
        () => (data?.notificationRules || []).map(
            (notificationRule, index) => (
                <NotificationRule
                    key={`eventtype-${eventTypeId}__notification-rule-${notificationRule.id}`}
                    id={notificationRule.id}
                    when={notificationRule.when}
                    relativeTo={notificationRule.relativeTo}
                    instructions={notificationRule.instructions}
                    messageExample={notificationRule.messageExample}
                    eventTypeId={eventTypeId}
                    label={`Reminder ${(index + 1)}`}
                />
            ),
        ),
        [data?.notificationRules, eventTypeId],
    );

    const handleAddReminder = useCallback(
        () => addReminder({}),
        [addReminder],
    );

    const handleEditEventType = useCallback(
        () => {
            if (isLoading || !data) return;

            editEventType({
                id: data?.id,
                name: data?.name,
                description: data?.description,
                duration: data?.duration,
                eventTypeIncrement: data?.eventTypeIncrement,
            });
        },
        [data, editEventType, isLoading],
    );

    const handleDeleteEventType = useCallback(
        () => removeEventType(eventTypeId),
        [eventTypeId, removeEventType],
    );

    const addReminderButtonLabel = useMemo(
        () => (
            reminders.length > 0
                ? 'Add More Reminders'
                : 'Add Reminder'
        ),
        [reminders.length],
    );

    return (
        <>
            <Container>
                <Header>
                    <HeaderItems>
                        <StyledButtonIcon
                            variant="base"
                            borderRadius="semi-square"
                            icon={<ChevronLeft />}
                            onClick={goBack}
                        />
                        <Title title="Event Type Details">Event Type Details</Title>
                        <StyledButtonIcon
                            variant="base"
                            borderRadius="semi-square"
                            icon={<Pencil />}
                            onClick={handleEditEventType}
                        />
                        <StyledButtonIcon
                            variant="base"
                            borderRadius="semi-square"
                            icon={<Trash />}
                            onClick={handleDeleteEventType}
                        />
                    </HeaderItems>
                </Header>
                <Divider />
                <Content>
                    <LeftColumn>
                        <TitleContainer>
                            <Title>Main Information</Title>
                            <Description>
                                Here you can see the basic parameters defined in the event type.
                            </Description>
                        </TitleContainer>
                        <DetailsContainer>
                            <RenderIf isTrue={isLoading}>
                                <LoadingShape width="65%" />
                                <LoadingShape width="45%" />
                                <LoadingShape width="70%" />
                            </RenderIf>
                            <RenderIf isTrue={!isLoading}>
                                <StyledInput
                                    label="Event Type Name"
                                    value={data?.name}
                                    required
                                    readOnly
                                />
                                <StyledInput
                                    label="Duration"
                                    value={formatDurationFromSeconds(data?.duration || 0)}
                                    required
                                    readOnly
                                />
                                <StyledInput
                                    label="Start Time Increments"
                                    value={formatDurationFromSeconds(data?.eventTypeIncrement || 0)}
                                    required
                                    readOnly
                                />
                                <StyledInput
                                    label="Description"
                                    placeholder="No description"
                                    value={data?.description}
                                    readOnly
                                />
                            </RenderIf>
                        </DetailsContainer>
                    </LeftColumn>
                    <RightColumn>
                        <TitleContainer>
                            <Title>Reminders</Title>
                            <Description>
                                Set up automatic reminders to ensure attendees are promptly
                                informed about upcoming events. Customize timing and messages
                                for effective communication.
                            </Description>
                        </TitleContainer>
                        <RenderIf isTrue={isLoading}>
                            <DetailsContainer>
                                <LoadingShape width="80%" />
                                <LoadingShape width="100%" />
                                <LoadingShape width="70%" />
                            </DetailsContainer>
                        </RenderIf>
                        <RenderIf isTrue={!isLoading && reminders.length === 0}>
                            <EmptyMessage message="There are no reminders." />
                        </RenderIf>
                        <RenderIf isTrue={!isLoading && reminders.length > 0}>
                            <ActivityTimeline>
                                {reminders}
                            </ActivityTimeline>
                        </RenderIf>
                        <AddReminderButton
                            variant="base"
                            size="small"
                            borderRadius="semi-square"
                            onClick={handleAddReminder}
                        >
                            <BellIcon />
                            {addReminderButtonLabel}
                        </AddReminderButton>
                    </RightColumn>
                </Content>
            </Container>
            <AddEditEventTypeForm />
            <AddEditReminerForm />
        </>
    );
};

export default CalendarEventTypeDetails;
