import { useMemo } from 'react';
import { Inbox } from 'data/firestore/inbox/types';
import { BadgeProps } from 'react-rainbow-components/components/Badge';
import { formatRelativeDate } from 'data/services/date/formatter';
import {
    TaskTitle as Title,
    TitleContainer,
    PriorityBadge,
    DateLabel,
} from './styled';

const priorityToVariantMap: Record<Inbox['priority'], BadgeProps['variant']> = {
    low: 'success',
    medium: 'warning',
    high: 'warning',
    urgent: 'error',
};

interface TaskTitleProps {
    label?: string;
    priority?: Inbox['priority'];
    date?: Date;
}

const TaskTitle = ({ label = '', priority = 'low', date }: TaskTitleProps) => {
    const formattedDate = useMemo(
        () => date && formatRelativeDate(date as Date),
        [date],
    );

    return (
        <TitleContainer>
            <Title>{label}</Title>
            <div className="rainbow-flex">
                <PriorityBadge variant={priorityToVariantMap[priority]}>{priority}</PriorityBadge>
                <DateLabel>{`Task Created ${formattedDate}`}</DateLabel>
            </div>
        </TitleContainer>
    );
};

export default TaskTitle;
