import {
    Fragment,
    useCallback,
    useMemo, useRef, useState,
} from 'react';
import { ref, getBlob } from 'firebase/storage';
import { RenderIf } from 'react-rainbow-components';
import ImageIcon from 'components/icons/image';
import Download from 'components/icons/download';
import ButtonIcon from 'components/ButtonIcon';
import HiddenAnchor from 'components/HiddenAnchor';
import ZoomedImage from 'components/ZoomableImage/zoomedImage';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import { storage } from '../../../firebase';
import { FileItem, FilesContainer } from './styled';

const getFileBlobUrl = async (path: string) => {
    const fileRef = ref(storage, path);
    const blob = await getBlob(fileRef);
    return URL.createObjectURL(blob);
};

export const getImageSize = (url: string) => new Promise<DOMRect>(
    (resolve) => {
        const link = document.createElement('img');
        link.src = url;
        link.setAttribute('style', 'display:none');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link.onload = () => {
            const size = new DOMRect(0, 0, link.naturalWidth, link.naturalHeight);
            link.parentNode?.removeChild(link);
            return resolve(size);
        };
        document.body.appendChild(link);
    },
);

const FileList = ({ data }: { data: Calendarevent['files'] }) => {
    const triggerRef = useRef<HTMLButtonElement>(null);
    const hiddenLinkRef = useRef<HTMLAnchorElement>(null);
    const imageRect = useRef<DOMRect>();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isDownloading, setIsDownloading] = useState(false);

    const open = useCallback(
        async (path: string) => {
            const downloadUrl = await getFileBlobUrl(path);
            const size = await getImageSize(downloadUrl);
            const triggerRect = triggerRef.current?.getBoundingClientRect();
            const aspectRatio = size.width / size.height;
            imageRect.current = new DOMRect(
                triggerRect?.left || 0,
                triggerRect?.top || 0,
                (triggerRect?.height || size.height) * aspectRatio,
                triggerRect?.height,
            );
            setImageUrl(downloadUrl);
        },
        [],
    );

    const close = useCallback(() => setImageUrl(null), []);

    const download = useCallback(async (name: string, path: string) => {
        if (!hiddenLinkRef.current) return;

        setIsDownloading(true);
        const downloadUrl = await getFileBlobUrl(path);
        hiddenLinkRef.current.href = downloadUrl;
        hiddenLinkRef.current.download = name;
        hiddenLinkRef.current.click();
        setIsDownloading(false);
    }, []);

    const filesList = useMemo(
        () => data?.map(({ name, path }) => (
            <Fragment key={name}>
                <FileItem
                    onClick={() => open(path)}
                    ref={triggerRef}
                >
                    <ImageIcon />
                    {name.slice(-10)}
                </FileItem>
                <HiddenAnchor ref={hiddenLinkRef} target="_blank" />
                <ButtonIcon
                    size="small"
                    borderRadius="semi-rounded"
                    icon={<Download />}
                    onClick={() => download(name, path)}
                    disabled={isDownloading}
                />
                <RenderIf isTrue={imageUrl}>
                    <ZoomedImage
                        src={imageUrl || ''}
                        alt={name}
                        close={close}
                        originalRect={imageRect.current}
                    />
                </RenderIf>
            </Fragment>
        )),
        [close, data, download, imageUrl, isDownloading, open],
    );

    return (
        <FilesContainer>
            {filesList}
        </FilesContainer>
    );
};

export default FileList;
