import { useState } from 'react';
import { ShowIf } from 'react-rainbow-components';
import {
    Container, StyledChevron,
} from './styled';

interface ItemSectionProps {
    children: React.ReactNode;
    label: React.ReactNode;
    disabled?: boolean;
}

const ItemSection = ({
    children, label, disabled = false,
}: ItemSectionProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <>
            <Container
                onClick={() => {
                    if (disabled) return;
                    setExpanded((prevExpanded) => !prevExpanded);
                }}
            >
                <StyledChevron expanded={expanded} disabled={disabled} />
                {label}
            </Container>
            <ShowIf isTrue={expanded} inAnimation="fade" outAnimation="slideVertical" className="rainbow-p-bottom_x-small">
                {children}
            </ShowIf>
        </>

    );
};

export default ItemSection;
