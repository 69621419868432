import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { limit, query, where } from 'firebase/firestore';
import useTasks from 'data/firestore/inbox/useCollection';
import { Provider } from './context';

interface Params {
    children?: ReactNode | ReactNode[];
}

const AgentProvider = ({ children }: Params) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();

    const { data: tasks, isLoading } = useTasks({
        listQueryFn: (ref) => query(
            ref,
            where('agentId', '==', agentId),
            where('_tags', 'array-contains', 'not-spam'),
            where('solvedBy', '==', null),
            where('removedBy', '==', null),
            limit(1000),
        ),
        track: [agentId],
    });

    const value = useMemo(() => ({
        tasks,
        isLoading,
    }), [tasks, isLoading]);

    return (
        <Provider value={value}>
            {children}
        </Provider>
    );
};

export default AgentProvider;
