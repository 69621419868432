import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { orderBy, query, where } from 'firebase/firestore';
import useAgentData from 'hooks/useAgentData';
import useCalendarEvents from 'data/firestore/agent/calendarevent/useCollectionOnce';
import { Calendareventstatus } from 'data/firestore/agent/calendareventstatus/types';
import { getFormatter } from 'data/services/date/formatter';
import getStatusFieldName from '../helpers/getStatusFieldName';
import { statusColorMap, statusLabelMap } from '../helpers/mappers';

interface Params {
    agentId?: string;
    from?: Date;
    to?: Date;
}

const useMonthlyBookingsInsights = ({
    agentId = '',
    from,
    to,
}: Params) => {
    const { agent, isLoading: isLoadingAgent } = useAgentData();

    const { data: events = [], isLoading: isLoadingEvents } = useCalendarEvents(
        agentId,
        {
            disabled: !agentId || !from || !to,
            track: [agentId, from?.toISOString(), to?.toISOString()],
            listQueryFn: (ref) => query(
                ref,
                where('createdAt', '>', from),
                where('createdAt', '<=', to),
                where('createdBy.role', '==', 'affiliate'),
                orderBy('createdAt'),
            ),
        },
    );

    const mappedData = useMemo(
        () => {
            if (!to || !from) return [];
            const timeZone = agent?.companyAddress.timezone
                || Intl.DateTimeFormat().resolvedOptions().timeZone;
            const formatter = getFormatter('en-US', { month: 'short', timeZone });
            const months = Array.from(new Array(12)).reduce(
                (acc, num, index) => {
                    const dateTo = DateTime.fromJSDate(to).minus({ month: index }).toJSDate();
                    const month = formatter.format(dateTo);
                    return [
                        month,
                        ...acc,
                    ];
                },
                [],
            );
            return {
                months,
                data: events.reduce(
                    (result, event) => {
                        const month = formatter.format(event.startAt);
                        const monthIndex = months.indexOf(month);

                        const total = result['total-bookings'];
                        const currentValue = total.values[monthIndex] as number;
                        total.values[monthIndex] = currentValue + 1;

                        const label = getStatusFieldName(event.status as Calendareventstatus);
                        const currentLabelData = result[label];
                        const currenLabeltValue = currentLabelData.values[monthIndex] as number;
                        currentLabelData.values[monthIndex] = currenLabeltValue + 1;

                        return {
                            ...result,
                            'total-bookings': total,
                            [label]: currentLabelData,
                        };
                    },
                    Object.keys(statusLabelMap).reduce(
                        (acc, key) => ({
                            ...acc,
                            [key]: {
                                color: statusColorMap[key],
                                values: Array.from(new Array(12)).fill(0),
                            },
                        }),
                        {},
                    ) as Record<string, { color: string; values: number[]; }>,
                ),
            };
        },
        [agent?.companyAddress.timezone, events, from, to],
    );

    return useMemo(
        () => ({
            isLoading: isLoadingAgent || isLoadingEvents,
            data: mappedData,
            totalBookings: events.length,
        }),
        [events.length, isLoadingAgent, isLoadingEvents, mappedData],
    );
};

export default useMonthlyBookingsInsights;
