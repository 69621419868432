import styled from 'styled-components';

export const StyledSpinnerContiner = styled.div`
    position: relative;
    height: 212px;
    width: 172px;
`;

export const StyledVideoContainer = styled.div`
    position: relative;
    margin: 5px 0;
`;

export const StyledVideo = styled.video`
    border-radius: 10px;
`;
