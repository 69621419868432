import { useMemo } from 'react';
import { formatLanguage } from 'data/services/language/formatter';
import { CellWrapper, EmptyLabel } from './styled';

interface Props {
    value?: string;
}

const LanguageColumn = ({ value }: Props) => {
    const languageValue = useMemo(
        () => formatLanguage(value),
        [value],
    );

    if (!languageValue) return <CellWrapper><EmptyLabel>Not Provided</EmptyLabel></CellWrapper>;

    return <CellWrapper>{languageValue}</CellWrapper>;
};

export default LanguageColumn;
