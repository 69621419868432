import styled from 'styled-components';
import { color } from 'theme';

export const StyledHours = styled.div`
    flex: none;
    color: ${color('text.main')};
    height: auto;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 65px;
    overflow-y: hidden;
    overflow-x: hidden;
`;

export const StyledHour = styled.div`
    position: relative;
    height: 40px;
    min-height: 40px;
    padding-right: 8px;
    text-align: right;
    color: ${color('text.main')};
    font-size: 0.75rem;

    :first-child>div {
        display: none;
    }                  
`;

export const StyledTitleHour = styled.div`
    position: relative;
    top: -7px;
`;
