const sexLabelMap: Record<string, string> = {
    male: 'Male',
    female: 'Female',
    intersex: 'Intersex',
    unknown: 'Prefer Not to Say',
};

export default function formatSex(sex: string = ''): string {
    return sexLabelMap[sex.toLowerCase()] || '';
}
