import styled, { css } from 'styled-components';
import ChevronRight from 'components/icons/chevronRight';
import { GREY_600, GREY_300 } from '../../../constants';

export const Container = styled.li`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

interface StyledLinkProps {
    isActive: boolean;
}
export const StyledLink = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<StyledLinkProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    width: 100%;
    color: ${GREY_600};
    padding: 0.6rem 0.75rem;
    border-radius: 5rem;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.375rem;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;
    border: 2px solid transparent;

    > svg {
        color: ${GREY_300};
    }

    &:hover, &:focus, &:active {
        color: ${GREY_600};
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.05);
    }

    ${(props) => props.isActive && css`
        color: ${GREY_600};
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.05);
    `};
`;

interface StyledExpandedProps {
    expanded: boolean;
}

export const StyledLabel = styled.span`
    flex-grow: 1;
    margin-left: 0.95rem;
`;

export const StyledChevron = styled(ChevronRight).withConfig({
    shouldForwardProp: (prop) => !['expanded'].includes(prop),
})<StyledExpandedProps>`
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.25s ease-in-out 0s;
    margin-right: -0.25rem;
    color: ${GREY_600} !important;

    ${(props) => props.expanded && css`
        transition: transform 0.25s ease-in-out 0s;
        transform: rotate(90deg);
    `}
`;
