import { useCallback, useEffect, useState } from 'react';
import useSMSChat from 'hooks/useSMSChat';
import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import useTwilioConversation from 'hooks/useTwilioConversation';
import EmptyMessage from 'components/EmptyMessage';
import {
    EmptyMessageContainer, EmptyMessageEmoji, EmptyMessageTitle,
} from './styled';
import SMSChat from './chat';
import SMSChatLayout from './layout';
import ConversationsList from './ConversationList';

const SMSChatModal = () => {
    const { isOpen, data: customerData, closeChat } = useSMSChat();
    const { isSMSEnabled } = useTwilioConversation();
    const [activeCustomer, setActiveCustomer] = useState<EntityGet<Customer> | null>(null);

    const handleCloseChat = useCallback(
        () => {
            closeChat();
            setActiveCustomer(null);
        },
        [closeChat],
    );

    useEffect(() => {
        if (isOpen && customerData?.id) {
            setActiveCustomer(customerData as unknown as EntityGet<Customer>);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData?.id]);

    if (!isOpen) return null;

    if (!isSMSEnabled) {
        return (
            <SMSChatLayout
                customerData={customerData as unknown as EntityGet<Customer>}
                onRequestClose={handleCloseChat}
                hideInput
            >
                <EmptyMessageContainer>
                    <EmptyMessageEmoji>
                        💬
                    </EmptyMessageEmoji>
                    <EmptyMessageTitle>
                        Need to send SMS?
                    </EmptyMessageTitle>
                    <EmptyMessage message="This feature isn't activated yet. Contact our friendly support team to get started!" />
                </EmptyMessageContainer>
            </SMSChatLayout>
        );
    }

    if (!activeCustomer) {
        return (
            <ConversationsList
                onRequestClose={handleCloseChat}
                onCustomerSelect={setActiveCustomer}
            />
        );
    }

    return (
        <SMSChat
            onBackClick={() => setActiveCustomer(null)}
            customerData={activeCustomer as unknown as EntityGet<Customer>}
            onRequestClose={handleCloseChat}
        />
    );
};

export default SMSChatModal;
