/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const lessThanEqual: Snippet = {
    label: 'lessThanEqual',
    body: '{{#lessThanEqual ${1:value1} ${2:value2}}}\n\t${0}\n{{/lessThanEqual}}',
    documentation: 'lessThanEqual statement',
};

export default lessThanEqual;
