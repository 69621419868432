import React from 'react';
import { RenderIf } from 'react-rainbow-components';
import { StyledLink, Label, StyledBadge } from './styled';

interface OptionProps {
    to: string;
    icon?: React.ReactNode;
    label: string;
    notifications?: number | null;
    variant?: 'error' | 'warning' | 'brand' | 'success' | 'inverse';
    isActive?: boolean;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const Option = (props: OptionProps) => {
    const {
        to, icon, label, variant, notifications, isActive, onClick,
    } = props;

    return (
        <StyledLink to={to} isActive={!!isActive} onClick={onClick}>
            {icon}
            {' '}
            <Label>{label}</Label>
            <RenderIf isTrue={!!notifications}>
                <StyledBadge label={notifications} title="New Tasks" size="small" variant={variant} />
            </RenderIf>
        </StyledLink>
    );
};

export default Option;
