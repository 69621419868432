import styled from 'styled-components';
import { Spinner, DateTimePicker } from 'react-rainbow-components';
import CustomerPicker from 'components/CustomerPicker';
import { GREY_400 } from '../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    height: 100%;
`;

export const Instructions = styled.pre`
    padding: 0 1.5rem;
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_400};
    white-space: preserve-breaks;
`;

export const JsonView = styled.div`
    padding: 0 1.5rem;
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    align-self: stretch;
    overflow: auto;
    color: ${GREY_400};
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    -moz-box-align: stretch;
    align-items: stretch;
    overflow: auto;
    height: 100%;
    gap: 1rem;
    padding: 0.75rem 2.25rem 0px;
`;

export const Footer = styled.div<{ align?: string; }>`
    display: flex;
    padding: 0.5rem 1.25rem 0.5rem 2.25rem;
    justify-content: ${(props) => props.align || 'flex-start'};
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    z-index: unset;
    transform: unset;
    left: unset;
    top: unset;
    width: 1rem;
    height: 1rem;
`;

export const Row = styled.div`
    display: flex;
    flex: 0;
    align-items: flex-start;
    gap: 1rem;
`;

export const FieldLabel = styled.div`
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    padding: 0;
`;

export const StyledCustomerPicker = styled(CustomerPicker)`
    display: flex;
    flex: 1;
    max-width: 40%;

    label {
        margin-left: 0;
    }
`;

export const StyledDatePicker = styled(DateTimePicker)`
    display: flex;
    flex: 1;
    max-width: 300px;

    label {
        margin-left: 0;
    }
`;
