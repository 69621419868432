import { useEffect, useState } from 'react';
import { SortByPickerValue } from 'components/SortByPIcker';
import useStoredValue from 'data/hooks/useStoredValue';

const defaultSort: SortByPickerValue = { sortBy: 'createdAt', order: 'asc' };

const useStoredSort = (id: string) => {
    const { get, save } = useStoredValue<Record<string, SortByPickerValue>>('sorts');
    const [sort, setSort] = useState<SortByPickerValue>();

    useEffect(() => {
        const savedSorts = get() || {};
        const savedSort = savedSorts[id];
        if (savedSort) {
            setSort(savedSort);
        } else {
            setSort(defaultSort);
        }
    }, [get, id]);

    useEffect(() => {
        if (!sort) {
            return;
        }

        const savedSorts = get() || {};
        const savedSort = savedSorts[id];
        const isSameSort = JSON.stringify(savedSort) === JSON.stringify(sort);
        if (isSameSort) {
            return;
        }
        save({ ...savedSorts, [id]: sort });
    }, [get, save, sort, id]);

    return [sort, setSort] as const;
};

export default useStoredSort;
