import styled, { css } from 'styled-components';
import HappyFace from 'components/icons/happyFace';
import Input from 'components/Input';
import LinkWithQuery from 'components/LinkWithQuery';
import UserAvatar from 'components/UserAvatar';
import {
    GREY_300, GREY_800, GREY_400, GREY_500, WHITE,
} from '../../../constants';

interface UreadProps {
    isNotRead?: boolean;
}

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const PaginationInformation = styled.p`
    margin: 0;
    color: ${GREY_300};
    font-size: 0.9375rem;
    font-weight: 400;
    margin-right: 0.75rem;
`;

export const StyledUl = styled.ul`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: scroll;
`;

export const StyledLi = styled.li`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const StyledInput = styled(Input)`
    position: absolute;
    top: calc(50% - 14px);
    left: 1.25rem;
    z-index: 1;
`;

export const StyledLink = styled(LinkWithQuery).withConfig({
    shouldForwardProp: (prop) => !['isNotRead'].includes(prop),
})<UreadProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 1.25rem;
    text-decoration: none;
    color: ${GREY_800};
    padding: 0.95rem 1.25rem 0.95rem 3.5rem;
    font-size: 0.9375rem;
    box-shadow: 0px 0px 0px 0px transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #DBDADE;
    transition: border 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    background: #f8f8f8;

    &:hover, &:focus, &:active {
        color: ${GREY_800};
        text-decoration: none;
        box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
        border-top: 1px solid #DBDADE;
        border-bottom: 1px solid transparent;
        transform: translateY(-2px);
        background: #f8f8f8;
    }

    ${(props) => props.isNotRead && css`
        background: ${WHITE};

        &:hover, &:focus, &:active {
            color: ${GREY_800};
            text-decoration: none;
            box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.30);
            border-top: 1px solid #DBDADE;
            border-bottom: 1px solid transparent;
            transform: translateY(-2px);
            background: ${WHITE};
        }
    `}
`;

export const StyledAvatar = styled(UserAvatar)`
    width: 2rem;
    height: 2rem;
    font-size: 0.8125rem;
    flex-shrink: 0;
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    overflow: hidden;
    flex-grow: 1;
`;

export const Label = styled.h2<UreadProps>`
    width: 9rem;
    min-width: 9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${GREY_500};
    font-weight: 300;
    font-size: 0.9375rem;


    ${(props) => props.isNotRead && css`
        font-weight: 500;
        color: ${GREY_800};
    `}
`;

export const Description = styled.p<UreadProps>`
    font-size: 0.9375rem;
    line-height: 1.375rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${GREY_400};
    flex-grow: 1;
    font-weight: 300;

    ${(props) => props.isNotRead && css`
        font-weight: 500;
        color: ${GREY_800};
    `}
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 0.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    width: fit-content;

`;

export const Date = styled.p`
    font-size: 0.8125rem;
    line-height: 1.25rem;
    text-align: right;
    color: ${GREY_300};
    white-space: nowrap;
`;

export const LoadingContainer = styled.li`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    padding: 0.95rem 1.25rem;

    & > div:nth-child(1) {
        flex: 1;
    }
    & > div:nth-child(2) {
        flex: 2;
    }
    & > div:nth-child(3) {
        flex: 0.5;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const EmptyMessageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    gap: 0.5rem;
`;

export const EmptyMessage = styled.p`
    font-size: 0.9375rem;
    color: ${GREY_400};
    font-weight: 300;
`;

export const Icon = styled(HappyFace)`
    width: 1.5rem;
    height: 1.5rem;
    color: ${GREY_400};
`;
