import {
    useCallback, useMemo, useRef, useState,
} from 'react';
import { InternalOverlay, Option } from 'react-rainbow-components';
import { OptionProps } from 'react-rainbow-components/components/Option';
import { useOutsideClick, useWindowResize, useWindowScrolling } from '@rainbow-modules/hooks';
import { DropdownValue } from 'react-rainbow-components/components/InternalDropdown';
import FilterIcon from 'components/icons/filter';
import { StyledButtonIcon, StyledInternalDropdown } from './styled';

const StatusFilter = ({
    value: valueInProps = [],
    options = [],
    onChange = () => {},
}: {
    value?: string[];
    options?: OptionProps[];
    onChange?: (value: string[]) => void;
}) => {
    const triggerRef = useRef();
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    useWindowScrolling(() => setIsOpen(false), isOpen);
    useWindowResize(() => setIsOpen(false), isOpen);
    useOutsideClick(dropdownContainerRef, () => setIsOpen(false), isOpen);

    const toggleOpen = useCallback(
        () => setIsOpen(!isOpen),
        [isOpen],
    );

    const value = useMemo(() => {
        if (valueInProps.length === 0) return undefined;
        return options.filter((option) => valueInProps.includes(option.value)) as DropdownValue;
    }, [valueInProps, options]);

    const handleOnChange = useCallback(
        (newValues: DropdownValue | DropdownValue[]) => {
            const normalizedValues = (Array.isArray(newValues) ? newValues : [newValues]);
            onChange(
                normalizedValues.map(({ value: newValue }) => newValue),
            );
        },
        [onChange],
    );

    const optionsList = useMemo(
        () => options.map(
            (option) => <Option key={option.name} {...option} />,
        ),
        [options],
    );

    return (
        <>
            <StyledButtonIcon
                ref={triggerRef}
                borderRadius="semi-square"
                icon={<FilterIcon />}
                onClick={toggleOpen}
            />
            <InternalOverlay
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => triggerRef.current.htmlElementRef}
                isVisible={isOpen}
            >
                <div ref={dropdownContainerRef}>
                    <StyledInternalDropdown
                        borderRadius="semi-square"
                        multiple
                        showCheckbox
                        value={value}
                        onChange={handleOnChange}
                    >
                        <Option variant="header" label="Filter Column Status" />
                        {optionsList}
                    </StyledInternalDropdown>
                </div>
            </InternalOverlay>
        </>
    );
};

export default StatusFilter;
