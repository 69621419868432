import { getFormatter } from 'data/services/date/formatter';

export default ({
    startAt,
    duration,
    locale = 'en-US',
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: {
    startAt: Date;
    duration: number;
    locale?: string;
    timezone?: string;
}) => {
    const from = new Date(startAt.toLocaleString(locale, { timeZone: timezone }));
    const to = new Date(from.getTime() + duration * 1000);
    const formatter = getFormatter(locale, { hour: '2-digit', minute: '2-digit', hour12: true });

    return [
        formatter.format(from),
        '-',
        formatter.format(to),
    ].join(' ');
};
