import React from 'react';
import { MenuItemContainer } from './styled';

interface ItemProps {
    icon?: React.ReactNode;
    label: string;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

const MenuItem = (props: ItemProps) => {
    const {
        icon, label, isActive, onClick,
    } = props;

    return (
        <MenuItemContainer title={label} isActive={isActive} onClick={onClick}>
            {icon}
            <span>{label}</span>
        </MenuItemContainer>
    );
};

export default MenuItem;
