import { ReactNode } from 'react';
import { FeatureWrapper, StyledSpan, IconContainer } from './styled';

interface Props {
    row?: Record<string, unknown>,
    value?: string;
}

const FeatureColumn = ({ row, value }: Props) => (
    <FeatureWrapper>
        <IconContainer>
            {row?.icon as ReactNode}
        </IconContainer>
        <StyledSpan>
            {value}
        </StyledSpan>
    </FeatureWrapper>
);

export default FeatureColumn;
