import { DateTime } from 'luxon';

const getDate = (dateValue?: Date, timezone?: string) => {
    let date = (
        dateValue
            ? DateTime.fromJSDate(dateValue)
            : DateTime.now()
    );
    if (timezone) {
        const result = date.setZone(timezone);
        if (result.isValid) {
            date = result;
        }
    }
    return {
        day: date.day,
        month: date.monthLong,
        year: date.year,
        dayOfWeek: date.weekdayLong,
        hour: date.hour,
        minute: date.minute,
        second: date.second,
        weekOfYear: date.weekNumber,
        timeZone: date.zoneName,
    };
};

export default getDate;
