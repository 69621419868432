import StatusIcon from 'components/SMSChat/ChatMessages/Message/StatusIcon';
import { MessageFooterContainer } from './styled';

const MessageFooter = ({
    type,
    content = {},
    isCurrentUser = false,
}: {
    type?: string;
    content?: Record<string, unknown>,
    isCurrentUser?: boolean;
}) => {
    if (type === 'calendar-event-sms-sent') {
        const { smsStatus } = content;
        return (
            <MessageFooterContainer isCurrentUser={isCurrentUser}>
                <StatusIcon status={smsStatus as any} />
            </MessageFooterContainer>
        );
    }
    return null;
};

export default MessageFooter;
