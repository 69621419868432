import {
    MouseEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import { CalendarEventClick } from 'types';
import getUnavailableSlots from './helpers/getUnavailableSlots';
import getHeightOfMinutes from '../helpers/getHeightOfMinutes';
import { CalendarItemContainer, UnavailableTime } from './styled';
import Event from './event';
import getEventPositionInSlot from './helpers/getEventPositionInSlot';

interface CalendarItemProps {
    color: string;
    availability?: Calendar['availability'];
    events?: Calendarevent[];
    timeZone: string;
    selectedEvent?: string | null;
    onEventClick?: CalendarEventClick;
    onAvailableSpaceClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const CalendarItem = ({
    color,
    events = [],
    selectedEvent,
    availability = [],
    timeZone,
    onEventClick = () => {},
    onAvailableSpaceClick = () => {},
}: CalendarItemProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);

    const unAvailableSlots = useMemo(
        () => getUnavailableSlots(
            availability.map(({ startAt, duration }) => ({ startAt, duration })),
        ).map(({ startAt, duration }) => (
            <UnavailableTime
                id={`day-view-unavailable-time-slot__${startAt}`}
                key={`day-view-unavailable-time-slot__${startAt}`}
                height={getHeightOfMinutes(duration / 60) - 1}
                top={getHeightOfMinutes(startAt / 60) + 1}
                onClick={(e) => e.stopPropagation()}
            />
        )),
        [availability],
    );

    const eventsList = useMemo(
        () => events.map(
            (event, index) => {
                const description = `${event.title} (${event.status?.name})`;
                const totalPrevEventsInSameSlot = events
                    .slice(0, index)
                    .filter(
                        ({ startAt }) => startAt.getTime() === event.startAt.getTime(),
                    ).length;
                const totalEventsInSameSlot = events
                    .slice(index - totalPrevEventsInSameSlot)
                    .filter(
                        ({ startAt }) => startAt.getTime() === event.startAt.getTime(),
                    ).length;

                const positionInSlot = getEventPositionInSlot({
                    maxSize: containerWidth,
                    totalEventsInSameSlot,
                    totalPrevEventsInSameSlot,
                });

                return (
                    <Event
                        key={`day-view-event__${event.id}`}
                        title={getDisplayName(event.people)}
                        description={description}
                        color={color}
                        startAt={event.startAt}
                        duration={event.duration}
                        timeZone={timeZone}
                        isSelected={event.id === selectedEvent}
                        isPending={event.status?.name === 'pending-approval' && event.status.removable === false}
                        isRejected={event.status?.name === 'rejected' && event.status.removable === false}
                        positionInSlot={positionInSlot}
                        onEventClick={
                            ({ triggerRef }) => onEventClick({
                                triggerRef,
                                event: event as unknown as Record<string, unknown>,
                            })
                        }
                    />
                );
            },
        ),
        [
            timeZone,
            color,
            events,
            onEventClick,
            selectedEvent,
            containerWidth,
        ],
    );

    useEffect(
        () => {
            if (containerRef.current?.clientWidth) {
                setContainerWidth(containerRef.current?.clientWidth);
            }
        },
        [containerRef.current?.clientWidth],
    );

    return (
        <CalendarItemContainer
            ref={containerRef}
            onClick={onAvailableSpaceClick}
        >
            {unAvailableSlots}
            {eventsList}
        </CalendarItemContainer>
    );
};

export default CalendarItem;
