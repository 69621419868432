import { SpinnerContainer, StyledSpinner, SpinnerMessage } from './styled';

const RecordingMessage = () => (
    <SpinnerContainer>
        <StyledSpinner variant="brand" type="arc" />
        <SpinnerMessage>Recording conversation...</SpinnerMessage>
    </SpinnerContainer>
);

export default RecordingMessage;
