import replaceAlpha from 'react-rainbow-components/styles/helpers/color/replaceAlpha';
import styled, { css } from 'styled-components';
import { color } from 'theme';

export default styled.div<{ position?: 'top' | 'bottom' }>`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.25rem;
    pointer-events: none;
    background-image: linear-gradient(
        to bottom,
        ${(props) => replaceAlpha(color('background.main')(props), 0)},
        ${(props) => replaceAlpha(color('background.main')(props), 0.88)} 71%
    );

    ${(props) => props.position === 'top' && css`
        top: 0;
        bottom: unset;
        background-image: linear-gradient(
            to top,
            ${replaceAlpha(color('background.main')(props), 0)},
            ${replaceAlpha(color('background.main')(props), 0.88)} 71%
        );
    `};
    z-index: 1;
`;
