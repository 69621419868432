import { StyledUniversalForm } from './styled';
import Fields from './fields';

interface FormProps {
    id?: string,
    initialValues?: Record<string, unknown>,
    mode?: 'create' | 'edit',
    onSubmit?: (values: Record<string, unknown>) => void,
}

const Form = ({
    id,
    initialValues,
    mode,
    onSubmit,
}: FormProps) => {
    const onSubmitMiddleware = async (formValues: Record<string, unknown>) => {
        if (onSubmit) onSubmit(formValues);
    };

    return (
        <StyledUniversalForm
            id={id}
            onSubmit={onSubmitMiddleware}
            initialValues={initialValues}
        >
            <Fields mode={mode} />
        </StyledUniversalForm>
    );
};

export default Form;
