import styled from 'styled-components';
import { StyledBadge } from './styled';

interface Props {
    className?: string;
    label?: string;
}

const DateBadge = (props: Props) => {
    const { className, label } = props;
    return <StyledBadge label={label} className={className} borderRadius="semi-square" />;
};

export default DateBadge;

export const StyledDateBadge = styled(DateBadge)<{ isScrolling: boolean }>`
    position: absolute;
    top: 12px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: fit-content;
    opacity: ${(props) => (props.isScrolling ? 1 : 0)};
    visibility: ${(props) => (props.isScrolling ? 'visible' : 'hidden')};
    transition: visibility 1000ms linear, opacity 500ms linear;
`;
