import { useMemo } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import styled from 'styled-components';
import { GREY_500, GREY_300 } from '../../../constants';

const HeaderContainer = styled.div`
    display: flex;
    padding-bottom: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
`;

const HeaderTitle = styled.h1`
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.25rem;
    color: ${GREY_500};
`;

const HeaderSubtitle = styled.h3`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_300};
`;

export const ADD_NOTIFICATION_OPTION_FORM_MODAL = 'add-notification-option-form-modal';

const useAddNotificationOption = () => {
    const [openModal, closeModal] = useOpenModal(ADD_NOTIFICATION_OPTION_FORM_MODAL);
    return useMemo(() => ([
        (props: Record<string, unknown> = { title: 'Title', subtitle: '' }) => {
            const { subtitle, ...otherProps } = props;
            return openModal({
                ...otherProps,
                title: (
                    <HeaderContainer>
                        <HeaderTitle>{props.title as string}</HeaderTitle>
                        <HeaderSubtitle>{subtitle as string}</HeaderSubtitle>
                    </HeaderContainer>
                ),
            });
        },
        closeModal,
    ]), [openModal, closeModal]);
};

export default useAddNotificationOption;
