import UserAvatar from 'components/UserAvatar';
import Eye from 'components/icons/eye';
import Pencil from 'components/icons/pencil2';
import Computer from 'components/icons/computer';
import { RoleCellWrapper, RoleLabel } from './styled';

interface Props {
    value?: string;
}

const iconMap: Record<string, React.ReactNode> = {
    editor: <Pencil />,
    owner: <Computer />,
    read: <Eye />,
    viewer: <Eye />,
    write: <Pencil />,
};

const roleMap: Record<string, string> = {
    editor: 'editor',
    read: 'viewer',
    viewer: 'viewer',
    write: 'editor',
    owner: 'owner',
};

const RoleColumn = ({ value = 'read' }: Props) => (
    <RoleCellWrapper>
        <UserAvatar
            icon={iconMap[value] || <Eye />}
            role={roleMap[value] || 'viewer'}
        />
        <RoleLabel>{value}</RoleLabel>
    </RoleCellWrapper>
);

export default RoleColumn;
