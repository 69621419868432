import { useCallback, useMemo } from 'react';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Close from 'components/icons/close';
import {
    PickersContainer,
    RangePickerContainer,
    Separator,
    StyledPicklist,
    StyledButtonIcon,
} from './styled';

const timeOptions = [
    { name: '0000', label: '12:00am', value: 0 },
    { name: '0030', label: '12:30am', value: 1800 },
    { name: '0100', label: '1:00am', value: 3600 },
    { name: '0130', label: '1:30am', value: 5400 },
    { name: '0200', label: '2:00am', value: 7200 },
    { name: '0230', label: '2:30am', value: 9000 },
    { name: '0300', label: '3:00am', value: 10800 },
    { name: '0330', label: '3:30am', value: 12600 },
    { name: '0400', label: '4:00am', value: 14400 },
    { name: '0430', label: '4:30am', value: 16200 },
    { name: '0500', label: '5:00am', value: 18000 },
    { name: '0530', label: '5:30am', value: 19800 },
    { name: '0600', label: '6:00am', value: 21600 },
    { name: '0630', label: '6:30am', value: 23400 },
    { name: '0700', label: '7:00am', value: 25200 },
    { name: '0730', label: '7:30am', value: 27000 },
    { name: '0800', label: '8:00am', value: 28800 },
    { name: '0830', label: '8:30am', value: 30600 },
    { name: '0900', label: '9:00am', value: 32400 },
    { name: '0930', label: '9:30am', value: 34200 },
    { name: '1000', label: '10:00am', value: 36000 },
    { name: '1030', label: '10:30am', value: 37800 },
    { name: '1100', label: '11:00am', value: 39600 },
    { name: '1130', label: '11:30am', value: 41400 },
    { name: '1200', label: '12:00pm', value: 43200 },
    { name: '1230', label: '12:30pm', value: 45000 },
    { name: '1300', label: '1:00pm', value: 46800 },
    { name: '1330', label: '1:30pm', value: 48600 },
    { name: '1400', label: '2:00pm', value: 50400 },
    { name: '1430', label: '2:30pm', value: 52200 },
    { name: '1500', label: '3:00pm', value: 54000 },
    { name: '1530', label: '3:30pm', value: 55800 },
    { name: '1600', label: '4:00pm', value: 57600 },
    { name: '1630', label: '4:30pm', value: 59400 },
    { name: '1700', label: '5:00pm', value: 61200 },
    { name: '1730', label: '5:30pm', value: 63000 },
    { name: '1800', label: '6:00pm', value: 64800 },
    { name: '1830', label: '6:30pm', value: 66600 },
    { name: '1900', label: '7:00pm', value: 68400 },
    { name: '1930', label: '7:30pm', value: 70200 },
    { name: '2000', label: '8:00pm', value: 72000 },
    { name: '2030', label: '8:30pm', value: 73800 },
    { name: '2100', label: '9:00pm', value: 75600 },
    { name: '2130', label: '9:30pm', value: 77400 },
    { name: '2200', label: '10:00pm', value: 79200 },
    { name: '2230', label: '10:30pm', value: 81000 },
    { name: '2300', label: '11:00pm', value: 82800 },
    { name: '2330', label: '11:30pm', value: 84600 },
    { name: '2400', label: '12:00am', value: 86400 },
];

interface TimeRangePickerProps {
    from: number;
    to: number;
    min: number;
    max: number;
    onChange?: (value: Record<string, number>) => void;
    onDelete?: () => void;
}

const TimeRangePicker = ({
    from,
    to,
    min,
    max,
    onChange = () => {},
    onDelete = () => {},
}: TimeRangePickerProps) => {
    const fromOptions = useMemo(
        () => timeOptions
            .slice(0, -1)
            .filter((option) => option.value >= min && option.value < max)
            .map((option) => <Option key={`from-time_${option.name}`} {...option} />),
        [min, max],
    );

    const toOptions = useMemo(
        () => timeOptions
            .filter((option) => option.value > from && option.value <= max)
            .map((option) => <Option key={`to-time_${option.name}`} {...option} />),
        [from, max],
    );

    const fromPicklistValue = useMemo(() => {
        if (!from) return undefined;
        return timeOptions.find((option) => option.value === from);
    }, [from]);

    const toPicklistValue = useMemo(() => {
        if (!to) return undefined;
        return timeOptions.find((option) => option.value === to);
    }, [to]);

    const handleFromChange = useCallback((newFrom: PicklistValue) => {
        if (newFrom.value < to) {
            onChange({
                from: newFrom.value,
                to,
            });
        }
    }, [onChange, to]);

    const handleToChange = useCallback((newTo: PicklistValue) => {
        onChange({
            from,
            to: newTo.value,
        });
    }, [from, onChange]);

    return (
        <RangePickerContainer>
            <PickersContainer>
                <StyledPicklist
                    borderRadius="semi-rounded"
                    variant="bare"
                    value={fromPicklistValue}
                    onChange={handleFromChange}
                >
                    {fromOptions}
                </StyledPicklist>
                <Separator>-</Separator>
                <StyledPicklist
                    borderRadius="semi-rounded"
                    variant="bare"
                    value={toPicklistValue}
                    onChange={handleToChange}
                >
                    {toOptions}
                </StyledPicklist>
            </PickersContainer>
            <StyledButtonIcon
                size="small"
                borderRadius="semi-rounded"
                icon={<Close />}
                onClick={onDelete}
            />
        </RangePickerContainer>
    );
};

export default TimeRangePicker;
