import styled, { css } from 'styled-components';
import { Picklist } from 'react-rainbow-components';
import { DIVIDER } from '../../constants';

export default styled(Picklist)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border: 1px solid ${DIVIDER};
        border-radius: 0.375rem;
        
        ::placeholder {
            font-weight: 300 !important;
        }
    }

    ${(props) => props.readOnly && css`
        input {
            border: none;
            border-radius: 0.375rem;    
        }
    `};

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;
