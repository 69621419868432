/* eslint-disable react/no-unused-prop-types */
import { ReactNode, useCallback, useMemo } from 'react';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { useReduxForm } from '@rainbow-modules/hooks';
import CalendarIcon from 'components/icons/calendarThin';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { PickerVariantProps } from './types';
import { StyledUniversalPicker, PickerOptionContainer, IconContainer } from './styled';

const PickerItem = ({
    children,
    ...rest
}: { children: ReactNode; }) => (
    <PickerOptionContainer {...rest}>
        {children}
    </PickerOptionContainer>
);

const CalendarPickerVariantPicker = (props: PickerVariantProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        error,
        required,
        calendars = [],
        isLoading = false,
    } = useReduxForm(props);

    const pickerValue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Calendar).id
                );
                const found = calendars.find((calendar) => calendar.id === valueId);
                if (found) {
                    if (typeof valueInProps === 'string') {
                        onChange(found);
                    }
                    return found.id;
                }
            }
            if (calendars.length > 0) {
                const firstInList = calendars[0];
                onChange(firstInList);
                return firstInList.id;
            }
        }
        return undefined;
    }, [calendars, isLoading, onChange, valueInProps]);

    const pickerOptions = useMemo(
        () => calendars.map(
            (calendar) => (
                <UniversalPickerOption
                    key={`calendar__${calendar.id}`}
                    component={PickerItem}
                    name={calendar.id}
                >
                    <IconContainer color={calendar.color}>
                        <CalendarIcon />
                    </IconContainer>
                    {calendar.name}
                </UniversalPickerOption>
            ),
        ),
        [calendars],
    );

    const handleOnChange = useCallback(
        (newValue: string | string[]) => {
            const calendarId = typeof newValue === 'string' ? newValue : newValue[0];
            const found = calendars.find((calendar) => calendar.id === calendarId);
            if (found) {
                onChange(found);
            }
        },
        [calendars, onChange],
    );

    return (
        <StyledUniversalPicker
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            required={required}
            value={pickerValue}
            onChange={handleOnChange}
            error={error}
        >
            {pickerOptions}
        </StyledUniversalPicker>
    );
};

export default CalendarPickerVariantPicker;
