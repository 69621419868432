import ArchiveIcon from 'components/icons/archive';
import {
    ModalContainer, ModalDescription, ModalIconContainer, ModalTitle,
} from '../../pages/Inbox/styled';

const ArchiveModal = () => (
    <ModalContainer>
        <ModalIconContainer>
            <ArchiveIcon />
        </ModalIconContainer>
        <ModalTitle>Are you sure you want to archive this?</ModalTitle>
        <ModalDescription>
            Ensure that all issues have been addressed before proceeding.
        </ModalDescription>
    </ModalContainer>
);

export default ArchiveModal;
