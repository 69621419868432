import styled, { css } from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import Input from 'components/Input';
import {
    PURPLE_500, PURPLE_100, GREY_400, DIVIDER,
} from '../../constants';

export const StyledUniversalPicker = styled(UniversalPicker)`
    & > legend {
        padding: 0;
    }

    & > div {
        gap: 1.5rem;
    }

    & div[data-id="universal-picker-option"] {
        margin: 0;
    }
`;

export const PhoneNumberOptionContainer = styled.div<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean;
}>`
    display: flex;
    padding: 0.75rem;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    border: 1px solid ${DIVIDER};
    border-radius: 0.375rem;
    width: 18.75rem;

    ${(props) => props.isHover && !props.disabled && css`
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
        cursor: pointer;
    `};

    ${(props) => props.isSelected && css`
        background: ${PURPLE_100};
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
    `};

    & > svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;

export const Label = styled.h3`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    margin: 0 0 0.25rem;
    padding: 0;
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
`;

export const Description = styled.p`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${GREY_400};
`;

export const InputRadio = styled(Input)`
    use-select: none;
    pointer-events: none;
`;
