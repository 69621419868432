import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import { useOutletContext, useParams } from 'react-router-dom';
import { showAppNotification } from '@rainbow-modules/app';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import getStageTypeColor from '../../../helpers/getStageColor';
import { ViewContextType } from '../../../types';
import {
    CellWrapper, StyledPicklist, StyledOption, StyledSpinner,
} from './styled';

interface Props {
    row?: EntityGet<Opportunity>;
    value?: string;
}

const StageColumn = ({ row, value: valueInProps }: Props) => {
    const { agentId = '' } = useParams();
    const { stages = [], isLoading } = useOutletContext<ViewContextType>();
    const [value, setValue] = useState<PicklistValue>();
    const [isUpdating, setUpdating] = useState(false);

    const pickListvalue = useMemo(
        () => {
            if (value) return value;
            if (!isLoading) {
                if (valueInProps) {
                    const found = stages.find((stage) => stage.id === valueInProps);
                    if (found) {
                        const {
                            id: stageId,
                            name: stageName,
                            description: stageDescription,
                        } = found;
                        return {
                            label: stageName,
                            name: stageId,
                            value: found,
                            searchableText: `${stageName} - ${stageDescription}`,
                        };
                    }
                }
            }
            return undefined;
        },
        [isLoading, stages, valueInProps, value],
    );

    const pickListOptions = useMemo(
        () => stages.map(
            (stage) => (
                <StyledOption
                    key={`pipeline-stage__${stage.id}`}
                    label={stage.name}
                    name={stage.id}
                    value={stage}
                    searchableText={`${stage.name} - ${stage.description}`}
                    isBuiltIn={!stage.removable}
                />
            ),
        ),
        [stages],
    );

    const color = useMemo(
        () => getStageTypeColor(pickListvalue?.value.type),
        [pickListvalue],
    );

    const { mutateAsync: updateOpportunity } = useHttpMutation({
        pathname: `/agents/${agentId}/opportunities/${row?.id}`,
        method: 'PATCH',
    });

    const handleOnChange = useCallback(
        async (newValue: PicklistValue) => {
            setValue(newValue);
            setUpdating(true);
            try {
                await updateOpportunity({
                    body: {
                        stageId: newValue.name,
                    },
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
                setValue(undefined);
            }
            setUpdating(false);
        },
        [updateOpportunity],
    );

    useEffect(
        () => setValue(undefined),
        [valueInProps],
    );

    return (
        <CellWrapper>
            <StyledPicklist
                id="select-stage"
                label="Select Stage"
                borderRadius="semi-square"
                size="small"
                onChange={handleOnChange}
                hideLabel
                value={pickListvalue}
                isLoading={isLoading}
                disabled={isUpdating}
                color={color}
            >
                {pickListOptions}
            </StyledPicklist>
            <RenderIf isTrue={isUpdating}>
                <StyledSpinner variant="base" type="arc" size="xx-small" />
            </RenderIf>
        </CellWrapper>
    );
};

export default StageColumn;
