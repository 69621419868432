import { useMemo } from 'react';
import UserAvatar from 'components/UserAvatar';
import { Apikey } from 'data/firestore/agent/apikey/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { UserCellWrapper } from './styled';

interface Props {
    row?: Apikey;
}

const CreatedBy = ({ row }: Props) => {
    const {
        uid = '', displayName, email = '', photoURL,
    } = row?.createdBy || {};

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName || '');
    }, [displayName]);

    const label = useMemo(() => {
        const name = getDisplayName(row?.createdBy);
        return (
            email ? `${name} (${email})` : name
        );
    }, [email, row]);

    return (
        <UserCellWrapper>
            <UserAvatar
                src={photoURL}
                initials={initials}
                value={uid || email}
            />
            {label}
        </UserCellWrapper>
    );
};

export default CreatedBy;
