import styled from 'styled-components';
import { BadgeOverlay } from 'react-rainbow-components';
import UserAvatar from 'components/UserAvatar';
import LoadingShape from 'components/LoadingShape';
import { Link } from 'react-router-dom';
import {
    GREY_300, DIVIDER, GREY_800, GREY_600, GREY_400, GREY_500, PURPLE_100, GREY_100,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    padding: 1.5rem 1.5rem 8rem 1.5rem;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    gap: 1.5rem;
    height: 100%;
    overflow: scroll;
`;

export const AvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${DIVIDER};
`;

export const StyledAvatar = styled(UserAvatar)`
    width: 7.5rem;
    height: 7.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: 300;

    svg {
        width: 4rem;
        height: 4rem;
    }

    abbr {
        font-size: 2.5rem;
    }
`;

export const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
`;

export const NameLabel = styled.label`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.75rem;
    color: ${GREY_800};
    margin: 0;
    text-align: center;
`;

export const QuickActions = styled.div`
    display: flex;
`;

export const SectionTitle = styled.div`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${GREY_300};
    text-transform: uppercase;
    margin-bottom: 1rem;
`;

export const ConversationSummaryContainer = styled.div`
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.4rem;
    letter-spacing: 0.4px;
    text-align: left;
    color: ${GREY_500};
`;

export const DetailsElements = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

export const ElementContainer = styled.div`
    display: flex;
    align-self: stretch;
    color: ${GREY_500};
    margin: 0;
`;

export const IconContainer = styled.div`
    padding: 0.75rem;
    color: ${GREY_400};

    > svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

export const StyledLoadingShape = styled(LoadingShape)`
    margin-bottom: 5px;
`;

export const Row = styled.div`
    display: inline-flex;
    flex-direction: row;
`;

export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    padding-right: 0.5rem;
    padding-right: 5px;
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.81rem 0 0.5rem;
`;

export const Label = styled.div`
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${GREY_600};
`;

export const Value = styled.div`
    color: ${GREY_600};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
`;

export const Footer = styled.footer`
    padding: 0 1rem 1rem 1rem;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
    display: flex;
    box-sizing: border-box;
`;

export const Gradient = styled.div`
    height: 8rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    position: absolute;
    pointer-events: none;
    bottom: 56px;
    left: 0;
    right: 0;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${GREY_500};
    padding: 12px;
    border-radius: 100%;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
        color: rgb(103, 92, 216);
        background: ${PURPLE_100};
    }
`;

export const StyledBadgeOverlay = styled(BadgeOverlay)`
    & > span {
        width: 0.625rem;
        height: 0.625rem;
        box-sixing: border-box;
        border: 2px solid ${GREY_100};
        right: 0.325rem;
        border-radius: 100%;
    }
`;
