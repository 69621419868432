import styled from 'styled-components';
import { GREY_600, TEAL_700 } from '../../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
    margin-top: 0.5rem;
    align-self: stretch;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
`;

export const StyledImage = styled.img`
    max-width: 183px;
    max-height: 160px;
    min-width: 185px;
    min-height: 160px;
    object-fit: cover;
    border-radius: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

export const TitleDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0.25rem 0;
`;

export const Title = styled.h3`
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    color: ${TEAL_700};
`;

export const Description = styled.p`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    color: ${GREY_600};
`;
