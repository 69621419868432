import { collection, query, getCountFromServer } from "firebase/firestore";
import { ListServiceOpts } from "../../types";
import { db } from "../../firebase";

export type CustomerCountService = (
  agentId: string,
  opts?: ListServiceOpts
) => Promise<number>;

const count: CustomerCountService = async (agentId, opts = {}) => {
  const collectionRef = collection(db, `/agent/${agentId}/customer`);
  const q =
    typeof opts.listQueryFn === "function"
      ? opts.listQueryFn(collectionRef)
      : query(collectionRef);
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};

export default count;
