import { Fragment, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import EmptyMessage from 'components/EmptyMessage';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import { CalendarEventClick } from 'types';
import {
    Container,
    ItemContainer,
    Label,
    SectionHeader,
    StatusBadge,
    StyledDot,
    TimeLabel,
} from './styled';
import getTimeLabel from './helpers/getTimeLabel';

interface Params {
    calendars?: Calendar[],
    events?: Calendarevent[],
    onEventClick?: CalendarEventClick;
    timeZone?: string;
}

const CalendarListView = ({
    calendars = [],
    events = [],
    onEventClick = () => {},
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: Params) => {
    const items = useMemo(
        () => calendars.map((calendar) => {
            const eventsList = events
                .filter((event) => event.calendarId === calendar.id)
                .map(
                    (event) => {
                        const timeStr = getTimeLabel({
                            startAt: event.startAt,
                            duration: event.duration,
                            timezone: timeZone,
                        });
                        const isPending = event.status?.name === 'pending-approval';
                        return (
                            <ItemContainer
                                key={`event__${event.id}`}
                                isPending={isPending}
                                onClick={
                                    ({ target }) => onEventClick({
                                        triggerRef: {
                                            current: target,
                                        },
                                        event: event as unknown as Record<string, unknown>,
                                    })
                                }
                            >
                                <TimeLabel>{timeStr}</TimeLabel>
                                <StyledDot color={calendar.color} />
                                <Label>{`${getDisplayName(event.people)} - ${event.title}`}</Label>
                                <RenderIf isTrue={event.status}>
                                    <StatusBadge color={event.status?.color as string}>
                                        {event.status?.name}
                                    </StatusBadge>
                                </RenderIf>
                            </ItemContainer>
                        );
                    },
                );
            return (
                <Fragment key={`header__${calendar.id}`}>
                    <SectionHeader>{calendar.name}</SectionHeader>
                    <RenderIf isTrue={eventsList.length > 0}>
                        {eventsList}
                    </RenderIf>
                    <RenderIf isTrue={eventsList.length === 0}>
                        <EmptyMessage message="There are no events" />
                    </RenderIf>
                </Fragment>
            );
        }),
        [calendars, events, onEventClick, timeZone],
    );

    return (
        <Container>
            <RenderIf isTrue={calendars.length > 0}>
                {items}
            </RenderIf>
            <RenderIf isTrue={calendars.length === 0}>
                <EmptyMessage message="There are no calendars" />
            </RenderIf>
        </Container>
    );
};

export default CalendarListView;
