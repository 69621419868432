/* eslint-disable react/forbid-component-props */
import { forwardRef } from 'react';
import useTimer from '../hooks/useTimer';
import {
    StyledHours, StyledHour, StyledTitleHour,
} from './styled';

interface HoursProps {
    locale?: string,
}

const Hours = forwardRef<HTMLDivElement, HoursProps>((props, ref) => {
    const { locale } = props;
    const clock = useTimer();

    const before = new Date(clock);
    before.setMinutes(before.getMinutes() + 15);
    const after = new Date(clock);
    after.setMinutes(after.getMinutes() - 15);

    function ListHours() {
        const hour = new Date();
        hour.setMinutes(0);
        hour.setSeconds(0);
        return Array.from(Array(24), (_value, index) => {
            hour.setHours(index);
            const formattedHours = new Intl.DateTimeFormat(locale, {
                hour: 'numeric',
                hour12: true,
            }).format(hour);
            return (
                <StyledHour key={index}>
                    <StyledTitleHour>
                        {formattedHours}
                    </StyledTitleHour>
                </StyledHour>
            );
        });
    }

    return (
        <StyledHours ref={ref} data-id="25">
            <ListHours />
        </StyledHours>
    );
});

export default Hours;
