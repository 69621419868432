import { useMemo } from 'react';
import getHeightOfMinutes from '../helpers/getHeightOfMinutes';
import { Highlight } from './styled';

interface HighligthedHoursProps {
    color: string;
    startAt: number;
    duration: number;
}

const HighligthedHours = (props:HighligthedHoursProps) => {
    const { color, startAt, duration } = props;
    const style = useMemo(
        () => ({
            height: `${getHeightOfMinutes(duration / 60)}px`,
            top: `${getHeightOfMinutes(startAt / 60)}px`,
        }),
        [duration, startAt],
    );

    return (
        // eslint-disable-next-line react/forbid-component-props
        <Highlight style={style} color={color} />
    );
};

export default HighligthedHours;
