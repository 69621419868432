/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'if',
    body: '{{#if ${1:condition}}}\n\t${0}\n{{/if}}',
    documentation: 'if statement',
};

export default snippet;
