import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import FormModal from 'components/FormModal';
import useEventStatus from 'data/firestore/agent/calendareventstatus/useCollection';
import useHttpMutation from 'data/firestore/useHttpMutation';
import ModalTitle from 'components/ModalTitle';
import { ADD_EDIT_CALENDAR_EVENT_STATUS } from '../../../../constants';
import {
    Container, Header, Description, Title, TitleContainer, Content,
} from './styled';
import EventStatus from './item';
import Fields from './fields';
import LoadingShape from '../loading';
import { StyledEmptyMessage } from '../styled';

const CalendarEventStatus = () => {
    const { agentId = '' } = useParams();
    const connectedModalProps = useConnectModal(ADD_EDIT_CALENDAR_EVENT_STATUS);
    const [
        openEventStatusForm,
        closeEventStatusForm,
    ] = useOpenModal(ADD_EDIT_CALENDAR_EVENT_STATUS);

    const { data = [], isLoading } = useEventStatus(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    const { mutateAsync: createEventStatus } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/event-statuses`,
        onSuccess: closeEventStatusForm,
    });

    const handleAddNewEventStatus = useCallback(
        () => openEventStatusForm({
            title: <ModalTitle>Create Event Status</ModalTitle>,
            message: 'Assign a name and color to a new event status for quick recognition.',
            submitButtonLabel: 'Create',
            onSubmit: async (
                {
                    name,
                    color,
                }: {
                    name: string;
                    color: string;
                },
            ) => {
                showAppSpinner();
                try {
                    await createEventStatus({
                        body: {
                            name,
                            color,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [createEventStatus, openEventStatusForm],
    );

    const eventyStatuses = useMemo(
        () => {
            if (data.length === 0) {
                return (
                    <StyledEmptyMessage message="No event statuses have been defined yet." />
                );
            }

            return data.map(
                (status) => (
                    <EventStatus
                        id={status.id}
                        name={status.name}
                        color={status.color}
                        removable={status.removable}
                    />
                ),
            );
        },
        [data],
    );
    return (
        <>
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>Event Status</Title>
                        <Description>
                            Customize your calendar by assigning unique status labels to events,
                            aiding in the clear organization and tracking of event progress.
                        </Description>
                    </TitleContainer>
                    <Button
                        variant="outline-brand"
                        borderRadius="semi-square"
                        label="New Status"
                        onClick={handleAddNewEventStatus}
                    />
                </Header>
                <Content>
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {eventyStatuses}
                    </RenderIf>
                </Content>
            </Container>
            <FormModal
                borderRadius="semi-square"
                size="small"
                fields={Fields}
                {...connectedModalProps}
            />
        </>
    );
};

export default CalendarEventStatus;
