import styled, { css } from 'styled-components';
import Badge from 'components/Badge';
import { Link } from 'react-router-dom';
import {
    PURPLE_500,
    GREY_600,
    GREY_300,
    PURPLE_100,
    GREY_100,
    RED_100,
    RED_500,
    GREEN_500,
    WARNING,
    ORANGE_500,
    ORANGE_100,
    WHITE,
} from '../../../constants';

interface StyledLinkProps {
    isActive: boolean;
}

export const StyledLink = styled(Link).withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<StyledLinkProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 0.75rem 0.75rem;
    margin: 0;
    transition: background-color 0.2s ease-in-out;
    gap: 0.5rem;
    color: ${GREY_600};
    border-radius: 3rem;

    > svg {
        color: ${GREY_300};
    }

    &:hover, &:focus, &:active {
        color: ${GREY_600};
        text-decoration: none;
        background-color: ${GREY_100};
    }

    ${(props) => props.isActive && css`
        color: ${PURPLE_500};
        background-color: ${PURPLE_100};

        > svg {
            color: ${PURPLE_500};
        }

        &:hover, &:focus, &:active {
            color: ${PURPLE_500};
            background-color: ${PURPLE_100};
        }
    `}
`;

export const Label = styled.span`
    flex-grow: 1;
    margin-left: 0.5rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.375rem;
`;

export const StyledBadge = styled(Badge)`

    ${(props) => props.variant === 'error' && css`
        color: ${RED_100};
        background-color: ${RED_500};
    `}

    ${(props) => props.variant === 'success' && css`
        color: ${WHITE};
        background-color: ${GREEN_500};
    `}

    ${(props) => props.variant === 'warning' && css`
        color: ${WHITE};
        background-color: ${WARNING};
    `}

    ${(props) => props.variant === 'brand' && css`
        color: ${PURPLE_100};
        background-color: ${PURPLE_500};
    `}

    ${(props) => props.variant === 'inverse' && css`
        color: ${ORANGE_100};
        background-color: ${ORANGE_500};
    `}
`;
