import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OptionProps } from 'react-rainbow-components/components/Option';
import { DateTime } from 'luxon';
import useAgentData from 'hooks/useAgentData';
import SummaryAffiliatesItem from 'components/SummaryAffiliatesItem';
import { Column } from 'react-rainbow-components';
import Table from 'components/Table';
import CalendarIcon from 'components/icons/calendarThin';
import Pagination from 'components/Pagination';
import DataExportPicker from 'components/DataExportPicker';
import { PURPLE_100, PURPLE_500, PAGE_SIZE } from '../../../constants';
import useAffiliateBookingsInsights from '../hooks/useAffiliateBookingsInsights';
import {
    IconContainer, SecondaryInsightSectionContainer,
    StyledProgressCircular, PercentLabel,
} from '../styled';
import {
    EntriesText, FilterContainer, PaginationContainer, SumaryContainer,
} from './styled';
import UserColumn from './columns/user';
import BookingsAmountColumn from './columns/value';
import { statusColorMap, statusLabelMap } from '../helpers/mappers';
import DateFilter from './dateFilter';
import SearchFilter from './searchFilter';
import StatusFilter from './statusFilter';

const getValue = (obj: Record<string, { [key: string]:number }>, key: string) => {
    if (!obj[key]) {
        return {
            value: 0,
            percent: 0,
        };
    }

    return obj[key];
};

const statusFilterOptions = Object
    .keys(statusLabelMap)
    .reduce(
        (options: OptionProps[], key) => {
            if (key === 'total-bookings') return options;
            return [
                ...options,
                {
                    name: key,
                    label: statusLabelMap[key],
                    value: key,
                },
            ];
        },
        [],
    );

const AffiliateBookingsInsights = () => {
    const { agentId = '' } = useParams();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const [statusFilter, setStatusFilter] = useState<string[]>(Object.keys(statusLabelMap));
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState<string | Date[]>('current-month');
    const [activePage, setActivePage] = useState(1);

    const { from, to } = useMemo(
        () => {
            if (!agent) return {};
            const timeZone = agent?.companyAddress.timezone
                || Intl.DateTimeFormat().resolvedOptions().timeZone;

            const now = DateTime.now().setZone(timeZone);

            if (Array.isArray(dateFilter)) {
                const [fromDate, toDate] = dateFilter;
                return {
                    from: DateTime.fromJSDate(fromDate).setZone(timeZone, { keepLocalTime: true }).startOf('day'),
                    to: toDate && DateTime.fromJSDate(toDate).setZone(timeZone, { keepLocalTime: true }).endOf('day'),
                };
            }

            if (dateFilter === 'current-month') {
                return {
                    from: now.startOf('month'),
                    to: now.endOf('month'),
                };
            }

            if (dateFilter === 'last-month') {
                return {
                    from: now.minus({ month: 1 }).startOf('month'),
                    to: now.minus({ month: 1 }).endOf('month'),
                };
            }

            return {
                from: now.minus({ months: 12 }).startOf('month'),
                to: now.endOf('month'),
            };
        },
        [agent, dateFilter],
    );

    const {
        isLoading: isLoadingData,
        globalStats = {},
        byAffiliate = [],
    } = useAffiliateBookingsInsights({
        agentId,
        from: from?.toJSDate(),
        to: to?.toJSDate(),
    });

    const globals = useMemo(
        () => Object.keys(globalStats).reduce(
            (result, key) => ({
                ...result,
                [key]: {
                    value: globalStats[key],
                    percent: 100 * (globalStats[key] / globalStats.totalBookings),
                },
            }),
            {} as Record<string, { value: number; percent: number; }>,
        ),
        [globalStats],
    );

    const filteredData = useMemo(
        () => {
            if (!search) return byAffiliate;
            return byAffiliate.filter(
                ({ displayName = 'Unknown', phoneNumber = '' }) => (
                    (displayName as string).toLowerCase().indexOf(search.toLowerCase()) >= 0
                        || (phoneNumber as string).toLowerCase().indexOf(search.toLowerCase()) >= 0
                ),
            );
        },
        [byAffiliate, search],
    );

    const totalHits = filteredData.length;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = totalHits === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + totalHits,
    );
    const dataToShow = filteredData.slice(firstItemOfActivePage - 1, lastItemOfActivePage);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingData) return;
        if (pages === 0) return;
        if (page === activePage) return;
        setActivePage(page);
    };

    return (
        <SecondaryInsightSectionContainer
            label="Affiliate Booking Details"
            description="View the affiliate's booking numbers and status updates."
            isLoading={isLoadingAgent || isLoadingData}
            filter={(
                <FilterContainer>
                    <SearchFilter value={search} onChange={setSearch} />
                    <DataExportPicker
                        data={byAffiliate}
                        columns={['displayName', 'phoneNumber', ...Object.keys(statusColorMap)]}
                    />
                    <DateFilter value={dateFilter} onChange={setDateFilter} />
                    <StatusFilter
                        value={statusFilter}
                        options={statusFilterOptions}
                        onChange={setStatusFilter}
                    />
                </FilterContainer>
            )}
        >
            <SumaryContainer>
                <SummaryAffiliatesItem
                    label="Total Bookings"
                    value={globals.totalBookings.value}
                    icon={(
                        <IconContainer color={PURPLE_500} background={PURPLE_100}>
                            <CalendarIcon />
                        </IconContainer>
                    )}
                />
                <SummaryAffiliatesItem
                    label="Accepted"
                    value={getValue(globals, 'accepted').value}
                    percentLabel={(
                        <PercentLabel>
                            {`${getValue(globals, 'accepted').percent.toFixed(2)}%`}
                        </PercentLabel>
                    )}
                    icon={(
                        <IconContainer>
                            <StyledProgressCircular
                                value={getValue(globals, 'accepted').percent}
                                color={statusColorMap.accepted}
                            />
                        </IconContainer>
                    )}
                />
                <SummaryAffiliatesItem
                    label="Rejected"
                    value={getValue(globals, 'rejected').value}
                    percentLabel={(
                        <PercentLabel>
                            {`${getValue(globals, 'rejected').percent.toFixed(2)}%`}
                        </PercentLabel>
                    )}
                    icon={(
                        <IconContainer>
                            <StyledProgressCircular
                                value={getValue(globals, 'rejected').percent}
                                color={statusColorMap.rejected}
                            />
                        </IconContainer>
                    )}
                />
                <SummaryAffiliatesItem
                    label="Pending"
                    value={getValue(globals, 'pending-approval').value}
                    percentLabel={(
                        <PercentLabel>
                            {`${getValue(globals, 'pending-approval').percent.toFixed(2)}%`}
                        </PercentLabel>
                    )}
                    icon={(
                        <IconContainer>
                            <StyledProgressCircular
                                value={getValue(globals, 'pending-approval').percent}
                                color={statusColorMap['pending-approval']}
                            />
                        </IconContainer>
                    )}
                />
            </SumaryContainer>
            <Table data={dataToShow} keyField="uid">
                <Column header="Affiliate Name" component={UserColumn} />
                <Column
                    header="Total Bookings"
                    field="total-bookings"
                    component={BookingsAmountColumn}
                    hidePercent
                />
                {statusFilter.includes('accepted') && (
                    <Column
                        header="Bookings Accepted"
                        field="accepted"
                        component={BookingsAmountColumn}
                    />
                )}
                {statusFilter.includes('rejected') && (
                    <Column
                        header="Bookings Rejected"
                        field="rejected"
                        component={BookingsAmountColumn}
                    />
                )}
                {statusFilter.includes('pending-approval') && (
                    <Column
                        header="Bookings Pending"
                        field="pending-approval"
                        component={BookingsAmountColumn}
                    />
                )}
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </SecondaryInsightSectionContainer>
    );
};

export default AffiliateBookingsInsights;
