import { Plan } from 'types';
import getTierDescription from 'data/services/price/getTierDescription';

const getPlanDescription = (plan: Plan) => {
    const tiers = plan.tiers.map((tier) => getTierDescription(tier, plan.currency));
    return tiers.reduce((acc, tierDescription, index) => {
        if (index === tiers.length - 1) {
            return `${acc} and then ${tierDescription}`;
        }
        return `${acc}, ${tierDescription}`;
    });
};

export default getPlanDescription;
