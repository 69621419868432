import {
    SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Timestamp } from 'firebase/firestore';
import { isEmpty } from '@rainbow-modules/validation';
import { RenderIf } from 'react-rainbow-components';
import AccordionSection from 'components/AccordionSection';
import CalendarIcon from 'components/icons/calendarThin';
import Check from 'components/icons/check';
import Clock from 'components/icons/clock';
import Note from 'components/icons/note';
import { EntityGet } from 'data/firestore/types';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import useEvent from 'data/firestore/agent/calendarevent/use';
import useCalendar from 'data/firestore/agent/calendar/use';
import DetailElement from 'components/CalendarEventDetails/detail';
import { EventDetails } from 'components/CalendarEventDetails/styled';
import { getFormatter } from 'data/services/date/formatter';
import { formatDurationFromSeconds } from 'data/services/duration/formatter';
import { Calendar } from 'data/firestore/agent/calendar/types';
import useAgentTimeZone from 'hooks/useAgentTimeZone';
import CalendarEventStatusBadge from './statusBadge';
import {
    Container, PrimaryText, StyledAccordion,
} from './styled';

interface EventData extends Pick<Calendarevent, 'status' | 'notes'> {
    startAt?: Timestamp;
}

interface CalendarEventInformationProps {
    agentId: string;
    eventId: string;
    data?: EventData;
    locale?: string;
    rescheduled?: boolean;
}

const CalendarEventInformation = ({
    agentId = '',
    eventId = '',
    data,
    locale = 'en-US',
    rescheduled = false,
}: CalendarEventInformationProps) => {
    const timeZone = useAgentTimeZone();
    const [activeSection, setActiveSection] = useState<string | string[]>();
    const [fetchData, setFetchData] = useState(false);
    const [eventData, setEventData] = useState<EntityGet<Calendarevent> | null>();
    const [calendarData, setCalendarData] = useState<EntityGet<Calendar> | null>();

    const { data: eventInfo, isLoading: isLoadingEvent } = useEvent(agentId, eventId, {
        disabled: !agentId || !eventId || !fetchData || Boolean(eventData),
        track: [agentId, eventId],
    });

    const { data: calendar, isLoading: isLoadingCalendar } = useCalendar(
        agentId,
        eventInfo?.calendarId as string,
        {
            disabled: !eventInfo?.calendarId || !fetchData || Boolean(calendarData),
            track: [eventInfo?.calendarId as string],
        },
    );

    const handleToggleAccordion = useCallback(
        (event: any, activeNames: SetStateAction<string | string[] | undefined>) => {
            setFetchData(!isEmpty(activeNames));
            setActiveSection(activeNames);
        },
        [],
    );

    const formattedDate = useMemo(
        () => getFormatter(
            locale,
            {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                timeZone,
            },
        ).format(data?.startAt?.toDate() || eventData?.startAt),
        [locale, timeZone, data?.startAt, eventData?.startAt],
    );

    const formattedDuration = useMemo(
        () => formatDurationFromSeconds(eventData?.duration || 0),
        [eventData?.duration],
    );

    const status = useMemo(
        () => data?.status || eventData?.status,
        [data?.status, eventData?.status],
    );

    const label = useMemo(
        () => (rescheduled ? 'Reschedule Details' : 'Event Details'),
        [rescheduled],
    );

    const dateFieldLabel = useMemo(
        () => (rescheduled ? 'New Date & Time:' : 'Date & Time:'),
        [rescheduled],
    );

    useEffect(() => {
        setEventData(eventInfo);
    }, [eventInfo]);

    useEffect(() => {
        setCalendarData(calendar);
    }, [calendar]);

    const isLoading = isLoadingEvent || isLoadingCalendar;

    return (
        <StyledAccordion
            activeSectionNames={activeSection}
            onToggleSection={handleToggleAccordion}
        >
            <AccordionSection label={<PrimaryText>{label}</PrimaryText>}>
                <Container>
                    <EventDetails>
                        <DetailElement
                            icon={<Clock />}
                            isLoading={isLoading}
                            label={dateFieldLabel}
                            value={formattedDate}
                        />
                        <DetailElement
                            icon={<CalendarIcon />}
                            isLoading={isLoading}
                            label="Calendar:"
                            value={calendarData?.name}
                        />
                        <DetailElement
                            icon={<Clock />}
                            isLoading={isLoading}
                            label="Duration:"
                            value={formattedDuration}
                        />
                        <DetailElement
                            component={CalendarEventStatusBadge}
                            isLoading={isLoading}
                            icon={<Check />}
                            label="Status:"
                            value={status?.name}
                            color={status?.color}
                        />
                        <RenderIf isTrue={data?.notes}>
                            <DetailElement
                                icon={<Note />}
                                isLoading={isLoading}
                                label="Notes:"
                                value={data?.notes}
                                wrap
                            />
                        </RenderIf>
                    </EventDetails>
                </Container>
            </AccordionSection>
        </StyledAccordion>
    );
};

export default CalendarEventInformation;
