import { Monaco } from '@monaco-editor/react';
import { languages } from 'monaco-editor';
import { Snippet } from './types';

let registered = false;

const registerSnippets = (monaco: Monaco, snippets: Snippet[]) => {
    if (registered || !snippets.length) return;
    monaco.languages.registerCompletionItemProvider(
        'handlebars',
        {
            provideCompletionItems: (model, position) => ({
                suggestions: snippets.map((snippet) => ({
                    label: snippet.label,
                    kind: monaco.languages.CompletionItemKind.Snippet,
                    insertText: snippet.body,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    range: new monaco.Range(
                        position.lineNumber,
                        1,
                        position.lineNumber,
                        position.column,
                    ),
                })),
            } as languages.ProviderResult<languages.CompletionList>),
        },
    );
    registered = true;
};

export default registerSnippets;
