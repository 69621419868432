/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import useFeaturesSchemas from 'data/firestore/responderfeatureschema/useCollectionOnce';
import { Responderfeatureschema } from 'data/firestore/responderfeatureschema/types';

type FeatureSchema = Responderfeatureschema;

interface FeatureSchemaPickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    disabled?: boolean;
    value?: string | PicklistValue;
    onChange?: (value?: FeatureSchema | null) => void;
    hideLabel?: boolean;
}

const FeatureSchemaPicker = (props: FeatureSchemaPickerProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        disabled,
        hideLabel = false,
    } = useReduxForm(props);
    const { data: features, isLoading } = useFeaturesSchemas();

    const pickListvalue = useMemo(() => {
        if (valueInProps) {
            let inputValue: FeatureSchema | null | undefined = valueInProps as FeatureSchema;
            if (typeof valueInProps === 'string') {
                inputValue = features.find((feature) => feature.name === valueInProps);
                if (inputValue) {
                    onChange(inputValue);
                }
            }
            if (inputValue) {
                const { name: featureName, displayName } = inputValue as FeatureSchema;
                return {
                    label: displayName,
                    name: featureName,
                    value: inputValue,
                    searchableText: `${displayName} ${featureName}`,
                } as PicklistValue;
            }
        }
        return undefined;
    }, [features, onChange, valueInProps]);

    const handleOnChange = useCallback(
        (newValue: PicklistValue) => onChange(newValue.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => features.map(
            (feature) => {
                const { name: featureName, displayName } = feature;
                return (
                    <Option
                        key={`feature-schema__${feature.id}`}
                        label={displayName}
                        name={featureName}
                        value={feature}
                        searchableText={`${displayName} ${featureName}`}
                    />
                );
            },
        ),
        [features],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
            disabled={disabled}
            enableSearch
            hideLabel={hideLabel}
        >
            {pickListOptions}
        </Picklist>
    );
};

export default FeatureSchemaPicker;
