import { ReactNode } from 'react';
import {
    StepContainer,
} from './styled';

interface Params extends Record<string, unknown> {
    children?: ReactNode;
}

const Step = ({
    children,
    ...rest
}: Params) => (
    <StepContainer {...rest}>
        {children}
    </StepContainer>
);

export default Step;
