import { IconProps } from 'types';

const BoardIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        viewBox="0 0 24 25"
        width={24}
        height={25}
    >
        <title>{title}</title>
        <rect x="4" y="4.31348" width="6" height="16" rx="2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="14" y="4.31348" width="6" height="10" rx="2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default BoardIcon;
