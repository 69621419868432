import { useCallback, useContext } from 'react';
import { showAppNotification } from '@rainbow-modules/app';
import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import Button from 'components/Button';
import ReactJson from 'react-json-view';
import context from 'components/InstructionTestingLayout/context';
import useHttpMutation from 'data/firestore/useHttpMutation';
import {
    Container, JsonView, Footer, Content, LoadingContainer,
} from './styled';
import { ResponderInstructionTestingContext, ResponderInstructionTestStepComponentProps } from '../types';

const OutputView = ({
    agentId,
    responderId,
    systemMessageTemplate,
    onChange = () => {},
}: ResponderInstructionTestStepComponentProps) => {
    const {
        chatMessages,
        templateContext: input,
        output,
    } = useContext<ResponderInstructionTestingContext>(context);

    const {
        mutateAsync: invokeResponder,
        isLoading,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/agents/${agentId}/llms/${responderId}/test`,
        method: 'POST',
    });

    const handleInvoke = useCallback(
        async () => {
            try {
                const response = await invokeResponder({
                    body: {
                        input,
                        messages: chatMessages,
                        template: systemMessageTemplate,
                    },
                });
                onChange({ output: response });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [chatMessages, input, invokeResponder, onChange, systemMessageTemplate],
    );

    return (
        <Container>
            <RenderIf isTrue={isLoading}>
                <Content>
                    <LoadingContainer>
                        <LoadingShape width="80%" height="10px" />
                        <LoadingShape width="100%" height="10px" />
                        <LoadingShape width="70%" height="10px" />
                    </LoadingContainer>
                </Content>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <JsonView>
                    <ReactJson
                        src={output || {}}
                        displayObjectSize={false}
                        enableClipboard={false}
                        displayDataTypes={false}
                    />
                </JsonView>
            </RenderIf>
            <Footer align="flex-end">
                <Button
                    variant="brand"
                    borderRadius="semi-square"
                    label="Invoke responder"
                    disabled={isLoading}
                    onClick={handleInvoke}
                />
            </Footer>
        </Container>
    );
};

export default OutputView;
