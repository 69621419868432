interface Params {
    agentId: string;
    status?: 'solved' | 'spam' | 'removed' | 'transferred' | null;
    priorities?: string[] | null;
    topics?: string[] | null;
}

const getInboxFilters = ({
    agentId, status, priorities, topics,
}: Params) => {
    let filters = `agentId:${agentId}`;
    if (status) {
        if (status === 'solved') {
            filters += ' AND (_tags:solved AND _tags:unremoved)';
        }
        if (status === 'spam') {
            filters += ' AND _tags:spam';
        }
        if (status === 'removed') {
            filters += ' AND _tags:removed';
        }
        if (status === 'transferred') {
            filters += ' AND _tags:transferred';
        }
    } else {
        filters += ' AND (_tags:unsolved AND _tags:unremoved)';
    }
    if (priorities && priorities.length > 0) {
        filters += ` AND (${priorities.map((p) => `priority:${p}`).join(' OR ')})`;
    }
    if (topics && topics.length > 0) {
        filters += ` AND (${topics.map((t) => `topics.id:${t}`).join(' OR ')})`;
    }
    return filters;
};

export default getInboxFilters;
