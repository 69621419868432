const languagesMap: Record<string, string> = {
    en: 'English',
    es: 'Spanish',
};

export const formatLanguage = (value?: string) => {
    if (!value) return null;
    const [language, region] = value.split('-');
    const languageLabel = languagesMap[language];

    if (!region) return languageLabel;

    return `${languageLabel} (${region.toUpperCase()})`;
};

const formatLanguages = (languages: string[] = []) => languages.map((lang: string) => formatLanguage(lang || 'en')).join(', ');

export default formatLanguages;
