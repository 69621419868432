const getEventPositionInSlot = ({
    maxSize,
    totalEventsInSameSlot,
    totalPrevEventsInSameSlot,
}: {
    maxSize: number;
    totalEventsInSameSlot: number;
    totalPrevEventsInSameSlot: number;
}) => {
    const eventSize = maxSize / totalEventsInSameSlot;

    const left = (
        eventSize >= 16
            ? totalPrevEventsInSameSlot * eventSize
            : totalPrevEventsInSameSlot * 8
    );
    const positionInSlot = {
        left,
        width: Math.max(eventSize, 16),
    };

    if (totalPrevEventsInSameSlot === totalEventsInSameSlot - 1) {
        positionInSlot.width = maxSize - left;
    }

    return positionInSlot;
};

export default getEventPositionInSlot;
