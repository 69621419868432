import styled from 'styled-components';
import { Document } from '@rainbow-modules/icons';

export const StyledSpinnerContiner = styled.div`
    position: relative;
    height: 30px;
    width: 172px;
`;

export const StyledContiner = styled.div`
    display: flex;
    margin: 5px 0;
    flex-direction: row;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6750a4;
    border-radius: 10px;
    width: 40px;
    height: 40px;
`;

export const StyledIcon = styled(Document)`
    color: #fff;
    width: 16px !important;
    height: 16px !important;
`;

export const StyledContent = styled.div`
    flex: 1 1 auto;
    padding-left: 8px;
`;

export const StyledFileName = styled.p`
    font-size: 14px;
    font-weight: 400;
`;

export const StyledInfo = styled.p`
    font-size: 12px;
    font-weight: 400;
`;
