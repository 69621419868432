import styled from 'styled-components';
import { CheckboxGroup } from 'react-rainbow-components';
import ListTile from 'components/ListTile';

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    & label {
        display: flex;
    }
`;

export const MemberInfo = styled(ListTile)`
    width: unset;
`;
