import { useMemo } from 'react';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { UserCellWrapper } from './styled';

interface Props {
    row?: Record<string, string>;
}

const UserColumn = ({ row }: Props) => {
    const {
        uid = '', displayName, email = '', photoURL,
    } = row || {};

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName || '');
    }, [displayName]);

    return (
        <UserCellWrapper>
            <ListTile
                icon={(
                    <UserAvatar
                        src={photoURL}
                        initials={initials}
                        value={uid || email}
                    />
                )}
                title={getDisplayName(row)}
                subtitle={email || 'Empty email'}
            />
        </UserCellWrapper>
    );
};

export default UserColumn;
