import {
    Container,
    GeneralVariable,
    Description,
    Label,
    StyledDivider,
} from './styled';

const generalVariables = [
    {
        label: 'language.tag',
        description: 'Language tag of the call. Can be es, en.',
        value: 'language.tag',
    },
    {
        label: 'language.inEnglish',
        description: 'Language of the call when system message is in English. Can be English or Spanish.',
        value: 'language.inEnglish',
    },
    {
        label: 'language.inSpanish',
        description: 'Language of the call when system message is in Spanish. Can be Inglés or Español.',
        value: 'language.inSpanish',
    },
];

const companyVariables = [
    {
        label: 'company.name',
        description: 'Name of the company',
        value: 'company.name',
    },
    {
        label: 'company.address',
        description: 'Address of the company',
        value: 'company.address',
    },
];

const customerVariables = [
    {
        label: 'customer.alias',
        description: 'Alias of the customer',
        value: 'customer.alias',
    },
    {
        label: 'customer.firstName',
        description: 'First name of the customer',
        value: 'customer.firstName',
    },
    {
        label: 'customer.lastName',
        description: 'Last name of the customer',
        value: 'customer.lastName',
    },
    {
        label: 'customer.email',
        description: 'Email of the customer',
        value: 'customer.email',
    },
    {
        label: 'customer.phoneNumber',
        description: 'Phone number of the customer',
        value: 'customer.phoneNumber',
    },
];

const GeneralVariables = () => (
    <Container>
        <Label>General</Label>
        <Description>
            Variables for common elements not tied to a specific entity,
            such as language or time, which can be used universally in instructions.

        </Description>
        {
            generalVariables.map((variable) => (
                <GeneralVariable
                    key={variable.value}
                >
                    {variable.label}
                </GeneralVariable>
            ))
        }
        <StyledDivider />
        <Label>Company</Label>
        <Description>
            Variables that represent specific business information like
            the organization&apos;s name and address.

        </Description>
        {
            companyVariables.map((variable) => (
                <GeneralVariable
                    key={variable.value}
                >
                    {variable.label}
                </GeneralVariable>
            ))
        }
        <StyledDivider />
        <Label>Customer</Label>
        <Description>
            Variables for specific customer information such as name,
            and phone number, ideal for personalized communication.

        </Description>
        {
            customerVariables.map((variable) => (
                <GeneralVariable
                    key={variable.value}
                >
                    {variable.label}
                </GeneralVariable>
            ))
        }
    </Container>
);

export default GeneralVariables;
