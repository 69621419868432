import styled from 'styled-components';
import { PURPLE_500 } from '../../constants';

export const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0 0.5rem 0;
    line-height: 1.467;
    font-size: 0.85rem;
    color: #6f6d7d;
    font-weight: 400;
`;

export const Links = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const StyledLink = styled.a`
    line-height: 1.467;
    font-size: 0.85rem;
    color: #6f6d7d;
    font-weight: 400;

    &:hover {
        color: ${PURPLE_500};
    }
`;
