/* eslint-disable max-len */
import {
    useCallback, useMemo, useRef, useState,
} from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
import { isEmpty } from '@rainbow-modules/validation';
import Button from 'components/Button';
import Footer from 'components/Footer';
import InfoIcon from 'components/icons/info';
import { Agent } from 'data/firestore/agent/types';
import useUpdateAgentMutation from 'data/hooks/useUpdateAgentMutation';
import getLocation from 'helpers/getLocation';
import resolveTimezone from 'helpers/resolveTimezone';
import useAgentData from 'hooks/useAgentData';
import useUserRoles from 'hooks/useUserRoles';
import { Geometry } from 'types';
import Locations from './Locations';
import {
    Container,
    StyledCard,
    PageContainer,
    Header,
    Title,
    StyledForm,
    Row,
    Description,
    FormFooter,
    StyledInput,
    StyledGoogleAddressLookup,
    StyledLanguagePicker,
    StyledLoadingShape,
    InputLabel,
    TooltipButton,
    TooltipContainer,
} from './styled';

interface FormValues extends Record<string, unknown> {
    companyName?: string;
    companyAddress?: Record<string, unknown>;
}

export interface AnyObject {
    [key: string]: any;
}
export type ValidationErrors = AnyObject | undefined;
export type SubmissionErrors = AnyObject | undefined;

interface FormState {
    active: undefined | keyof FormValues;
    dirty: boolean;
    dirtyFields: { [key: string]: boolean };
    dirtyFieldsSinceLastSubmit: { [key: string]: boolean };
    dirtySinceLastSubmit: boolean;
    error: any;
    errors: ValidationErrors;
    hasSubmitErrors: boolean;
    hasValidationErrors: boolean;
    initialValues: Partial<FormValues>;
    invalid: boolean;
    modified?: { [key: string]: boolean };
    modifiedSinceLastSubmit: boolean;
    pristine: boolean;
    submitError: any;
    submitErrors: SubmissionErrors;
    submitFailed: boolean;
    submitSucceeded: boolean;
    submitting: boolean;
    touched?: { [key: string]: boolean };
    valid: boolean;
    validating: boolean;
    values: FormValues;
    visited?: { [key: string]: boolean };
}

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

const checkFormModified = (formState: FormState): boolean => {
    const { pristine, valid } = formState;
    return pristine || !valid;
};

const mapFormValues = async (currentValues: FormValues) => {
    const {
        companyAddress,
        companyName,
        aiOutputLanguage,
        customersPreferredLanguage,
    } = currentValues;

    let newCompanyAddress;
    if (companyAddress) {
        const { lat, lng } = getLocation(companyAddress?.geometry as Geometry);
        newCompanyAddress = {
            addressInfo: companyAddress,
            formattedAddress: companyAddress.formatted_address,
            timezone: await resolveTimezone(lat, lng),
        };
    }

    return {
        companyName,
        aiOutputLanguage,
        customersPreferredLanguage,
        companyAddress: newCompanyAddress,
    };
};

const CompanyInfo = () => {
    const { agentId = '' } = useParams();
    const [disableSubmit, setDisableSubmit] = useState(true);
    const formRef = useRef();
    const { agent, isLoading: isLoadingAgent } = useAgentData();
    const { role, isAdmin } = useUserRoles();

    const userCanWrite = useMemo(() => isAdmin || ['owner', 'editor'].includes(role as string), [role, isAdmin]);

    const {
        mutateAsync: updateAgent,
        isLoading: isUpdatingAgent,
    } = useUpdateAgentMutation(agentId as string);

    const handleSubmit = useCallback(
        async (values: FormValues) => {
            try {
                const newValues = await mapFormValues(values) as Partial<Pick<Agent, 'companyName' | 'companyAddress'>>;
                await updateAgent({
                    body: newValues,
                });
                showAppNotification({
                    title: 'Success',
                    description: 'Your changes have been saved.',
                    icon: 'success',
                    timeout: 5000,
                });
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formRef.current?.reset();
            } catch (error: any) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        },
        [updateAgent],
    );

    return (
        <PageContainer>
            <Container>
                <StyledCard>
                    <Header>
                        <Title>Company Info</Title>
                        <Description>
                            A concise overview of the company&apos;s fundamental details, such
                            as its name, address, and preferred language.
                        </Description>
                    </Header>
                    <RenderIf isTrue={isLoadingAgent}>
                        <StyledLoadingShape />
                        <StyledLoadingShape />
                        <StyledLoadingShape />
                        <StyledLoadingShape />
                    </RenderIf>
                    <RenderIf isTrue={!isLoadingAgent}>
                        <StyledForm
                            ref={formRef}
                            id="company-info-form"
                            initialValues={{
                                companyName: agent?.companyName,
                                companyAddress: agent?.companyAddress?.addressInfo,
                                aiOutputLanguage: agent?.aiOutputLanguage || 'en',
                                customersPreferredLanguage: agent?.customersPreferredLanguage || 'en',
                            }}
                            onSubmit={handleSubmit}
                        >
                            <Row>
                                <Field
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    component={StyledInput}
                                    name="companyName"
                                    label="Company Name"
                                    labelAlignment="left"
                                    placeholder="My Company"
                                    borderRadius="semi-square"
                                    autoComplete="off"
                                    required
                                    readOnly={!userCanWrite}
                                    disabled={isUpdatingAgent}
                                    validate={validateNotEmpty}
                                />
                                <Field
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    component={StyledGoogleAddressLookup}
                                    name="companyAddress"
                                    label="Company Address"
                                    labelAlignment="left"
                                    placeholder="45 Roker Terrace"
                                    borderRadius="semi-square"
                                    autoComplete="off"
                                    required
                                    readOnly={!userCanWrite}
                                    disabled={isUpdatingAgent}
                                    validate={validateNotEmpty}
                                />
                            </Row>
                            <Row>
                                <Field
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    component={StyledLanguagePicker}
                                    name="aiOutputLanguage"
                                    label={(
                                        <InputLabel>
                                            AI Output Language
                                            <TooltipButton
                                                icon={<InfoIcon />}
                                                tooltip={(
                                                    <TooltipContainer>
                                                        <p>Sets the language for displaying conversation summaries and inbox titles on the website. Also determines the language used in Email and SMS notifications.</p>
                                                    </TooltipContainer>
                                                )}
                                            />
                                        </InputLabel>
                                    )}
                                    labelAlignment="left"
                                    borderRadius="semi-square"
                                    required
                                    readOnly={!userCanWrite}
                                    disabled={isUpdatingAgent}
                                />
                                <Field
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    component={StyledLanguagePicker}
                                    name="customersPreferredLanguage"
                                    label={(
                                        <InputLabel>
                                            Customers Preferred Language
                                            <TooltipButton
                                                icon={<InfoIcon />}
                                                tooltip={(
                                                    <TooltipContainer>
                                                        <p>Sets the language for notifications sent to customers when an relational event is created/updated in an specific calendar.</p>
                                                    </TooltipContainer>
                                                )}
                                            />
                                        </InputLabel>
                                    )}
                                    labelAlignment="left"
                                    borderRadius="semi-square"
                                    required
                                    readOnly={!userCanWrite}
                                    disabled={isUpdatingAgent}
                                />
                            </Row>
                            <FormSpy
                                onChange={
                                    (props) => setTimeout(
                                        () => setDisableSubmit(checkFormModified(props)),
                                        0,
                                    )
                                }
                            />
                        </StyledForm>
                        <RenderIf isTrue={userCanWrite}>
                            <FormFooter>
                                <Button
                                    label="Save Change"
                                    variant="brand"
                                    form="company-info-form"
                                    type="submit"
                                    borderRadius="semi-square"
                                    disabled={disableSubmit}
                                    isLoading={isUpdatingAgent}
                                />
                                <Button
                                    label="Cancel"
                                    variant="outline-brand"
                                    form="company-info-form"
                                    borderRadius="semi-square"
                                    disabled={disableSubmit}
                                    isLoading={isUpdatingAgent}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    onClick={() => formRef.current?.reset()}
                                />
                            </FormFooter>
                        </RenderIf>
                    </RenderIf>
                </StyledCard>
                <Locations />
            </Container>
            <Footer />
        </PageContainer>
    );
};

export default CompanyInfo;
