import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIsMutating } from 'react-query';
import { RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import ChevronLeft from 'components/icons/chevronLeft';
import ChevronRight from 'components/icons/chevronRight';
import LoadingShape from 'components/LoadingShape';
// import SortByPicker, { SortByPickerValue } from 'components/SortByPIcker';
import TaskPriorityFilter from 'components/TaskPriorityFilter';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import getInboxFilters from 'data/algolia/getInboxFilters';
import { EntityGet } from 'data/firestore/types';
import { Inbox } from 'data/firestore/inbox/types';
import useDebouncedState from 'hooks/useDebouncedState';
import { TaskStatus } from 'types';
import { ALGOLIA_INDEX_INBOX, PAGE_SIZE } from '../../constants';
import Task from './task';
import {
    Actions,
    // ButtonGroupDivider,
    Container, Content, Header, Title,
    PaginationContainer,
    PaginationInformation,
} from './styled';
import EmptyTasks from './empty';

const defaultPriorityFilterValue = ['urgent', 'high', 'medium', 'low'];

// const sortFields = [
//     { name: 'priority', label: 'Priority' },
//     { name: 'createdAt', label: 'Create Date' },
// ];

const inboxStatusMap: { [key in Exclude<TaskStatus, 'open'>]: 'solved' | 'removed' } = {
    solved: 'solved',
    archived: 'removed',
};

interface TaskWithStatusProps {
    status: Exclude<TaskStatus, 'open'>;
    searchQuery?: string;
}
// type SortFields = 'createdAt' | 'priority';

const defaultData: EntityGet<Inbox>[] = [];

const TaskWithStatus = ({ status, searchQuery: search = '' }: TaskWithStatusProps) => {
    const { agentId = '' } = useParams();
    // eslint-disable-next-line max-len
    // const [sort, setSort] = useState<SortByPickerValue<SortFields>>({ sortBy: 'createdAt', order: 'asc' });
    const [priorities, setPriorityFilter] = useState<string[]>(defaultPriorityFilterValue);
    const [activePage, setActivePage] = useState(1);
    const [isLoading, setIsLoading] = useDebouncedState(true);
    const [mutationTracker, setMutationTracker] = useState(Symbol('taskUpdated'));
    // TODO: when we fix `useHttpMutation` to allow pass `mutationKey`
    // we can then filter by the key
    const isMutating = useIsMutating({
        predicate: (mutation) => {
            const { state } = mutation;
            const { variables } = state;
            if (variables?.pathname?.startsWith('/inbox')) {
                return true;
            }
            return false;
        },
    });

    useEffect(() => setActivePage(1), [search, status, priorities]);

    useEffect(() => {
        if (!isMutating) {
            setMutationTracker(Symbol('taskUpdated'));
        }
    }, [isMutating]);

    const inboxStatus = inboxStatusMap[status];
    const algoliaData = useAlgoliaSearchData<EntityGet<Inbox>>({
        search,
        activePage,
        pageSize: PAGE_SIZE,
        // indexName: `${ALGOLIA_INDEX_INBOX}_${sort.sortBy}_${sort.order}`,
        indexName: ALGOLIA_INDEX_INBOX,
        filters: getInboxFilters({
            agentId, status: inboxStatus, priorities,
        }),
        enabled: true,
        track: [mutationTracker],
    });

    const { isLoading: algoliaIsLoading } = algoliaData || { isLoading: false };
    useEffect(() => {
        // Use the debounced state to avoid content flickering
        // when the data is loading too fast
        setIsLoading(algoliaIsLoading);
    }, [algoliaIsLoading, setIsLoading]);

    const dataToShow = algoliaData?.hits || defaultData;
    const totalHits = (
        typeof algoliaData?.nbHits === 'number'
            ? algoliaData.nbHits
            : 0
    );
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );
    const firstPage = activePage === 1;
    const hasMore = activePage < pages;

    const handleNextPage = () => {
        if (hasMore) {
            setActivePage((value) => value + 1);
        }
    };

    const handlePrevPage = () => {
        if (!firstPage) {
            setActivePage((value) => value - 1);
        }
    };

    const tasksList = useMemo(
        () => dataToShow.map((task) => (
            <Task
                key={task.id}
                task={{ ...task, createdAt: new Date(task.createdAt) }}
            />
        )),
        [dataToShow],
    );

    useEffect(() => {
        setPriorityFilter(defaultPriorityFilterValue);
    }, [status]);

    const isEmpty = dataToShow.length === 0;
    if (isLoading && isEmpty) {
        return (
            <Container>
                <div className="rainbow-flex-column">
                    <LoadingShape width="50%" height="1rem" />
                    <LoadingShape width="30%" height="1rem" />
                    <LoadingShape width="60%" height="1rem" />
                </div>
            </Container>
        );
    }
    if (isEmpty) {
        return <EmptyTasks searchQuery={search} status={status} />;
    }

    return (
        <Container>
            <Header>
                <Title>All Tasks</Title>
                <PaginationContainer>
                    <PaginationInformation>
                        {firstItemOfActivePage}
                        -
                        {lastItemOfActivePage}
                        {' '}
                        of
                        {' '}
                        {totalHits}
                    </PaginationInformation>
                    <ButtonIcon variant="base" icon={<ChevronLeft />} size="small" disabled={firstPage || isLoading} onClick={handlePrevPage} />
                    <ButtonIcon variant="base" icon={<ChevronRight />} size="small" disabled={!hasMore || isLoading} onClick={handleNextPage} />
                </PaginationContainer>
                <Actions>
                    {/* <SortByPicker
                        sortFields={sortFields}
                        value={sort}
                        onChange={(value) => setSort(value as SortByPickerValue<SortFields>)}
                    />
                    <ButtonGroupDivider /> */}
                    <TaskPriorityFilter value={priorities} onChange={setPriorityFilter} />
                </Actions>
            </Header>
            <Content>
                <RenderIf isTrue={isLoading && !tasksList}>
                    <div className="rainbow-flex-column">
                        <LoadingShape width="50%" height="1rem" />
                        <LoadingShape width="30%" height="1rem" />
                        <LoadingShape width="60%" height="1rem" />
                    </div>
                </RenderIf>
                <RenderIf isTrue={!isLoading && tasksList}>
                    {tasksList}
                </RenderIf>
            </Content>
        </Container>
    );
};

export default TaskWithStatus;
