import { useCallback, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import CalendarWeekPreview from 'components/CalendarWeekPreview';
import ChevronLeft from 'components/icons/chevronLeft';
import ChevronRight from 'components/icons/chevronRight';
import addDays from 'components/CalendarWeekPreview/helpers/addDays';
import getTitle from 'pages/Calendar/View/helpers/getTitle';
import {
    HeaderActions, PreviewContainer, StyledButtonIcon, Title,
} from './styled';

const CalendarPreview = () => {
    const [currentWeek, setCurrentWeek] = useState(new Date());
    const { values } = useFormState();

    const formattedDate = useMemo(() => getTitle({
        date: currentWeek,
        viewType: 'week',
    }), [currentWeek]);

    const showPrevDay = useCallback(
        () => setCurrentWeek(addDays(currentWeek, -7)),
        [currentWeek],
    );

    const showNextDay = useCallback(
        () => setCurrentWeek(addDays(currentWeek, 7)),
        [currentWeek],
    );

    return (
        <>
            <HeaderActions>
                <StyledButtonIcon
                    borderRadius="semi-square"
                    icon={<ChevronLeft />}
                    onClick={showPrevDay}
                />
                <StyledButtonIcon
                    borderRadius="semi-square"
                    icon={<ChevronRight />}
                    onClick={showNextDay}
                />
                <Title>
                    {formattedDate}
                </Title>
            </HeaderActions>
            <PreviewContainer>
                <CalendarWeekPreview
                    currentWeek={currentWeek}
                    highlightColor={values.color}
                    availabileHours={values.availability}
                />
            </PreviewContainer>
        </>
    );
};

export default CalendarPreview;
