const getVariantFromScore = (score?: number | null) => {
    if (typeof score === 'number') {
        if (score < 40) return 'error';
        if (score < 70) return 'warning';
        return 'success';
    }
    return undefined;
};

export default getVariantFromScore;
