import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    min-height: 0;
    min-width: 0;
    .monaco-editor {
        min-width: 0;
        min-height: 0;
        height: 100%;
    }
`;
