import { createContext } from 'react';
import { ChatMessage } from '../../types';

export interface Context {
    compiledTemplate?: string;
    templateContext?: Record<string, unknown>,
    chatMessages?: ChatMessage[];
    [key:string]: unknown;
}

const context = createContext<Context>({});

export const { Provider, Consumer } = context;

export default context;
