import { RenderIf } from 'react-rainbow-components';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledBreadcrumb, StyledBreadcrumbs } from 'components/Breadcrumbs';
import ListIcon from 'components/icons/list';
import useSession from 'data/firestore/session/use';
import Footer from 'components/Footer';
import Alert from 'components/Alert';
import { EntityGet } from 'data/firestore/types';
import { Session } from 'data/firestore/session/types';
import useResolveCustomerByPhoneNumber from 'data/hooks/useResolveCustomerByPhoneNumber';
import Transcript from './Transcript';
import Details from './Details';
import {
    Columns,
    Container,
    LeftColumn,
    RightColumn,
    RightContent,
    ConversationCardTitle,
} from './styled';

const ConversationDetails = () => {
    const { agentId = '', sessionId = '' } = useParams();
    const { data: session, isLoading: isLoadingSession } = useSession(sessionId as string);
    const { data: customer, isLoading: isLoadingCustomer } = useResolveCustomerByPhoneNumber(
        agentId,
        session?.from || '',
        {
            disabled: !agentId || !session?.from,
        },
    );
    const navigate = useNavigate();
    const isSpam = session?._tags?.includes('spam');
    const isLoading = isLoadingSession || isLoadingCustomer;

    return (
        <Container>
            <StyledBreadcrumbs>
                <StyledBreadcrumb label="History" onClick={() => navigate(`/${agentId}/voip/history`)} />
                <StyledBreadcrumb label="Conversation Details" />
            </StyledBreadcrumbs>
            <Columns>
                <LeftColumn>
                    <Details
                        sessionId={sessionId}
                        data={{
                            ...(session || {}) as EntityGet<Session>,
                            customer,
                        }}
                        isLoading={isLoading}
                    />
                </LeftColumn>
                <RightColumn
                    title={(
                        <ConversationCardTitle>
                            <ListIcon />
                            <span>
                                Transcript & Recording
                            </span>
                        </ConversationCardTitle>
                    )}
                >
                    <RightContent>
                        <RenderIf isTrue={!isSpam}>
                            <Transcript
                                sessionId={sessionId}
                                from={customer}
                                recordingId={session?.recordingId}
                                status={session?.status}
                            />
                        </RenderIf>
                        <RenderIf isTrue={isSpam}>
                            <Alert variant="error">
                                This phone number was detected as a spam risk.
                            </Alert>
                        </RenderIf>
                    </RightContent>
                </RightColumn>
            </Columns>
            <Footer />
        </Container>
    );
};

export default ConversationDetails;
