/* eslint-disable jsx-a11y/aria-role */
import { useContext } from 'react';
import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import context from 'components/InstructionTestingLayout/context';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import {
    Container, Content, Text,
} from './styled';
import { VoipInstructionTestingContext } from '../types';

interface Params extends InstructionTestStepComponentProps {}

const SummaryView = ({
    isLoading = false,
}: Params) => {
    const { generatedSummary } = useContext<VoipInstructionTestingContext>(context);

    return (
        <Container>
            <Content>
                <Text>
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape width="80%" height="10px" />
                        <LoadingShape width="100%" height="10px" />
                        <LoadingShape width="70%" height="10px" />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>{generatedSummary?.summary}</RenderIf>
                </Text>
            </Content>
        </Container>
    );
};

export default SummaryView;
