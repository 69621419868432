/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { PickerVariantProps } from './types';

const CalendarPickerVariantSelect = (props: PickerVariantProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        calendars = [],
        isLoading = false,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Calendar).id
                );
                const found = calendars.find((calendar) => calendar.id === valueId);
                if (found) {
                    if (typeof valueInProps === 'string') {
                        onChange(found);
                    }
                    const { id: calendarId, name: calendarName } = found;
                    return {
                        label: calendarName,
                        name: calendarId,
                        value: found,
                        searchableText: calendarName,
                    };
                }
            }

            if (calendars.length > 0) {
                const firstInList = calendars[0];
                onChange(firstInList);
                const { id: calendarId, name: calendarName } = firstInList;
                return {
                    label: calendarName,
                    name: calendarId,
                    value: firstInList,
                    searchableText: calendarName,
                };
            }
        }
        return undefined;
    }, [calendars, isLoading, onChange, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => calendars.map(
            (calendar) => (
                <Option
                    key={`calendar__${calendar.id}`}
                    label={calendar.name}
                    name={calendar.id}
                    value={calendar}
                    searchableText={calendar.name}
                />
            ),
        ),
        [calendars],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
            enableSearch
        >
            <Option label="Select Calendar" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default CalendarPickerVariantSelect;
