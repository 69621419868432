import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import { ItemUser } from 'data/firestore/globals';
import { Blockednumber } from 'data/firestore/agent/blockednumber/types';
import { UserCellWrapper } from '../styled';

interface Props {
    value?: ItemUser;
    row?: Blockednumber;
}

const UserColumn = ({ value, row }: Props) => {
    const { displayName = 'Unknown', photoURL } = value || {};
    const { phoneNumber } = row || {};

    return (
        <UserCellWrapper>
            <ListTile
                icon={<UserAvatar value={displayName} src={photoURL} />}
                title={displayName}
                subtitle={phoneNumber}
            />
        </UserCellWrapper>
    );
};

export default UserColumn;
