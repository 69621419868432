import styled from 'styled-components';
import { InternalDropdown as RainbowInternalDropdown } from 'react-rainbow-components';
import { InternalDropdownProps } from 'react-rainbow-components/components/InternalDropdown';
import ButtonIcon from 'components/ButtonIcon';
import Picklist from 'components/Picklist';
import { GREY_300 } from '../../../constants';

const InternalDropdown = RainbowInternalDropdown as React.FC<InternalDropdownProps>;

export const SumaryContainer = styled.div`
    display: flex;
    padding: 1rem 1.25rem 1.5rem 1.5rem;
    align-items: center;
    gap: 1.625rem;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const FilterContainer = styled.div`
    display: flex;
    gap: 1rem;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;

export const StyledPicklist = styled(Picklist)`
    width: 280px;
`;

export const StyledInternalDropdown = styled(InternalDropdown)`
    width: 260px;
`;
