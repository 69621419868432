import styled, { css } from 'styled-components';
import { RecordField } from '@rainbow-modules/record';
import { Spinner } from 'react-rainbow-components';
import Card from 'components/Card';
import PicklistButton from 'components/PicklistButton';
import {
    GREY_800, GREY_500, DIVIDER, GREY_300,
} from '../../constants';

export const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 30.35rem;
    padding: 1.5rem;
    gap: 1.5rem;
`;

export const Backdrop = styled.div`
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`;

export const Header = styled.div`
    display: flex;
    align-self: stretch;
    gap: 0.75rem;
`;

export const Content = styled.div`
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
`;

export const Footer = styled.div`
    display: flex;
    align-self: stretch;
    gap: 1.5rem;
    border-top: 1px solid ${DIVIDER};
    padding: 1rem;
    margin: 0 -1.5rem -1.5rem;
`;

export const Actions = styled.div`
    margin-left: 2.25rem;
`;

export const ColorBox = styled.div<{ color?: string; }>`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0.125rem;
    align-items: flex-start;
    border-radius: 0.375rem;
    background: ${(props) => props.color || '#000000'};
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.75rem;
`;

export const Title = styled.span`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_800};
`;

export const Description = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    color: ${GREY_800};
    display: flex;
    gap: 0.25rem;
`;

export const EventDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem
    align-self: stretch;
`;

export const ElementContainer = styled.div`
   display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    color: ${GREY_500};
    margin: 0;

    svg {
        width: 20px;
        height: auto;
    }
`;

export const IconContainer = styled.div`
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    margin-top: 0.375rem;
`;

export const StyledRecordField = styled(RecordField)<{ wrap: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;

    > span {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_500};
    }
    
    > span:first-child {
        font-weight: 600;
        white-space: nowrap;
        color: ${GREY_500};
        line-height: 1.375rem;
    }

    > span:last-child {
        overflow: hidden;
        color: ${GREY_500};
        text-overflow: ellipsis;
        flex: 1;
            
        > div {
            flex: 1;
        }
    }

    ${(props) => props.wrap && css`
        flex-direction: column;
        flex: 1;
        gap: 0;
        align-items: stretch;

        > span {
            align-self: stretch;
            flex: 1 1 100%;
        }
    `};
`;

export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    padding-right: 0.5rem;
    padding-right: 5px;
`;

export const StyledPicklistButton = styled(PicklistButton)<{ color?: string }>`
    padding: 0.1875rem 0.375rem;
    & > button {
        border-radius: 0.25rem;
        padding: 0 0.375rem;
        align-items: center;
        gap: unset;
        color: ${(props) => props.color};
        background: ${(props) => `${props.color}29`};
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.875rem;

        svg {
            stroke: ${(props) => props.color};
        }

        &:hover,
        &:focus,
        &:active {
            color: ${(props) => props.color};
            background: ${(props) => `${props.color}29`}; 
        }


        &[disabled] {
            color: ${(props) => props.color};
            background: ${(props) => `${props.color}29`};    
        }
    }

    & > div {
        min-width: 20rem;
    }
`;

export const StatusBadgeContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
    align-content: flex-start;
    align-items: center;
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    z-index: unset;
    transform: unset;
    left: unset;
    top: unset;
    width: 1rem;
    height: 1rem;
`;

export const FooterButtonsContainer = styled.div<{ justify?: 'flext-start' | 'center' | 'flex-end' }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 0.5rem;
    font-size: 0.875rem;
    justify-content: ${(props) => props.justify || 'unset'};
`;
