import styled from 'styled-components';
import LinkWithQuery from 'components/LinkWithQuery';
import { color } from 'theme';
import { GREY_400, GREY_300 } from '../../../../constants';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.375rem;
    text-transform: capitalize;
`;

export const StyledTitleContainer = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.35rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
`;

export const PhoneNumber = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledLink = styled(LinkWithQuery)`
    color: ${color('brand.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: ${color('brand.dark')};
    }
`;
export const EmptyLabel = styled.span`
    font-style: italic;
    color: ${GREY_300};
    font-weight: 300;
    font-size: 0.9375rem;
`;
