import { ReactNode, useMemo } from 'react';
import { orderBy, query } from 'firebase/firestore';
import { RenderIf } from 'react-rainbow-components';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import { Customer } from 'data/firestore/agent/customer/types';
import useConversation from 'data/firestore/session/conversation/useCollection';
import AudioPlayer from '../AudioPlayer';
import ConversationMessage from './message';
import RecordingMessage from './recordingMesage';
import { HighlightedText, MessagesList } from './styled';
import { PostCallTranscript } from './types';
import extractHighlightedText from './helpers/extractHightlightedText';

interface CallTranscriptionHighlightsProps {
    sessionId?: string;
    recordingId?: string;
    from?: Customer | null;
    status?: string;
    markHighlightedText?: boolean;
    hideNonHighlighedText?: boolean;
    emptyMessage?: ReactNode
}

const CallTranscriptionHighlights = ({
    sessionId = '',
    recordingId = '',
    from,
    status = '',
    markHighlightedText = false,
    hideNonHighlighedText = false,
    emptyMessage = 'No messages in this transcript.',
}: CallTranscriptionHighlightsProps) => {
    const [postcallData, isLoadingPostCallData] = useDocOnce({
        path: `/session/${sessionId}/metadata/postcall`,
        flat: true,
        disabled: !sessionId,
    });

    const {
        data: messages = [],
        isLoading: isLoadingSessionMessages,
    } = useConversation(sessionId, {
        listQueryFn: (ref) => query(ref, orderBy('createdAt')),
        disabled: (
            !sessionId
            || hideNonHighlighedText
            || isLoadingPostCallData
            || !postcallData
            || postcallData?.metadata?.call?.conversation?.length > 0
        ),
        track: [sessionId],
    });

    const messagesList = useMemo(
        () => {
            const conversation = (
                postcallData?.metadata?.call?.conversation
                || messages.filter((message) => message.role === 'assistant' || message.role === 'user')
            ) as PostCallTranscript[];
            return conversation.reduce(
                (result, message, index) => {
                    const messageHighlights = message.highlights || [];
                    if (messageHighlights.length === 0 && hideNonHighlighedText) {
                        return result;
                    }
                    let messageText: ReactNode = message.content;
                    if (hideNonHighlighedText) {
                        messageText = messageHighlights.map((highlight) => highlight.text).join('...');
                    } else if (markHighlightedText) {
                        messageText = extractHighlightedText(message.content, messageHighlights)
                            .map(({ text, highlighted, type }) => (
                                highlighted
                                    ? (
                                        <HighlightedText
                                            key={text}
                                            type={type}
                                            disableHighlight={!markHighlightedText}
                                        >
                                            {text}
                                        </HighlightedText>
                                    )
                                    : text
                            ));
                    }
                    const key = `${index}_${message.transcript}`;
                    return [
                        ...result,
                        <ConversationMessage
                            key={key}
                            from={from}
                            role={message.role as string}
                            content={messageText}
                        />,
                    ];
                },
                [] as ReactNode[],
            );
        },
        [from, hideNonHighlighedText, markHighlightedText, messages, postcallData],
    );

    const isLoading = useMemo(
        () => isLoadingPostCallData || isLoadingSessionMessages,
        [isLoadingPostCallData, isLoadingSessionMessages],
    );

    return (
        <MessagesList>
            <RenderIf isTrue={status === 'completed' && recordingId}>
                <AudioPlayer recordingId={recordingId} />
            </RenderIf>
            <RenderIf isTrue={status === 'in-progress'}>
                <RecordingMessage />
            </RenderIf>
            <RenderIf isTrue={isLoading}>
                <ConversationMessage isLoading={isLoading} />
            </RenderIf>
            <RenderIf isTrue={!isLoading && messagesList.length}>
                {messagesList}
            </RenderIf>
            <RenderIf isTrue={!isLoading && !messagesList.length}>
                <ConversationMessage
                    from={from}
                    content={emptyMessage}
                />
            </RenderIf>
        </MessagesList>
    );
};

export default CallTranscriptionHighlights;
