import { Avatar } from 'react-rainbow-components';
import styled from 'styled-components';
import Input from 'components/Input';
import Card from '../../components/Card';
import { GREY_400, GREY_300 } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
`;

export const TableContainer = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-weight: 300;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 0.69rem 0.625rem 0.69rem 1.125rem;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
    padding: 0 2rem 0 0;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const ModalDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
`;

export const StyledAvatar = styled(Avatar)`
    width: 2rem;
    height: 2rem;
`;
