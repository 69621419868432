import { useMemo } from 'react';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import getDisplayName from 'data/services/profile/getDisplayName';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getInitials from 'data/services/string/getInitials';
import { UserCellWrapper } from './styled';

interface Props {
    row?: Record<string, string>;
}

const UserColumn = ({ row }: Props) => {
    const {
        uid = '', displayName = '', phoneNumber = '', photoURL,
    } = row || {};

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    return (
        <UserCellWrapper>
            <ListTile
                icon={(
                    <UserAvatar
                        src={photoURL}
                        initials={initials}
                        value={uid || ''}
                    />
                )}
                title={getDisplayName(row)}
                subtitle={formatIntlPhoneNumber(phoneNumber) || 'Empty email'}
            />
        </UserCellWrapper>
    );
};

export default UserColumn;
