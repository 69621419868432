import { IconProps } from 'types';

const IncommingCallIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
        width={12}
        height={12}
        fill="none"
    >
        <title>{title}</title>
        <path d="M2.5 2H4.5L5.5 4.5L4.25 5.25C4.78548 6.33577 5.66423 7.21452 6.75 7.75L7.5 6.5L10 7.5V9.5C10 10.0523 9.55228 10.5 9 10.5C4.96382 10.2547 1.74528 7.03618 1.5 3C1.5 2.44772 1.94772 2 2.5 2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 4.5L10 2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 2.5V4.5H9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default IncommingCallIcon;
