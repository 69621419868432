import { Column } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import useLocations from 'data/firestore/agent/location/useCollection';
import Plus from '@rainbow-modules/icons/lib/components/plus';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { UniversalFormModal } from '@rainbow-modules/forms';
import Table from 'components/Table';
import useHttpMutation from 'data/firestore/useHttpMutation';
import {
    Header,
    Title,
    Description,
} from '../styled';
import Fields from './form';
import Actions from './columns/actions';
import { Row, StyledButton, StyledCard } from './styled';

interface FormValues extends Record<string, unknown> {
    name: string;
    address?: Record<string, unknown>;
}

const Locations = () => {
    const { agentId = '' } = useParams();
    const { data: locations } = useLocations(agentId, {});
    const connectedModalProps = useConnectModal('add-location');
    const [openModal, closeModal] = useOpenModal('add-location');

    const {
        mutateAsync: createLocation,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        pathname: `/agents/${agentId}/locations`,
        method: 'POST',
    });

    const {
        mutateAsync: updateLocation,
    } = useHttpMutation<Record<string, unknown>, Record<string, unknown>>({
        method: 'PATCH',
    });

    const update = (row: Record<string, any>) => {
        openModal({
            title: 'Edit Location',
            submitButtonLabel: 'Edit Location',
            initialValues: {
                name: row?.name,
                address: row?.address.addressInfo,
            },
            onSubmit: async (values: FormValues) => {
                showAppSpinner();
                try {
                    await updateLocation({
                        body: values,
                        pathname: `/agents/${agentId}/locations/${row.id}`,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
                closeModal();
            },
        });
    };

    const addLocation = () => {
        openModal({
            title: 'Add New Location',
            submitButtonLabel: 'Add Location',
            onSubmit: async (values: FormValues) => {
                showAppSpinner();
                try {
                    await createLocation({
                        body: values,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
                closeModal();
            },
        });
    };

    return (
        <StyledCard>
            <Row>
                <Header>
                    <Title>Locations</Title>
                    <Description>
                        List all your physical business locations
                        to manage services across multiple sites effectively.
                    </Description>
                </Header>
                <StyledButton variant="brand" size="medium" borderRadius="semi-square" onClick={addLocation}>
                    <Plus className="rainbow-m-right_x-small" />
                    Add Location
                </StyledButton>
            </Row>
            <Table keyField="id" data={locations}>
                <Column header="Location Name" field="name" />
                <Column header="Location Address" field="address.formattedAddress" />
                <Column
                    header="Actions"
                    component={Actions}
                    defaultWidth={140}
                    agentId={agentId}
                    onEdit={update}
                />
            </Table>
            <UniversalFormModal
                fields={Fields}
                borderRadius="semi-square"
                {...connectedModalProps}
            />
        </StyledCard>
    );
};

export default Locations;
