import { Field, isRequired } from '@rainbow-modules/forms';
import ModalBody from 'components/ModalBody';
import User from 'components/icons/user';
import PhoneInput from 'components/PhoneInput';
import { ModalContent, StyledInput } from './styled';

const Fields = () => (
    <ModalBody
        title="Add New Affiliate"
        description={(
            <ModalContent>
                <Field
                    name="displayName"
                    component={StyledInput}
                    label="Affiliate Name"
                    placeholder="Enter the affiliate's name"
                    validate={isRequired()}
                    labelAlignment="left"
                    borderRadius="semi-square"
                    icon={<User />}
                    required
                />
                <Field
                    name="phoneNumber"
                    component={PhoneInput}
                    label="Affiliate Phone Number"
                    placeholder="Enter a phone number"
                    validate={isRequired()}
                    labelAlignment="left"
                    borderRadius="semi-square"
                    countries={['us']}
                    required
                />
            </ModalContent>
        )}
    />
);

export default Fields;
