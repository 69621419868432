import LoadingShape from 'components/LoadingShape';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-self: stretch;
    overflow-x: auto;
    width: calc(100% + 1rem);
    padding-right: 1rem;
    height: 100%;
`;

export const StyledLoadingShape = styled(LoadingShape)`
    min-width: 300px;
    margin: 0;
    & > div {
        border-radius: 0.375rem;
    }
`;
