import styled, { css } from 'styled-components';
import ChevronRight from 'components/icons/chevronRight';

export const ListContainer = styled.div``;

export const LabelIcon = styled.div<{ color?: string }>`
    display: flex;
    svg {
        width: 1.25rem;
        height: auto;
    }

    ${(props) => props.color && css`
        color: ${props.color};
    `};
`;

export const ReminderLabel = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const ReminderBody = styled.div`
    padding-left: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
`;

interface StyledExpandedProps {
    expanded: boolean;
}
export const StyledChevron = styled(ChevronRight).withConfig({
    shouldForwardProp: (prop) => !['expanded'].includes(prop),
})<StyledExpandedProps>`
    width: 1.15rem;
    height: 1.15rem;
    transition: transform 0.25s ease-in-out 0s;
    margin-right: -0.5rem;

    ${(props) => props.expanded && css`
        transition: transform 0.25s ease-in-out 0s;
        transform: rotate(90deg);
    `}
`;
