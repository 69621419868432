import styled from 'styled-components';
import Accordion from 'components/Accordion';
import { GREY_500, DIVIDER, GREY_300 } from '../../constants';

export const StyledAccordion = styled(Accordion)`
    display: flex;
    flex: 1;
    align-items: center;
    align-self: stretch;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid ${DIVIDER};
    padding: 0;
    
    li {
        flex: 1;

        > button {
            padding: 0.8125rem 0.75rem;
            border-radius: 0.5rem;
        }
    }
`;

export const AccordionHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PrimaryText = styled.h3`
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: ${GREY_500};
`;

export const SecondaryText = styled.h4`
    font-size: 0.8125rem;
    line-height: 1.25rem;
    color: ${GREY_300};
    font-weight: 300;
`;
