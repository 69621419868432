/* eslint-disable react/jsx-no-undef */
import { Field, isRequired } from '@rainbow-modules/forms';
import { RadioGroup, RenderIf, Textarea } from 'react-rainbow-components';
import { useForm, useFormState } from 'react-final-form';
import Input from 'components/Input';
import FeatureSchemaPicker from 'components/FeatureSchemaPicker';
import { useEffect, useMemo } from 'react';

const selectSkillOptions = [
    {
        value: 'open',
        label: 'Independent Persona',
        description: 'This persona will not respond to predefined skills.',
    },
    {
        value: 'system',
        label: 'Predefined Skill',
        description: 'This persona will use a specific skill defined in the system.',
    },
];

interface Params {
    disabledFields?: string[],
    hiddenFields?: string[],
}

const Fields = ({
    disabledFields = [],
    hiddenFields = [],
} :Params) => {
    const { change } = useForm();
    const { values } = useFormState();

    useEffect(
        () => {
            if (!disabledFields.includes('skill') && values.skill === 'open') {
                change('feature', undefined);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [change, values.skill],
    );

    const skillsOptions = useMemo(
        () => selectSkillOptions.map((skillOption) => ({
            ...skillOption,
            disabled: disabledFields.includes('skill'),
        })),
        [disabledFields],
    );

    return (
        <>
            <Field
                component={Input}
                name="name"
                label="Name"
                labelAlignment="left"
                placeholder="Enter a name for the AI Persona"
                borderRadius="semi-rounded"
                required
                className="rainbow-m-bottom_medium"
            />
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Textarea}
                name="description"
                label="Description"
                labelAlignment="left"
                borderRadius="semi-rounded"
                placeholder="Enter a description for the AI Persona"
                className="rainbow-m-bottom_medium"
            />
            <RenderIf isTrue={!hiddenFields.includes('skill')}>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={RadioGroup}
                    name="skill"
                    // type="radio"
                    label="Select the Skill type for your AI Persona"
                    labelAlignment="left"
                    required
                    options={skillsOptions}
                    orientation="horizontal"
                    validate={isRequired('This field is required')}
                    className="rainbow-m-bottom_medium"
                />
            </RenderIf>
            <RenderIf isTrue={!hiddenFields.includes('feature') && values.skill === 'system'}>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={FeatureSchemaPicker}
                    name="feature"
                    label={(
                        disabledFields.includes('feature')
                            ? 'System AI Skill'
                            : 'Select the system AI Skill do you want to use'
                    )}
                    labelAlignment="left"
                    required={!disabledFields.includes('feature')}
                    disabled={disabledFields.includes('feature')}
                    validate={isRequired('This field is required')}
                    placeholder="Click to select a system AI Skill"
                    className="rainbow-m-bottom_large"
                    borderRadius="semi-rounded"
                />
            </RenderIf>
        </>
    );
};

export default Fields;
