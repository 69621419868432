/* eslint-disable react/forbid-component-props */
import { Modal } from 'react-rainbow-components';
import Button from 'components/Button';
import { UniversalFormModalProps } from '@rainbow-modules/forms/lib/components/UniversalFormModal';
import { useUniqueIdentifier } from '@rainbow-modules/hooks';
import UniversalForm from 'components/UniversalForm';

interface FormModalProps extends UniversalFormModalProps {
    onOpened?: () => void;
}

const FormModal = (props: FormModalProps) => {
    const {
        id,
        className,
        style,
        isOpen = false,
        onRequestClose = () => {},
        fields: Fields = () => null,
        onSubmit = () => {},
        title = '',
        initialValues = {},
        submitButtonLabel = 'Submit',
        cancelButtonLabel = 'Cancel',
        onOpened = () => {},
        disableSubmit = false,
        borderRadius = 'semi-square',
        size = 'small',
        ...rest
    } = props;
    const uniqueId = useUniqueIdentifier('form-modal');
    const footer = (
        <div className="rainbow-flex rainbow-justify_end">
            <Button
                variant="border"
                onClick={onRequestClose}
                borderRadius={borderRadius}
            >
                {cancelButtonLabel}
            </Button>
            <Button
                disabled={disableSubmit as boolean}
                variant="brand"
                type="submit"
                form={uniqueId}
                borderRadius={borderRadius}
            >
                {submitButtonLabel}
            </Button>
        </div>
    );
    return (
        <Modal
            id={id}
            className={className}
            style={style}
            title={title}
            isOpen={isOpen}
            footer={footer}
            onRequestClose={onRequestClose}
            onOpened={onOpened}
            borderRadius={borderRadius}
            size={size}
        >
            <UniversalForm onSubmit={onSubmit} id={uniqueId} initialValues={initialValues}>
                <Fields {...rest} />
            </UniversalForm>
        </Modal>
    );
};

export default FormModal;
