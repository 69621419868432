import styled from 'styled-components';
import { Tab } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import SendNoteForm from 'components/SendNoteForm';
import {
    WHITE, GREY_100, GREY_800,
} from '../../../constants';

export const CloseButton = styled(ButtonIcon)`
    background: ${GREY_100};
    color: ${GREY_800};
`;

export const DetailsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: hidden;
`;

export const DetailsContent = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    padding-bottom: 4rem;
`;

export const Gradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 90%);
    height: 6rem;
    position: absolute;
    top: -6rem;
    left: 0;
    width: calc(100% - 1rem);
    z-index: 1000;
    pointer-events: none;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 1.5rem;
    gap: 1rem;
`;

export const HeaderActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const CustomerContainer = styled.div`
    display: flex;
    background: ${GREY_100};
    border-radius: 100px;
    padding: 0.5rem;
    margin: 1.5rem 0;
`;

export const Line = styled.div`
    border-left: 1px dashed transparent;
    width: 1px;
    height: 1.5rem;
    position: absolute;
    top: 6rem;
    left: 2.2rem;
`;

export const SummaryContainer = styled.div`
    display: flex;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
`;

export const TabsetContainer = styled.div`
    padding: 0;
    position: sticky;
    top: 0;
    margin-bottom: 1.5rem;
    z-index: 1000;
    background: white;
`;

export const StyledTab = styled(Tab)`
    display: flex;
    height: 3.5rem;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    color: ${GREY_800};

    button {
        padding: 0;
    }
    button::before {
        border-radius: 0;
        height: 2px;
    }
`;

export const TabLabel = styled.span`
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.02688rem;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NotesContainer = styled.div`
    background: ${WHITE};
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 1.5rem;
    z-index: 1001;
`;

export const StyledSendNoteForm = styled(SendNoteForm)`
    flex-grow: 1;
    z-index: 1;
    margin: 0;
`;
