import { EmailPasswordSignUpForm } from '@rainbow-modules/auth';
import Logo from 'components/Logo';
import LoginLayout from 'layouts/Login';
import {
    LoginHeader,
    LoginDescription,
    TermsOfServiceContainer,
} from './styled';

const EmailSignUp = () => (
    <LoginLayout>
        <Logo />
        <LoginHeader>
            Welcome to Voxity!
            {' '}
            {'\u{1F44B}'}
        </LoginHeader>
        <LoginDescription>
            Please sign up with your account and start the adventure
        </LoginDescription>
        <br />
        <EmailPasswordSignUpForm />
        <TermsOfServiceContainer>
            <div>
                By signing up, you have read and agree to our
                {' '}
                <a href="https://www.voxity.ai/terms-of-service">terms of service</a>
                {' '}
                and
                {' '}
                <a href="https://www.voxity.ai/privacy-policy">privacy policy</a>
            </div>
        </TermsOfServiceContainer>
    </LoginLayout>
);

export default EmailSignUp;
