import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { ElementContainer, IconContainer, StyledRecordField } from './styled';

interface DetailElementProps {
    icon?: ReactNode;
    label?: ReactNode;
    value?: ReactNode | Record<string, unknown>;
    [key: string]: any;
}

const DetailElement = ({
    icon, label, value, ...rest
}: DetailElementProps) => (
    <ElementContainer>
        <IconContainer>
            <RenderIf isTrue={icon}>
                {icon}
            </RenderIf>
        </IconContainer>
        <StyledRecordField label={label} value={value} {...rest} />
    </ElementContainer>
);

export default DetailElement;
