import { EntityGet } from 'data/firestore/types';
import useCustomer from 'data/firestore/agent/customer/use';
import { Customer } from 'data/firestore/agent/customer/types';
import CustomerBasicInfo from './basicInfo';

interface CustomerInformationProps {
    agentId?: string;
    customerId?: string;
    data?: EntityGet<Customer>;
}

const CustomerInformation = ({
    agentId = '',
    customerId = '',
    data,
}: CustomerInformationProps) => {
    const { data: customerInfo, isLoading } = useCustomer(
        agentId,
        customerId,
        {
            disabled: !agentId || !customerId,
            track: [agentId, customerId],
        },
    );

    return (
        <CustomerBasicInfo
            isLoading={isLoading}
            data={customerInfo || data}
        />
    );
};

export default CustomerInformation;
