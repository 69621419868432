import styled from 'styled-components';
import { Drawer } from 'react-rainbow-components';
import { DIVIDER } from '../../constants';

export const StyledDrawer = styled(Drawer)`
    border-radius: 1.5rem;
    margin: 1.5rem;
    box-sizing: border-box;
    height: calc(100% - 3rem);
    min-height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);

    & > h1 + div {
        margin: 0;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    margin: 1rem 1rem 0 1.5rem;
    padding-bottom: 1rem;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${DIVIDER};
`;

export const HeaderLabel = styled.h1`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0;
    flex: 1;
`;
