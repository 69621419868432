import {
    MouseEvent, UIEvent, useCallback, useEffect, useMemo,
    useRef,
} from 'react';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import { CalendarEventClick } from 'types';
import { DIVIDER } from '../../../constants';
import getHeightOfMinutes from '../helpers/getHeightOfMinutes';
import CalendarItem from './calendar';
import {
    Container,
    StyledCalendars,
    Grid,
    GridLine,
    Content,
    Scroll,
} from './styled';
import ClockLine from './clockLine';

const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

interface Params {
    date?: Date;
    selectedEvent?: string | null;
    onScroll: (e: UIEvent<HTMLDivElement>) => void,
    onEventClick?: CalendarEventClick;
    onAvailableSpaceClick: (calendar: Calendar, e: MouseEvent<HTMLDivElement>) => void;
    calendars?: Calendar[],
    events?: Calendarevent[],
    hideClockLine?: boolean;
    timeZone: string;
}

const Calendars = ({
    date = new Date(),
    selectedEvent,
    calendars = [],
    events = [],
    onScroll = () => {},
    onEventClick = () => {},
    onAvailableSpaceClick = () => {},
    hideClockLine = false,
    timeZone,
}: Params) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const handleAvailableSpaceClick = useCallback(
        (calendar: Calendar) => (
            e: MouseEvent<HTMLDivElement>,
        ) => onAvailableSpaceClick(calendar, e),
        [onAvailableSpaceClick],
    );

    const calendarsList = useMemo(
        () => calendars.map(
            (calendar) => {
                const calendarEvents = events.filter((event) => event.calendarId === calendar.id);
                const weekDay = weekDays[date.getDay()];
                return (
                    <CalendarItem
                        key={calendar.id}
                        color={calendar.color}
                        events={calendarEvents}
                        selectedEvent={selectedEvent}
                        availability={calendar.availability.filter(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            ({ dayOfWeek }) => dayOfWeek === weekDay,
                        )}
                        onEventClick={onEventClick}
                        onAvailableSpaceClick={handleAvailableSpaceClick(calendar)}
                        timeZone={timeZone}
                    />
                );
            },
        ),
        [calendars, date, events, handleAvailableSpaceClick, onEventClick, timeZone, selectedEvent],
    );

    const gridLines = useMemo(
        () => Array.from(
            Array(48),
            (_value, index) => {
                const color = (
                    index % 2 === 0
                        ? DIVIDER
                        : `${DIVIDER}72`
                );
                return <GridLine key={index} color={color} />;
            },
        ),
        [],
    );

    useEffect(
        () => {
            const minAvailability = calendars.reduce(
                (result, calendar) => Math.min(
                    result,
                    calendar.availability.reduce(
                        (
                            minStartAt: number,
                            availableTime: { startAt: number },
                        ) => Math.min(availableTime.startAt, minStartAt),
                        Infinity,
                    ),
                ),
                Infinity,
            );
            const minAvailableTimePos = getHeightOfMinutes((minAvailability - 900) / 60);
            if (scrollRef.current) {
                scrollRef.current.scrollTop = minAvailableTimePos;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [date],
    );

    return (
        <Scroll
            ref={scrollRef}
            onScroll={onScroll}
        >
            <Container>
                <Content>
                    <Grid>
                        {gridLines}
                    </Grid>
                    <ClockLine visible={!hideClockLine} />
                    <StyledCalendars>
                        <div />
                        {calendarsList}
                    </StyledCalendars>
                </Content>
            </Container>
        </Scroll>
    );
};

export default Calendars;
