import { ReactNode, useMemo } from 'react';
import { useCurrentUser, useUserClaims } from '@rainbow-modules/firebase-hooks';
import { useParams } from 'react-router-dom';
import { User as AuthUser } from 'firebase/auth';
import { EntityGet } from 'data/firestore/types';
import { User } from 'data/firestore/user/types';
import useUser from 'data/firestore/user/use';
import { Provider } from './context';

interface Params {
    children?: ReactNode | ReactNode[];
}

const getUserRoleForAgent = (
    user: EntityGet<User> | User | null,
    agentId: string,
) => {
    if (agentId && Object.hasOwn(user?.agents || {}, agentId)) {
        return (user?.agents as Record<string, string>)[agentId] as string;
    }
    return '';
};

const UserProvider = ({ children }: Params) => {
    const { agentId = '' } = useParams<{ agentId?: string }>();
    const { uid } = useCurrentUser() || {} as AuthUser;
    const [claims, isLoadingClaims] = useUserClaims();
    const { data: userData, isLoading } = useUser(
        uid,
        {
            disabled: !uid,
            track: [uid],
        },
    );

    const role = useMemo(() => getUserRoleForAgent(userData, agentId), [agentId, userData]);

    const value = useMemo(() => ({
        user: userData,
        isLoading: isLoading || isLoadingClaims,
        isAdmin: claims?.roles?.includes('admin'),
        role,
    }), [isLoading, isLoadingClaims, role, userData, claims]);

    return (
        <Provider
            value={value}
        >
            {children}
        </Provider>
    );
};

export default UserProvider;
