import { Outlet } from 'react-router-dom';
import BasicPageLayout from 'layouts/BasicPage';
import { StyledCard } from './styled';

const CalendarPage = () => (
    <BasicPageLayout>
        <StyledCard>
            <Outlet />
        </StyledCard>
    </BasicPageLayout>
);

export default CalendarPage;
