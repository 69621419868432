/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Availability, WeekDay } from './types';
import AvailabilityInputItem from './dayAvailabilityInput';
import { Container } from './styled';

interface CalendarAvailabilityInputProps {
    id?: string;
    className?: string;
    value?: Availability[];
    onChange?: (value: Availability[]) => void;
}

const CalendarAvailabilityInput = (props: CalendarAvailabilityInputProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
    } = useReduxForm(props);

    const availabilitiesPerWeekDay: Record<WeekDay, Availability[]> = useMemo(() => ({
        sunday: (valueInProps || []).filter((value) => value.dayOfWeek === 'sunday'),
        monday: (valueInProps || []).filter((value) => value.dayOfWeek === 'monday'),
        tuesday: (valueInProps || []).filter((value) => value.dayOfWeek === 'tuesday'),
        wednesday: (valueInProps || []).filter((value) => value.dayOfWeek === 'wednesday'),
        thursday: (valueInProps || []).filter((value) => value.dayOfWeek === 'thursday'),
        friday: (valueInProps || []).filter((value) => value.dayOfWeek === 'friday'),
        saturday: (valueInProps || []).filter((value) => value.dayOfWeek === 'saturday'),
    }), [valueInProps]);

    const handleChange = useCallback(
        (day: WeekDay) => (newValue: Availability[]) => {
            const newAvailability = Object
                .keys(availabilitiesPerWeekDay)
                .reduce(
                    (acc: Availability[], weekDay: string) => [
                        ...acc,
                        ...(
                            weekDay !== day
                                ? availabilitiesPerWeekDay[weekDay as WeekDay]
                                : newValue
                        ),
                    ],
                    [],
                );
            onChange(newAvailability);
        },
        [availabilitiesPerWeekDay, onChange],
    );

    const items = useMemo(
        () => Object.keys(availabilitiesPerWeekDay).map(
            (weekDay) => (
                <AvailabilityInputItem
                    dayOfWeek={weekDay as WeekDay}
                    availabilityHours={availabilitiesPerWeekDay[weekDay as WeekDay]}
                    onChange={handleChange(weekDay as WeekDay)}
                />
            ),
        ),
        [availabilitiesPerWeekDay, handleChange],
    );

    return (
        <Container id={id} className={className}>
            {items}
        </Container>
    );
};

export default CalendarAvailabilityInput;
