import styled from 'styled-components';
import UniversalForm from 'components/UniversalForm';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    height: 100%;
`;

export const Form = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    height: 100%;
    align-self: stretch;
    form {
        height: 100%;
    }
`;
