import ButtonIcon from 'components/ButtonIcon';
import HiddenAnchor from 'components/HiddenAnchor';
import Download from 'components/icons/download';
import { useCallback, useRef, useState } from 'react';

interface Props {
    url: string;
    name: string;
    originalName: string;
}

const DownloadButton = ({ url, name, originalName }: Props) => {
    const hiddenLinkRef = useRef<HTMLAnchorElement>(null);
    const [isDownloading, setIsDownloading] = useState(false);

    const download = useCallback(async () => {
        if (!hiddenLinkRef.current) return;

        setIsDownloading(true);
        const originalExt = originalName.split('.').pop();
        try {
            const res = await fetch(url);
            const blob = await res.blob();
            const downloadUrl = URL.createObjectURL(blob);
            hiddenLinkRef.current.href = downloadUrl;
            hiddenLinkRef.current.download = `${name}.${originalExt}`;
            hiddenLinkRef.current.click();
        } catch (error) {
            console.error('Error downloading file', error);
        }
    }, [name, originalName, url]);

    return (
        <>
            <HiddenAnchor ref={hiddenLinkRef} />
            <ButtonIcon
                borderRadius="semi-square"
                icon={<Download />}
                tooltip="Download File"
                size="small"
                onClick={download}
                disabled={isDownloading}
            />
        </>
    );
};

export default DownloadButton;
