/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'unless',
    body: '{{#unless ${1}}}\n\t${0}\n{{/unless}}',
    documentation: 'unless statement',
};

export default snippet;
