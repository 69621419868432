import { Field, useFormState } from 'react-final-form';
import { isEmpty } from '@rainbow-modules/validation';
import CalendarAvailabilityInput from 'components/CalendarAvailabilityInput';
import CalendarEventStatusSelect from 'components/CalendarEventStatusSelect';
import CalendarEventStatusPicker from 'components/CalendarEventStatusPicker';
import CalendarEventTypeSelect from 'components/CalendarEventTypeSelect';
import ColorPicklist from 'components/ColorPicklist';
import {
    FormSection,
    FormSectionHeader,
    SectionTitle,
    SectionDescription,
    FieldsContainer,
    StyledInput,
} from './styled';

const validateNotEmpty = (value: unknown) => {
    if (isEmpty(value)) return 'This field is required';
    return undefined;
};

interface Params {
    agentId?: string;
}

const FormFields = ({
    agentId = '',
}: Params) => {
    const { values } = useFormState();

    return (
        <>
            <FormSection>
                <FormSectionHeader>
                    <SectionTitle>Calendar Styles</SectionTitle>
                </FormSectionHeader>
                <FieldsContainer>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledInput}
                        name="name"
                        label="Calendar Name"
                        labelAlignment="left"
                        placeholder="Enter a name for your calendar"
                        borderRadius="semi-square"
                        autoComplete="off"
                        required
                        validate={validateNotEmpty}
                    />
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={ColorPicklist}
                        name="color"
                        label="Color"
                        labelAlignment="left"
                        placeholder="Select Color"
                        borderRadius="semi-square"
                        required
                        validate={validateNotEmpty}
                    />
                </FieldsContainer>
            </FormSection>
            <FormSection>
                <FormSectionHeader>
                    <SectionTitle>Event Type</SectionTitle>
                    <SectionDescription>Add description here</SectionDescription>
                </FormSectionHeader>
                <FieldsContainer>
                    <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                        component={CalendarEventTypeSelect}
                        agentId={agentId}
                        name="eventTypes"
                        required
                        validate={validateNotEmpty}
                    />
                </FieldsContainer>
            </FormSection>
            <FormSection>
                <FormSectionHeader>
                    <SectionTitle>Event Status</SectionTitle>
                    <SectionDescription>Add description here</SectionDescription>
                </FormSectionHeader>
                <FieldsContainer>
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={CalendarEventStatusSelect}
                        agentId={agentId}
                        name="eventStatuses"
                        required
                        validate={validateNotEmpty}
                    />
                    <Field
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={CalendarEventStatusPicker}
                        name="defaultEventStatusId"
                        label="Default Status"
                        labelAlignment="left"
                        placeholder="Select the default status for new events"
                        borderRadius="semi-square"
                        required
                        eventStatuses={values?.eventStatuses}
                        validate={validateNotEmpty}
                    />
                </FieldsContainer>
            </FormSection>
            <FormSection>
                <FormSectionHeader>
                    <SectionTitle>Calendar Availability</SectionTitle>
                    <SectionDescription>
                        Set when you’re regularly available for events.
                    </SectionDescription>
                </FormSectionHeader>
                <FieldsContainer>
                    <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                        component={CalendarAvailabilityInput}
                        name="availability"
                        required
                    />
                </FieldsContainer>
            </FormSection>
        </>
    );
};

export default FormFields;
