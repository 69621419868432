import formatIntlPhoneNumber from 'data/services/phone/formatter';
import {
    Description, Label, PhoneNumberOptionContainer, InputRadio,
} from './styled';

interface PhoneNumberOptionProps extends Record<string, unknown> {
    number: string;
    language: string;
}

const languageMap: Record<string, string> = {
    en: 'English',
    es: 'Spanish',
    auto: 'Auto',
};

const PhoneNumberOption = ({ number, language, ...rest }: PhoneNumberOptionProps) => (
    <PhoneNumberOptionContainer {...rest}>
        <InputRadio type="radio" readOnly checked={rest.isSelected as boolean} />
        <div>
            <Label>{formatIntlPhoneNumber(number)}</Label>
            <Description>{`Preferred Language: ${languageMap[language]}`}</Description>
        </div>
    </PhoneNumberOptionContainer>
);

export default PhoneNumberOption;
