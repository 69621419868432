import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { RenderIf, ShowIf } from 'react-rainbow-components';
import {
    StyledLink, Container, StyledLabel, StyledChevron,
} from './styled';

interface ItemSectionProps {
    children: React.ReactNode;
    label: string;
    icon?: React.ReactNode;
    prefix: string;
    isShrinked?: boolean;
}

const ItemSection = ({
    children, label, icon, prefix, isShrinked,
}: ItemSectionProps) => {
    const match = useMatch(`${prefix}/:sectionName`);
    const [expanded, setExpanded] = useState<boolean>(
        Boolean(match?.params?.sectionName && !isShrinked),
    );

    useEffect(
        () => setExpanded(Boolean(match?.params?.sectionName) && !isShrinked),
        [match, isShrinked],
    );

    return (
        <>
            <Container
                className="rainbow-p-left_x-small rainbow-p-right_x-small"
                onClick={() => {
                    setExpanded((prevExpanded) => !prevExpanded);
                }}
            >
                <StyledLink
                    isActive={!!match}
                >
                    {icon}
                    <RenderIf isTrue={!isShrinked}>
                        <StyledLabel>{label}</StyledLabel>
                        <StyledChevron expanded={expanded} />
                    </RenderIf>
                </StyledLink>
            </Container>
            <ShowIf isTrue={expanded} inAnimation="fade" outAnimation="slideVertical" className="rainbow-p-bottom_x-small">
                {children}
            </ShowIf>
        </>
    );
};

export default ItemSection;
