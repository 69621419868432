import { IconProps } from 'types';

const Location = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.97046 9.14465C6.97046 6.2787 9.24145 3.99049 12 3.99049C14.7586 3.99049 17.0296 6.2787 17.0296 9.14465C17.0296 9.77329 16.7554 10.7234 16.2342 11.8965C15.7269 13.0381 15.0378 14.2784 14.3231 15.4473C13.6103 16.6131 12.883 17.6904 12.3078 18.5025C12.1991 18.6559 12.0961 18.7995 12 18.932C11.9039 18.7995 11.8009 18.6559 11.6922 18.5025C11.117 17.6904 10.3897 16.6131 9.67691 15.4473C8.96224 14.2784 8.27311 13.0381 7.76586 11.8965C7.24465 10.7234 6.97046 9.77329 6.97046 9.14465ZM12 1.99049C8.09851 1.99049 4.97046 5.2129 4.97046 9.14465C4.97046 10.2154 5.39612 11.4887 5.93816 12.7086C6.49416 13.9599 7.23167 15.282 7.97055 16.4906C8.71133 17.7022 9.46447 18.8175 10.0602 19.6585C10.3581 20.0792 10.6186 20.4339 10.8204 20.7001C11.0033 20.9413 11.1784 21.1641 11.2857 21.2736L12 22.0027L12.7143 21.2736C12.8216 21.1641 12.9967 20.9413 13.1796 20.7001C13.3814 20.4339 13.6419 20.0792 13.9398 19.6585C14.5355 18.8175 15.2887 17.7022 16.0295 16.4906C16.7683 15.282 17.5059 13.9599 18.0619 12.7086C18.6039 11.4887 19.0296 10.2154 19.0296 9.14465C19.0296 5.2129 15.9015 1.99049 12 1.99049ZM12.0001 10.903C12.9515 10.903 13.7228 10.1157 13.7228 9.14462C13.7228 8.17353 12.9515 7.3863 12.0001 7.3863C11.0486 7.3863 10.2773 8.17353 10.2773 9.14462C10.2773 10.1157 11.0486 10.903 12.0001 10.903Z" fill="currentColor" />
    </svg>
);
export default Location;
