import styled, { css } from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';
import { GREY_800 } from '../../constants';

export default styled(ButtonIcon)`
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;

    svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
            font-size: 1.5rem !important;
        }

    ${(props) => props.size === 'small' && css`
        width: 2rem;
        height: 2rem;

        svg {
            width: 1.35rem !important;
            height: 1.35rem !important;
            font-size: 1.35rem !important;
        }
    `}

    ${(props) => props.size === 'large' && css`
        width: 3.5rem;
        height: 3.5rem;

        svg {
            width: 1.35rem !important;
            height: 1.35rem !important;
            font-size: 1.35rem !important;
        }
    `}

    ${(props) => props.variant === 'base' && css`
        color: ${GREY_800};
    `}

    ${(props) => props.borderRadius === 'semi-square' && css`
        border-radius: 0.375rem;
    `}

    ${(props) => props.borderRadius === 'semi-rounded' && css`
        border-radius: 0.75rem;
    `}

    ${(props) => props.shaded && css`
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    `}
`;
