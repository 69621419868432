import styled from 'styled-components';
import Card from 'components/Card';
import Drawer from 'components/Drawer';

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledDrawer = styled(Drawer)`
    width: 400px;

    div[id^=drawer-content] {
        padding: 0;
    }
`;
