import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';

const DeleteModal = () => (
    <ModalBody
        icon={<Trash />}
        title="Remove member from team?"
        description="Once this action is confirmed, it cannot be undone."
    />
);

export default DeleteModal;
