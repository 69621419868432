import styled, { css } from 'styled-components';
import { Warning } from '@rainbow-modules/icons';
import { ItemUser } from 'data/firestore/globals';
import {
    GREY_100,
    GREY_400, GREY_600, ORANGE_400, PURPLE_100, PURPLE_800,
} from '../../../../constants';

interface ItemProps {
    direction: 'outgoing' | 'incoming';
    role?: ItemUser['role'];
}

export const Item = styled.div<ItemProps>`
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0rem 0.75rem 0.75rem 0.75rem;
    background: ${GREY_100};
    position: relative;
    color: ${GREY_600};
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;

    ${(props) => props.role === 'agent' && css`
        background: ${PURPLE_100};
        color: ${PURPLE_800};
         margin-right: 5rem;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${PURPLE_100} transparent transparent transparent;
            left: 0;
            top: 0;
            transform: translateX(-50%);
    `}

    ${(props) => props.direction === 'incoming' && css`
        margin-right: 5rem;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${GREY_100} transparent transparent transparent;
            left: 0;
            top: 0;
            transform: translateX(-50%);
        `}

    ${(props) => props.direction === 'outgoing' && css`
        border-radius: 0.75rem 0rem 0.75rem 0.75rem;
        margin-left: 5rem;

        ::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.75rem 0.785rem 0;
            border-color: ${GREY_100} transparent transparent transparent;
            top: 0;
            right: 0;
            transform: translateX(50%);
    `}
`;

export const AuthorContainer = styled.div`
    padding: 6px 6px 6px 0;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
`;

export const AuthorName = styled.p`
    text-align: end;
    font-size: 12px;
    color: ${GREY_400};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Time = styled.h3`
    color: ${GREY_400};
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.375rem;
`;

export const MessageContainer = styled.div<ItemProps>`
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    ${(props) => props.direction === 'outgoing' && css`
        flex-direction: row-reverse;
    `};

    :last-of-type {
        padding-bottom: 16px;
    }
`;

export const MessageContent = styled.div<ItemProps>`
    display: flex;
    padding-bottom: 0.5rem;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 0 0;

    ${(props) => props.direction === 'incoming' && css`
        align-items: flex-start;
    `};
`;

export const BubbleTopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const BubbleBottomContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
`;

export const WarningIcon = styled(Warning)`
    width: 1rem !important;
    height: 1rem !important;
    font-size: 1rem !important;
    color: ${ORANGE_400};
`;
