import styled from 'styled-components';
import { lighten } from 'react-rainbow-components/styles/helpers/color';
import {
    GREY_500, DIVIDER, RED_500,
} from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-self: stretch;
    overflow: auto;
`;

export const ItemContainer = styled.div<{ isPending?: boolean }>`
    display: flex;
    padding: 0.5625rem 1rem;
    align-items: center;
    gap: 0.625rem;
    border-bottom: 1px solid ${DIVIDER};
    align-self: stretch;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    cursor: pointer;
    & > * {
        pointer-events: none;
    }

    ${(props) => props.isPending && `
        background-color: ${lighten(DIVIDER, 0.8)};
        position: relative;
        opacity: 0.8;

        &::before {
            content: '';
            position: absolute;
            top: calc(50% - 4px);
            left: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${RED_500};
        }
    `}
`;

export const SectionHeader = styled(ItemContainer)`
    position: sticky;
    top: -1px;
    z-index: 1;
    background: #F8F7FA;
`;

export const StyledDot = styled.div<{ color: string }>`
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: ${(props) => props.color};
`;

export const Label = styled.span`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_500};
`;

export const TimeLabel = styled(Label)`
    width: 11.25rem;
`;

export const StatusBadge = styled.div<{ color: string }>`
    display: flex;
    padding: 0.1875rem 0.375rem;
    border-radius: 0.25rem;
    align-items: center;
    gap: 0.625rem;
    color: ${(props) => props.color};
    background: ${(props) => `${props.color}29`};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
`;
