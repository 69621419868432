/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import { Calendareventtype } from 'data/firestore/agent/calendareventtype/types';
import { PickerVariantProps } from './types';

const CalendarEventTypePickerVariantSelect = (props: PickerVariantProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
        eventTypes = [],
        isLoading = false,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Calendareventtype).id
                );
                const found = eventTypes.find((eventType) => eventType.id === valueId);
                if (found) {
                    if (typeof valueInProps === 'string') {
                        onChange(found);
                    }
                    const {
                        id: eventTypeId,
                        name: eventTypeName,
                        description: eventTypeDescription,
                    } = found;
                    return {
                        label: eventTypeName,
                        name: eventTypeId,
                        value: found,
                        searchableText: `${eventTypeName} - ${eventTypeDescription}`,
                    };
                }
            }

            if (eventTypes.length > 0) {
                const firstInList = eventTypes[0];
                onChange(firstInList);
                const {
                    id: eventTypeId,
                    name: eventTypeName,
                    description: eventTypeDescription,
                } = firstInList;
                return {
                    label: eventTypeName,
                    name: eventTypeId,
                    value: firstInList,
                    searchableText: `${eventTypeName} - ${eventTypeDescription}`,
                };
            }
        }
        return undefined;
    }, [eventTypes, isLoading, onChange, valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => eventTypes.map(
            (eventType) => (
                <Option
                    key={`eventType__${eventType.id}`}
                    label={eventType.name}
                    name={eventType.id}
                    value={eventType}
                    searchableText={`${eventType.name} - ${eventType.description}`}
                />
            ),
        ),
        [eventTypes],
    );

    return (
        <Picklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
            enableSearch
        >
            <Option label="Select Event Type" variant="header" />
            {pickListOptions}
        </Picklist>
    );
};

export default CalendarEventTypePickerVariantSelect;
