import { useState, useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Paginator, Message } from '@twilio/conversations';

const usePrependItems = (customerId: string, messagesCount: number) => {
    const queryClient = useQueryClient();
    const [firstItemIndex, setFirstItemIndex] = useState(messagesCount);

    const prependItems = useCallback(async () => {
        const data = queryClient.getQueryData<Paginator<Message>>(['messages', customerId]);
        if (data?.hasPrevPage) {
            const result = await data.prevPage();
            setFirstItemIndex((prevIndex) => prevIndex - result.items.length);
            queryClient.setQueryData<Paginator<Message> | undefined>(
                ['messages', customerId],
                (oldData) => {
                    if (oldData) {
                        return {
                            ...result,
                            items: [...result.items, ...oldData.items],
                        };
                    }
                    return oldData;
                },
            );
        }
    }, [queryClient, customerId]);

    return useMemo(
        () => ({
            prependItems,
            firstItemIndex,
        }),
        [firstItemIndex, prependItems],
    );
};

export default usePrependItems;
