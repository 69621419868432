import { useCallback, useMemo } from 'react';
import useHttpMutation from 'data/firestore/useHttpMutation';

const useSendNote = () => {
    const { mutate: hookMutate, mutateAsync: hookMutateAsync, ...rest } = useHttpMutation({
        method: 'POST',
    });
    const mutate = useCallback(
        ({
            agentId,
            customerId,
            inboxId,
            opportunityId,
            note,
        }: Record<string, unknown>) => hookMutate({
            pathname: '/send-note',
            body: {
                agentId,
                customerId,
                inboxId,
                opportunityId,
                text: note,
            },
        }),
        [hookMutate],
    );

    const mutateAsync = useCallback(
        ({
            agentId,
            customerId,
            inboxId,
            opportunityId,
            note,
        }: Record<string, unknown>) => hookMutateAsync({
            pathname: '/send-note',
            body: {
                agentId,
                customerId,
                inboxId,
                opportunityId,
                text: note,
            },
        }),
        [hookMutateAsync],
    );

    return useMemo(() => ({ mutate, mutateAsync, ...rest }), [mutate, mutateAsync, rest]);
};

export default useSendNote;
