import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import { RenderIf } from 'react-rainbow-components';
import {
    ConversationSummaryContainer,
    SectionHeader,
    StyledLoadingShape,
} from './styled';

interface UserAvatarProps {
    sessionId: string;
}

const ConversationSummary = ({
    sessionId,
}: UserAvatarProps) => {
    const [data, isLoading] = useDocOnce({
        path: `/session/${sessionId}/metadata/summary`,
        flat: true,
    });

    if (!isLoading && !data?.summary) return null;

    return (
        <div className="rainbow-flex_column">
            <SectionHeader>Summary</SectionHeader>
            <ConversationSummaryContainer>
                <RenderIf isTrue={isLoading}>
                    <StyledLoadingShape />
                    <StyledLoadingShape width="90%" />
                    <StyledLoadingShape width="85%" />
                    <StyledLoadingShape width="95%" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    {data?.summary}
                </RenderIf>
            </ConversationSummaryContainer>
        </div>

    );
};

export default ConversationSummary;
