/* eslint-disable max-len */
import {
    ComponentType,
    useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Modal, RenderIf, Spinner } from 'react-rainbow-components';
import useUpdateAgentMutation from 'data/hooks/useUpdateAgentMutation';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import Footer from 'components/Footer';
import Close from 'components/icons/close';
import { InstructionTestComponentProps } from 'components/InstructionTestingModal/types';
import useInstructionsValidation from 'data/hooks/useInstructionsValidation';
import useAgentData from 'hooks/useAgentData';
import useRedirectOnMissingRoles from 'hooks/useRedirectWhenMissingRoles';
import useSystemMessageVersions from 'data/firestore/agent/systemmessageversion/useCollection';
import useSummaryTemplateVersions from 'data/firestore/agent/summarytemplateversion/useCollection';
import useInboxSubjectTemplateVersions from 'data/firestore/agent/inboxtitleversion/useCollection';
import useTemplatePartials from 'data/firestore/agent/templatepartial/useCollection';
import useTemplatePartialVersions from 'data/firestore/agent/templatepartial/version/useCollection';
import useCreateTemplatePartialMutation from 'data/hooks/useCreateTemplatePartialMutation';
import useUpdateTemplatePartialMutation from 'data/hooks/useUpdateTemplatePartialMutation';
import useDeleteTemplatePartialMutation from 'data/hooks/useDeleteTemplatePartialMutation';
import { orderBy, query } from 'firebase/firestore';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import Button from 'components/Button';
import Trash from 'components/icons/trash';
import ImportIcon from 'components/icons/import';
import ExportIcon from 'components/icons/export2';
import ModalBody from 'components/ModalBody';
import AlertTriangle from 'components/icons/alertTriangle';
import Handlebar from 'components/icons/handlebar';
import InstructionTestingModal from 'components/InstructionTestingModal';
import ImportModal from 'components/ImportInstructionsModal';
import useExportTemplatePartialsMutation from 'data/hooks/useExportTemplatePartialsMutation';
import useImportTemplatePartialsMutation from 'data/hooks/useImportTemplatePartialsMutation';
import GeneralVariables from '../../components/GeneralVariables';
import { VOIP_INSTRUCTIONS_TESTING_MODAL } from '../../constants';
import generalVariables from './variables';
import History from './history';
import FormFooter from './formFooter';
import { HistoryItem } from './types';
import Fields from './createPartialForm';
import DeleteModal from './deleteModal';
import MenuItem from './menuItem';
import {
    StyledSidebar, SidebarLabel,
    Content,
    Header,
    Title,
    Description,
    InstructionsInput,
    InstructionsInputContainer,
    InstructionsContainer,
    HelperContainer,
    StyledButtonIcon,
    HeaderWithActions,
    PageContainer,
    HeaderActions,
    StyledDeleteButtonIcon,
    HeaderContent,
    Row,
    HiddenA,
    InstructionsInputContent,
} from './styled';
import MainInstructionTesting from './InstructionTesting/MainInstructionTesting';
import SummaryInstructionTesting from './InstructionTesting/SummaryInstructionTesting';
import InboxTitleInstructionTesting from './InstructionTesting/InboxTitleInstructionTesting';

const description = 'Define how your AI assistant responds to calls, using customizable instructions and variables tailored to your business needs.';

interface IntstructinoValidationError {
    message: string;
    startLineNumber: number;
    endLineNumber: number;
    startColumn: number;
    endColumn: number;
}

const getValidationStatus = (errors: IntstructinoValidationError[], isLoading: boolean) => {
    if (isLoading) {
        return 'loading';
    }
    if (errors.length > 0) {
        return 'invalid';
    }
    return 'valid';
};

const mainTemplateName = 'main';
const esGreetingsTemplateName = 'greeting_es';
const enGreetingsTemplateName = 'greeting_en';
const summaryTemplate = 'summaryTemplate';
const inboxSubjectTemplate = 'inboxTitleTemplate';
const noPartialNames = [
    mainTemplateName,
    esGreetingsTemplateName,
    enGreetingsTemplateName,
    summaryTemplate,
    inboxSubjectTemplate,
];

const contentMapper: Record<string, ComponentType<InstructionTestComponentProps>> = {
    [mainTemplateName]: MainInstructionTesting,
    [summaryTemplate]: SummaryInstructionTesting,
    [inboxSubjectTemplate]: InboxTitleInstructionTesting,
};

const titleMapper: Record<string, string> = {
    [mainTemplateName]: 'Main Instructions',
    [summaryTemplate]: 'Call Summary',
    [inboxSubjectTemplate]: 'Inbox Subject',
};

const Instructions = () => {
    const { agentId = '' } = useParams();
    const { agent } = useAgentData();
    const { mutateAsync: updateAgent, isLoading: isUpdatingAgent } = useUpdateAgentMutation(agentId as string);
    const { mutateAsync: createTemplatePartial } = useCreateTemplatePartialMutation(agentId);
    const { mutateAsync: updateTemplatePartial, isLoading: isUpdatingPartial } = useUpdateTemplatePartialMutation();
    const { mutateAsync: deleteTemplatePartial, isLoading: isDeletingPartial } = useDeleteTemplatePartialMutation();

    const [helpOpen, setHelpOpen] = useState(false);
    const [editorContent, setEditorContent] = useState<string>(agent?.systemMessageTemplate || '');
    const [validationErrors, setValidationErrors] = useState<IntstructinoValidationError[]>([]);
    const { mutateAsync: validateInstructions, isLoading: isLoadingInstructionsValidation } = useInstructionsValidation(
        agentId as string,
    );
    useRedirectOnMissingRoles({
        requiredRoles: ['owner'],
    });
    const { data: templatePartials } = useTemplatePartials(agentId as string);
    const [selectedTemplate, setTemplate] = useState<string>(mainTemplateName);
    const [selectedVersion, setSelectedVersion] = useState<HistoryItem | null>(null);
    const [isTemplateInUse, setIsTemplateInUse] = useState(false);

    const [historyOpen, setHistoryOpen] = useState(false);
    const { data: systemMessageVersions } = useSystemMessageVersions(agentId as string, {
        disabled: !agentId,
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
    });
    const { data: summaryTemplateVersions } = useSummaryTemplateVersions(agentId as string, {
        disabled: !agentId,
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
    });
    const { data: inboxSubjectTemplateVersions } = useInboxSubjectTemplateVersions(agentId as string, {
        disabled: !agentId,
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
    });
    const selectedPartialId = templatePartials?.find(({ name }) => name === selectedTemplate)?.id;
    const { data: templatePartialVersions } = useTemplatePartialVersions(agentId as string, selectedPartialId || '', {
        disabled: !agentId || !selectedPartialId,
        listQueryFn: (ref) => query(ref, orderBy('createdAt', 'desc')),
        track: [selectedPartialId],
    });

    // instructions testing
    const connectedTestingModalProps = useConnectModal(VOIP_INSTRUCTIONS_TESTING_MODAL);
    const [openInstructionTesting] = useOpenModal(VOIP_INSTRUCTIONS_TESTING_MODAL);

    // Import/export
    const hiddenLinkRef = useRef<HTMLAnchorElement>(null);
    const { mutateAsync: exportInstructions, isLoading: isExporting } = useExportTemplatePartialsMutation(agentId || '');
    const { mutateAsync: importInstructions, isLoading: isImporting } = useImportTemplatePartialsMutation(agentId || '');

    const connectedModalProps = useConnectModal('add-template-partial');
    const [openCreateForm, closeCreateForm] = useOpenModal('add-template-partial');
    const inUseConnectedModalProps = useConnectModal('template-partial-in-use');
    const [openInUseModal, closeInUseModal] = useOpenModal('template-partial-in-use');

    useEffect(() => {
        if (selectedVersion) {
            setEditorContent(selectedVersion?.value || '');
        }
    }, [selectedVersion]);

    useEffect(() => {
        setSelectedVersion(null);
    }, [selectedTemplate]);

    useEffect(() => {
        if (
            [
                mainTemplateName,
                enGreetingsTemplateName,
                esGreetingsTemplateName,
                summaryTemplate,
                inboxSubjectTemplate,
            ].includes(selectedTemplate)
        ) {
            setIsTemplateInUse(false);
            return;
        }
        const isUsed = agent?.systemMessageTemplate?.includes(`{{> ${selectedTemplate}}}`) || templatePartials.some(({ content }) => content.includes(`{{> ${selectedTemplate}}}`));
        setIsTemplateInUse(isUsed);
    }, [agent?.systemMessageTemplate, agent?.greetings?.en, agent?.greetings?.es, selectedTemplate, templatePartials]);

    useEffect(
        () => {
            if (selectedTemplate === mainTemplateName) {
                setEditorContent(agent?.systemMessageTemplate || '');
            } else if (selectedTemplate === enGreetingsTemplateName) {
                setEditorContent(agent?.greetings?.en || '');
            } else if (selectedTemplate === esGreetingsTemplateName) {
                setEditorContent(agent?.greetings?.es || '');
            } else if (selectedTemplate === summaryTemplate) {
                setEditorContent(agent?.summaryTemplate || '');
            } else if (selectedTemplate === inboxSubjectTemplate) {
                setEditorContent(agent?.inboxTitleTemplate || '');
            } else {
                setEditorContent(templatePartials?.find(({ name }) => name === selectedTemplate)?.content || '');
            }
        },
        [
            openCreateForm,
            selectedTemplate,
            templatePartials,
            agent?.systemMessageTemplate,
            agent?.greetings?.en,
            agent?.greetings?.es,
            agent?.summaryTemplate,
            agent?.inboxTitleTemplate,
        ],
    );

    const createPartial = () => {
        openCreateForm({
            title: '',
            onSubmit: async ({ name, description: partialDescription }: { name: string; description?: string }) => {
                showAppSpinner();
                try {
                    await createTemplatePartial({
                        body: {
                            name,
                            description: partialDescription,
                            content: '{{! This is a comment. Remove it and add your instructions here}}',
                        },
                    });
                    closeCreateForm();
                    setTemplate(name);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        });
    };

    const handleSaveChanges = async () => {
        try {
            if (selectedTemplate === 'main') {
                await updateAgent({ body: { systemMessageTemplate: editorContent } });
            } else if (selectedTemplate === enGreetingsTemplateName) {
                await updateAgent({
                    body: {
                        greetings: {
                            ...agent?.greetings,
                            en: editorContent,
                        },
                    },
                });
            } else if (selectedTemplate === esGreetingsTemplateName) {
                await updateAgent({
                    body: {
                        greetings: {
                            ...agent?.greetings,
                            es: editorContent,
                        },
                    },
                });
            } else if (selectedTemplate === summaryTemplate) {
                await updateAgent({ body: { summaryTemplate: editorContent } });
            } else if (selectedTemplate === inboxSubjectTemplate) {
                await updateAgent({ body: { inboxTitleTemplate: editorContent } });
            } else {
                const partial = templatePartials?.find(({ name }) => name === selectedTemplate);
                if (partial) {
                    await updateTemplatePartial({
                        pathname: `agents/${agentId}/template-partials/${partial.id}`,
                        body: {
                            name: partial.name as string,
                            description: partial.description as string,
                            content: editorContent,
                        },
                    });
                }
            }
            showAppNotification({
                title: 'Success',
                description: 'Your changes have been saved.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error: any) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
    };

    const handleDelete = async () => {
        const partialName = selectedTemplate;
        if (isTemplateInUse) {
            openInUseModal({
                title: '',
            });
            return;
        }
        const result = await confirmModal({
            header: '',
            question: <DeleteModal partialName={partialName} />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Delete',
            cancelButtonLabel: 'Cancel',
            variant: 'destructive',
        });
        if (!result) return;

        try {
            if (selectedTemplate === 'main') {
                throw new Error('Cannot delete main template');
            }
            const partial = templatePartials?.find(({ name }) => name === selectedTemplate);
            if (partial) {
                await deleteTemplatePartial({
                    pathname: `agents/${agentId}/template-partials/${partial.id}`,
                });
                setTemplate(mainTemplateName);
            }
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            if (message === 'Template partial is in use') {
                openInUseModal({
                    title: '',
                });
            } else {
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
        }
    };

    const handleImport = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        input.onchange = async (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (!file) return;
            const reader = new FileReader();
            reader.onload = async (event) => {
                const data = event.target?.result;
                if (!data) return;

                const result = await confirmModal({
                    header: '',
                    question: <ImportModal />,
                    borderRadius: 'semi-square',
                    okButtonLabel: 'Import',
                    cancelButtonLabel: 'Cancel',
                    variant: 'neutral',
                });
                if (!result) return;

                try {
                    showAppSpinner();
                    await importInstructions({
                        body: JSON.parse(data as string),
                    });
                    showAppNotification({
                        title: 'Success',
                        description: 'Your changes have been saved.',
                        icon: 'success',
                        timeout: 5000,
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            };
            reader.readAsText(file);
        };
        input.click();
    };

    const handleExport = async () => {
        if (!hiddenLinkRef.current) {
            return;
        }
        try {
            const res = await exportInstructions({});
            const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(res, null, 2))}`;
            hiddenLinkRef.current.href = dataStr;
            hiddenLinkRef.current.setAttribute('download', 'export.json');
            hiddenLinkRef.current.click();
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
    };

    const showRemovePartialButton = !noPartialNames.includes(selectedTemplate);

    const validationStatus = getValidationStatus(validationErrors, isLoadingInstructionsValidation);
    const isUpdating = isUpdatingAgent || isUpdatingPartial || isDeletingPartial;
    const historyItems = useMemo(
        () => {
            if (selectedTemplate === 'main') {
                return systemMessageVersions.map<HistoryItem>((v) => ({
                    ...v,
                    value: v.systemMessageTemplate,
                }));
            }

            if (selectedTemplate === 'summaryTemplate') {
                return summaryTemplateVersions.map<HistoryItem>((v) => ({
                    ...v,
                    value: v.summaryTemplate,
                }));
            }

            if (selectedTemplate === 'inboxTitleTemplate') {
                return inboxSubjectTemplateVersions.map<HistoryItem>((v) => ({
                    ...v,
                    value: v.inboxTitleTemplate,
                }));
            }

            return templatePartialVersions.map<HistoryItem>((v) => ({
                ...v,
                value: v.content,
            }));
        },
        [
            selectedTemplate,
            systemMessageVersions,
            summaryTemplateVersions,
            inboxSubjectTemplateVersions,
            templatePartialVersions,
        ],
    );

    const orderedPartials = useMemo(() => templatePartials?.sort((a, b) => a.name.localeCompare(b.name)) || [], [templatePartials]);

    const instructionTestingDisabled = useMemo(
        () => ![mainTemplateName, summaryTemplate, inboxSubjectTemplate].includes(selectedTemplate),
        [selectedTemplate],
    );

    return (
        <>
            <PageContainer>
                <Content>
                    <InstructionsContainer>
                        <Header>
                            <HeaderContent>
                                <Row>
                                    <Title>Instructions</Title>
                                    <Description>{description}</Description>
                                </Row>
                                <HeaderActions>
                                    <RenderIf isTrue={showRemovePartialButton}>
                                        <StyledDeleteButtonIcon
                                            icon={isUpdating ? <Spinner type="arc" size="small" /> : <Trash />}
                                            title="Delete partial"
                                            tooltip="Delete partial"
                                            borderRadius="semi-square"
                                            size="small"
                                            disabled={isUpdating}
                                            onClick={handleDelete}
                                        />
                                    </RenderIf>
                                    <Button
                                        borderRadius="semi-square"
                                        variant="outline-brand"
                                        onClick={handleImport}
                                        isLoading={isImporting}
                                    >
                                        <ImportIcon className="rainbow-m-right_x-small" />
                                        Import
                                    </Button>
                                    <Button
                                        borderRadius="semi-square"
                                        variant="outline-brand"
                                        onClick={handleExport}
                                        isLoading={isExporting}
                                    >
                                        <ExportIcon className="rainbow-m-right_x-small" />
                                        Export
                                    </Button>
                                    <HiddenA ref={hiddenLinkRef} />
                                </HeaderActions>
                            </HeaderContent>
                        </Header>
                        <InstructionsInputContainer>
                            <StyledSidebar>
                                <MenuItem
                                    label="Greeting English"
                                    isActive={selectedTemplate === 'greeting_en'}
                                    onClick={() => setTemplate(enGreetingsTemplateName)}
                                />
                                <MenuItem
                                    label="Greeting Spanish"
                                    isActive={selectedTemplate === 'greeting_es'}
                                    onClick={() => setTemplate(esGreetingsTemplateName)}
                                />
                                <MenuItem
                                    label="Main Instructions"
                                    isActive={selectedTemplate === 'main'}
                                    onClick={() => setTemplate(mainTemplateName)}
                                />
                                <MenuItem
                                    label="Call Summary"
                                    isActive={selectedTemplate === 'summaryTemplate'}
                                    onClick={() => setTemplate(summaryTemplate)}
                                />
                                <MenuItem
                                    label="Inbox Subject"
                                    isActive={selectedTemplate === 'inboxTitleTemplate'}
                                    onClick={() => setTemplate(inboxSubjectTemplate)}
                                />
                                <SidebarLabel>Partials</SidebarLabel>
                                {orderedPartials?.map(({ id, name }) => (
                                    <MenuItem
                                        key={id}
                                        label={name}
                                        icon={<Handlebar />}
                                        isActive={selectedTemplate === name}
                                        onClick={() => setTemplate(name)}
                                    />
                                ))}
                                <Button variant="neutral" label="Add Partial" borderRadius="semi-square" onClick={createPartial} />
                            </StyledSidebar>
                            <InstructionsInputContent>
                                <InstructionsInput
                                    value={editorContent}
                                    onChange={(value) => setEditorContent(value as string)}
                                    variables={generalVariables}
                                    onValidate={async (currentValue: string) => {
                                        try {
                                            await validateInstructions({
                                                body: {
                                                    template: currentValue,
                                                    partialName: selectedTemplate !== 'main' ? `${selectedTemplate}` : undefined,
                                                    agentId,
                                                },
                                            });
                                            setValidationErrors([]);
                                            return undefined;
                                        } catch (errorPayload: any) {
                                            if (errorPayload?.errors) {
                                                const currentValidationErrors: IntstructinoValidationError[] = errorPayload.errors;
                                                setValidationErrors(currentValidationErrors);
                                                return currentValidationErrors;
                                            }
                                            return undefined;
                                        }
                                    }}
                                />
                                <FormFooter
                                    formId="instructions-form"
                                    submitLoading={isUpdating}
                                    historyOpen={historyOpen}
                                    helpOpen={helpOpen}
                                    onOpenHelp={() => {
                                        setHistoryOpen(false);
                                        setHelpOpen(true);
                                    }}
                                    onOpenHistory={() => {
                                        setHelpOpen(false);
                                        setHistoryOpen(true);
                                    }}
                                    onsubmit={() => handleSaveChanges()}
                                    validationStatus={validationStatus}
                                    validationErrors={validationErrors.length}
                                    disableTesting={instructionTestingDisabled}
                                    testOpen={connectedTestingModalProps.isOpen}
                                    onOpenTest={openInstructionTesting}
                                />
                            </InstructionsInputContent>
                        </InstructionsInputContainer>
                    </InstructionsContainer>
                    <RenderIf isTrue={historyOpen || helpOpen}>
                        <HelperContainer>
                            <HeaderWithActions>
                                <Title>{historyOpen ? 'History' : 'Help'}</Title>
                                <StyledButtonIcon
                                    icon={<Close />}
                                    onClick={() => {
                                        setHistoryOpen(false);
                                        setHelpOpen(false);
                                    }}
                                    size="small"
                                    className="rainbow-m-left_small"
                                    borderRadius="semi-square"
                                    variant="base"
                                />
                            </HeaderWithActions>
                            <RenderIf isTrue={historyOpen}>
                                <History
                                    items={historyItems}
                                    selectedItem={selectedVersion || historyItems[0]}
                                    onSelectedVersion={setSelectedVersion}
                                />
                            </RenderIf>
                            <RenderIf isTrue={helpOpen}>
                                <GeneralVariables />
                            </RenderIf>
                        </HelperContainer>
                    </RenderIf>
                </Content>
                <Footer />
            </PageContainer>
            <UniversalFormModal fields={Fields} borderRadius="semi-square" submitButtonLabel="Create Partial" {...connectedModalProps} />
            <Modal {...inUseConnectedModalProps} onRequestClose={closeInUseModal} borderRadius="semi-square">
                <ModalBody
                    title={`The partial "${selectedTemplate}" is in use.`}
                    description={`The partial "${selectedTemplate}" is currently active in the main instructions. Please remove it from there before attempting to delete.`}
                    icon={<AlertTriangle />}
                    variant="warning"
                />
            </Modal>
            <InstructionTestingModal
                {...connectedTestingModalProps}
                component={contentMapper[selectedTemplate]}
                title={titleMapper[selectedTemplate]}
                agentId={agentId}
                template={editorContent}
            />
        </>
    );
};

export default Instructions;
