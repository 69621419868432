import { useState } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import SearchIcon from 'components/icons/search';
import { ADD_EDIT_CUSTOMER_MODAL } from '../../../constants';
import { StyledCard } from '../styled';
import CustomersList from './customersList';
import { SearchContainer, SearchInput } from './styled';

const CustomersListPage = () => {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [openDrawer] = useOpenModal(ADD_EDIT_CUSTOMER_MODAL);

    return (
        <StyledCard>
            <SearchContainer>
                <SearchInput
                    type="search"
                    placeholder="Search customer..."
                    variant="bare"
                    icon={<SearchIcon />}
                    borderRadius="semi-square"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <Button
                    label="Add Customer"
                    variant="brand"
                    size="medium"
                    borderRadius="semi-square"
                    onClick={() => openDrawer({
                        header: 'Add a Customer',
                        onCompleted: (
                            customer: Record<string, unknown>,
                        ) => navigate(customer.id as string),
                    })}
                />
            </SearchContainer>
            <CustomersList search={search} />
        </StyledCard>
    );
};

export default CustomersListPage;
