import { IconProps } from 'types';

const MessageCircleIcon = ({ className, title }: IconProps) => (
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        className={className}
    >
        <title>{title}</title>
        <path d="M2 13.3331L2.86667 10.7331C1.31741 8.44175 1.91592 5.48489 4.26651 3.81726C6.61711 2.14964 9.99349 2.28654 12.1635 4.13745C14.3336 5.98837 14.6267 8.98139 12.8492 11.1378C11.0718 13.2943 7.7728 13.9478 5.13333 12.6664L2 13.3331" stroke="#6F6B7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />

    </svg>
);
export default MessageCircleIcon;
