import { ReactNode } from 'react';
import { HelpText, LoadingShape, RenderIf } from 'react-rainbow-components';
import {
    SummaryItemContainer, InfoContainer, IconContainer, Value, Label, Footer,
} from './styled';

interface SummaryBillingItemProps {
    value: string;
    label: string;
    icon: ReactNode;
    isLoading?: boolean;
    footer?: ReactNode | string;
    tooltip?: string;
}

const SummaryBillingItem = ({
    value, label, icon, isLoading, footer, tooltip,
}: SummaryBillingItemProps) => (
    <SummaryItemContainer>
        <RenderIf isTrue={isLoading}>
            <LoadingShape shape="square" />
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
            <InfoContainer>
                <Label>{label}</Label>

                <Value>
                    {value}
                    <RenderIf isTrue={!!tooltip}>
                        <>
                            {' '}
                            <HelpText text={tooltip} iconSize="small" />
                        </>
                    </RenderIf>
                </Value>

                <Footer>{footer}</Footer>
            </InfoContainer>
            <IconContainer className="icon">{icon}</IconContainer>
        </RenderIf>
    </SummaryItemContainer>
);

export default SummaryBillingItem;
