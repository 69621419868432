import { CellWrapper } from '../styled';

interface Props {
    value?: Record<string, any>;
}

const LineType = ({ value }: Props) => {
    const { carrier } = value || {};
    const { type = 'Unknown' } = carrier || {};

    return (
        <CellWrapper>
            {type}
        </CellWrapper>
    );
};

export default LineType;
