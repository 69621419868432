import { GOOGLE_MAPS_API_KEY } from '../constants';

const resolveTimezone = async (lat: number, lng: number) => {
    try {
        const timestamp = Math.floor(Date.now() / 1000);
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${GOOGLE_MAPS_API_KEY}`,
        );
        if (response.ok) {
            const { timeZoneId } = await response.json();
            return timeZoneId;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }

    return null;
};

export default resolveTimezone;
