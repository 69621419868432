import { Fragment, ReactNode, useMemo } from 'react';
import { DateTime } from 'luxon';
import { RenderIf } from 'react-rainbow-components';
import MessageLoadingShape from 'components/MessageLoadingShape';
import { EntityGet } from 'data/firestore/types';
import { Chat } from 'data/firestore/agent/customer/chat/types';
import MessagesList from './messages';
import DateSplitter from './splitter';
import { DisplayContext, ChatMessage } from './types';
import {
    Container, EmptyMessage, EmptyMessageContainer,
} from './styled';

function formatDate(dateStr?: string, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    const today = DateTime.now().setZone(timezone, { keepLocalTime: true });
    const yesterday = today.minus({ day: 1 });
    const date = DateTime.fromISO(dateStr as string).setZone(timezone);

    if (date.hasSame(today, 'day')) return 'Today';

    if (date.hasSame(yesterday, 'day')) return 'Yesterday';

    if (date.hasSame(today, 'year')) return date.toFormat('cccc, MMMM d');

    return date.toFormat('cccc, MMMM d, kkkk');
}

interface CustomerActivityProps {
    messages?: EntityGet<Chat>[],
    isLoading?: boolean;
    emptyMessage?: ReactNode | string;
    agentId?: string;
    context?: DisplayContext;
}

const CustomerActivity = ({
    messages = [],
    isLoading = false,
    emptyMessage = 'There is no activity',
    agentId = '',
    context = 'customer',
}: CustomerActivityProps) => {
    const messagesList = useMemo(
        () => {
            if (messages.length > 0) {
                const groupedMessages = messages
                    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
                    .reduce(
                        (
                            acc: { [key: string]: Array<ChatMessage> },
                            message: EntityGet<Chat>,
                        ) => {
                            const date = formatDate(message.createdAt.toISOString());
                            if (!Object.keys(acc).includes(date)) {
                                acc[date] = [];
                            }
                            acc[date].push({
                                ...message,
                                agentId,
                                context,
                            });
                            return acc;
                        },
                        {},
                    );
                return Object.keys(groupedMessages).map((date) => (
                    <Fragment key={`activity-${date}`}>
                        <DateSplitter date={date} />
                        <MessagesList
                            messages={groupedMessages[date]}
                            key={`messages-${date}`}
                        />
                    </Fragment>
                ));
            }

            if (typeof emptyMessage === 'string') {
                return (
                    <EmptyMessageContainer>
                        <EmptyMessage>{emptyMessage}</EmptyMessage>
                    </EmptyMessageContainer>
                );
            }

            return emptyMessage;
        },
        [messages, emptyMessage, agentId, context],
    );

    return (
        <Container>
            <RenderIf isTrue={isLoading}>
                <MessageLoadingShape />
                <MessageLoadingShape />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                {messagesList}
            </RenderIf>
        </Container>
    );
};

export default CustomerActivity;
