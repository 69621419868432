/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'ifel',
    body: '{{#if ${1:condition}}}\n\t${2}\n{{else}}\n\t${0}\n{{/if}}',
    documentation: 'if else statement',
};

export default snippet;
