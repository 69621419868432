import styled from 'styled-components';
import Input from 'components/Input';
import Card from 'components/Card';
import Button from 'components/Button';
import { GREY_100, GREY_800 } from '../../constants';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    box-sizing: border-box;
    position: relative;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    align-self: stretch;
    gap: 1.5rem;
    flex: 1;
`;

export const StyledCard = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1.5rem;
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.25rem;
`;

export const HeaderTitle = styled.h1`font-size: 2.125rem;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.75rem;
    color: ${GREY_800};
`;

export const HeaderSubtitle = styled.p`
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 6rem auto 1rem auto;
    padding: 0 1rem;
    gap: 0.5rem;
    width: 100%;
    max-width: 35rem;
`;

export const EmptyMessageEmoji = styled.h2`
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 150%;
`;

export const EmptyMessageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};
`;

export const EmptyMessageDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    margin-top: 0;
    width: 100%;
`;

export const ModalTitle = styled.h1`
    font-size: 1.625rem;
    margin-bottom: 1rem;
    font-weight: 500;
    line-height: 2rem;
    color: ${GREY_800};
`;

export const ModalDescription = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
    padding-bottom: 1rem;
`;

export const StyledInput = styled(Input)`
    margin-bottom: 12px;
`;

export const KeyField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${GREY_800};
    background-color: ${GREY_100};
    border-radius: 0.75rem;
    padding: 0.25rem 0.5rem;
`;

export const StyledButton = styled(Button)`
    align-self: flex-end;
    margin-top: auto;
    margin-left: auto;
    margin-right: 0;
    margin-top: 1.5rem;
`;
