import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BadgeOverlay } from 'react-rainbow-components';
import UserAvatar from 'components/UserAvatar';
import {
    BACKGROUND, GREY_100, GREY_500, GREY_900, PURPLE_500,
} from '../../../../constants';

export const CustomerInfoContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const CustomerLoading = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    > div:first-child {
        margin: 0;
    }

    > div:last-child {
        margin-top: 0.75rem;
    }
`;

export const CustomerName = styled.h2`
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; 
    color: ${GREY_900};
`;

export const CustomerAvatar = styled(UserAvatar)`
    width: 3rem;
    height: 3rem;
`;

export const CustomerBubble = styled.div`
    border-radius: 7.75rem;
    background: ${BACKGROUND};
    display: inline-flex;
    padding: 0rem 0.5rem;
    align-items: center;
    gap: 1rem;
    position: relative;

    ::before {
        position: absolute;
        top: 50%;
        left: -10px;
        content: '';
        width: 0;
        height: 0;
        margin: -6px 0 0;
        border-right: solid 14px ${BACKGROUND};
        border-bottom: solid 6px transparent;
        border-top: solid 6px transparent;
    }
`;

export const CustomerAction = styled(Link)`
    text-decoration: none;
    color: ${GREY_500};
    padding: 12px;
    border-radius: 100%;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
        color: ${PURPLE_500};
        background: rgb(227, 224, 252);
    }
`;

export const CustomerSmsBadgeOverlay = styled(BadgeOverlay)`
    & > span {
        width: 0.625rem;
        height: 0.625rem;
        box-sizing: border-box;
        border: 2px solid ${GREY_100};
        right: 0.325rem;
        border-radius: 100%;
    }
`;

export const CustomerButton = styled.button`
    border: none;
    background: transparent;
    display: flex;
    border-radius: 5rem;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem 0 0;

    :hover {
        background: ${BACKGROUND};
        > h2 {
            text-decoration: underline;
            color: ${GREY_900};
        }
    }
`;
