import React from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { StyledLink, MenuItemContainer } from './styled';

interface ItemProps {
    to: string;
    icon: React.ReactNode;
    label: string;
}

const MenuItem = (props: ItemProps) => {
    const {
        to, icon, label,
    } = props;
    const { agentId = '' } = useParams();
    const match = useMatch(`/${agentId}/calendar/settings/${to}`);
    return (
        <MenuItemContainer className="rainbow-p-left_x-small rainbow-p-right_x-small">
            <StyledLink to={to} isActive={match !== null}>
                {icon}
                <span className="rainbow-m-left_medium">{label}</span>
            </StyledLink>
        </MenuItemContainer>
    );
};

export default MenuItem;
