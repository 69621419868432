import { IconProps } from 'types';

const AdjustmentsHorizontal = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
    >
        <title>{title}</title>
        <circle
            cx={10.5}
            cy={4.5}
            r={1.5}
            stroke="#7367F0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        />
        <path
            stroke="#7367F0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 4.5h6M12 4.5h3"
        />
        <circle
            cx={6}
            cy={9}
            r={1.5}
            stroke="#7367F0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        />
        <path
            stroke="#7367F0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 9h1.5M7.5 9H15"
        />
        <circle
            cx={12.75}
            cy={13.5}
            r={1.5}
            stroke="#7367F0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        />
        <path
            stroke="#7367F0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 13.5h8.25M14.25 13.5H15"
        />
    </svg>
);

export default AdjustmentsHorizontal;
