import { IconProps } from 'types';

const Handlebar = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={16}
        height={5}
        viewBox="0 0 16 5"
        fill="none"
    >
        <title>{title}</title>
        <g clipPath="url(#clip0_2830_46695)">
            <path d="M6.022 0.500025C6.54305 0.497771 7.07739 0.647274 7.48237 0.984132C7.68989 1.15211 7.85752 1.36285 7.99988 1.58724C8.25603 1.17319 8.63018 0.821368 9.09157 0.651783C9.65381 0.440716 10.2893 0.455294 10.8506 0.662175C11.3409 0.841157 11.7695 1.15307 12.1583 1.49611C12.4624 1.76458 12.7438 2.05835 13.0571 2.31678C13.2221 2.45068 13.3994 2.57615 13.6024 2.64553C13.8722 2.73763 14.1913 2.65074 14.3842 2.44289C14.5628 2.24837 14.5518 1.91212 14.355 1.73446C14.2298 1.62647 14.0158 1.64819 13.9273 1.79213C13.8498 1.9063 13.8933 2.04863 13.9539 2.1592C13.7927 2.04152 13.6313 1.87743 13.6319 1.66344C13.5985 1.41604 13.7821 1.1978 14.0019 1.11124C14.4309 0.939694 14.9688 1.08759 15.2395 1.46531C15.4999 1.81777 15.5206 2.2811 15.4752 2.70098C15.4204 3.09981 15.1974 3.47206 14.8666 3.70291C14.4023 4.0343 13.8138 4.13705 13.2539 4.12994C12.7156 4.11533 12.1829 3.99375 11.6842 3.7927C10.8503 3.45745 10.0698 2.9941 9.20864 2.7263C8.92298 2.61541 8.61918 2.55542 8.31373 2.53692C8.07055 2.53531 7.84001 2.52395 7.61044 2.53953C7.33029 2.56646 7.05178 2.62384 6.78948 2.72659C5.91371 2.99899 5.12158 3.47399 4.27175 3.80991C3.55418 4.08907 2.75916 4.21878 1.99753 4.06378C1.57147 3.97754 1.14735 3.78916 0.859758 3.45356C0.61365 3.16822 0.495645 2.78628 0.507267 2.4121C0.493655 2.05899 0.571144 1.68384 0.804605 1.40887C1.02182 1.14821 1.3717 1.01592 1.70763 1.04511C1.92387 1.05515 2.14308 1.1495 2.27666 1.32363C2.38172 1.46142 2.38819 1.65015 2.34214 1.81127C2.28605 1.95621 2.1703 2.06939 2.04451 2.15627C2.10871 2.04796 2.14762 1.90498 2.07174 1.79181C1.97835 1.64105 1.74846 1.62582 1.62689 1.74965C1.47709 1.90369 1.44562 2.15531 1.54615 2.34401C1.65508 2.54181 1.88046 2.65463 2.09864 2.67764C2.42874 2.70619 2.71504 2.50159 2.95725 2.30317C3.45852 1.87158 3.90984 1.37778 4.46593 1.01267C4.92442 0.705296 5.46459 0.497449 6.02197 0.500054L6.022 0.500025Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_2830_46695">
                <rect width="15" height="3.63" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
        </defs>
    </svg>
);
export default Handlebar;
