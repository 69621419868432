import { IconProps } from 'types';

const Pencil = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 12.75V3.75C15 2.90925 14.3408 2.25 13.5 2.25C12.6592 2.25 12 2.90925 12 3.75V12.75L13.5 14.25L15 12.75Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 5.25H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 14.25H3.75C2.92157 14.25 2.25 13.5784 2.25 12.75C2.25 11.9216 2.92157 11.25 3.75 11.25H6.75C7.57843 11.25 8.25 10.5784 8.25 9.75C8.25 8.92157 7.57843 8.25 6.75 8.25H4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Pencil;
