import { InstructionsValidationError } from '../types';

const getValidationStatus = (errors: InstructionsValidationError[], isLoading: boolean) => {
    if (isLoading) {
        return 'loading';
    }
    if (errors.length > 0) {
        return 'invalid';
    }
    return 'valid';
};

export default getValidationStatus;
