import { CellWrapper, PercentLabel, ValueLabel } from './styled';

interface Props {
    row?: Record<string, unknown>;
    value?: number;
}

const ValueColumn = ({ row, value }: Props) => (
    <CellWrapper align="flex-end">
        <ValueLabel>{`${value} calls`}</ValueLabel>
        <PercentLabel>{`${row?.percent}%`}</PercentLabel>
    </CellWrapper>
);

export default ValueColumn;
