import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import {
    EmptyMessageDescription, EmptyMessageTitle, EmptyMessageEmoji, EmptyMessageContainer,
} from './styled';

interface Props {
    searchQuery?: string;
    hadTasks?: boolean;
}

const EmptyTasks = ({ searchQuery, hadTasks }: Props) => {
    const { displayName } = useCurrentUser() || {};

    if (searchQuery) {
        return (
            <EmptyMessageContainer>
                <EmptyMessageEmoji>
                    🔍
                </EmptyMessageEmoji>
                <EmptyMessageTitle>
                    No Matching Results
                </EmptyMessageTitle>
                <EmptyMessageDescription>
                    We couldn&apos;t find anything matching your search for &quot;
                    <strong>{searchQuery}</strong>
                    &quot;
                    <br />
                    Try using different keywords or check your spelling.
                </EmptyMessageDescription>
            </EmptyMessageContainer>
        );
    }

    if (hadTasks) {
        return (
            <EmptyMessageContainer>
                <EmptyMessageEmoji>
                    🎉
                </EmptyMessageEmoji>
                <EmptyMessageTitle>
                    Great job
                    {' '}
                    {displayName}
                    !
                </EmptyMessageTitle>
                <EmptyMessageDescription>
                    All your tasks have been resolved for now.
                </EmptyMessageDescription>
            </EmptyMessageContainer>
        );
    }

    return (
        <EmptyMessageContainer>
            <EmptyMessageEmoji>
                📭
            </EmptyMessageEmoji>
            <EmptyMessageTitle>
                No Tasks Open Yet,
                {' '}
                {displayName}
                !
            </EmptyMessageTitle>
            <EmptyMessageDescription>
                Don&apos;t worry, tasks will appear here as soon as they&apos;re assigned to you.
            </EmptyMessageDescription>
        </EmptyMessageContainer>
    );
};

export default EmptyTasks;
