import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { limit, query, where } from 'firebase/firestore';
import { EntityGet } from 'data/firestore/types';
import { Inbox } from 'data/firestore/inbox/types';
import { Topic } from 'data/firestore/agent/topic/types';
import useTopics from 'data/firestore/agent/topic/useCollection';
import useOpenTasks from 'hooks/useOpenTasks';
import filterTasks from 'pages/Tasks/helpers/filterTasks';

const defaultUnknownTopic = {
    id: 'unknown',
    name: 'Others',
    isUnknown: true,
};

const useTaskTopics = ({ searchQuery }: { searchQuery?: string }) => {
    const { agentId = '' } = useParams();
    const { tasks, isLoading } = useOpenTasks();

    const { data: unknownTopics } = useTopics(agentId, {
        listQueryFn: (ref) => query(
            ref,
            where('isUnknown', '==', true),
            limit(1),
        ),
    });

    const unknownTopic = unknownTopics && unknownTopics.length > 0
        ? unknownTopics[0]
        : defaultUnknownTopic;

    // Extract unique topics from tasks or assign unknown if none
    const topics = useMemo(() => {
        const allTopics = tasks.reduce((acc, task) => {
            const taskTopics = task.topics || [];
            taskTopics.forEach((topic) => {
                if (!acc.find((t) => t.id === topic.id)) {
                    acc.push(topic);
                }
            });

            return acc;
        }, [] as Topic[]);

        const unknownTopicIndex = allTopics.findIndex((t) => t.isUnknown);
        if (unknownTopicIndex === -1) {
            allTopics.push(unknownTopic);
        }

        return allTopics;
    }, [tasks, unknownTopic]);

    // Map the tasks to an object where the key is the topic id
    const mappedTasks = useMemo(() => filterTasks({
        tasks,
        searchQuery,
    }).reduce(
        (acc, task) => {
            const taskTopics = task.topics;
            // If the task has no topics, add it to the unknown topic
            if (!taskTopics || taskTopics.length === 0) {
                if (unknownTopic) {
                    if (!acc[unknownTopic.id]) {
                        acc[unknownTopic.id] = [];
                    }
                    acc[unknownTopic.id].push(task);
                }
            } else {
                taskTopics.forEach((topic) => {
                    if (!acc[topic.id]) {
                        acc[topic.id] = [];
                    }

                    acc[topic.id].push(task);
                });
            }

            return acc;
        },
        {} as Record<string, EntityGet<Inbox>[]>,
    ), [searchQuery, tasks, unknownTopic]);

    // Create a list of topics with the count of tasks. Only topics with tasks are included.
    const topicsWithTasks = topics.filter((topic) => mappedTasks[topic.id]).map((topic) => ({
        ...topic,
        count: mappedTasks[topic.id].length,
    }));

    return useMemo(() => ({
        topicsWithTasks,
        mappedTasks,
        isLoading,
    }), [isLoading, mappedTasks, topicsWithTasks]);
};

export default useTaskTopics;
