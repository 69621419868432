const getNotificationRuleDescription = (when: number, relativeTo: 'creation-time' | 'event-time') => {
    if (relativeTo === 'event-time') {
        if (when === 2880) return '48 hours before the event start';
        if (when === 4320) return '72 hours before the event start';
        return '24 hours before the event start';
    }

    return 'Immediately after the event is booked';
};

export default getNotificationRuleDescription;
