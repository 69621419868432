import {
    MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    ref, listAll, getMetadata, getDownloadURL,
} from 'firebase/storage';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import DateTimeColumn from 'components/DateTimeColumn';
import getSign from 'helpers/getSign';
import { storage } from '../../../../firebase';
import FilePrview from './columns/fileUrl';
import UploadedBy from './columns/uploadedBy';
import {
    Container, Description, Header, Title, StyledTable, Content,
} from './styled';
import Actions from './columns/actions';

const useFiles = () => {
    const { agentId = '', customerId = '' } = useParams();
    const [files, setFiles] = useState<Record<string, unknown>[]>([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const fileRef = ref(storage, `agents/${agentId}/customers/${customerId}`);
            const { items } = await listAll(fileRef);
            const filesData = await Promise.all(items.map(async (item) => {
                const metadata = await getMetadata(item);
                const url = await getDownloadURL(item);
                return {
                    ...metadata,
                    url,
                };
            }));
            setLoading(false);
            setFiles(filesData);
        })();
    }, [agentId, customerId]);

    return useMemo(() => ({ isLoading, files }), [isLoading, files]);
};

const CustomerFiles = () => {
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
    const { files, isLoading } = useFiles();

    const sortedFiles = useMemo(
        () => {
            const newData = [...files];
            const reverse = sortDirection === 'asc' ? 1 : -1;
            newData.sort(
                (fileA, fileB) => {
                    const timeA = new Date(fileA.timeCreated as string).getTime();
                    const timeB = new Date(fileB.timeCreated as string).getTime();
                    return reverse * getSign(timeA - timeB);
                },
            );

            return newData;
        },
        [files, sortDirection],
    );

    const handleOnSort = useCallback(
        (
            event: MouseEvent<HTMLElement>,
            field: string,
            nextSortDirection: 'asc' | 'desc',
        ) => {
            setSortDirection(nextSortDirection);
        },
        [setSortDirection],
    );

    return (
        <Container>
            <Header>
                <Title>Customer Files</Title>
                <Description>
                    Store and organize the customer&apos;s documents
                    for quick reference and retrieval.
                </Description>
            </Header>
            <Content>
                <StyledTable
                    data={sortedFiles}
                    keyField="name"
                    isLoading={isLoading}
                    onSort={handleOnSort}
                    sortedBy="timeCreated"
                    sortDirection={sortDirection}
                >
                    <Column
                        header="Files"
                        field="url"
                        component={FilePrview}
                    />
                    <Column
                        header="Uploaded By"
                        field="customMetadata"
                        component={UploadedBy}
                    />
                    <Column
                        header="Uploaded At"
                        field="timeCreated"
                        component={DateTimeColumn}
                        sortable
                    />
                    <Column header="Actions" headerAlignment="right" component={Actions} />
                </StyledTable>
            </Content>
        </Container>
    );
};

export default CustomerFiles;
