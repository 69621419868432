import { MouseEvent, useCallback, useRef } from 'react';
import { useLocale } from 'react-rainbow-components';
import Gradient from 'components/Gradient';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { CalendarEventClick } from 'types';
import Calendars from './calendars';
import Header from './header';
import Hours from './hours';
import { Container, Content } from './styled';
import getMinuteFromPos from './calendars/helpers/getMinuteFromPos';

interface Params {
    className?: string;
    locale?: string;
    calendars?: any[],
    events?: any[],
    date?: Date;
    timeZone?: string;
    selectedEvent?: string | null;
    onEventClick?: CalendarEventClick;
    onAvailableSpaceClick?: (calendar: Calendar, minute: number) => void;
    hideClockLine?: boolean;
}

const CalendarDayView = ({
    className,
    locale: localLocale,
    calendars = [],
    events = [],
    selectedEvent,
    onEventClick = () => {},
    onAvailableSpaceClick = () => {},
    hideClockLine = false,
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    date,
}: Params) => {
    const locale = useLocale(localLocale);
    const hoursRef = useRef<HTMLDivElement>(null);
    const calendarsRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(
        (event: any) => {
            if (hoursRef.current) {
                hoursRef.current.scrollTop = event.target.scrollTop;
            }
            if (calendarsRef.current) {
                calendarsRef.current.scrollLeft = event.target.scrollLeft;
            }
        },
        [],
    );

    const handleAvailableSpaceClick = useCallback(
        (calendar: Calendar, e: MouseEvent<HTMLDivElement>) => {
            if (hoursRef.current) {
                const { clientY } = e;
                const { top } = hoursRef.current.getBoundingClientRect();
                const { scrollTop } = hoursRef.current;
                const pos = clientY - top + scrollTop;
                const minute = getMinuteFromPos(pos);
                onAvailableSpaceClick(calendar, minute);
            }
        },
        [onAvailableSpaceClick],
    );

    return (
        <Container className={className}>
            <Header
                calendars={calendars}
                ref={calendarsRef}
            />
            <Content>
                <Hours
                    ref={hoursRef}
                    locale={locale}
                />
                <Calendars
                    date={date}
                    calendars={calendars}
                    events={events}
                    selectedEvent={selectedEvent}
                    onScroll={handleScroll}
                    onEventClick={onEventClick}
                    onAvailableSpaceClick={handleAvailableSpaceClick}
                    hideClockLine={hideClockLine}
                    timeZone={timeZone}
                />
            </Content>
            <Gradient />
        </Container>
    );
};

export default CalendarDayView;
