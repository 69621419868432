import { useState, useEffect, useContext } from 'react';
import { SearchIndex } from 'algoliasearch/lite';
import context, { Context } from 'context';
import useAlgoliaClient from './useClient';

const useAlgoliaIndex = (indexName: keyof Context['algoliaSecureKeys']) => {
    const [index, setIndex] = useState<SearchIndex>();
    const { algoliaSecureKeys } = useContext(context);

    const client = useAlgoliaClient(algoliaSecureKeys[indexName]);

    useEffect(() => {
        if (client) {
            setIndex(client.initIndex(indexName));
        }
    }, [client, indexName]);

    return index;
};

export default useAlgoliaIndex;
