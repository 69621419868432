import { useCallback, useMemo } from 'react';
import ListTile from 'components/ListTile';
import UserAvatar from 'components/UserAvatar';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { EntityGet } from 'data/firestore/types';
import useCustomerPanel from 'hooks/useCustomerPanel';
import { CustomerNotificationBadge, UserCellWrapper } from '../../styled';

interface Props {
    row?: EntityGet<Opportunity>;
}

const UserColumn = ({ row }: Props) => {
    const {
        id = '',
        firstName = '',
        lastName = '',
        alias = '',
        displayName: displayNameProp = '',
        phoneNumber,
        photoURL,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hasNewMessage = false,
    } = row?.customer || {};
    const { openCustomerPanel } = useCustomerPanel();

    const displayName = useMemo(
        () => getDisplayName({
            firstName,
            lastName,
            alias,
            displayName: displayNameProp,
        }),
        [firstName, lastName, alias, displayNameProp],
    );

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const handleCustomerPanelClick = useCallback(
        () => openCustomerPanel({ data: row?.customer }),
        [openCustomerPanel, row?.customer],
    );

    return (
        <UserCellWrapper>
            <ListTile
                icon={(
                    <CustomerNotificationBadge
                        overlap="circle"
                        position="bottom-right"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        isHidden={!hasNewMessage}
                    >
                        <UserAvatar
                            initials={initials}
                            value={id}
                            src={photoURL}
                        />
                    </CustomerNotificationBadge>
                )}
                onClick={handleCustomerPanelClick}
                title={displayName}
                subtitle={formatIntlPhoneNumber(phoneNumber)}
            />
        </UserCellWrapper>
    );
};

export default UserColumn;
