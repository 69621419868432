import {
    ReactNode, useCallback, useMemo, useRef, useState,
} from 'react';
import { useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import { Filter } from '@rainbow-modules/icons';
import { Provider } from 'react-rainbow-components/components/PrimitiveMenu/context';
import ButtonIcon from 'components/ButtonIcon';
import Check from 'components/icons/check';
import Close from 'components/icons/close';
import { InternalOverlay } from 'react-rainbow-components';
import { TaskStatus } from 'types';
import positionResolver from './resolver';
import {
    DropDown, StyledMenuItem, Header,
} from './styled';

interface Status {
    name: string;
    label?: string;
}

interface TaskStatusPickerProps {
    taskStatuses?: Status[];
    value?: TaskStatus;
    onChange?: (newValue: string) => void;
    buttonIcon?: ReactNode;
}

const TaskStatusPicker = ({
    buttonIcon,
    taskStatuses = [],
    value: valueInProps,
    onChange = () => {},
}: TaskStatusPickerProps) => {
    const buttonRef = useRef();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    useWindowResize(() => setOpen(false), isOpen);
    useOutsideClick(dropdownRef, () => setOpen(false), isOpen);

    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

    const close = useCallback(() => setOpen(false), []);

    const changeStatus = useCallback(
        (newStatus: string) => onChange(newStatus),
        [onChange],
    );

    const options = useMemo(
        () => taskStatuses.map(
            (status) => {
                const icon = (
                    valueInProps === status.name
                        ? <Check />
                        : undefined
                );
                return (
                    <StyledMenuItem
                        key={status.name}
                        icon={icon}
                        iconPosition="right"
                        label={status.label || status.name}
                        onClick={() => changeStatus(status.name)}
                    />
                );
            },
        ),
        [changeStatus, taskStatuses, valueInProps],
    );

    const contextValue = useMemo(() => ({
        privateOnClose: () => {},
        privateOnHover: (event: any, childRef: any) => childRef.focus(),
        privateRegisterChild: () => {},
        privateUnregisterChild: () => {},
    }), []);

    return (
        <>
            <ButtonIcon
                ref={buttonRef}
                variant="base"
                onClick={toggle}
                icon={buttonIcon || <Filter />}
            />
            <InternalOverlay
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => buttonRef.current.htmlElementRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <Provider value={contextValue}>
                        <Header>
                            Show Tasks
                            <ButtonIcon
                                icon={<Close />}
                                onClick={close}
                            />
                        </Header>
                        {options}
                    </Provider>
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default TaskStatusPicker;
