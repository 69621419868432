import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import { GREY_800 } from '../../constants';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 1.5rem;
    width: 380px;
    min-width: 380px;
    height: calc(100vh - 3rem);
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    padding: 0;
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);
    z-index: 5001;
    overflow: hidden;
`;

export const CloseButton = styled(ButtonIcon)`
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: ${GREY_800};

    & svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    align-self: stretch;
`;
