import { Spinner } from 'react-rainbow-components';
import Badge from 'components/Badge';
import styled from 'styled-components';
import { color } from 'theme';

export const StyledStatusBadge = styled(Badge)`
    padding: 0.25rem 0.35rem;
    line-height: 1.35 !important;
    height: fit-content !important;
    text-transform: unset;
    font-weight: 400;
    letter-spacing: 0;

    span {
        display: flex;
        align-items: center;
        line-height: 100%;
    }
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    z-index: unset;
    transform: unset;
    left: unset;
    top: unset;
    width: 0.75rem;
    height: 0.75rem;
    padding: 1px;
    margin-right: 0.25rem;
    
    & .path {
        stroke: ${color('warning.dark')};
    }
`;
