import styled from 'styled-components';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 20px;
`;

export const BookingsCellWrapper = styled(CellWrapper)`
    gap: 0.5rem;
`;

export const BookingsColumnValue = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
`;
