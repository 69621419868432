import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import WaveSurfer from 'wavesurfer.js';
import Pause from 'components/icons/pause';
import Play from 'components/icons/play';
import LoadingShape from 'components/LoadingShape';
import useConversationAudio from 'hooks/useConversationAudio';
import { GREY_300, PURPLE_500 } from '../../../constants';
import {
    Container, PlayButton, TimeLineContainer, Wave, WaveformGlobalStyle,
} from './styled';

const useWavesurfer = (
    containerRef: React.RefObject<HTMLDivElement>,
    url?: string,
) => {
    const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

    useEffect(() => {
        if (containerRef.current && url) {
            const ws = WaveSurfer.create({
                url,
                height: 48,
                barWidth: 2,
                barGap: 1,
                barRadius: 2,
                waveColor: GREY_300,
                progressColor: PURPLE_500,
                fillParent: true,
                cursorWidth: 2,
                container: containerRef.current,
            });

            setWavesurfer(ws);

            return () => ws.destroy();
        }
        return undefined;
    }, [url, containerRef]);

    return wavesurfer;
};

interface RecordedAudioPlayerProps {
    recordingId?: string;
}

const RecordedAudioPlayer = ({
    recordingId = '',
}:RecordedAudioPlayerProps) => {
    const waveRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const { isLoading, recordingUrl } = useConversationAudio(recordingId);

    const wavesurfer = useWavesurfer(
        waveRef,
        recordingUrl,
    );

    const onPlayClick = useCallback(() => {
        if (wavesurfer) {
            if (wavesurfer.isPlaying()) {
                wavesurfer.pause();
            } else {
                wavesurfer.play();
            }
        }
    }, [wavesurfer]);

    useEffect(() => {
        if (wavesurfer) {
            setIsPlaying(false);

            const subscriptions = [
                wavesurfer.on('play', () => setIsPlaying(true)),
                wavesurfer.on('pause', () => setIsPlaying(false)),
            ];

            return () => {
                subscriptions.forEach((unsub) => unsub());
            };
        }
        return undefined;
    }, [wavesurfer]);

    return (
        <Container>
            <PlayButton
                borderRadius="rounded"
                icon={isPlaying ? <Pause /> : <Play />}
                disabled={isLoading || !recordingUrl}
                onClick={onPlayClick}
            />
            <TimeLineContainer>
                <RenderIf isTrue={isLoading}>
                    <LoadingShape shape="rect" width="100%" height="50px" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <WaveformGlobalStyle />
                    <Wave ref={waveRef} id="wave" />
                </RenderIf>
            </TimeLineContainer>
        </Container>
    );
};

export default RecordedAudioPlayer;
