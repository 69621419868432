import styled from 'styled-components';
import { Textarea, Modal } from 'react-rainbow-components';
import CalendarEventTypePicker from 'components/CalendarEventTypePicker';
import CalendarPicker from 'components/CalendarPicker';
import CustomerPicker from 'components/CustomerPicker';
import Input from 'components/Input';
import UniversalForm from 'components/UniversalForm';
import { DIVIDER, GREY_200 } from '../../../../constants';

export const StyledModal = styled(Modal)`
    width: 43.03475rem;
    flex-shrink: 0;
    max-height: unset;

    > div[id^="modal-content"] {
        flex: 1 0 28.6rem;
    }
`;

export const StyledUniversalForm = styled(UniversalForm)`
    flex: 1;
    height: 100%;
`;

export const ModalFooter = styled.footer`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    gap: 1rem;
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1rem;
    padding: 2.5rem 2px 0 0;
    height: 100%;
`;

export const Row = styled.div`
    display: flex;
    flex: 0;
    align-items: stretch;
    gap: 1rem;
`;

export const StyledInput = styled(Input)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledTextarea = styled(Textarea)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > div {
        border: none;
        box-shadow: none;
    }

    textarea {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};
        width: unset;

        :focus, :active {
            border: 2px solid ${DIVIDER};
            width: unset;
        }

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledCustomerPicker = styled(CustomerPicker)`
    flex: 1;
    align-self: stretch;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledCalendarPicker = styled(CalendarPicker)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const StyledCalendarEventTypePicker = styled(CalendarEventTypePicker)`
    flex: 1;

    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;
