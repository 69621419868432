import { useMemo } from 'react';
import priceFormatter from 'data/services/price/formatter';
import { PriceLabel, SummaryContainer, SummaryLabel } from './styled';
import { OpportunityCard } from './types';

const StageSummary = ({
    cards = [],
}: {
    cards?: OpportunityCard[],
}) => {
    const totalAmount = useMemo(
        () => {
            const total = cards.reduce(
                (result, card: OpportunityCard) => result + ((card.data?.amount || 0) as number),
                0,
            );

            return priceFormatter('usd').format(total / 100);
        },
        [cards],
    );

    return (
        <SummaryContainer>
            <PriceLabel>{totalAmount}</PriceLabel>
            <SummaryLabel>Total Value</SummaryLabel>
        </SummaryContainer>
    );
};

export default StageSummary;
