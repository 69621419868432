import Badge from 'components/Badge';
import { BaseProps } from 'types';

interface Props extends BaseProps {
    size?: 'small' | 'medium' | 'large';
}

const SpamBadge = ({ className, size = 'small' }: Props) => (
    <Badge className={className} variant="error" borderRadius="semi-square" size={size}>Spam</Badge>
);

export default SpamBadge;
