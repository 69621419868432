import { Outlet } from 'react-router-dom';
import BasicPageLayout from 'layouts/BasicPage';

const OpportunitiesPage = () => (
    <BasicPageLayout>
        <Outlet />
    </BasicPageLayout>
);

export default OpportunitiesPage;
