import styled from 'styled-components';
import Picklist from 'components/Picklist';

export const StyledPicklist = styled(Picklist)`
    width: 250px;

    input {
        border-radius: 6.25rem;
    }
`;

export const WordCloudContainer = styled.div`
    width: 100%;
`;

export const TableContainer = styled.div`
    width: 100%;
`;

export const TableHeading = styled.div`
    display: flex;
    padding-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 3.5rem;
`;

export const TableTitle = styled.span`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
`;
