import { useCallback, useMemo, useRef } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    orderBy, query, where,
} from 'firebase/firestore';
import SmsIcon from 'components/icons/sms';
import useCustomers from 'data/firestore/agent/customer/useCollection';
import useSMSChat from 'hooks/useSMSChat';
import { ButtonIconHandle } from './types';
import {
    NotificationMenuContainer, StyledButtonIcon, StyledSpinner,
    StyledBadgeOverlay,
} from '../SMSChat/ConversationList/styled';

interface SmsNotificationsProps {
    agentId: string;
    className?: string;
}

const SmsNotifications = ({
    agentId,
    className,
}: SmsNotificationsProps) => {
    const buttonRef = useRef<ButtonIconHandle>();

    const { openChat } = useSMSChat();

    const { data = [], isLoading } = useCustomers(
        agentId,
        {
            disabled: !agentId,
            listQueryFn: (ref) => query(
                ref,
                where('hasNewMessage', '==', true),
                orderBy('lastMessage.createdAt', 'desc'),
            ),
            track: [agentId],
        },
    );

    const unreads = useMemo(
        () => data.length,
        [data],
    );

    const value = useMemo(
        () => (
            typeof unreads === 'number' && unreads > 0
                ? unreads
                : undefined
        ),
        [unreads],
    );
    const icon = (
        <StyledBadgeOverlay
            overlap="circle"
            isHidden={!unreads}
            value={value}
        >
            <SmsIcon />
        </StyledBadgeOverlay>
    );

    const handleOnClick = useCallback(
        () => openChat({}),
        [openChat],
    );

    return (
        <NotificationMenuContainer className={className}>
            <RenderIf isTrue={isLoading}>
                <StyledSpinner type="arc" variant="brand">
                    <StyledButtonIcon
                        ref={buttonRef}
                        icon={icon}
                        onClick={handleOnClick}
                    />
                </StyledSpinner>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <StyledButtonIcon
                    ref={buttonRef}
                    icon={icon}
                    onClick={handleOnClick}
                />
            </RenderIf>
        </NotificationMenuContainer>
    );
};

export default SmsNotifications;
