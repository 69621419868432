import { useCallback } from 'react';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import { formatIntlPhoneNumber } from 'data/services/phone/formatter';
import useCustomerPanel from 'hooks/useCustomerPanel';
import {
    AvatarContainer,
    PhoneLabel,
    NameLabel,
    StyledAvatar,
    UserInfoContainer,
} from './styled';

interface UserAvatarProps {
    isLoading?: boolean;
    initials?: string | null;
    uid?: string;
    phoneNumber?: string;
    displayName?: string;
    src?: string;
}

const UserAvatar = ({
    initials,
    uid,
    phoneNumber,
    displayName,
    src,
    isLoading = false,
}: UserAvatarProps) => {
    const { openCustomerPanel } = useCustomerPanel();
    const handleCustomerPanelClick = useCallback(
        () => openCustomerPanel({ data: { id: uid } }),
        [openCustomerPanel, uid],
    );

    return (
        <AvatarContainer type="button" onClick={handleCustomerPanelClick}>
            <StyledAvatar
                src={src}
                initials={initials}
                assistiveText={displayName}
                title={displayName}
                value={(uid || phoneNumber) as string}
            />
            <UserInfoContainer>
                <NameLabel className="rainbow-m-top_small">
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {displayName}
                    </RenderIf>
                </NameLabel>
                <PhoneLabel className="rainbow-m-top_xx-small">
                    <RenderIf isTrue={isLoading}>
                        <LoadingShape />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {formatIntlPhoneNumber(phoneNumber)}
                    </RenderIf>
                </PhoneLabel>
            </UserInfoContainer>
        </AvatarContainer>
    );
};

export default UserAvatar;
