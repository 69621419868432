import { IconProps } from 'types';

const CalendarStatusIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
        width={18}
        height={18}
    >
        <title>{title}</title>
        <path d="M8.84625 15.75H3.75C2.92157 15.75 2.25 15.0784 2.25 14.25V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H12.75C13.5784 3.75 14.25 4.42157 14.25 5.25V8.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.84625 15.75H3.75C2.92157 15.75 2.25 15.0784 2.25 14.25V5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H12.75C13.5784 3.75 14.25 4.42157 14.25 5.25V8.25" stroke="white" strokeOpacity="0.1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="13.5" cy="13.5" r="3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 2.25V5.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 2.25V5.25" stroke="white" strokeOpacity="0.1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.25 2.25V5.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.25 2.25V5.25" stroke="white" strokeOpacity="0.1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.25 8.25H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 12.3711V13.4991L14.25 14.2491" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default CalendarStatusIcon;
