/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Inbox } from 'data/firestore/inbox/types';

export interface Context {
    inboxItem?: Inbox | null;
    isLoading?: boolean;
}

const context = React.createContext<Context>({
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
