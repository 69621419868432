import styled from 'styled-components';
import LoadingShape from 'components/LoadingShape';
import { GREY_800 } from '../../constants';

export const SectionHeader = styled.div`
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${GREY_800};
    padding-top: 0.5rem;
`;

export const ConversationSummaryContainer = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: 0.4px;
    text-align: left;
    color: ${GREY_800};
    margin-bottom: 1rem;
`;

export const StyledLoadingShape = styled(LoadingShape)`
    margin-bottom: 5px;
`;
