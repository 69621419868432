import { RenderIf } from 'react-rainbow-components';
import { Avatar, StyledSaraAvatar } from './styled';

interface Props {
    [prop: string]: any;
    role?: string;
}

const ActivityAvatar = ({ role, ...rest }: Props) => (
    <>
        <RenderIf isTrue={role !== 'agent'}>
            <Avatar {...rest} />
        </RenderIf>
        <RenderIf isTrue={role === 'agent'}>
            <StyledSaraAvatar />
        </RenderIf>
    </>
);

export default ActivityAvatar;
