import styled from 'styled-components';

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ActionsCellWrapper = styled(CellWrapper)`
    justify-content: flex-end;
    padding: 0 2rem 0 0;
    gap: 1.75rem;
`;

export const UserCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 20px;
`;

export const RoleCellWrapper = styled(CellWrapper)`
    gap: 1rem;
`;

export const RoleLabel = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    text-transform: capitalize;
`;
