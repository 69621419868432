import { Picklist } from 'react-rainbow-components';
import styled, { createGlobalStyle } from 'styled-components';

export const PickListGlobalStyle = createGlobalStyle`
    div[id^="listbox-"][role="listbox"] > div {
        padding: 0.5rem;
    }
`;

export const StyledPickList = styled(Picklist)`
    width: 265px;

    input {
        border-radius: 0.375rem;
        border: 1px solid #DBDADE;

        ::placeholder {
            font-weight: 300 !important;
        }
    }
`;
