import formatDuration from 'data/services/duration/formatter';
import { CellWrapper } from '../styled';

interface Props {
    value?: number;
}

const Usage = ({ value }: Props) => (
    <CellWrapper>
        {value && formatDuration(value * 1000)}
    </CellWrapper>
);

export default Usage;
