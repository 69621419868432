import styled from 'styled-components';
import { Drawer } from 'react-rainbow-components';

export const StyledDrawer = styled(Drawer)`
    width: 40vw;
    min-width: 40vw;
    border-radius: 1.5rem;
    margin: 1.5rem;
    box-sizing: border-box;
    height: calc(100% - 3rem);
    min-height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);
    box-shadow: 0px 0px 44px 0px rgba(0, 0, 0, 0.07);

    > div {
        padding: 1.5rem 1.5rem 0 1.5rem;
        box-sizing: border-box;
    }
`;

export const DrawerGradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 90%);
    height: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-right: 24px;
    width: 100%;
    z-index: 1000;
    border-radius: 0 0 1.5rem 1.5rem;
`;
