const genderLabelMap: Record<string, string> = {
    male: 'Male',
    female: 'Female',
    'non-binary': 'Non-Binary',
    queer: 'Genderqueer',
    fluid: 'Genderfluid',
    trans: 'Transgender',
    unknown: 'Prefer Not to Say',
};

export default function formatGender(gender: string = ''): string {
    return genderLabelMap[gender.toLowerCase()] || gender;
}
