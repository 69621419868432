import { IconProps } from 'types';

const Download = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        viewBox="0 0 22 22"
        width={22}
        height={22}
    >
        <title>{title}</title>
        <path d="M3.66669 15.584V17.4173C3.66669 18.4298 4.4875 19.2507 5.50002 19.2507H16.5C17.5125 19.2507 18.3334 18.4298 18.3334 17.4173V15.584" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.41669 10.084L11 14.6673L15.5834 10.084" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 3.66602V14.666" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Download;
