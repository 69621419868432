import { DateTime } from 'luxon';
import { RenderIf } from 'react-rainbow-components';
import ActivityAvatar from 'components/ActivityAvatar';
import getInitials from 'data/services/string/getInitials';
import getAvatarIcon from 'data/services/profile/getAvatarIcon';
import getValueForAvatar from 'data/services/profile/getValueForAvatar';
import { ItemUser } from 'data/firestore/globals';
import getDisplayName from 'data/services/profile/getDisplayName';
import { useMemo } from 'react';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import getMessageLabel from './getMessageLabel';
import getMessageIcon from './getMessageIcon';
import MessageContent from './messageContent';
import {
    MessageBody,
    MessageContainer,
    Name,
    SecondaryText,
    ActivityTitle,
    Icon,
    ChatBubble,
} from './styled';
import { ChatMessage, DisplayContext } from './types';
import MessageFooter from './messageFooter';

interface Params {
    type: ChatMessage['type'];
    inboxId?: string | null;
    opportunityId?: string | null;
    agentId?: string;
    content: Record<string, unknown>,
    createdBy?: ItemUser,
    createdAt: Date;
    context?: DisplayContext;
}

const formatTime = (
    date?: Date,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
) => date && DateTime.fromJSDate(date).setZone(timezone).toFormat('t');

const Message = ({
    createdBy,
    agentId = '',
    inboxId = '',
    opportunityId = '',
    type = 'note',
    content = {},
    createdAt,
    context = 'customer',
}: Params) => {
    const { uid: currentUserId } = useCurrentUser() || {};
    const displayName = useMemo(() => getDisplayName(createdBy), [createdBy]);
    const initials = useMemo(() => {
        if (displayName === 'Unknown' || createdBy?.role === 'agent') return null;
        return getInitials(displayName);
    }, [createdBy?.role, displayName]);

    // eslint-disable-next-line max-len
    const isCurrentUser = useMemo(() => currentUserId === createdBy?.uid, [currentUserId, createdBy?.uid]);

    return (
        <MessageContainer isCurrentUser={isCurrentUser} role={createdBy?.role}>
            <ActivityAvatar
                src={createdBy?.photoURL}
                initials={initials}
                icon={getAvatarIcon(createdBy as ItemUser)}
                value={getValueForAvatar(createdBy as ItemUser, agentId)}
                role={createdBy?.role}
            />
            <MessageBody isCurrentUser={isCurrentUser}>
                <ActivityTitle isCurrentUser={isCurrentUser}>
                    <Icon>
                        {getMessageIcon(type, content, createdBy?.role as string)}
                    </Icon>
                    <Name>
                        {displayName}
                        <RenderIf isTrue={isCurrentUser}>
                            {' '}
                            (You)
                        </RenderIf>
                    </Name>
                    <SecondaryText>
                        {getMessageLabel({
                            type,
                            content,
                            agentId,
                            inboxId,
                            opportunityId,
                            context,
                        })}
                        {' '}
                        at
                        {' '}
                        {formatTime(createdAt)}
                    </SecondaryText>
                </ActivityTitle>
                <ChatBubble role={createdBy?.role} isCurrentUser={isCurrentUser}>
                    <MessageContent
                        type={type}
                        content={{
                            inboxId,
                            opportunityId,
                            ...content,
                        }}
                        context={context}
                    />
                </ChatBubble>
                <MessageFooter
                    isCurrentUser={isCurrentUser}
                    type={type}
                    content={{
                        inboxId,
                        opportunityId,
                        ...content,
                    }}
                />
            </MessageBody>
        </MessageContainer>
    );
};

export default Message;
