/* eslint-disable react/forbid-component-props */
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Button from 'components/Button';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { Calendareventtype } from 'data/firestore/agent/calendareventtype/types';
import StepOne from './step1';
import StepTwo from './step2';
import {
    ModalFooter,
    StyledModal,
    StyledUniversalForm,
} from './styled';
import { CalendarEventModalProps } from './types';

interface StepProps {
    agentId?: string;
}

const stepNames = ['step-1', 'step-2'];

const stepsComponentsMap: Record<string, React.JSXElementConstructor<StepProps>> = {
    'step-1': StepOne,
    'step-2': StepTwo,
};

const isLastStep = (currentStepIndex: number) => currentStepIndex === stepNames.length - 1;

const isFirstStep = (currentStepIndex: number) => currentStepIndex === 0;

const getBackButtonLabel = (currentStepIndex: number) => {
    if (isFirstStep(currentStepIndex)) {
        return 'Cancel';
    }
    return 'Back';
};

const getNextButtonLabel = (currentStepIndex: number) => {
    if (isLastStep(currentStepIndex)) {
        return 'Book';
    }
    return 'Next';
};

const mapValues = (values: Record<string, unknown>) => {
    const {
        calendar,
        eventType,
        startAt: startAtValue,
        ...rest
    } = values;

    const { id: calendarId } = calendar as Calendar;
    const { id: eventTypeId, duration } = eventType as Calendareventtype;

    const startAt = (
        typeof startAtValue === 'string'
            ? startAtValue
            : (startAtValue as Date).toISOString()
    );

    return {
        calendarId,
        eventTypeId,
        startAt,
        duration,
        ...rest,
    };
};

const CalendarEventModalCreateMode = ({
    agentId,
    isOpen = false,
    title,
    initialValues = {},
    onRequestClose = () => {},
    onComplete = () => {},
    className,
    style,
}: CalendarEventModalProps) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const goBackStep = useCallback(
        () => {
            if (!isFirstStep(currentStepIndex)) {
                setCurrentStepIndex(currentStepIndex - 1);
            } else {
                onRequestClose();
            }
        },
        [currentStepIndex, onRequestClose],
    );

    const goNextStep = useCallback(
        (values: Record<string, unknown>) => {
            if (isLastStep(currentStepIndex)) {
                onComplete(mapValues(values));
            } else {
                setCurrentStepIndex(currentStepIndex + 1);
            }
        },
        [currentStepIndex, onComplete],
    );

    const currentStep = stepNames[currentStepIndex];
    const StepComponent = useMemo(
        () => stepsComponentsMap[currentStep] || null,
        [currentStep],
    );

    useEffect(() => {
        if (isOpen) {
            setCurrentStepIndex(0);
        }
    }, [isOpen]);

    return (
        <StyledModal
            className={className}
            style={style}
            title={title}
            size="small"
            borderRadius="semi-square"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            footer={(
                <ModalFooter>
                    <Button
                        label={getBackButtonLabel(currentStepIndex)}
                        onClick={goBackStep}
                        borderRadius="semi-square"
                    />
                    <Button
                        label={getNextButtonLabel(currentStepIndex)}
                        variant="brand"
                        type="submit"
                        form="calendar-event-form"
                        borderRadius="semi-square"
                    />
                </ModalFooter>
            )}
        >
            <StyledUniversalForm
                id="calendar-event-form"
                initialValues={initialValues}
                onSubmit={goNextStep}
            >
                <StepComponent agentId={agentId} />
            </StyledUniversalForm>
        </StyledModal>
    );
};

export default CalendarEventModalCreateMode;
