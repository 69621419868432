import styled from 'styled-components';
import { Calendar } from 'react-rainbow-components';
import { PURPLE_100, DIVIDER } from '../../constants';

export default styled(Calendar)`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: 2rem 1.5rem;


    & > div {
        & > div:last-child select {
            border-radius: 0.25rem;
            border-color: ${DIVIDER};
        }
    }

    h3[id^="month"] {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3125rem;
    }

    button {
        border-radius: 0.375rem;

        &[data-selected="false"]:hover {
            background: ${PURPLE_100};
            border-color: transparent;
        }

        &[data-selected="true"] {
            box-shadow: none;
        }
    }
`;
