import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MetaLoginCallback = () => {
    const agentId = localStorage.getItem('instagramAuth');
    const navigate = useNavigate();

    useEffect(() => {
        if (!agentId) {
            return;
        }

        const url = new URL(window.location.href);
        navigate(`/${agentId}/social/meta?${url.hash}`);
    }, [agentId, navigate]);

    return null;
};

export default MetaLoginCallback;
