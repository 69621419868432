import styled, { css } from 'styled-components';
import { Modal as RainbowModal } from 'react-rainbow-components';
import Card from 'components/Card';
import { color } from 'theme';
import Page from 'components/icons/page';
import { GREY_300 } from '../../constants';

export const Container = styled.div`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    gap: 1rem;
`;

export const LeftColumn = styled(Card)`
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 20rem;
    padding: 1.5rem;
    gap: 1.5rem;
`;

export const Main = styled(Card)`
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const SideBar = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 0.25rem;
`;

export const Option = styled.li<{ selected?: boolean; }>`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 4.0625rem;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0.1875rem;
    width: 100%;
    height: 3rem;
    flex: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${color('text.main')};

    &:hover,
    :visited,
    :focus {
        background-color: #F5F5F6;
        text-decoration: none;
    }

    ${(props) => props.selected
        && css`
            background-color: #F5F5F6;
            cursor: default;

            &:hover {
                background-color: #F5F5F6;
            }
        `}
`;

export const OptionIconContainer = styled.div`
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    box-sizing: border-box;
    flex: none;
    align-items: center;
    justify-content: center;
    padding: 0.1875rem;
    color: #88888D;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    align-items: flex-start;
    gap: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
`;

export const Title = styled.h2`
    color: #000;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.75rem;
`;

export const SubTitle = styled.h4`
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const Modal = styled(RainbowModal)`
    width: 620px;
    padding: 24px !important;
`;

export const PageIcon = styled(Page)`
    color: ${GREY_300};
`;
