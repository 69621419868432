import { getFormatter } from 'data/services/date/formatter';
import { CellWrapper } from '../styled';

interface Props {
    value?: number;
}

const dateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

const DateColumn = ({ value }: Props) => (
    <CellWrapper>
        {dateFormatter.format(value)}
    </CellWrapper>
);

export default DateColumn;
