import { useEffect } from 'react';
import { To, useNavigate } from 'react-router-dom';
import useUserRoles from './useUserRoles';

interface Params {
    redirectTo?: To,
}

const useRedirectWhenNotAdmin = ({
    redirectTo = '/',
}: Params) => {
    const { isLoading = false, isAdmin } = useUserRoles();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !isAdmin) {
            navigate(redirectTo, { replace: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAdmin, redirectTo]);
};

export default useRedirectWhenNotAdmin;
