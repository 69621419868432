import styled from 'styled-components';
import Table from 'components/Table';
import { GREY_800, GREY_500, DIVIDER } from '../../../../constants';

export const Container = styled.div`
    display: flex;
    padding: 0.75rem 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
    overflow: hidden;
    align-self: stretch;
`;

export const Content = styled.div`
    display: flex;
    align-sleft: stretch;
    overflow: hidden;
    flex:1;
    border: 1px solid ${DIVIDER};
    border-radius: 0.375rem;
`;

export const StyledTable = styled(Table)`
    table {
        padding-bottom: 1px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.50rem;
    width: 100%;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;

export const Description = styled.p`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${GREY_500};
`;
