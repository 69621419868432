const checkTemplateInUse = (
    templateName: string,
    systemMessageTemplate: string = '',
    templatePartials: Array<{ content: string; }> = [],
) => (
    systemMessageTemplate.includes(`{{> ${templateName}}}`)
        || templatePartials.some(({ content }) => content.includes(`{{> ${templateName}}}`))
);

export default checkTemplateInUse;
