import styled, { createGlobalStyle, css } from 'styled-components';
import {
    GREY_800, TEAL_500, TEAL_100, GREY_300, ORANGE_500, ORANGE_100,
} from '../../constants';

export const PickListGlobalStyle = createGlobalStyle<{ id?:string }>`
    div[id^="listbox-"][role="listbox"] {
        li > div#viewer,
        li > div#editor
        {
            white-space: unset;
            padding: 0.5rem 1.25rem;
        }
    }
`;

export const OptionContainer = styled.div`
    display: flex;
    align-content: flex-start;
    justify-content: stretch;
    gap: 0.75rem;
`;

export const IconContainer = styled.div.withConfig({
    shouldForwardProp(prop, defaultValidatorFn) {
        return !['roleName'].includes(prop) && defaultValidatorFn(prop);
    },
})<{ roleName?: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 0.375rem;
    flex-shrink: 0;
    
    svg {
        width: 1.25rem
        height: 1.25rem
    }

    ${(props) => props.roleName === 'viewer' && css`
        background: ${ORANGE_100};
        color: ${ORANGE_500};
    `};

    ${(props) => props.roleName === 'editor' && css`
        background: ${TEAL_100};
        color: ${TEAL_500};
    `};
`;

export const OptionLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OptionLabel = styled.span`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_800};
`;

export const OptionDescription = styled.span`
    font-family: Public Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    whitespace: wrap;
    color: ${GREY_300};
`;
