import { IconProps } from 'types';

const ChatIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={24}
        height={24}
    >
        <title>{title}</title>
        <path
            d="M3 20.0016L4.3 16.1016C1.97612 12.6646 2.87388 8.22928 6.39977 5.72785C9.92566 3.22642 14.9902 3.43176 18.2453 6.20813C21.5003 8.98451 21.9401 13.474 19.2739 16.7087C16.6076 19.9434 11.6592 20.9237 7.7 19.0016L3 20.0016"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default ChatIcon;
