import { MutableRefObject, useRef, useState } from 'react';
import { InternalOverlay } from 'react-rainbow-components';
import { useOutsideClick } from '@rainbow-modules/hooks';
import ChevronsRight from 'components/icons/chevronsRight';
import {
    StyledButtonIcon, StyledRateContainer, StyledRateLi, StyledRateUl,
} from './styled';
import positionResolver from './helpers/positionResolver';

interface Props {
    value?: number;
    onChange: (newValue: number) => void;
    dark?: boolean;
}

interface ButtonIconRef {
    htmlElementRef: MutableRefObject<HTMLElement>;
}

const options = [0.5, 0.75, 1, 1.25, 1.5];

const RateInput = ({ value, onChange, dark }: Props) => {
    const triggerRef = useRef<ButtonIconRef>();
    const dropdownRef = useRef<HTMLDivElement>();
    const [isOpen, setIsOpen] = useState(false);

    useOutsideClick(
        dropdownRef as MutableRefObject<HTMLDivElement>,
        (event) => {
            if (event.target !== triggerRef?.current?.htmlElementRef?.current) {
                setIsOpen(false);
            }
        },
        isOpen,
    );

    return (
        <>
            <StyledButtonIcon
                size="x-small"
                icon={<ChevronsRight />}
                variant="base"
                ref={triggerRef}
                onClick={() => setIsOpen(!isOpen)}
                dark={dark}
            />
            <InternalOverlay
                isVisible={isOpen}
                positionResolver={positionResolver}
                render={() => (
                    <StyledRateContainer ref={dropdownRef as MutableRefObject<HTMLDivElement>}>
                        <StyledRateUl>
                            {options.map((option) => (
                                <StyledRateLi
                                    key={option}
                                    onClick={() => onChange(option)}
                                    active={value === option}
                                >
                                    {option}
                                </StyledRateLi>
                            ))}
                        </StyledRateUl>
                    </StyledRateContainer>
                )}
                // eslint-disable-next-line max-len
                triggerElementRef={() => triggerRef?.current?.htmlElementRef as MutableRefObject<HTMLElement>}
            />
        </>
    );
};

export default RateInput;
