import { ReactNode } from 'react';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import {
    SummaryItemContainer, InfoContainer, IconContainer, Value, Label,
} from './styled';

interface SummaryItemProps {
    value: string;
    label: string;
    icon: ReactNode;
    isLoading?: boolean;
}

const SummaryItem = ({
    value, label, icon, isLoading,
}: SummaryItemProps) => (
    <SummaryItemContainer>
        <RenderIf isTrue={isLoading}>
            <LoadingShape shape="square" />
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
            <InfoContainer>
                <Value>{value}</Value>
                <Label>{label}</Label>
            </InfoContainer>
            <IconContainer className="icon">{icon}</IconContainer>
        </RenderIf>
    </SummaryItemContainer>
);

export default SummaryItem;
