import Card from 'components/Card';
import styled from 'styled-components';
import { Modal } from 'react-rainbow-components';

export const StyledCard = styled(Card)`
    height: calc(100vh - 161px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const StyledModal = styled(Modal)`
    width: 620px;
    padding: 24px !important;
`;
