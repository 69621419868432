import {
    Routes,
    Route,
    Outlet,
    Navigate,
} from 'react-router-dom';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import usePageViewAnalytics from 'analytics/usePageViewAnalytics';
import Items from 'pages/Inbox/Items';
import Item from 'pages/Inbox/Item';
import Login from 'pages/Login';
import AppLayout from 'layouts/App';
import Inbox from 'pages/Inbox';
import Insights from 'pages/Insights';
import Billing from 'pages/Billing';
import History from 'pages/History';
import HistoryConversationDetails from 'pages/History/ConversationDetails';
import BlockedNumbers from 'pages/BlockedNumbers';
import InboxItemOverview from 'pages/Inbox/Item/Overview';
import InboxItemActivity from 'pages/Inbox/Item/Activity';
import CustomersPage from 'pages/Customers';
import CustomersListPage from 'pages/Customers/CustomersList';
import CustomerDetails from 'pages/Customers/CustomerDetails';
import CustomerDetailsActivity from 'pages/Customers/CustomerDetails/Activity';
import CustomerFiles from 'pages/Customers/CustomerDetails/CustomerFiles';
import Instructions from 'pages/Instructions';
import TeamLayout from 'pages/Team';
import OpenTasksProvider from 'components/OpenTasksProvider';
import UserProvider from 'components/UserProvider';
import NotificationSettings from 'pages/Notifications';
import CompanyInfo from 'pages/CompanyInfo';
import PhoneNumbersPage from 'pages/PhoneNumbers';
import AgentProvider from 'components/AgentProvider';
import CalendarPage from 'pages/Calendar';
import CalendarViewPage from 'pages/Calendar/View';
import CalendarSettingsPage from 'pages/Calendar/Settings';
import CalendarViewList from 'pages/Calendar/View/List';
import CalendarEventStatus from 'pages/Calendar/Settings/EventStatus';
import CalendarEventTypesList from 'pages/Calendar/Settings/EventTypes/ListPage';
import CalendarEventTypeDetails from 'pages/Calendar/Settings/EventTypes/DetailsPage';
import CalendarsList from 'pages/Calendar/Settings/Calendars';
import NewCalendarPage from 'pages/Calendar/Settings/Calendars/CreatePage';
import EditCalendarPage from 'pages/Calendar/Settings/Calendars/EditPage';
import CalendarViewDay from 'pages/Calendar/View/Day';
import TeamAffiliates from 'pages/Team/Affiliates';
import AffiliatesSettings from 'pages/Team/Affiliates/Settings';
import TeamPage from 'pages/Team/Members';
import EmailLogin from 'pages/EmailLogin';
import EmailSignUp from 'pages/EmailSignUp';
import AffiliateInsights from 'pages/AffiliateInsights';
import Meta from 'pages/Social/Meta';
import MetaLoginCallback from 'pages/Social/Meta/callback';
import OpportunitiesPage from 'pages/Opportunities';
import OpportunitiesViewPage from 'pages/Opportunities/View';
import OpportunitiesViewList from 'pages/Opportunities/View/List';
import OpportunitiesViewBoard from 'pages/Opportunities/View/Board';
import Responders from 'pages/Responders';
import RespondersListPage from 'pages/Responders/RespondersList';
import ResponderDetails from 'pages/Responders/ResponderDetails';
import LLMResponders from 'pages/LLMResponders';
import LLMRespondersListPage from 'pages/LLMResponders/RespondersList';
import LLMResponderDetails from 'pages/LLMResponders/ResponderDetails';
import OpportunityInsights from 'pages/OpportunityInsights';
import TopicsInsights from 'pages/TopicsInsights';
import TasksPage from 'pages/Tasks';
import TasksList from 'pages/Tasks/List';
import ApiKeyPage from 'pages/ApiKeys';
import WebhooksPage from 'pages/Webhooks';
import SkillsPage from 'pages/Skills';

const App = () => {
    usePageViewAnalytics();
    return (
        <Routes>
            <Route
                path="/login"
                element={(
                    <WhenNoAuthenticated redirect="/">
                        <Login />
                    </WhenNoAuthenticated>
                )}
            />
            <Route
                path="/login/email"
                element={(
                    <WhenNoAuthenticated redirect="/">
                        <EmailLogin />
                    </WhenNoAuthenticated>
                )}
            />
            <Route
                path="/signup/email"
                element={(
                    <WhenNoAuthenticated redirect="/">
                        <EmailSignUp />
                    </WhenNoAuthenticated>
                )}
            />
            <Route
                path="/"
                element={(
                    <WhenAuthenticated path="/" redirect="/login">
                        <UserProvider>
                            <OpenTasksProvider>
                                <Outlet />
                            </OpenTasksProvider>
                        </UserProvider>
                    </WhenAuthenticated>
                )}
            >
                <Route path="/meta-callback" element={<MetaLoginCallback />} />
                <Route path="/" element={<AppLayout />}>
                    <Route
                        path=":agentId"
                        element={(
                            <AgentProvider>
                                <Outlet />
                            </AgentProvider>
                        )}
                    >
                        <Route path="tasks" element={<TasksPage />}>
                            <Route path="" element={<Navigate to="open" replace />} />
                            <Route path=":taskStatus" element={<TasksList />} />
                        </Route>
                        <Route path="inbox" element={<Inbox />}>
                            <Route path="" element={<Items />} />
                            <Route path=":itemId" element={<Item />}>
                                <Route path="" element={<Navigate to="overview" replace />} />
                                <Route path="overview" element={<InboxItemOverview />} />
                                <Route path="activity" element={<InboxItemActivity />} />
                            </Route>
                        </Route>
                        <Route path="calendar" element={<CalendarPage />}>
                            <Route path="" element={<Navigate to="view" replace />} />
                            <Route path="view" element={<CalendarViewPage />}>
                                <Route path="" element={<Navigate to="day" replace />} />
                                <Route path="day" element={<CalendarViewDay />} />
                                <Route path="list" element={<CalendarViewList />} />
                            </Route>
                            <Route path="create" element={<NewCalendarPage />} />
                            <Route path="edit/:calendarId" element={<EditCalendarPage />} />
                            <Route path="settings/event-types/:eventTypeId/details" element={<CalendarEventTypeDetails />} />
                            <Route path="settings" element={<CalendarSettingsPage />}>
                                <Route path="" element={<Navigate to="event-types" replace />} />
                                <Route path="event-types" element={<CalendarEventTypesList />} />
                                <Route path="event-status" element={<CalendarEventStatus />} />
                                <Route path="calendars" element={<CalendarsList />} />
                            </Route>
                        </Route>
                        <Route path="customers" element={<CustomersPage />}>
                            <Route path="" element={<CustomersListPage />} />
                            <Route path=":customerId" element={<CustomerDetails />}>
                                <Route path="" element={<Navigate to="activity" replace />} />
                                <Route path="activity" element={<CustomerDetailsActivity />} />
                                <Route path="files" element={<CustomerFiles />} />
                            </Route>
                        </Route>
                        <Route path="opportunities" element={<OpportunitiesPage />}>
                            <Route path="" element={<Navigate to="view" replace />} />
                            <Route path="view" element={<OpportunitiesViewPage />}>
                                <Route path="" element={<Navigate to="board" replace />} />
                                <Route path="list" element={<OpportunitiesViewList />} />
                                <Route path="board" element={<OpportunitiesViewBoard />} />
                            </Route>
                        </Route>
                        <Route path="insights" element={<Outlet />}>
                            <Route path="" element={<Navigate to="calls" replace />} />
                            <Route path="calls" element={<Insights />} />
                            <Route path="affiliates" element={<AffiliateInsights />} />
                            <Route path="opportunities" element={<OpportunityInsights />} />
                            <Route path="topics" element={<TopicsInsights />} />
                        </Route>

                        {/* Control panel */}
                        <Route path="billing" element={<Billing />} />
                        <Route path="settings/blocked-numbers" element={<BlockedNumbers />} />
                        <Route path="settings/company-info" element={<CompanyInfo />} />
                        <Route path="team" element={<TeamLayout />}>
                            <Route path="members" element={<TeamPage />} />
                            <Route path="affiliates" element={<TeamAffiliates />} />
                            <Route path="affiliates/settings" element={<AffiliatesSettings />} />
                        </Route>

                        {/* VoIP */}
                        <Route path="voip/instructions" element={<Instructions />} />
                        <Route path="voip/notifications" element={<NotificationSettings />} />
                        <Route path="voip/phone-numbers" element={<PhoneNumbersPage />} />
                        <Route path="voip/history" element={<History />} />
                        <Route path="voip/history/:sessionId" element={<HistoryConversationDetails />} />
                        <Route path="social/meta" element={<Meta />} />

                        {/* Responders */}
                        <Route path="responders" element={<Responders />}>
                            <Route path="" element={<RespondersListPage />} />
                            <Route path=":responderId" element={<ResponderDetails />} />
                        </Route>

                        {/* LLM Responders */}
                        <Route path="llms" element={<LLMResponders />}>
                            <Route path="" element={<LLMRespondersListPage />} />
                            <Route path=":responderId" element={<LLMResponderDetails />} />
                        </Route>

                        {/* AI Skills */}
                        <Route path="skills" element={<SkillsPage />} />

                        {/* Developer */}
                        <Route path="developer" element={<Outlet />}>
                            <Route path="api" element={<ApiKeyPage />} />
                            <Route path="webhooks" element={<WebhooksPage />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

export default App;
