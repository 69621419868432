import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    ElementContainer, IconContainer, StyledRecordField,
} from './styled';

interface DetailElementProps {
    icon?: ReactNode;
    label?: ReactNode;
    value?: ReactNode | Record<string, unknown>;
    wrap?: boolean;
    [key: string]: any;
}

const DetailElement = ({
    icon,
    label,
    value,
    wrap = false,
    ...rest
}: DetailElementProps) => (
    <ElementContainer>
        <RenderIf isTrue={icon}>
            <IconContainer>
                {icon}
            </IconContainer>
        </RenderIf>
        <StyledRecordField
            label={label}
            value={value}
            wrap={wrap}
            {...rest}
        />
    </ElementContainer>
);

export default DetailElement;
