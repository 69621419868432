import styled from 'styled-components';
import { Virtuoso } from 'react-virtuoso';
import Badge from 'components/Badge';
import EmptyMessage from 'components/EmptyMessage';
import ChevronDown from 'components/icons/chevronDown';
import {
    PURPLE_600, PURPLE_500, GREY_400, PURPLE_300, PURPLE_200, WHITE,
} from '../../../constants';

export const StyledVirtuoso = styled(Virtuoso)`
    width: 100%;
    overflow-x: hidden;
`;

export const StyledEmptyMessage = styled(EmptyMessage)`
    color: ${GREY_400};
    border-radius: 10px;
    width: 75%;
    margin: 2rem auto 0 auto;
    padding: 8px 12px;
    color: ${GREY_400};
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 300;
`;

export const StyledBadge = styled(Badge)`
    background-color: ${WHITE};
    text-transform: none;
    align-self: center;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 0.25rem;
    border: 1px solid #DBDADE;
    display: flex;
    padding: 0.3125rem 0.625rem;
    align-items: center;
    gap: 0.625rem;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Space = styled.div`
    height: 12px;
`;

export const StyledChevronDown = styled(ChevronDown)`
    color: ${PURPLE_600};
    height: 1.25rem;
`;

export const StyledUnreadDot = styled.div`
    background-color: ${PURPLE_500};
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    top: -5px;
    left: -5px;
`;

export const StyledDownButton = styled.button`
    background-color: ${PURPLE_200};
    border: none;
    border-radius: 2rem;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 1rem;
    bottom: 2rem;
    z-index: 100;
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);

    :hover {
        background-color: ${PURPLE_300};
        cursor: pointer;
        box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.15);
    }
`;
