import { IconProps } from 'types';

const Eye = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        width={20}
        height={20}
    >
        <title>{title}</title>
        <circle cx="9.99992" cy="10.0026" r="1.66667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.3334 10.0013C16.1109 13.8905 13.3334 15.8346 10.0001 15.8346C6.66675 15.8346 3.88925 13.8905 1.66675 10.0013C3.88925 6.11214 6.66675 4.16797 10.0001 4.16797C13.3334 4.16797 16.1109 6.11214 18.3334 10.0013" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Eye;
