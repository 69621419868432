import { ReactNode } from 'react';
import useUserRoles from 'hooks/useUserRoles';
import { Role } from 'types';

interface Params {
    oneOf: Role[];
    children?: ReactNode;
}

const RenderIfRole = ({ oneOf = ['viewer'], children }: Params) => {
    const { role, isAdmin } = useUserRoles();
    if (oneOf.includes(role as Role) || isAdmin) {
        return children;
    }
    return null;
};

export default RenderIfRole;
