import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { color } from 'theme';
import Badge from 'components/Badge';
import {
    GREY_600, WHITE, PURPLE_500, GREY_300, PURPLE_100,
} from '../../../constants';

export const Container = styled.li`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

interface StyledLinkProps {
    isActive: boolean;
    isShrinked?: boolean;
    notifications?: string | number;
}
export const StyledLink = styled(Link).withConfig({
    shouldForwardProp: (prop) => !['isActive', 'notifications', 'isShrinked'].includes(prop),
})<StyledLinkProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
    color: ${GREY_600};
    padding: 0.6rem 0.75rem;
    border-radius: 5rem;
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.375rem;
    transition: background-color 0.2s ease-in-out;
    border: 2px solid transparent;

    svg {
        color: ${GREY_300};
    }

    &:hover, &:focus, &:active {
        color: ${GREY_600};
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.05);
    }

    ${(props) => props.notifications && props.isShrinked && css`
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0.5rem;
            width: 0.5rem;
            height: 0.5rem;
            transform: translate(50%, -50%);
            border-radius: 50%;
            background-color: ${color('brand.main')};
            color: ${WHITE};
            font-size: 0.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `};

    ${(props) => props.isActive && css`
        color: ${PURPLE_500};
        border: 2px solid ${PURPLE_500};

        svg {
            color: ${PURPLE_500};
        }

        &:hover, &:focus, &:active {
            color: ${PURPLE_500};
            background-color: ${PURPLE_100};
        }
    `}
`;

export const StyledBadge = styled(Badge)`
    color: ${PURPLE_100};
    background-color: ${PURPLE_500};
`;
