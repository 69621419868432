import { IconProps } from 'types';

const RotateClockwise = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        width={22}
        height={22}
        fill="none"
    >
        <title>{title}</title>
        <path
            d="M19.0311 12.0141C19.0849 11.6034 18.7957 11.2268 18.385 11.173C17.9743 11.1192 17.5977 11.4085 17.5438 11.8192L19.0311 12.0141ZM17.1324 8.52749C17.2857 8.9123 17.7219 9.1 18.1067 8.94672C18.4915 8.79345 18.6792 8.35725 18.5259 7.97244L17.1324 8.52749ZM19.0375 3.66657C19.0375 3.25236 18.7017 2.91657 18.2875 2.91657C17.8733 2.91657 17.5375 3.25236 17.5375 3.66657L19.0375 3.66657ZM18.2875 8.24991L18.2875 8.99991C18.4864 8.99991 18.6772 8.92089 18.8178 8.78024C18.9585 8.63959 19.0375 8.44882 19.0375 8.24991L18.2875 8.24991ZM13.7041 7.49991C13.2899 7.49991 12.9541 7.83569 12.9541 8.24991C12.9541 8.66412 13.2899 8.99991 13.7041 8.99991L13.7041 7.49991ZM17.5438 11.8192C17.1133 15.1037 14.3093 17.5567 10.9967 17.5468L10.9922 19.0468C15.0596 19.059 18.5025 16.047 19.0311 12.0141L17.5438 11.8192ZM10.9967 17.5468C7.68404 17.5369 4.89473 15.0672 4.48385 11.7801L2.99543 11.9662C3.49993 16.0022 6.92478 19.0347 10.9922 19.0468L10.9967 17.5468ZM4.48385 11.7801C4.07297 8.49305 6.16851 5.41266 9.37676 4.58764L9.00318 3.13491C5.06394 4.1479 2.49093 7.93016 2.99543 11.9662L4.48385 11.7801ZM9.37676 4.58764C12.585 3.76263 15.9066 5.44999 17.1324 8.52749L18.5259 7.97244C17.0208 4.19374 12.9424 2.12192 9.00318 3.13491L9.37676 4.58764ZM17.5375 3.66657L17.5375 8.24991L19.0375 8.24991L19.0375 3.66657L17.5375 3.66657ZM18.2875 7.49991L13.7041 7.49991L13.7041 8.99991L18.2875 8.99991L18.2875 7.49991Z"
            fill="currentColor"
        />
        <path
            d="M19.0311 12.0141C19.0849 11.6034 18.7957 11.2268 18.385 11.173C17.9743 11.1192 17.5977 11.4085 17.5438 11.8192L19.0311 12.0141ZM17.1324 8.52749C17.2857 8.9123 17.7219 9.1 18.1067 8.94672C18.4915 8.79345 18.6792 8.35725 18.5259 7.97244L17.1324 8.52749ZM19.0375 3.66657C19.0375 3.25236 18.7017 2.91657 18.2875 2.91657C17.8733 2.91657 17.5375 3.25236 17.5375 3.66657L19.0375 3.66657ZM18.2875 8.24991L18.2875 8.99991C18.4864 8.99991 18.6772 8.92089 18.8178 8.78024C18.9585 8.63959 19.0375 8.44882 19.0375 8.24991L18.2875 8.24991ZM13.7041 7.49991C13.2899 7.49991 12.9541 7.83569 12.9541 8.24991C12.9541 8.66412 13.2899 8.99991 13.7041 8.99991L13.7041 7.49991ZM17.5438 11.8192C17.1133 15.1037 14.3093 17.5567 10.9967 17.5468L10.9922 19.0468C15.0596 19.059 18.5025 16.047 19.0311 12.0141L17.5438 11.8192ZM10.9967 17.5468C7.68404 17.5369 4.89473 15.0672 4.48385 11.7801L2.99543 11.9662C3.49993 16.0022 6.92478 19.0347 10.9922 19.0468L10.9967 17.5468ZM4.48385 11.7801C4.07297 8.49305 6.16851 5.41266 9.37676 4.58764L9.00318 3.13491C5.06394 4.1479 2.49093 7.93016 2.99543 11.9662L4.48385 11.7801ZM9.37676 4.58764C12.585 3.76263 15.9066 5.44999 17.1324 8.52749L18.5259 7.97244C17.0208 4.19374 12.9424 2.12192 9.00318 3.13491L9.37676 4.58764ZM17.5375 3.66657L17.5375 8.24991L19.0375 8.24991L19.0375 3.66657L17.5375 3.66657ZM18.2875 7.49991L13.7041 7.49991L13.7041 8.99991L18.2875 8.99991L18.2875 7.49991Z"
            fill="currentColor"
        />

    </svg>
);
export default RotateClockwise;
