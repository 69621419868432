import useHttpMutation from 'data/firestore/useHttpMutation';

interface Req {
    indexName: string
}

interface Res {
    algoliaSecureKey: string
}

const useGenerateSearchKey = () => useHttpMutation<Req, Res>({
    method: 'POST',
    pathname: '/search/generate-key',
});

export default useGenerateSearchKey;
