import { ReactNode, useCallback, useMemo } from 'react';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { RenderIf } from 'react-rainbow-components';
import { Step } from './types';
import {
    Container,
    LeftColumn,
    MainContent,
    StepLabel,
    StepDescription,
    StyledUniversalPicker,
} from './styled';
import StepComponent from './step';
import { Context, Provider } from './context';

interface Params {
    activeStep?: string;
    steps?: Step[];
    children: ReactNode;
    contextValue?: Record<string, unknown>;
    onStepChange?: (stepName: string) => void;
}

const InstructionTestingLayout = ({
    activeStep,
    steps = [],
    contextValue = {},
    onStepChange = () => {},
    children,
}: Params) => {
    const stepsList = useMemo(
        () => steps.map(
            (step) => (
                <UniversalPickerOption
                    key={`system-message-testing-step___${step.name}`}
                    id={`system-message-testing-step___${step.name}`}
                    name={step.name}
                    component={StepComponent}
                    disabled={step.disabled}
                >
                    <RenderIf isTrue={step.icon}>
                        {step.icon}
                    </RenderIf>
                    <div>
                        <StepLabel>{step.label}</StepLabel>
                        <RenderIf isTrue={step.description}>
                            <StepDescription>{step.description}</StepDescription>
                        </RenderIf>
                    </div>
                </UniversalPickerOption>
            ),
        ),
        [steps],
    );

    const handleStepChange = useCallback(
        async (newValue: string | string[]) => {
            const nextStep = (
                typeof newValue === 'string'
                    ? newValue
                    : newValue[0]
            );
            if (activeStep !== nextStep) {
                onStepChange(nextStep);
            }
        },
        [activeStep, onStepChange],
    );

    return (
        <Container>
            <Provider value={contextValue satisfies Context}>
                <LeftColumn>
                    <StyledUniversalPicker
                        direction="vertical"
                        value={activeStep}
                        onChange={handleStepChange}
                    >
                        {stepsList}
                    </StyledUniversalPicker>
                </LeftColumn>
                <MainContent>
                    {children}
                </MainContent>
            </Provider>
        </Container>
    );
};

export default InstructionTestingLayout;
