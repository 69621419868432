/* eslint-disable @typescript-eslint/no-empty-function */
import { User } from 'data/firestore/user/types';
import React from 'react';

export interface Context {
    user?: User | null;
    role?: string;
    isLoading?: boolean;
    isAdmin?: boolean;
}

const context = React.createContext<Context>({
    role: '',
    isLoading: false,
    isAdmin: false,
});

export const { Provider, Consumer } = context;

export default context;
