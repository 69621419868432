import { SortByPickerValue } from 'components/SortByPIcker';
import { Inbox } from 'data/firestore/inbox/types';
import { EntityGet } from 'data/firestore/types';

interface Args {
    sort: SortByPickerValue;
    tasks: EntityGet<Inbox>[];
}

const prioritySortMap = {
    low: 1,
    medium: 2,
    high: 3,
    urgent: 4,
};

const sortTasks = ({ sort, tasks }: Args) => {
    const sorted = [...tasks].sort((a, b) => {
        if (sort.sortBy === 'priority') {
            return prioritySortMap[a.priority] - prioritySortMap[b.priority];
        }
        if (sort.sortBy === 'createdAt') {
            return a.createdAt.getTime() - b.createdAt.getTime();
        }
        return a.title?.localeCompare(b.title || '') || 0;
    });

    return sort.order === 'asc' ? sorted : sorted.reverse();
};

export default sortTasks;
