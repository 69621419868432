import { doc, getDoc, getDocFromServer } from "firebase/firestore";
import { db } from "../../firebase";
import { GetServiceOpts, EntityGet, EntityServerGet } from "../../types";
import { Calendar } from "./types";
import { transformMetadata, processTimestampFields } from "../../utils";

export type CalendarGetService<T> = (
  agentId: string,
  id: string,
  opts?: GetServiceOpts
) => Promise<T | null>;

const get: CalendarGetService<EntityGet<Calendar>> = async (
  agentId,
  id,
  opts = {}
) => {
  const docRef = doc(db, `/agent/${agentId}/calendar/${id}`);
  const { disableCache } = opts;
  const getFn = disableCache ? getDocFromServer : getDoc;
  const docSnap = await getFn(docRef);
  if (docSnap.exists()) {
    return processTimestampFields(
      transformMetadata({
        ...docSnap.data(),
        id: docSnap.id,
      } as EntityServerGet<Calendar>)
    );
  }
  return null;
};

export default get;
