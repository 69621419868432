import { useState } from 'react';
import SearchIcon from 'components/icons/search';
import DatePickerFilter from 'components/DatePickerFilter';
import Footer from 'components/Footer';
import ConversationsList from './ConversationsList';
import {
    SearchContainer, SearchInput, StyledCard,
} from './styled';

const Page = () => {
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState<Date[]>([]);

    return (
        <>
            <StyledCard>
                <SearchContainer>
                    <SearchInput
                        type="search"
                        placeholder="Search conversation..."
                        variant="bare"
                        icon={<SearchIcon />}
                        borderRadius="semi-square"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                    <DatePickerFilter
                        placeholder="Select Date"
                        borderRadius="semi-square"
                        value={dateFilter}
                        onChange={setDateFilter}
                    />
                </SearchContainer>
                <ConversationsList search={search} dateFilter={dateFilter} />
            </StyledCard>
            <Footer />
        </>
    );
};

export default Page;
