import { useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import RedioGroupWithCustomValue, { RedioGroupWithCustomValueProps } from 'components/RedioGroupWithCustomValue';

export interface ReminderValue {
    when: number;
    relativeTo: 'event-time' | 'creation-time';
}

interface ReminderTimeSelectProps extends RedioGroupWithCustomValueProps<ReminderValue> {}

const options = [
    {
        name: 'inmmediately',
        label: 'Immediately after the event is booked',
        value: {
            when: 0,
            relativeTo: 'creation-time',
        },
    },
    {
        name: '24-before',
        label: '24 hours before the event start',
        value: {
            when: 1440,
            relativeTo: 'event-time',
        },
    },
    {
        name: '48-before',
        label: '48 hours before the event start',
        value: {
            when: 2880,
            relativeTo: 'event-time',
        },
    },
    {
        name: '72-before',
        label: '72 hours before the event start',
        value: {
            when: 4320,
            relativeTo: 'event-time',
        },
    },
];

const findValue = (val: { value: ReminderValue; }) => {
    if (val?.value) {
        const { value } = val;
        return options.find(
            ({ value: optionValue }) => (
                optionValue.when === value.when && optionValue.relativeTo === value.relativeTo
            ),
        );
    }
    return undefined;
};

const ReminderTimeSelect = (props: ReminderTimeSelectProps) => {
    const {
        value: valueInProps,
        ...otherProps
    } = useReduxForm(props);
    const radioGroupValue = useMemo(
        () => findValue(valueInProps as { value: ReminderValue; }),
        [valueInProps],
    );
    return (
        <RedioGroupWithCustomValue
            {...otherProps}
            value={radioGroupValue}
            options={options}
        />
    );
};

export default ReminderTimeSelect;
