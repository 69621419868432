import { ReactNode, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import Dot from 'components/Dot';
import Camera from 'components/icons/camera';
import Video from 'components/icons/video2';
import UserAvatar from 'components/UserAvatar';
import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import { formatRelativeDate } from 'data/services/date/formatter';
import getDisplayName from 'data/services/profile/getDisplayName';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getInitials from 'data/services/string/getInitials';
import {
    DateLabel,
    NotificationContent,
    NotificationContainer,
    NotidicationTitle,
    NotidicationText,
} from './styled';

const mediaTypeIconMap: Record<string, ReactNode> = {
    photo: <Camera />,
    video: <Video />,
};

interface CustomerMessageProps {
    customerData?: EntityGet<Customer>;
    onClick?: ((customer: EntityGet<Customer>) => void);
}

const CustomerMessage: React.FC<CustomerMessageProps> = ({
    customerData,
    onClick,
}: CustomerMessageProps) => {
    const handleClick = () => {
        if (onClick && customerData) {
            onClick(customerData);
        }
    };

    const customerDisplayName = useMemo(
        () => getDisplayName(
            customerData,
            formatIntlPhoneNumber(customerData?.phoneNumber as string),
        ),
        [customerData],
    );

    const formattedDate = useMemo(
        () => {
            if (!customerData?.lastMessage?.createdAt) return null;
            return formatRelativeDate((new Date(customerData?.lastMessage?.createdAt)));
        },
        [customerData],
    );

    const mediaType = useMemo(() => {
        const { contentType = '' } = customerData?.lastMessage?.media?.at(0) || {};
        if (/image/gi.test(contentType)) return 'photo';
        if (/video/gi.test(contentType)) return 'video';
        return '';
    }, [customerData]);

    const icon = useMemo(
        () => mediaTypeIconMap[mediaType],
        [mediaType],
    );

    const text = useMemo(
        () => {
            if (customerData?.lastMessage?.text) return customerData?.lastMessage?.text;
            if (mediaType) {
                return `${mediaType.at(0)?.toUpperCase()}${mediaType.slice(1)}`;
            }
            return '';
        },
        [mediaType, customerData],
    );

    return (
        <NotificationContainer onClick={handleClick}>
            <UserAvatar
                initials={getInitials(customerData as unknown as Record<string, unknown>)}
                value={customerData?.id}
                src={customerData?.photoURL}
            />
            <NotificationContent>
                <div className="rainbow-flex rainbow-justify_spread">
                    <NotidicationTitle>{customerDisplayName}</NotidicationTitle>
                    <DateLabel>{formattedDate}</DateLabel>
                </div>
                <div className="rainbow-flex rainbow-justify_spread rainbow-align_center">
                    {icon}
                    <NotidicationText hasIcon={!!icon}>
                        {text}
                    </NotidicationText>
                    <RenderIf isTrue={customerData?.hasNewMessage}>
                        <Dot variant="brand" />
                    </RenderIf>
                </div>
            </NotificationContent>
        </NotificationContainer>
    );
};
export default CustomerMessage;
