import {
    ReactNode,
    useCallback, useRef, useState,
} from 'react';
import SearchIcon from 'components/icons/search';
import { FloatingSearch } from '@rainbow-modules/listview';
import SearchButton from './searchButton';

const FloatingFilterButton = ({
    value,
    icon = <SearchIcon />,
    onChange = () => {},
}: {
    value?: string;
    icon?: ReactNode;
    onChange?: (newValue: string) => void;
}) => {
    const triggerRef = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen, setOpen]);

    const handleCloseSearch = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <SearchButton
                ref={triggerRef}
                icon={icon}
                value={value}
                onClick={toggleOpen}
            />
            <FloatingSearch
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => triggerRef.current.htmlElementRef}
                id="search"
                value={value}
                onChange={onChange}
                onRequestClose={handleCloseSearch}
            />
        </>
    );
};

export default FloatingFilterButton;
