import { IconProps } from 'types';

const ArrowsDiagonal = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M16 4H20V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 10L20 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 20H4V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 20L10 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default ArrowsDiagonal;
