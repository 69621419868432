import styled from 'styled-components';
import Input from 'components/Input';
import { GREY_300 } from '../../../constants';

export const Container = styled.div`
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 2rem 1rem 1rem 1.25rem;
    height: fit-content;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    margin-top: -2rem;
    z-index: 1;
`;

export const EntriesText = styled.span`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${GREY_300};
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
`;

export const SearchInput = styled(Input)`
    flex: 1;
`;

export const CellWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const NameCellWrapper = styled(CellWrapper)`
    padding: 10px 10px 10px 20px;
`;

export const ModelCellWrapper = styled(CellWrapper)`
    gap: 0.5rem;
`;
