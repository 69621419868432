import UserColumn from 'pages/Customers/CustomersList/columns/user';

interface Props {
    value?: {
        createdBy: string;
    };
}

const UploadedBy = ({ value }: Props) => {
    const { createdBy = '{}' } = value || {};
    const data = JSON.parse(createdBy);
    return <UserColumn row={data} disableLink />;
};

export default UploadedBy;
