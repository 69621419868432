import { IconProps } from 'types';

const Email = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        width={24}
        height={24}
    >
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4.04262 6.49324C4.28344 5.07771 5.51586 4 7 4H17C18.4841 4 19.7166 5.07772 19.9574 6.49326L12 11.7982L4.04262 6.49324ZM4 8.86853V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V8.86855L12.5547 13.8321C12.2188 14.056 11.7812 14.056 11.4453 13.8321L4 8.86853Z" fill="currentColor" />

    </svg>
);
export default Email;
