import {
    PURPLE_500, RED_500, GREY_200, GREEN_500,
} from '../../../constants';

export const statusLabelMap: Record<string, string> = {
    'total-bookings': 'Total bookings',
    'pending-approval': 'Pending Approval',
    rejected: 'Rejected',
    accepted: 'Accepted',
};

export const statusColorMap: Record<string, string> = {
    'total-bookings': PURPLE_500,
    accepted: GREEN_500,
    rejected: RED_500,
    'pending-approval': GREY_200,
};
