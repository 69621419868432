import { IconProps } from 'types';

const Checks = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M7 12L12 17L22 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.53033 11.4697C2.23744 11.1768 1.76256 11.1768 1.46967 11.4697C1.17678 11.7626 1.17678 12.2374 1.46967 12.5303L2.53033 11.4697ZM6.46967 17.5303C6.76256 17.8232 7.23744 17.8232 7.53033 17.5303C7.82322 17.2374 7.82322 16.7626 7.53033 16.4697L6.46967 17.5303ZM11.4697 11.4697C11.1768 11.7626 11.1768 12.2374 11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303L11.4697 11.4697ZM17.5303 7.53033C17.8232 7.23744 17.8232 6.76256 17.5303 6.46967C17.2374 6.17678 16.7626 6.17678 16.4697 6.46967L17.5303 7.53033ZM1.46967 12.5303L6.46967 17.5303L7.53033 16.4697L2.53033 11.4697L1.46967 12.5303ZM12.5303 12.5303L17.5303 7.53033L16.4697 6.46967L11.4697 11.4697L12.5303 12.5303Z" fill="currentColor" />
    </svg>
);
export default Checks;
