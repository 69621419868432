import createDownloadLink from './createDownloadLink';

const extractColumnsFromData = (data: Record<string, unknown>[] = []): string[] => Array.from(
    data.reduce(
        (columns, row) => new Set([...Array.from(columns), ...Object.keys(row)]),
        new Set(),
    ),
) as string[];

const exportCSV = ({
    columns = [],
    data = [],
}: {
    columns?: string[];
    data?: Record<string, unknown>[];
}) => {
    const header = (
        columns.length > 0
            ? columns
            : extractColumnsFromData(data)
    );

    const result = [];

    result.push(header.join(','));

    data.forEach(
        (row) => {
            result.push(
                header.reduce(
                    (values: string[], column: string) => [...values, (row[column] || '') as string],
                    [],
                ).join(','),
            );
        },
    );

    createDownloadLink(result.join('\n'), 'affiliates_report.csv');
};

export default exportCSV;
