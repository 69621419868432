import styled, { css } from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import { color, shadow } from 'theme';

export const StyledButtonIcon = styled(ButtonIcon)<{ dark?: boolean }>`
    width: 1.25rem;
    height: 1.25rem;

    ${(props) => props.dark && css`
        color: #fff;
    `}

    svg {
        width: 14px !important;
        height: 14px !important;
    }
`;

export const StyledVolumeContainer = styled.div`
    display: flex;
    background-color: rgba(74, 68, 88, 0.8);
    border-radius: 8px;
    height: 20px;
    padding: 0 3px;
    width: 70px;
`;

export const StyledRateContainer = styled.div`
    background-color: rgba(74, 68, 88, 0.8);
    border-radius: 8px;
    width: 100%;
    height: 100%;
`;

export const StyledRateUl = styled.ul`
    color: #fff;
    text-align: center;
    padding: 5px 0;
`;

interface RateLiProps {
    active: boolean;
}

export const StyledRateLi = styled.li<RateLiProps>`
    width: 70px;
    ${(props) => props.active && css`
        background-color: rgb(74, 68, 88);
    `}
    &:hover {
        background-color: rgba(74, 68, 88, 0.9);
    }
`;

export const StyledInputRange = styled.input`
    appearance: none;
    width: 100%;
    margin: 0.5rem 0;
    background: transparent;
    border-radius: 0.4rem;
    box-sizing: border-box;
    color: inherit;
    font: inherit;
    line-height: normal;
    flex: 1;

    ::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
        border: 0;
        box-shadow: ${shadow('shadow_1')};
        cursor: pointer;
        transition: all 0.3s ease 0s;
        margin-top: -1px;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.37);
        border-radius: 16px;
    }

    &::-moz-range-thumb {
        appearance: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
        border: 0;
        box-shadow: ${shadow('shadow_1')};
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;
        margin-top: -1px;
    }

    &::-moz-range-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.37);
        border-radius: 16px;
    }

    &::-ms-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        border-radius: 0.4rem;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-thumb {
        width: 6px;
        height: 6px;
        border-radius: 12rem;
        background-color: #fff;
        border: 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0 2px 3px;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;
        margin-top: -1px;
    }

    &:focus {
        outline: 0;
    }

    &::-webkit-slider-thumb:hover {
        background-color: #F6F7F9;
    }

    &::-moz-range-thumb:hover {
        background-color: #F6F7F9;
    }

    &::-ms-thumb:hover {
        background-color: #F6F7F9;
    }

    &:focus::-webkit-slider-thumb {
        background-color: #fff;
        border: 1px solid ${color('brand.main')};
        box-shadow: ${shadow('brand')};
    }

    &:active::-webkit-slider-thumb {
        background-color: #fff;
        transition: all 0.3s ease 0s;
        transform: scale3d(1.5, 1.5, 1);
    }

    &:focus::-moz-range-thumb {
        background-color: #fff;
        border: 1px solid ${color('brand.main')};
        box-shadow: ${shadow('brand')};
    }

    &:active::-moz-range-thumb {
        background-color: #fff;
    }
`;
