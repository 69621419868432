import { CellWrapper } from './styled';

interface Props {
    value?: string;
}

const SecretKey = ({ value }: Props) => (
    <CellWrapper>
        {value}
        {'•'.repeat(5)}
    </CellWrapper>
);
export default SecretKey;
