/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'else',
    body: '{{else}}',
    documentation: 'else statement',
};

export default snippet;
