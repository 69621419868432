import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import ZoomableImage from 'components/ZoomableImage';
import { GREY_300, GREY_400 } from '../../../constants';

export const FilesListContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    max-height: 200px;
    overflow-y: auto;
`;

export const FileContainer = styled.div`
    position: relative;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageContainer = styled.div`
    position: relative;
`;

export const Label = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    margin-top: 0.25rem;
    color: ${GREY_400}
`;

export const SizeLabel = styled.span`
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    color: ${GREY_300};
`;

export const StyledZoomableImage = styled(ZoomableImage)`
    border-radius: 0.375rem;
    align-self: center;
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
`;

export const DeleteButton = styled(ButtonIcon)`
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0;
    right: 0;

    svg {
        width: 0.5rem;
        height: 0.5rem;    
    }
`;
