import { IconProps } from 'types';

const GaugeIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 23"
        width={22}
        height={23}
        fill="none"
    >
        <title>{title}</title>
        <circle cx="11" cy="11.3135" r="8.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="11" cy="11.3135" r="8.25" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="11" cy="11.3134" r="0.916667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="11" cy="11.3134" r="0.916667" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.2925 10.0209L14.6667 7.64673" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.2925 10.0209L14.6667 7.64673" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.41669 11.3136C6.41669 8.78225 8.46872 6.73022 11 6.73022" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.41669 11.3136C6.41669 8.78225 8.46872 6.73022 11 6.73022" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default GaugeIcon;
