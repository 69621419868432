import { useMemo } from 'react';
import {
    Label, LabelsContainer, Percent, StageName, Total,
} from './styled';

interface FunnelLabelsProps {
    data?: Record<string, unknown>[];
}

const FunnelLabels = ({
    data = [],
}: FunnelLabelsProps) => {
    const labels = useMemo(
        () => data.map(
            (item) => (
                <Label key={`${item.id}`}>
                    <Total>{`${item.value}`}</Total>
                    <StageName>{`${item.label}`}</StageName>
                    <Percent>{`${(item.percent as number).toFixed(2)}%`}</Percent>
                </Label>
            ),
        ),
        [data],
    );

    return (
        <LabelsContainer>
            {labels}
        </LabelsContainer>
    );
};

export default FunnelLabels;
