import { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { Textarea } from 'react-rainbow-components';
import { composeValidators } from '@rainbow-modules/forms';
import Input from 'components/Input';
import { FieldsContainer, FieldSection } from './styled';
import validateIfCondition from './validation/validdateIfCondition';
import validateNotEmpty from './validation/validateNotEmpty';
import validateConversation from './validation/validateConversation';

const textAreaPlaceHolder = `
assistant: Hello
user: Hi
assistant: How can I help you?
user: I need help with my order
`.trim();

const Fields = () => {
    const { values } = useFormState();
    const { resetFieldState } = useForm();

    useEffect(
        () => {
            const fieldName = (values.importFrom === 'text' ? 'sessionId' : 'conversation');
            resetFieldState(fieldName);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.importFrom],
    );

    return (
        <FieldsContainer>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Input}
                name="importFrom"
                type="radio"
                value="text"
                label="From text"
            />
            <FieldSection>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Textarea}
                    name="conversation"
                    placeholder={textAreaPlaceHolder}
                    borderRadius="semi-square"
                    rows={8}
                    disabled={values.importFrom !== 'text'}
                    validate={validateIfCondition(
                        values.importFrom === 'text',
                        composeValidators(
                            validateNotEmpty('This field is required'),
                            validateConversation('Invalid format'),
                        ),
                    )}
                />
            </FieldSection>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Input}
                name="importFrom"
                type="radio"
                value="session"
                label="From existing conversation"
            />
            <FieldSection>
                <Field
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Input}
                    type="text"
                    name="sessionId"
                    placeholder="Enter existing conversation ID..."
                    borderRadius="semi-square"
                    disabled={values.importFrom !== 'session'}
                    validate={validateIfCondition(
                        values.importFrom === 'session',
                        validateNotEmpty('This field is required'),
                    )}
                />
            </FieldSection>
        </FieldsContainer>
    );
};

export default Fields;
