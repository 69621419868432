import { PostCallTranscriptHighlight } from '../types';

interface HighlightResult {
    text: string;
    highlighted: boolean;
    type?: string;
}

const extractHighlightedText = (
    originalText: string,
    highlights: PostCallTranscriptHighlight[],
): HighlightResult[] => {
    if (highlights.length > 0) {
        let lastPos = 0;
        const parts = highlights.reduce(
            (result, highlight) => {
                const {
                    start, end, text: highlightedText, label,
                } = highlight as PostCallTranscriptHighlight;

                if (start > lastPos) {
                    const text = originalText.slice(lastPos, start);
                    if (text) {
                        result.push({
                            text,
                            highlighted: false,
                        });
                    }
                }

                result.push({
                    text: highlightedText,
                    highlighted: true,
                    type: label,
                });
                lastPos = end + 1;

                return result;
            },
            [] as HighlightResult[],
        );

        if (lastPos < originalText.length) {
            parts.push({
                text: originalText.slice(lastPos),
                highlighted: false,
            });
        }

        return parts;
    }

    return [{ text: originalText, highlighted: false }];
};

export default extractHighlightedText;
