import {
    ComponentType, useCallback, useMemo, useState,
} from 'react';
import ListIcon from 'components/icons/list';
import PaperIcon from 'components/icons/paper';
import ChatIcon from 'components/icons/chat2';
import SettingIcon from 'components/icons/setting';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { InstructionTestStepComponentProps } from 'components/InstructionTestingLayout/types';
import { InstructionTestComponentProps } from 'components/InstructionTestingModal/types';
import InstructionTestingLayout from 'components/InstructionTestingLayout';
import StepCompileSystemMessage from '../views/systemMessage';
import StepContext from '../views/context';
import StepUseCase from '../views/useCase';
import StepAgentChat from './agentChat';

const stepComponentMap: Record<string, ComponentType<InstructionTestStepComponentProps>> = {
    'use-case': StepUseCase,
    context: StepContext,
    'system-message': StepCompileSystemMessage,
    chat: StepAgentChat,
};

const steps = [
    {
        name: 'use-case',
        label: 'Use Case',
        icon: <SettingIcon />,
    },
    {
        name: 'context',
        label: 'Context',
        icon: <ListIcon />,
    },
    {
        name: 'system-message',
        label: 'System Message',
        icon: <PaperIcon />,
    },
    {
        name: 'chat',
        label: 'Chat',
        icon: <ChatIcon />,
    },
];

interface Params extends InstructionTestComponentProps {}

const MainInstructionTesting = ({ template, agentId }: Params) => {
    const [currentStep, setCurrentStep] = useState('use-case');
    const [contextValue, setContextValue] = useState({});

    const {
        mutateAsync: compileTemplate,
    } = useHttpMutation<Record<string, unknown>, { result: string }>({
        pathname: `/agents/${agentId}/system-message/validate`,
        method: 'POST',
    });

    const handleStepUpdate = useCallback(
        (stepName: string) => async (values: Record<string, unknown>) => {
            const newContext: Record<string, unknown> = contextValue;

            if (stepName === 'use-case') {
                const { context, formValues } = values;
                newContext.useCaseValues = formValues;
                newContext.templateContext = context;
                newContext.compiledTemplate = '';
                try {
                    const { result } = await compileTemplate({
                        body: {
                            template,
                            context,
                            agentId,
                        },
                    });
                    newContext.compiledTemplate = result;
                } catch (error) {
                    // No catch
                }
            }

            if (stepName === 'chat') {
                const { messages } = values;
                newContext.chatMessages = messages;
            }
            setContextValue(newContext);
        },
        [agentId, compileTemplate, contextValue, template],
    );

    const StepComponent = useMemo(() => stepComponentMap[currentStep], [currentStep]);

    return (
        <InstructionTestingLayout
            activeStep={currentStep}
            steps={steps}
            onStepChange={setCurrentStep}
            contextValue={contextValue}
        >
            <StepComponent
                agentId={agentId}
                systemMessageTemplate={template}
                onChange={handleStepUpdate(currentStep)}
            />
        </InstructionTestingLayout>
    );
};

export default MainInstructionTesting;
