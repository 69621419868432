import styled from 'styled-components';
import { AccordionSection } from 'react-rainbow-components';

export default styled(AccordionSection)`
    button {
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding: 1.125rem 0;

        :focus {
            box-shadow: none;
        }

        > h3 {
            padding-right: 0;
        }
    }


    section {
        border: none;
        box-shadow: none;
    }
`;
