import EditForm from './editMode';
import CreateForm from './createMode';
import { BaseFormProps } from './types';

interface FormProps extends BaseFormProps {
    mode?: 'create' | 'edit';
}

const CreateEditCustomerForm = ({ mode = 'create', ...rest }: FormProps) => {
    if (mode === 'edit') {
        return <EditForm {...rest} />;
    }
    return <CreateForm {...rest} />;
};

export default CreateEditCustomerForm;
