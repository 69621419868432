import { isEmpty } from '@rainbow-modules/validation';
import getSafeString from '../string/getSafeString';

export default function getDisplayName(profile: unknown, fallBackValue: string = 'Unknown'): string {
    const data = (profile as Record<string, string | null>) || {};
    const firstName = getSafeString(data.firstName);
    const lastName = getSafeString(data.lastName);
    const alias = getSafeString(data.alias);
    const displayName = getSafeString(data.displayName);
    const fullName = [firstName, lastName].join(' ').trim();

    if (fullName && alias.trim()) return `${fullName} (${alias})`;

    if (fullName) return fullName;

    if (alias.trim()) return alias as string;

    if (displayName.trim()) return displayName as string;

    if (!isEmpty(getSafeString(fallBackValue).trim())) return fallBackValue;

    return 'Unknown';
}
