import { ComponentType } from 'react';
import Button from 'components/Button';
import {
    FieldsContainer, FormFooter, StyledModal, StyledUniversalForm, ModalTitle,
} from './styled';
import Fields from './fields';
import { ModelConfig } from '../types';
import isBuiltinModel from '../helpers/isBuiltinModel';

interface AiModelConfigFormProps {
    initialValues?: any;
    onSubmit?: (values: ModelConfig) => void;
    onRequestClose: () => void;
    fieldsComponent?: ComponentType;
}

const getModel = (modelId: string = '') => {
    if (isBuiltinModel(modelId)) {
        if (modelId.indexOf('gpt-3.5') > 0) return modelId;
        return 'gpt-4';
    }

    return 'fine-tuned';
};

const getModelId = (modelId: string = '') => {
    if (isBuiltinModel(modelId) && modelId.startsWith('gpt-3.5')) return '';
    return modelId;
};

interface FormValues extends Record<string, unknown> {
    temperature: string | number;
    model: string;
    modelId?: string
}

const mapInitialValuesToFormValues = (initialValues: any): FormValues => {
    const { model, temperature, ...rest } = initialValues;
    return {
        temperature,
        model: getModel(model.id),
        modelId: getModelId(model.id),
        ...rest,
    };
};

const mapFormValuesToConfig = (currentValues: FormValues): ModelConfig => {
    const {
        temperature, model, modelId, ...rest
    } = currentValues;
    return {
        model: {
            id: modelId || model,
            name: modelId || model,
        },
        temperature: parseFloat(temperature as string),
        ...rest,
    };
};

const AiModelConfigForm = ({
    initialValues = {},
    onSubmit = () => {},
    onRequestClose = () => {},
    fieldsComponent: FieldsComponent = Fields,
}: AiModelConfigFormProps) => (
    <StyledModal
        size="large"
        onRequestClose={onRequestClose}
        isOpen
    >
        <StyledUniversalForm
            onSubmit={(values) => onSubmit(mapFormValuesToConfig(values as FormValues))}
            initialValues={mapInitialValuesToFormValues({
                temperature: 1,
                model: {
                    id: 'gpt-3.5-turbo-0125',
                    name: 'gpt-3.5-turbo-0125',
                },
                ...initialValues,
            })}
        >
            <ModalTitle>Configuration</ModalTitle>
            <FieldsContainer>
                <FieldsComponent />
            </FieldsContainer>
            <FormFooter>
                <Button
                    label="Cancel"
                    variant="outline-brand"
                    borderRadius="semi-square"
                    onClick={onRequestClose}
                />
                <Button
                    type="submit"
                    label="Save"
                    variant="brand"
                    borderRadius="semi-square"
                />
            </FormFooter>
        </StyledUniversalForm>
    </StyledModal>
);

export default AiModelConfigForm;
