import { useMemo } from 'react';
import { FilesListContainer } from './styled';
import AttachedFile from './file';

interface AttachedFilesProps {
    files?: File[];
    onRemoveFile?: (index: number) => void;
}

const AttachedFiles = ({
    files = [],
    onRemoveFile = () => {},
}: AttachedFilesProps) => {
    const filesList = useMemo(
        () => files.map(
            (file: File, index: number) => {
                const key = `${file.name}__${index}`;
                return (
                    <AttachedFile
                        key={key}
                        file={file}
                        onRemoveClick={() => onRemoveFile(index)}
                    />
                );
            },
        ),
        [files, onRemoveFile],
    );

    return (
        <FilesListContainer>{filesList}</FilesListContainer>
    );
};

export default AttachedFiles;
