import { IconProps } from 'types';

const CircleIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={22}
        height={11}
        fill="none"
    >
        <title>{title}</title>
        <circle
            cx="11"
            cy="5.41211"
            r="3.75"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

);
export default CircleIcon;
