/* eslint-disable arrow-body-style */
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import Button from 'components/Button';
import useCalendars from 'data/firestore/agent/calendar/useCollection';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import LoadingShape from '../loading';
import { StyledEmptyMessage } from '../styled';
import {
    Container, Header, Description, Title, TitleContainer, Content,
} from './styled';
import CalendarItem from './item';

const CalendarsList = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();

    const { data = [], isLoading } = useCalendars(agentId, {
        disabled: !agentId,
        track: [agentId],
    });

    const calendarsList = useMemo(
        () => {
            if (data.length === 0) {
                return (
                    <StyledEmptyMessage message="No calendars have been added yet." />
                );
            }

            return data.map(
                (calendar) => (
                    <CalendarItem
                        key={calendar.id}
                        name={calendar.name}
                        color={calendar.color}
                        onEdit={() => navigate(`/${agentId}/calendar/edit/${calendar.id}`)}
                    />
                ),
            );
        },
        [agentId, data, navigate],
    );
    return (
        <Container>
            <Header>
                <TitleContainer>
                    <Title>Calendars</Title>
                    <Description>
                        Organize your schedule by creating multiple calendars,
                        each with its unique settings and purposes to fit different
                        scheduling needs.
                    </Description>
                </TitleContainer>
                <Button
                    variant="outline-brand"
                    borderRadius="semi-square"
                    label="New Calendar"
                    onClick={() => navigate(`/${agentId}/calendar/create`)}
                />
            </Header>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <LoadingShape />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    {calendarsList}
                </RenderIf>
            </Content>
        </Container>
    );
};

export default CalendarsList;
