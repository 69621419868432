/* eslint-disable @typescript-eslint/no-empty-function */
import { Agent } from 'data/firestore/agent/types';
import { EntityGet } from 'data/firestore/types';
import React from 'react';

export interface Context {
    agent?: EntityGet<Agent> | null;
    isLoading?: boolean;
}

const context = React.createContext<Context>({
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
