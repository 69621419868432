import { Customer } from 'data/firestore/agent/customer/types';
import { EntityGet } from 'data/firestore/types';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface Context {
    smsChatProps: Record<string, unknown>;
    customerPanelProps: {
        isOpen?: boolean;
        data?: EntityGet<Customer>;
    },
    algoliaSecureKeys: {
        sessions?: string;
        inbox?: string;
        blockednumbers?: string;
        customers?: string;
        affiliates?: string;
        // inbox_createdAt_desc?: string;
        // inbox_createdAt_asc?: string;
        // inbox_priority_desc?: string;
        // inbox_priority_asc?: string;
    },
    setAlgoliaSecureKeys: Dispatch<SetStateAction<Context['algoliaSecureKeys']>>;
}

const context = createContext<Context>({
    smsChatProps: {},
    customerPanelProps: {},
    algoliaSecureKeys: {},
    setAlgoliaSecureKeys: () => {},
});

export const { Provider, Consumer } = context;

export default context;
