import { IconProps } from 'types';

const ArrowRight = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M13 20L21 12M21 12L13 4M21 12L3 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default ArrowRight;
