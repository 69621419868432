/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const greaterThanEqual: Snippet = {
    label: 'greaterThanEqual',
    body: '{{#greaterThanEqual ${1:value1} ${2:value2}}}\n\t${0}\n{{/greaterThanEqual}}',
    documentation: 'greaterThanEqual statement',
};

export default greaterThanEqual;
