import { ReactNode } from 'react';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import {
    SummaryItemContainer, InfoContainer, IconContainer, Value, Label,
} from './styled';

interface SummaryAffiliatesItemProps {
    value: string | number;
    label: string;
    icon: ReactNode;
    percentLabel?: ReactNode | string | number;
    isLoading?: boolean;
}

const SummaryAffiliatesItem = ({
    value, label, icon, isLoading, percentLabel,
}: SummaryAffiliatesItemProps) => (
    <SummaryItemContainer>
        <RenderIf isTrue={isLoading}>
            <LoadingShape shape="square" />
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
            <IconContainer className="icon">{icon}</IconContainer>
            <InfoContainer>
                <Value>
                    {value}
                    <RenderIf isTrue={percentLabel}>
                        {percentLabel}
                    </RenderIf>
                </Value>
                <Label>{label}</Label>
            </InfoContainer>
        </RenderIf>
    </SummaryItemContainer>
);

export default SummaryAffiliatesItem;
