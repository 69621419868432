import { useEffect, useMemo, useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import Close from 'components/icons/close';
import fileSizeFormatter from 'data/services/size/formatter';
import {
    FileContainer, Label, SizeLabel, StyledZoomableImage, DeleteButton, ImageContainer,
} from './styled';
import getFileBlob from '../helpers/getFileBlob';

interface AttachedFileProps {
    file: File,
    onRemoveClick?: () => void;
}

const AttachedFile = ({
    file,
    onRemoveClick = () => {},
}: AttachedFileProps) => {
    const [url, setUrl] = useState<string>('');
    const [deleteButtonVisible, setDeleteButtonVisible] = useState<boolean>(false);

    const formattedFilename = useMemo(
        () => {
            const fileName = file.name;
            if (file.name.length > 15) {
                return `${fileName.slice(0, 5)}...${fileName.slice(-7)}`;
            }
            return fileName;
        },
        [file],
    );

    const formattedFileSize = useMemo(
        () => fileSizeFormatter(file.size),
        [file.size],
    );

    useEffect(
        () => {
            (async () => {
                const blob = await getFileBlob(file);
                setUrl(URL.createObjectURL(blob));
            })();
        },
        [file],
    );

    return (
        <FileContainer
            onMouseEnter={() => setDeleteButtonVisible(true)}
            onMouseLeave={() => setDeleteButtonVisible(false)}
        >
            <ImageContainer>
                <StyledZoomableImage
                    src={url || ''}
                    alt={file.name}
                    width={80}
                    height={80}
                />
                <RenderIf isTrue={deleteButtonVisible}>
                    <DeleteButton
                        variant="destructive"
                        size="xx-small"
                        icon={<Close />}
                        onClick={onRemoveClick}
                    />
                </RenderIf>
            </ImageContainer>
            <Label>{formattedFilename}</Label>
            <SizeLabel>{formattedFileSize}</SizeLabel>
        </FileContainer>
    );
};

export default AttachedFile;
