import styled from 'styled-components';
import { GREY_400 } from '../../../../constants';

export const CellWrapper = styled.div<{ align?: string; }>`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${GREY_400};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: capitalize;
    justify-content: ${(props) => props.align || 'flex-start'};
    gap: 0.5rem;
`;

export const ValueLabel = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    color: #2E2D34;
`;

export const PercentLabel = styled.span`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem);
    color: #88888D;
`;
