import styled from 'styled-components';
import { GREY_500 } from '../../../../constants';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
`;

export const Title = styled.h1`
    font-size: 1.625rem;
    font-weight: 400;
    color: ${GREY_500};
    text-align: center;
    margin-bottom: 1rem;
`;
