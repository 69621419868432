const getRightSidebarTitle = (
    {
        isHistoryOpen,
        isHelpOpen,
        isModelConfigOpen,
    }: {
        isHistoryOpen: boolean;
        isHelpOpen: boolean;
        isModelConfigOpen: boolean;
    },
) => {
    if (isHistoryOpen) {
        return 'History';
    }
    if (isHelpOpen) {
        return 'Help';
    }
    if (isModelConfigOpen) {
        return 'AI Model Config';
    }
    return '';
};

export default getRightSidebarTitle;
