import { useOpenModal } from '@rainbow-modules/hooks';
import { EntityGet } from 'data/firestore/types';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { OPPORTUNITY_DETAILS_DRAWER } from '../../../../../constants';
import {
    OpportunityCellWrapper, StyledProgressCircular, CellLabel, LinkButton,
} from './styled';
import getVariantFromScore from '../../../helpers/getVariantFromScore';

interface Props {
    row?: EntityGet<Opportunity>;
    value?: string;
}

const OpportunityColumn = ({ row, value }: Props) => {
    const [openModal] = useOpenModal(OPPORTUNITY_DETAILS_DRAWER);
    return (
        <OpportunityCellWrapper>
            <StyledProgressCircular
                variant={getVariantFromScore(row?.score)}
                value={row?.score || 0}
            />
            <LinkButton
                type="button"
                onClick={() => openModal({
                    data: row?.id,
                })}
            >
                <CellLabel>
                    {value}
                </CellLabel>
            </LinkButton>
        </OpportunityCellWrapper>
    );
};

export default OpportunityColumn;
