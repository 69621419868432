import { IconProps } from 'types';

const MinusCircle = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width={22} height={22} viewBox="0 0 22 22" fill="none">
        <title>{title}</title>
        <path
            d="M11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.75329 20.5 11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5ZM0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11Z"
            fill="currentColor"
        />
        <path
            d="M6.5 11C6.5 10.7239 6.72386 10.5 7 10.5H15C15.2761 10.5 15.5 10.7239 15.5 11C15.5 11.2761 15.2761 11.5 15 11.5H7C6.72386 11.5 6.5 11.2761 6.5 11Z"
            fill="currentColor"
        />
    </svg>
);

export default MinusCircle;
