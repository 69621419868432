import { Field, isRequired } from '@rainbow-modules/forms';
import Input from 'components/Input';
import ModalBody from 'components/ModalBody';
import { ModalDescriptionContainer } from './styled';

const Fields = () => (
    <ModalBody
        title="Add Number to Blocked List"
        description={(
            <ModalDescriptionContainer>
                Enter the phone number you wish to block.
                This number will be unable to contact you through our service.
                <Field
                    name="phoneNumber"
                    component={Input}
                    label="Phone Number"
                    placeholder="Enter a phone number"
                    validate={isRequired()}
                    labelAlignment="left"
                    borderRadius="semi-square"
                    required
                />
            </ModalDescriptionContainer>
        )}
    />
);

export default Fields;
