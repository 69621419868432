import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1.625rem;
    align-self: stretch;
    flex-grow: 1;
`;

export const Column = styled.div`
    width: 100%;
    padding: 0;
`;

export const FieldContainer = styled.div`
    margin-bottom: 1rem;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

export const ModalIconContainer = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
    background-color: ${color('brand.light')};
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 2.5rem;
        height: 2.5rem;
        color: ${color('brand.main')};
    }
`;

export const ModalTitle = styled.h1`
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
    text-align: center;
`;

export const ModalDescription = styled.p`
    font-size: 0.8rem;
    text-align: center;
    color: ${color('text.header')};
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 121px);
`;
