import { IconProps } from 'types';

const Copy = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={20}
        height={20}
        fill="none"
    >
        <title>{title}</title>
        <rect
            width={10}
            height={10}
            x={6.667}
            y={6.668}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            rx={2}
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13.333 6.665V5c0-.92-.746-1.667-1.666-1.667H5c-.92 0-1.667.746-1.667 1.667v6.666c0 .92.747 1.667 1.667 1.667h1.667"
        />
    </svg>
);

export default Copy;
