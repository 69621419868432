import {
    Avatar as RainbowAvatar, MenuDivider, MenuItem,
} from 'react-rainbow-components';
import { usePostHog } from 'posthog-js/react';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { User } from 'data/firestore/user/types';
import { analytics, auth } from '../../firebase';
import Logout from '../icons/logout';
import {
    UserName, UserEmail, StyledAvatarMenu, UserInformation,
} from './styled';

const Avatar = ({ user }: { user: User }) => {
    const { displayName, email, photoURL } = user;
    const posthog = usePostHog();

    const logout = () => {
        auth.signOut();
        posthog?.reset();
        setUserId(analytics, null);
        setUserProperties(analytics, {
            name: null,
            email: null,
        });
    };

    return (
        <StyledAvatarMenu
            src={photoURL}
            assistiveText={displayName}
            menuAlignment="right"
            menuSize="small"
            avatarSize="medium"
            title={displayName}
        >
            <UserInformation>
                <RainbowAvatar
                    src={photoURL}
                    assistiveText={displayName}
                    title={displayName}
                    size="medium"
                />
                <div className="rainbow-m-left_x-small">
                    <UserName>{displayName}</UserName>
                    <UserEmail>
                        {email}
                    </UserEmail>
                </div>
            </UserInformation>
            <MenuDivider variant="space" />
            <MenuItem
                label="Sign Out"
                icon={<Logout className="rainbow-m-left_small" />}
                iconPosition="left"
                onClick={logout}
            />
        </StyledAvatarMenu>
    );
};

export default Avatar;
