import { IconProps } from 'types';

const ChatIcon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        width={20}
        height={20}
    >
        <title>{title}</title>
        <path d="M17.5002 11.6667L15.0002 9.16667H9.16683C8.70659 9.16667 8.3335 8.79357 8.3335 8.33333V3.33333C8.3335 2.8731 8.70659 2.5 9.16683 2.5H16.6668C17.1271 2.5 17.5002 2.8731 17.5002 3.33333V11.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.5002 11.6667L15.0002 9.16667H9.16683C8.70659 9.16667 8.3335 8.79357 8.3335 8.33333V3.33333C8.3335 2.8731 8.70659 2.5 9.16683 2.5H16.6668C17.1271 2.5 17.5002 2.8731 17.5002 3.33333V11.6667" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.6667 12.4999V14.1666C11.6667 14.6268 11.2936 14.9999 10.8333 14.9999H5L2.5 17.4999V9.16659C2.5 8.70635 2.8731 8.33325 3.33333 8.33325H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.6667 12.4999V14.1666C11.6667 14.6268 11.2936 14.9999 10.8333 14.9999H5L2.5 17.4999V9.16659C2.5 8.70635 2.8731 8.33325 3.33333 8.33325H5" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default ChatIcon;
