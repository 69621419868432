import { IconProps } from '../../types';

const VolumeIcon = ({ title = 'Volumen', className }: IconProps) => (
    <svg
        className={className}
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g clipPath="url(#clip0_1128_18507)">
            <path
                d="M6.18182 2.5L3.68182 4.5H1.68182V7.5H3.68182L6.18182 9.5V2.5Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2168 2.46484C11.1542 3.40248 11.6808 4.67402 11.6808 5.99984C11.6808 7.32566 11.1542 8.5972 10.2168 9.53484M8.45182 4.22984C8.9205 4.69866 9.18379 5.33443 9.18379 5.99734C9.18379 6.66025 8.9205 7.29602 8.45182 7.76484"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1128_18507">
                <rect
                    width="12"
                    height="12"
                    fill="currentColor"
                    transform="translate(0.681824)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default VolumeIcon;
