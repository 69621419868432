import styled, { css } from 'styled-components';
import { color } from 'theme';
import { Variant } from './types';

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

export const ModalIconContainer = styled.div<{ variant?: Variant }>`
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
    background-color: ${color('brand.light')};
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 2.5rem;
        height: 2.5rem;
        color: ${color('brand.main')};
    }

    ${({ variant }) => variant === 'warning' && css`
        background-color: ${color('warning.light')};
        
        > svg {
            color: ${color('warning.main')};
        }
    `}

    ${({ variant }) => variant === 'destructive' && css`
        background-color: ${color('error.light')};
        
        > svg {
            color: ${color('error.main')};
        }
    `}
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ModalDescription = styled.div`
    font-size: 0.8rem;
    text-align: center;
    color: ${color('text.header')};
    width: 100%;
`;
