import Input from 'components/Input';
import styled, { createGlobalStyle } from 'styled-components';
import { DIVIDER } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: stretch
`;

export const PickListGlobalStyle = createGlobalStyle`
    div[id^="listbox-"][role="listbox"] > div {
        padding: 0.5rem;
    }
`;

export const StyledInput = styled(Input)`
    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }
`;
