import Footer from 'components/Footer';
import SummaryBillingItem from 'components/SummaryBillingItem';
import Dollar from 'components/icons/dollar';
import CalendarIcon from 'components/icons/calendarThin';
import useHttpQuery from 'data/firestore/useHttpQuery';
import priceFormatter from 'data/services/price/formatter';
import { getFormatter } from 'data/services/date/formatter';
import formatDuration from 'data/services/duration/formatter';
import useAgentData from 'hooks/useAgentData';
import useRedirectOnMissingRoles from 'hooks/useRedirectWhenMissingRoles';
import { DateTime } from 'luxon';
import { PeriodSummary } from 'types';
import getShortPlanDescription from 'data/services/price/getShortPlanDescription';
import getPlanDescription from 'data/services/price/getPlanDescription';
// import useRedirectOnMissingRoles from 'hooks/useRedirectWhenMissingRoles';
import {
    Container,
    SumaryContainer,
    IconContainer,
    MinutesUsageCard,
    UsageRightColumn,
    UsageLeftColumn,
    CardTitle,
    CardDescription,
    CardValue,
    UsageCardFooter,
    UsageCardHeader,
    HistoryCard,
    HistoryHeader,
    StyledPlot,
} from './styled';
import ChartContainer from './ChartContainer';
import InvoiceList from './InvoiceList';
import {
    ORANGE_500,
} from '../../constants';

const dateFormatterShort = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
});

const dateFormatterMedium = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

function daysLeftString(targetDate: number) {
    const currentDate = Date.now();
    const timeRemaining = targetDate - currentDate;

    const daysLeft = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

    return rtf.format(daysLeft, 'day');
}

const Billing = () => {
    const { agent } = useAgentData();

    useRedirectOnMissingRoles({
        requiredRoles: ['owner', 'editor'],
    });

    const {
        data: currentPeriodSummary,
        isFetched,
    } = useHttpQuery<PeriodSummary>({
        pathname: `/subscriptions/${agent?.billing?.subscriptionId}/current-period`,
        queryOptions: {
            enabled: Boolean(agent?.billing?.subscriptionId),
        },
    });
    const isLoadingSummary = !isFetched;

    return (
        <Container>
            <SumaryContainer>
                <SummaryBillingItem
                    isLoading={isLoadingSummary}
                    value={currentPeriodSummary?.plan ? getShortPlanDescription(currentPeriodSummary?.plan as PeriodSummary['plan']) : 'No Plan'}
                    label="Current Plan"
                    icon={(
                        <IconContainer>
                            <Dollar />
                        </IconContainer>
                    )}
                    footer={currentPeriodSummary?.plan.name as string}
                    tooltip={currentPeriodSummary?.plan ? getPlanDescription(currentPeriodSummary?.plan as PeriodSummary['plan']) : undefined}
                />
                <SummaryBillingItem
                    isLoading={isLoadingSummary}
                    value={
                        currentPeriodSummary?.plan
                            ? priceFormatter(
                                currentPeriodSummary?.plan.currency as string,
                            ).format(
                                (currentPeriodSummary?.upcomming_invoice.amount_due || 0) / 100,
                            )
                            : 'No Plan'
                    }
                    label="Current Monthly Bill"
                    icon={(
                        <IconContainer>
                            <Dollar />
                        </IconContainer>
                    )}
                    footer={currentPeriodSummary?.period ? `${dateFormatterShort.format(currentPeriodSummary?.period.start as number)} - Today` : 'No Plan'}
                />
                <SummaryBillingItem
                    isLoading={isLoadingSummary}
                    value={
                        currentPeriodSummary?.upcomming_invoice ? dateFormatterMedium.format(currentPeriodSummary?.upcomming_invoice.next_payment_attempt as number) : 'No Plan'
                    }
                    label="Next Payment Due"
                    icon={(
                        <IconContainer>
                            <CalendarIcon />
                        </IconContainer>
                    )}
                    footer={
                        currentPeriodSummary?.upcomming_invoice
                            ? daysLeftString(currentPeriodSummary?.upcomming_invoice.next_payment_attempt) : 'No Plan'
                    }
                />
            </SumaryContainer>
            <MinutesUsageCard>
                <UsageLeftColumn>
                    <UsageCardHeader>
                        <CardTitle>
                            Monthly Minutes to Date
                        </CardTitle>
                        <CardDescription>
                            Daily breakdown
                        </CardDescription>
                    </UsageCardHeader>
                    <UsageCardFooter>
                        <CardValue>
                            {currentPeriodSummary?.usage ? `${Math.floor(currentPeriodSummary?.usage.quantity as number / 60)} min` : 'No Plan'}
                        </CardValue>
                        <CardDescription>
                            Total minutes spoken
                        </CardDescription>
                    </UsageCardFooter>
                </UsageLeftColumn>
                <UsageRightColumn>
                    <ChartContainer isLoading={isLoadingSummary}>
                        <StyledPlot
                            config={{
                                displayModeBar: false,
                                responsive: true,
                                autosizable: true,
                                doubleClick: false,
                                editable: false,
                                fillFrame: false,
                                scrollZoom: false,
                                showAxisDragHandles: false,
                                showAxisRangeEntryBoxes: false,
                                showTips: false,
                                showLink: false,
                                showEditInChartStudio: false,
                            }}
                            data={[
                                {
                                    x: currentPeriodSummary
                                        ?.usage?.daily_breakdown.map((item) => item.date) || [],
                                    y: currentPeriodSummary
                                        ?.usage?.daily_breakdown.map(
                                            (item) => item.amount / 60,
                                        ) || [],
                                    type: 'bar',
                                    xaxis: 'x',
                                    yaxis: 'y',
                                    name: 'Total calls',
                                    marker: {
                                        color: ORANGE_500,
                                    },
                                    hoverinfo: 'text',
                                    hovertext: currentPeriodSummary
                                        ?.usage?.daily_breakdown.map(
                                            (item) => `${formatDuration(item.amount * 1000)} at ${DateTime.fromISO(item.date).toFormat('MMM d')}`,
                                        ) || [],
                                },
                            ]}
                            layout={{
                                autosize: true,
                                paper_bgcolor: 'transparent',
                                plot_bgcolor: 'transparent',
                                margin: {
                                    l: 35,
                                    r: 20,
                                    b: 30,
                                    t: 0,
                                },
                                xaxis: {
                                    fixedrange: true,
                                },
                                yaxis: {
                                    fixedrange: true,
                                },
                            }}
                        />
                    </ChartContainer>
                </UsageRightColumn>
            </MinutesUsageCard>
            <HistoryCard>
                <HistoryHeader>
                    <CardTitle>
                        Invoice History
                    </CardTitle>
                </HistoryHeader>
                <InvoiceList subscriptionId={agent?.billing?.subscriptionId} />
            </HistoryCard>
            <Footer />
        </Container>
    );
};

export default Billing;
