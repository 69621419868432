import { Inbox } from 'data/firestore/inbox/types';
import { EntityGet } from 'data/firestore/types';
import React from 'react';

export interface Context {
    tasks: EntityGet<Inbox>[];
    isLoading: boolean;
}

const context = React.createContext<Context>({
    tasks: [],
    isLoading: false,
});

export const { Provider, Consumer } = context;

export default context;
