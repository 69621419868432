import styled, { css } from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import Card from 'components/Card';
import UserAvatar from 'components/UserAvatar';
import {
    GREY_500,
    GREY_400,
    GREY_800,
    RED_500,
    RED_100,
    PURPLE_500,
    PURPLE_100,
    GREEN_500,
    GREEN_100,
} from '../../../constants';

export const StyledCard = styled(Card)`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5rem;
`;

export const SectionHeader = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.375rem;
    padding: 1rem 1rem 0 1.5rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.5rem;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    color: ${GREY_800};
`;

export const Subtitle = styled.div`
    font-size: 0.8125rem;
    font-weight: 300;
    line-height: 1rem;
    color: ${GREY_500};
`;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
`;

export const ActionButton = styled(ButtonIcon)`
    & > svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const EmptyMessageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    gap: 0.5rem;
`;

export const EmptyMessageLabel = styled.p`
    font-size: 0.9375rem;
    color: ${GREY_400};
    font-weight: 300;
`;

export const OptionContainer = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 0; 

    & > div:first-child {
        display: inline-flex;
        flex: 1;
    }
`;

export const TrashButton = styled(ButtonIcon)`
    background: ${RED_100};
    color: ${RED_500};
    border: none;
    border-radius: 0.375rem;
    width: 2.38rem;
    height: 2.38rem;

    & > svg { 
        width: 1.375rem !important;
        height: 1.375rem !important;
        font-size: 1.375rem !important; 
    }
`;

export const ItemLabelContaier = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow: hidden;
    flex-grow: 1;
`;

export const NotificationIcon = styled(UserAvatar).withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop as string),
})<{ type: 'sms' | 'email'; }>`
    display: flex;
    width: 2.375rem;
    height: 2.375rem;
    justify-content: center;
    align-items: center;

    & > svg {
        width: 1.2rem !important;
        height: auto !important;
        flex-shrink: 0;
    }

    ${(props) => props.type === 'email' && css`
        background: ${PURPLE_100};
        color: ${PURPLE_500};
        
        &:hover {
            color: ${PURPLE_500};
        }
    `};

    ${(props) => props.type === 'sms' && css`
        background: ${GREEN_100};
        color: ${GREEN_500};
        
        &:hover {
            color: ${GREEN_500};
        }
    `};

`;

export const Label = styled.h2`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${GREY_500};
    font-weight: 300;
    font-size: 0.9375rem;
`;
