import styled from 'styled-components';
import { CheckboxGroup } from 'react-rainbow-components';
import { WHITE } from '../../constants';

export const DropDown = styled.div`
    display: flex;
    width: 18.75rem;
    padding: 0.75rem 0 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1rem;
    background: ${WHITE};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06), 0px 8px 24px 0px rgba(75, 70, 92, 0.16);
`;

export const Header = styled.div`
    width: 100%;
    padding: 0 1rem 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; 
`;

export const StyledCheckboxGroup = styled(CheckboxGroup)`
    display: flex;
    padding-left: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
`;
