import { NameCellWrapper } from '../styled';

interface Props {
    value?: string;
}

const TypeColumn = ({ value }: Props) => (
    <NameCellWrapper>
        {value}
    </NameCellWrapper>
);

export default TypeColumn;
