import { IconProps } from '../../types';

const ChevronsRight = ({ title, className }: IconProps) => (
    <svg
        className={className}
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <path
            d="M7.18182 8.5L9.68182 6L7.18182 3.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.68182 8.5L6.18182 6L3.68182 3.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ChevronsRight;
