import { useOutletContext } from 'react-router-dom';
import { useOpenModal } from '@rainbow-modules/hooks';
import { ViewContextType } from 'pages/Calendar/types';
import CalendarListView from 'components/CalendarListView';
import { CALENDAR_EVENT_DETAILS } from '../../../../constants';

const CalendarViewList = () => {
    const {
        calendars,
        events,
        timeZone,
    } = useOutletContext<ViewContextType>();
    const [openEventDetails] = useOpenModal(CALENDAR_EVENT_DETAILS);
    return (
        <CalendarListView
            calendars={calendars}
            events={events}
            onEventClick={({ triggerRef, event }) => openEventDetails({
                triggerRef,
                eventId: event.id,
                calendarId: event.calendarId,
            })}
            timeZone={timeZone}
        />
    );
};

export default CalendarViewList;
