interface Params {
    agentId: string;
}

const getAlgoliaFilters = ({ agentId }: Params) => {
    const filters = `agentId:${agentId} AND status:active`;
    return filters;
};

export default getAlgoliaFilters;
