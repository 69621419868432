import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import Picklist from 'components/Picklist';
import { GREY_100, GREY_300 } from '../../../constants';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap: 1.95rem;
    box-sizing: border-box;
`;

export const DayLabel = styled.div`
    display: inline-flex;
    flex: 0;
    min-width: 3.125rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    text-transform: capitalize;
    height: 2.875rem;
    align-items: center;
    box-sizing: border-box;
`;

export const Label = styled.div`
    display: flex;
    flex: 1;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    color: ${GREY_300};
    height: 2.875rem;
    align-items: center;
    width: 19.125rem;
`;

export const InputsContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 0rem 0.125rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    width: 19.125rem;
    align-self: stretch;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    margin: 0.4375rem 0;
`;

export const RangePickerContainer = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    align-self: stretch;
    gap: 1.95rem;
`;

export const PickersContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const Separator = styled.div`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
`;

export const StyledPicklist = styled(Picklist)`
    div[role="combobox"] > span {
        display: none;
    }

    input {
        border: medium;
        padding-right: 0.75rem;
        background: #F8F8F8;
        text-align: center;
        user-select: none;

        width: 7.5rem;
        height: 2.875rem;

        border-radius: 0.375rem;
        
        ::placeholder {
            font-weight: 300 !important;
        }

        :focus,
        :active,
        :hover {
            border: unset;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
            background: ${GREY_100};
        }
    }
`;
