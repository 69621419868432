import { useCallback, useMemo } from 'react';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { Topic } from 'data/firestore/agent/topic/types';
import Item from './item';
import {
    Container, StyledUniversalPicker, Gradient,
} from './styled';

interface TasksCategoriesFilterProps {
    categories?: Topic[];
    value?: string[];
    onChange?: (values: string[]) => void;
}

const TasksCategoriesFilter = ({
    categories = [],
    value,
    onChange = () => {},
}: TasksCategoriesFilterProps) => {
    const options = useMemo(() => categories.map(
        (category) => (
            <UniversalPickerOption
                key={category.id}
                component={Item}
                {...category}
                name={category.id}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                displayName={category.displayName || category.name}
            />
        ),
    ), [categories]);

    const handleOnChange = useCallback(
        (values: string | string[]) => {
            onChange((
                typeof values === 'string'
                    ? [values]
                    : values
            ));
        },
        [onChange],
    );

    return (
        <Container>
            <StyledUniversalPicker
                value={value}
                onChange={handleOnChange}
                multiple
            >
                {options}
            </StyledUniversalPicker>
            <Gradient />
        </Container>
    );
};

export default TasksCategoriesFilter;
