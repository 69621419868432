import { IconProps } from 'types';

const Skills = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
    >
        <title>{title}</title>
        <path d="M13 5H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 9H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 19H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="4" y="14.5" width="5" height="5" rx="1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 9.5293C2 9.9435 2.33579 10.2793 2.75 10.2793C3.16421 10.2793 3.5 9.9435 3.5 9.5293C3.5 9.11509 3.16421 8.7793 2.75 8.7793C2.33579 8.7793 2 9.11509 2 9.5293Z" fill="currentColor" />
        <path d="M5.7885 4.20886C5.9261 3.93038 6.32326 3.93038 6.46087 4.20886L6.64706 4.58549C6.96281 5.22466 7.42031 5.78335 7.98468 6.21906L8.36531 6.51297C8.55974 6.66308 8.55974 6.95648 8.36531 7.10659L7.98468 7.4005C7.42031 7.83621 6.96281 8.39491 6.64706 9.03407L6.46087 9.41071C6.32326 9.68918 5.9261 9.68918 5.7885 9.41071L5.60238 9.03407C5.28654 8.39491 4.82904 7.83621 4.26474 7.4005L3.8841 7.10659C3.68968 6.95648 3.68968 6.66308 3.8841 6.51297L4.26474 6.21906C4.82904 5.78335 5.28654 5.22466 5.60238 4.58549L5.7885 4.20886Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);
export default Skills;
