import {
    MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-rainbow-components';
import useCustomers from 'data/firestore/agent/customer/useCollectionWithPagination';
import { orderBy, query } from 'firebase/firestore';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import Pagination from 'components/Pagination';
import DateTimeColumn from 'components/DateTimeColumn';
import Table from 'components/Table';
import { EmptyLabel } from './columns/styled';
import { ALGOLIA_INDEX_CUSTOMERS, PAGE_SIZE } from '../../../constants';
import LanguageColumn from './columns/preferredLanguage';
import UserColumn from './columns/user';
import {
    Container, EntriesText, PaginationContainer, CellWrapper,
} from './styled';
import GenderColumn from './columns/gender';

interface CustomersListProps {
    search?: string;
}

const dobColumnFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'utc',
};

const CustomersList = ({ search }: CustomersListProps) => {
    const [activePage, setActivePage] = useState(1);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const { agentId = '' } = useParams<{ agentId: string }>();
    const {
        data: customers,
        isLoading: isLoadingCustomers,
        totalRecords: totalCustomers,
        nextPage,
        prevPage,
        error,
    } = useCustomers(
        agentId,
        {
            disabled: !agentId,
            limit: PAGE_SIZE,
            listQueryFn: (ref) => query(ref, orderBy('createdAt', sortDirection)),
            track: [agentId, sortDirection],
        },
    );

    useEffect(() => setActivePage(1), [search]);

    const algoliaData = useAlgoliaSearchData<EntityGet<Customer>>({
        search,
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_CUSTOMERS,
        filters: `agentId:${agentId}`,
        enabled: Boolean(search),
    });

    const disableSorting = useMemo(
        () => Boolean(algoliaData?.hits),
        [algoliaData?.hits],
    );

    const dataToShow = algoliaData?.hits || customers;
    const totalHits = typeof algoliaData?.nbHits === 'number' ? algoliaData.nbHits : totalCustomers;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        if (isLoadingCustomers) return;
        if (page === activePage) return;
        if (!algoliaData?.hits) {
            if (page > activePage) nextPage();
            if (page < activePage) prevPage();
        }
        setActivePage(page);
    };

    const handleOnSort = useCallback(
        (
            event: MouseEvent<HTMLElement>,
            field: string,
            nextSortDirection: 'asc' | 'desc',
        ) => {
            if (disableSorting) return;
            setSortDirection(nextSortDirection);
        },
        [disableSorting],
    );

    if (error || (!customers && !isLoadingCustomers)) {
        return (
            <Container>
                <h1>Something went wrong</h1>
                {error?.message && (
                    <p>
                        {error.name}
                        :
                        {' '}
                        {error.code}
                    </p>
                )}
            </Container>
        );
    }

    return (
        <Container>
            <Table
                data={dataToShow}
                keyField="id"
                isLoading={isLoadingCustomers}
                sortedBy="createdAt"
                onSort={handleOnSort}
                sortDirection={sortDirection}
            >
                <Column
                    header="Customer"
                    field="id"
                    component={UserColumn}
                />
                <Column
                    header="Preferred Language"
                    field="preferredLanguage"
                    component={LanguageColumn}
                />
                <Column
                    header="DOB"
                    field="dob"
                    component={DateTimeColumn}
                    options={dobColumnFormatOptions}
                    emptyLabel={<CellWrapper><EmptyLabel>Not Provided</EmptyLabel></CellWrapper>}
                />
                <Column
                    header="Gender"
                    field="gender"
                    component={GenderColumn}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    component={DateTimeColumn}
                    sortable={!disableSorting}
                />
            </Table>
            <PaginationContainer>
                <EntriesText>
                    Showing
                    {' '}
                    {firstItemOfActivePage}
                    {' '}
                    to
                    {' '}
                    {lastItemOfActivePage}
                    {' '}
                    of
                    {' '}
                    {totalHits}
                    {' '}
                    entries.
                </EntriesText>
                <Pagination
                    activePage={activePage}
                    pages={pages}
                    onChange={handlePageChange}
                />
            </PaginationContainer>
        </Container>
    );
};

export default CustomersList;
