import { forwardRef, ReactNode } from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import { ButtonProps } from 'react-rainbow-components/components/Button';
import ButtonIcon from 'components/ButtonIcon';
import Dot from 'components/Dot';
import { StyledButton, DotContainer } from './styled';

interface SearchButtonProps extends Pick<ButtonProps, 'onClick'> {
    icon?: ReactNode;
    value?: string;
}

const SearchButton = forwardRef<HTMLButtonElement, SearchButtonProps>(
    ({ value, icon, onClick }, ref) => {
        if (!isEmpty(value)) {
            return (
                <StyledButton
                    ref={ref}
                    variant="base"
                    onClick={onClick}
                >
                    {icon}
                    <DotContainer>
                        <Dot variant="error" />
                    </DotContainer>
                    {value}
                </StyledButton>
            );
        }

        return (
            <ButtonIcon
                ref={ref}
                variant="base"
                icon={icon}
                onClick={onClick}
            />
        );
    },
);

export default SearchButton;
