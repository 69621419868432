const BASE_URL = process.env.REACT_APP_BASE_URL;

const buildUrlForFeature = ({
    agentId,
    feature,
}: {
    agentId: string;
    feature: string;
}) => {
    if (![
        'meta.instagram.dm',
        'meta.facebook.dm',
    ].includes(feature)) return '';
    const channel = feature.split('.').at(1);
    return `${BASE_URL}/agents/${agentId}/social/${channel}`;
};

export default buildUrlForFeature;
