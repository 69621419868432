import styled from 'styled-components';
import { AccordionSection, TimelineMarker } from 'react-rainbow-components';
import Button from 'components/Button';
import Input from 'components/Input';
import {
    GREY_500, GREY_400, DIVIDER, GREY_100, GREY_300, GREEN_500, GREEN_100,
} from '../../../../../constants';

export const Content = styled.div`
    display: flex;
    align-items: stretch;
    align-self: stretch;
    flex: 1 0 0;
    overflow: hidden;
`;

export const LeftColumn = styled.div`
    display: flex;
    width: 24.0625rem;
    padding: 1rem 1.25rem 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
    align-self: stretch;
    border-right: 1px solid ${DIVIDER};
    overflow: auto;
    flex-shrink: 0;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    flex: 1 1 auto;
    padding: 1rem 1.5rem 2rem 2rem;
    overflow: auto;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self:  stretch;
    margin-top: 0.5rem;
`;

export const Title = styled.h3`
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    color: ${GREY_500};
`;

export const Description = styled.span`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.375rem;
    color: ${GREY_400};
    margin-bottom: 0.5rem;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
`;

export const StyledInput = styled(Input)`
    display: flex;
    align-self: stretch;
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    label + div > span:first-child {
        margin-left: 0.5rem;
    }

    input {
        border: none;
        padding: 0.4375rem 0.625rem 0.4375rem 0.5rem;
        pointer-events: none;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;

        ::placeholder {
            font-weight: 300 !important;
        }

        &[readonly] {
            padding: 0 1rem;
        }
    }
`;

export const AddReminderButton = styled(Button)`
    align-self: flex-end;
`;

export const IconContainer = styled.div`
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    color: ${GREEN_500};
    background: ${GREEN_100};
    border-radius: 100%;
`;

export const StyledTimelineMarker = styled(TimelineMarker)`
    & > div::before {
        height: calc(100% - 2rem);
        top: 2rem;
    }

    div + p {
        display: none;
    }

    h1 {
        padding: 0;
        margin: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
        font-size: 0.9375rem;
        font-style: normal;
        line-height: 1.3125rem;    
        font-weight: 0;
    }
`;

export const StyledAccordionSection = styled(AccordionSection)`
    border: 1px solid ${DIVIDER};
    border-radius: 0.375rem;
    padding: 0;
    box-shadow: none;

    button {
        border-radius: 0.375rem;
        border: none;
        padding: 0.8125rem 1.25rem;
        box-shadow: none;

        :focus {
            box-shadow: none;
        }

        :hover {
            background: none;
        }

        > h3 {
            padding-right: 0;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.375rem; 
        }
    }

    section {
        border: none;
        box-shadow: none;
        padding: 0.4375rem 1.25rem 0.8125rem 2.75rem;
        gap: 1.5rem;
    }
`;

export const ItemContent = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    gap: 1.5rem;
`;

export const ItemHeader = styled.div`
    display: flex;
    align-items: stretch;
    gap: 0.125rem;
    align-self: stretch;
`;

export const ItemTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ItemLabel = styled.div`
    font-weight: 500;
    color: ${GREY_500};
    margin: 0;
    padding: 0;
`;

export const ItemDescription = styled.span`
    font-weight: 400;
    color: ${GREY_300};
`;

export const ItemActions = styled.div`
    display: flex;
    width: 3.5rem;
    padding-top: 0.5rem;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.75rem;
`;

export const ExampleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    margin-top: 0.25rem;
`;

export const ExampleMessage = styled.div`
    display: flex;
    padding: 0.625rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: ${GREY_100};
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    border-radius: 0rem 0.375rem 0.375rem 0.375rem;
`;

export const ExampleLoadingContainer = styled.div`
    display: flex;
    align-items: stretch;
    gap: 0.75rem;
    align-self: stretch;
    margin-top: 0.25rem;
    
    & > div:first-child {
        flex: 0;
        width: 48px;
        height: 48px;
    }

    & > div:last-child {
        width: 100%;
        align-self: stretch;

        > div {
            border-radius: 0rem 0.375rem 0.375rem 0.375rem;
        }
    }
`;
