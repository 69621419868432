import { Outlet } from 'react-router-dom';
import InboxBar from 'components/InboxBar';
import Footer from 'components/Footer';
import { Container, ContentPage } from './styled';

const Page = () => (
    <ContentPage>
        <Container>
            <InboxBar />
            <Outlet />
        </Container>
        <Footer />
    </ContentPage>
);

export default Page;
