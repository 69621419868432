import { useCallback, useRef, useState } from 'react';
import { useUniqueIdentifier, useWindowScrolling } from '@rainbow-modules/hooks';
import { FloatingSearch } from '@rainbow-modules/listview';
import SearchIcon from 'components/icons/search';
import { StyledButtonIcon } from './styled';

const SearchFilter = ({
    value,
    onChange = () => {},
}: {
    value?: string;
    onChange?: (value: string) => void;
}) => {
    const triggerRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const floatingSearchId = useUniqueIdentifier('floating-search');
    useWindowScrolling(() => setIsOpen(false), isOpen);

    const toggleOpen = useCallback(
        () => setIsOpen(!isOpen),
        [isOpen],
    );

    const handleCloseSearch = useCallback(
        () => setIsOpen(false),
        [],
    );

    return (
        <>
            <StyledButtonIcon
                ref={triggerRef}
                borderRadius="semi-square"
                icon={<SearchIcon />}
                onClick={toggleOpen}
                ariaControls={floatingSearchId}
            />
            <FloatingSearch
                id={floatingSearchId}
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => triggerRef.current.htmlElementRef}
                placeholder="Search by name"
                onChange={onChange}
                value={value}
                onRequestClose={handleCloseSearch}
            />
        </>
    );
};

export default SearchFilter;
