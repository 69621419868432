import styled from 'styled-components';
import { LoadingShape } from 'react-rainbow-components';
import LinkWithQuery from 'components/LinkWithQuery';
import { color } from 'theme';
import { GREY_400, GREY_300 } from '../../constants';

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: none;
    outline: none;
    justify-content: flex-start;
    text-align: left;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    line-height: 1.2;
    overflow: hidden;

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const Title = styled.span`
    font-weight: 500;
    color: ${GREY_400};
    font-size: 0.9375rem;
    line-height: 1.5;
`;

export const Subtitle = styled.span`
    color: ${GREY_300};
    font-weight: 300;
    font-size: 0.8125rem;
    line-height: 1.5;
`;

export const IconContainer = styled.div`
    flex-shrink: 0;
    margin-right: 10px;
`;

export const StyledAvatarLoadingShape = styled(LoadingShape)`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;

    > div {
        width: 100%;
        height: 100%;
        min-width: auto;
        min-height: auto;
    }
`;

export const StyledLink = styled(LinkWithQuery)`
    color: ${color('brand.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        color: ${color('brand.dark')};
    }
`;
