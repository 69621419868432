/* eslint-disable react/forbid-component-props */
import { useRef } from 'react';
import Picklist, { PicklistProps } from 'react-rainbow-components/components/Picklist/index';
import {
    PicklistButtonContainer, PicklistContainer, StyledButton, StyledChevronIcon,
} from './styled';

const PicklistButton = (props: PicklistProps) => {
    const picklistRef = useRef(null);
    const {
        value, className, style, disabled,
    } = props;
    const { label } = value || {};

    const handleClick = () => {
        (picklistRef.current as any)?.click();
    };

    return (
        <PicklistButtonContainer className={className} style={style}>
            <StyledButton
                variant="base"
                size="small"
                borderRadius="semi-square"
                disabled={disabled}
                onClick={handleClick}
            >
                {label}
                <StyledChevronIcon />
            </StyledButton>
            <PicklistContainer>
                <Picklist {...props} ref={picklistRef} />
            </PicklistContainer>
        </PicklistButtonContainer>
    );
};

export default PicklistButton;
