import { IconProps } from 'types';

const Computer = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        width={22}
        height={22}
        fill="none"
    >
        <title>{title}</title>
        <path d="M2.75 17.4173H19.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="4.58334" y="5.5" width="12.8333" height="9.16667" rx="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Computer;
