import { useContext, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import ButtonIcon from 'components/ButtonIcon';
import ExternalLink from 'components/icons/externalLink';
import AccordionSection from 'components/AccordionSection';
import Accordion from 'components/Accordion';
import MessageLoadingShape from 'components/MessageLoadingShape';
import UserAvatar from 'components/UserAvatar';
import Transcript from 'pages/History/ConversationDetails/Transcript';
import { Customer } from 'data/firestore/agent/customer/types';
import dateFormatter from 'data/services/date/formatter';
import { EntityGet } from 'data/firestore/types';
import { Inbox } from 'data/firestore/inbox/types';
import useSession from 'data/firestore/session/use';
import phoneNumberFormatter from 'data/services/phone/formatter';
import isValidPhoneNumber from 'data/services/phone/isValid';
import getInitials from 'data/services/string/getInitials';
import getDisplayName from 'data/services/profile/getDisplayName';
import Sms from 'components/icons/sms';
import ListTile from 'components/ListTile';
import useCustomerPanel from 'hooks/useCustomerPanel';
import useSMSChat from 'hooks/useSMSChat';
import {
    Card,
    SecondaryText,
    Body,
    PrimaryText,
    SummaryContainer,
    AccordionHeader,
    CardHeader,
    Row,
    ItemContent,
    MessageContainer,
    MessageTitle,
    MessageDescription,
    StyledSaraAvatar,
    SummaryHeader,
    MessageTime,
    MessageName,
    BubbleContainer,
} from '../styled';
import { Divider, TitleLoadingLoadingShape } from '../../styled';
import context, { Context } from '../context';

const InboxItemOverview = () => {
    const navigate = useNavigate();
    const { inboxItem, isLoading: isLoadngItem } = useContext<Context>(context);

    const { customer, createdAt } = inboxItem as EntityGet<Inbox>;

    const { data: session, isLoading: isLoadingSession } = useSession(inboxItem?.sessionId || '', {
        disabled: isLoadngItem || !inboxItem?.sessionId,
    });

    const [conversationSummary, isLoadingConversationSummary] = useDocOnce({
        path: `/session/${session?.id}/metadata/summary`,
        flat: true,
    });

    const displayName = useMemo(() => getDisplayName(customer), [customer]);
    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const phoneNumber = useMemo(() => {
        if (isValidPhoneNumber(session?.from)) {
            return phoneNumberFormatter(session?.from);
        }
        return '';
    }, [session?.from]);

    const isOverallLoading = isLoadngItem || isLoadingSession || isLoadingConversationSummary;
    const isSpam = inboxItem?._tags?.includes('spam');

    const { openChat } = useSMSChat();
    const handleSMSClick = useCallback(
        () => openChat({ ...customer, id: customer?.uid }),
        [openChat, customer],
    );

    const { openCustomerPanel } = useCustomerPanel();
    const handleCustomerPanelClick = useCallback(
        () => openCustomerPanel({ data: { id: customer?.uid } }),
        [openCustomerPanel, customer],
    );

    if (isOverallLoading) {
        return (
            <ItemContent>
                <Card>
                    <CardHeader>
                        <TitleLoadingLoadingShape />
                    </CardHeader>
                    <Divider />
                    <Body>
                        <MessageLoadingShape hideAvatar />
                    </Body>
                </Card>
            </ItemContent>
        );
    }

    return (
        <ItemContent>
            <Card>
                <CardHeader>
                    <Row>
                        <ListTile
                            icon={(
                                <UserAvatar
                                    initials={initials}
                                    value={customer?.uid}
                                    src={customer?.photoURL}
                                />
                            )}
                            onClick={handleCustomerPanelClick}
                            title={displayName}
                            subtitle={phoneNumber}
                        />
                    </Row>
                    <ButtonIcon
                        icon={<Sms />}
                        onClick={handleSMSClick}
                    />
                </CardHeader>
                <Divider />
                <Body>
                    <SummaryContainer>
                        <StyledSaraAvatar />
                        <BubbleContainer>
                            <SummaryHeader>
                                <MessageName>
                                    Sara
                                </MessageName>
                                <MessageTime>
                                    {dateFormatter.format(createdAt)}
                                </MessageTime>
                            </SummaryHeader>
                            <MessageContainer>
                                <MessageTitle>
                                    Summary
                                </MessageTitle>
                                <MessageDescription>
                                    <RenderIf isTrue={isSpam}>
                                        This phone number was detected as a spam risk
                                        and added to the list of blocked numbers.
                                    </RenderIf>
                                    {conversationSummary?.summary}
                                </MessageDescription>
                            </MessageContainer>
                        </BubbleContainer>
                    </SummaryContainer>
                    <RenderIf isTrue={session && session.status !== 'blocked'}>
                        <Divider />
                        <Accordion>
                            <AccordionSection label={(
                                <AccordionHeader>
                                    <div>
                                        <PrimaryText>
                                            Call Transcript & Recording
                                        </PrimaryText>
                                        <SecondaryText>
                                            ID:
                                            {' '}
                                            {session?.id}
                                        </SecondaryText>
                                    </div>
                                    <ButtonIcon variant="base" icon={<ExternalLink />} size="small" onClick={() => navigate(`/${session?.agentId}/voip/history/${session?.id}`)} />
                                </AccordionHeader>
                            )}
                            >
                                <Transcript
                                    sessionId={session?.id}
                                    recordingId={session?.recordingId}
                                    from={inboxItem?.customer as unknown as Customer}
                                    status={session?.status}
                                />
                            </AccordionSection>
                        </Accordion>
                    </RenderIf>
                </Body>
            </Card>
        </ItemContent>
    );
};

export default InboxItemOverview;
