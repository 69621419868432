import { useParams } from 'react-router-dom';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { Affiliate } from 'data/firestore/agent/affiliate/types';
import { ActionsCellWrapper } from '../styled';
import RemoveModal from '../removeModal';

interface Props {
    row?: Affiliate;
}

const ActionsColumn = ({ row }: Props) => {
    const { id, phoneNumber } = row || {};
    const { agentId } = useParams<{ agentId: string }>();
    const { mutateAsync: removeAffiliate } = useHttpMutation({
        method: 'DELETE',
        pathname: `/agents/${agentId}/affiliates/${id}`,
    });

    const handleRemove = async () => {
        if (!id) {
            return;
        }

        const result = await confirmModal({
            header: '',
            question: <RemoveModal />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Remove',
            cancelButtonLabel: 'Cancel',
        });

        if (!result) {
            return;
        }

        showAppSpinner();
        try {
            await removeAffiliate({});
            showAppNotification({
                title: `${phoneNumber} removed`,
                description: 'The affiliate was removed successfully.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    return (
        <ActionsCellWrapper>
            <ButtonIcon borderRadius="semi-square" icon={<Trash />} tooltip="Remove affiliate" size="small" onClick={handleRemove} />
        </ActionsCellWrapper>
    );
};

export default ActionsColumn;
