import Card from 'components/Card';
import styled from 'styled-components';
import { LoadingShape } from 'react-rainbow-components';
import { GREY_800, GREY_300 } from '../../constants';

export const Container = styled(Card)`
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    height: fit-content;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 1.5rem;
    padding-top:  0.25rem;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 1rem;
    gap: 0.35rem;
`;

export const CardTitle = styled.h2`
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125rem;
    white-space: nowrap;
    color: ${GREY_800};
`;

export const CardSubtitle = styled.h3`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.25rem;
    text-align: left;
    white-space: nowrap;
    color: ${GREY_300};
`;

interface StyledLoadingProps {
    randomWidth?: boolean;
}
export const StyledLoading = styled(LoadingShape).withConfig({
    shouldForwardProp: (prop) => !['randomWidth'].includes(prop),
})<StyledLoadingProps>`
    margin-bottom: 1rem;
    ${(props) => props.randomWidth && `width: ${Math.floor(Math.random() * 100) + 1}%`}
`;

export const Filter = styled.div``;

export const Chart = styled.div``;
