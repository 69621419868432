import React from 'react';
import { StyledDot } from './styled';

interface Props {
    variant?: 'brand' | 'success' | 'error' | 'warning' | 'orange';
}

const Dot = ({ variant = 'brand' }: Props) => (
    <StyledDot variant={variant} />
);

export default Dot;
