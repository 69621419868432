const getUnavailableSlots = (
    availability: Record<string, number>[],
) => {
    if (availability.length === 0) {
        return [
            { startAt: 0, duration: 86400 },
        ];
    }

    let initialTime = 0;
    const slots = availability
        .sort((a, b) => a.startAt - b.startAt)
        .reduce(
            (result: Record<string, number>[], { startAt, duration }) => {
                const diff = Math.abs(initialTime - startAt);
                if (diff > 0) {
                    result.splice(result.length - 1, 0, { startAt: initialTime, duration: diff });
                }

                initialTime = startAt + duration;

                const last = result.pop();
                if (last) {
                    const durationRemaining = Math.abs(last.startAt + last.duration - initialTime);
                    if (durationRemaining > 0) {
                        result.push({
                            startAt: initialTime,
                            duration: durationRemaining,
                        });
                    }
                }

                return result;
            },
            [{ startAt: 0, duration: 86400 }],
        );

    return slots;
};

export default getUnavailableSlots;
