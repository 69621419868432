import { IconProps } from 'types';

const Play = ({ title = 'Play', className }: IconProps) => (
    <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 5.5V18.2692L18 12L7 5.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

    </svg>

);

export default Play;
