/* eslint-disable no-template-curly-in-string */
import { Snippet } from '../types';

const snippet: Snippet = {
    label: 'each',
    body: '{{#each ${1:list} as |${2:alias}|}}\n\t${0}\n{{/each}}',
    documentation: 'each statement',
};

export default snippet;
