import styled, { css } from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import { PURPLE_500, PURPLE_100, DIVIDER } from '../../constants';

export const StyledUniversalPicker = styled(UniversalPicker)`
    legend {
        padding-left: 0;
    }

    legend + div {
        justify-content: flex-start;
    }
`;

export const PickerOptionContainer = styled.div<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean;
}>`
    display: flex;
    padding: 0.3125rem 0.75rem 0.3125rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid ${DIVIDER};
    border-radius: 0.25rem;

    ${(props) => props.isHover && !props.disabled && css`
        border-color: ${PURPLE_500};
        cursor: pointer;
    `};

    ${(props) => props.isSelected && css`
        border-color: ${PURPLE_500};
        color: ${PURPLE_500};
        background: ${PURPLE_100};
    `};
`;
