import { IconProps } from 'types';

const Inbox = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
    >
        <title>{title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.41886 15H4V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V15H16.5811C16.0673 15 15.6112 15.3288 15.4487 15.8162C15.014 17.1204 13.7935 18 12.4189 18H11.5811C10.2065 18 8.98603 17.1204 8.55132 15.8162C8.38884 15.3288 7.93267 15 7.41886 15ZM19.6542 13H16.5811C15.2065 13 13.986 13.8796 13.5513 15.1838C13.3888 15.6712 12.9327 16 12.4189 16H11.5811C11.0673 16 10.6112 15.6712 10.4487 15.1838C10.014 13.8796 8.79353 13 7.41886 13H4.34577L6.32662 5.73688C6.44528 5.30182 6.84043 5 7.29139 5H16.7086C17.1596 5 17.5547 5.30182 17.6734 5.73688L19.6542 13ZM19.6029 5.21065C19.2469 3.90546 18.0615 3 16.7086 3H7.29139C5.93853 3 4.75306 3.90546 4.3971 5.21065L2 14V18C2 19.6569 3.34315 21 5 21H19C20.6569 21 22 19.6569 22 18V14L19.6029 5.21065Z" fill="currentColor" />

    </svg>
);
export default Inbox;
