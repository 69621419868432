import { IconProps } from 'types';

const Calendar = ({ className, title }: IconProps) => (
    <svg
        className={className}
        fill="none"
        width={24}
        height={24}
        viewBox="0 0 24 24"
    >
        <title>{title}</title>
        <path d="M6 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V6C21 4.89543 20.1046 4 19 4H18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <line x1="4" y1="9" x2="20" y2="9" stroke="currentColor" strokeWidth="2" />
        <path d="M9 5L9 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M15 5L15 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M12 12L13.0882 13.9118L15 15L13.0882 16.0882L12 18L10.9118 16.0882L9 15L10.9118 13.9118L12 12Z" fill="currentColor" />
    </svg>
);
export default Calendar;
