import { IconProps } from 'types';

const AlertOctagon = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M7.97918 3.58579C8.35426 3.21071 8.86297 3 9.3934 3L15.6066 3C16.137 3 16.6457 3.21071 17.0208 3.58579L21.4142 7.97919C21.7893 8.35426 22 8.86297 22 9.3934L22 15.6066C22 16.137 21.7893 16.6457 21.4142 17.0208L17.0208 21.4142C16.6457 21.7893 16.137 22 15.6066 22L9.3934 22C8.86297 22 8.35426 21.7893 7.97918 21.4142L3.58579 17.0208C3.21071 16.6457 3 16.137 3 15.6066L3 9.3934C3 8.86297 3.21071 8.35426 3.58579 7.97918L7.97918 3.58579Z" stroke="currentColor" strokeWidth="2" />
        <circle cx="12.5" cy="18" r="1" fill="currentColor" />
        <path d="M12.5 14L12.5 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);
export default AlertOctagon;
