import Footer from 'components/Footer';
import TopicsAnalysis from './TopicsAnalysis';
import { Container } from './styled';

const TopicsInsights = () => (
    <Container>
        <TopicsAnalysis />
        <Footer />
    </Container>
);

export default TopicsInsights;
