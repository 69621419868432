import { ReactNode } from 'react';
import { EmptyMessageContainer, EmptyMessageLabel } from './styled';

interface EmptyMessageParams {
    message?: ReactNode | string;
}

const EmptyMessage = ({
    message = '',
}: EmptyMessageParams) => {
    if (typeof message === 'string') {
        return (
            <EmptyMessageContainer>
                <EmptyMessageLabel>{message}</EmptyMessageLabel>
            </EmptyMessageContainer>
        );
    }
    return message;
};

export default EmptyMessage;
