const getLocalStorage = () => (typeof window !== 'undefined' ? localStorage : undefined);

export const getFromLocalStorage = <T>(key: string): T | undefined => {
    const data = getLocalStorage()?.getItem(key);
    if (typeof data === 'string') {
        try {
            const { value } = JSON.parse(data);
            return value as T;
        } catch (error) {
            // no catch
        }
    }
    return undefined;
};

export const saveToLocalStorage = <T>(key: string, value: T) => {
    getLocalStorage()?.setItem(key, JSON.stringify({ value }));
    window.dispatchEvent(new StorageEvent('storage', { key, newValue: JSON.stringify({ value }) }));
};

export const removeFromLocalStorage = (key: string) => getLocalStorage()?.removeItem(key);
