import { EventStatus } from '../../data/firestore/globals';
import { BadgeContainer } from './styled';

const labelMap: Record<string, string> = {
    'pending-approval': 'Pending Approval',
    rejected: 'Rejected',
};

interface Params {
    value?: EventStatus;
}

const CalendarBuiltInStatusBadge = ({
    value,
}: Params) => (
    <BadgeContainer color={value?.color as string}>
        {labelMap[value?.name as string] || value?.name}
    </BadgeContainer>
);

export default CalendarBuiltInStatusBadge;
