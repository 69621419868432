import { Llm as Responder } from 'data/firestore/agent/llm/types';
import { EntityGet } from 'data/firestore/types';
import { ModelCellWrapper } from '../styled';
import { StyledOpenAiIcon, StyledSpan } from './styled';

interface Props {
    value?: EntityGet<Responder>['model'];
}

const ModelColumn = ({ value }: Props) => (
    <ModelCellWrapper>
        <StyledOpenAiIcon />
        <StyledSpan>
            {value?.name || value?.id || 'No model'}
        </StyledSpan>
    </ModelCellWrapper>
);

export default ModelColumn;
