import { TermsOfServiceContainer } from 'pages/Login/styled';
import { Spinner } from 'react-rainbow-components';
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const StyledSpinner = styled(Spinner)`
    transform: unset;
    z-index: unset;
    position: unset;
    justify-self: center;
    display: flex;

    svg {
        width: 5rem;
        height: 5rem;
        
        circle {
            stroke-width: 1;
        }
    }
`;

export const TextContainer = styled(TermsOfServiceContainer)`
    padding: 0;
    justify-content: start;
`;
