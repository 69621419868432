import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    background-color: ${color('background.main')};
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const Content = styled.div`
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 960px;
`;
