import { DefaultTheme } from 'styled-components';
import {
    PURPLE_500, GREEN_500, WARNING, RED_500,
} from './constants';

declare module 'styled-components' {
    interface DefaultTheme {
        rainbow: {
            palette: {
                [color: string]: {
                    [type: string]: string;
                };
            };
            shadows: {
                brand: string;
                error: string;
                shadow_1: string;
                shadow_2: string;
                shadow_3: string;
                shadow_4: string;
                shadow_5: string;
                shadow_6: string;
                shadow_7: string;
                shadow_8: string;
                shadow_9: string;
                shadow_10: string;
                shadow_11: string;
                shadow_12: string;
                success: string;
            }
        };
    }
}

interface StyledThemeProps {
    theme: DefaultTheme;
}

export type ColorType = 'brand.main' | 'brand.dark' | 'brand.light' | 'success.main' | 'success.dark' | 'success.light' | 'error.main' | 'error.dark' | 'error.light' | 'warning.main' | 'warning.dark' | 'warning.light' | 'background.main' | 'background.highlight' | 'background.secondary' | 'background.disabled' | 'text.main' | 'text.title' | 'text.header' | 'text.label' | 'text.disabled' | 'border.main' | 'border.divider' | 'border.disabled' | 'action.active' | 'action.hover' | 'getContrastText' | 'isDark';

export const color = (name: ColorType): ((props: StyledThemeProps) => string) => {
    const [colorName, type] = name.split('.');
    return (props) => props.theme.rainbow.palette[colorName][type];
};

export const shadow = (shadowName: keyof DefaultTheme['rainbow']['shadows']): ((props: StyledThemeProps) => string) => (props) => props.theme.rainbow.shadows[shadowName];

const theme = {
    rainbow: {
        palette: {
            brand: PURPLE_500,
            success: GREEN_500,
            warning: WARNING,
            error: RED_500,
        },
    },
};

export default theme;
