import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import SearchIcon from 'components/icons/search';
import ChevronLeft from 'components/icons/chevronLeft';
import ChevronRight from 'components/icons/chevronRight';
import Trash from 'components/icons/trash';
import MailOpened from 'components/icons/mailOpened';
import Input from 'components/Input';
import ButtonIcon from 'components/ButtonIcon';
import useInbox from 'data/firestore/inbox/useCollectionWithPagination';
import useAlgoliaSearchData from 'data/algolia/useSearchData';
import { confirmModal, hideAppSpinner, showAppSpinner } from '@rainbow-modules/app';
import { orderBy, query, where } from 'firebase/firestore';
import { EntityGet } from 'data/firestore/types';
import { Inbox } from 'data/firestore/inbox/types';
import ListItem from './listItem';
import {
    LoadingContainer,
    PaginationContainer,
    PaginationInformation,
    StyledUl,
    Row,
    EmptyMessage,
    EmptyMessageContainer,
    Icon,
} from './styled';
import {
    Content, Header, PrimaryBar, Divider, SecondaryBar,
} from '../styled';
import DeleteModal from './deleteModal';
import { ALGOLIA_INDEX_INBOX, PAGE_SIZE } from '../../../constants';
import useUpdateItem from '../hooks/useUpdateItem';

interface Params {
    agentId: string;
    status?: string | null;
    priority?: string | null;
}

const getFilters = ({ agentId, status, priority }: Params) => {
    let filters = `agentId:${agentId}`;
    if (status) {
        if (status === 'solved') {
            filters += ' AND (_tags:solved AND _tags:unremoved)';
        }
        if (status === 'spam') {
            filters += ' AND _tags:spam';
        }
        if (status === 'removed') {
            filters += ' AND _tags:removed';
        }
        if (status === 'transferred') {
            filters += ' AND _tags:transferred';
        }
    } else {
        filters += ' AND (_tags:unsolved AND _tags:unremoved)';
    }
    if (priority) {
        filters += ` AND priority:${priority}`;
    }
    return filters;
};

const Items = () => {
    const [checkedItems, setCheckedItems] = useState<Inbox[]>([]);
    const { agentId = '' } = useParams<{ agentId: string }>();
    const [searchParams] = useSearchParams();
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const queryStatus = searchParams.get('status');
    const queryPriority = searchParams.get('priority');
    const {
        data, isLoading, totalRecords, firstPage, nextPage, prevPage,
    } = useInbox({
        limit: PAGE_SIZE,
        disableCache: true,
        disabled: Boolean(search),
        listQueryFn: (ref) => {
            let q = query(ref, where('agentId', '==', agentId));
            switch (queryStatus) {
                case 'solved':
                    q = query(q, where('_tags', 'array-contains', 'solved'), where('removedBy', '==', null));
                    break;
                case 'spam':
                    q = query(q, where('_tags', 'array-contains', 'spam'), where('removedBy', '==', null));
                    break;
                case 'removed':
                    q = query(q, where('_tags', 'array-contains', 'removed'));
                    break;
                case 'transferred':
                    q = query(q, where('_tags', 'array-contains', 'transferred'));
                    break;
                default:
                    q = query(q, where('_tags', 'array-contains', 'not-spam'), where('solvedBy', '==', null), where('removedBy', '==', null));
                    break;
            }
            if (queryPriority) {
                q = query(q, where('priority', '==', queryPriority));
            }
            q = query(q, orderBy('createdAt', 'desc'));
            return q;
        },
        track: [agentId, queryStatus || '', queryPriority || ''],
    });
    const { mutateAsync: updateItem } = useUpdateItem();

    useEffect(() => setActivePage(1), [search]);

    useEffect(() => {
        setCheckedItems([]);
    }, [queryStatus, queryPriority]);

    const algoliaData = useAlgoliaSearchData<EntityGet<Inbox>>({
        search,
        activePage,
        pageSize: PAGE_SIZE,
        indexName: ALGOLIA_INDEX_INBOX,
        filters: getFilters({ agentId, status: queryStatus, priority: queryPriority }),
        enabled: Boolean(search),
    });

    const dataToShow = algoliaData?.hits || data;
    const totalHits = typeof algoliaData?.nbHits === 'number' ? algoliaData.nbHits : totalRecords;
    const pages = Math.ceil(totalHits / PAGE_SIZE);
    const firstItemOfActivePage = dataToShow.length === 0 ? 0 : PAGE_SIZE * (activePage - 1) + 1;
    const lastItemOfActivePage = Math.min(
        PAGE_SIZE * activePage,
        (PAGE_SIZE * activePage - PAGE_SIZE) + dataToShow.length,
    );
    const hasMore = activePage < pages;

    const handleNextPage = () => {
        if (hasMore) {
            nextPage();
            setActivePage((value) => value + 1);
        }
    };

    const handlePrevPage = () => {
        if (!firstPage) {
            prevPage();
            setActivePage((value) => value - 1);
        }
    };

    const handleToggleItem = useCallback((item: Inbox, isSelected: boolean, refresh = false) => {
        if (isSelected) {
            const items = refresh ? checkedItems.filter(({ id }) => item.id !== id) : checkedItems;
            setCheckedItems([...items, item]);
        } else {
            setCheckedItems(checkedItems.filter(({ id }) => item.id !== id));
        }
    }, [checkedItems]);

    const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckedItems(dataToShow);
        } else {
            setCheckedItems([]);
        }
    };

    const handleMarkRead = async () => {
        showAppSpinner();
        try {
            await Promise.all(checkedItems.map(({ id }) => updateItem(id, 'read')));
        } catch (error) {
            // TODO: handle error
            console.error(error);
        }
        hideAppSpinner();
    };

    const handleDelete = async () => {
        const result = await confirmModal({
            header: '',
            question: <DeleteModal />,
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
            borderRadius: 'semi-square',
        });
        if (!result) return;

        showAppSpinner();
        try {
            await Promise.all(checkedItems.map(({ id }) => updateItem(id, 'remove')));
            setCheckedItems([]);
        } catch (error) {
            // TODO: handle error
            console.error(error);
        }
        hideAppSpinner();
    };

    const isAllChecked = !!dataToShow.length && checkedItems.length === dataToShow.length;
    const isSomeChecked = !!dataToShow.length && checkedItems.length > 0;
    const showBulkDelete = checkedItems.some(({ removedBy }) => !removedBy);
    const showBulkMarkRead = checkedItems.some(({ readBy }) => !readBy?.length);
    return (
        <Content>
            <Header>
                <PrimaryBar>
                    <Input
                        type="search"
                        variant="bare"
                        placeholder="Search in inbox..."
                        icon={<SearchIcon />}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </PrimaryBar>
                <Divider />
                <SecondaryBar>
                    <Row>
                        <Input type="checkbox" checked={isAllChecked} onChange={handleToggleAll} className="rainbow-m-right_x-small" />
                        <RenderIf isTrue={isSomeChecked}>
                            <RenderIf isTrue={showBulkMarkRead}>
                                <ButtonIcon variant="base" icon={<MailOpened />} size="small" title="Mark Read" tooltip="Mark as Read" onClick={handleMarkRead} />
                            </RenderIf>
                            <RenderIf isTrue={showBulkDelete}>
                                <ButtonIcon variant="base" icon={<Trash />} size="small" title="Delete" tooltip="Delete" onClick={handleDelete} />
                            </RenderIf>
                        </RenderIf>
                    </Row>
                    <PaginationContainer>
                        <PaginationInformation>
                            {firstItemOfActivePage}
                            -
                            {lastItemOfActivePage}
                            {' '}
                            of
                            {' '}
                            {totalHits}
                        </PaginationInformation>
                        <ButtonIcon variant="base" icon={<ChevronLeft />} size="small" disabled={firstPage || isLoading} onClick={handlePrevPage} />
                        <ButtonIcon variant="base" icon={<ChevronRight />} size="small" disabled={!hasMore || isLoading} onClick={handleNextPage} />
                    </PaginationContainer>
                </SecondaryBar>
                <Divider />
            </Header>
            <StyledUl>
                <RenderIf isTrue={isLoading}>
                    <LoadingContainer>
                        <LoadingShape />
                        <LoadingShape />
                        <LoadingShape />
                    </LoadingContainer>
                </RenderIf>
                <RenderIf isTrue={!isLoading && !dataToShow.length}>
                    <EmptyMessageContainer>
                        <Icon />
                        <EmptyMessage>
                            No Items Found
                        </EmptyMessage>
                    </EmptyMessageContainer>
                </RenderIf>
                <RenderIf isTrue={!isLoading && dataToShow.length > 0}>
                    {dataToShow.map((item) => (
                        <ListItem
                            key={item.id}
                            to={`${item.id}/overview`}
                            item={item}
                            isChecked={checkedItems.some(({ id }) => item.id === id)}
                            onToggle={handleToggleItem}
                        />
                    ))}
                </RenderIf>
            </StyledUl>
        </Content>
    );
};

export default Items;
