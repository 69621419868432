import { useCallback, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import InstagramSolid from 'components/icons/instagramSolid';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/agent/customer/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import PhoneIcon from 'components/icons/phone';
import SmsIcon from 'components/icons/sms';
import FacebookSolid from 'components/icons/facebookSolid';
import useSMSChat from 'hooks/useSMSChat';
import useCustomerPanel from 'hooks/useCustomerPanel';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import {
    BasicInfoContainer,
    CustomerAvatar,
    CustomerName,
    StyledLink,
    LeftContent,
    RightContent,
    StyledBadgeOverlay,
    CustomerButton,
} from './styled';

const CustomerBasicInfo = ({
    isLoading = false,
    data,
}: {
    isLoading?: boolean;
    data?: EntityGet<Customer>;
}) => {
    const customerDisplayName = useMemo(
        () => getDisplayName(data, formatIntlPhoneNumber(data?.phoneNumber)),
        [data],
    );
    const { openChat } = useSMSChat();
    const handleSMSClick = useCallback(
        () => openChat({ ...data }),
        [openChat, data],
    );

    const { openCustomerPanel } = useCustomerPanel();
    const handleCustomerPanelClick = useCallback(
        () => openCustomerPanel({ data }),
        [openCustomerPanel, data],
    );

    if (isLoading) return null;

    return (
        <BasicInfoContainer>
            <LeftContent>
                <CustomerButton type="button" onClick={handleCustomerPanelClick}>
                    <CustomerAvatar
                        size="large"
                        initials={getInitials(data as unknown as Record<string, unknown>)}
                        src={data?.photoURL}
                        value={data?.id}
                    />
                    <CustomerName>{customerDisplayName}</CustomerName>
                </CustomerButton>
            </LeftContent>
            <RightContent>
                <RenderIf isTrue={data?.phoneNumber}>
                    <StyledLink as="a" href={`tel:${data?.phoneNumber}`}>
                        <PhoneIcon />
                    </StyledLink>
                </RenderIf>
                <RenderIf isTrue={data?.phoneNumber}>
                    <ButtonIcon
                        icon={(
                            <StyledBadgeOverlay
                                overlap="circle"
                                isHidden={!data?.hasNewMessage}
                            >
                                <SmsIcon />
                            </StyledBadgeOverlay>
                        )}
                        onClick={handleSMSClick}
                    />
                </RenderIf>
                <RenderIf isTrue={data?.instagramProfileURL}>
                    <StyledLink to={String(data?.instagramProfileURL)} target="_blank">
                        <InstagramSolid />
                    </StyledLink>
                </RenderIf>
                <RenderIf isTrue={data?.facebookProfileURL}>
                    <StyledLink to={String(data?.facebookProfileURL)} target="_blank">
                        <FacebookSolid />
                    </StyledLink>
                </RenderIf>
            </RightContent>
        </BasicInfoContainer>
    );
};

export default CustomerBasicInfo;
