import { IconProps } from 'types';

const Message = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <title>{title}</title>
        <path d="M5.5 8L3 3L21 12L3 21L6 12H13.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Message;
