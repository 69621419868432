/* eslint-disable react/no-unused-prop-types */
import { ReactNode, useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistProps } from 'react-rainbow-components/components/Picklist';
import Picklist from 'components/Picklist';
import Pencil from 'components/icons/pencil2';
import Eye from 'components/icons/eye';
import { Option } from 'react-rainbow-components';
import RolePickerOption from './option';
import { PickListGlobalStyle } from './styled';

interface PicklistValue {
    label?: string;
    description?: string;
    name?: string | number;
    icon?: ReactNode;
    value?: any;
}

interface RolePickerProps extends PicklistProps {
    id?: string;
    className?: string;
    label?: string;
    name?: string;
    value?: PicklistValue;
    onChange?: (value: PicklistValue) => void;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    readOnly?: boolean;
}

const options = [
    {
        roleIcon: <Eye />,
        name: 'viewer',
        label: 'Viewer',
        description: 'Manages call history, inbox, blocked numbers, and customer details.',
        value: 'viewer',
    },
    {
        roleIcon: <Pencil />,
        name: 'editor',
        label: 'Editor',
        description: 'Includes Viewer permissions plus the ability to modify assistant behavior.',
        value: 'editor',
    },
];

const RolePicker = (props: RolePickerProps) => {
    const {
        id,
        className,
        name,
        label,
        value: valueInProps,
        onChange = () => {},
        error,
        placeholder,
        tabIndex,
        required,
    } = useReduxForm(props);

    const pickListvalue = useMemo(() => {
        if (!valueInProps) return undefined;
        return options.find((option) => option.value === valueInProps);
    }, [valueInProps]);

    const handleOnChange = useCallback(
        (value: PicklistValue) => onChange(value.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => options.map((option) => (
            <Option
                key={`role-picker-option__${option.name}`}
                component={RolePickerOption}
                name={option.name}
                label={option.label}
                value={option.value}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                roleName={option.name}
                roleIcon={option.roleIcon}
                description={option.description}
            />
        )),
        [],
    );

    return (
        <>
            <PickListGlobalStyle />
            <Picklist
                id={id}
                name={name}
                className={className}
                label={label}
                labelAlignment="left"
                value={pickListvalue}
                placeholder={placeholder}
                onChange={handleOnChange}
                tabIndex={tabIndex}
                required={required}
                error={error}
            >
                <Option variant="header" label="Select Role" />
                {pickListOptions}
            </Picklist>
        </>
    );
};

export default RolePicker;
