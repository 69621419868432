import { IconProps } from 'types';

const LockClosed = ({ title = 'LockOpen', className }: IconProps) => (
    <svg className={className} width={12} height={12} viewBox="0 0 12 12" fill="none">
        <title>{title}</title>
        <rect x="2.5" y="5.5" width="7" height="5" rx="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="6" cy="8" r="0.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 5.5V3.5C4 2.39543 4.89543 1.5 6 1.5C7.10457 1.5 8 2.39543 8 3.5V5.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default LockClosed;
