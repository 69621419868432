import { UIEvent, useMemo } from 'react';
import { Availability, WeekDay } from 'components/CalendarAvailabilityInput/types';
import StyledWeekDay from './day';
import {
    Container,
    Days,
    Grid,
    GridLine,
    Content,
    Scroll,
} from './styled';

const weekdaysNames: WeekDay[] = [
    'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday',
];

interface WeekProps {
    highlightColor: string;
    onScroll: (e: UIEvent<HTMLDivElement>) => void,
    availabileHours?: Availability[];
}

const Week = (props: WeekProps) => {
    const { highlightColor, onScroll, availabileHours = [] } = props;

    const days = useMemo(
        () => Array.from(
            Array(7),
            (_value, index) => {
                const dayAvailability = availabileHours.filter(
                    (availability: Availability) => availability.dayOfWeek === weekdaysNames[index],
                );
                return (
                    <StyledWeekDay
                        key={index}
                        highlightColor={highlightColor}
                        availability={dayAvailability}
                    />
                );
            },
        ),
        [availabileHours, highlightColor],
    );

    const gridLines = useMemo(
        () => Array.from(Array(24), (_value, index) => <GridLine key={index} />),
        [],
    );

    return (
        <Scroll onScroll={onScroll}>
            <Container>
                <Content>
                    <Grid>
                        {gridLines}
                    </Grid>
                    <Days>
                        <div />
                        {days}
                    </Days>
                </Content>
            </Container>
        </Scroll>
    );
};

export default Week;
