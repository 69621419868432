import { IconProps } from 'types';

const ClockIcon = ({ className }: IconProps) => (
    <svg className={className} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38" height="38" rx="6" fill="#00CFE8" fillOpacity="0.08" />
        <circle cx="19" cy="19" r="10.5" stroke="#00CFE8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19 13.1665V18.9998L22.5 22.4998" stroke="#00CFE8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default ClockIcon;
