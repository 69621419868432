import styled from 'styled-components';
import { ColorInput } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import Input from 'components/Input';
import {
    GREY_300, GREY_500, DIVIDER, GREY_200, HIGHLIGHT, GREY_400,
} from '../../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
`;

export const Header = styled.div`
    display: flex;
    padding: 0rem 0rem 1.5rem 1.5rem;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
`;

export const Title = styled.h3`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_500};
`;

export const Description = styled.span`
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.375rem;
    color: ${GREY_400};
    margin-bottom: 0.5rem;
`;

export const Content = styled.div`
    display: flex;
    padding: 0rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    flex-wrap: wrap;
    overflow: auto;
`;

export const ItemContainer = styled.div`
    display: flex;
    width: 17.1875rem;
    padding: 0.75rem;
    align-items: flex-start;
    gap: 1rem;
    align-content: stretch;
    align-items: center;
    gap: 1rem;
    border-radius: 0.375rem;
    background: ${HIGHLIGHT};
`;

export const ColorBox = styled.div<{ color: string; }>`
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    align-items: flex-start;
    border-radius: 0.375rem;
    background: ${(props) => props.color};
`;

export const ItemTitle = styled.div`
    display: innline-flex;
    flex-grow: 1;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${GREY_500};
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    color: ${GREY_300};
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    padding: 1rem 0 1rem;

    & > div {
        flex: 1;
    }
`;

export const StyledInput = styled(Input)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;

export const Message = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_200};
    text-align: center;
    padding-top: 0.75rem;
`;

export const StyledColorInput = styled(ColorInput)`
    label {
        margin-left: 0;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    label + div {
        div:last-child {
            visibility: hidden;
        }


        button > div > div {
            border-radius: 0.375rem;
        }
    }

    input {
        ::placeholder {
            font-weight: 300 !important;
            color: ${GREY_300};
        }
    }

    div[id^="error-message"] {
        align-self: flex-start;
    }
`;
