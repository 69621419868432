import { useParams } from 'react-router-dom';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import ButtonIcon from 'components/ButtonIcon';
import LockOpen from 'components/icons/lockOpen';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { Blockednumber } from 'data/firestore/agent/blockednumber/types';
import { ActionsCellWrapper } from '../styled';
import UnblockModal from '../unblockModal';

interface Props {
    row?: Blockednumber;
}

const Actions = ({ row }: Props) => {
    const { id, phoneNumber } = row || {};
    const { agentId } = useParams<{ agentId: string }>();
    const { mutateAsync: unblock } = useHttpMutation({
        method: 'DELETE',
        pathname: `/agents/${agentId}/blocked-numbers/${id}`,
    });

    const unblockNumber = async () => {
        if (!id) {
            return;
        }

        const result = await confirmModal({
            header: '',
            question: <UnblockModal />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Remove',
            cancelButtonLabel: 'Cancel',
        });

        if (!result) {
            return;
        }

        showAppSpinner();
        try {
            await unblock({});
            showAppNotification({
                title: `${phoneNumber} Unblocked`,
                description: 'The phone number has been removed from the Blocked List and will now be received as regular calls.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    return (
        <ActionsCellWrapper>
            <ButtonIcon borderRadius="semi-square" icon={<LockOpen />} tooltip="Unblock Number" size="small" onClick={unblockNumber} />
        </ActionsCellWrapper>
    );
};

export default Actions;
