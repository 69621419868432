import styled from 'styled-components';
import { ButtonGroupPicker, CheckboxGroup } from 'react-rainbow-components';
import Accordion from 'components/Accordion';
import AccordionSection from 'components/AccordionSection';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/Button';
import {
    PURPLE_500, PURPLE_100, PURPLE_200, GREY_400, DIVIDER, GREY_300,
} from '../../../constants';

export const Container = styled.div`
    display: flex;
    align-items: stretch;
    flex: 1 0 0;
    align-self: stretch;
    overflow: hidden;
`;

export const StyledButton = styled(Button)`
    height: 3rem;
    line-height: 3rem;
`;

export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 0.0625rem solid ${DIVIDER};
    border-radius: 1.5rem;
    width: 20rem;
    gap: 0;
    flex: 0;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden;
`;

export const LeftColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

export const Header = styled.div`
    display: flex;
    padding: 1.5rem 1.5rem 0 1.5rem;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-self: stretch;
    gap: 0.75rem;
`;

export const HeaderItems = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 0.75rem;
`;

export const Title = styled.div`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_400};
    flex: 1;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;

export const StyledButtonGroupPicker = styled(ButtonGroupPicker)`
    background: ${PURPLE_100};

    label {
        z-index: unset;
        border: none;
        box-shadow: none;

        span {
            color: ${PURPLE_500};
            background: transparent;
            z-index: unset;
            box-shadow: none;
            border: none;
        }
        
        input:checked + span {
            background: ${PURPLE_200};
            box-shadow: none;
            border: none;
        }
    }
`;

export const Divider = styled.div`
    display: flex;
    align-self: stretch;
    height: 0.0625rem;
    background: ${DIVIDER};
`;

export const StyledAccordion = styled(Accordion)`
    li {
        & > button {
            display: flex;
            padding: 1.5rem 1.5rem 1rem;
    
            svg {
                order: 2;
                path {
                    fill: ${GREY_300};
                }
            }
    
            h3 {
                font-size: 0.8125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.25rem;
                color: ${GREY_300};
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                order: 1;
            }
        }
    }
`;

export const StyledAccordionSection = styled(AccordionSection)`
    section[id^="accordion-section-details"] {
        padding: 0.5rem 1.5rem 1.5rem;
        background: transparent;
    }
`;

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    & > div {
        gap: 0.75rem;
    }

    label > span:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400}
    }
`;
