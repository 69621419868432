import { IconProps } from 'types';

const Pencil = ({ className, title }: IconProps) => (
    <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <title>{title}</title>
        <path d="M7.49992 5.83203H4.99992C4.07944 5.83203 3.33325 6.57822 3.33325 7.4987V14.9987C3.33325 15.9192 4.07944 16.6654 4.99992 16.6654H12.4999C13.4204 16.6654 14.1666 15.9192 14.1666 14.9987V12.4987" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 12.4995H10L17.0833 5.4162C17.7737 4.72585 17.7737 3.60656 17.0833 2.9162C16.393 2.22585 15.2737 2.22585 14.5833 2.9162L7.5 9.99954V12.4995" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.3333 4.16797L15.8333 6.66797" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export default Pencil;
