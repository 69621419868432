import {
    ComponentType, useCallback, useMemo, useRef, useState,
} from 'react';
import { useOutsideClick, useWindowResize } from '@rainbow-modules/hooks';
import { Provider } from 'react-rainbow-components/components/PrimitiveMenu/context';
import ButtonIcon from 'components/ButtonIcon';
import Check from 'components/icons/check';
import Close from 'components/icons/close';
import SortAscIcon from 'components/icons/sortAsc';
import SortDescIcon from 'components/icons/sortDesc';
import { InternalOverlay, RenderIf } from 'react-rainbow-components';
import positionResolver from './resolver';
import { SortOrder } from '../../types';
import {
    Divider, DropDown, LabelContainer, StyledMenuItem, Header, StyledButton,
} from './styled';

const sortDirectionIconMap: Record<SortOrder, ComponentType<{ className?: string }>> = {
    asc: SortAscIcon,
    desc: SortDescIcon,
};

const sortDirections = [
    { name: 'asc', label: 'Ascending', icon: <sortDirectionIconMap.asc className="rainbow-m-right_small" /> },
    { name: 'desc', label: 'Descending', icon: <sortDirectionIconMap.desc className="rainbow-m-right_small" /> },
];

export interface SortByPickerValue<T = string> {
    sortBy: T;
    order: SortOrder;
}

interface SortField {
    name: string;
    label?: string;
}

interface SortByPickerProps<T extends string = string> {
    sortFields?: SortField[],
    value?: SortByPickerValue<T>;
    onChange?: (newValue: SortByPickerValue<T>) => void;
    iconPosition?: 'left' | 'right';
    prefixLabel?: string;
    borderRadius?: 'square' | 'semi-square' | 'semi-rounded' | 'rounded';
}

const SortByPicker = ({
    sortFields = [],
    value: valueInProps = { order: 'asc', sortBy: '' },
    iconPosition = 'left',
    onChange = () => {},
    prefixLabel,
    borderRadius = 'rounded',
}: SortByPickerProps<string>) => {
    const buttonRef = useRef();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(false);

    useWindowResize(() => setOpen(false), isOpen);
    useOutsideClick(dropdownRef, () => setOpen(false), isOpen);

    const toggle = useCallback(() => setOpen(!isOpen), [isOpen]);

    const close = useCallback(() => setOpen(false), []);

    const changeSortOrder = useCallback(
        (newOrder: SortOrder) => onChange({
            ...valueInProps,
            order: newOrder,
        }),
        [onChange, valueInProps],
    );

    const changeSortBy = useCallback(
        (newSortBy: string) => onChange({
            ...valueInProps,
            sortBy: newSortBy,
        }),
        [onChange, valueInProps],
    );

    const sortOptions = useMemo(
        () => sortFields.map(
            (field) => {
                const icon = (
                    valueInProps?.sortBy === field.name
                        ? <Check />
                        : undefined
                );
                return (
                    <StyledMenuItem
                        key={field.name}
                        icon={icon}
                        iconPosition="right"
                        label={field.label || field.name}
                        onClick={() => changeSortBy(field.name)}
                    />
                );
            },
        ),
        [changeSortBy, sortFields, valueInProps],
    );

    const sortDirectionsOptions = useMemo(
        () => sortDirections.map(
            (direction) => {
                const icon = (
                    valueInProps?.order === direction.name
                        ? <Check />
                        : undefined
                );
                return (
                    <StyledMenuItem
                        key={direction.name}
                        icon={icon}
                        iconPosition="right"
                        label={(
                            <LabelContainer>
                                {direction.icon}
                                {direction.label}
                            </LabelContainer>
                        )}
                        onClick={() => changeSortOrder(direction.name as SortOrder)}
                    />
                );
            },
        ),
        [changeSortOrder, valueInProps],
    );

    const buttonLabel = useMemo(
        () => {
            const found = sortFields.find(
                (field) => valueInProps?.sortBy === field.name,
            ) || sortFields[0];
            const label = found?.label || '';
            if (prefixLabel) return `${prefixLabel} ${label}`;
            return label;
        },
        [sortFields, valueInProps, prefixLabel],
    );

    const SortIcon = useMemo(
        () => sortDirectionIconMap[valueInProps?.order || 'asc'] || sortDirectionIconMap.asc,
        [valueInProps],
    );

    const contextValue = useMemo(() => ({
        privateOnClose: () => {},
        privateOnHover: (event: any, childRef: any) => childRef.focus(),
        privateRegisterChild: () => {},
        privateUnregisterChild: () => {},
    }), []);

    return (
        <>
            <StyledButton
                ref={buttonRef}
                variant="base"
                onClick={toggle}
                borderRadius={borderRadius}
            >
                <RenderIf isTrue={iconPosition === 'left'}>
                    <SortIcon className="rainbow-m-right_small" />
                </RenderIf>
                {buttonLabel}
                <RenderIf isTrue={iconPosition === 'right'}>
                    <SortIcon className="rainbow-m-left_small" />
                </RenderIf>
            </StyledButton>
            <InternalOverlay
                isVisible={isOpen}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                triggerElementRef={() => buttonRef.current.htmlElementRef}
                positionResolver={positionResolver}
            >
                <DropDown ref={dropdownRef}>
                    <Provider value={contextValue}>
                        <Header>
                            Sort by
                            <ButtonIcon
                                icon={<Close />}
                                onClick={close}
                            />
                        </Header>
                        {sortOptions}
                        <Divider />
                        {sortDirectionsOptions}
                    </Provider>
                </DropDown>
            </InternalOverlay>
        </>
    );
};

export default SortByPicker;
