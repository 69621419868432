import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';

interface DeleteModalProps {
    partialName: string;
}

const DeleteModal = ({ partialName }: DeleteModalProps) => (
    <ModalBody
        title={`Are you sure you want to delete the "${partialName}" partial?`}
        description="Please confirm that you want to delete the selected partial. If you delete this partial, it will be permanently removed from your instructions."
        icon={<Trash />}
        variant="destructive"
    />
);

export default DeleteModal;
