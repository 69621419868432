import { IconProps } from 'types';

const HelpIcon = ({ className, title }: IconProps) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title}</title>
        <circle cx="9" cy="9" r="6.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 12.7502V12.7577" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 10.125C8.97163 9.62484 9.2774 9.16618 9.75 9C10.523 8.70438 11.0252 7.95301 11.0028 7.12569C10.9803 6.29838 10.438 5.57537 9.65011 5.32215C8.86218 5.06893 8.00024 5.34067 7.5 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);
export default HelpIcon;
