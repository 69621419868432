import { ColumnHeaderProps } from '../types';
import { ColumnTitleContainer, ColumnTitleLabel } from './styled';

const ColumnTitle = ({
    title,
    column,
    cards = [],
}: ColumnHeaderProps) => (
    <ColumnTitleContainer>
        <ColumnTitleLabel removable={column.removable}>{title}</ColumnTitleLabel>
        {cards.length}
    </ColumnTitleContainer>
);

export default ColumnTitle;
