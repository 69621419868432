/* eslint-disable react/forbid-component-props */
import { useUniqueIdentifier } from '@rainbow-modules/hooks';
import { CalendarEventClick } from 'types';
import useEventStyle from './hooks/useEventStyle';
import {
    StyledEvent,
    StyledEventBackground,
    StyledEventTitle,
    StyledEventDescription,
} from './styled';

interface Params {
    id?: string;
    title: string;
    description: string;
    startAt: Date;
    duration: number;
    color: string;
    onEventClick?: CalendarEventClick;
    timeZone: string;
    isSelected?: boolean;
    isPending?: boolean;
    isRejected?: boolean;
    positionInSlot?: {
        left: number;
        width?: number;
    };
}

const Event = ({
    onEventClick = () => {},
    timeZone,
    isSelected = false,
    isPending = false,
    isRejected = false,
    positionInSlot = {
        left: 0,
    },
    ...event
}: Params) => {
    const {
        title, description, color, startAt, duration,
    } = event;
    const eventId = useUniqueIdentifier('calendar-event');
    const style = useEventStyle({
        startDate: startAt,
        duration: duration / 60,
        timeZone,
    });

    return (
        <StyledEvent
            id={`day-view-event__${eventId}`}
            left={positionInSlot.left}
            width={positionInSlot.width}
            style={style}
            onClick={(e) => {
                e.stopPropagation();
                return onEventClick({
                    triggerRef: {
                        current: e.target,
                    },
                    event,
                });
            }}
        >
            <StyledEventBackground
                color={color}
                isSelected={isSelected}
                isPending={isPending}
                isRejected={isRejected}
            >
                <StyledEventTitle>{title}</StyledEventTitle>
                <StyledEventDescription>{description}</StyledEventDescription>
            </StyledEventBackground>
        </StyledEvent>
    );
};

export default Event;
