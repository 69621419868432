import styled, { css } from 'styled-components';
import { CheckboxGroup } from 'react-rainbow-components';
import { color } from 'theme';
import { GREY_400 } from '../../constants';

export const StyledCheckBoxGroup = styled(CheckboxGroup)`
    & > div {
        gap: 0.25rem;
    }

    input[disabled] + label > span:first-child {
        background-color: ${color('brand.light')};
        border-color: ${color('brand.main')};
        color: ${color('brand.main')};
        
        &::after {
            border-color: ${color('brand.main')};
        }
    }

    label > span:last-child {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400};
    }
`;

export const Label = styled.span<{ isBuiltIn?: boolean }>`
    ${(props) => props.isBuiltIn && css`
        text-transform: capitalize;
    `};
`;
