import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    z-index: 8;
`;

export const Title = styled.h1`
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.75rem;
`;

export const Actions = styled.div`
    display: flex;
    padding: 0.25rem;
`;

export const TasksListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    height: 100%;
    overflow: auto;
    padding: 2rem 1.5rem 3rem 1.5rem;
    margin: -0.5rem -1.5rem 0 -1.5rem;
`;
