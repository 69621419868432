import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import Button from 'components/Button';
import { DIVIDER, WHITE } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
    height: 100%;
`;

export const InitChatButton = styled(Button)`
    flex: 0;
    align-self: center;
    margin: auto;
`;

export const ChatMessageContainer = styled.div`
    display: flex;
    padding: 1.5rem 0.75rem;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    border-bottom: 1px solid ${DIVIDER};
`;

export const ChatMessageRole = styled.div`
    width: 6.4375rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: uppercase;
    cursor: default;
`;

export const ChatMessageContent = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    flex: 1;
`;

export const ChatMessagesList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    padding: 0 2.25rem;
    overflow: auto;
`;

export const Footer = styled.div<{ align?: string; }>`
    display: flex;
    padding: 0.5rem 1.25rem 0.5rem 2.25rem;
    justify-content: ${(props) => props.align || 'flex-start'};
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
`;

export const StyledTextarea = styled(Textarea)`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    label + div {
        box-shadow: none;
        background: ${WHITE};
    }
    textarea {
        padding-left: 0;
        padding-right: 0;
        max-height: 6.25rem;

        &:focus,
        &:active {
            padding: 0.5rem;
        }

        &[readonly] {
            &:focus,
            &:active {
                padding: unset;
            }            
        }
    }
`;
