import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
`;

export const InputsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
`;

export const ErrorText = styled.div`
    font-size: 0.875rem;
    margin-top: 0.5rem;
    align-self: left;
    color: ${color('error.main')};
`;
