import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import ModalTitle from 'components/ModalTitle';
import { ADD_EDIT_CALENDAR_EVENT_TYPE } from '../../../../../constants';

const useManageEventType = ({
    agentId,
    onEventTypeCreated = () => {},
    onEventTypeDeleted = () => {},
} : {
    agentId: string;
    onEventTypeCreated?: (id: string) => void;
    onEventTypeDeleted?: () => void;
}) => {
    const [openEventTypeForm, closeEventTypeForm] = useOpenModal(ADD_EDIT_CALENDAR_EVENT_TYPE);

    const { mutateAsync: createEventType } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/event-types`,
        onSuccess: closeEventTypeForm,
    });

    const { mutateAsync: updateEventType } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closeEventTypeForm,
    });

    const { mutateAsync: deleteEventType } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreateEventType = useCallback(
        (initialValues: Record<string, unknown>) => openEventTypeForm({
            title: <ModalTitle>Create New Event Type</ModalTitle>,
            message: 'Specify the basic information for a new event type to match your scheduling needs.',
            submitButtonLabel: 'Create',
            initialValues,
            onSubmit: async (
                {
                    name,
                    description,
                    duration,
                    eventTypeIncrement,
                }: {
                    name: string;
                    description: string;
                    duration: number;
                    eventTypeIncrement: number;
                },
            ) => {
                showAppSpinner();
                try {
                    const { id } = await createEventType({
                        body: {
                            name,
                            description,
                            duration,
                            eventTypeIncrement,
                        },
                    }) as Calendarevent;
                    onEventTypeCreated(id);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [createEventType, onEventTypeCreated, openEventTypeForm],
    );

    const handleEditEventType = useCallback(
        (eventData: {
            id: string;
            name: string;
            description: string;
            duration: number;
            eventTypeIncrement?: number;
        }) => openEventTypeForm({
            id: eventData.id,
            title: <ModalTitle>Edit Event Type</ModalTitle>,
            message: 'Modify the basic information for this event type to match your scheduling needs.',
            submitButtonLabel: 'Update',
            initialValues: eventData,
            onSubmit: async ({
                name: newName,
                description: newDescription,
                duration: newDuration,
                eventTypeIncrement: newEventTypeIncrement,
            }: {
                name: string;
                description: string;
                duration: number;
                eventTypeIncrement: number;
            }) => {
                showAppSpinner();
                try {
                    await updateEventType({
                        pathname: `/agents/${agentId}/event-types/${eventData.id}`,
                        body: {
                            name: newName,
                            description: newDescription,
                            duration: newDuration,
                            eventTypeIncrement: newEventTypeIncrement,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, openEventTypeForm, updateEventType],
    );

    const handleDeleteEventType = useCallback(
        async (eventId: string) => {
            const result = await confirmModal({
                header: '',
                question: (
                    <ModalBody
                        icon={<Trash />}
                        title="Remove Event Type?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deleteEventType({
                    pathname: `/agents/${agentId}/event-types/${eventId}`,
                });
                onEventTypeDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deleteEventType, onEventTypeDeleted],
    );

    return {
        create: handleCreateEventType,
        edit: handleEditEventType,
        remove: handleDeleteEventType,
    };
};

export default useManageEventType;
