import Checks from 'components/icons/checks';
import AlertCircle from 'components/icons/alertCircle';
import styled from 'styled-components';
import {
    GREEN_600, GREY_200, GREY_400, RED_500,
} from '../../../../../constants';

export const StyledReadCheck = styled(Checks)`
    color: ${GREEN_600} !important;
`;

export const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    & > svg {
        display: flex;
        width: 1.125rem !important; 
        height: 1.125rem !important;
        justify-content: center;
        align-items: center;
        color: ${GREY_200};
    }
`;

export const StatusLabel = styled.span`
    color: ${GREY_400};
    font-size: 0.625rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.375rem;
`;

export const StyledAlertCircle = styled(AlertCircle)`
    color: ${RED_500} !important;
`;
