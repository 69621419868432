import { IconProps } from 'types';

const SidebarShrinkIcon = ({ title = 'Shrink Sidebar', className }: IconProps) => (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>
        <path d="M18.3536 11.6464L20.1464 9.85355C20.4614 9.53857 21 9.76165 21 10.2071L21 13.7929C21 14.2383 20.4614 14.4614 20.1464 14.1464L18.3536 12.3536C18.1583 12.1583 18.1583 11.8417 18.3536 11.6464Z" fill="currentColor" />
        <path d="M4 6H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M4 18H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        <path d="M4 12H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default SidebarShrinkIcon;
