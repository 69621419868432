import { IconProps } from 'types';

const MicrophoneIcon = ({ className, title }: IconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <title>{title}</title>
        <rect x="9" y="3" width="7" height="11" rx="3.5" stroke="currentColor" strokeWidth="2" />
        <path d="M12.5 18C15.7655 18 18.5436 15.913 19.5732 13M12.5 18C9.23445 18 6.45635 15.913 5.42676 13M12.5 18V21M9 21H12.5M16 21H12.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />

    </svg>

);
export default MicrophoneIcon;
