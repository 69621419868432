import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';

const RemoveModal = () => (
    <ModalBody
        icon={<Trash />}
        title="Remove Affiliate?"
        description="Please confirm if you want to remove this affiliate."
    />
);

export default RemoveModal;
