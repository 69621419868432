import styled from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import SaraAvatar from 'components/icons/saraAvatar';

export const Container = styled.div`
    flex-shrink: 0;
`;

export const Avatar = styled(UserAvatar)`
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    line-height: 3rem;
`;

export const StyledSaraAvatar = styled(SaraAvatar)`
    width: 3.3125rem;
    height: fit-content;
    flex-shrink: 0;
    position: relative;
`;
