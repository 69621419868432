import { Outlet, useParams } from 'react-router-dom';
import CalendarStatusIcon from 'components/icons/calendarStatus';
import ChevronLeft from 'components/icons/chevronLeft';
import FocusIcon from 'components/icons/focus';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import {
    Container,
    Content,
    Divider,
    Header,
    HeaderItems,
    LeftColumn,
    Options,
    RightColumn,
    StyledButtonIcon,
    StyledCalendarIcon,
    Title,
} from './styled';
import MenuItem from './menuItem';

const CalendarSettingsPage = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();
    return (
        <Container>
            <Header>
                <HeaderItems>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<ChevronLeft />}
                        onClick={() => navigate(`/${agentId}/calendar`)}
                    />
                    <Title title="Settings">Calendar Settings</Title>
                </HeaderItems>
            </Header>
            <Divider />
            <Content>
                <LeftColumn>
                    <Options>
                        <MenuItem to="event-types" label="Event Types" icon={<FocusIcon />} />
                        <MenuItem to="event-status" label="Event Status" icon={<CalendarStatusIcon />} />
                        <MenuItem to="calendars" label="Calendars" icon={<StyledCalendarIcon />} />
                    </Options>
                </LeftColumn>
                <RightColumn>
                    <Outlet />
                </RightColumn>
            </Content>
        </Container>
    );
};

export default CalendarSettingsPage;
