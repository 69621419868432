import styled, { css } from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import Badge from 'components/Badge';
import {
    BACKGROUND,
    GREY_200,
    GREY_300,
    GREY_800,
    PURPLE_100,
    PURPLE_500,
    WHITE,
    GREY_400,
    GREY_100,
} from '../../constants';

export const Container = styled.div`
    position: relative;
    display; flex;
    align-self: stretch;
`;

export const StyledUniversalPicker = styled(UniversalPicker)`
    overflow-x: auto;
    padding: 2px 2px 2px 2px;

    & > div {
        display: flex;
        width: max-content;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        flex-wrap: nowrap;
        margin: 0;
    }

    div > div {
        margin: 0;
    }
`;

export const ItemContainer = styled.div<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean; count?: number;
}>`
    display: inline-flex;
    padding: 1.5rem;
    align-items: center;
    gap: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    box-shadow: 0 0 0 1px ${GREY_200};
    cursor: pointer;

    ${(props) => props.count && css`
        & span:last-child {
            color: ${WHITE} !important;
        }`}

    ${(props) => (props.isHover) && css`
        background: ${PURPLE_100};
        box-shadow: 0 0 0 1px ${GREY_200};
    `};

    ${(props) => (props.isSelected) && css`
        box-shadow: 0 0 0 2px ${PURPLE_500};
        background: ${PURPLE_100};
        color: ${PURPLE_500};

        ${(props.isHover) && `
            background: ${PURPLE_100};
        `};
    `};
`;

export const ItemLabel = styled.span<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean; count?: number;
}>`
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem; 
    white-space: nowrap;
    color: ${GREY_800};

    ${(props) => (props.isSelected || (props.isHover && !props.disabled)) && css`
        color: ${PURPLE_500};
    }`
}`;

export const StyledIcon = styled.div<{
    isSelected?: boolean; disabled?: boolean; isHover?: boolean; count?: number;
}>`
    color: ${GREY_300};

    ${(props) => (props.isSelected || (props.isHover && !props.disabled)) && css`
        color: ${PURPLE_500};
    }`
}`;

export const Gradient = styled.div`
    position: absolute;
    top: 0;
    right: -1.5rem;
    width: 5.25rem;
    height: 100%;
    background: linear-gradient(-90deg, ${BACKGROUND} 5.81%, ${BACKGROUND}00 100%);
    pointer-events: none;
`;

export const StyledBadge = styled(Badge)<{
    isSelected?: boolean; isHover?: boolean;
}>`
    background: ${GREY_400};
    color: ${GREY_100};

    ${(props) => (props.isSelected || props.isHover) && css`
        background: ${PURPLE_500};
        color: ${PURPLE_100};
    `};
`;
