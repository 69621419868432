/* eslint-disable react/no-unused-prop-types */
import { useCallback, useMemo } from 'react';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { useReduxForm } from '@rainbow-modules/hooks';
import { Calendar } from 'data/firestore/agent/calendar/types';
import { PickerVariantProps } from './types';
import { StyledUniversalPicker, PickerOptionContainer } from './styled';

const CalendarEventTypePickerVariantPicker = (props: PickerVariantProps) => {
    const {
        value: valueInProps,
        onChange = () => {},
        id,
        className,
        name,
        label,
        error,
        required,
        eventTypes = [],
        isLoading = false,
    } = useReduxForm(props);

    const pickerValue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Calendar).id
                );
                const found = eventTypes.find((eventType) => eventType.id === valueId);
                if (found) {
                    if (typeof valueInProps === 'string') {
                        onChange(found);
                    }
                    return found.id;
                }
            }
            if (eventTypes.length > 0) {
                const firstInList = eventTypes[0];
                onChange(firstInList);
                return firstInList.id;
            }
        }
        return undefined;
    }, [eventTypes, isLoading, onChange, valueInProps]);

    const pickerOptions = useMemo(
        () => eventTypes.map(
            (eventType) => (
                <UniversalPickerOption
                    key={`calendar-event-type__${eventType.id}`}
                    component={PickerOptionContainer}
                    name={eventType.id}
                >
                    {eventType.name}
                </UniversalPickerOption>
            ),
        ),
        [eventTypes],
    );

    const handleOnChange = useCallback(
        (newValue: string | string[]) => {
            const calendarId = typeof newValue === 'string' ? newValue : newValue[0];
            const found = eventTypes.find((eventType) => eventType.id === calendarId);
            if (found) {
                onChange(found);
            }
        },
        [eventTypes, onChange],
    );

    return (
        <StyledUniversalPicker
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            required={required}
            value={pickerValue}
            onChange={handleOnChange}
            error={error}
        >
            {pickerOptions}
        </StyledUniversalPicker>
    );
};

export default CalendarEventTypePickerVariantPicker;
