/* eslint-disable react/no-unused-prop-types */
import { CalendarEventTypePickerProps } from './types';
import CalendarEventTypePickerVariantSelect from './select';
import CalendarEventTypePickerVariantPicker from './picker';

const CalendarEventTypePicker = (props: CalendarEventTypePickerProps) => {
    const {
        variant = 'select',
        ...rest
    } = props;

    if (variant === 'picker') {
        return (
            <CalendarEventTypePickerVariantPicker
                {...rest}
            />
        );
    }

    return (
        <CalendarEventTypePickerVariantSelect
            {...rest}
        />
    );
};

export default CalendarEventTypePicker;
