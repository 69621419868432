import Button from 'components/Button';
import ChevronDown from 'components/icons/chevronDown';
import styled from 'styled-components';
import { color } from 'theme';

export const PicklistButtonContainer = styled.div`
    position: relative;
`;

export const PicklistContainer = styled.div`
    min-width: 30rem;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
`;

export const StyledButton = styled(Button)`
    z-index: 1;
    background-color: ${color('background.highlight')};
`;

export const StyledChevronIcon = styled(ChevronDown)`
    width: 1.2rem;
    height: 1.2rem;
    margin-left: .5rem;
`;
