import styled from 'styled-components';
import { DatePicker } from 'react-rainbow-components';
import { DIVIDER } from '../../constants';

export default styled(DatePicker)`
    input {
        border-radius: 0.375rem;
        border: 1px solid ${DIVIDER};

        ::placeholder {
            font-weight: 300 !important;
        }
    }
`;
