import {
    CellWrapper, ButtonsContainer, StyledCopyToClipboardButton, StyledSpan,
} from './styled';

interface Props {
    value?: string;
}

const UrlColumn = ({ value }: Props) => (
    <CellWrapper>
        <StyledSpan>
            {value}
        </StyledSpan>
        <ButtonsContainer>
            <StyledCopyToClipboardButton mode="icon" value={value || ''} borderRadius="semi-square" />
        </ButtonsContainer>
    </CellWrapper>
);

export default UrlColumn;
