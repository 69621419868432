import { useMemo } from 'react';
import { RenderIf, RainbowThemeContainer } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import { getFormatter } from 'data/services/date/formatter';
import { PURPLE_200 } from '../../../../constants';
import {
    BubbleContainer, MessageContainer, DetailsButton,
    StyledSaraAvatar, SummaryContainer, SummaryHeader,
    DateLabel,
} from './styled';

interface TaskSummaryProps {
    header?: string;
    summary?: string;
    isLoading?: boolean;
    date?: Date;
    hideDetailsLink?: boolean;
    showDate?: boolean;
    onDetailsClick?: () => void;
}

const theme = {
    rainbow: {
        palette: {
            mainBackground: PURPLE_200,
        },
    },
};

const TaskSummary = ({
    header,
    summary,
    isLoading,
    date,
    showDate = false,
    hideDetailsLink = false,
    onDetailsClick = () => {},
}: TaskSummaryProps) => {
    const formattedTime = useMemo(
        () => getFormatter('en-US', { hour12: true, hour: 'numeric', minute: '2-digit' }).format(date),
        [date],
    );

    return (
        <SummaryContainer>
            <StyledSaraAvatar />
            <BubbleContainer>
                <SummaryHeader>
                    {header}
                    <RenderIf isTrue={showDate && date}>
                        <DateLabel>{`at ${formattedTime}`}</DateLabel>
                    </RenderIf>
                </SummaryHeader>
                <MessageContainer>
                    <RenderIf isTrue={isLoading}>
                        <RainbowThemeContainer theme={theme}>
                            <LoadingShape shape="rounded-rect" width="100%" height="10px" />
                            <LoadingShape shape="rounded-rect" width="60%" height="10px" />
                        </RainbowThemeContainer>
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        {summary}
                    </RenderIf>
                </MessageContainer>
                <RenderIf isTrue={!hideDetailsLink}>
                    <DetailsButton onClick={onDetailsClick}>See all details</DetailsButton>
                </RenderIf>
            </BubbleContainer>
        </SummaryContainer>
    );
};

export default TaskSummary;
