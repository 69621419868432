import { IconProps } from 'types';

const Light = ({ title = 'File Text', className }: IconProps) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <title>{title}</title>

        <path d="M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13V11ZM4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11V13ZM13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3H13ZM11 4C11 4.55228 11.4477 5 12 5C12.5523 5 13 4.55228 13 4H11ZM20 11C19.4477 11 19 11.4477 19 12C19 12.5523 19.4477 13 20 13V11ZM21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11V13ZM6.30706 4.89289C5.91653 4.50237 5.28337 4.50237 4.89284 4.89289C4.50232 5.28341 4.50232 5.91658 4.89284 6.3071L6.30706 4.89289ZM5.59284 7.0071C5.98337 7.39763 6.61653 7.39763 7.00706 7.0071C7.39758 6.61658 7.39758 5.98341 7.00706 5.59289L5.59284 7.0071ZM19.1072 6.3071C19.4977 5.91658 19.4977 5.28341 19.1072 4.89289C18.7166 4.50237 18.0835 4.50237 17.6929 4.89289L19.1072 6.3071ZM16.9929 5.59289C16.6024 5.98341 16.6024 6.61658 16.9929 7.0071C17.3835 7.39763 18.0166 7.39763 18.4072 7.0071L16.9929 5.59289ZM3 13H4V11H3V13ZM11 3V4H13V3H11ZM20 13H21V11H20V13ZM4.89284 6.3071L5.59284 7.0071L7.00706 5.59289L6.30706 4.89289L4.89284 6.3071ZM17.6929 4.89289L16.9929 5.59289L18.4072 7.0071L19.1072 6.3071L17.6929 4.89289Z" fill="currentColor" />
        <path d="M9.00032 16C7.2786 14.7087 6.57634 12.4606 7.25691 10.4189C7.93748 8.37715 9.84817 7 12.0003 7C14.1525 7 16.0632 8.37715 16.7437 10.4189C17.4243 12.4606 16.722 14.7087 15.0003 16C14.2085 16.7838 13.8371 17.8978 14.0003 19C14.0003 20.1046 13.1049 21 12.0003 21C10.8958 21 10.0003 20.1046 10.0003 19C10.1635 17.8978 9.79216 16.7838 9.00032 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.7002 17H14.3002" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Light;
