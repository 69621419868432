import ButtonIcon from 'components/ButtonIcon';
import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 12px;
    align-items: end;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 100px;
`;

export const PlayButton = styled(ButtonIcon)`
    flex-shrink: 0;
`;

export const TimeLineContainer = styled.div`
    display: inline-flex;
    flex: 1;
`;

export const WaveformGlobalStyle = createGlobalStyle`

    #wave ::part(cursor) {
        border-radius: 0;
    }
`;

export const Wave = styled.div`
    flex: 1;
`;
