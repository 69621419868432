import { IconProps } from 'types';

const LockOpen = ({ title = 'LockOpen', className }: IconProps) => (
    <svg className={className} width={22} height={22} viewBox="0 0 22 22" fill="none">
        <title>{title}</title>
        <rect x="4.5835" y="10.083" width="12.8333" height="9.16667" rx="2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <ellipse cx="11.0002" cy="14.6667" rx="0.916667" ry="0.916667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.3335 10.083V5.49967C7.3335 3.47463 8.97512 1.83301 11.0002 1.83301C13.0252 1.83301 14.6668 3.47463 14.6668 5.49967" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default LockOpen;
