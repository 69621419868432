import styled, { css } from 'styled-components';
import { Badge } from 'react-rainbow-components';
import {
    PURPLE_500,
    PURPLE_100,
    GREEN_700,
    GREEN_100,
    RED_600,
    RED_100,
    ORANGE_700,
    ORANGE_100,
    GREY_100,
    GREY_700,
    YELLOW_100,
    YELLOW_600,
} from '../../constants';

export default styled(Badge)`
    flex-shrink: 0;
    text-transform: none;
    color: ${GREY_700};
    background-color: ${GREY_100};
    border-radius: 5rem;
    text-transform: capitalize;
    font-weight: 700;
    padding: 0 0.5rem;
    line-height: 1.5rem;
    font-size: 0.625rem;
    border: none;
    min-width: 1.5rem;

    ${(props) => props.variant === 'default' && css`
        color: ${GREY_700};
        background-color: ${GREY_100};
        border: none;
    `}

    ${(props) => props.variant === 'error' && css`
        color: ${RED_600};
        background-color: ${RED_100};
        border: none;
    `}

    ${(props) => props.variant === 'success' && css`
        color: ${GREEN_700};
        background-color: ${GREEN_100};
        border: none;
    `}

    ${(props) => props.variant === 'warning' && css`
        color: ${YELLOW_600};
        background-color: ${YELLOW_100};
        border: none;
    `}

    ${(props) => props.variant === 'brand' && css`
        color: ${PURPLE_500};
        background-color: ${PURPLE_100};
        border: none;
    `}

    ${(props) => props.variant === 'inverse' && css`
        color: ${ORANGE_700};
        background-color: ${ORANGE_100};
        border: none;
    `}

    ${(props) => props.size === 'large' && css`
        height: 3rem;
        padding: 0.75rem 1.5rem;
        border: none;
    `}

    ${(props) => props.size === 'medium' && css`
        font-size: 0.625rem;
        border: none;
    `}
`;
