import styled, { css } from 'styled-components';
import Divider from 'components/icons/divider';
import Logo from 'components/Logo';
import LogoLarge from 'components/LogoLarge';
import LockClosed from 'components/icons/lockClosed';
import {
    GREY_600, RED_500, GREY_300,
} from '../../constants';

interface ShrinkedProps {
    isShrinked?: boolean;
}

export const Container = styled.aside<ShrinkedProps>`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100vh;
  padding: 0 0.38rem 0.88rem 1.5rem;
  z-index: 4;
  transition: width 200ms ease 0s;

    ${(props) => props.isShrinked && css`
        width: 100px;
        // width: fit-content;
        // min-width: fit-content;
    `}
`;

export const Header = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.65rem;
`;

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.65rem;
    padding: 1.5rem 0 0.5rem;
`;
export const StyledLogoLarge = styled(LogoLarge)`
    width: 148px;
    height: auto;
    padding: 0.9rem 0 1.5rem 0;
`;

export const StyledLogoSmall = styled(Logo)`
    width: 3rem;
`;

export const HeaderTitle = styled.h1`
    font-family: 'Public Sans';
    font-size: 1.375rem;
    font-weight: 600;
    color: ${GREY_600};
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

export const Options = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
`;

export const Separator = styled.h2`
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.375rem;
    margin: 0 1rem;
    margin-top: 1.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    padding-top: 0.75rem;
    color: ${GREY_600};
`;

export const DividerIcon = styled(Divider)`
    color: ${GREY_600};
    margin-right: auto;
    margin-left: auto;
    margin-top: 0.5rem;
`;

const colorMap = {
    red: RED_500,
    gray: GREY_300,
};

export const CustomBadge = styled.span<{ color: 'red' | 'gray' }>`
    color: ${({ color }) => colorMap[color]};
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.75rem;
`;

export const LockClosedIcon = styled(LockClosed)`
    width: 1.45rem;
    height: 1.45rem;
`;
