import { useEffect, useState } from 'react';
import { query, where } from 'firebase/firestore';
import { useIsMutating } from 'react-query';
import getCount from 'data/firestore/inbox/count';

interface UseUnsolvedCountProps {
    agentId: string;
    priority?: 'low' | 'medium' | 'high' | 'urgent';
}

const useUnsolvedCount = ({ agentId, priority }: UseUnsolvedCountProps) => {
    const [count, setCount] = useState<number | null>(null);
    // TODO: when we fix `useHttpMutation` to allow pass `mutationKey`
    // we can then filter by the key
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isMutating) return;

        const fetchCount = async () => {
            const totalPromise = getCount({
                listQueryFn: (ref) => {
                    let q = query(ref, where('agentId', '==', agentId));
                    if (priority) {
                        q = query(q, where('priority', '==', priority));
                    }
                    return q;
                },
            });
            const solvedPromise = getCount({
                listQueryFn: (ref) => {
                    let q = query(ref, where('agentId', '==', agentId), where('_tags', 'array-contains-any', ['solved', 'transferred', 'spam', 'removed']));
                    if (priority) {
                        q = query(q, where('priority', '==', priority));
                    }
                    return q;
                },
            });
            const [total, solved] = await Promise.all([totalPromise, solvedPromise]);
            setCount(total - solved);
        };

        fetchCount();
    }, [agentId, priority, isMutating]);

    return { count };
};

export default useUnsolvedCount;
