import { UniversalForm } from '@rainbow-modules/forms';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;
    padding: 0 1.5rem;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 0 0;
`;

export const StyledUniversalForm = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0 0;
`;
