import priceFormatter from 'data/services/price/formatter';
import { CellWrapper } from '../styled';

interface Props {
    value?: number;
}

const Total = ({ value }: Props) => (
    <CellWrapper>
        {priceFormatter('usd').format((value || 0) / 100)}
    </CellWrapper>
);

export default Total;
