import { NameCellWrapper } from '../styled';

interface Props {
    value?: string;
}

const DescriptionColumn = ({ value }: Props) => (
    <NameCellWrapper>
        {value}
    </NameCellWrapper>
);

export default DescriptionColumn;
