import { useEffect, useMemo, useState } from 'react';
import {
    CollectionReference, limit, query, where,
} from 'firebase/firestore';
import { Customer } from 'data/firestore/agent/customer/types';
import listCustomers from 'data/firestore/agent/customer/list';

const resolveCustomer = async (agentId: string, phoneNumber: string): Promise<Customer> => (
    await listCustomers(
        agentId,
        {
            listQueryFn: (queryRef: CollectionReference) => query(
                queryRef,
                where('phoneNumber', '==', phoneNumber),
                limit(1),
            ),
        },
    )
)[0];

const useResolveCustomerByPhoneNumber = (
    agentId: string,
    phoneNumber: string,
    options: {
        disabled: boolean;
        track?: Array<string | number | boolean | undefined>;
    },
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Customer | null>(null);

    useEffect(
        () => {
            (async () => {
                if (!options.disabled) {
                    setIsLoading(true);
                    try {
                        const customer = await resolveCustomer(agentId, phoneNumber);
                        setData(customer || { phoneNumber });
                    } catch (error) {
                        // no catch
                    }
                    setIsLoading(false);
                }
            })();
        },
        [options.track, options.disabled, agentId, phoneNumber],
    );

    return useMemo(
        () => ({
            data,
            isLoading,
        }),
        [data, isLoading],
    );
};

export default useResolveCustomerByPhoneNumber;
