import { RenderIf } from 'react-rainbow-components';
import getDisplayName from 'data/services/profile/getDisplayName';
import { Description } from './styled';

interface EventCreatedByProps {
    value?: Record<string, string>;
}

const EventCreatedBy = ({ value }: EventCreatedByProps) => (
    <Description>
        {getDisplayName(value)}
        <RenderIf isTrue={value?.email}>
            <span>&#8226;</span>
            {value?.email}
        </RenderIf>
    </Description>
);

export default EventCreatedBy;
