import tryParseJson from 'data/services/string/parseJson';

const mapLLMValues = (values: Record<string, unknown>) => {
    const {
        tools, useInputSchema, inputSchema, outputSchema, jsonMode, ...rest
    } = values;

    const result: Record<string, unknown> = { jsonMode, ...rest };

    if (tools) {
        result.tools = tryParseJson(tools as string) || [];
    }

    result.inputSchema = tryParseJson(inputSchema as string);

    if (jsonMode) {
        result.outputSchema = tryParseJson(outputSchema as string);
    }

    return result;
};

export default mapLLMValues;
