import { useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { RenderIf, Slider, Tab } from 'react-rainbow-components';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import OpenAI from 'components/icons/openai';
import GPT4ModelPicker from 'components/GPT4ModelPicker';
import RadioItem from './radioItem';
import {
    FieldTitle, FieldDescription, CreativityHelpContainer,
    StyledPickerOptionContent, StyledOptionLabel, StyledUniversalPicker, StyledInput,
    TabContentContainer,
    StyledTabset,
} from './styled';
import isBuiltinModel from '../helpers/isBuiltinModel';

const DEFAULT_GPT4_MODEL = 'gpt-4o-2024-05-13';

const isBuiltinGpt4Model = (modelId: string) => isBuiltinModel(modelId) && modelId.startsWith('gpt-4');

const Fields = () => {
    const { change } = useForm();
    const { values } = useFormState();
    const { model, modelId } = values;
    const [activeTab, setActiveTab] = useState('llm');

    useEffect(() => {
        // TODO: final-form accepts a `destroyOnUnregister` prop that can be used to
        // remove field data automatically when the field is unregistered.
        // But our UniversalForm does not support this prop yet.
        if (
            !['fine-tuned', 'gpt-4'].includes(model)
            || (model === 'fine-tuned' && isBuiltinGpt4Model(modelId))
        ) {
            change('modelId', undefined);
        }
        if (model === 'gpt-4' && !isBuiltinGpt4Model(modelId)) {
            change('modelId', DEFAULT_GPT4_MODEL);
        }
    }, [change, model, modelId]);

    return (
        <>
            <StyledTabset
                variant="line"
                activeTabName={activeTab}
                onSelect={(e, selectedTab) => setActiveTab(selectedTab)}
            >
                <Tab name="llm" label="LLM" />
            </StyledTabset>
            <TabContentContainer>
                <FieldTitle>Creativity</FieldTitle>
                <FieldDescription>
                    Increase the creativity setting for imaginative content or
                    reduce it for more straightforward tasks.
                </FieldDescription>
                <Field
                    name="temperature"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component={Slider}
                    type="number"
                    min={0}
                    max={2}
                    step={0.01}
                />
                <CreativityHelpContainer>
                    <span>consistent</span>
                    <span>creative</span>
                </CreativityHelpContainer>
                <br />
                <FieldTitle>Select Model</FieldTitle>
                <FieldDescription>
                    Select an AI model tailored to your needs,
                    ranging from advanced and complex to simple
                    and direct for your assistant&apos;s interactions.
                </FieldDescription>
                <StyledUniversalPicker
                    name="model"
                    value={model}
                    onChange={(value: any) => change('model', value)}
                    direction="vertical"
                >
                    <UniversalPickerOption component={RadioItem} name="gpt-3.5-turbo-0125">
                        <StyledPickerOptionContent>
                            <OpenAI />
                            <StyledOptionLabel>GPT-3.5</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                    <UniversalPickerOption component={RadioItem} name="gpt-4">
                        <StyledPickerOptionContent>
                            <OpenAI />
                            <StyledOptionLabel>GPT-4</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                    <UniversalPickerOption component={RadioItem} name="fine-tuned">
                        <StyledPickerOptionContent>
                            <OpenAI />
                            <StyledOptionLabel>Fine Tuned</StyledOptionLabel>
                        </StyledPickerOptionContent>
                    </UniversalPickerOption>
                </StyledUniversalPicker>
                <RenderIf isTrue={model === 'fine-tuned'}>
                    <Field
                        name="modelId"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={StyledInput}
                        label="Model ID"
                        labelAlignment="left"
                        borderRadius="semi-square"
                        required
                    />
                </RenderIf>
                <RenderIf isTrue={model === 'gpt-4'}>
                    <Field
                        name="modelId"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={GPT4ModelPicker}
                        label="Select GPT-4 model"
                        labelAlignment="left"
                        borderRadius="semi-square"
                        required
                    />
                </RenderIf>
            </TabContentContainer>
        </>
    );
};

export default Fields;
