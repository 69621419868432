import { DateTime } from 'luxon';

const extractDate = ({
    value,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
} : {
    value?: Date | string | null;
    timezone?: string;
}): Date => {
    let date: Date | number = Date.now();
    if (value) {
        const dateInTz = DateTime
            .fromJSDate(new Date(value))
            .setZone(timezone);

        date = new Date(
            dateInTz.get('year'),
            dateInTz.get('month') - 1,
            dateInTz.get('day'),
        );
        date.setHours(0, 0, 0, 1);
    }
    return new Date(date);
};

export default extractDate;
