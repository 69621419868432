import { Llm } from 'data/firestore/agent/llm/types';
import { Respondermapping } from 'data/firestore/agent/respondermapping/types';
import { createContext } from 'react';

export interface Context {
    responders?: Llm[];
    responderMapping?: Respondermapping[];
}

const context = createContext<Context>({
    responders: [],
    responderMapping: [],
});

export const { Provider, Consumer } = context;

export default context;
