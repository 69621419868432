import Trash from 'components/icons/trash';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import {
    ModalContainer, ModalDescription, ModalIconContainer, ModalTitle,
} from '../styled';

interface Props {
    phoneNumber: string;
}

const NotSpamModal = ({ phoneNumber }: Props) => (
    <ModalContainer>
        <ModalIconContainer>
            <Trash />
        </ModalIconContainer>
        <ModalTitle>
            Mark
            {' '}
            {formatIntlPhoneNumber(phoneNumber)}
            {' '}
            as “Not Spam”?
        </ModalTitle>
        <ModalDescription>
            By confirming, the selected phone number will be removed from the Blocked List.
            Future calls from this number will be managed as normal calls and will
            no longer be treated as spam.
        </ModalDescription>
    </ModalContainer>
);

export default NotSpamModal;
