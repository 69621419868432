import {
    // useCallback,
    useMemo,
    useState,
    MouseEvent,
    ChangeEvent,
    useEffect,
} from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import Dot from 'components/Dot';
import Button from 'components/Button';
import ButtonIcon from 'components/ButtonIcon';
import Trash from 'components/icons/trash';
import MailOpened from 'components/icons/mailOpened';
import { Inbox } from 'data/firestore/inbox/types';
import { EntityGet } from 'data/firestore/types';
import getInitials from 'data/services/string/getInitials';
import getDisplayName from 'data/services/profile/getDisplayName';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
// import useCustomerPanel from 'hooks/useCustomerPanel';
import DeleteModal from '../Item/deleteModal';
import useUpdateItem from '../hooks/useUpdateItem';
import NotSpamModal from './notSpamModal';
import {
    StyledLink,
    Date as StyledDate,
    Description,
    Label,
    Info,
    RightContent,
    StyledLi,
    StyledInput,
    StyledAvatar,
} from './styled';

const priorityColorMap: Record<string, 'error' | 'warning' | 'orange' | 'success'> = {
    urgent: 'error',
    high: 'orange',
    medium: 'warning',
    low: 'success',
};

const formatDate = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 1);
    const dateFormatter = (
        date > today
            ? new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            : new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' })
    );
    return dateFormatter.format(date);
};

interface ListItemProps {
    item: EntityGet<Inbox>;
    to: string;
    isChecked?: boolean;
    onToggle?: (item: Inbox, isSelected: boolean, refresh?: boolean) => void;
}

const ListItem = (props: ListItemProps) => {
    const {
        item, to, isChecked, onToggle,
    } = props;
    const {
        id, title, customer, priority, readBy, _tags: tags, removedBy, createdAt,
    } = item;
    const [isHover, setIsHover] = useState(false);
    const { mutateAsync: updateItem } = useUpdateItem();

    const refreshCheckItem = () => {
        onToggle?.(item, !!isChecked, true);
    };

    useEffect(() => {
        refreshCheckItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readBy]);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
        if (onToggle) {
            onToggle(item, event.target.checked);
        }
    };

    const markAsRead = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            await updateItem(id, 'read');
        } catch (error) {
            // TODO: handle error
            console.error(error);
        }
    };

    const handleMarkAsNotSpam = async (event: MouseEvent<HTMLButtonElement>) => {
        if (!customer?.phoneNumber) return;
        event.preventDefault();
        event.stopPropagation();

        const result = await confirmModal({
            header: '',
            question: <NotSpamModal phoneNumber={customer?.phoneNumber} />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
        });
        if (!result) return;

        showAppSpinner();
        try {
            await updateItem(id, 'not-spam');
            showAppNotification({
                title: `${customer.phoneNumber} removed from spam`,
                description: 'The phone number has been removed from the Blocked List and will now be received as regular calls.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            // TODO: handle error
            console.error(error);
            showAppNotification({
                title: 'Error',
                description: 'Something went wrong. Please try again.',
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement>) => {
        if (!id) return;
        event.preventDefault();
        event.stopPropagation();

        const result = await confirmModal({
            header: '',
            question: <DeleteModal />,
            borderRadius: 'semi-square',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
        });
        if (!result) return;

        try {
            await updateItem(id, 'remove');
        } catch (error) {
            // TODO: handle error
            console.error(error);
        }
    };

    const phoneNumber = useMemo(
        () => customer?.phoneNumber && formatIntlPhoneNumber(customer?.phoneNumber),
        [customer],
    );

    const displayName = useMemo(() => getDisplayName(customer), [customer]);

    const initials = useMemo(() => {
        if (displayName === 'Unknown') return null;
        return getInitials(displayName);
    }, [displayName]);

    const label = useMemo(
        () => (displayName === 'Unknown' ? phoneNumber || displayName : displayName),
        [displayName, phoneNumber],
    );

    const isNotRead = !readBy || readBy.length === 0;
    const isNotRemoved = !removedBy;
    const isSpam = tags?.includes('spam');
    const date = formatDate(createdAt);

    // const { openCustomerPanel } = useCustomerPanel();
    // const handleCustomerPanelClick = useCallback(
    //     () => openCustomerPanel({ data }),
    //     [openCustomerPanel, data],
    // );

    return (
        <StyledLi>
            <StyledInput
                type="checkbox"
                checked={isChecked}
                onChange={handleCheck}
            />
            <StyledLink
                to={to}
                isNotRead={isNotRead}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Info>
                    {/* <button type="button" onClick={handleCustomerPanelClick}> */}
                    <StyledAvatar
                        initials={initials}
                        value={customer?.uid || displayName}
                        src={customer?.photoURL}
                    />
                    {/* </button> */}
                    <Label isNotRead={isNotRead}>{label}</Label>
                    <Description isNotRead={isNotRead}>{title}</Description>
                </Info>
                <RightContent>
                    <RenderIf isTrue={!isHover}>
                        <Dot variant={priorityColorMap[priority] || 'success'} />
                        <StyledDate>
                            {date}
                        </StyledDate>
                    </RenderIf>
                    <RenderIf isTrue={isHover && !isSpam}>
                        <RenderIf isTrue={isNotRead}>
                            <ButtonIcon variant="base" borderRadius="semi-square" tooltip="Mark as Read" icon={<MailOpened />} size="small" title="Mark Unread" onClick={markAsRead} />
                        </RenderIf>
                        <RenderIf isTrue={isNotRemoved}>
                            <ButtonIcon variant="base" borderRadius="semi-square" icon={<Trash />} size="small" title="Delete" tooltip="Delete" onClick={handleDelete} />
                        </RenderIf>
                    </RenderIf>
                    <RenderIf isTrue={isHover && isSpam}>
                        <Button variant="base" borderRadius="semi-square" size="small" onClick={handleMarkAsNotSpam}>Not spam</Button>
                    </RenderIf>
                </RightContent>
            </StyledLink>
        </StyledLi>
    );
};

export default ListItem;
