import { RenderIf } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import checkIsImage from 'pages/Customers/CustomerDetails/helpers/checkIsImage';
import DownloadButton from 'components/DownloadButton';
import ZoomableImage from 'components/ZoomableImage';
import { formatBytes } from 'data/services/files/formatter';
import ListTile from 'components/ListTile';
import FilesIcon from 'components/icons/files';
import { FileInfoContainer } from './styled';
import useMediaFiles from './hooks/useMediaFiles';

interface SMSInformationProps {
    body?: string;
    media?: {
        url: any;
        contentType: any;
        size: number;
    }[];
}

const SMSInformation = ({
    body = '',
    media = [],
}: SMSInformationProps) => {
    const { agentId = '', customerId = '' } = useParams();
    const { files, isLoading: isLoadingFiles } = useMediaFiles({ agentId, customerId, media });

    return (
        <>
            <RenderIf isTrue={!body && files.length === 0}>
                <div>No message content</div>
            </RenderIf>
            <div>{body}</div>
            <RenderIf isTrue={!isLoadingFiles}>
                {files.map((file) => {
                    const isImage = checkIsImage(file.contentType as string);
                    const name = file.name as string;
                    const { originalName } = (file.customMetadata as Record<string, string>);
                    const size = Number(file.size) || 0;
                    const url = file.url as string;

                    if (isImage) {
                        return (
                            <ZoomableImage
                                src={url}
                                alt={name}
                                key={name}
                                width={200}
                                height={200}
                            />
                        );
                    }

                    // TODO: implement design
                    return (
                        <FileInfoContainer>
                            <ListTile
                                title={name}
                                subtitle={formatBytes(size)}
                                icon={<FilesIcon />}
                                isLoading={isLoadingFiles}
                            />
                            <DownloadButton
                                name={name}
                                originalName={originalName}
                                url={url}
                            />
                        </FileInfoContainer>
                    );
                })}
            </RenderIf>
        </>
    );
};

export default SMSInformation;
