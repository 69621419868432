import { ReactNode, useMemo } from 'react';
import { Subscriptions } from 'data/firestore/agent/subscriptions/types';
import { isEmpty } from '@rainbow-modules/validation';
import Plus from 'components/icons/plus';
import { RenderIf } from 'react-rainbow-components';
import LoadingShape from 'components/LoadingShape';
import {
    SectionContent,
    SectionHeader,
    StyledCard,
    Subtitle,
    Title,
    TitleContainer,
    ActionButton,
} from './styled';
import EmptyMessage from './emptyMessage';
import NotificationOption from './item';

interface NotificationSectionParams {
    readOnly?: boolean;
    isLoading?: boolean;
    title?: string;
    subtitle?: string;
    actionIcon?: ReactNode;
    notifications?: Subscriptions[];
    emptyMessage?: ReactNode | string;
    onAddClick?: () => void;
    onRemoveItem?: (value: Subscriptions) => void;
}

const NotificationSection = ({
    title,
    subtitle,
    actionIcon = <Plus />,
    notifications = [],
    isLoading = false,
    readOnly = true,
    emptyMessage,
    onAddClick = () => {},
    onRemoveItem = () => {},
}: NotificationSectionParams) => {
    const notificationsList = useMemo(() => notifications.map(
        (notification) => (
            <NotificationOption
                readOnly={readOnly}
                key={`option-${notification.id}`}
                type={notification.type}
                value={notification}
                onDelete={onRemoveItem}
            />
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [notifications]);

    return (
        <StyledCard>
            <SectionHeader>
                <TitleContainer>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleContainer>
                <RenderIf isTrue={!readOnly}>
                    <ActionButton
                        title="Add"
                        icon={actionIcon}
                        borderRadius="semi-square"
                        disabled={isLoading}
                        onClick={onAddClick}
                    />
                </RenderIf>
            </SectionHeader>
            <SectionContent>
                <RenderIf isTrue={isLoading}>
                    <LoadingShape variant="solid" shape="rounded-rect" width="40%" />
                    <LoadingShape variant="solid" shape="rounded-rect" width="30%" />
                    <LoadingShape variant="solid" shape="rounded-rect" width="60%" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>{notificationsList}</RenderIf>
                <RenderIf isTrue={!isLoading && isEmpty(notifications)}>
                    <EmptyMessage message={emptyMessage} />
                </RenderIf>
            </SectionContent>
        </StyledCard>
    );
};

export default NotificationSection;
