import styled, { css } from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';

interface ItemProps {
    direction: 'outgoing' | 'incoming';
}

export const MediaContainer = styled.div<ItemProps>`
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    ${(props) => props.direction === 'outgoing' && css`
        flex-direction: row-reverse;
    `};
`;

export const StyledButtonIcon = styled(ButtonIcon)<ItemProps>`
    position: absolute;
    top: 0;
    right: 3rem;

    & > svg {
        width: 1rem !important;
        height: 1rem !important;
    }

    ${(props) => props.direction === 'outgoing' && css`
        left: 3rem;
    `};
`;
