import { useMemo } from 'react';
import formatGender from 'data/services/profile/formatGender';
import { CellWrapper, EmptyLabel } from './styled';

interface Props {
    value?: string;
}

const GenderColumn = ({ value }: Props) => {
    const genderValue = useMemo(
        () => formatGender(value),
        [value],
    );

    if (!genderValue) return <CellWrapper><EmptyLabel>Not Provided</EmptyLabel></CellWrapper>;

    return <CellWrapper>{genderValue}</CellWrapper>;
};

export default GenderColumn;
