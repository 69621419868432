import styled, { css } from 'styled-components';
import { ProgressCircular } from 'react-rainbow-components';
import Plot from 'react-plotly.js';
import Button from 'components/Button';
import InsightSectionContainer from 'components/InsightSectionContainer';
import { GREY_500, GREY_300 } from '../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: auto;
`;

export const PrimaryInsightSectionContainer = styled(InsightSectionContainer)`
    h2 {
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.25rem;
    }

    & > div:last-child {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
`;

export const SecondaryInsightSectionContainer = styled(InsightSectionContainer)`
    ${(props) => !props.isLoading && css`
        & > div:last-child {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }
    `};
`;

export const SumaryContainer = styled.div`
    display: flex;
    padding: 0.62rem 1.5rem 0.5rem;
    align-items: center;
`;

export const IconContainer = styled.div<{ color?: string; background?: string; }>`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.background && css`
        background-color: ${props.background};
    `};
    ${(props) => props.color && css`
        color: ${props.color};
    `};
`;

export const StyledProgressCircular = styled(ProgressCircular)<{ color?: string; }>`
    width: 2.5rem;
    height: 2.7rem;

    h1 { display: none; }

    ${(props) => props.color && css`
        svg circle:last-child {
            stroke: ${props.color};
        }
    `};

`;

export const PercentLabel = styled.small<{ color?: string; }>`
    text-align: right;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem;
    color: ${GREY_300};
    ${(props) => props.color && css`
        color: ${props.color};
    `};
`;

export const Content = styled.div`
    padding: 0 1.5rem;
`;

export const StyledPlot = styled(Plot)`
    width: 100%;
    height: 340px;
`;

export const ButtonBar = styled.div`
    padding: 1rem 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
`;

export const StyledButton = styled(Button)`
    padding: 0.25rem 0.75rem;
    gap: 0.5rem;
    color: ${GREY_500};
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
`;
