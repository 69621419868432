import { useMemo } from 'react';
import MessageCircleIcon from 'components/icons/messageCircle';
import { getFormatter } from 'data/services/date/formatter';
import Check from 'components/icons/check';
import Clock from 'components/icons/clock3';
import SMSInformation from 'components/SMSInformation';
import { RenderIf } from 'react-rainbow-components';
import { Reminder } from 'data/hooks/useEventReminders';
import AlertCircle from 'components/icons/alertCircle';
import {
    ListContainer, LabelIcon, ReminderLabel, ReminderBody,
} from './styled';
import ItemSection from './ItemSection';
import { GREEN_500, RED_500 } from '../../../constants';

const ReminderIcon = ({
    status = '',
}: {
    status: string;
}) => {
    if (['sent', 'delivered'].includes(status)) {
        return (
            <LabelIcon color={GREEN_500}>
                <Check />
            </LabelIcon>
        );
    }

    if (['undelivered', 'failed'].includes(status)) {
        return (
            <LabelIcon color={RED_500}>
                <AlertCircle />
            </LabelIcon>
        );
    }

    return <LabelIcon><Clock /></LabelIcon>;
};

interface Params {
    when: Date;
    status: 'scheduled' | 'pending' | 'sent' | 'failed';
}

const dateFormatter = getFormatter('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
});

const getStatusDescription = (status:Params['status']) => {
    switch (status) {
        case 'scheduled':
            return 'Scheduled';
        case 'pending':
            return 'Pending to send';
        case 'sent':
            return 'Sent';
        case 'failed':
            return 'Failed to send';
        default:
            return 'Scheduled';
    }
};

const getReminderShortDescription = ({
    when,
    status,
}: Params) => {
    const date = dateFormatter.format(when);
    const statusDescription = getStatusDescription(status);
    return `SMS ${statusDescription} on ${date}`;
};

interface Props {
    when: Date;
    status: 'scheduled' | 'pending' | 'sent' | 'failed';
    body?: string;
}

const ReminderInfo = ({ when, status, body }: Props) => (
    <ItemSection
        disabled={status === 'scheduled'}
        label={(
            <ReminderLabel>
                <MessageCircleIcon />
                {getReminderShortDescription({ when, status })}
                <ReminderIcon status={status} />
            </ReminderLabel>
        )}
    >
        <RenderIf isTrue={Boolean(body)}>
            <ReminderBody>
                <SMSInformation body={body} />
            </ReminderBody>
        </RenderIf>
    </ItemSection>
);

const ReminderList = ({ value: reminders } : { value: Reminder[] }) => {
    const remindersList = useMemo(
        () => reminders.map((reminder) => (
            <ReminderInfo
                key={reminder.notificationId}
                when={reminder.when}
                status={reminder.status}
                body={reminder.body}
            />
        )),
        [reminders],
    );
    return (
        <ListContainer>
            {remindersList}
        </ListContainer>
    );
};

export default ReminderList;
