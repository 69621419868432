import { useMemo, useContext } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import context from 'context';
import { CUSTOMER_PANEL_DRAWER } from '../constants';

const useCustomerPanel = () => {
    const { customerPanelProps } = useContext(context);
    const { isOpen, data } = customerPanelProps;
    const [openModal, closeModal] = useOpenModal(CUSTOMER_PANEL_DRAWER);

    return useMemo(
        () => ({
            isOpen: isOpen as boolean,
            data,
            openCustomerPanel: openModal,
            closeCustomerPanel: closeModal,
        }),
        [closeModal, isOpen, data, openModal],
    );
};

export default useCustomerPanel;
