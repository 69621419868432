import { RenderIf } from 'react-rainbow-components';
import { Message } from '@twilio/conversations';
import * as linkify from 'linkifyjs';
import Linkify from 'linkify-react';
import { UrlPreview } from '../../../types';
import { MessageText } from './styled';
import LinkPreview from '../LinkPreview';

interface Props {
    body: string;
    twilioMessage?: Message;
    urlPreviewMetadata?: UrlPreview;
}

const renderLink = ({ attributes, content }: linkify.IntermediateRepresentation) => {
    const { href } = attributes;
    const isValidLink = /(\.[a-zA-Z]+\/?)|([\w-]+\/)*[\w-]+\?[\w-]+=([\w-]+&?)*$/.test(href)
        ? 'a'
        : 'span';
    return (
        <MessageText
            key={`link_${href}`}
            as={isValidLink}
            href={href}
            target="_blank"
            rel="noopener"
        >
            {content}
        </MessageText>
    );
};

const Body = (props: Props) => {
    const { body, twilioMessage, urlPreviewMetadata } = props;
    const linkifyOptions = {
        validate: {
            url: (value: any) => /^https?:\/\//.test(value),
        },
    };
    const links = linkify.find(body as string, linkifyOptions);
    const url = links.length > 0 ? links[0].href : null;
    if (links.length > 0) {
        return (
            <>
                <RenderIf isTrue={body}>
                    <Linkify options={{ render: renderLink }}>{body}</Linkify>
                </RenderIf>
                <RenderIf isTrue={twilioMessage && url && !urlPreviewMetadata?.error}>
                    <LinkPreview
                        url={url as string}
                        twilioMessage={twilioMessage}
                        urlPreviewMetadata={urlPreviewMetadata}
                    />
                </RenderIf>
            </>
        );
    }
    return <MessageText>{body}</MessageText>;
};

export default Body;
