import { useEffect, useState } from 'react';

const useDebouncedState = <T>(value: T, delay = 300) => {
    const [state, setState] = useState(value);
    const [debouncedState, setDebouncedState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedState(state);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [state, delay]);

    return [debouncedState, setState] as const;
};

export default useDebouncedState;
